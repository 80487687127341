<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        My Family

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(64)"
        />
      </h1>
    </div>

    <p>
      From your profile and contacts, this is the current view of your family.
    </p>

    <fieldset
      v-if="will.partner"
      data-test="partner"
    >
      <legend>Partner</legend>

      <span data-test="name">
        {{ will.partner.fullName }}
      </span>
    </fieldset>

    <fieldset
      v-if="will.children.length"
      data-test="children"
    >
      <legend>Children</legend>

      <ul>
        <li
          v-for="child in will.children"
          :key="child.id"
          data-test="child"
        >
          {{ child.fullName }}
        </li>
      </ul>
    </fieldset>

    <fieldset
      v-if="will.guardians.length"
      data-test="guardians"
    >
      <legend>Guardians</legend>

      <ul>
        <li
          v-for="guardian in will.guardians"
          :key="guardian.id"
          data-test="guardian"
        >
          {{ guardian.fullName }}
        </li>
      </ul>
    </fieldset>
  </div>
</template>

<script>
import Will from '@/models/Will'

export default {
  name: 'MyFamily',

  computed: {
    will () {
      return Will
        .query()
        .with('partner')
        .with('children')
        .with('guardians')
        .first()
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 64,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
}
</style>
