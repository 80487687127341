<template>
  <div>
    <label>
      <div>
        <div class="fake-button">
          Attach File
        </div>
      </div>

      <input
        type="file"
        class="real-input"
        data-test="file-input"
        @change="emitChange"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'AddFileButton',

  methods: {
    emitChange (event) {
      this.$emit('change', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.real-input {
  display: none;
}

.fake-button {
  background-color: transparent;
  border: 1px solid $link-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-normal;
  padding: 0.4rem 0.6rem;
  text-transform: capitalize;
  transition: all ease 300ms;
  width: fit-content;

  &:hover,
  &.active {
    background-color: lighten($main-background-color, 8%);
    color: $white;
  }

  &:disabled {
    cursor: auto;

    &:hover {
      background-color: transparent;
      color: $font-color-two;
    }
  }
}
</style>
