<template>
  <div
    v-on-clickaway="toggle"
    class="sliding-help"
  >
    <div class="pad-inner">
      <e-button
        class="icon-x-circle ghost close-button"
        @click="toggle"
      />

      <component
        :is="help"
        class="help"
      />

      <p class="feedback-form-text">
        If you would like to report an issue or ask for help,
        please use our feedback form <span class="link" @click="showFeedbackBar">here</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'SlidingHelp',

  mixins: [clickaway],

  computed: {
    help () {
      return {
        template: this.$store.state.currentHelpObject.html
      }
    }
  },

  methods: {
    toggle () {
      this.$store.commit('setShowSlidingHelp', !this.$store.state.showSlidingHelp)
    },

    showFeedbackBar () {
      this.toggle()
      this.$store.commit('setShowFeedbackBar', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.sliding-help {
  height: 100%;
  position: fixed;
  right: 0;
  z-index: 4;

  .pad-inner {
    background-color: $input-background-color;
    bottom: 0;
    box-shadow: -1px 0 3px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-y: scroll;
    padding: 1rem 1.6rem;
    position: absolute;
    right: 0;
    scrollbar-width: none;
    top: 0;
    width: 70vw;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    .close-button {
      align-self: flex-end;
      font-size: 1.4rem;
      padding: 0;
    }
  }
}
</style>
