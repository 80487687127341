'use strict'

import md5 from 'md5'

export default object => {
  const base64Block = btoa(unescape(encodeURIComponent(JSON.stringify(object))))

  return {
    md5Hash: md5(base64Block),
    base64Block
  }
}
