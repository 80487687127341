<template>
  <div>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this contact"
      @confirm="deleteContact"
      @cancel="showDeleteDialog = false"
    />

    <e-dialog
      v-if="showLinkedAccountsDialog"
      class="linked-accounts-dialog"
      data-test="linked-accounts-dialog"
    >
      <p>
        Before deletion, this contact must be unlinked from the following:
      </p>

      <ul>
        <li v-for="account in accountsLinkedWithThisContact" :key="account.id">
          {{ account.name }}
        </li>
      </ul>

      <e-button
        data-test="cancel-button"
        @click="showLinkedAccountsDialog = false"
      >
        Close
      </e-button>
    </e-dialog>

    <div class="top-inputs">
      <h1>
        {{ contactName }}
      </h1>

      <span class="buttons">
        <e-button @click="goToEdit">
          edit
        </e-button>

        <e-button class="delete-button" @click="showDeleteDialog = true">
          delete
        </e-button>
      </span>
    </div>

    <article>
      <section>
        <div v-if="contact.type === 'person'">
          <span v-if="contact.jobTitle">{{ contact.jobTitle }}</span>
          <span v-if="contact.jobTitle && contact.organisationName">, </span>
          <span>{{ contact.organisationName }}</span>
        </div>
      </section>

      <section v-if="contact.emailAddresses.length">
        <header>Email</header>

        <ul>
          <li v-for="email in contact.emailAddresses" :key="email.id">
            <span v-if="email.type">{{ email.type }}:</span> {{ email.value }}
          </li>
        </ul>
      </section>

      <section v-if="contact.phoneNumbers.length">
        <header>Phone</header>

        <ul>
          <li v-for="phoneNumber in contact.phoneNumbers" :key="phoneNumber.id">
            <span v-if="phoneNumber.type">{{ phoneNumber.type }}:</span> {{ phoneNumber.value }}
          </li>
        </ul>
      </section>

      <section v-if="contact.addresses.length">
        <header>Address</header>

        <ul>
          <li v-for="address in contact.addresses" :key="address.id">
            {{ address.placeResult['formatted_address'] }}
          </li>
        </ul>
      </section>

      <section v-if="showDetails">
        <header>Details</header>

        <dl>
          <div v-if="contact.dateOfBirth">
            <dt>Birthday:</dt>
            <dd>{{ formattedBirthday }}</dd>
          </div>

          <div v-if="contact.genderType">
            <dt>Gender:</dt>
            <dd>{{ contact.genderType }}</dd>
          </div>

          <div v-if="contact.placeOfBirth">
            <dt>Place of birth:</dt>
            <dd>{{ contact.placeOfBirth }}</dd>
          </div>

          <template v-if="contactIsACharity">
            <div v-if="contact.acronym">
              <dt>Acronym:</dt>
              <dd>{{ contact.acronym }}</dd>
            </div>

            <div v-if="contact.charityNumber">
              <dt>Charity number:</dt>
              <dd>{{ contact.charityNumber }}</dd>
            </div>

            <div>
              <dt>Is UK registered:</dt>
              <dd>{{ contact.isUkRegistered }}</dd>
            </div>
          </template>

          <div v-if="contact.website">
            <dt>Website:</dt>
            <dd>{{ contact.website }}</dd>
          </div>
        </dl>
      </section>

      <section v-if="contact.notes">
        <header>Notes</header>

        <div class="notes">
          {{ contact.notes }}
        </div>
      </section>
    </article>

    <div v-if="false">
      {{ contact.prefixes }}
      {{ contact.suffixes }}
      {{ contact.genderIdentity }}
    </div>
  </div>
</template>

<script>
import Account from '@/models/Account'
import Contact from '@/models/Contact'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import { format } from 'date-fns'

export default {
  name: 'ContactView',

  components: {
    ConfirmationDialog
  },

  props: {
    contactId: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted'
  ],

  data () {
    return {
      showDeleteDialog: false,
      showLinkedAccountsDialog: false
    }
  },

  computed: {
    contact () {
      return Contact.find(this.contactId) || {}
    },

    contactIsACharity () {
      return !!(this.contact.type === 'organisation' && this.contact.isCharity)
    },

    showDetails () {
      if (this.contact.type === 'person') {
        if (this.contact.dateOfBirth) return true
        if (this.contact.genderType) return true
        if (this.contact.placeOfBirth) return true
        if (this.contact.website) return true
        return false
      }

      if (this.contactIsACharity) {
        if (this.contact.acronym) return true
        if (this.contact.charityNumber) return true
        if (this.contact.isUkRegistered) return true
        if (this.contact.website) return true
        return false
      }

      // type === organisation
      if (this.contact.website) return true
      return false
    },

    contactName () {
      return this.contact.type === 'person'
        ? this.contact.fullName
        : this.contact.organisationName
    },

    accountsLinkedWithThisContact () {
      return Account
        .query()
        .where('supplierId', this.contactId)
        .get() || []
    },

    formattedBirthday () {
      return this.contact.dateOfBirth
        ? format(new Date(this.contact.dateOfBirth), 'MMMM d, Y')
        : null
    }
  },

  methods: {
    goToEdit () {
      this.$router.push({
        name: 'EditContact',
        params: {
          contactId: this.contactId
        }
      })
    },

    async deleteContact () {
      this.showDeleteDialog = false

      if (this.accountsLinkedWithThisContact.length) {
        this.showLinkedAccountsDialog = true
        return
      }

      await this.$store.dispatch('deleteContacts', [this.contactId])

      this.$emit('deleted', { name: 'NewContact' })
    }
  }
}
</script>

<style scoped lang="scss">
section {
  margin: 1rem 0;

  header {
    border-bottom: 2px solid $link-color;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  ul {
    list-style: none;
  }

  dl > div {
    column-gap: 0.2rem;
    display: grid;
    grid-template-columns: max-content auto;
  }

  dt {
    font-weight: 400;
    grid-column-start: 1;
  }

  dd {
    grid-column-start: 2;
  }

  ul,
  dl {
    padding-left: 0.4rem;
  }

  .notes {
    white-space: pre-wrap;
  }
}
</style>
