var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('e-button',{attrs:{"data-test":"add-passport-button"},on:{"click":function($event){return _vm.addPassport(_vm.passports)}}},[_c('span',{staticClass:"icon-plus"}),_vm._v(" Add Passport ")]),(_vm.passports.length)?_c('fieldset',[_c('legend',[_vm._v("Passports")]),_c('table',{attrs:{"data-test":"passports-table"}},[_vm._m(0),_c('tbody',_vm._l((_vm.passports),function(passport,index){return _c('tr',{key:passport.id},[_c('td',[_c('validation-provider',{attrs:{"vid":((passport.id) + "-passport-number"),"name":"Passport Number","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"value":_vm.passports[index].number,"error-messages":errors,"success":valid,"data-test":"passport-number-input"},on:{"input":function($event){return _vm.updatePassport(_vm.passports, passport.id, 'number', $event)}}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"Country","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"value":_vm.passports[index].countryIsoCode,"items":_vm.countrySelectOptions,"default-option-text":"Select Country","error-messages":errors,"success":valid,"data-test":"passport-country-code-select"},on:{"input":function($event){return _vm.updatePassport(_vm.passports, passport.id, 'countryIsoCode', $event)}}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"Expiry Date","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"value":_vm.passports[index].expiryDate,"type":"date","error-messages":errors,"success":valid,"data-test":"passport-expiry-date-input"},on:{"input":function($event){return _vm.updatePassport(_vm.passports, passport.id, 'expiryDate', $event)}}})]}}],null,true)})],1),_c('td',[_c('e-input',{attrs:{"value":_vm.passports[index].notes,"data-test":"passport-notes-input"},on:{"input":function($event){return _vm.updatePassport(_vm.passports, passport.id, 'notes', $event)}}})],1),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"ghost",attrs:{"data-test":"remove-passport-button"},on:{"click":function($event){return _vm.removePassport(_vm.passports, passport.id)}}},[_c('span',{staticClass:"icon-trash-2"})])],1)])}),0)])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Number")]),_c('th',[_vm._v("Country")]),_c('th',[_vm._v("Expiry Date")]),_c('th',[_vm._v("Notes")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])])}]

export { render, staticRenderFns }