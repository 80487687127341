var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeIn animate__faster"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Complete Your Details")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"user-profile-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"First Name","error-messages":errors,"success":valid,"is-required":true,"data-test":"first-name-input"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Last Name","error-messages":errors,"success":valid,"is-required":true,"data-test":"last-name-input"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":{ required: true, is_not_over_150_years_ago: true, is_past_date: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date of Birth","type":"date","error-messages":errors,"success":valid,"is-required":true,"data-test":"date-of-birth-input"},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Gender","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.genderTypes,"label":"Gender","is-required":true,"error-messages":errors,"success":valid,"data-test":"gender-type-select"},model:{value:(_vm.genderType),callback:function ($$v) {_vm.genderType=$$v},expression:"genderType"}})]}}],null,true)})],1),_c('address-manager',{attrs:{"addresses":_vm.addresses,"address-required":true}}),_c('submit-button',{staticClass:"submit-button",attrs:{"form-is-valid":formIsValid,"form":"user-profile-form","button-text":"Submit my details","button-style":"one","button-type":"single"}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }