<template>
  <div class="unsupported-block">
    <p v-if="unsupportedOperatingSystem">
      The operating system you are using does not meet our requirements.
    </p>

    <p v-if="unsupportedBrowser">
      Your web browser does not meet our security requirements.
    </p>

    <p>Please check the <a href="https://www.meavitae.com">MeaVitae</a> website for details</p>
  </div>
</template>

<script>
export default {
  name: 'UnsupportedBrowserOrOperatingSystem',

  computed: {
    unsupportedOperatingSystem () {
      return (this.$store.state.operatingSystem === 'Android OS')
    },

    unsupportedBrowser () {
      return (this.$store.state.browserName === 'firefox')
    }
  }
}
</script>

<style scoped lang="scss">
.unsupported-block {
  a {
    color: $link-color;
    text-decoration: none;
  }

  p {
    color: $font-color-one;
    display: block;
    margin: 2rem 0 0;
    text-align: center;
  }
}
</style>
