'use strict'

import FormView from '@/views/FormView'
import LoadingSpinner from '@/components/LoadingSpinner'
import Membership from '@/views/UserAccount/Membership'
import MyData from '@/views/UserAccount/MyData'
import MyFiles from '@/views/UserAccount/MyFiles'
import Purchases from '@/views/UserAccount/Purchases'
import SecurityKeys from '@/views/UserAccount/SecurityKeys'
import UserAccount from '@/views/UserAccount/UserAccount'
import Wallet from '@/views/UserAccount/Wallet'
import Orders from '@/views/UserAccount/Orders'

export default {
  path: '/account',
  name: 'UserAccount',
  components: {
    appClosedLayout: LoadingSpinner,
    default: UserAccount
  },
  meta: { title: 'User Account' },
  children: [
    {
      path: 'profile',
      name: 'Profile',
      props: {
        default: () => ({
          form: 'UserForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'My Profile' }
    },

    {
      path: 'membership',
      name: 'Membership',
      component: Membership,
      props: true,
      meta: { title: 'Membership' }
    },

    {
      path: 'purchases',
      name: 'Purchases',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Purchases
      },
      meta: { title: 'Purchases' }
    },

    {
      path: 'orders',
      name: 'Orders',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Orders
      },
      meta: { title: 'Orders' }
    },

    {
      path: 'wallet',
      name: 'Wallet',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Wallet
      },
      meta: { title: 'Wallet' }
    },

    {
      path: 'security-keys',
      name: 'SecurityKeys',
      components: {
        appClosedLayout: LoadingSpinner,
        default: SecurityKeys
      },
      meta: { title: 'My Security Keys' }
    },

    {
      path: 'my-files',
      name: 'MyFiles',
      components: {
        appClosedLayout: LoadingSpinner,
        default: MyFiles
      },
      meta: { title: 'Files' }
    },

    {
      path: 'my-data',
      name: 'MyData',
      components: {
        appClosedLayout: LoadingSpinner,
        default: MyData
      },
      meta: { title: 'My Data' }
    }
  ]
}
