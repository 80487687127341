<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn animate__faster"
  >
    <div class="container">
      <div
        id="terms-and-conditions-text"
        class="terms-and-conditions"
      >
        <h2>Terms of Use</h2>

        <p><strong>1. Agreement to Terms</strong></p>
        <p>
          1.1. These terms and conditions (<strong>Terms of
            Use</strong>) constitute a legally binding agreement
          made between you, whether personally or on behalf of an
          entity (<strong>you</strong>), and MeaVitae Limited (Company
          number 11556365), located at 1a The Quadrant Courtyard,
          Quadrant Way, Weybridge, KT13 8DR United Kingdom
          (<strong>we, us, our, MeaVitae</strong>), concerning your
          access to and use of the website <span><a
            href="https://www.meavitae.com/"
            target="_blank"
          >https://www.meavitae.com
          </a></span>(the <strong>Website</strong>) and the
          app https://app.meavitae.com (the <strong>App</strong>)
          as well as any related applications (altogether
          the <strong>Site</strong>).
        </p>
        <p>
          1.2. You understand and agree that MeaVitae is not a lawyer
          nor a law firm and does not engage in the practice of law or
          provide legal advice or legal representation. All
          information, software, services, and comments provided on
          the Site are for informational and self-help purposes only
          and are not intended to be a substitute for professional
          legal advice.
        </p>
        <p>
          1.3. In some areas you will have different rights under these
          Terms of Use depending on whether you are a business or
          consumer. You are a consumer if:
        </p>
        <p style="padding-left: 40px;">
          1.3.1. You are an individual.
        </p>
        <p style="padding-left: 40px;">
          1.3.2. You are buying products
          from us wholly or mainly for your personal use (not for use
          in connection with your trade, business, craft or
          profession).
        </p>
        <p style="padding-left: 40px;">
          Provisions specific to consumers
          only are in <span style="color: #c9f;">purple</span> and
          those specific to businesses only are in <span
            style="color: #396;"
          >green</span>.
        </p>
        <p>
          1.4. In order to access the Site, you will be provided with a
          hardware security key. You understand and agree that
          MeaVitae will not be liable to you for any loss or damage,
          whether in contract, tort (including negligence), breach of
          statutory duty, or otherwise, even if foreseeable, for the
          loss of access to your encrypted data if you should lose
          your hardware security keys.
        </p>
        <p>
          1.5. The Site provides the following services: online
          platform enabling users to securely list, update and manage
          assets and liabilities, apportion said assets and
          liabilities through dynamic will creation tools, create
          contact lists, make purchases through an online marketplace
          and communicate with selected service providers (the
          <strong>Services</strong>). You agree that by accessing the
          Site and/or Services, you have read, understood, and agree
          to be bound by all of these Terms of Use.
        </p>
        <p>
          1.6. If you do not agree with all of these Terms of Use, then
          you are prohibited from using the Site and Services and you
          must discontinue use immediately. We recommend that you
          print or save a copy of these Terms of Use for future
          reference.
        </p>
        <p>
          1.7. The supplemental policies set out in Section 1.12 below,
          as well as any supplemental terms and conditions or
          documents that may be posted on the Site from time to time,
          are expressly incorporated by reference.
        </p>
        <p>
          1.8. We may make changes to these Terms of Use at any time by
          sending you an email with details of the change or notifying
          you of a change when you next start the Site. The updated
          version of these Terms of Use will be indicated by an
          updated “Revised” date and the updated version will be
          effective as soon as it is accessible. You are responsible
          for reviewing these Terms of Use to stay informed of
          updates. Your continued use of the Site represents that you
          have accepted such changes.
        </p>
        <p>
          1.9. We may update or change the Site from time to time to
          reflect changes to our products, services, our users' needs
          and/or our business priorities.
        </p>
        <p>
          1.10. Our Site is directed to people residing in England and
          Wales. The information provided on the Site is not intended
          for distribution to or use by any person or entity in any
          jurisdiction or country where such distribution or use would
          be contrary to law or regulation or which would subject us
          to any registration requirement within such jurisdiction or
          country.
        </p>
        <p>
          1.11. The Site is intended for users who are at least 16
          years old. If you are under the age of 16, you are not
          permitted to register for the Site or use the Services. If
          you are under 18, you must have your parent or legal
          guardian's permission to use the Site. Please have them read
          these Terms of Use with you. If you are a parent or legal
          guardian of a user under the age of 18, by allowing your
          child to use the Site, you are subject to the terms of these
          Terms of Use and responsible for your child's activity on
          the Site. The laws of England and Wales prohibit someone
          under the age of 18 from executing a will.
        </p>
        <p>
          1.12. Additional policies which also apply to your use of the
          Site include:
        </p>
        <p style="padding-left: 40px;">
          1.12.1. Our <span><a
            href="https://www.meavitae.com/privacy-notice/"
            target="_blank"
          >Privacy Notice</a></span>,
          which sets out the terms on which we process any personal
          data we collect from you, or that you provide to us. By
          using the Site, you consent to such processing and you
          warrant that all data provided by you is accurate. We have
          no visibility to any information you store within your
          secure digital vault.
        </p>
        <p style="padding-left: 40px;">
          1.12.2. Our <a
            href="https://www.meavitae.com/cookie-policy/"
            target="_blank"
          >Cookies Policy</a> which sets out
          information about the cookies on our site.
        </p>
        <p style="padding-left: 40px;">
          1.12.3. Certain parts of the Site
          can only be used on payment of a fee. If you wish to use
          such Services, you will, in addition to our Terms of Use,
          also be subject to our <span><a
            href="https://www.meavitae.com/terms-of-purchase/"
            target="_blank"
          >Terms of
            Purchase</a></span>
        </p>
        <p style="padding-left: 40px;">
          1.12.4. If you make other
          purchases from our marketplace, our <span><a
            href="https://www.meavitae.com/terms-of-purchase/"
            target="_blank"
          >Terms of Purchase
          </a></span>will apply to the sales, use, and licence of
          these.
        </p>
        <p><strong>2. Licence</strong></p>
        <p>
          2.1. Provided that you are eligible to use the Site, you are
          granted a revocable, non-exclusive, non-transferable licence
          to access and use the Site and Our Content (as defined in
          clause 7.1) and to download or print a copy of any portion
          of Our Content to which you have properly gained access
          solely for your personal, non-commercial use.
        </p>
        <p>
          2.2. If you access the Services via the App, then we also
          grant you a revocable, non-exclusive, non-transferable,
          licence to install and use the App on wireless electronic
          devices owned or controlled by you, and to access and use
          the App on such devices solely for your personal,
          non-commercial use.
        </p>
        <p><strong>3. Licence Restrictions</strong></p>
        <p>
          3.1. You may not access or use the Site for any purpose other
          than that for which we make the Site and our Services
          available. The Site may not be used in connection with any
          commercial endeavours except those that are specifically
          endorsed or approved by us.
        </p>
        <p>3.2. As a user of this Site, you agree to:</p>
        <p style="padding-left: 40px;">
          3.2.1. except in the course of
          permitted sharing, not rent, lease, sub-license, loan,
          provide, or otherwise make available, the Site or the
          Services in any form, in whole or in part to any person
          without prior written consent from us;
        </p>
        <p style="padding-left: 40px;">
          3.2.2. not copy the Site or
          Services, except as part of the normal use of the Site or
          where it is necessary for the purpose of back-up or
          operational security;
        </p>
        <p style="padding-left: 40px;">
          3.2.3. not translate, merge,
          adapt, vary, alter or modify, the whole or any part of the
          Site or Services, nor permit the Site or the Services or any
          part of them to be combined with, or become incorporated in,
          any other programs, except as necessary to use the Site and
          the Services on devices as permitted in these terms;
        </p>
        <p style="padding-left: 40px;">
          3.2.4. not disassemble,
          de-compile, reverse engineer or create derivative works
          based on the whole or any part of the Site or the Services
          nor attempt to do any such things, except to the extent that
          (by virtue of sections 50B and 296A of the Copyright,
          Designs and Patents Act 1988) such actions cannot be
          prohibited because they are necessary to decompile the Site
          to obtain the information necessary to create an independent
          program that can be operated with the Site or with another
          program (<strong>Permitted Objective</strong>), and provided
          that the information obtained by you during such activities:
        </p>
        <p style="padding-left: 80px;">
          3.2.4.1. is not disclosed or
          communicated without our prior written consent to any third
          party to whom it is not necessary to disclose or communicate
          it in order to achieve the Permitted Objective;
        </p>
        <p style="padding-left: 80px;">
          3.2.4.2. is not used to create
          any software that is substantially similar in its expression
          to the Site;
        </p>
        <p style="padding-left: 80px;">
          3.2.4.3. is kept secure; and
        </p>
        <p style="padding-left: 80px;">
          3.2.4.4. is used only for the
          Permitted Objective; and
        </p>
        <p style="padding-left: 40px;">
          3.2.5. comply with all applicable
          technology control or export laws and regulations that apply
          to the technology used or supported by the Site or any
          Service.
        </p>
        <p> <strong>4. Acceptable Use Restrictions</strong></p>
        <p>4.1. As a user of this Site, you must not:</p>
        <p style="padding-left: 40px;">
          4.1.1. use the Site or any
          Service in any unlawful manner, for any unlawful purpose, or
          in any manner inconsistent with these terms, or act
          fraudulently or maliciously, for example, by hacking into or
          inserting malicious code, such as viruses, or harmful data,
          into the Site, any Service or any operating system;
        </p>
        <p style="padding-left: 40px;">
          4.1.2. infringe our intellectual
          property rights or those of any third party in relation to
          your use of the Site or any Service, including by the
          submission of any material (to the extent that such use is
          not licensed by these terms);
        </p>
        <p style="padding-left: 40px;">
          4.1.3. transmit any material that
          is defamatory, offensive or otherwise objectionable in
          relation to your use of the Site or any Service;
        </p>
        <p style="padding-left: 40px;">
          4.1.4. use the Site or any
          Service in a way that could damage, disable, overburden,
          impair or compromise our systems or security or interfere
          with other users;
        </p>
        <p style="padding-left: 40px;">
          4.1.5. collect or harvest any
          information or data from any Service or our systems or
          attempt to decipher any transmissions to or from the servers
          running any Service;
        </p>
        <p style="padding-left: 40px;">
          4.1.6. circumvent, disable, or
          otherwise interfere with security-related features of the
          Site, including features that prevent or restrict the use or
          copying of any content or enforce limitations on the use;
        </p>
        <p style="padding-left: 40px;">
          4.1.7. engage in unauthorized
          framing of or linking to the Site;
        </p>
        <p style="padding-left: 40px;">
          4.1.8. trick, defraud, or mislead
          us and other users, especially in any attempt to learn
          sensitive account information such as user passwords;
        </p>
        <p style="padding-left: 40px;">
          4.1.9. make improper use of our
          support services, or submit false reports of abuse or
          misconduct;
        </p>
        <p style="padding-left: 40px;">
          4.1.10. engage in any automated
          use of the system, or using any data mining, robots, or
          similar data gathering and extraction tools;
        </p>
        <p style="padding-left: 40px;">
          4.1.11. interfere with, disrupt,
          or create an undue burden on the Site or the networks and
          services connected to the Site;
        </p>
        <p style="padding-left: 40px;">
          4.1.12. attempt to impersonate
          another user or person, or use the username of another user;
        </p>
        <p style="padding-left: 40px;">
          4.1.13. sell or otherwise
          transfer your profile;
        </p>
        <p style="padding-left: 40px;">
          4.1.14. use the Site or Our
          Content as part of any effort to compete with us or to
          create a revenue-generating endeavour or commercial
          enterprise;
        </p>
        <p style="padding-left: 40px;">
          4.1.15. attempt to access any
          portions of the Site that you are restricted from accessing;
        </p>
        <p style="padding-left: 40px;">
          4.1.16. harass, annoy,
          intimidate, or threaten any of our employees, agents, or
          other users;
        </p>
        <p style="padding-left: 40px;">
          4.1.17. behave in a way which may
          annoy any of our employees, agents, or other users by, for
          example, sending unsolicited communication, promotions or
          advertisements, or spam.
        </p>
        <p style="padding-left: 40px;">
          4.1.18. delete the copyright or
          other proprietary rights notice from any of Our Content;
        </p>
        <p style="padding-left: 40px;">
          4.1.19. copy the Site's software,
          including but not limited to PHP, HTML, JavaScript, or other
          code
        </p>
        <p style="padding-left: 40px;">
          4.1.20. upload, store or transmit
          any illegal or explicit content;
        </p>
        <p style="padding-left: 40px;">
          4.1.21. use, launch, or engage in
          any automated use of the system, such as using robots,
          scrapers, offline readers, or similar data gathering and
          extraction tools;
        </p>
        <p style="padding-left: 40px;">
          4.1.22. disparage, tarnish, or
          otherwise harm, in our opinion, us and/or the Site;
        </p>
        <p style="padding-left: 40px;">
          4.1.23. use the Site in a manner
          inconsistent with any applicable laws or regulations; or
        </p>
        <p style="padding-left: 40px;">
          4.1.24. falsely imply a
          relationship with us or another company with whom you do not
          have a relationship.
        </p>
        <p><strong>5. Information you provide to us</strong></p>
        <p>
          5.1. You represent and warrant that: (a) all registration
          information you submit will be true, accurate, current, and
          complete and relate to you and not a third party; (b) you
          will maintain the accuracy of such information and promptly
          update such information as necessary; and (c) you will keep
          your password and PIN confidential and hardware encryption
          key secure and will be responsible for all use of your
          password, PIN and account.
        </p>
        <p>
          5.2. If you know or suspect that anyone other than you knows
          your user information (such as an identification code or
          user name), password and/or your hardware encryption key has
          been compromised you must promptly notify us at <span><a
            href="https://www.meavitae.com/cdn-cgi/l/email-protection#8de9fde2cde0e8ecfbe4f9ece8a3eee2e0"
          ><span
            class="__cf_email__"
            data-cfemail="3551455a75585054435c4154501b565a58"
            target="_blank"
          >[email&#160;protected]</span></a></span>.
        </p>
        <p>
          5.3. If you provide any information that is untrue,
          inaccurate, not current or incomplete, we may suspend or
          terminate your account. We may remove or change a user name
          you enter if we determine that such user name is
          inappropriate.
        </p>
        <p><strong>6. Third Party Accounts</strong></p>
        <p>
          6.1. If the functionality of the Site allows it, you may link
          your account with online accounts you may have with
          third-party service providers (each such account, a
          <strong>Third-Party Account</strong>) by either: (a)
          providing your Third-Party Account login information through
          the Site; or (b) allowing us to access your Third-Party
          Account, as is permitted under the applicable terms and
          conditions that govern your use of each Third-Party Account.
        </p>
        <p>
          6.2. You represent that you are entitled to disclose your
          Third-Party Account login information to us and/or grant us
          access to your Third-Party Account without breach by you of
          any of the Terms of Use that govern your use of the
          applicable Third-Party Account and without obligating us to
          pay any fees or making us subject to any usage limitations
          imposed by such third-party service providers.
        </p>
        <p>
          6.3. By granting us access to any Third-Party Accounts, you
          understand that (a) we may access, make available and store
          (if applicable) any content that you have provided to and
          stored in your Third-Party Account (the <strong>Third-Party
            Account Content</strong>) so that it is available on and
          through the Site via your account; and (b) we may submit and
          receive additional information to your Third-Party Account
          to the extent you are notified when you link your account
          with the Third-Party Account.
        </p>
        <p>
          6.4. Depending on the Third-Party Accounts you choose and
          subject to the privacy settings that you have set in such
          Third-Party Accounts, personally identifiable information
          that you post to your Third-Party Accounts may be available
          on and through your account on the Site. Please note that if
          a Third-Party Account or associated service becomes
          unavailable or our access to such Third-Party Account is
          terminated by the third-party service provider, then
          Third-Party Account Content may no longer be available on
          and through the Site.
        </p>
        <p>
          6.5. You will have the ability to disable the connection
          between your account on the Site and your Third-Party
          Accounts at any time. Please note that your relationship
          with the third-party service providers associated with your
          Third-Party Account(s) is governed solely by your
          agreement(s) with such third-party service providers. We
          make no effort to review any Third-Party Account Content for
          any purpose, including but not limited to, for accuracy,
          legality or non-infringement, and we are not responsible for
          any Third-Party Account Content.
        </p>
        <p><strong>7. Our Content</strong></p>
        <p>
          7.1. Unless otherwise indicated, the Site and Services
          including source code, databases, functionality, software,
          website designs, audio, video, text, photographs, and
          graphics on the Site (<strong>Our Content</strong>) are
          owned by us or licensed to us and the rights in the Site and
          the Services are licensed or sub-licensed (not sold) to you.
          You have no intellectual property rights in, or to, the Site
          or the Services other than the right to use them in
          accordance with these Terms of Use.
        </p>
        <p>
          7.2. Except as expressly provided in these Terms of Use, no
          part of the Site, Services or Our Content may be copied,
          reproduced, aggregated, republished, uploaded, posted,
          publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any
          commercial purpose whatsoever, without our express prior
          written permission.
        </p>
        <p>
          7.3. You shall not (a) try to gain unauthorised access to the
          Site or any networks, servers or computer systems connected
          to the Site; and/or (b) make for any purpose including error
          correction, any modifications, adaptations, additions or
          enhancements to the Site or Our Content, except for the
          modification of digital files you have purchased from the
          Service marketplace or have access to as part of your
          subscription with us.
        </p>
        <p>
          7.4. We shall prepare the Site and Our Content and provide
          our Services with reasonable skill and care.
        </p>
        <p>
          7.5. Our Content on the Site is provided for general
          information only. It is not intended to amount to advice on
          which you should rely. You must obtain professional or
          specialist advice before taking, or refraining from taking,
          any action on the basis of any information on the Site.
        </p>
        <p>
          7.6. Although we make reasonable efforts to update the
          information on our Site, we make no representations,
          warranties or guarantees, whether express or implied, that
          Our Content on the Site is accurate, complete or up to date.
        </p>
        <p><strong>8. Your Content</strong></p>
        <p>
          8.1. Some of our Services allow you to store or share files,
          documents and communications and messages on the Site
          (<strong>Your Content</strong>). You own and are responsible
          for all Your Content.
        </p>
        <p>
          8.2. To the extent necessary to provide the Site and
          Services, you hereby grant us a worldwide and royalty free
          licence to use Your Content, including the right to make
          copies of, retain, transmit, reformat, and display Your
          Content to you on your device. This licence shall extend to
          our affiliates and trusted third parties who we work with
          where necessary.  The rights you grant in this licence are
          for the limited purpose of providing the Services to you. We
          encrypt Your Content when it is uploaded to the Site and it
          is therefore not visible to MeaVitae. Please review our
          Privacy Policy for further information on how we protect
          information you upload to the Site.
        </p>
        <p>
          8.3. If you share Your Content with other users on the Site,
          you understand that they may be able to use, save, record,
          reproduce, broadcast, transmit, share and display Your
          Content. If you do not want other users to use Your Content
          in this way, you must not make Your Content public or share
          it with other users.
        </p>
        <p><strong>9. Link to third-party content</strong></p>
        <p>
          9.1. The Site may contain links to websites or applications
          operated by third parties. We do not have any influence or
          control over any such third-party websites or applications
          or the third-party operator. We are not responsible for and
          do not endorse any third-party websites or applications or
          their availability or content.
        </p>
        <p><strong>10. Site management</strong></p>
        <p>
          10.1. We reserve the right at our sole discretion, to (a)
          monitor the Site for breaches of these Terms of Use; (b)
          take appropriate legal action against anyone in breach of
          applicable laws or these Terms of Use; (c) remove from the
          Site or otherwise disable all files and content that are
          excessive in size or are in any way a burden to our systems;
          and (d) otherwise manage the Site in a manner designed to
          protect our rights and property and to facilitate the proper
          functioning of the Site and Services.
        </p>
        <p>
          10.2. We cannot guarantee that the Site will be secure or
          free from bugs or viruses.
        </p>
        <p>
          10.3. Assuming adherence to section 5.1(c), we guarantee that
          the Site will be secure and that we have no visibility to
          the information stored in your secure digital vault.
        </p>
        <p>
          10.4. You are responsible for configuring your information
          technology, computer programs and platform to access the
          Site and you should use your own virus protection software.
        </p>
        <p>
          <strong>11. Modifications to and availability of the
            Site</strong>
        </p>
        <p>
          11.1. We reserve the right to change, modify, or remove the
          contents of the Site at any time or for any reason at our
          sole discretion without notice. We also reserve the right to
          modify or discontinue all or part of the Services without
          notice at any time.
        </p>
        <p>
          11.2. We cannot guarantee the Site and Services will be
          available at all times. We may experience hardware,
          software, or other problems or need to perform maintenance
          related to the Site, resulting in interruptions, delays, or
          errors. You agree that we have no liability whatsoever for
          any loss, damage, or inconvenience caused by your inability
          to access or use the Site or Services during any downtime or
          discontinuance of the Site or Services. We are not obliged
          to maintain and support the Site or Services or to supply
          any corrections, updates, or releases.
        </p>
        <p>
          11.3. There may be information on the Site that contains
          typographical errors, inaccuracies, or omissions that may
          relate to the Services, including descriptions, pricing,
          availability, and various other information. We reserve the
          right to correct any errors, inaccuracies, or omissions and
          to change or update the information at any time, without
          prior notice.
        </p>
        <p><strong>12. Disclaimer/Limitation of Liability</strong></p>
        <p>
          12.1. The Site and Services are provided on an as-is and
          as-available basis. You agree that your use of the Site
          and/or Services will be at your sole risk except as
          expressly set out in these Terms of Use.
        </p>
        <p>
          12.2. We make no warranties or representations about the
          accuracy or completeness of the Site’s content and are not
          liable for any (1) errors or omissions in content: (2) any
          unauthorized access to or use of the servers of our cloud
          service providers; (3) any interruption or cessation of
          transmission to or from the site or services; and/or (4) any
          bugs, viruses, trojan horses, or the like which may be
          transmitted to or through the site by any third party. We
          will not be responsible for any delay or failure to comply
          with our obligations under these Terms of Use if such delay
          or failure is caused by an event beyond our reasonable
          control (for example, a natural disaster). This section does
          not affect your statutory rights as a consumer.
        </p>
        <p>
          12.3. Our responsibility for loss or damage suffered by you:
        </p>
        <p style="padding-left: 40px;">
          12.3.1. Whether you are a
          consumer or a business user:
        </p>
        <p style="padding-left: 80px;">
          12.3.1.1. We do not exclude or
          limit in any way our liability to you where it would be
          unlawful to do so. This includes liability for death or
          personal injury caused by our negligence or the negligence
          of our employees, agents or subcontractors and for fraud or
          fraudulent misrepresentation.
        </p>
        <p style="padding-left: 80px;">
          12.3.1.2. If we fail to comply
          with these Terms of Use, we will be responsible for loss or
          damage you suffer that is a foreseeable result of our breach
          of these Terms of Use, but we would not be responsible for
          any loss or damage that were not foreseeable at the time you
          started using the Site/Services.
        </p>
        <p style="padding-left: 40px;">
          <span
            style="color: #396;"
          >12.3.2. If you are a business
            user:</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.1. All warranties, terms,
            conditions and undertakings, express or implied
            (including by statute, custom or usage, a course of
            dealing, or common law) in connection with the Site and
            Services and your use thereof including, without
            limitation, the implied warranties of satisfactory
            quality, fitness for a particular purpose and
            non-infringement are excluded to the fullest extent
            permitted by applicable law.</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.2. Notwithstanding
            anything to the contrary contained in the
            Disclaimer/Limitation of Liability section, our
            liability to you for any cause whatsoever and regardless
            of the form of the action, will at all times be limited
            to a total aggregate amount equal to the greater of (a)
            the sum of £1000 or (b) the amount paid, if any, by you
            to us for the Services/Site during the six (6) month
            period prior to any cause of action arising. Different
            limitations and exclusions of liability may apply to
            liability arising as a result of the supply of any
            products to you.</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.3. We will not be liable
            to you for any loss or damage, whether in contract, tort
            (including negligence), breach of statutory duty, or
            otherwise, even if foreseeable, arising under or in
            connection with:</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.3.1. use of, or inability
            to use, our Site/Services; or</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.3.2. use of or reliance
            on any content displayed on our Site.</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4. In particular, we will
            not be liable for:</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4.1. loss of profits,
            sales, business, or revenue;</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4.2. business
            interruption;</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4.3. loss of anticipated
            savings;</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4.4. loss of business
            opportunity, goodwill or reputation; or</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #396;"
          >12.3.2.4.5. any indirect or
            consequential loss or damage.</span>
        </p>
        <p style="padding-left: 40px;">
          <span
            style="color: #c9f;"
          >12.3.3. If you are a consumer
            user:</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #c9f;"
          >12.3.3.1. we will either repair
            the damage or pay you compensation if defective digital
            content that we have supplied, damages a device or
            digital content belonging to you and this is caused by
            our failure to use reasonable care and skill,.</span>
        </p>
        <p style="padding-left: 80px;">
          <span
            style="color: #c9f;"
          >12.3.3.2. you have legal rights
            in relation to goods that are faulty or not as
            described. Advice about your legal rights is available
            from your local Citizens' Advice Bureau or Trading
            Standards office. Nothing in these Terms of Use will
            affect these legal rights.</span>
        </p>
        <p><strong>13. Term and Termination</strong></p>
        <p>
          13.1. These Terms of Use shall remain in full force and
          effect while you use the Site or Services or are otherwise a
          user of the Site, as applicable. You may terminate your use,
          subject to the <a href="https://www.meavitae.com/terms-of-purchase/" target="_blank">Terms of
            Purchase</a> at any time, for any reason,
          by following the instructions for terminating user accounts
          in your account settings.
        </p>
        <p>
          13.2. Without limiting any other provision of these Terms of
          Use, we reserve the right, in our sole discretion and
          without notice or liability, to deny access to and use of
          the Site and the Services (including blocking certain IP
          addresses), to any person for any reason including without
          limitation for breach of any representation, warranty or
          covenant contained in these Terms of Use or of any
          applicable law or regulation.
        </p>
        <p>
          13.3. Without affecting any other right or remedy available
          to us, we may terminate your use of the Site or Service with
          immediate effect by giving notice to you if:
        </p>
        <p style="padding-left: 40px;">
          13.3.1. you fail to pay any
          amount due to us under our agreement with you on the due
          date for payment and remain in default more than seven (7)
          days after being notified to make such payment;
        </p>
        <p style="padding-left: 40px;">
          13.3.2. you commit a material
          breach of any term of this agreement and (if such breach is
          remediable) fail to remedy that breach within a period of
          seven (7) days after being notified to do so; or
        </p>
        <p style="padding-left: 40px;">
          13.3.3. you repeatedly breach any
          of the terms of this agreement.
        </p>
        <p>
          13.4. If we determine, in our sole discretion, that your use
          of the Site and/or the Services is in breach of any
          applicable law or regulation, we may terminate your use of
          the Site and the Services, without notice, in our sole
          discretion.
        </p>
        <p>
          13.5. If we terminate or suspend your account for any reason
          set out in this Section 12, you are prohibited from
          registering and creating a new account under your name, a
          fake or borrowed name, or the name of any third party, even
          if you may be acting on behalf of the third party. In
          addition to terminating or suspending your account, we
          reserve the right to take appropriate legal action,
          including without limitation pursuing civil, criminal, and
          injunctive redress.
        </p>
        <p><strong>14. Mobile Application</strong></p>
        <p>
          14.1. If you download or stream the App onto any phone or
          other device not owned by you, you must have the owner's
          permission to do so. You will be responsible for complying
          with these terms, whether or not you own the phone or other
          device.
        </p>
        <p>
          14.2. The following terms apply when you use a mobile
          application obtained from either the Apple Store or Google
          Play (each an <strong>App Distributor</strong>) to access
          the Services:
        </p>
        <p style="padding-left: 40px;">
          14.2.1. The licence granted to
          you for our mobile application is limited to a
          non-transferable licence to use the application on a device
          that utilizes the Apple iOS or Android operating system, as
          applicable, and in accordance with the usage rules set forth
          in the applicable App Distributor terms of service;
        </p>
        <p style="padding-left: 40px;">
          14.2.2. We are responsible for
          providing any maintenance and support services with respect
          to the mobile application as specified in these Terms of Use
          or as otherwise required under applicable law. You
          acknowledge that each App Distributor has no obligation
          whatsoever to furnish any maintenance and support services
          with respect to the mobile application;
        </p>
        <p style="padding-left: 40px;">
          14.2.3. In the event of any
          failure of the mobile application to conform to any
          applicable warranty, you may notify an App Distributor, and
          the App Distributor, in accordance with its terms and
          policies, may refund the purchase price, if any, paid for
          the mobile application, and to the maximum extent permitted
          by applicable law, an App Distributor will have no other
          warranty obligation whatsoever with respect to the mobile
          application;
        </p>
        <p style="padding-left: 40px;">
          14.2.4. You represent and warrant
          that (i) you are not located in a country that is subject to
          a U.S. government embargo, or that has been designated by
          the U.S. government as a “terrorist supporting” country, and
          (ii) you are not listed on any U.S. government list of
          prohibited or restricted parties;
        </p>
        <p style="padding-left: 40px;">
          14.2.5. You must comply with
          applicable third-party terms of agreement when using the
          mobile application, e.g., if you have a VoIP application,
          then you must not be in breach of their wireless data
          service agreement when using the mobile application; and
        </p>
        <p style="padding-left: 40px;">
          14.2.6. You acknowledge and agree
          that the App Distributors are third party beneficiaries of
          these Terms of Use, and that each App Distributor will have
          the right (and will be deemed to have accepted the right) to
          enforce these Terms of Use against you as a third-party
          beneficiary thereof.
        </p>
        <p><strong>15. General</strong></p>
        <p>
          15.1. Visiting the Site, sending us emails, and completing
          online forms constitute electronic communications. You
          consent to receive electronic communications and you agree
          that all agreements, notices, disclosures, and other
          communications we provide to you electronically, via email
          and on the Site, satisfy any legal requirement that such
          communication be in writing.
        </p>
        <p>
          15.2. You hereby agree to the use of electronic signatures,
          contracts, orders and other records and to electronic
          delivery of notices, policies and records of transactions
          initiated or completed by us or via the Site. You hereby
          waive any rights or requirements under any statutes,
          regulations, rules, ordinances or other laws in any
          jurisdiction which require an original signature or delivery
          or retention of non-electronic records, or to payments or
          the granting of credits by other than electronic means.
        </p>
        <p>
          15.3. These Terms of Use and any policies or operating rules
          posted by us on the Site or in respect to the Services
          constitute the entire agreement and understanding between
          you and us.
        </p>
        <p>
          15.4. Our failure to exercise or enforce any right or
          provision of these Terms of Use shall not operate as a
          waiver of such right or provision.
        </p>
        <p>
          15.5. We may assign any or all of our rights and obligations
          to others at any time.
        </p>
        <p>
          15.6. We shall not be responsible or liable for any loss,
          damage, delay or failure to act caused by any cause beyond
          our reasonable control (such as natural disaster, fire,
          strike or pandemic).
        </p>
        <p>
          15.7. If any provision or part of a provision of these Terms
          of Use is unlawful, void or unenforceable, that provision or
          part of the provision is deemed severable from these Terms
          of Use and does not affect the validity and enforceability
          of any remaining provisions.
        </p>
        <p>
          15.8. There is no joint venture, partnership, employment or
          agency relationship created between you and us as a result
          of these Terms of Use or use of the Site or Services.
        </p>
        <p>
          <span style="color: #c9f;">15.9. For consumers only -
            Please note that these Terms of Use, their subject
            matter and their formation, are governed by English law.
            You and we both agree that the courts of England and
            Wales will have exclusive jurisdiction except that if
            you are a resident of Northern Ireland you may also
            bring proceedings in Northern Ireland, and if you are
            resident of Scotland, you may also bring proceedings in
            Scotland. If you have any complaint or wish to raise a
            dispute under these Terms of Use or otherwise in
            relation to the Site please follow this link <a
              href="http://ec.europa.eu/odr"
              style="color: #c9f;"
              target="_blank"
            >http://ec.europa.eu/odr</a></span>
        </p>
        <p>
          <span style="color: #396;">15.10. For business users only
            - If you are a business user, these Terms of Use, their
            subject matter and their formation (and any
            non-contractual disputes or claims) are governed by
            English Law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.</span>
        </p>
        <p>
          15.11. Except as stated under the Mobile Application section,
          a person who is not a party to these Terms of Use shall have
          no right under the Contracts (Rights of Third Parties) Act
          1999 to enforce any term of these Terms of Use.
        </p>
        <p>
          15.12. In order to resolve a complaint regarding the Services
          or to receive further information regarding the use of the
          Services, please contact us by email at <a
            href="https://www.meavitae.com/cdn-cgi/l/email-protection"
            class="__cf_email__"
            data-cfemail="aecadec1eec3cbcfd8c7dacfcb80cdc1c3"
            target="_blank"
          >[email&#160;protected]</a>
          or by post to:
        </p>
        <p>
          The Data Protection Officer<br>MeaVitae Limited<br>1a The
          Quadrant Courtyard<br>Quadrant Way<br>Weybridge, KT13
          8DR<br>United Kingdom
        </p>

        <e-input
          :value="iAgreeToMeavitaeTermsAndConditions"
          type="checkbox"
          label="I agree to MeaVitae's Terms and Conditions"
          class="agree-terms-checkbox"
          data-test="agree-terms-checkbox"
          @change="value => iAgreeToMeavitaeTermsAndConditions = !!value"
        />
      </div>

      <e-button
        :disabled="!iAgreeToMeavitaeTermsAndConditions"
        @click="nextOnboardingStage"
      >
        Next
      </e-button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UserSetupTermsAndConditions',

  inheritAttrs: false,

  emits: [
    'next-stage'
  ],

  data () {
    return {
      iAgreeToMeavitaeTermsAndConditions: false
    }
  },

  mounted () {
    document
      .getElementById('terms-and-conditions-text')
      ?.addEventListener('scroll', this.bottomOfTermsAndConditionsReached)
  },

  beforeDestroy () {
    document
      .getElementById('terms-and-conditions-text')
      ?.removeEventListener('scroll', this.bottomOfTermsAndConditionsReached)
  },

  methods: {
    bottomOfTermsAndConditionsReached ({ target: { scrollHeight, scrollTop, clientHeight } }) {
      if (!(scrollHeight - scrollTop - clientHeight)) {
        this.agreeButtonDisabled = false
      }
    },

    nextOnboardingStage () {
      this.$emit('next-stage', true)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: auto;
  height: 80vh;

  button {
    text-transform: uppercase;
    width: 100%;
  }

  .terms-and-conditions {
    align-self: stretch;
    overflow-y: scroll;
    scroll-behavior: smooth;

    a {
      color: $link-color;
      text-decoration: none;

      &:hover {
        color: $link-hover-color;
      }
    }

    .agree-terms-checkbox {
      display: block;
      margin: 2em 0;
    }

    h2 {
      margin-bottom: 1em;
    }
  }
}
</style>
