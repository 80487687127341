<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Guardians

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(67)"
        />
      </h1>
    </div>

    <p>
      A guardian is someone appointed to have parental responsibility for
      any of your children, should both parents die.
    </p>

    <e-select
      v-if="!maxGuardiansReached"
      :items="eligiblePotentialGuardians"
      default-option-text="Add Guardian"
      data-test="eligible-guardians-select"
      @change="addGuardian"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="guardians.length">
        <legend>Guardians</legend>

        <ul data-test="guardian-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in guardians" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-guardian-button"
                @click="removeGuardian(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>

    <e-select
      v-if="!maxSubstituteGuardiansReached"
      :items="eligiblePotentialSubstituteGuardians"
      default-option-text="Add Substitute Guardian"
      data-test="eligible-substitute-guardians-select"
      @change="addSubstituteGuardian"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="substituteGuardians.length">
        <legend>Substitute Guardians</legend>

        <ul data-test="substitute-guardian-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in substituteGuardians" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-substitute-guardian-button"
                @click="removeSubstituteGuardian(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>

    <p>
      Add one substitute guardian for your children by selecting a contact from your address book
    </p>

    <p>
      If no guardian has been appointed by the parents or the named guardian
      is unable to take on the responsibilities and the parents have not named
      a substitute then the courts will decide how best to proceed to maintain
      the welfare of the children.
    </p>
  </div>
</template>

<script>
import Contact from '@/models/Contact'
import Group from '@/models/Group'
import GuardianContact from '@/models/GuardianContact'
import SubstituteGuardianContact from '@/models/SubstituteGuardianContact'
import Will from '@/models/Will'
import { getAge } from '@/utils'

export default {
  name: 'Guardians',

  computed: {
    will () {
      return Will
        .query()
        .with('children')
        .with('guardians')
        .with('substituteGuardians')
        .first()
    },

    guardians () {
      return this.will.guardians
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    substituteGuardians () {
      return this.will.substituteGuardians
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    eligibleContactsAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .map(({ id, selectText }) => ({
          value: id,
          text: selectText,
          isDisabled: this.isDisabled(id)
        }))
    },

    eligiblePotentialGuardians () {
      return this.eligibleContactsAsSelectOptions
        .map(option =>
          this.substituteGuardians.some(({ id }) => id === option.value)
            ? { ...option, isDisabled: true }
            : option
        )
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    eligiblePotentialSubstituteGuardians () {
      return this.eligibleContactsAsSelectOptions
        .map(option =>
          this.guardians.some(({ id }) => id === option.value)
            ? { ...option, isDisabled: true }
            : option
        )
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    maxGuardiansReached () {
      const max = Group
        .all()
        .find(({ name }) => name === 'guardians')
        .maxContacts

      return this.guardians.length >= max
    },

    maxSubstituteGuardiansReached () {
      const max = Group
        .all()
        .find(({ name }) => name === 'substituteGuardians')
        .maxContacts

      return this.substituteGuardians.length >= max
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 67,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    isDisabled (contactId) {
      return [
        { id: this.will.partnerId },
        ...Contact.all()
          .filter(({ type }) => type === 'person')
          .filter(({ dateOfBirth }) => getAge(dateOfBirth) < 18),
        ...this.will.children,
        ...this.will.guardians,
        ...this.will.substituteGuardians
      ]
        .map(({ id }) => id)
        .includes(contactId)
    },

    async addGuardian (contactId) {
      GuardianContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['guardianContacts'],
        message: 'Saved'
      })
    },

    async addSubstituteGuardian (contactId) {
      SubstituteGuardianContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['substituteGuardianContacts'],
        message: 'Saved'
      })
    },

    async removeGuardian (contactId) {
      GuardianContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['guardianContacts'],
        message: 'Saved'
      })
    },

    async removeSubstituteGuardian (contactId) {
      SubstituteGuardianContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['substituteGuardianContacts'],
        message: 'Saved'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-column-gap: 1%;
  grid-row-gap: 5%;
  grid-template-columns: 78% 21%;
  grid-template-rows: auto;
}
</style>
