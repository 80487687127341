<template>
  <div>
    <transition
      v-if="!credentialId"
      appear
      enter-active-class="animate__animated animate__fadeIn animate__faster"
    >
      <div>
        <h2>Register New Security Key</h2>

        <ul>
          <li>Unplug all security keys from your device that you do not want to create this new registration on</li>
          <li>Sync only the new security key you do wish to register, by plugging into the usb slot of your computer and click 'REGISTER' to begin this process</li>
          <li>After registering your new security key, we will then check it has stored our details correctly</li>
        </ul>

        <img
          src="../assets/security_key.png"
          alt="Authenticator Security Key"
        >

        <p>On the next screen, please use the same PIN if this key has been registered or used with MeaVitae before</p>

        <e-button
          class="register-button"
          data-test="register-button"
          @click="registerSecurityKey"
        >
          Register
        </e-button>
      </div>
    </transition>

    <transition
      v-else
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <div>
        <h2>Check New Security Key Registered</h2>

        <img
          src="../assets/security_key.png"
          alt="Authenticator Security Key"
        >

        <p>We are now going to check that your security key has setup correctly</p>

        <e-button
          class="register-button"
          data-test="check-registration-button"
          @click="checkSecurityKeyRegistered"
        >
          Check Registration
        </e-button>
      </div>
    </transition>
  </div>
</template>

<script>
import checkUserHandleSuccessfullyStoredOnSecurityKey from '@/auth/checkUserHandleSuccessfullyStoredOnSecurityKey'
import convertArrayBufferToBase64Block from '@/utils/convertArrayBufferToBase64Block'
import createPublicKeyCredential from '@/auth/createPublicKeyCredential'
import createSecurityKeyId from '@/auth/createSecurityKeyId'

export default {
  name: 'SecurityKeyRegistration',

  inheritAttrs: false,

  props: {
    envelopeKey: {
      type: String,
      default: ''
    },

    rpId: {
      type: String,
      default: ''
    },

    challenge: {
      type: String,
      default: ''
    },

    securityKeyObjects: {
      type: Array,
      default: () => ([])
    },

    userId: {
      type: String,
      default: ''
    },

    userEmailAddress: {
      type: String,
      default: ''
    }
  },

  emits: [
    'error-message',
    'save-security-key'
  ],

  data () {
    return {
      credentialId: '',
      securityKeyId: '',
      userHandle: ''
    }
  },

  computed: {
    existingSecurityKeyIds () {
      return this.securityKeyObjects
        .filter(({ securityKeyId }) => securityKeyId)
        .map(({ securityKeyId }) => securityKeyId)
    },

    existingSecurityKeyCredentialIds () {
      return this.securityKeyObjects
        .map(({ credentialId }) => credentialId)
    }
  },

  created () {
    if (
      !this.envelopeKey ||
      !this.rpId ||
      !this.challenge ||
      !this.userEmailAddress ||
      !this.userId
    ) this.$emit('error-message', 'Insufficient data has been provided to begin the security key registration')
  },

  methods: {
    async registerSecurityKey () {
      try {
        this.securityKeyId = createSecurityKeyId(this.existingSecurityKeyIds)
        this.userHandle = `${this.envelopeKey}:${this.securityKeyId}`

        const { rawId } = await createPublicKeyCredential({
          userHandle: this.userHandle,
          rpId: this.rpId,
          challenge: this.challenge,
          email: this.userEmailAddress,
          userId: this.userId,
          excludeCredentialIds: this.existingSecurityKeyCredentialIds
        })

        this.credentialId = convertArrayBufferToBase64Block(rawId)
        if (!this.credentialId) throw new Error('Failed to create credentialId')
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeyRegistration',
          functionName: 'registerSecurityKey'
        })

        this.$emit('error-message', 'Your security key failed to register')
      }
    },

    async checkSecurityKeyRegistered () {
      try {
        await checkUserHandleSuccessfullyStoredOnSecurityKey({
          rpId: this.rpId,
          credentialId: this.credentialId,
          challenge: this.challenge,
          userHandle: this.userHandle
        })

        this.$emit('save-security-key', {
          credentialId: this.credentialId,
          securityKeyId: this.securityKeyId
        })
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeyRegistration',
          functionName: 'checkSecurityKeyRegistered'
        })

        this.$emit('error-message', 'Your security key failed to register')
      }
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  padding: 20px 0;
}

img {
  display: block;
  margin: 10px auto;
}

p {
  color: $color-warning;
  display: block;
  margin-top: 2em;
  text-align: center;
}

.register-button {
  margin-top: 1em;
  text-transform: uppercase;
  width: 100%;
}

ul {
  list-style: disc;

  li {
    margin: 10px 30px;
  }
}
</style>
