<template>
  <div class="action-objects">
    <file-progress-element
      v-for="fileActionObject in fileActionObjects"
      :key="fileActionObject.id"
      :file-action-object="fileActionObject"
    />

    <gdpr-progress-element v-if="showGdprProgressElement" />
  </div>
</template>

<script>
import FileProgressElement from '@/components/FileProgressElement'
import GdprProgressElement from '@/components/GdprProgressElement'
import { mapState } from 'vuex'

export default {
  name: 'ActionObjects',

  components: {
    FileProgressElement,
    GdprProgressElement
  },

  computed: {
    ...mapState('files', [
      'fileActionObjects'
    ]),

    showGdprProgressElement () {
      return this.$store.state.gdpr.showProgressElement
    }
  }
}
</script>

<style lang="scss" scoped>
.action-objects {
  bottom: 1vh;
  display: grid;
  left: 50%;
  position: fixed;
  row-gap: 0.4rem;
  transform: translateX(-50%);
  z-index: 6;
}
</style>
