import ProductsByCategory from '@/views/Files/ProductsByCategory'
import Vue from 'vue'
import makeOrder from '../apis/marketplace/newOrder'
import cancel from '../apis/marketplace/cancelOrder'
import status from '../apis/marketplace/status'
import getMarketplaceVendorOrders from '../apis/marketplace/myOrders'
import doVendorRefund from '../apis/wallet/vendorRefundPurchase'
import encryptString from '../utils/encryptString'
import signString from '../utils/signString'
import attemptApiCall from '../utils/attemptApiCall'
import getPurchases from '../apis/wallet/getPurchasesForUserId'
import refundWalletPurchase from '../apis/wallet/refundPurchase'
import getMarketplaceProducts from '../apis/cms/getMarketplaceProducts'
import documentRouteMappings from '../json_files/documentTypes.json'

const documentProductRouteLookup = documentRouteMappings
  .reduce((accumulator, currentValue) => ({
    ...accumulator,
    ...currentValue.productId && { [currentValue.productId]: currentValue.routeName }
  })
  , {})

const createDynamicRoutes = ({ categories, router }) => {
  categories.forEach(category => {
    router.addRoute('Generators', {
      path: ':subCategoryId',
      name: category.id,
      props: true,
      component: ProductsByCategory,
      meta: { title: 'Generators' }
    })
  })
}

export default {
  namespaced: true,

  state: {
    orders: [],
    purchases: [],
    products: []
  },

  getters: {
    productLookup: state => state
      .products
      .flatMap(category => category.inventory)
      .reduce((accumulator, product) => {
        return {
          ...accumulator,
          [product.id]: {
            ...product,
            routeName: documentProductRouteLookup[product.id]
          }
        }
      }, {}),

    getPurchasedProduct: (state, getters, rootState, rootGetters) => (productId) => {
      const product = getters.productLookup[productId]
      if (!product) return

      return rootGetters.userIsAdmin
        ? product
        : state.purchases.find(purchase => purchase.itemId === productId) && product
    }
  },

  actions: {
    setOrders: async ({ commit }) => {
      try {
        const response = await getMarketplaceVendorOrders({
          jwt: sessionStorage.getItem('jwt')
        })
        commit('setOrders', response.data)
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }
        throw error
      }
    },

    cancelOrder: async ({ commit }, { orderId }) => {
      try {
        const response = await cancel({
          jwt: sessionStorage.getItem('jwt'),
          orderId
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    vendorRefundPurchase: async ({ commit }, refundObj) => {
      try {
        commit('setOrderToProcessing', refundObj.id)
        const response = await doVendorRefund({
          jwt: sessionStorage.getItem('jwt'),
          refundObj
        })
        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    refundPurchase: async ({ commit }, purchase) => {
      try {
        const response = await refundWalletPurchase({
          jwt: sessionStorage.getItem('jwt'),
          purchase
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getOrder: async ({ commit }, { orderId }) => {
      try {
        const response = await status({
          jwt: sessionStorage.getItem('jwt'),
          orderId
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    newOrder: async ({ commit, dispatch }, payload) => {
      const AiLetterOrder = !!payload.order.item.prompt
      try {
        const { data } = await makeOrder(
          {
            jwt: sessionStorage.getItem('jwt'),
            order: payload.order
          }
        )
        commit('snackbar/update', { type: 'success', message: 'Your Order has being accepted.' }, { root: true })
        if (payload.order.item.type === 'service') {
          payload.vendor = data.vendor
          await dispatch('sendMessageToVendor', payload)
          delete data.vendor
        }
        await dispatch('setPurchases')
        if (AiLetterOrder) return commit('setAiLetterResponse', data.payload)
        return data
      } catch (error) {
        console.error('MKT ERROR:', error)

        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        } else {
          commit('snackbar/update', {
            type: 'error',
            message: 'Please try your order again later.'
          }, { root: true })
          if (AiLetterOrder) {
            if (error && error.response.data.require) {
              return commit('marketplace/insufficientTokens', { requirement: error.response.data.require, tokenPriceMeta: error.response.data.tokenPriceMeta })
            }
          }
          throw error
        }
      }
    },

    setPurchases: async ({ state, rootState, commit }) => {
      try {
        const marketplacePurchases = (await attemptApiCall(
          getPurchases,
          { jwt: sessionStorage.getItem('jwt') }
        )).map(purchase => {
          const { routeName } = documentRouteMappings.find(documentRouteMap => {
            const purchasedProduct = state.products
              .flatMap(category => category.inventory)
              .find(product => product.id === purchase.itemId) || {}
            return purchasedProduct.id === documentRouteMap.productId ? documentRouteMap.routeName : null
          }) || {}
          return {
            ...purchase,
            routeName
          }
        })
        commit('setPurchases', marketplacePurchases)
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        throw error
      }
    },

    setCategoriesAndProducts: async ({ commit }, router) => {
      try {
        const marketplaceCategoriesAndProducts = await attemptApiCall(
          getMarketplaceProducts,
          { jwt: sessionStorage.getItem('jwt') }
        )
        commit('setCategoriesAndProducts', marketplaceCategoriesAndProducts)
        createDynamicRoutes({ categories: marketplaceCategoriesAndProducts, router })
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        throw error
      }
    },

    sendMessageToVendor: async ({ commit, dispatch }, payload) => {
      try {
        const messageBody = `Order placed for ${payload.order.item.title}`
        const body = await encryptString({
          unencryptedString: messageBody,
          publicKeyObjects: [
            { armoredPublicKey: payload.order.payer.armoredPublicKey },
            { armoredPublicKey: payload.vendor.armoredPublicKey }
          ],
          armoredPrivateKey: payload.pk
        })
        const sig = await signString(Date.now(), payload.pk)
        const messageObject = {
          to: [payload.vendor.id],
          subject: `New Service Order for ${payload.order.item.title}`,
          sig,
          body,
          attachments: []
        }
        await dispatch('messages/sendMessage', messageObject, { root: true })
        commit('snackbar/update', { type: 'success', message: 'Service Vendor has been notified and will contact you shortly.' }, { root: true })
      } catch (err) {
        console.error(err.toString())
        throw err
      }
    },

    sendMessageToClient: async ({ commit, dispatch }, payload) => {
      try {
        const body = await encryptString({
          unencryptedString: payload.messageBody,
          publicKeyObjects: [
            { armoredPublicKey: payload.client.armoredPublicKey },
            { armoredPublicKey: payload.vendor.armoredPublicKey }
          ],
          armoredPrivateKey: payload.pk // local user PrivateKey
        })
        const sig = await signString(Date.now(), payload.pk)
        const messageObject = {
          to: [payload.client.userId],
          subject: payload.subject,
          sig,
          body,
          attachments: []
        }
        await dispatch('messages/sendMessage', messageObject, { root: true })
        commit('snackbar/update', { type: 'success', message: 'A Secure Message has been sent to your Client and they have been notified.' }, { root: true })
      } catch (err) {
        console.error(err.toString())
        throw err
      }
    }
  },

  mutations: {
    setOrders: (state, value) => Vue.set(state, 'orders', value),
    setCategoriesAndProducts: (state, value) => Vue.set(state, 'products', value),
    setPurchases: (state, value) => Vue.set(state, 'purchases', value),
    setOrderToProcessing: (state, orderId) => Vue.set(state, 'orders', state.orders.map(order => {
      return order.id === orderId ? { ...order, status: 'processing' } : order
    })),
    setAiLetterResponse: (state, value) => Vue.set(state, 'aiLetterResponse', value)
  }
}
