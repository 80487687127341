'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ messageObject, jwt }) => {
  const url = messageObject.id
    ? `${VUE_APP_COMMS_URL}/drafts/${messageObject.id}`
    : `${VUE_APP_COMMS_URL}/drafts`

  return axios({
    method: 'put',
    headers: {
      Authorization: jwt
    },
    url,
    data: messageObject,
    timeout: 8000
  })
}
