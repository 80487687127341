'use strict'

import axios from 'axios'

const { VUE_APP_API_URL } = process.env

export default async ({ userId, jwt }) => axios({
  method: 'get',
  headers: { Authorization: jwt },
  url: `${VUE_APP_API_URL}/${userId}`,
  timeout: 8000
})
