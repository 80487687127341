<template>
  <div class="main-element">
    <div class="header">
      <h1>{{ item.parentCategory.title }} Service from {{ item.vendor.name }}</h1>

      <e-button
        class="close-button icon-x-circle ghost"
        @click="$emit('closed')"
      />
    </div>

    <div class="body">
      <img :alt="item.title" :src="item.imageUrl || '../../default_thumbnail.png'">

      <div class="info">
        <h1>
          {{ item.title }}
        </h1>

        <div class="cost-and-button">
          <div class="category">
            <span>Category: </span>{{ item.parentCategory.title }}
          </div>
          <div class="category">
            <span>Cost:</span> {{ item.price }} MVT
          </div>
        </div>
      </div>
    </div>

    <component :is="html" class="item-description" />
  </div>
</template>

<script>
import portableTextToHtml from '@/utils/portableTextToHtml'

export default {
  name: 'ServiceInfoElement',

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    html () {
      return {
        template: portableTextToHtml(this.item.description)
      }
    }
  } // ,

  // methods: {
  //   async bookService () {
  //     await this.$store.dispatch('newOrder')
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.main-element {
  display: grid;
  row-gap: 1rem;

  h1 {
    padding-bottom: 1rem;
  }

  .close-button {
    align-self: start;
    padding: 0;
  }

  .body {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr;

    .info {
      display: grid;

      .cost-and-button {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .book-button-container {
          justify-self: end;

          .book-button {
            background-color: $color-success;
            border: 0;
            color: $font-color-two;
            font-size: 1.4rem;
            font-weight: bold;
            height: 2.6rem;
            padding: 0 1.6rem;
            text-transform: capitalize;
          }
        }
      }
    }

    .mvt-price {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}
</style>
