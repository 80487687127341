'use strict'

import axios from 'axios'

const { VUE_APP_CONTENT_URL } = process.env

export default async ({ jwt, articleId }) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: jwt
    },
    url: `${VUE_APP_CONTENT_URL}/kb/id/${articleId}`,
    timeout: 8000
  })
}
