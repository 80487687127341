<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Contingency

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(75)"
        />
      </h1>
    </div>

    <p>
      Considering the contingency provision for your Residuary Estate
      is an important step in your estate planning.
    </p>

    <p>
      Failure to consider the possibility that all other clauses in your will
      might fail means you could die intestate and the courts would then apply
      the basic 'Rules of Intestacy' to determine who should receive your
      remaining assets. This could mean that people you did not intend receive
      your assets and others you did intend receive nothing.
    </p>

    <beneficiaries-manager
      :beneficiary-ids="beneficiaryIds"
      @add-beneficiary="addBeneficiary"
      @remove-beneficiary="removeBeneficiary"
    />
  </div>
</template>

<script>
import Beneficiary from '@/models/Beneficiary'
import BeneficiariesManager from '@/components/BeneficiariesManager'
import ResiduaryEstateBeneficiary from '@/models/ResiduaryEstateBeneficiary'
import Will from '@/models/Will'

export default {
  name: 'Provisions',

  components: {
    BeneficiariesManager
  },

  computed: {
    will () {
      return Will
        .query()
        .first()
    },

    beneficiaryIds () {
      return ResiduaryEstateBeneficiary
        .query()
        .where('willId', this.will.id)
        .get()
        .map(({ beneficiaryId }) => beneficiaryId)
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 75,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async addBeneficiary (beneficiaryObject) {
      const newBeneficiary = await Beneficiary.new()

      Beneficiary.update({
        where: newBeneficiary.id,
        data: beneficiaryObject
      })

      ResiduaryEstateBeneficiary.insert({
        data: {
          willId: this.will.id,
          beneficiaryId: newBeneficiary.id
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'residuaryEstateBeneficiaries'],
        message: 'Beneficiary successfully added'
      })
    },

    async removeBeneficiary (beneficiaryId) {
      ResiduaryEstateBeneficiary.delete([this.will.id, beneficiaryId])
      Beneficiary.delete(beneficiaryId)

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'residuaryEstateBeneficiaries'],
        message: 'Beneficiary successfully removed'
      })
    }
  }
}
</script>
