<template>
  <div>
    <div class="top-inputs">
      <h1 class="title">
        {{ title }}

        <span
          v-if="jsonFile.defaultHelpId"
          class="icon-help-circle"
          @click.prevent="setHelp(jsonFile.defaultHelpId)"
        />
      </h1>
    </div>

    <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
      <form
        id="dynamic-form"
        class="spaced"
        @submit.prevent="handleSubmit(submitted)"
      >
        <div v-html="header" />

        <div v-for="question in jsonFile.questions" :key="question.id">
          <dynamic-fieldset
            v-if="question.type === 'fieldset'"
            :fieldset="question"
            :dynamic-properties="dynamicProperties"
            :value="dynamicProperties[question.propertyName]"
            :get-input-type="getInputType"
            :help="jsonFile.help"
            @update="({ value, propertyName }) => dynamicProperties[propertyName] = value"
          />

          <component
            :is="getInputType(question.type)"
            v-else
            :question="question"
            :value="dynamicProperties[question.propertyName]"
            :validation-rules="question.validationRules"
            :help-object="getHelpObject(question.helpId)"
            @update="({ value, propertyName }) => dynamicProperties[propertyName] = value"
          />
        </div>

        <div v-html="footer" />
      </form>

      <save-bar :form-is-valid="formIsValid">
        <submit-button
          :form-is-valid="formIsValid"
          form="dynamic-form"
          button-text="Preview"
        />
      </save-bar>
    </validation-observer>
  </div>
</template>

<script>
import DynamicFieldset from './DynamicFieldset'
import DynamicInput from './DynamicInput'
import DynamicRadioGroup from './DynamicRadioGroup'
import DynamicSelect from './DynamicSelect'
import DynamicTextarea from './DynamicTextarea'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'

export default {
  name: 'DynamicForm',

  components: {
    DynamicFieldset,
    DynamicRadioGroup,
    DynamicTextarea,
    DynamicInput,
    DynamicSelect,
    SaveBar,
    SubmitButton
  },

  props: {
    jsonFile: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data () {
    return {
      dynamicProperties: this.getDynamicProperties(this.jsonFile)
    }
  },

  computed: {
    title () {
      return this.jsonFile.title
    },

    header () {
      return this.purify(this.jsonFile.header)
    },

    footer () {
      return this.purify(this.jsonFile.footer)
    }
  },

  watch: {
    'jsonFile.questions' () {
      this.dynamicProperties = this.getDynamicProperties(this.jsonFile)
    }
  },

  created () {
    if (this.jsonFile.defaultHelpId) {
      this.setHelp(this.jsonFile.defaultHelpId)
    }
  },

  methods: {
    getHelpObject (helpId) {
      return this.jsonFile.help.find(({ id }) => id === helpId)
    },

    getDynamicProperties (jsonFile) {
      return jsonFile
        .questions
        .reduce((accumulator, { propertyName, type, defaultValue, questions }) => type === 'fieldset'
          ? {
              ...accumulator,
              ...questions.reduce((accumulator2, { propertyName, defaultValue }) =>
                ({ ...accumulator2, [propertyName]: defaultValue || '' }), {})
            }
          : {
              ...accumulator,
              [propertyName]: defaultValue || ''
            }
        , {})
    },

    setHelp (html) {
      this.$store.dispatch('findAndSetHelpObject', { html })
    },

    getInputType (type) {
      return {
        radioGroup: 'DynamicRadioGroup',
        select: 'DynamicSelect',
        textarea: 'DynamicTextarea',
        textInput: 'DynamicInput',
        numberInput: 'DynamicInput'
      }[type]
    },

    submitted () {
      this.$emit('submit', this.dynamicProperties)
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
