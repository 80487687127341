<template>
  <div>
    <e-button
      data-test="add-license-button"
      @click="addLicense(licenses)"
    >
      <span class="icon-plus" />

      Add License
    </e-button>

    <fieldset v-if="licenses.length">
      <legend>Licenses</legend>

      <table data-test="licenses-table">
        <thead>
          <tr>
            <th>Type</th>

            <th>Notes (Number)</th>

            <th>Expiry Date</th>

            <th>Country</th>

            <th class="center">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(license, index) in licenses"
            :key="license.id"
          >
            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${license.id}-license-type`"
                name="License Type"
                :rules="{ required: true }"
                tag="div"
              >
                <e-input
                  :value="licenses[index].type"
                  :error-messages="errors"
                  :success="valid"
                  data-test="license-type-input"
                  @input="updateLicense(licenses, license.id, 'type', $event)"
                />
              </validation-provider>
            </td>

            <td>
              <e-input
                :value="licenses[index].notes"
                data-test="license-notes-input"
                @input="updateLicense(licenses, license.id, 'notes', $event)"
              />
            </td>

            <td>
              <e-input
                :value="licenses[index].expiryDate"
                type="date"
                data-test="license-expiry-date-input"
                @input="updateLicense(licenses, license.id, 'expiryDate', $event)"
              />
            </td>

            <td>
              <e-select
                :value="licenses[index].countryIsoCode"
                :items="countrySelectOptions"
                default-option-text="Select Country"
                data-test="license-country-code-select"
                @input="updateLicense(licenses, license.id, 'countryIsoCode', $event)"
              />
            </td>

            <td class="actions">
              <e-button
                class="ghost"
                data-test="remove-license-button"
                @click="removeLicense(licenses, license.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import countries from '@/json_files/countries.json'
import { createId } from '@/utils'

export default {
  name: 'UserLicensesManager',

  props: {
    licenses: {
      type: Array,
      default: () => []
    }
  },

  emits: ['is-dirty'],

  computed: {
    countrySelectOptions () {
      return countries.map(({ name, code }) => ({
        value: code,
        text: name
      }))
    }
  },

  methods: {
    addLicense (licenses) {
      licenses.push({ id: createId() })
      this.$emit('is-dirty')
    },

    updateLicense (licenses, licenseId, key, value) {
      licenses.find(({ id }) => id === licenseId)[key] = value
      this.$emit('is-dirty')
    },

    removeLicense (licenses, licenseId) {
      const index = licenses.findIndex(({ id }) => id === licenseId)
      licenses.splice(index, 1)
      this.$emit('is-dirty')
    }
  }
}
</script>

<style scoped lang="scss">
button {
  margin-bottom: 0.8rem;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
