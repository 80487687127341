<template>
  <div>
    <e-button
      type="button"
      data-test="refer-a-friend-button"
      @click="showReferAFriendDialog = true"
    >
      Invite
    </e-button>

    <e-dialog v-if="showReferAFriendDialog" data-test="refer-a-friend-dialog">
      <div class="header">
        <h1>Refer A Friend To Meavitae</h1>

        <e-button
          class="icon-x-circle ghost"
          data-test="close-refer-a-friend-dialog-button"
          @click="showReferAFriendDialog = false"
        />
      </div>

      <p>
        Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae.
      </p>

      <validation-observer
        v-slot="{ handleSubmit, valid: formIsValid }"
        slim
      >
        <form
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <div class="connect-input">
            <div class="recipients-element">
              <h2>Recipients</h2>

              <div class="box">
                <e-button
                  v-if="!showAddRecipientList && eligibleRecipientsAsSelectOptions.length"
                  class="add-recipient person-button ghost"
                  data-test="add-recipient-button"
                  @click="showAddRecipientList = true"
                >
                  <span class="icon-plus" />
                </e-button>

                <ul data-test="current-recipients">
                  <li
                    v-for="recipient in recipients"
                    :key="recipient.id"
                  >
                    <span>
                      <e-button
                        class="delete-icon person-button ghost"
                        data-test="remove-recipient-button"
                        @click="removeRecipient(recipient.id)"
                      >
                        <span data-test="recipient-name">
                          {{ recipient.fullName }}
                        </span>

                        <span class="icon-trash-2" />
                      </e-button>
                    </span>
                  </li>
                </ul>
              </div>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Eligible Recipient"
                :rules="{ array_length: true }"
                tag="div"
              >
                <input
                  v-model="recipients.length"
                  type="hidden"
                >

                <e-select
                  v-if="showAddRecipientList"
                  :items="eligibleRecipientsAsSelectOptions"
                  default-option-text="Select a contact to add"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  class="add-contact-select"
                  data-test="contact-select"
                  @change="addRecipient"
                />
              </validation-provider>
            </div>
          </div>

          <template v-if="showFormTextFields">
            <e-input
              :value="subject"
              :disabled="true"
              label="Subject"
              data-test="subject-input"
            />

            <validation-provider
              v-slot="{ errors, valid }"
              name="Message"
              rules="required"
              tag="div"
            >
              <e-textarea
                v-model="message"
                label="Message To Recipients"
                rows="12"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
              />
            </validation-provider>
          </template>

          <e-button
            :disabled="!formIsValid"
            type="submit"
            class="submit-button"
          >
            Send Referrals
          </e-button>
        </form>
      </validation-observer>
    </e-dialog>
  </div>
</template>

<script>
import Contact from '@/models/Contact'
import EDialog from '@/components/dialogs/EDialog'
import attemptApiCall from '@/utils/attemptApiCall'
import getReferAFriendMessageTemplate from '@/apis/cms/getReferAFriendMessageTemplate'
import postReferAFriendInvitations from '@/apis/comms/postReferAFriendInvitations'

export default {
  name: 'ReferAFriendDialog',

  components: {
    EDialog
  },

  props: {
    preSelectedRecipients: {
      type: Array,
      default: () => ([])
    }
  },

  emits: ['delete-confirmed'],

  data () {
    return {
      showReferAFriendDialog: false,
      showAddRecipientList: false,
      mutableSelectedRecipients: [],
      showFormTextFields: false,
      subject: '',
      message: ''
    }
  },

  computed: {
    eligibleRecipients () {
      return Contact
        .query()
        .get()
        .filter(({ type }) => type === 'person')
        .flatMap(({ id, fullName, firstName, lastName, emailAddresses }) => emailAddresses
          .sort((a, b) => b.isPref - a.isPref)
          .reduce((accumulator, emailAddress) => [
            ...accumulator,
            { id, fullName, firstName, lastName, email: emailAddress.value }
          ], [])?.[0] || []
        )
    },

    recipients () {
      return this.mutableSelectedRecipients
        .map(contact => ({ ...contact, contactId: contact.id }))
    },

    recipientIds () {
      return this.recipients
        .map(({ id }) => id)
    },

    eligibleRecipientsAsSelectOptions () {
      return this.eligibleRecipients
        .filter(({ id }) => !this.recipientIds.includes(id))
        .map(({ id, fullName }) => ({ value: id, text: fullName }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  watch: {
    async showReferAFriendDialog (value) {
      if (value) {
        this.mutableSelectedRecipients = this.eligibleRecipients
          .filter(({ id }) => this.preSelectedRecipients.includes(id))

        if (!this.subject || !this.message) await this.getAndSetReferAFriendMessageTemplateData()
      }
    }
  },

  methods: {
    async getAndSetReferAFriendMessageTemplateData () {
      const { subject, message } = await attemptApiCall(getReferAFriendMessageTemplate, sessionStorage.getItem('jwt'))
      this.subject = subject
      this.message = message
      this.showFormTextFields = true
    },

    addRecipient (contactId) {
      this.mutableSelectedRecipients.push(this.eligibleRecipients
        .find(({ id }) => id === contactId)
      )
    },

    removeRecipient (contactId) {
      this.mutableSelectedRecipients = this.mutableSelectedRecipients.filter(({ id }) => id !== contactId)
    },

    async submit () {
      try {
        await attemptApiCall(
          postReferAFriendInvitations,
          {
            payload: {
              invitees: this.recipients,
              body: this.message
            },
            jwt: sessionStorage.getItem('jwt')
          }
        )

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Refer a friend invitations successfully sent'
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'The refer a friend invitations failed to send'
        })
      } finally {
        this.showReferAFriendDialog = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 0.4rem;
}

.recipients-element {
  width: 60%;
}

.delete-icon {
  color: $input-font-color;
}

.add-contact-select {
  margin-top: 1em;
}

.box {
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $corner;
  color: $input-font-color;
  min-height: 3.2rem;
  padding: 5px 0;
}

.submit-button {
  margin-top: 1em;
  text-transform: uppercase;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0.2rem 0.4rem;

  li {
    display: inline;

    span {
      white-space: nowrap;
    }

    button {
      padding: 0 0 0 0.2rem;
    }

    .recipient {
      border: solid 1px black;
    }
  }
}
</style>
