'use strict'

import axios from 'axios'

export default async symbol => {
  return axios({
    method: 'GET',
    url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-financials',
    headers: {
      'x-rapidapi-key': '46ef6e23d4msh0fb4a1574b4c7efp13de38jsnbf6357b0c78c',
      'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com'
    },
    params: { symbol }
  })
}
