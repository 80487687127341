<template>
  <div>
    <h1>Membership</h1>

    <article>
      <section>
        <header>{{ user.fullName }}</header>

        <dl>
          <div v-for="emailAddress in user.emailAddresses" :key="emailAddress.id">
            <dt>Email:</dt>
            <dd>{{ emailAddress.value }}</dd>
          </div>

          <div v-if="formattedBirthday">
            <dt>Date of Birth:</dt>
            <dd>{{ formattedBirthday }}</dd>
          </div>

          <div v-if="user.placeOfBirth">
            <dt>Place of birth:</dt>
            <dd>{{ user.placeOfBirth }}</dd>
          </div>

          <div v-if="gender">
            <dt>Gender:</dt>
            <dd>{{ gender }}</dd>
          </div>

          <div v-for="address in user.addresses" :key="address.id">
            <dt>Address:</dt>
            <dd>{{ address.placeResult.formatted_address }}</dd>
          </div>

          <div v-for="phoneNumber in phoneNumbers" :key="phoneNumber.id">
            <dt>Phone Number:</dt>
            <dd><span v-if="phoneNumber.type">({{ phoneNumber.type }})&nbsp;</span>{{ phoneNumber.value }}</dd>
          </div>
        </dl>
      </section>

      <section>
        <h2>Your Meavitae Membership Plan</h2>

        <p v-if="showUpgradeSubscriptionMessage">
          Your current membership plan does not have access to use this MeaVitae Feature. You can upgrade your membership by visiting...
        </p>

        <div class="subscription-container">
          <membership-plan
            v-for="subscriptionPlan in subscriptionPlans"
            :key="subscriptionPlan.id"
            :subscription-plan="subscriptionPlan"
            :is-user-membership="user.subscriptionProductId === subscriptionPlan.id"
          />
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import MembershipPlan from '@/components/MembershipPlan'
import { format } from 'date-fns'
import { genderTypeLookup, phoneNumberTypeLookup } from '@/utils'

export default {
  name: 'Membership',

  components: {
    MembershipPlan
  },

  props: {
    upgradeSubscriptionFeatureId: {
      type: Number,
      default: null
    }
  },

  computed: {
    user () {
      return this.$store.getters.user || {}
    },

    formattedBirthday () {
      return this.user.dateOfBirth
        ? format(new Date(this.user.dateOfBirth), 'MMMM d, Y')
        : null
    },

    gender () {
      return genderTypeLookup[this.user?.genderType]
    },

    phoneNumbers () {
      return this.user?.phoneNumbers
        .map(({ type, value }) => ({ type: phoneNumberTypeLookup[type], value }))
    },

    showUpgradeSubscriptionMessage () {
      return !!this.upgradeSubscriptionFeatureId
    },

    subscriptionPlans () {
      return this.$store.state.subscriptionPlans
        .filter(({ isListed, jurisdiction }) => (isListed && jurisdiction?.code === 'GB'))
    }
  }
}
</script>

<style scoped lang="scss">
section {
  margin: 2rem 0 0;

  h2 {
    margin: 1rem 0;
  }

  header {
    border-bottom: 2px solid $link-color;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  dl > div {
    column-gap: 0.2rem;
    display: grid;
    grid-template-columns: max-content auto;
    margin: 0.5rem 0;
  }

  dt {
    align-items: center;
    display: flex;
    font-weight: 400;
    grid-column-start: 1;
  }

  dd {
    align-items: center;
    display: flex;
    grid-column-start: 2;
  }

  dl {
    padding-left: 0.4rem;
  }
}

.subscription-container {
  display: grid;
  grid-gap: 1rem;
  justify-items: stretch;
}

@media screen and (min-width: $media-break-1) {
  .subscription-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
