<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Files
        </h1>

        <accordion
          :items="items"
          :display-is-valid="true"
          :display-sub-items-size="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded">
        <router-view
          :key="$route.fullPath"
          data-test="router-view"
          @set-active="setActiveCategory"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'
import DocumentRecord from '@/models/DocumentRecord'

export default {
  name: 'Files',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      active: '',
      activeCategory: ''
    }
  },

  computed: {
    documentRecords () {
      return DocumentRecord.all()
    },

    documents () {
      return {
        id: 'documents',
        text: 'Generated Documents',
        length: this.documentRecords.length,
        isActive: this.active === 'documents',
        sub: [{
          id: 'DocumentsTable',
          text: 'Contracts',
          routeName: 'DocumentsTable',
          paramKey2: 'subCategoryId'
        }]
      }
    },

    uploadedFiles () {
      return {
        id: 'uploads',
        text: 'Uploaded Files',
        isActive: this.active === 'uploads',
        sub: [{
          id: 'UploadedFiles',
          text: 'All',
          routeName: 'UploadedFiles',
          sub: []
        }]
      }
    },

    moreGenerators () {
      const prods = this.$store.state.marketplace.products
        .map(category => ({
          ...category,
          text: `${category.title} (${category.inventory.length})`,
          routeName: category.id,
          paramKey2: 'subCategoryId',
          inventory: category.inventory.map(i => i.isListed ? i : null)
            .filter(i => i)
        }))
        .filter(category => category.inventory.length > 0)
      return {
        id: 'generators',
        text: 'Generators',
        isActive: this.activeCategory === 'generators',
        length: prods.reduce((total, category) => category.inventory.length + total, 0),
        sub: prods.filter(({ text }) => this.filter
          ? text.toLowerCase().includes(this.filter.toLowerCase())
          : true
        )
          .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
      }
    },

    items () {
      return [
        this.documents,
        this.uploadedFiles,
        this.moreGenerators
      ]
    }
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    }
  }
}
</script>
