'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Note extends Model {
  static get entity () { return 'notes' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      title: this.string(),
      description: this.string(),
      createdDate: this.string()
    }
  }
}
