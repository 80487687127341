<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this bucket list item"
      @confirm="deleteBucketListItem"
      @cancel="showDeleteDialog = false"
    />

    <h1>
      {{ isExistingBucketListItem ? 'Edit' : 'Create' }} Bucket List Item

      <span
        class="icon-help-circle"
        @click.prevent="setHelp(132)"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="bucket-list-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="title"
            rules="required"
            slim
          >
            <e-input
              v-model="title"
              label="Title"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="133"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-textarea
            v-model="details"
            label="Details"
            @input="setIsDirty(true)"
          />

          <e-input
            v-model="targetDate"
            label="Target Date"
            type="date"
            @input="setIsDirty(true)"
          />

          <div class="connect-input">
            <e-input
              :value="isDone"
              label="Done !"
              type="checkbox"
              class="is-done-checkbox"
              @input="setIsDirty(true)"
              @change="updateIsDone"
            />

            <e-input
              v-model="doneText"
              class="done-text-input"
              @input="setIsDirty(true)"
            />
          </div>
        </form>

        <save-bar :form-is-valid="formIsValid">
          <submit-button
            :form-is-valid="formIsValid"
            form="bucket-list-form"
          />

          <e-button
            v-if="isExistingBucketListItem"
            @click="showDeleteDialog = true"
          >
            Delete
          </e-button>
        </save-bar>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import BucketListItem from '@/models/BucketListItem'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import { format } from 'date-fns'

export default {
  name: 'CredentialForm',

  components: {
    ConfirmationDialog,
    SaveBar,
    SubmitButton
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      title: '',
      details: '',
      doneText: '',
      createdDate: '',
      targetDate: '',
      completedDate: '',
      isDone: false,

      showDeleteDialog: false,

      isDirty: false,
      isExistingBucketListItem: true
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 132,
      isInitial: true
    })

    if (this.id) {
      const bucketListItem = BucketListItem.find(this.id)

      this.bucketListItemId = this.id
      this.title = bucketListItem.title
      this.details = bucketListItem.details
      this.doneText = bucketListItem.doneText
      this.createdDate = bucketListItem.createdDate
      this.targetDate = bucketListItem.targetDate
      this.completedDate = bucketListItem.completedDate
      this.isDone = bucketListItem.isDone
    } else {
      this.bucketListItemId = new BucketListItem().id
      this.isExistingBucketListItem = false
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    updateIsDone (bool) {
      this.isDone = bool
      this.doneText = bool ? `Completed ${format(new Date(), 'yyyy-MM-dd')}.` : ''
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async deleteBucketListItem () {
      this.showDeleteDialog = false

      BucketListItem.delete(this.bucketListItemId)

      this.$emit('deleted', { name: 'NewBucketListItem' })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['bucketListItems'],
        message: 'Bucket List Item successfully deleted'
      })
    },

    async submit () {
      this.setIsDirty(false)

      BucketListItem.insertOrUpdate({
        data: {
          id: this.bucketListItemId,
          title: this.title,
          details: this.details,
          doneText: this.doneText,
          createdDate: this.createdDate || new Date().toISOString(),
          targetDate: this.targetDate || new Date().toISOString(),
          completedDate: this.completedDate,
          isDone: this.isDone
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['bucketListItems'],
        message: 'Bucket List Item successfully saved'
      })

      this.$emit('submitted', {
        name: 'BucketListItem',
        params: { id: this.bucketListItemId }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.connect-input .done-text-input {
  flex-grow: 20;
}
</style>
