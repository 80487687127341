<template>
  <div>
    <section v-if="articles.length">
      <div class="top-inputs">
        <h1>
          Articles found: {{ articles.length }}
        </h1>
      </div>

      <div class="articles">
        <article-element
          v-for="article in articles"
          :key="article.id"
          :article="article"
          @article-clicked="goToArticle"
        />
      </div>
    </section>

    <section v-if="isSearch && articles.length === 0">
      <p>
        Welcome to the MeaVitae Knowledge Base. Here you will find
        a wide selection of articles with helpful information and
        how-to guides to help you navigate some of the complex
        issues that MeaVitae is designed to solve.
      </p>
    </section>
  </div>
</template>

<script>
import ArticleElement from '@/components/ArticleElement'

export default {
  name: 'KnowledgeBaseSubCategory',

  components: {
    ArticleElement
  },

  props: {
    isSearch: {
      type: Boolean,
      default: false
    },

    searchString: {
      type: String,
      default: ''
    },

    categoryId: {
      type: String,
      default: ''
    },

    subCategoryId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      articles: []
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 139,
      isInitial: true
    })

    if (this.searchString) {
      try {
        this.articles = await this.$store.dispatch('knowledgeBase/getSearchResults', this.searchString)
      } catch {
        this.articles = []
      }
    }

    if (this.categoryId && this.subCategoryId) {
      this.articles = await this.$store.dispatch('knowledgeBase/getArticles', {
        categoryId: this.categoryId,
        subCategoryId: this.subCategoryId
      })
    }
  },

  methods: {
    goToArticle ({ id, category, subCategory }) {
      this.$router.push({
        name: 'KnowledgeBaseArticle',
        params: {
          categoryId: category.id,
          subCategoryId: subCategory.id,
          articleId: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .articles {
    display: grid;
    row-gap: 1em;
  }
</style>
