<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Creating your Will

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(63)"
        />
      </h1>
    </div>

    <p>
      As one of the most important documents of your life, your will is a
      complicated set of instructions that you leave behind.  It is designed
      to cover all aspects of your life's  work and legacy.  As such there
      are many questions to be answered here.
    </p>

    <p>
      To help we have broken out the main sections of the document in the
      menu to the left.  Some of these sections may not be of interest to
      you and can be skipped over, but you should take time reading the help
      text to the right of this screen and consider how you select the options
      in each section.
    </p>

    <p>
      Now, the first question to answer is:
    </p>

    <validation-observer v-slot="{ handleSubmit }">
      <form class="spaced" @submit.prevent="handleSubmit(submit)">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Disposing of all worldwide assets"
          :rules="{ required: { allowFalse: false } }"
          tag="div"
        >
          <e-input
            :value="disposingOfAllWorldwideAssets"
            :error-messages="errors"
            :success="valid"
            type="checkbox"
            label="I am disposing of all worldwide assets?"
            data-test="disposing-of-all-worldwide-assets-checkbox"
            @change="value => disposingOfAllWorldwideAssets = value"
          />
        </validation-provider>

        <div class="bottom-actions">
          <e-button
            type="submit"
            class="save-button"
            data-test="save-button"
          >
            <span>Start</span>
          </e-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import Will from '@/models/Will'

export default {
  name: 'CreatingYourWill',

  emits: ['submitted'],

  data () {
    return {
      disposingOfAllWorldwideAssets: null
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 63,
      isInitial: true
    })

    this.disposingOfAllWorldwideAssets = Will
      .query()
      .first()
      .disposingOfAllWorldwideAssets
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async submit () {
      await this.$store.dispatch('updateWillProperty', {
        disposingOfAllWorldwideAssets: this.disposingOfAllWorldwideAssets
      })

      this.$emit('submitted', {
        name: 'MyFamily'
      })
    }
  }
}
</script>
