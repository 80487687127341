'use strict'

import Vcf from 'vcf'
import contactIsValid from './contactIsValid'
import { format, parseISO } from 'date-fns'
import { createId } from './'

const getProperties = ({ vCard, type }) =>
  !Array.isArray(vCard.get(type)) ? [vCard.get(type)] : vCard.get(type)

const addressParser = addressArray => {
  const address = addressArray.slice(1, 7)
  const temp = address[0]
  address[0] = address[1]
  address[1] = temp
  return address.join(', ').replace(' , ', ' ')
}

const parseVCardProperty = ({ property, types, valueParser }) => {
  const obj = property.toJSON()

  const type = Array.isArray(obj[1].type)
    ? obj[1].type.find(type => types.includes(type))
    : types.includes(obj[1].type) ? obj[1].type : ''

  const isPref = Array.isArray(obj[1].type)
    ? obj[1].type.includes('pref')
    : obj[1].type === 'pref'

  return {
    id: createId(),
    isPref,
    type,
    value: valueParser(obj[3])
  }
}

const _getName = vCard => {
  if (!vCard.get('n')) return {}

  const name = vCard.get('n').toJSON()[3]

  return {
    firstName: name[1] || '',
    lastName: name[0] || '',
    middleNames: name[2] || '',
    prefixes: name[3] || '',
    suffixes: name[4] || ''
  }
}

const _getGender = vCard => {
  if (!vCard.get('gender')) return {}

  const genderLookupObj = {
    M: 'male',
    F: 'female',
    O: 'other',
    N: 'none',
    U: 'unknown'
  }

  const gender = vCard
    .get('gender')
    .toJSON()[3]
    .split(';')

  return {
    genderType: genderLookupObj[gender[0]],
    genderIdentity: gender[1]
  }
}

const _getOrganisation = vCard => {
  if (!vCard.get('org')) return ''
  return vCard.get('org').toJSON()[3].split(';')[0]
}

const _getTitle = vCard => {
  return vCard.get('title') ? vCard.get('title').toJSON()[3] : ''
}

const _getEmailAddresses = vCard => {
  if (!vCard.get('email')) return []
  const types = ['work', 'home', 'other']
  const properties = getProperties({ vCard, type: 'email' })
  return properties.map(property => parseVCardProperty({ property, types, valueParser: value => value }))
}

const _getPhoneNumbers = vCard => {
  if (!vCard.get('tel')) return []
  const types = ['cell', 'work', 'home', 'other']
  const valueParser = value => value.replace(/\s/g, '')
  const properties = getProperties({ vCard, type: 'tel' })
  return properties.map(property => parseVCardProperty({ property, types, valueParser }))
}

const _getAddresses = vCard => {
  if (!vCard.get('adr')) return []
  const types = ['home', 'work', 'other']
  const properties = getProperties({ vCard, type: 'adr' })

  return properties.map(property => {
    const { id, isPref, type, value } = parseVCardProperty({ property, types, valueParser: addressParser })

    return {
      id,
      isPref,
      type,
      placeResult: {
        formatted_address: value
      },
      returnData: {}
    }
  })
}
const _getDOB = vCard => {
  if (!vCard.get('bday')) return ''
  const dob = vCard.get('bday').toJSON()[3]
  console.log(dob)
  return dob ? format(parseISO(dob), 'yyyy-MM-dd') : ''
}

const _getSource = vCard => vCard.get('prodid') ? vCard.get('prodid').toJSON()[3] : ''

const parseVCardToContactObjects = async newVCard => {
  let rawCards
  try {
    rawCards = Vcf.parse(newVCard)
  } catch (err) {
    rawCards = Vcf.parse(newVCard.replace(/\r?\n/g, '\r\n'))
  }

  return Promise.all(rawCards.map(async vCard => {
    const { firstName, middleNames, lastName, prefixes, suffixes } = _getName(vCard)
    const { genderType, genderIdentity } = _getGender(vCard)
    const emailAddresses = _getEmailAddresses(vCard)
    const phoneNumbers = _getPhoneNumbers(vCard)
    const organisationName = _getOrganisation(vCard)
    const jobTitle = _getTitle(vCard)
    const addresses = _getAddresses(vCard)
    const dateOfBirth = _getDOB(vCard)
    const source = _getSource(vCard)

    const isValid = await contactIsValid({
      type: 'person',
      firstName,
      lastName,
      dateOfBirth,
      genderType,
      addresses,
      emailAddresses
    })

    return {
      id: createId(),
      type: 'person',
      isValid,
      createdDate: new Date().toISOString(),
      ...firstName && { firstName },
      ...middleNames && { middleNames },
      ...lastName && { lastName },
      ...prefixes && { prefixes },
      ...suffixes && { suffixes },
      ...genderType && { genderType },
      ...genderIdentity && { genderIdentity },
      ...jobTitle && { jobTitle },
      ...organisationName && { organisationName },
      ...addresses && { addresses },
      ...dateOfBirth && { dateOfBirth },
      ...emailAddresses.length && { emailAddresses },
      ...phoneNumbers.length && { phoneNumbers },
      ...source && { source }
    }
  }))
}

export {
  _getAddresses,
  _getDOB,
  _getEmailAddresses,
  _getGender,
  _getName,
  _getOrganisation,
  _getPhoneNumbers,
  _getTitle,
  _getSource
}

export default parseVCardToContactObjects
