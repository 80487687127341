<template>
  <validation-observer
    v-slot="{ handleSubmit, valid: formIsValid }"
    slim
  >
    <form
      v-if="showView"
      id="message-form"
      class="spaced"
      @submit.prevent="handleSubmit(submit)"
    >
      <div class="headline">
        {{ headline }}
      </div>

      <div class="connect-input">
        <div class="recipients-element">
          <h2>
            Recipients
            <span
              class="icon-help-circle"
              @click.prevent="setHelp(110)"
            />
          </h2>

          <div class="box">
            <e-button
              class="add-recipient person-button ghost"
              data-test="add-recipient-button"
              @click="doShowAddRecipientList"
            >
              <span class="icon-plus" />
            </e-button>
            <ul data-test="current-recipients">
              <li
                v-for="recipient in recipients"
                :key="recipient.id"
              >
                <span>
                  <e-button
                    class="delete-icon person-button ghost"
                    data-test="remove-recipient-button"
                    @click="removeRecipient(recipient.userId)"
                  >
                    <span data-test="recipient-name">
                      {{ recipient.name }}
                    </span>

                    <span class="icon-trash-2" />
                  </e-button>
                </span>
              </li>
            </ul>
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Eligible Contact"
            :rules="{ array_length: true }"
            tag="div"
          >
            <input
              v-model="recipients.length"
              type="hidden"
            >

            <e-select
              v-if="showAddRecipientList"
              :items="eligibleRecipientsAsSelectOptions"
              default-option-text="Select a contact to add"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :disabled="recipients.length === 25"
              class="add-contact-select"
              data-test="contact-select"
              @change="addRecipient"
            />
          </validation-provider>
        </div>
      </div>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Subject"
        :rules="{ required: true, max: 100 }"
        slim
      >
        <e-input
          v-model="subject"
          :error-messages="errors"
          label="Subject"
          :success="valid"
          :is-required="true"
          :help-id="111"
          data-test="subject-input"
        />
      </validation-provider>

      <e-textarea
        v-model="body"
        label="Body"
        rows="12"
      />

      <add-file-button
        data-test="file-input"
        @change="event => { addAttachment(event.target.files[0]) }"
      />

      <div v-if="attachments.length">
        <h2>
          Attachments
        </h2>

        <div class="box">
          <ul data-test="attachments">
            <li
              v-for="attachment in attachments"
              :key="attachment.id"
            >
              {{ attachment.name }}

              <e-button
                class="delete-icon ghost"
                data-test="remove-attachment-button"
                @click="removeAttachment(attachment)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </ul>
        </div>
      </div>

      <save-bar :form-is-valid="formIsValid">
        <e-button
          v-if="showSendButton"
          data-test="save-as-draft-button"
          @click="saveAsDraft"
        >
          Save as draft
        </e-button>

        <submit-button
          v-if="showSendButton"
          :form-is-valid="formIsValid"
          form="message-form"
          button-text="Send"
          data-test="form-submit-button"
        />
      </save-bar>
    </form>
  </validation-observer>
</template>

<script>
import AddFileButton from '@/components/buttons/AddFileButton'
import Contact from '@/models/Contact'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import asyncForEach from '@/utils/asyncForEach'
import createFileActionObject from '@/utils/createFileActionObject'
import encryptFile from '@/utils/encryptFile'
import encryptString from '@/utils/encryptString'
import { createId, currentFileUploadLocation } from '@/utils'

export default {
  name: 'NewMessage',

  components: {
    AddFileButton,
    SaveBar,
    SubmitButton
  },

  props: {
    messageType: {
      type: String,
      default: 'new'
    },

    predefinedMessageId: {
      type: String,
      default: ''
    },

    predefinedSubject: {
      type: String,
      default: ''
    },

    predefinedBody: {
      type: String,
      default: ''
    },

    predefinedAttachments: {
      type: Array,
      default: () => []
    },

    predefinedTo: {
      type: Array,
      default: () => []
    },

    predefinedFrom: {
      type: Object,
      default: () => ({})
    },

    setCursorInBody: {
      type: Boolean,
      default: false
    },

    testRecipientsList: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showView: false,
      showSendButton: true,
      showAddRecipientList: false,
      matchedIdpContacts: [],
      messageId: '',
      headline: 'New Message',
      subject: '',
      body: '',
      recipients: [],
      attachments: [],
      attachmentsToDeleteFromApi: []
    }
  },

  computed: {
    eligibleRecipientsAsSelectOptions () {
      return this.matchedIdpContacts
        .filter(({ userId }) => !this.recipients.map(({ userId }) => userId).includes(userId))
        .map(({ userId, name }) => ({ value: userId, text: name }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 109,
      isInitial: true
    })

    try {
      this.matchedIdpContacts = Contact
        .query()
        .where('userId', userId => userId)
        .get()
        .map(({ userId, fullName }) => ({ userId, name: fullName }))

      this.headline = `SECURE ${this.messageType.toUpperCase()} MESSAGE`

      if (this.messageType) {
        if (['new', 'draft'].includes(this.messageType)) {
          if (this.messageType === 'draft') this.messageId = this.predefinedMessageId

          this.predefinedTo.forEach(({ userId, firstName, lastName }) => {
            const name = `${firstName} ${lastName}`
            this.addMessageContactToMatchedIdpContacts({ userId, name })
            this.addMessageContactToRecipients({ userId, name })
          })
        }
        this.showAddRecipientList = this.testRecipientsList
        this.subject = this.predefinedSubject
        this.body = this.predefinedBody

        if (['reply', 'replyAll'].includes(this.messageType)) {
          const userId = this.predefinedFrom.userId
          const name = `${this.predefinedFrom.firstName} ${this.predefinedFrom.lastName}`

          this.addMessageContactToMatchedIdpContacts({ userId, name })
          this.addMessageContactToRecipients({ userId, name })

          if (this.messageType === 'replyAll') {
            this.predefinedTo.forEach(({ userId, firstName, lastName }) => {
              const name = `${firstName} ${lastName}`
              this.addMessageContactToMatchedIdpContacts({ userId, name })
              this.addMessageContactToRecipients({ userId, name })
            })
          }
        }

        if (['forward', 'draft'].includes(this.messageType)) {
          this.attachments = this.predefinedAttachments.map(predefinedAttachment => ({
            ...predefinedAttachment,
            isNew: false
          }))
        }

        if (this.setCursorInBody) {
          this.$nextTick(() => {
            document.getElementById('textarea').focus()
            document.getElementById('textarea').setSelectionRange(0, 0)
          })
        }
      }

      this.showView = true
    } catch (error) {
      if (error.response?.status === 401) {
        this.$store.commit('setShowRefreshJwtDialog', true)
      }

      this.$store.commit('snackbar/update', {
        type: 'error',
        message: 'An error occurred'
      })

      this.$store.dispatch('logError', {
        error,
        fileName: 'MessageForm',
        functionName: 'created'
      })
    }
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addMessageContactToMatchedIdpContacts ({ userId, name }) {
      if (!this.matchedIdpContacts.some(idpContact => idpContact.userId === userId)) {
        this.matchedIdpContacts.push({ userId, name })
      }
    },

    addMessageContactToRecipients ({ userId, name }) {
      if (!this.recipients.some(currentRecipient => currentRecipient.userId === userId)) {
        this.recipients.push({ userId, name })
      }
    },

    doShowAddRecipientList () {
      this.showAddRecipientList = true
    },

    addRecipient (contactUserId) {
      this.recipients.push(this.matchedIdpContacts
        .find(({ userId }) => userId === contactUserId)
      )
    },

    removeRecipient (contactUserId) {
      this.recipients = this.recipients.filter(({ userId }) => userId !== contactUserId)
    },

    async addAttachment (file) {
      this.showSendButton = false

      file.id = createId()

      const { encryptedFile, encryptKey } = await encryptFile(file)

      this.attachments.push({
        id: file.id,
        name: file.name,
        mimeType: file.type,
        size: file.size,
        decryptKey: encryptKey,
        encryptedFile,
        source: currentFileUploadLocation,
        isNew: true
      })

      this.showSendButton = true
    },

    removeAttachment (attachment) {
      this.attachments = this.attachments.filter(({ id }) => id !== attachment.id)
      if (!attachment.isNew) this.attachmentsToDeleteFromApi.push(attachment)
    },

    async uploadFilesToApi (attachments) {
      return await Promise.all(attachments
        .map(async attachment => {
          if (attachment.isNew) {
            const fileActionObject = createFileActionObject({ name: attachment.name, type: 'upload' })

            this.$store.commit('files/addFileActionObject', fileActionObject)

            const { downloadUrl, etag } = await this.$store.dispatch('files/uploadFileToApi', {
              file: attachment.encryptedFile,
              userId: this.$store.state.userId,
              fileId: attachment.id,
              progressExecutor: event => fileActionObject.progressUpdator(event),
              abortController: fileActionObject.abortController
            })

            return {
              ...attachment,
              isNew: false,
              etag,
              fileUrl: downloadUrl,
              source: 'R2'
            }
          }

          return attachment
        })
      )
    },

    async generateMessageObject () {
      const { messageId, body, recipients, subject, attachments } = this

      const userIdsToGetPublicKeysFor = recipients.map(({ userId }) => userId)

      const recipientArmoredPublicKeys = (await this.$store.dispatch('getUserPublicKeysFromIdp', { userIdsToGetPublicKeysFor, allowUserPublicKeyNotFoundError: true }))
        .map(({ armoredPublicKey }) => ({ armoredPublicKey }))

      const encryptedBody = await encryptString({
        unencryptedString: body,
        publicKeyObjects: [
          { armoredPublicKey: this.$store.state.armoredPublicKey },
          ...recipientArmoredPublicKeys
        ],
        armoredPrivateKey: this.$store.state.armoredPrivateKey
      })

      const encryptedAttachmentObjects = await Promise.all(attachments
        .map(async ({ id, name, mimeType, decryptKey, size, fileUrl, source }) => ({
          id,
          encryptedAttachmentObject: await encryptString({
            unencryptedString: JSON.stringify({
              id,
              originUserId: this.$store.getters.user.id,
              name,
              mimeType,
              decryptKey,
              size,
              fileUrl,
              source
            }),
            publicKeyObjects: [
              { armoredPublicKey: this.$store.state.armoredPublicKey },
              ...recipientArmoredPublicKeys
            ],
            armoredPrivateKey: this.$store.state.armoredPrivateKey
          })
        }))
      )

      return {
        id: messageId,
        to: recipients.map(({ userId }) => userId),
        subject,
        body: encryptedBody,
        attachments: encryptedAttachmentObjects.slice(0, 25)
      }
    },

    handleFailedRecipientsSnackbarResponse ({ failedRecipients, numRecipients }) {
      if (!failedRecipients.length) {
        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Message sent successfully'
        })

        return
      }

      if (failedRecipients.length && failedRecipients.length < numRecipients) {
        const failedRecipientNames = failedRecipients
          .map(({ recipient: recipientId }) => this.matchedIdpContacts
            .find(({ userId }) => userId === recipientId)
            .name
            .toString()
          )

        this.$store.commit('snackbar/update', {
          type: 'warning',
          message: `The message failed to send to the following recipients: ${failedRecipientNames}`
        })

        return
      }

      if (failedRecipients.length === numRecipients) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'The message failed to send to any of the recipients'
        })
      }
    },

    async deleteRemovedAttachmentsFromApi () {
      await asyncForEach(this.attachmentsToDeleteFromApi, async ({ id: fileId, source }) => {
        await this.$store.dispatch('files/deleteFileFromApi', { fileId, source })
      })
      this.attachmentsToDeleteFromApi = []
    },

    async saveAsDraft () {
      try {
        this.attachments = await this.uploadFilesToApi(this.attachments)

        if (this.messageType === 'draft') {
          await this.deleteRemovedAttachmentsFromApi()
        }

        const messageObject = await this.generateMessageObject()
        const messageId = await this.$store.dispatch('messages/saveDraft', messageObject)

        this.messageId = messageId

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Draft saved'
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to save draft message'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'MessageForm',
          functionName: 'saveAsDraft'
        })
      }
    },

    async submit () {
      try {
        this.attachments = await this.uploadFilesToApi(this.attachments)

        const messageObject = await this.generateMessageObject()
        const sendMessageResponseObjects = await this.$store.dispatch('messages/sendMessage', messageObject)
        const failedRecipients = sendMessageResponseObjects.filter(({ status }) => status !== 'delivered')

        this.handleFailedRecipientsSnackbarResponse({ failedRecipients, numRecipients: this.recipients.length })

        this.$emit('route-change', 'Inbox')
        this.$router.push({ name: 'Inbox' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to send message'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'MessageForm',
          functionName: 'submit'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 0.4rem;
}

.headline {
  background-color: $nav-bar-background-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 400;
  height: 2.2rem;
  line-height: 2.2rem;
  margin-top: 2.7rem;
  text-align: center;
  width: 100%;
}

.recipients-element {
  width: 60%;
}

.delete-icon {
  color: $input-font-color;
}

.add-contact-select {
  margin-top: 1em;
}

.box {
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $corner;
  color: $input-font-color;
  min-height: 3.2rem;
  padding: 5px 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0.2rem 0.4rem;

  li {
    display: inline;

    // &:not(:last-child)::after {
    //   content: ',';
    // }

    span {
      white-space: nowrap;
    }

    button {
      padding: 0 0 0 0.2rem;
    }

    .recipient {
      border: solid 1px black;
    }
  }
}
</style>
