'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Group extends Model {
  static get entity () { return 'groups' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      text: this.string(),
      name: this.string(),
      description: this.string(),
      maxContacts: this.number()
    }
  }

  get selectText () {
    return `Group - ${this.text}`
  }

  get type () {
    return 'group'
  }
}
