<template>
  <div class="spaced">
    <e-dialog
      v-if="selectedAssetId"
      data-test="beneficiaries-manager-dialog"
    >
      <beneficiaries-manager
        :title="selectedAssetName"
        :beneficiary-ids="selectedAssetBeneficiaryIds"
        :show-close-button="true"
        @add-beneficiary="addAsssetBeneficiary"
        @remove-beneficiary="removeAssetBeneficiary"
        @closed="selectedAssetId = null"
      >
        <p>
          You can add either one contact, or a group of contacts as the beneficiary of this asset.
          You may also add a substitute beneficiary to each, who will replace them if they are
          deceased or fail at the time of probate.
        </p>

        <p>
          If you do not assign a specific beneficiary, then the asset will be left in your will as part
          of your residual estate.
        </p>
      </beneficiaries-manager>
    </e-dialog>

    <div class="top-inputs">
      <h1>
        Possessions

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(70)"
        />
      </h1>
    </div>

    <p>
      This section lets you give specific possessions you have created to named individuals or
      group of individuals. It details whether possessions have been assigned to beneficiaries
      or not and you can alter the beneficiaries for each possession by clicking on the
      beneficiaries icon next to each.
    </p>

    <e-table
      :headers="headers"
      :rows="rows"
      data-test="possessions-table"
    >
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            class="ghost"
            @click="event => setSelectedId({ event, itemId: item.id })"
          >
            <span class="icon-edit" />
          </e-button>
        </div>
      </template>
    </e-table>

    <fieldset>
      <legend>Remaining Personal Possessions</legend>

      <p>
        The gift of all remaining personal possessions is:
      </p>

      <e-input
        :value="residualPersonalPossessionsInstruction"
        radio-value="outrightGift"
        label="An outright gift"
        type="radio"
        data-test="outright-gift-radio"
        @change="value => residualPersonalPossessionsInstruction = value"
      />

      <e-input
        :value="residualPersonalPossessionsInstruction"
        radio-value="coupledWithRequestToDistribute"
        label="Coupled with a request to distribute"
        type="radio"
        data-test="request-to-distribute-radio"
        @change="value => residualPersonalPossessionsInstruction = value"
      />
    </fieldset>

    <fieldset v-if="residualPersonalPossessionsInstruction === 'outrightGift'">
      <beneficiaries-manager
        title="Residual Personal Possessions Beneficiaries"
        :beneficiary-ids="residualPersonalPossessionsBeneficiaryIds"
        @add-beneficiary="addResidualPersonalPossessionsBeneficiary"
        @remove-beneficiary="removeResidualPersonalPossessionsBeneficiary"
      />
    </fieldset>
  </div>
</template>

<script>
import Asset from '@/models/Asset'
import AssetBeneficiary from '@/models/AssetBeneficiary'
import Beneficiary from '@/models/Beneficiary'
import BeneficiariesManager from '@/components/BeneficiariesManager'
import ResidualPersonalPossessionsBeneficiary from '@/models/ResidualPersonalPossessionsBeneficiary'
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import Will from '@/models/Will'

export default {
  name: 'Possessions',

  components: {
    BeneficiariesManager,
    EDialog,
    ETable
  },

  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'left'
        },
        {
          text: 'Beneficiaries',
          value: 'beneficiaries',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],

      selectedAssetId: null
    }
  },

  computed: {
    will () {
      return Will
        .query()
        .with('residualPersonalPossessionsBeneficiaries')
        .first()
    },

    assets () {
      return Asset
        .query()
        .with('beneficiaries')
        .with('beneficiaries.contact')
        .with('beneficiaries.group')
        .get()
        .filter(({ type }) => type !== 'property')
    },

    rows () {
      return this.assets
        .map(({ id, name, beneficiaries }) => ({
          id,
          name,
          beneficiaries: beneficiaries
            ?.sort((a, b) => b.percentageShare - a.percentageShare)
            .slice(0, 3)
            .map(({ name, percentageShare }) => `${name} (${percentageShare}%)`)
            .join(', ')
            .concat(beneficiaries.length > 3
              ? `, ...(+${beneficiaries.length - 3} more)`
              : ''
            ),
          rowClickedFunction: () => (this.selectedAssetId = id)
        }))
    },

    selectedAssetBeneficiaryIds () {
      return this.assets
        .find(({ id }) => id === this.selectedAssetId)
        ?.beneficiaries
        .map(({ id }) => id)
    },

    selectedAssetName () {
      return this.assets
        .find(({ id }) => id === this.selectedAssetId)
        ?.name
    },

    residualPersonalPossessionsInstruction: {
      get () {
        return this.will.residualPersonalPossessionsInstruction
      },

      async set (value) {
        Will.update({
          where: this.will.id,
          data: {
            residualPersonalPossessionsInstruction: value
          }
        })

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['will'],
          message: 'Saved'
        })
      }
    },

    residualPersonalPossessionsBeneficiaryIds () {
      return this
        .will
        .residualPersonalPossessionsBeneficiaries
        .map(({ id }) => id)
    }
  },

  watch: {
    residualPersonalPossessionsInstruction () {
      if (this.type !== 'outrightGift') {
        this.residualPersonalPossessionsBeneficiaryIds
          .forEach(({ id }) => Beneficiary.delete(id))

        ResidualPersonalPossessionsBeneficiary.deleteAll()
      }
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 70,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    setSelectedId ({ event, itemId }) {
      event.stopPropagation()
      this.selectedAssetId = itemId
    },

    async addAsssetBeneficiary (beneficiaryObject) {
      const { beneficiaries } = await Beneficiary.insert({ data: beneficiaryObject })

      AssetBeneficiary.insert({
        data: {
          assetId: this.selectedAssetId,
          beneficiaryId: beneficiaries[0].id
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'assetBeneficiaries'],
        message: 'Beneficiary successfully added'
      })
    },

    async removeAssetBeneficiary (beneficiaryId) {
      AssetBeneficiary.delete([this.selectedAssetId, beneficiaryId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['assetBeneficiaries'],
        message: 'Beneficiary successfully removed'
      })
    },

    async addResidualPersonalPossessionsBeneficiary (beneficiaryObject) {
      const { beneficiaries } = await Beneficiary.insert({ data: beneficiaryObject })

      ResidualPersonalPossessionsBeneficiary.insert({
        data: {
          willId: this.will.id,
          beneficiaryId: beneficiaries[0].id
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'residualPersonalPossessionsBeneficiaries'],
        message: 'Beneficiary successfully added'
      })
    },

    async removeResidualPersonalPossessionsBeneficiary (beneficiaryId) {
      ResidualPersonalPossessionsBeneficiary.delete([this.will.id, beneficiaryId])
      Beneficiary.delete(beneficiaryId)

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'residualPersonalPossessionsBeneficiaries'],
        message: 'Beneficiary successfully removed'
      })
    }
  }
}
</script>

<style scoped lang="scss">
table {
  margin-bottom: 2rem;
}
</style>
