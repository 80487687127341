'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ jwt, file, userId, fileId, progressExecutor, abortController }) => {
  const response = await axios({
    method: 'put',
    headers: {
      Authorization: jwt,
      'Content-Type': 'application/octet-stream'
    },
    url: `${VUE_APP_GATEWAY_URL}/file/upload/${userId}/${fileId}`,
    data: file,
    onUploadProgress: progressExecutor,
    signal: abortController.signal,
    timeout: 600000 // 10 minutes
  })

  return response?.data
}
