'use strict'

import { Model } from '@vuex-orm/core'

export default class LiabilityAsset extends Model {
  static get entity () { return 'liabilityAssets' }
  static get primaryKey () { return ['liabilityId', 'assetId'] }

  static fields () {
    return {
      liabilityId: this.attr(null),
      assetId: this.attr(null)
    }
  }
}
