'use strict'

import { extend, validate } from 'vee-validate'
import { required, min_value as minValue, email } from 'vee-validate/dist/rules'

extend('required', required)
extend('min_value', minValue)
extend('email', email)

export default async ({
  firstName,
  lastName,
  organisationName,
  emailAddresses = [],
  addresses = [],
  type
}) => {
  const validationPromises = []

  validationPromises.push(validate(addresses.length, 'min_value:1'))

  if (type === 'person') {
    validationPromises.push(validate(firstName, 'required'))
    validationPromises.push(validate(lastName, 'required'))
    validationPromises.push(validate(emailAddresses.length, 'min_value:1'))
    emailAddresses.forEach(({ value }) => validationPromises.push(validate(value, 'email')))
  }

  if (type !== 'person') {
    validationPromises.push(validate(organisationName, 'required'))
  }

  const validationResults = await Promise.all(validationPromises)

  return validationResults.every(({ valid }) => valid)
}
