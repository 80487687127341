<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Assets
        </h1>

        <accordion
          :items="items"
          :display-sub-items-size="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @deleted="handleDeleted"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import Asset from '@/models/Asset'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'
import assetTypes from '@/json_files/assetTypes.json'

export default {
  name: 'Assets',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      activeCategory: ''
    }
  },

  computed: {
    items () {
      return assetTypes
        .map(({ type, text, routeName }) => ({
          id: routeName,
          text,
          isActive: this.activeCategory === routeName,
          sub: Asset
            .query()
            .where(asset => asset.type === type)
            .get()
            .map(({ id, name }) => ({ id, text: name, routeName })),
          additionals: [
            {
              id: `New${routeName}`,
              text: 'Add new',
              routeName: `New${routeName}`
            }
          ]
        }))
    }
  },

  created () {
    const currentRouteName = this.$router.history.current.name

    if (currentRouteName.substr(0, 3) === 'New') {
      this.activeCategory = currentRouteName.substr(3)
      return
    }

    this.activeCategory = currentRouteName
  },

  methods: {
    handleDeleted (routeObject) {
      this.$router.push(routeObject)
    },

    handleSubmitted (routeObject) {
      const notADoubleClick = routeObject.name !== this.$route.name

      if (notADoubleClick) {
        this.$router.push(routeObject)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  cursor: pointer;
}
</style>
