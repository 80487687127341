<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this credential"
      @confirm="deleteCredential"
      @cancel="showDeleteDialog = false"
    />

    <div class="top-inputs">
      <h1>
        {{ credentialName }}
      </h1>

      <span class="buttons">
        <e-button @click="goToEdit">
          edit
        </e-button>

        <e-button class="delete-button" @click="showDeleteDialog = true">
          delete
        </e-button>
      </span>
    </div>

    <article>
      <section>
        <header>Details</header>

        <dl>
          <div>
            <dt>Created Date:</dt>
            <dd>{{ formattedCreatedDate }}</dd>
          </div>

          <div>
            <dt>Username:</dt>
            <dd>{{ username }}</dd>
          </div>

          <div>
            <dt>Password:</dt>
            <dd class="password">
              {{ showPassword ? password : obfuscatedPassword }}

              <span
                class="show-password-span"
                :class="showPassword ? 'icon-eye-slash' : 'icon-eye'"
                @click.stop="toggleShowPassword"
              />
            </dd>
          </div>

          <div>
            <dt>Service Website:</dt>
            <dd>{{ serviceWebsite }}</dd>
          </div>
        </dl>
      </section>

      <section v-if="description">
        <header>Description</header>

        <div class="description">
          {{ description }}
        </div>
      </section>
    </article>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import Credential from '@/models/Credential'
import { format } from 'date-fns'

export default {
  name: 'CredentialView',

  components: {
    ConfirmationDialog
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: ['deleted'],

  data () {
    return {
      credentialId: '',
      credentialName: '',
      username: '',
      password: '',
      serviceWebsite: '',
      description: '',
      createdDate: '',

      showDeleteDialog: false,
      showPassword: false
    }
  },

  computed: {
    obfuscatedPassword () {
      return ''.padEnd(this.password.length, '*')
    },

    formattedCreatedDate () {
      return this.createdDate
        ? format(new Date(this.createdDate), 'MMMM d, Y - HH:ii')
        : null
    }
  },

  created () {
    this.$emit('set-active', 'secrets')

    const credential = Credential.find(this.id)

    this.credentialId = this.id
    this.credentialName = credential.credentialName
    this.username = credential.username
    this.password = credential.password
    this.serviceWebsite = credential.serviceWebsite
    this.description = credential.description
    this.createdDate = credential.createdDate
  },

  methods: {
    goToEdit () {
      this.$router.push({
        name: 'EditCredential',
        params: {
          credentialId: this.credentialId
        }
      })
    },

    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },

    async deleteCredential () {
      this.showDeleteDialog = false

      Credential.delete(this.credentialId)

      this.$emit('deleted', { name: 'NewCredential' })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['credentials'],
        message: 'Credential successfully deleted'
      })
    }
  }
}
</script>

<style scoped lang="scss">
section {
  margin: 1rem 0;

  header {
    border-bottom: 2px solid $link-color;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  dl > div {
    column-gap: 0.2rem;
    display: grid;
    grid-template-columns: max-content auto;
    margin: 0.5rem 0;
  }

  dt {
    align-items: center;
    display: flex;
    font-weight: 400;
    grid-column-start: 1;
  }

  dd {
    align-items: center;
    display: flex;
    grid-column-start: 2;
  }

  dl {
    padding-left: 0.4rem;
  }

  .description {
    white-space: pre-wrap;
  }

  .password {
    font-family: Monospace;
    font-size: 1.2em;
  }
}

.show-password-span {
  color: $input-font-color;
  font-size: 1.2rem;
  margin: 0 0.5rem;
}
</style>
