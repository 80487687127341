<template>
  <div class="set-password-block">
    <div class="header">
      <span />

      <e-button
        class="icon-x-circle ghost"
        @click="goToLogin"
      />
    </div>

    <corp-bar class="mv-icon" />

    <div
      v-if="idpApiSuccess"
    >
      <p>We are processing your password change request. If successful, you will be able to login with your new details in 30 seconds time</p>

      <e-button
        class="button gras"
        @click="goToLogin"
      >
        Login
      </e-button>
    </div>

    <div v-else>
      <p>Please enter your new password</p>

      <validation-observer v-slot="{ handleSubmit, invalid }">
        <form
          class="spaced"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <validation-provider
            v-if="!code"
            v-slot="{ errors, valid }"
            name="Code"
            rules="required"
            slim
          >
            <e-input
              v-model="enteredCode"
              label="Code"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="input"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="New Password"
            rules="required|confirmed:confirm"
            slim
          >
            <e-input
              v-model="newPassword"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              label="New Password"
              type="password"
              class="input"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            vid="confirm"
            name="New Password Confirm"
            rules="required"
            slim
          >
            <e-input
              v-model="newPasswordConfirm"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              label="New Password Confirm"
              type="password"
              class="input"
            />
          </validation-provider>

          <e-button
            :disabled="invalid"
            type="submit"
            class="button gras"
          >
            Request Password Change
          </e-button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import CorpBar from '@/components/CorpBar'
import attemptApiCall from '../utils/attemptApiCall'
import updatePasswordSetRequest from '../apis/idp/updatePasswordSetRequest'

export default {
  name: 'SetPasswordBox',

  components: {
    CorpBar
  },

  props: {
    code: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      idpApiSuccess: false,
      enteredCode: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },

  methods: {
    goToLogin () {
      this.$router.push({ name: 'Login' })
    },

    async submitForm () {
      try {
        await attemptApiCall(
          updatePasswordSetRequest,
          {
            code: this.code || this.enteredCode,
            newPassword: this.newPassword
          }
        )
      } catch (error) {
      } finally {
        this.idpApiSuccess = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.set-password-block {
  display: flex;
  flex-direction: column;
  max-width: 20rem;

  div {
    p {
      display: block;
      margin: 2em 0;
      text-align: center;
    }
  }

  .input {
    color: $font-color-one;
  }

  input:focus {
    background-color: $link-hover-color;
    border-color: rgb(134, 134, 195);
  }

  .button {
    letter-spacing: 0.05em;
    margin-top: 0.5em;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $color-success;
      border-color: $color-success;
      color: $filled-button-text;
    }
  }

  .mv-icon {
    height: 10rem;
  }
}
</style>
