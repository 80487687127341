<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Non-Disclosure Agreement

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="non-disclosure-agreement-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Agreement Date"
            rules="required"
            slim
          >
            <e-input
              v-model="agreementDate"
              label="Agreement Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Employer</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Employer"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="employerId"
                :items="employerSelectOptions"
                label="Employer"
                default-option-text="Add Employer"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Employer Witness"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="employerWitnessId"
                :items="employerWitnessSelectOptions"
                label="Employer Witness"
                default-option-text="Add Employer Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset>
            <legend>Employee</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Employee"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="employeeId"
                :items="employeeSelectOptions"
                label="Employee"
                default-option-text="Add Employee"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Employee Witness"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="employeeWitnessId"
                :items="employeeWitnessSelectOptions"
                label="Employee Witness"
                default-option-text="Add Employee Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Witnessed Date"
            rules="required"
            slim
          >
            <e-input
              v-model="witnessedDate"
              label="Witnessed Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="non-disclosure-agreement-form"
              button-text="Preview"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'nonDisclosureAgreementAstonVeer'

export default {
  name: 'NonDisclosureAgreement',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: '12e78a80-5e95-421c-8265-d16bcfb2fb76',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      agreementDate: '',
      jobPosition: '',
      employerId: '',
      employeeId: '',
      employerWitnessId: '',
      employeeWitnessId: '',
      witnessedDate: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    employerSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => ![this.employeeId, this.employerWitnessId, this.employeeWitnessId].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    employeeSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![this.employerId, this.employerWitnessId, this.employeeWitnessId].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    employerWitnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![this.employerId, this.employeeId, this.employeeWitnessId].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    employeeWitnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![this.employerId, this.employeeId, this.employerWitnessId].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            agreementDate: this.agreementDate,
            jobPosition: this.jobPosition,
            ...this.employerId && {
              employer: this.userAndContacts.find(({ id }) => id === this.employerId)
            },
            ...this.employeeId && {
              employee: this.userAndContacts.find(({ id }) => id === this.employeeId)
            },
            ...this.employerWitnessId && {
              employerWitness: this.userAndContacts.find(({ id }) => id === this.employerWitnessId)
            },
            ...this.employeeWitnessId && {
              employeeWitness: this.userAndContacts.find(({ id }) => id === this.employeeWitnessId)
            },
            witnessedDate: this.witnessedDate
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'NonDisclosureAgreement',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'NonDisclosureAgreement',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-wide {
  flex: 100;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
