'use strict'

import aes from 'crypto-js/aes'
import convertArrayBufferToString from '../utils/convertArrayBufferToString'
import getPublicKeyCredential from './getPublicKeyCredential'
import utf8 from 'crypto-js/enc-utf8'

export default async ({ encryptedArmoredPrivateKey, credentialIds, rpId, challenge }) => {
  try {
    const publicKeyCredential = await getPublicKeyCredential({ credentialIds, rpId, challenge })

    if (!publicKeyCredential) throw new Error('Authenticator did not return publicKeyCredential')
    if (!publicKeyCredential.response?.userHandle) throw new Error('Authenticator did not return userHandle')

    const [envelopeKey] = convertArrayBufferToString(publicKeyCredential.response.userHandle).split(':')
    if (typeof envelopeKey !== 'string') throw new Error('envelopeKey was not created correctly')

    const armoredPrivateKey = JSON.parse(aes
      .decrypt(encryptedArmoredPrivateKey, envelopeKey)
      .toString(utf8)
    )

    return armoredPrivateKey
  } catch (error) {
    // console.error(error)

    throw new Error('Retrieval of private key failed', { cause: error })
  }
}
