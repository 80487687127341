'use strict'

import KnowledgeBase from '@/views/KnowledgeBase/KnowledgeBase'
import KnowledgeBaseArticle from '@/views/KnowledgeBase/KnowledgeBaseArticle'
import KnowledgeBaseSubCategory from '@/views/KnowledgeBase/KnowledgeBaseSubCategory'
import LoadingSpinner from '@/components/LoadingSpinner'

const subscriptionFeatureId = 2020

export default {
  path: '/knowledge-base',
  name: 'KnowledgeBase',
  components: {
    appClosedLayout: LoadingSpinner,
    default: KnowledgeBase
  },
  meta: {
    title: 'Knowledge Base',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'search/:searchString?',
      name: 'KnowledgeBaseSearchResult',
      components: {
        appClosedLayout: LoadingSpinner,
        default: KnowledgeBaseSubCategory
      },
      props: {
        default: route => ({
          isSearch: true,
          searchString: route.params.searchString
        })
      },
      meta: {
        title: 'Knowledge Base',
        subscriptionFeatureId
      }
    },

    {
      path: ':categoryId/:subCategoryId/:articleId',
      name: 'KnowledgeBaseArticle',
      components: {
        appClosedLayout: LoadingSpinner,
        default: KnowledgeBaseArticle
      },
      props: {
        default: route => ({
          categoryId: route.params.categoryId,
          subCategoryId: route.params.subCategoryId,
          articleId: route.params.articleId
        })
      },
      meta: {
        title: 'Knowledge Base Article',
        subscriptionFeatureId
      }
    }
  ]
}
