<template>
  <span>
    <unsaved-changes-warning-dialog
      v-if="showUnsavedChangesWarningDialog"
      class="unsaved-changes-warning-dialog"
      @confirm="next"
      @cancel="showUnsavedChangesWarningDialog = false"
    />

    <component
      :is="form"
      v-bind="$attrs"
      @is-dirty="setIsDirty"
      @deleted="handleDeleted"
      @submitted="handleSubmitted"
    />
  </span>
</template>

<script>
import UnsavedChangesWarningDialog from '@/components/dialogs/UnsavedChangesWarningDialog'

export default {
  name: 'FormView',

  components: {
    UnsavedChangesWarningDialog
  },

  beforeRouteUpdate (to, from, next) {
    this.checkIfUnsavedChanges(next)
  },

  beforeRouteLeave (to, from, next) {
    this.checkIfUnsavedChanges(next)
  },

  props: {
    form: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isDirty: false,
      showUnsavedChangesWarningDialog: false
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
    },

    checkIfUnsavedChanges (next) {
      if (this.isDirty) {
        this.showUnsavedChangesWarningDialog = true
        this.next = next
      } else {
        next()
      }
    },

    handleDeleted (routeObject) {
      this.$router.push(routeObject)
    },

    handleSubmitted (routeObject) {
      const notADoubleClick = routeObject.name !== this.$route.name

      if (notADoubleClick) {
        this.$router.push(routeObject)
      }
    }
  }
}
</script>
