<template>
  <div class="feature-panels">
    <div class="top-inputs">
      <h2>Features</h2>
    </div>

    <div class="feature-panel-container">
      <dashboard-feature-panel
        v-for="feature in featurePanels"
        :key="feature.featureId"
        :link-path="feature.linkPath"
        :link-is-external="feature.linkIsExternal"
        :image-url="feature.imageUrl"
        :panel-text="feature.text"
      />
    </div>
  </div>
</template>

<script>
import DashboardFeaturePanel from '@/components/DashboardFeaturePanel'

export default {
  name: 'DashboardFeaturePanelContainer',

  components: {
    DashboardFeaturePanel
  },

  props: {
    featurePanels: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style scoped lang="scss">
.feature-panels {
  border-bottom: 1px solid $border-color-one;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  .feature-panel-container {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: $media-break-1) {
  .feature-panels {
    .feature-panel-container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
