'use strict'

import CryptoJS from 'crypto-js'

const convertWordArrayToUint8Array = wordArray => {
  const arrayOfWords = Object.prototype.hasOwnProperty.call(wordArray, 'words')
    ? wordArray.words
    : []

  const length = Object.prototype.hasOwnProperty.call(wordArray, 'sigBytes')
    ? wordArray.sigBytes
    : arrayOfWords.length * 4

  const uInt8Array = new Uint8Array(length)

  let index = 0
  let word

  for (let i = 0; i < length; i++) {
    word = arrayOfWords[i]
    uInt8Array[index++] = word >> 24
    uInt8Array[index++] = (word >> 16) & 0xff
    uInt8Array[index++] = (word >> 8) & 0xff
    uInt8Array[index++] = word & 0xff
  }

  return uInt8Array
}

const errorCallback = reject => error => reject(error)

const decryptFile = ({ resolve, encryptedFile, decryptKey, filename, mimeType }) => event => {
  const decrypted = CryptoJS
    .AES
    .decrypt(event.currentTarget.result, decryptKey)

  const typedArray = convertWordArrayToUint8Array(decrypted)

  const decryptedFile = new File(
    [typedArray],
    filename,
    { type: mimeType }
  )

  resolve(decryptedFile)
}

export default ({ encryptedFile, decryptKey, filename, mimeType }) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = decryptFile({ resolve, encryptedFile, decryptKey, filename, mimeType })
  reader.onerror = errorCallback(reject)
  reader.readAsText(encryptedFile)
})
