<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Provisions

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(74)"
        />
      </h1>
    </div>

    <p>
      You can use this section to decide how you want to pass on your remaining estate after
      the payment of debts, funeral expenses and inheritance tax. You can leave it to your spouse
      or civil partner and then to your children or grandchildren if that provision fails or you can
      leave your remaining estate directly to your children or grandchildren. If you take the latter
      option, there are legislations which your spouse or civil partner can use to prevent them falling
      into financial hardship due to omission from your will.
    </p>

    <fieldset>
      <legend>
        Remaining Estate
      </legend>

      <p>
        Who do you want to pass your remaining estate to?
      </p>

      <e-input
        :value="remainingEstateToPartner"
        type="checkbox"
        label="To my partner"
        data-test="remaining-estate-to-partner-checkbox"
        @change="value => remainingEstateToPartner = value"
      />

      <e-input
        :value="remainingEstateToChildren"
        type="checkbox"
        label="To my children"
        data-test="remaining-estate-to-children-checkbox"
        @change="value => remainingEstateToChildren = value"
      />

      <e-input
        :value="remainingEstateToGrandchildren"
        type="checkbox"
        label="To my grandchildren"
        data-test="remaining-estate-to-grandchildren-checkbox"
        @change="value => remainingEstateToGrandchildren = value"
      />
    </fieldset>
  </div>
</template>

<script>
import Will from '@/models/Will'

export default {
  name: 'Provisions',

  computed: {
    will () {
      return Will.query().first()
    },

    remainingEstateToPartner: {
      get () {
        return this.will.remainingEstateToPartner
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          remainingEstateToPartner: value
        })
      }
    },

    remainingEstateToChildren: {
      get () {
        return this.will.remainingEstateToChildren
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          remainingEstateToChildren: value
        })
      }
    },

    remainingEstateToGrandchildren: {
      get () {
        return this.will.remainingEstateToGrandchildren
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          remainingEstateToGrandchildren: value
        })
      }
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 74,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    }
  }
}
</script>
