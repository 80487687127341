<template>
  <three-column-layout>
    <template #one>
      <div class="padded summary">
        <h1 class="main-title">
          {{ userFullName }}

          <button v-if="false" @click="refreshTodoList">
            refresh todos
          </button>
        </h1>

        <div class="container">
          <account-and-logout-buttons />

          <div class="headline">
            <div class="headline-text" @click="toggleShowNetWorth">
              ESTIMATED NET WORTH
              <span
                v-if="isSmallDisplay"
                class="net-worth-icon-chevron"
                :class="[showNetWorth ? 'icon-chevron-down' : 'icon-chevron-up']"
              />
            </div>

            <div class="net-worth">
              {{ formatAsCurrency(netWorth) }}
            </div>
          </div>

          <div
            v-if="showNetWorth"
            class="breakdown"
          >
            <div class="assets-text">
              ASSETS: {{ formatAsCurrency(assetsValue) }}
              <span class="key" />
            </div>

            <div class="accounts-text">
              ACCOUNTS: {{ formatAsCurrency(accountsValue) }}
              <span class="key" />
            </div>

            <net-worth-bars
              :assets-value="assetsValue"
              :accounts-value="accountsValue"
              :liabilities-value="liabilitiesValue"
            />

            <div class="liabilities-text">
              LIABILITIES: {{ formatAsCurrency(-Math.abs(liabilitiesValue)) }}
              <span class="key" />
            </div>
          </div>

          <p class="info-text">
            Est. Liabilities sums the value of debts on register records but doesn't
            try to account for any total debt occuring from recurring obligations
          </p>
        </div>
      </div>
    </template>

    <template #two>
      <e-dialog v-if="showDeleteTodoDialog">
        <h1>Delete Todo</h1>

        <p>
          Are you sure you wish to delete this todo?
        </p>

        <confirmation-buttons
          @confirm="deleteTodo"
          @cancel="showDeleteTodoDialog = false"
        />
      </e-dialog>

      <div class="padded full-height">
        <dashboard-feature-panel-container
          v-if="featurePanels.length"
          :feature-panels="featurePanels"
        />

        <div class="todos">
          <div class="top-inputs">
            <h2>Things to do...</h2>

            <span class="buttons">
              <e-button @click="addTodo">
                ADD
              </e-button>
            </span>
          </div>

          <transition-group
            name="list"
            class="task-cards"
          >
            <task-card
              v-if="showNewTodo"
              key="new"
              :todo="newTodo"
              status="ongoing"
              :start-in-edit-mode="true"
              @delete="handleDeleteRequest"
              @update="updateTodo"
            />

            <task-card
              v-for="todo in ongoingTodos"
              :key="todo.id"
              :todo="todo"
              status="ongoing"
              @card-status-changed="updateTodoStatus"
              @delete="handleDeleteRequest"
              @update="updateTodo"
            />
          </transition-group>

          <transition-group
            name="list"
            class="task-cards"
          >
            <task-card
              v-for="todo in completedTodos"
              :key="todo.id"
              :todo="todo"
              status="completed"
              @delete="handleDeleteRequest"
              @card-status-changed="updateTodoStatus"
            />
          </transition-group>
        </div>
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />

      <div class="login-info padded">
        <h3 class="login-note">
          Log in again before
        </h3>

        <div class="next-login-date">
          {{ nextLoginByDate }}
        </div>

        <p class="login-nudge">
          Don't worry, we'll nudge you before it's time.
        </p>
      </div>
    </template>
  </three-column-layout>
</template>

<script>
import Account from '@/models/Account'
import AccountAndLogoutButtons from '@/components/buttons/AccountAndLogoutButtons'
import Asset from '@/models/Asset'
import ConfirmationButtons from '@/components/buttons/ConfirmationButtons'
import DashboardFeaturePanelContainer from '@/components/DashboardFeaturePanelContainer'
import EDialog from '@/components/dialogs/EDialog'
import HelpSideBar from '@/components/HelpSideBar'
import Liability from '@/models/Liability'
import NetWorthBars from '@/components/NetWorthBars'
import TaskCard from '@/components/TaskCard'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'
import Todo from '@/models/Todo'
import attemptApiCall from '@/utils/attemptApiCall'
import deleteScheduledTodoEmail from '@/apis/emailScheduler/deleteScheduledTodoEmail'
import { formatMoney } from 'accounting'
import { flow } from 'lodash'
import { addMonths, format } from 'date-fns/fp'

const sortFunction = (a, b) => {
  if (a.type === 'system' && b.type === 'system') {
    return a.publishedAt > b.publishedAt ? -1 : 1
  }

  if (a.type === 'system' && b.type !== 'system') {
    return 1
  }

  if (a.type !== 'system' && b.type === 'system') {
    return -1
  }

  if (a.type !== 'system' && b.type !== 'system') {
    if (!a.deadlineDate && !b.deadlineDate) return 0
    if (!a.deadlineDate) return 1
    if (!b.deadlineDate) return -1
  }

  return a.deadlineDate < b.deadlineDate ? -1 : 1
}

export default {
  name: 'Dashboard',

  components: {
    AccountAndLogoutButtons,
    ConfirmationButtons,
    DashboardFeaturePanelContainer,
    EDialog,
    HelpSideBar,
    NetWorthBars,
    TaskCard,
    ThreeColumnLayout
  },

  data () {
    return {
      featurePanels: [],
      showNetWorth: false,
      showNewTodo: false,
      showDeleteTodoDialog: false,
      newTodo: {},
      idOfTodoToDelete: ''
    }
  },

  computed: {
    isSmallDisplay () {
      return this.$store.state.isSmall
    },

    userFullName () {
      return `${this.$store.getters.userIsAdmin ? 'ADMIN: ' : ''}${this.$store.getters.user?.fullName}`
    },

    assetsValue () {
      const assetValue = Asset
        .query()
        .get()
        .reduce((accumulator, { currency, value, ownershipPercentage }) => {
          return accumulator + this.getValueAsGBP({ currency, value, ownershipPercentage, calculateValueWithOwnershipPercentage: true })
        }, 0)

      const liabilityPayoutValue = Liability
        .query()
        .where('payout', (value) => value > 0)
        .get()
        .reduce((accumulator, { currency, payout, ownershipPercentage }) => {
          return accumulator + this.getValueAsGBP({ currency, value: payout, ownershipPercentage, calculateValueWithOwnershipPercentage: true })
        }, 0)

      return (assetValue + liabilityPayoutValue)
    },

    liabilitiesValue () {
      return Liability
        .query()
        .get()
        .reduce((accumulator, { currency, value, ownershipPercentage }) => {
          return accumulator + this.getValueAsGBP({ currency, value, ownershipPercentage, calculateValueWithOwnershipPercentage: true })
        }, 0)
    },

    accountsValue () {
      return Account
        .query()
        .get()
        .reduce((accumulator, { currency, value }) => {
          return accumulator + this.getValueAsGBP({ currency, value, calculateValueWithOwnershipPercentage: false })
        }, 0)
    },

    netWorth () {
      return this.accountsValue + this.assetsValue - this.liabilitiesValue
    },

    nextLoginByDate () {
      return flow(
        addMonths(1),
        format('dd  / MMMM / yyyy')
      )(new Date()).toUpperCase()
    },

    ongoingTodos () {
      return Todo
        .all()
        .filter(({ isDone }) => !isDone)
        .sort(sortFunction)
        .map(todo => ({
          ...todo,
          ...todo.title === 'Complete Your Details' && { highlight: true }
        }))
    },

    completedTodos () {
      return Todo
        .all()
        .filter(({ isDone }) => isDone)
        .sort(sortFunction)
    }
  },

  async created () {
    if (!this.isSmallDisplay) this.showNetWorth = true

    this.$store.dispatch('findAndSetHelpObject', {
      id: 1,
      isInitial: true
    })
    const featurePanelFeed = await this.$store.dispatch('getDashboardFeaturePanels')
    this.featurePanels = featurePanelFeed.map(feature => ({
      ...feature,
      featureId: feature._id,
      linkIsExternal: feature.isExternal,
      linkPath: feature.path
    }))
  },

  methods: {
    toggleShowNetWorth () {
      this.showNetWorth = !this.showNetWorth
    },

    formatAsCurrency (value) {
      return formatMoney(value, '£', 0)
    },

    getValueAsGBP ({ currency, value, ownershipPercentage, calculateValueWithOwnershipPercentage }) {
      const { rates, base } = this.$store.state.exchangeRatesObject

      const valueAsBase = (rates[base] / rates[currency.toUpperCase()]) * value
      return calculateValueWithOwnershipPercentage
        ? valueAsBase * rates.GBP * (ownershipPercentage / 100)
        : valueAsBase * rates.GBP
    },

    async addTodo () {
      this.showNewTodo = true
    },

    async updateTodoStatus ({ id, isDone, type }) {
      await this.$store.dispatch('todos/updateTodoIsDone', { id, isDone, type })
    },

    async updateTodo ({ id, title, body, deadlineDate, type, emailTodo }) {
      this.showNewTodo = false

      const updatedTodo = (await Todo.insertOrUpdate({
        data: {
          id,
          title,
          body,
          deadlineDate,
          type,
          emailTodo
        }
      }))?.todos?.[0]

      await this.$store
        .dispatch('todos/persistTodosToVault', {
          message: 'Todo successfully saved',
          updatedTodo
        })
    },

    handleDeleteRequest (id) {
      this.idOfTodoToDelete = id
      this.showDeleteTodoDialog = true
    },

    async deleteTodo () {
      this.showNewTodo = false
      this.showDeleteTodoDialog = false

      Todo.delete(this.idOfTodoToDelete)

      await this.$store
        .dispatch('todos/persistTodosToVault', {
          message: 'Todo successfully deleted'
        })

      const assetsUpdated = await Asset
        .update({
          where: asset => asset.vehicleMOTTodoId === this.idOfTodoToDelete,
          data: { vehicleMOTTodoId: null, vehicleMOTEmailTodo: false }
        })
      if (assetsUpdated?.assets?.length) await this.$store.dispatch('persistRecordToVault', { entityTypes: ['assets'] })

      await attemptApiCall(deleteScheduledTodoEmail, this.idOfTodoToDelete)
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  display: grid;

  .headline-text,
  .liabilities-text,
  .assets-text,
  .accounts-text {
    color: $font-color-one;
  }

  .breakdown {
    .assets-text,
    .accounts-text,
    .liabilities-text {
      align-items: center;
      display: flex;

      .key {
        display: inline-block;
        height: 1rem;
        margin-bottom: 0.2rem;
        margin-left: 0.4rem;
        width: 1rem;
      }
    }

    .assets-text .key {
      background-color: green;
    }

    .accounts-text .key {
      background-color: yellow;
    }

    .liabilities-text {
      justify-content: flex-end;

      .key {
        background-color: red;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    text-align: center;

    .account-buttons {
      margin: 0 0 1rem;

      & > :not(:last-child) {
        margin-right: 1rem;
      }
    }

    .info-text {
      display: none;
    }
  }

  .headline {
    padding-bottom: 0.6rem;

    .headline-text {
      padding-bottom: 0.6rem;
    }

    .net-worth {
      color: $font-color-two;
      font-size: 2rem;
      font-weight: normal;
    }
  }
}

.login-info {
  display: grid;
  row-gap: 3%;

  .login-note {
    font-weight: normal;
  }

  .login-nudge {
    padding-top: 0.8rem;
  }

  .next-login-date,
  .login-nudge {
    color: $font-color-one;
  }
}

.todos {
  .task-cards {
    display: grid;
    padding-bottom: 1em;
    row-gap: 1em;

    .list-enter-active,
    .list-leave-active {
      transition: all 1s;
    }

    .list-enter,
    .list-leave-to {
      opacity: 0;
      transform: translate(30px);
    }
  }
}

@media screen and (min-width: $media-break-1) {
  .summary {
    .container {
      padding: 0;
      text-align: left;

      .info-text {
        display: block;
        margin-top: 0.6rem;
      }
    }
  }
}
</style>
