import Asset from '../models/Asset'
import AssetBeneficiary from '../models/AssetBeneficiary'
import AssetCoOwnerContact from '../models/AssetCoOwnerContact'
import Beneficiary from '../models/Beneficiary'
import ChildContact from '../models/ChildContact'
import Contact from '../models/Contact'
import DecryptError from '../utils/DecryptError'
import ExecutorContact from '../models/ExecutorContact'
import Group from '../models/Group'
import GuardianContact from '../models/GuardianContact'
import Liability from '../models/Liability'
import LiabilityCoOwnerContact from '../models/LiabilityCoOwnerContact'
import MonetaryDebt from '../models/MonetaryDebt'
import MonetaryGift from '../models/MonetaryGift'
import ResiduaryEstateBeneficiary from '../models/ResiduaryEstateBeneficiary'
import SubstituteExecutorContact from '../models/SubstituteExecutorContact'
import SubstituteGuardianContact from '../models/SubstituteGuardianContact'
import User from '../models/User'
import Vue from 'vue'
import VuexORM from '@vuex-orm/core'
import Will from '../models/Will'
import WitnessContact from '../models/WitnessContact'
import attemptApiCall from '../utils/attemptApiCall'
import convertBase64BlockToObject from '../utils/convertBase64BlockToObject'
import database from './database'
import deleteUserFromIdp from '../apis/idp/deleteUserFromIdp'
import deleteVault from '../apis/vault/deleteVault'
import decryptString from '../utils/decryptString'
import deleteAllUserFilesFromAwsS3 from '../apis/file/deleteAllUserFilesFromAwsS3'
import deleteAllUserFilesFromCloudflareR2 from '../apis/file/deleteAllUserFilesFromCloudflareR2'
import deleteAllUserComms from '../apis/comms/deleteAllUserComms'
import deleteAllUserPurchases from '../apis/marketplace/deleteAllUserPurchases'
import documentTypes from '../json_files/documentTypes.json'
import encryptString from '../utils/encryptString'
import endpointLookupObject from '../apis/vault/utils/endpointLookupObject.json'
import extractArmoredPrivateKeyFromSecurityKey from '../auth/extractArmoredPrivateKeyFromSecurityKey'
import fastHash from '../utils/fastHash'
import files from './files'
import gdpr from './gdpr'
import getAvatar from '../apis/idp/getAvatar'
import getContactInvitesByUser from '../apis/comms/getContactInvitesByUser'
import getCurrencies from '../apis/openExchangeRates/getCurrencies'
import getDashboardFeaturePanels from '../apis/cms/getDashboardFeaturePanels'
import getHelpObjects from '../apis/help/getHelpObjects'
import getLatestExchangeRates from '../apis/openExchangeRates/getLatestExchangeRates'
import getIdpLogout from '../apis/idp/getIdpLogout'
import getSubscriptionPlans from '../apis/cms/getSubscriptionPlans'
import getTemplate from '../apis/tms/getTemplate'
import getVault from '../apis/vault/getVault'
import groups from '../json_files/groups.json'
import help from './help'
import insights from './insights'
import knowledgeBase from './knowledgeBase'
import letters from './letters'
import marketplace from './marketplace'
import messages from './messages'
import mvHandlebars from '@meavitae/mv-handlebars'
import postContactInfoToIdp from '../apis/idp/postContactInfoToIdp'
import postFeedback from '../apis/comms/postFeedback'
import postUserIdsToRetrievePublicKeysFromIdp from '../apis/idp/postUserIdsToRetrievePublicKeysFromIdp'
import portableTextToHtml from '../utils/portableTextToHtml'
import postLog from '../apis/logflare/postLog'
import putUserProfile from '../apis/idp/putUserProfile'
import putVaultEntity from '../apis/vault/putVaultEntity'
import services from './services'
import router from '@/router'
import snackbar from './snackbar'
import todos from './todos'
import updateCredentials from '../apis/idp/updateCredentials'
import validateUserJwtFromIdp from '../apis/gateway/validateUserJwtFromIdp'
import { invert } from 'lodash'

const decryptVaultRecord = async ({ encryptedVaultRecord, armoredPrivateKey }) => {
  try {
    return JSON.parse(await decryptString({
      encryptedString: encryptedVaultRecord,
      armoredPrivateKey
    }))
  } catch (error) {
    throw new DecryptError('failed to decrypt vault record', { cause: error })
  }
}

const getDocumentRecordsEntity = async ({ entitiesObject, armoredPrivateKey }) => {
  if (!entitiesObject[endpointLookupObject.documentRecords]) return null

  const documentRecordEntities = await Promise
    .all(convertBase64BlockToObject(entitiesObject[endpointLookupObject.documentRecords].data)
      .map(async ({ reference, timestamp, meta }) => ({
        reference,
        timestamp,
        ...await decryptVaultRecord({ encryptedVaultRecord: meta, armoredPrivateKey })
      }))
    )

  const documentRecordEntitiesObject = documentRecordEntities
    .reduce((accumulator, current) => ({
      ...accumulator,
      [current.reference]: current
    }), {})

  return {
    $connection: 'entities',
    $name: 'documentRecords',
    data: documentRecordEntitiesObject
  }
}

const getTheOtherEntities = async ({ entitiesObject, armoredPrivateKey }) => Promise
  .all(Object
    .entries(entitiesObject)
    .filter(([key]) => invert(endpointLookupObject)[key] !== 'documentRecords')
    .map(async ([_, { data, timestamp }]) => decryptVaultRecord({
      encryptedVaultRecord: convertBase64BlockToObject(data),
      armoredPrivateKey
    }))
  )

export default {
  state: {
    userId: '',
    firstName: '',
    middleNames: '',
    lastName: '',
    address1: '',
    address2: '',
    postcode: '',
    country: '',
    locale: '',
    subscribed: false,
    subscribedUntilDate: 0,
    subscribedFeatures: [],
    subscriptionProductId: '',
    armoredPublicKey: '',
    armoredPrivateKey: '',
    wallet: {},

    appVersion: process.env.VUE_APP_VERSION,
    operatingSystem: null,
    browserName: null,
    browserVersion: null,
    isSmall: true,
    helpObjects: [],
    currentHelpObject: {
      html: ''
    },
    dashboardFeaturePanels: [],
    subscriptionPlans: [],
    appError: {
      status: false,
      message: '',
      type: '',
      link: ''
    },
    apiUnsynced: [],
    exchangeRatesObject: { base: '', rates: [] },
    currencies: [],
    userUpdatedFromJwt: false,
    contactsWithInviteStatus: [],
    showAppOpen: false,
    showRefreshJwtDialog: false,
    showFeedbackBar: false,
    showSlidingHelp: false
  },

  modules: {
    files,
    gdpr,
    letters,
    marketplace,
    messages,
    knowledgeBase,
    todos,
    help,
    insights,
    services,
    snackbar
  },

  plugins: [
    VuexORM.install(database)
  ],

  getters: {
    isMobileDevice: state => !!(state.isSmall || ['iOS', 'Android OS'].includes(state.operatingSystem)),
    isApiUnsynced: state => !!state.apiUnsynced.length,
    user: () => User.query().first(),
    userEmailAddress: (_, { user }) => user?.emailAddresses?.[0]?.value,
    userPhoneNumber: (_, { user }) => user?.phoneNumbers?.sort((a, b) => (Number(a.isPref) - Number(b.isPref)))?.map(({ value }) => value).pop(),
    userIsLoggedIn: state => !!state.showAppOpen,
    userIsValid: (_, { user }) => !!user?.isValid,
    userIsAdmin: (_, { user }) => !!user?.permissions?.find(({ scope, role }) => scope === 'admin'),
    userIsVendor: (_, { user }) => !!user?.permissions?.find(({ scope, role }) => scope === 'app' && role === 'vendor'),

    routeAccessLookupObject: ({ subscribedFeatures = [] }) => (router.getRoutes())
      .reduce((accumulator, { name, meta: { subscriptionFeatureId = null } }) => ({
        ...accumulator,
        ...name && {
          [name]: {
            subscriptionFeatureId,
            routeAccessAllowed: !!(!subscriptionFeatureId || subscribedFeatures.includes(subscriptionFeatureId))
          }
        }
      }), {}),

    publicKeyObjects: ({ armoredPublicKey, userId }, { user }) => user
      ?.authorizedThirdPartyPublicKeyObjects
      .concat({
        userId,
        armoredPublicKey,
        scope: 'all'
      }),

    userAndContacts: (_, { user }) => (contactTypes = ['person', 'organisation']) => [
      {
        ...user,
        ...user?.address && {
          addresses: [{
            value: user.address
          }]
        },
        ...(user?.phoneNumber1 || user?.phoneNumber2) && {
          phoneNumbers: [
            ...user?.phoneNumber1 ? [{ value: user.phoneNumber1 }] : [],
            ...user?.phoneNumber2 ? [{ value: user.phoneNumber2 }] : []
          ]
        },
        type: 'person',
        selectText: `Me - ${user?.fullName}`
      },
      ...Contact.all().filter(({ type }) => contactTypes.includes(type))
    ],

    documentProducts: (_, { userMarketplaceProducts }) => {
      const purchasedProductSkuCodes = userMarketplaceProducts
        .filter(product => product.is_purchased)
        .map(({ sku }) => sku)

      return documentTypes
        .map(documentType => ({
          ...documentType,
          isPurchased: purchasedProductSkuCodes.includes(documentType.marketplaceProductSku)
        }))
    }
  },

  actions: {
    logError: ({ state }, { error, fileName, functionName }) => {
      postLog({
        messageObject: {
          source: 'Frontend App',
          environment: process.env.VUE_APP_ENVIRONMENT,
          message: error.message,
          fileName,
          functionName,
          stack: error.stack,
          userId: state.userId,
          date: Date.now(),
          ...error.cause && {
            cause: {
              message: error.cause.message,
              stack: error.cause.stack
            }
          }
        }
      })
    },

    updateUser: async ({ commit }, { userId, avatarUrl, firstName, middleNames, lastName, email, isPrivate, address1, address2, postcode, country, locale, permissions, subscribed, subscribedUntilDate, subscribedFeatures, subscriptionProductId }) => {
      const avatar = await getAvatar(avatarUrl)

      User.update({
        where: User.query().first().id,
        data: {
          avatar,
          firstName,
          middleNames,
          lastName,
          emailAddresses: [{
            id: userId,
            value: email
          }],
          permissions,
          subscribed,
          subscribedUntilDate,
          subscribedFeatures,
          subscriptionProductId,
          isPrivate,
          billingAddress1: address1,
          billingAddress2: address2,
          billingPostcode: postcode,
          billingCountry: country,
          locale
        }
      })

      commit('setUserUpdatedFromJwt')
    },

    logoutUser: async ({ commit, dispatch }, { jwt = sessionStorage.getItem('jwt'), allowRouterPush = true } = { jwt: null, allowRouterPush: true }) => {
      try {
        if (jwt) try { getIdpLogout(jwt) } catch {}

        sessionStorage.removeItem('jwt')

        await dispatch('entities/deleteAll')

        commit('setUserVars', {
          userId: null,
          armoredPublicKey: null,
          armoredPrivateKey: null,
          subscribed: false,
          subscribedUntilDate: 0,
          subscribedFeatures: [],
          subscriptionProductId: '',
          locale: ''
        })

        commit('setShowAppOpen', false)
        commit('setShowRefreshJwtDialog', false)

        const currentRouteName = router.history.current.name
        const loggedOutRoutes = ['Login', 'ForgottenPassword', 'SetPassword']
        if (!loggedOutRoutes.includes(currentRouteName) && allowRouterPush) router.push({ name: 'Login' })
      } catch {}
    },

    validateUserJwt: async ({ commit, dispatch }) => {
      try {
        return await attemptApiCall(
          validateUserJwtFromIdp,
          {
            jwt: sessionStorage.getItem('jwt')
          }
        )
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)
        if (error.response?.status === 419) {
          commit('setAppErrorState', {
            status: true,
            type: 'Single Device Rule',
            message: 'You have been logged out as you have logged in on another device'
          })

          await dispatch('logoutUser')
          return
        }

        throw error
      }
    },

    userLoggedInPollApis: async ({ dispatch, getters: { userIsLoggedIn } }) => {
      if (userIsLoggedIn) {
        try {
          dispatch('validateUserJwt')
          dispatch('messages/setUnreadMessageIds')
        } catch {}
      }
    },

    deleteUserFromIdp: async ({ commit }) => {
      try {
        return await attemptApiCall(
          deleteUserFromIdp,
          { jwt: sessionStorage.getItem('jwt') }
        )
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)
        throw error
      }
    },

    deleteContacts: async ({ dispatch }, idArrayOfContactsToDelete) => {
      idArrayOfContactsToDelete.forEach(idOfContactToDelete => {
        Asset.update({
          where: ({ supplierId }) => supplierId === idOfContactToDelete,
          data: { supplierId: '' }
        })

        Liability.update({
          where: ({ supplierId }) => supplierId === idOfContactToDelete,
          data: { supplierId: '' }
        })

        Will.update({
          where: ({ partnerId }) => partnerId === idOfContactToDelete,
          data: { partnerId: '' }
        })

        Beneficiary.update({
          where: ({ contactSubstituteId }) => contactSubstituteId === idOfContactToDelete,
          data: { contactSubstituteId: '' }
        })

        const relatedBeneficiaryIds = Beneficiary
          .query()
          .where('contactId', idOfContactToDelete)
          .get()
          .map(({ id }) => id)

        AssetBeneficiary.delete(({ beneficiaryId }) => relatedBeneficiaryIds.includes(beneficiaryId))
        ResiduaryEstateBeneficiary.delete(({ beneficiaryId }) => relatedBeneficiaryIds.includes(beneficiaryId))
        MonetaryGift.delete(({ beneficiaryId }) => relatedBeneficiaryIds.includes(beneficiaryId))
        MonetaryDebt.delete(({ debtorId }) => relatedBeneficiaryIds.includes(debtorId))
        Beneficiary.delete(({ contactId }) => contactId === idOfContactToDelete)
        AssetCoOwnerContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        LiabilityCoOwnerContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        ChildContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        ExecutorContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        GuardianContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        WitnessContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        SubstituteExecutorContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        SubstituteGuardianContact.delete(({ contactId }) => contactId === idOfContactToDelete)
        Contact.delete(idOfContactToDelete)
      })

      await dispatch('persistRecordToVault', {
        entityTypes: [
          'assetBeneficiaries',
          'residuaryEstateBeneficiaries',
          'monetaryGifts',
          'monetaryDebts',
          'beneficiaries',
          'assetCoOwnerContacts',
          'childContacts',
          'executorContacts',
          'guardianContacts',
          'witnessContacts',
          'substituteExecutorContacts',
          'substituteGuardianContacts',
          'contacts'
        ],
        message: 'Contact successfully deleted'
      })
    },

    deleteVault: async ({ state, commit }) => {
      try {
        return await attemptApiCall(
          deleteVault,
          {
            userId: state.userId,
            jwt: sessionStorage.getItem('jwt'),
            entityType: 'user'
          }
        )
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)
        throw error
      }
    },

    deleteAllUserFiles: async ({ state }) => {
      await Promise.all([
        attemptApiCall(
          deleteAllUserFilesFromAwsS3,
          {
            userId: state.userId,
            jwt: sessionStorage.getItem('jwt')
          }
        ),
        attemptApiCall(
          deleteAllUserFilesFromCloudflareR2,
          {
            userId: state.userId,
            jwt: sessionStorage.getItem('jwt')
          }
        )
      ])
    },

    deleteAllUserComms: async ({ state }) => {
      return attemptApiCall(
        deleteAllUserComms,
        {
          userId: state.userId,
          jwt: sessionStorage.getItem('jwt')
        }
      )
    },

    deleteAllUserPurchases: async ({ state }) => {
      return attemptApiCall(
        deleteAllUserPurchases,
        {
          userId: state.userId,
          jwt: sessionStorage.getItem('jwt')
        }
      )
    },

    deleteUserAccount: async ({ commit, dispatch }) => {
      await dispatch('deleteUserFromIdp')
      await dispatch('deleteVault')

      try {
        dispatch('deleteAllUserFiles')
        dispatch('deleteAllUserComms')
        // dispatch('deleteAllUserPurchases')
      } catch {}

      commit('snackbar/update', {
        type: 'success',
        message: 'User Account successfully deleted'
      })
    },

    persistRecordToVault: async ({ state, commit, getters }, { entityTypes, message }) => {
      const promiseOutcomes = await Promise.allSettled(entityTypes
        .map(async entityType => {
          const encryptedObject = await encryptString({
            unencryptedString: JSON.stringify(state.entities[entityType]),
            publicKeyObjects: getters.publicKeyObjects,
            armoredPrivateKey: state.armoredPrivateKey
          })

          return await attemptApiCall(
            putVaultEntity,
            {
              userId: state.userId,
              entityType,
              object: encryptedObject,
              jwt: sessionStorage.getItem('jwt')
            }
          )
        })
      )

      const allFulfilled = promiseOutcomes.every(({ status }) => status === 'fulfilled')

      if (allFulfilled) {
        commit('removeObjectTypesFromApiUnsynced', entityTypes)

        if (message) commit('snackbar/update', { type: 'success', message })
      }

      if (!allFulfilled) {
        const thereIsA401Response = promiseOutcomes
          .filter(({ status }) => status === 'rejected')
          .some(({ reason }) => reason.response?.status === 401)

        if (thereIsA401Response) commit('setShowRefreshJwtDialog', true)

        const rejectedEntityTypes = promiseOutcomes
          .map((outcome, index) => ({ ...outcome, index }))
          .filter(({ status }) => status === 'rejected')
          .map(({ index }) => entityTypes[index])

        if (rejectedEntityTypes.length) {
          commit('addObjectTypesToApiUnsynced', rejectedEntityTypes)

          commit('snackbar/update', {
            type: 'warning',
            message: 'Your item was saved locally, but failed to sync to our servers'
          })
        }
      }
    },

    setUpNewVault: async ({ dispatch }, { userId, firstName, middleNames, lastName, addresses, isPrivate, billingAddress1, billingAddress2, billingPostcode, billingCountry }) => {
      User.create({
        data: {
          id: userId,
          firstName,
          middleNames,
          lastName,
          addresses,
          authorizedThirdPartyPublicKeyObjects: [],
          isPrivate,
          billingAddress1,
          billingAddress2,
          billingPostcode,
          billingCountry
        }
      })

      const user = User.find(userId)

      Will.insert({ data: { user } })
      Group.create({ data: groups })

      await dispatch('persistRecordToVault', {
        entityTypes: ['user', 'will', 'groups']
      })
    },

    repopulateState: async ({ commit, dispatch }, {
      userId,
      armoredPublicKey,
      encryptedArmoredPrivateKey,
      subscribed,
      subscribedUntilDate,
      subscribedFeatures,
      subscriptionProductId,
      securityKeyObjects,
      locale,
      rpId,
      challenge,
      jwt
    }) => {
      try {
        const credentialIds = securityKeyObjects.map(({ credentialId }) => credentialId)

        const armoredPrivateKey = await extractArmoredPrivateKeyFromSecurityKey({
          encryptedArmoredPrivateKey,
          credentialIds,
          rpId,
          challenge
        })

        await dispatch('insertVaultRecordsIntoStore', { userId, armoredPrivateKey, jwt })

        commit('setUserVars', { userId, armoredPublicKey, armoredPrivateKey, subscribed, subscribedUntilDate, subscribedFeatures, subscriptionProductId, locale })

        return true
      } catch (error) {
        dispatch('logError', {
          error,
          fileName: 'store',
          functionName: 'repopulateState'
        })

        throw error
      }
    },

    openApp ({ commit }, goToRouteName) {
      commit('setShowAppOpen', true)

      if (goToRouteName) router.push({ name: goToRouteName })
    },

    insertVaultRecordsIntoStore: async ({ commit }, { userId, armoredPrivateKey, jwt }) => {
      try {
        const { data: { userId: userIdFromVault, timestamp, ...entitiesObject } } = await attemptApiCall(getVault, { userId, jwt })

        if (userIdFromVault !== userId) throw new Error('Returned userId does not match user')

        const documentRecordsEntity = await getDocumentRecordsEntity({ entitiesObject, armoredPrivateKey })
        const theOtherEntities = await getTheOtherEntities({ entitiesObject, armoredPrivateKey });

        [
          ...(documentRecordsEntity ? [documentRecordsEntity] : []),
          ...theOtherEntities
        ].forEach(entity => commit('setEntities', {
          key: entity.$name,
          value: entity
        }))
      } catch (error) {
        commit('setAppErrorState', {
          status: true,
          message: error.message
        })

        throw error
      }
    },

    persistDocumentRecordToVault: async ({ state, commit, getters, dispatch }, documentRecord) => {
      try {
        const metaObject = {
          id: documentRecord.reference,
          $id: documentRecord.reference,
          label: documentRecord.label,
          locale: documentRecord.locale,
          version: documentRecord.version,
          type: documentRecord.type
        }

        const object = {
          reference: documentRecord.reference,

          meta: await encryptString({
            unencryptedString: JSON.stringify(metaObject),
            publicKeyObjects: getters.publicKeyObjects,
            armoredPrivateKey: state.armoredPrivateKey
          }),

          data: await encryptString({
            unencryptedString: documentRecord.content,
            publicKeyObjects: getters.publicKeyObjects,
            armoredPrivateKey: state.armoredPrivateKey
          })
        }

        await attemptApiCall(
          putVaultEntity,
          {
            userId: state.userId,
            entityType: 'documentRecords',
            object,
            jwt: sessionStorage.getItem('jwt')
          }
        )

        commit('snackbar/update', {
          type: 'success',
          message: 'Record successfully saved'
        })
      } catch (error) {
        commit('addObjectTypesToApiUnsynced', 'documentRecords')
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        commit('snackbar/update', {
          type: 'warning',
          message: 'Your item was saved locally, but failed to sync to our servers'
        })

        dispatch('logError', {
          error,
          fileName: 'store',
          functionName: 'persistDocumentRecordToVault'
        })
      }
    },

    renderEngine: async ({ commit }, { context, locale, version: requestedVersion, type }) => {
      if (!type) throw new Error('type not defined')
      if (!locale) throw new Error('locale not defined')

      try {
        const { data: { timestamp, version, documentType, template } } = await getTemplate({
          jwt: sessionStorage.getItem('jwt'),
          type,
          locale,
          version: requestedVersion
        })

        const source = decodeURIComponent(escape(atob(template)))

        return {
          timestamp,
          locale,
          version,
          documentType,
          template: mvHandlebars(source, context)
        }
      } catch (error) {
        // console.error(error)
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        throw error
      }
    },

    updateWillProperty: async ({ dispatch }, data) => {
      Will.update({
        where: Will.query().first().id,
        data
      })

      await dispatch('persistRecordToVault', {
        entityTypes: ['will'],
        message: 'Saved'
      })
    },

    updateSecurityKeyObjectsToIdp: async ({ commit }, { securityKeyObjects }) => {
      try {
        return await attemptApiCall(
          updateCredentials,
          {
            jwt: sessionStorage.getItem('jwt'),
            securityKeyObjects
          }
        )
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        throw new Error('User Security Keys failed to update to our servers')
      }
    },

    updateUserDetailsToIdp: async (
      { commit },
      { avatar, firstName, middleNames, lastName, isPrivate, billingAddress1, billingAddress2, billingPostcode, billingCountry }
    ) => {
      try {
        return await attemptApiCall(
          putUserProfile,
          {
            jwt: sessionStorage.getItem('jwt'),
            avatar,
            firstName,
            middleNames,
            lastName,
            isPrivate,
            billingAddress1,
            billingAddress2,
            billingPostcode,
            billingCountry
          }
        )
      } catch (error) {
        if (error.response?.status === 401) commit('setShowRefreshJwtDialog', true)

        throw error
      }
    },

    setExchangeRateData: async ({ commit }) => {
      const currencies = await getCurrencies()
      const exchangeRatesObject = await getLatestExchangeRates()

      commit('setExchangeRateDataInState', {
        currencies,
        exchangeRatesObject
      })
    },

    setEssentialApiCallData: async ({ dispatch }) => {
      await Promise.all([
        dispatch('todos/setTodos'),
        dispatch('setHelpObjects')
      ])
    },

    setNonEssentialApiCallData: async ({ dispatch }, router) => {
      try {
        dispatch('setExchangeRateData')
        dispatch('messages/setUnreadMessageIds')
        dispatch('letters/getLetters')
        await Promise.all([
          dispatch('getAndSetContactDetailsFromBackend'),
          dispatch('getAndSetSubscriptionPlans'),
          dispatch('marketplace/setCategoriesAndProducts', router),
          dispatch('marketplace/setPurchases'),
          dispatch('help/setCategories', router),
          dispatch('knowledgeBase/setCategories', router),
          dispatch('insights/setCategories', router),
          dispatch('services/setCategories', router)
        ])
      } catch (error) {
        dispatch('logError', {
          error,
          fileName: 'store',
          functionName: 'setNonEssentialApiCallData'
        })
      }
    },

    retry: async ({ state, dispatch }) => {
      if (state.apiUnsynced.length) {
        await dispatch('persistRecordToVault', {
          entityTypes: state.apiUnsynced,
          message: 'Sync successful'
        })
      }

      await dispatch('files/handleAssociatedFilesLogic')
    },

    sendFeedback: async ({ commit }, bodyObject) => {
      try {
        await postFeedback({
          jwt: sessionStorage.getItem('jwt'),
          bodyObject
        })

        commit('snackbar/update', {
          type: 'success',
          message: 'Feedback message successfully sent'
        })

        commit('setShowFeedbackBar', false)
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        commit('snackbar/update', {
          type: 'error',
          message: 'Failed to send feedback message'
        })
        throw error
      }
    },

    setHelpObjects: async ({ commit }) => {
      try {
        const response = await getHelpObjects({
          jwt: sessionStorage.getItem('jwt')
        })

        commit('setHelpObjects', response.data)
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    findAndSetHelpObject: ({ state, commit }, { id, isInitial = false }) => {
      const helpObject = state.helpObjects.find(({ helpId }) => helpId === id) || {}

      if (state.isSmall) state.showSlidingHelp = !isInitial

      commit('setHelpObject', helpObject)
    },

    getDashboardFeaturePanels: async ({ commit }) => {
      try {
        return await attemptApiCall(getDashboardFeaturePanels, sessionStorage.getItem('jwt'))
      } catch (error) {
        commit('snackbar/update', {
          type: 'warning',
          message: 'Trouble getting dashboard features'
        })

        return []
      }
    },

    getAndSetContactDetailsFromBackend: async ({ dispatch }) => {
      try {
        const contactsToQueryBackendWith = Contact
          .query()
          .where(({ userId, emailAddresses }) => !userId && !!emailAddresses.length)
          .get()
          .map(({ id, emailAddresses }) => ({ id, emailAddresses }))

        if (!contactsToQueryBackendWith.length) return

        const contactsUpdatedByBackend = await attemptApiCall(
          postContactInfoToIdp,
          { jwt: sessionStorage.getItem('jwt'), contacts: contactsToQueryBackendWith }
        )

        let contactsUpdated = false
        contactsUpdatedByBackend.forEach(contact => {
          if (contact.userId) {
            Contact.update({ where: contact.id, data: { userId: contact.userId } })
            contactsUpdated = true
          }
        })

        if (contactsUpdated) await dispatch('persistRecordToVault', { entityTypes: ['contacts'] })
      } catch (error) { console.log(error) }
    },

    getAndSetSubscriptionPlans: async ({ commit }) => {
      try {
        const subscriptionPlans = await getSubscriptionPlans(sessionStorage.getItem('jwt'))

        commit('setSubscriptionPlans', subscriptionPlans)
      } catch (error) { console.log(error) }
    },

    getUserPublicKeysFromIdp: async ({ dispatch }, { userIdsToGetPublicKeysFor = [], allowUserPublicKeyNotFoundError = false }) => {
      const userArmoredPublicKeysFromIdp = await attemptApiCall(
        postUserIdsToRetrievePublicKeysFromIdp,
        { jwt: sessionStorage.getItem('jwt'), userIds: userIdsToGetPublicKeysFor }
      )

      let aUserPublicKeyIsMissing = false

      userIdsToGetPublicKeysFor.forEach(userId => {
        const userIsMissingRemoveUserIdFromContacts = !userArmoredPublicKeysFromIdp
          .find(userReturnedFromIdp => userReturnedFromIdp.userId === userId)

        if (userIsMissingRemoveUserIdFromContacts) {
          aUserPublicKeyIsMissing = true

          Contact.update({
            where: contact => (contact.userId === userId),
            data: { userId: '' }
          })
        }
      })

      if (aUserPublicKeyIsMissing) await dispatch('persistRecordToVault', { entityTypes: ['contacts'] })
      if (allowUserPublicKeyNotFoundError && aUserPublicKeyIsMissing) throw new Error('One or more users cannot be found to send to')

      return userArmoredPublicKeysFromIdp
        .map(({ userId, armoredPublicKey }) => ({ userId, armoredPublicKey }))
    },

    getAndSetContactsInviteStatus: async ({ commit }) => {
      const callGetContactInvitesByUser = async () => {
        try {
          return await attemptApiCall(getContactInvitesByUser, sessionStorage.getItem('jwt'))
        } catch (error) {
          console.error(error)
          return []
        }
      }

      const contacts = Contact.all()

      const sentUserContactInvites = await callGetContactInvitesByUser()

      if (!sentUserContactInvites.length) {
        commit('setContactsWithInviteStatus', contacts)
        return
      }

      const contactInviteStatus = await Promise.all(
        contacts
          .map(async contact => {
            if (!contact.emailAddresses.length) return { ...contact, inviteStatus: '' }

            const emailAddressesHashed = await Promise.all(contact.emailAddresses.map(async ({ value: email }) => (await fastHash(email))))

            const matchedContactInvite = sentUserContactInvites
              .find(({ email: hashedEmail }) => emailAddressesHashed.includes(hashedEmail))

            return matchedContactInvite
              ? { ...contact, inviteStatus: matchedContactInvite.status }
              : { ...contact, inviteStatus: '' }
          })
      )

      commit('setContactsWithInviteStatus', contactInviteStatus)
    }
  },

  mutations: {
    setAppVersion: (state, { key, value }) => Vue.set(state, 'appVersion', value),
    setSubscriptionPlans: (state, value) => Vue.set(state, 'subscriptionPlans', value),
    setAppErrorState: (state, errorObject) => Vue.set(state, 'appError', errorObject),
    setEntities: (state, { key, value }) => Vue.set(state.entities, key, value),
    setShowRefreshJwtDialog: (state, value) => Vue.set(state, 'showRefreshJwtDialog', value),
    setStateValue: (state, { key, value }) => Vue.set(state, key, value),
    setShowAppOpen: (state, value) => Vue.set(state, 'showAppOpen', value),
    setIsSmall: (state, value) => Vue.set(state, 'isSmall', value),
    setShowFeedbackBar: (state, value) => Vue.set(state, 'showFeedbackBar', value),
    setShowSlidingHelp: (state, value) => Vue.set(state, 'showSlidingHelp', value),
    setUserUpdatedFromJwt: (state) => Vue.set(state, 'userUpdatedFromJwt', true),
    setContactsWithInviteStatus: (state, value) => Vue.set(state, 'contactsWithInviteStatus', value),

    setBrowserDetails: (state, { os, name, version }) => {
      Vue.set(state, 'operatingSystem', os)
      Vue.set(state, 'browserName', name)
      Vue.set(state, 'browserVersion', version)
    },

    setHelpObjects: (state, helpObjects) => {
      state.helpObjects = helpObjects.map(helpObject => ({
        ...helpObject,
        blocks: helpObject.content
      }))
    },

    setHelpObject: (state, helpObject) => {
      state.currentHelpObject = {
        ...helpObject,
        ...(helpObject.blocks && {
          html: portableTextToHtml(helpObject.blocks)
        })
      }
    },

    setUserVars: (state, { userId, armoredPublicKey, armoredPrivateKey, subscribed, subscribedUntilDate, subscribedFeatures, subscriptionProductId, locale }) => {
      state.userId = userId
      state.subscribed = subscribed
      state.subscribedUntilDate = subscribedUntilDate
      state.subscribedFeatures = subscribedFeatures
      state.subscriptionProductId = subscriptionProductId
      state.armoredPublicKey = armoredPublicKey
      state.armoredPrivateKey = armoredPrivateKey
      state.locale = locale
    },

    setExchangeRateDataInState: (state, { currencies, exchangeRatesObject }) => {
      state.currencies = currencies
      state.exchangeRatesObject = exchangeRatesObject
    },

    addObjectTypesToApiUnsynced: (state, entityTypes) => {
      Vue.set(
        state,
        'apiUnsynced',
        [...new Set([...state.apiUnsynced, ...entityTypes])]
      )
    },

    removeObjectTypesFromApiUnsynced: (state, entityTypes) => {
      Vue.set(
        state,
        'apiUnsynced',
        [...new Set(state.apiUnsynced.filter(type => !entityTypes.includes(type)))]
      )
    }
  }
}
