<template>
  <div>
    <e-dialog v-if="showSignOutFromMicrosoftDialog" data-test="logout-dialog">
      <p>
        Operation complete, you should now sign out of your Microsoft account
      </p>

      <e-button class="microsoft primary out" @click="signOutFromMicrosoft">
        Sign Out
      </e-button>
    </e-dialog>

    <e-button
      data-test="import-contacts-button"
      class="microsoft primary"
      @click="importContacts"
    >
      Microsoft
    </e-button>
  </div>
</template>

<script>
'use strict'

import Contact from '@/models/Contact'

import { mergeContacts } from './contactManager'
import EDialog from '@/components/dialogs/EDialog'
import getContacts from '@/apis/microsoft/getContacts'
import { createId } from '@/utils'
import { format, parseISO } from 'date-fns'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
    redirectUri: process.env.VUE_APP_MICROSOFT_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common',
    postLogoutRedirectUri: process.env.VUE_APP_MICROSOFT_REDIRECT_URI
  },

  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}

const requestObject = {
  scopes: ['Contacts.Read']
}

export default {
  name: 'ImportMicrosoftContacts',

  components: {
    EDialog
  },

  data () {
    return {
      username: '',
      myMSALObj: {},
      showSignOutFromMicrosoftDialog: false
    }
  },

  methods: {
    async importContacts () {
      try {
        this.myMSALObj = new msal.PublicClientApplication(msalConfig)

        this.username = await this.signIn()
        const contacts = await this.getContacts()
        if (contacts.length) {
          const updatedContacts = await this.parseContacts(contacts)

          this.$store.commit('snackbar/update', {
            type: 'success',
            message: `Your Address Book now has ${updatedContacts.length} ${updatedContacts.length === 1 ? 'contact' : 'contacts'}.`
          })
        } else {
          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: 'No contacts found'
          })
        }
      } catch (error) {
        console.error(error)
        this.$store.commit('snackbar/update', {
          type: 'warning',
          message: error.message
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ImportMicrosoftContacts',
          functionName: 'ManageContacts'
        })
      } finally {
        this.showSignOutFromMicrosoftDialog = true
      }
    },

    async signIn () {
      const response = await this.myMSALObj.loginPopup(requestObject)
      return response.account.username
    },

    async getContacts () {
      const { accessToken } = await this.getTokenPopup(requestObject)
      const contacts = []
      let contactsComplete = false
      let contactsEndpoint = 'https://graph.microsoft.com/v1.0/me/contacts'
      while (!contactsComplete) {
        const getContactsResponse = await getContacts({
          endpoint: contactsEndpoint,
          accessToken
        })
        contacts.push(...getContactsResponse.data.value)
        if (getContactsResponse.data['@odata.nextLink']) contactsEndpoint = getContactsResponse.data['@odata.nextLink']
        else contactsComplete = true
      }
      return contacts
    },

    async getTokenPopup (requestObject) {
      requestObject.account = this.myMSALObj.getAccountByUsername(this.username)

      try {
        return this.myMSALObj.acquireTokenSilent(requestObject)
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return this.myMSALObj.acquireTokenPopup(requestObject)
        }

        throw error
      }
    },

    async parseContacts (contacts) {
      // add a deduplication on new contacts before merging
      const updatedContacts = mergeContacts(contacts.map(contact => createContactObject(contact)))
      Contact.insertOrUpdate({ data: updatedContacts })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['contacts']
      })

      await this.$store.dispatch('getAndSetContactDetailsFromBackend')
      return updatedContacts
    },

    async signOutFromMicrosoft () {
      try {
        const logoutRequest = {
          account: this.myMSALObj.getAccountByUsername(this.username)
        }

        await this.myMSALObj.logoutPopup(logoutRequest)
      } catch {}

      this.showSignOutFromMicrosoftDialog = false
    }
  }
}

const createContactObject = contact => {
  contact.givenName = contact.givenName || contact.displayName || contact.yomiGivenName || ''
  const gNameArray = contact?.givenName?.split(' ')
  if (gNameArray && gNameArray.length >= 2 && (!contact.surname || contact.surname === '')) {
    contact.surname = gNameArray[gNameArray.length - 1]
    contact.givenName = contact.givenName.split(' ' + contact.surname)[0]
  }
  if (!contact.givenName) return

  const contactObject = {
    firstName: contact.givenName,
    middleNames: contact.middleName || '',
    lastName: contact?.surname || '',
    prefixes: contact?.title || '',
    suffixes: contact?.generation || '',
    jobTitle: contact?.jobTitle || '',
    organisationName: contact?.companyName || '',
    ...contact.birthday && { dateOfBirth: format(parseISO(contact.birthday), 'yyyy-MM-dd') },
    source: 'microsoft',

    addresses: [],

    phoneNumbers: [
      ...contact.homePhones.map(phoneNumber => createPhoneNumberObject(phoneNumber, 'home')),
      ...contact.businessPhones.map(phoneNumber => createPhoneNumberObject(phoneNumber, 'work'))
    ],

    emailAddresses: contact.emailAddresses?.map(({ address }) => ({
      id: createId(),
      isPref: '',
      value: address
    })) || []
  }

  if (contact.mobilePhone) {
    contactObject.phoneNumbers.push(createPhoneNumberObject(contact.mobilePhone, 'mobile'))
  }

  if (objectHasKeys(contact.homeAddress)) {
    contactObject.addresses.push(createAddressObject(contact.homeAddress))
  }

  if (objectHasKeys(contact.businessAddress)) {
    contactObject.addresses.push(createAddressObject(contact.businessAddress))
  }

  if (objectHasKeys(contact.otherAddress)) {
    contactObject.addresses.push(createAddressObject(contact.otherAddress))
  }

  return contactObject
}

const objectHasKeys = object => {
  return Object.keys(object).length
}

const createAddressObject = address => {
  return {
    id: createId(),
    isPref: '',
    placeResult: {
      formatted_address: parseAddress(address)
    },

    returnData: {}
  }
}

const createPhoneNumberObject = (phoneNumber, type) => {
  return {
    id: createId(),
    isPref: '',
    type,
    value: phoneNumber
  }
}

const parseAddress = ({ street = '', city = '', state = '', postalCode = '', countryOrRegion = '' }) => {
  return `${street} ${city} ${state} ${postalCode} ${countryOrRegion}`
    .replace(/\{2,}/g)
    .trim()
}
</script>

<style lang="scss" scoped>
.microsoft {
  background-color: white !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAD1BMVEX/////uQB/ugAApO/yUCL56E7OAAAAH0lEQVR42mMY4oAFBhiYYGBUmiBghgEGRhgYlR6kAAD8KwMrw3bMQQAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-size: contain;
  color: grey;
  padding-left: 54px;

  &.out {
    margin: auto;
    width: 40%;
  }

  &:hover {
    background-color: lighten(white, 6%);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAD1BMVEX/////uQB/ugAApO/yUCL56E7OAAAAH0lEQVR42mMY4oAFBhiYYGBUmiBghgEGRhgYlR6kAAD8KwMrw3bMQQAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-size: contain;
    border-color: lighten($button-color-three, 6%);
    color: darkgray !important;
  }
}
</style>
