'use strict'

import Accounts from '@/views/Accounts/Accounts'
import AccountsDefault from '@/views/Accounts/AccountsDefault'
import LoadingSpinner from '@/components/LoadingSpinner'

import FormView from '@/views/FormView'

const subscriptionFeatureId = null

export default {
  path: '/accounts',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Accounts
  },
  meta: {
    title: 'Accounts',
    subscriptionFeatureId
  },
  children: [
    {
      path: '',
      name: 'Accounts',
      components: {
        appClosedLayout: LoadingSpinner,
        default: AccountsDefault
      },
      meta: {
        title: 'Accounts',
        subscriptionFeatureId
      }
    },

    {
      path: 'investment/new',
      name: 'NewInvestment',
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      props: {
        default: () => ({
          form: 'AccountForm'
        })
      },
      meta: {
        title: 'New Investment',
        subscriptionFeatureId
      }
    },

    {
      path: 'investment/:id?',
      name: 'Investment',
      props: {
        default: route => ({
          form: 'AccountForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Investment',
        subscriptionFeatureId
      }
    },

    {
      path: 'monetary-account/new',
      name: 'NewMonetaryAccount',
      props: {
        default: () => ({
          form: 'AccountForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Monetary Account',
        subscriptionFeatureId
      }
    },

    {
      path: 'monetary-account/:id?',
      name: 'MonetaryAccount',
      props: {
        default: route => ({
          form: 'AccountForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Monetary Account',
        subscriptionFeatureId
      }
    },

    {
      path: 'pension/new',
      name: 'NewPension',
      props: {
        default: () => ({
          form: 'AccountForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Pension',
        subscriptionFeatureId
      }
    },

    {
      path: 'pension/:id?',
      name: 'Pension',
      props: {
        default: route => ({
          form: 'AccountForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Pension',
        subscriptionFeatureId
      }
    },

    {
      path: 'otherAccount/new',
      name: 'NewOtherAccount',
      props: {
        default: () => ({
          form: 'AccountForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Other',
        subscriptionFeatureId
      }
    },

    {
      path: 'otherAccount/:id?',
      name: 'OtherAccount',
      props: {
        default: route => ({
          form: 'AccountForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Other',
        subscriptionFeatureId
      }
    }
  ]
}
