'use strict'

import genderTypes from '@/json_files/genderTypes.json'
import phoneNumberTypes from '@/json_files/phoneNumberTypes.json'
import { v4 as uuid } from 'uuid'
import { differenceInYears, parse } from 'date-fns'

const createId = () => uuid()
const getAge = date => differenceInYears(new Date(), parse(date, 'yyyy-MM-dd', new Date()))
const currentFileUploadLocation = 'R2'

const genderTypeLookup = Object.fromEntries(
  genderTypes.map(({ text, value }) => ([value, text]))
)

const phoneNumberTypeLookup = Object.fromEntries(
  phoneNumberTypes.map(({ text, value }) => ([value, text]))
)

export {
  createId,
  currentFileUploadLocation,
  genderTypeLookup,
  getAge,
  phoneNumberTypeLookup
}
