'use strict'

import convertBase64BlockToArrayBuffer from './convertBase64BlockToArrayBuffer'
import { key, decrypt, message } from 'openpgp'

export default async ({ encryptedString, armoredPrivateKey }) => {
  const privateKey = await key.readArmored(armoredPrivateKey)
  const { data: decryptedString } = await decrypt({
    message: await message.read(new Uint8Array(convertBase64BlockToArrayBuffer(encryptedString))),
    privateKeys: privateKey.keys
  })

  return decryptedString
}
