'use strict'

import Todo from './Todo'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

const getFullName = ({ firstName, lastName }) => firstName ? `${firstName} ${lastName}` : ''

export default class User extends Model {
  constructor (...args) {
    super(...args)

    if (typeof args[0] === 'object') {
      this.fullName = getFullName(args[0])
    }
  }

  static get entity () { return 'user' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      firstName: this.string(''),
      middleNames: this.string(''),
      lastName: this.string(''),
      dateOfBirth: this.string(),
      placeOfBirth: this.string(),
      genderType: this.string(),
      genderIdentity: this.string(),

      addresses: this.attr(() => []),
      phoneNumbers: this.attr(() => []),
      emailAddresses: this.attr(() => []),

      occupation: this.string(),
      avatar: this.string(),

      requests: this.attr([
        {
          id: 'funeralWishes',
          name: 'Funeral Wishes',
          text: ''
        },

        {
          id: 'memorial',
          name: 'Memomrial',
          text: ''
        }
      ]),

      nationalInsuranceNumber: this.string(),
      licenses: this.attr(() => []),
      passports: this.attr(() => []),
      nationalHealthServiceNumber: this.string(),
      governmentGatewayUserId: this.string(),
      selfAssessmentUniqueTaxpayerReference: this.string(),
      employerPayAsYouEarnReference: this.string(),

      todos: this.hasMany(Todo, 'userId'),
      systemTodoIsDoneArray: this.attr(() => []),
      authorizedThirdPartyPublicKeyObjects: this.attr(() => []),
      willId: this.attr(null),
      isValid: this.boolean(false),
      permissions: this.attr(() => []),
      isPrivate: this.boolean(false),

      subscribed: this.boolean(false),
      subscribedUntilDate: this.number(0),
      subscribedFeatures: this.attr(() => []),
      subscriptionProductId: this.string(),
      billingAddress1: this.string(),
      billingAddress2: this.string(),
      billingPostcode: this.string(),
      billingCountry: this.string(),
      locale: this.string()
    }
  }

  // get fullName () {
  //   return `${this.firstName} ${this.lastName}`
  // }

  get selectText () {
    return `Me   - ${this.fullName}`
  }
}
