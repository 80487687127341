'use strict'

import { extend, validate } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', required)

const getRouterLinkForContact = ({ id, fullName }) => {
  const initial = fullName.charAt(0).toUpperCase()

  return `<router-link to='/directory/address-book/${initial}/${id}/edit' class='link'>${fullName}</router-link>`
}

export default async willObj => {
  const validationPromises = []

  validationPromises.push(validate(
    willObj.disposingOfAllWorldwideAssets,
    {
      required: { allowFalse: false }
    },
    {
      name,
      customMessages: {
        required: "You must tick the <router-link to='/will/introduction/creating-your-will' class='link'>'I am disposing of all worldwide assets'</router-link> checkbox"
      }
    }
  ))

  validationPromises.push(validate(
    willObj.user.isValid,
    'required',
    {
      name: 'Your user profile',
      customMessages: {
        required: "Your <router-link to='/account/profile' class='link'>user profile</router-link> has missing information"
      }
    }
  ))

  const remainingEstateOptions = [
    willObj.remainingEstateToPartner,
    willObj.remainingEstateToChildren,
    willObj.remainingEstateToGrandchildren
  ]

  if (remainingEstateOptions.every(value => !value) && !willObj.residuaryEstateBeneficiaries.length) {
    validationPromises.push(validate(
      false,
      {
        required: { allowFalse: false }
      },
      {
        name: 'remaining estate instruction or residuary estate beneficiary missing',
        customMessages: {
          required: "You must select who you want to pass your <router-link to='/will/residual/provisions' class='link'>remaining estate to as an absolute gift</router-link>, or select a <router-link to='/will/residual/contingency' class='link'>residuary estate beneficiary</router-link>"
        }
      }
    ))
  }

  if (willObj.residualPersonalPossessionsInstruction === 'outrightGift' && !willObj.residualPersonalPossessionsBeneficiaries.length) {
    validationPromises.push(validate(
      false,
      {
        required: { allowFalse: false }
      },
      {
        name: 'residual personal possessions instruction set to outright gift, with no beneficiary selected .',
        customMessages: {
          required: "You have selected 'An outright gift' for your <router-link to='/will/distribution/possessions' class='link'>Residual personal possessions instruction</router-link>. If this option has been selected, you must select at least one beneficiary."
        }
      }
    ))
  }

  if (willObj.partner) {
    validationPromises.push(validate(
      willObj.partner.isValid,
      {
        required: { allowFalse: false }
      },
      {
        name: 'Your partner',
        customMessages: {
          required: `Your partner ${getRouterLinkForContact({ id: willObj.partner.id, fullName: willObj.partner.fullName })} has missing information`
        }
      }
    ))
  }

  willObj.children.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Child: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  willObj.guardians.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Guardian: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  willObj.substituteGuardians.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Substitute guardian: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  if (!willObj.executors.length) {
    validationPromises.push(validate(
      false,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: "You need to <router-link to='/will/executors-and-witnesses/executors' class='link'>add an executor</router-link>"
        }
      }
    ))
  }

  willObj.executors.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Executor: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  willObj.substituteExecutors.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Substitute executor: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  willObj.witnesses.forEach(({ id, fullName, isValid }) => {
    validationPromises.push(validate(
      isValid,
      {
        required: { allowFalse: false }
      },
      {
        name,
        customMessages: {
          required: `Witness: ${getRouterLinkForContact({ id, fullName })} has missing information`
        }
      }
    ))
  })

  willObj
    .nonPropertyAssets
    .filter(({ beneficiaries }) => beneficiaries?.length)
    .forEach(({ name, beneficiaries, coOwners }) => {
      beneficiaries
        .filter(({ type }) => type === 'contact')
        .forEach(({ contact: { isValid, type, id, fullName }, contactSubstitute }) => {
          if (type === 'person') {
            validationPromises.push(validate(
              isValid,
              {
                required: { allowFalse: false }
              },
              {
                name,
                customMessages: {
                  required: `Asset: ${name} - Beneficiary: ${getRouterLinkForContact({ id, fullName })} has missing information`
                }
              }
            ))
          }

          if (contactSubstitute) {
            validationPromises.push(validate(
              contactSubstitute.isValid,
              {
                required: { allowFalse: false }
              },
              {
                name,
                customMessages: {
                  required: `Asset: ${name} - Substitute Beneficiary: ${getRouterLinkForContact({ id: contactSubstitute.id, fullName: contactSubstitute.fullName })} has missing information`
                }
              }
            ))
          }
        })

      coOwners
        .forEach(({ isValid, id, fullName }) => {
          validationPromises.push(validate(
            isValid,
            {
              required: { allowFalse: false }
            },
            {
              name,
              customMessages: {
                required: `Asset: ${name} - Co-owner: ${getRouterLinkForContact({ id, fullName })} has missing information`
              }
            }
          ))
        })
    })

  willObj
    .propertyAssets
    .filter(({ beneficiaries }) => beneficiaries?.length)
    .forEach(({ name, beneficiaries, coOwners }) => {
      beneficiaries
        .filter(({ type }) => type === 'contact')
        .forEach(({ contact: { isValid, type, id, fullName }, contactSubstitute }) => {
          if (type === 'person') {
            validationPromises.push(validate(
              isValid,
              {
                required: { allowFalse: false }
              },
              {
                name,
                customMessages: {
                  required: `Asset: ${name} - Beneficiary: ${getRouterLinkForContact({ id, fullName })} has missing information`
                }
              }
            ))
          }

          if (contactSubstitute) {
            validationPromises.push(validate(
              contactSubstitute.isValid,
              {
                required: { allowFalse: false }
              },
              {
                name,
                customMessages: {
                  required: `Asset: ${name} - Substitute Beneficiary: ${getRouterLinkForContact({ id: contactSubstitute.id, fullName: contactSubstitute.fullName })} has missing information`
                }
              }
            ))
          }
        })

      coOwners
        .forEach(({ isValid, id, fullName }) => {
          validationPromises.push(validate(
            isValid,
            {
              required: { allowFalse: false }
            },
            {
              name,
              customMessages: {
                required: `Asset: ${name} - Co-owner: ${getRouterLinkForContact({ id, fullName })} has missing information`
              }
            }
          ))
        })
    })

  willObj.monetaryGifts
    .forEach(({ beneficiary }) => {
      if (beneficiary.type === 'contact') {
        validationPromises.push(validate(
          beneficiary.contact.isValid,
          {
            required: { allowFalse: false }
          },
          {
            name,
            customMessages: {
              required: `Monetary gift beneficiary: ${getRouterLinkForContact({ id: beneficiary.contact.id, fullName: beneficiary.contact.fullName })} has missing information`
            }
          }
        ))
      }
    })

  willObj.monetaryDebts
    .forEach(({ debtor }) => {
      if (debtor.type === 'contact') {
        validationPromises.push(validate(
          debtor.contact.isValid,
          {
            required: { allowFalse: false }
          },
          {
            name,
            customMessages: {
              required: `Monetary debt debtor: ${getRouterLinkForContact({ id: debtor.contact.id, fullName: debtor.contact.fullName })} has missing information`
            }
          }
        ))
      }
    })

  const validationResults = await Promise.all(validationPromises)

  if (!willObj.partnerType && willObj.remainingEstateToPartner) {
    validationResults.push({
      errors: ["As you have selected 'To my partner' under the Residual > Provisions section, you also need to set your partner type under <router-link to='/will/my-family/partner' class='link'>My Family >Partner</router-link>"],
      valid: false
    })
  }

  if (!willObj.user.firstName) {
    validationResults.push({
      errors: ["You must complete your <router-link to='/account/profile' class='link'>user profile</router-link>"],
      valid: false
    })
  }

  const isValid = validationResults.every(({ valid }) => valid)

  if (isValid) return { isValid, issues: [] }

  return {
    isValid: false,
    issues: validationResults
      .reduce((accumulator, { errors }) => [
        ...accumulator,
        ...errors
      ], [])
  }
}
