<template>
  <div class="container">
    <div class="header">
      <h1>Setup Your MeaVitae Account - {{ onboardingStage }}/{{ numberOfStages }}</h1>

      <e-button
        class="icon-x-circle ghost"
        @click="closeUserSetup"
      />
    </div>

    <component
      :is="currentOnboardingStageComponent.componentName"
      v-bind="currentStageProps"
      @next-stage="moveToNextStageOrCompleteUserSetup"
      @save-security-key="updateIdpAndCreateNewUserVault($event)"
      @save-user-profile="updateProfileToVaultAndIdp($event)"
      @save-contacts="saveContactsToVault"
      @error-message="processComponentErrorMessage($event)"
    />
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import SecurityKeyRegistration from '@/components/SecurityKeyRegistration'
import Todo from '@/models/Todo'
import User from '@/models/User'
import UserSetupAddContacts from '@/components/UserSetupAddContacts'
import UserSetupProfile from '@/components/UserSetupProfile'
import UserSetupTermsAndConditions from '@/components/UserSetupTermsAndConditions'
import UserSetupWelcome from '@/components/UserSetupWelcome'
import addSecurityKeyDetailsAndPublicPrivateKeysToIdp from '@/auth/addSecurityKeyDetailsAndPublicPrivateKeysToIdp'
import aes from 'crypto-js/aes'
import generatePublicPrivateKeyPair from '@/auth/generatePublicPrivateKeyPair'
import jwtDecode from 'jwt-decode'
import { createId } from '@/utils'
import { randomBytes } from 'crypto'

export default {
  name: 'UserSetupOnBoarding',

  components: {
    EDialog,
    SecurityKeyRegistration,
    UserSetupAddContacts,
    UserSetupProfile,
    UserSetupTermsAndConditions,
    UserSetupWelcome
  },

  props: {
    jwt: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close-user-setup-onboarding',
    'user-setup-onboarding-complete'
  ],

  data () {
    return {
      onboardingStage: 1,
      userJwt: '',
      jwtData: {},
      rpId: '',
      challenge: '',
      envelopeKey: '',
      userId: '',
      firstName: '',
      middleNames: '',
      lastName: '',
      userEmailAddress: '',
      avatarUrl: '',
      permissions: '',
      subscribed: false,
      subscribedUntilDate: 0,
      subscriptionProductId: '',
      isPrivate: false,
      billingAddress1: '',
      billingAddress2: '',
      billingPostcode: '',
      billingCountry: '',
      locale: ''
    }
  },

  computed: {
    onboardingComponentLookup () {
      return {
        1: {
          componentName: 'UserSetupWelcome',
          props: {}
        },

        2: {
          componentName: 'UserSetupTermsAndConditions',
          props: {}
        },

        3: {
          componentName: 'SecurityKeyRegistration',
          props: {
            rpId: this.rpId,
            envelopeKey: this.envelopeKey,
            challenge: this.challenge,
            securityKeyObjects: [],
            securityKeyName: 'Master',
            securityKeyNotes: 'This is the security key setup when creating your MeaVitae account',
            userId: this.userId,
            userEmailAddress: this.userEmailAddress
          }
        },

        4: {
          componentName: 'UserSetupProfile',
          props: { avatarUrl: this.avatarUrl }
        },

        5: {
          componentName: 'UserSetupAddContacts',
          props: {}
        }
      }
    },

    numberOfStages () {
      return Object.keys(this.onboardingComponentLookup).length
    },

    currentOnboardingStageComponent () {
      return this.onboardingComponentLookup[this.onboardingStage] || this.onboardingComponentLookup[1]
    },

    currentStageProps () {
      return this.currentOnboardingStageComponent.props
    }
  },

  created () {
    this.userJwt = this.jwt
    this.updateDataFromJwt()

    this.envelopeKey = randomBytes(29).toString('hex')
  },

  methods: {
    processError ({ error, functionName }) {
      this.$store.commit('setAppErrorState', {
        status: true,
        message: error.response?.data?.message || error.message,
        type: error.response?.data?.type,
        link: error.response?.data?.link
      })

      this.$store.dispatch('logError', {
        error,
        fileName: 'UserSetupOnboarding',
        functionName
      })

      this.$store.dispatch('logoutUser', { jwt: this.userJwt })
      this.closeUserSetup()
    },

    processComponentErrorMessage (errorMessage) {
      const error = new Error(errorMessage)
      this.processError({ error, functionName: 'processComponentErrorMessage' })
    },

    closeUserSetup () {
      this.$emit('close-user-setup-onboarding', true)
    },

    updateDataFromJwt () {
      try {
        this.jwtData = jwtDecode(this.userJwt)

        this.rpId = this.jwtData?.rpId
        this.challenge = this.jwtData?.challenge
        this.securityKeyCredentialChallenge = this.jwtData?.challenge
        this.userId = this.jwtData?.userId
        this.userEmailAddress = this.jwtData?.email
        this.firstName = this.jwtData?.firstName
        this.middleNames = this.jwtData?.middleNames
        this.lastName = this.jwtData?.lastName
        this.avatarUrl = this.jwtData?.avatarUrl
        this.permissions = this.jwtData?.permissions
        this.subscribed = this.jwtData?.subscribed
        this.subscribedUntilDate = this.jwtData?.subscribedUntilDate
        this.subscriptionProductId = this.jwtData?.subscriptionProductId
        this.isPrivate = this.jwtData?.isPrivate
        this.locale = this.jwtData?.locale
        this.billingAddress1 = this.jwtData?.address1
        this.billingAddress2 = this.jwtData?.address2
        this.billingPostcode = this.jwtData?.postcode
        this.billingCountry = this.jwtData?.country
      } catch (error) {
        this.processError({ error, functionName: 'updateDataFromJwt' })
      }
    },

    moveToNextStageOrCompleteUserSetup () {
      if (!this.onboardingComponentLookup[(this.onboardingStage + 1)]) {
        this.finishUserSetup()
        return
      }

      this.onboardingStage++
    },

    async updateIdpAndCreateNewUserVault ({ securityKeyId, credentialId }) {
      try {
        const { armoredPublicKey, armoredPrivateKey } = await generatePublicPrivateKeyPair({
          name: `${this.firstName} ${this.lastName}`,
          email: this.userEmailAddress
        })

        const encryptedArmoredPrivateKey = aes
          .encrypt(JSON.stringify(armoredPrivateKey), this.envelopeKey)
          .toString()

        const updatedJwt = await addSecurityKeyDetailsAndPublicPrivateKeysToIdp({
          securityKeyId,
          armoredPublicKey,
          encryptedArmoredPrivateKey,
          credentialId,
          jwt: this.userJwt
        })

        this.$store.commit('setUserVars', { userId: this.userId, armoredPublicKey, armoredPrivateKey })

        sessionStorage.setItem('jwt', updatedJwt)
        this.userJwt = updatedJwt

        await this.$store.dispatch('setUpNewVault', {
          userId: this.userId,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          addresses: [{
            id: createId(),
            placeResult: {
              formatted_address: `${this.billingAddress1}, ${this.billingAddress2}, ${this.billingPostcode}, ${this.billingCountry}`.replace(', , ', ', ')
            },
            returnData: {}
          }],
          billingAddress1: this.billingAddress1,
          billingAddress2: this.billingAddress2,
          billingPostcode: this.billingPostcode,
          billingCountry: this.billingCountry
        })

        this.moveToNextStageOrCompleteUserSetup()
      } catch (error) {
        this.processError({ error, functionName: 'updateIdpAndCreateUserVault' })
      }
    },

    async updateProfileToVaultAndIdp ({ firstName, lastName, genderType, addresses, dateOfBirth, avatar }) {
      try {
        User.update({
          data: {
            id: this.userId,
            firstName: firstName,
            lastName: lastName,
            genderType: genderType,
            emailAddresses: this.userEmailAddress,
            addresses: addresses,
            dateOfBirth: dateOfBirth,
            isValid: true
          }
        })

        Todo.update({
          where: ({ title }) => title === 'Complete your details',
          data: {
            isDone: true
          }
        })

        const updatedJwt = await this.$store.dispatch('updateUserDetailsToIdp', {
          avatar: avatar,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          isPrivate: this.isPrivate,
          billingAddress1: this.billingAddress1,
          billingAddress2: this.billingAddress2,
          billingPostcode: this.billingPostcode,
          billingCountry: this.billingCountry
        })

        sessionStorage.setItem('jwt', updatedJwt)
        this.userJwt = updatedJwt

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['user', 'todos']
        })

        this.updateDataFromJwt()
        this.moveToNextStageOrCompleteUserSetup()
      } catch (error) {
        this.processError({ error, functionName: 'updateProfileToVaultAndIdp' })
      }
    },

    async saveContactsToVault () {
      try {
        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['user', 'will', 'contacts', 'childContacts']
        })

        this.moveToNextStageOrCompleteUserSetup()
      } catch (error) {
        this.processError({ error, functionName: 'saveContactsToVault' })
      }
    },

    finishUserSetup () {
      this.$emit(
        'user-setup-onboarding-complete',
        {
          userId: this.userId,
          avatarUrl: this.avatarUrl,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          email: this.userEmailAddress,
          permissions: this.permissions,
          subscribed: this.subscribed,
          subscribedUntilDate: this.subscribedUntilDate,
          subscriptionProductId: this.subscriptionProductId,
          isPrivate: this.isPrivate,
          address1: this.billingAddress1,
          address2: this.billingAddress2,
          postcode: this.billingPostcode,
          country: this.billingCountry,
          locale: this.locale
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-flow: column;
  max-width: 60rem;
}
</style>
