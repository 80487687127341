'use strict'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt, orderId }) => {
  const response = await fetch(`${VUE_APP_ORDERS_URL}/order/${orderId}`, {
    method: 'delete',
    headers: {
      Authorization: jwt
    },
    timeout: 8000
  })
  if (!response.ok) throw (response.statusText)
}
