'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ jwt, contacts }) => {
  const { data } = await axios({
    method: 'post',
    url: `${VUE_APP_GATEWAY_URL}/idp/contacts-information`,
    headers: { Authorization: jwt },
    timeout: 8000,
    data: contacts
  })

  return data
}
