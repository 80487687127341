<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Executors

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(68)"
        />
      </h1>
    </div>

    <p>
      An executor is a person or organisation you appoint who will be
      responsible to tidying up your affairs and administer the provisions
      laid out in your will after your death.
    </p>

    <e-select
      v-if="!maxExecutorsReached"
      :items="eligiblePotentialExecutors"
      default-option-text="Add Executor"
      data-test="eligible-executors-select"
      @change="addExecutor"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="executors.length">
        <legend>Executors</legend>

        <ul data-test="executor-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in executors" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-executor-button"
                @click="removeExecutor(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>

    <p>
      Up to 4 people can apply for probate so, you can select up to 4 people,
      such as family members or close friends as executors.
    </p>

    <e-select
      v-if="!maxSubstituteExecutorsReached"
      :items="eligiblePotentialSubstituteExecutors"
      default-option-text="Add Substitute Executor"
      data-test="eligible-substitute-executors-select"
      @change="addSubstituteExecutor"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="substituteExecutors.length">
        <legend>Substitute Executors</legend>

        <ul data-test="substitute-executor-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in substituteExecutors" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-substitute-executor-button"
                @click="removeSubstituteExecutor(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>

    <p>
      You can pick up to 4 more people who can step in an fill spaces vacated by
      your nominated executors should they be unable to act on your behalf for some
      reason when the time comes. Your substitute executors will fill spaces in the
      order you list them here.
    </p>
  </div>
</template>

<script>
import Contact from '@/models/Contact'
import ExecutorContact from '@/models/ExecutorContact'
import Group from '@/models/Group'
import SubstituteExecutorContact from '@/models/SubstituteExecutorContact'
import Will from '@/models/Will'
import { getAge } from '@/utils'

export default {
  name: 'Executors',

  computed: {
    will () {
      return Will
        .query()
        .with('children')
        .with('executors')
        .with('substituteExecutors')
        .first()
    },

    executors () {
      return this.will.executors
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    substituteExecutors () {
      return this.will.substituteExecutors
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    eligibleContactsAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .map(({ id, selectText }) => ({
          value: id,
          text: selectText,
          isDisabled: this.isDisabled(id)
        }))
    },

    eligiblePotentialExecutors () {
      return this.eligibleContactsAsSelectOptions
        .map(option =>
          this.substituteExecutors.some(({ id }) => id === option.value)
            ? { ...option, isDisabled: true }
            : option
        )
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    eligiblePotentialSubstituteExecutors () {
      return this.eligibleContactsAsSelectOptions
        .map(option =>
          this.executors.some(({ id }) => id === option.value)
            ? { ...option, isDisabled: true }
            : option
        )
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    maxExecutorsReached () {
      const max = Group
        .all()
        .find(({ name }) => name === 'executors')
        .maxContacts

      return this.executors.length >= max
    },

    maxSubstituteExecutorsReached () {
      const max = Group
        .all()
        .find(({ name }) => name === 'substituteExecutors')
        .maxContacts

      return this.substituteExecutors.length >= max
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 68,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    isDisabled (contactId) {
      return [
        ...Contact.all()
          .filter(({ type }) => type === 'person')
          .filter(({ dateOfBirth }) => getAge(dateOfBirth) < 18),
        ...this.will.children,
        ...this.will.executors,
        ...this.will.substituteExecutors
      ]
        .map(({ id }) => id)
        .includes(contactId)
    },

    async addExecutor (contactId) {
      ExecutorContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['executorContacts'],
        message: 'Saved'
      })
    },

    async addSubstituteExecutor (contactId) {
      SubstituteExecutorContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['substituteExecutorContacts'],
        message: 'Saved'
      })
    },

    async removeExecutor (contactId) {
      ExecutorContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['executorContacts'],
        message: 'Saved'
      })
    },

    async removeSubstituteExecutor (contactId) {
      SubstituteExecutorContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['substituteExecutorContacts'],
        message: 'Saved'
      })
    }
  }
}
</script>
