<template>
  <div class="curtains" :class="{ open: open }">
    <div class="curtain-left" />
    <div class="curtain-right" />
  </div>
</template>

<script>
export default {
  name: 'Curtains',

  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.curtains {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  pointer-events: none;
  position: fixed;
  z-index: 7;

  .curtain-left,
  .curtain-right {
    background: $main-brand-color;
    background-image: url('../../public/mv-p12-5-white.png');
    transition: transform 900ms linear;
    width: 50vw;
  }

  .curtain-left {
    background-position: top right;
  }

  .curtain-right {
    background-position: top left;
  }

  &.open > .curtain-left {
    transform: translateX(-100%);
  }

  &.open > .curtain-right {
    transform: translateX(100%);
  }
}
</style>
