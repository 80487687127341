<template>
  <div>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete Message"
      @cancel="showDeleteDialog = false"
      @confirm="deleteMessage"
    >
      <p>
        Are you sure you wish to delete this message? Message will be moved to Bin.
      </p>
    </confirmation-dialog>

    <table-delete-dialog
      v-if="selectedForDeletion.length"
      item-type="Message"
      @delete-confirmed="deleteMessages"
    />

    <div class="top-inputs">
      <h1 class="page-title">
        Sent
      </h1>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Filter"
        :rules="{ min: 3 }"
        slim
      >
        <e-input
          v-model="filter"
          :delay="true"
          :error-messages="errors"
          :success="valid"
          class="filter"
          placeholder="Filter"
          @input="filterMessages"
        />
      </validation-provider>
    </div>

    <e-table
      :headers="headers"
      :rows="rows"
      @end-of-table-reached="getMoreMessages"
    >
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            class="icon-search ghost"
            data-test="go-to-message-button"
            @click="event => goToMessage({ item, event })"
          />

          <e-button
            class="icon-trash-2 ghost"
            data-test="delete-message-button"
            @click="event => setUpDeleteDialog({ item, event })"
          />
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import ETable from '@/components/ETable'
import TableDeleteDialog from '@/components/dialogs/TableDeleteDialog'
import { format } from 'date-fns'

export default {
  name: 'Sent',

  components: {
    ConfirmationDialog,
    ETable,
    TableDeleteDialog
  },

  data () {
    return {
      selectedForDeletion: [],
      messages: [],
      rows: [],
      listComplete: null,
      listCursor: '',
      showDeleteDialog: false,
      messageForDeletion: '',
      filter: '',

      headers: [
        {
          text: 'To',
          value: 'to',
          align: 'left',
          searchable: true
        },
        {
          text: 'Subject',
          value: 'subject',
          align: 'left'
        },
        {
          text: 'Sent',
          value: 'date',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 107,
      isInitial: true
    })

    try {
      await this.getMessages()
    } catch (error) {
      this.$store.commit('snackbar/update',
        {
          type: 'error',
          message: 'Failed to get messages'
        }
      )

      this.$store.dispatch('logError', {
        error,
        fileName: 'Messages > Sent',
        functionName: 'created'
      })
    }
  },

  methods: {
    async getMessages ({ filter, isRequestForMore = false } = {}) {
      const {
        messages,
        listComplete,
        cursor
      } = await this.$store.dispatch('messages/getMessages', {
        type: 'sent',
        ...this.listCursor && { cursor: this.listCursor },
        ...filter && { filter }
      })

      isRequestForMore
        ? this.messages = this.messages.concat(messages)
        : this.messages = messages

      this.listComplete = listComplete
      this.listCursor = cursor

      this.rows = await this.generateRows()
    },

    async generateRows () {
      return Promise.all(this.messages
        .filter(({ id }) => !this.$store
          .state
          .messages
          .sessionDeletedMessageIds
          .filter(({ mailboxType }) => mailboxType === 'sent')
          .map(({ messageId }) => messageId)
          .includes(id)
        )
        .map(async ({ id, subject, to, date, isRead }) => ({
          id,
          subject,
          to: to
            .map(({ firstName, lastName, isRead }) => `${firstName} ${lastName}${isRead ? ' ✔' : ''}`)
            .join(', '),
          date: format(new Date(date), 'yyyy-MM-dd HH:mm:ss'),
          highlight: to.some(({ isRead }) => !isRead),
          rowClickedFunction: () => this.goToMessage({ item: { id } })
        }))
      )
    },

    async filterMessages () {
      try {
        await this.getMessages({ filter: this.filter })
      } catch (error) {
        await this.getMessages()

        this.$store.dispatch('logError', {
          error,
          fileName: 'Messages > Sent',
          functionName: 'filterMessages'
        })
      }
    },

    async getMoreMessages () {
      if (!this.listComplete) {
        try {
          await this.getMessages({ isRequestForMore: true })
        } catch (error) {
          this.$store.commit('snackbar/update',
            {
              type: 'error',
              message: 'Failed to get messages'
            }
          )

          this.$store.dispatch('logError', {
            error,
            fileName: 'Messages > Sent',
            functionName: 'getMoreMessages'
          })
        }
      }
    },

    goToMessage ({ item, event }) {
      if (event) event.stopPropagation()

      this.$emit('route-change')

      this.$router.push({
        name: 'ViewSentMessage',
        params: {
          messageId: item.id,
          mailboxType: 'sent'
        }
      })
    },

    setUpDeleteDialog ({ item, event }) {
      event.stopPropagation()

      this.messageForDeletion = item
      this.showDeleteDialog = true
    },

    async deleteMessage () {
      this.showDeleteDialog = false

      try {
        await this.$store.dispatch('messages/deleteMessage', {
          messageId: this.messageForDeletion.id,
          mailboxType: 'sent'
        })

        this.$store.commit('messages/addSessionDeletedMessageId', {
          messageId: this.messageForDeletion.id,
          mailboxType: 'sent'
        })

        this.$store.commit('messages/removeSessionRestoredMessageId', {
          messageId: this.messageForDeletion.id,
          mailboxType: 'sent'
        })

        this.rows = await this.generateRows()
      } catch (error) {
        this.$store.commit('snackbar/update',
          {
            type: 'error',
            message: 'Failed to delete message'
          }
        )

        this.$store.dispatch('logError', {
          error,
          fileName: 'Messages > Sent',
          functionName: 'deleteMessage'
        })
      }
    }
  }
}
</script>
