<template>
  <div>
    <e-dialog
      v-if="showMonetaryGiftFormDialog"
      data-test="monetary-gift-dialog"
    >
      <monetary-gift-form
        v-if="showMonetaryGiftFormDialog"
        :monetary-gift-id="selectedMonetaryGiftId"
        @closed="resetMonetaryGiftAndCloseDialog"
        @submitted="resetMonetaryGiftAndCloseDialog"
      />
    </e-dialog>

    <fieldset>
      <legend>Gifts To Beneficiaries</legend>

      <e-button
        data-test="create-new-monetary-gift-button"
        @click="showMonetaryGiftFormDialog = true"
      >
        New Gift
      </e-button>

      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-if="monetaryGifts.length">
          <e-table
            :headers="headers"
            :rows="monetaryGifts"
          >
            <template #actions="{ item }">
              <div class="actions">
                <e-button
                  class="ghost"
                  data-test="edit-item-button"
                  @click="event => setMonetaryGiftAndOpenDialog({ item, event })"
                >
                  <span class="icon-edit" />
                </e-button>
              </div>
            </template>
          </e-table>
        </div>
      </transition>
    </fieldset>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import MonetaryGift from '@/models/MonetaryGift'
import MonetaryGiftForm from './MonetaryGiftForm'
import { formatMoney } from 'accounting'

export default {
  name: 'MonetaryGiftManager',

  components: {
    EDialog,
    ETable,
    MonetaryGiftForm
  },

  data () {
    return {
      headers: [
        {
          text: 'Beneficiary',
          value: 'beneficiary',
          align: 'left',
          searchable: true
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'right'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],

      showMonetaryGiftFormDialog: false,
      selectedMonetaryGiftId: ''
    }
  },

  computed: {
    monetaryGifts () {
      return MonetaryGift
        .query()
        .with('beneficiary')
        .with('beneficiary.contact')
        .with('beneficiary.group')
        .all()
        .map(gift => ({
          id: gift.id,
          beneficiary: gift.beneficiary.name,
          amount: this.toCurrency(gift.amount),
          rowClickedFunction: () => this.setMonetaryGiftAndOpenDialog({ item: { id: gift.id } })
        }))
    }
  },

  methods: {
    toCurrency: value => formatMoney(value, '£', 2) || '',

    setMonetaryGiftAndOpenDialog ({ item, event }) {
      if (event) event.stopPropagation()

      this.showMonetaryGiftFormDialog = true
      this.selectedMonetaryGiftId = item.id
    },

    resetMonetaryGiftAndCloseDialog () {
      this.showMonetaryGiftFormDialog = false
      this.selectedMonetaryGiftId = ''
    }
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin: 2rem 0 1.2rem;
}
</style>
