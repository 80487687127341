<template>
  <label>
    <div>
      <e-button
        class="contact-file primary"
        @click="activateFileDialogue"
      >
        Import From File
      </e-button>
    </div>

    <input
      id="fileDialogue"
      type="file"
      accept=".vcf,.csv"
      class="real-input"
      @change="ManageContacts"
    >
  </label>
</template>

<script>
import Contact from '@/models/Contact'
import Todo from '@/models/Todo'
import parseCsvToContactObjects from '@/utils/parseCsvToContactObjects'
import parseVCardToContactObjects from '@/utils/parseVCardToContactObjects'
import { mergeContacts } from './contactManager'

export default {
  name: 'ManageContactsFromFile',

  methods: {
    activateFileDialogue () {
      const hiddenFileElement = document.getElementById('fileDialogue')
      hiddenFileElement.click()
    },

    async ManageContacts (event) {
      const files = event.target.files
      if (!files.length) return

      try {
        const supportedFileTypes = {
          'text/vcard': parseVCardToContactObjects,
          'text/x-vcard': parseVCardToContactObjects,
          'text/directory': parseVCardToContactObjects,
          'text/csv': parseCsvToContactObjects
        }

        if (!Object.keys(supportedFileTypes).includes(files[0].type)) {
          throw new Error('Unsupported file type')
        }

        const data = await files[0].text()
        const importedContacts = await supportedFileTypes[files[0].type](data)
        const mergedDeduplicatedContacts = mergeContacts(importedContacts)
        Contact.insertOrUpdate({ data: mergedDeduplicatedContacts })

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['contacts']
        })

        await this.$store.dispatch('getAndSetContactDetailsFromBackend')

        Todo.update({
          where: ({ title }) => title === 'Import your contacts',
          data: {
            isDone: true
          }
        })

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['todos']
        })

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Contacts successfully imported'
        })
      } catch (error) {
        console.error(error)
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Import failed'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ContactManager',
          functionName: 'filesChange'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contact-file {
  background-color: white !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAA8CAMAAADygAyiAAABXFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADVFLkFAAAAc3RSTlMAAQIDBAUGBwgJCgsMDQ8QERMUFhgaIiMkKi8xMzg7PEBBQkRFRklMUVJWWF5fY2VoaWprbm9ze31+f4CEhoyNkZucoqeorK6ztrq7vr/AwsPExcrLzdLU1tfY2tvc3d7g4eLk6evy8/T19vf4+fr7/P3+ZLZynQAAAktJREFUeNrtlgVv60wURGfvrlNmZub2w8fMzFBmJsd15v9LL1bcyGVvZEHhiIxHs3cRV54bblBiiTpd48Aa5xSVAqChLIAGoE7x3F6cmp6xYHpq8fZJk6CNBdAGOZGoj55HKzyPfack6qHPjanZmZjMTm3QZw/khKiXHh/CiDpE1BEkQvbO4CE99kJOSeTxQT5pYBQTyS0KEamCwgN65yXSkkU5BgEqJSEaR3GiiU6KHkBU7g51g+PDzUBOCIWG8YnxiZC/+oMXD84VaQVAAf/Ok9x4Ugade1vzg1H+QXE80SMyvb9PviuBDit4sOu56Sxu2t3jT6RiiDQGmNkmub/N+4AUnEjwnrv8NPqC+1yphS60Rgqla+RcPfCaaXZAcMrk1BJHVL1OfgFwjy67Q1FxZGlAUQo6TrExS6b/Rtcy6bVAwkBGRQaVkRgigzt0mZnb5g5fAXJ8kZle+Ngcp9jBKOKBT9J3+QLQgKCdR3iLogtFCnjGjM+AjMvPFZDCRAZP6fvk+swqyR1+EQTyW9Gmfbi4aVKC4SDP75EaU975ltzkY0gBxXaASXp8gxx3Sd9rhNh3fxmGSM4A5VqkTPCSe8HiYj8gS4MKcRylCChD6xb5FUqhYcJuijh4S3+nKXyvYX6RS2Wwn7QOvpE7VWFTRPCd3K6E/TJSgv/I5/mSpPB/cKvtExlgYKw4L1KHt5bLyEMYJ9cnIUoji1EWi39+O1Ii0Zfhrf12JCd3RusNMpEtO4FDROLHmkQOWgkc/ZI/jCZ3PL7hhqvEHx62lAdOTR0IAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
  padding-left: 54px;

  &:hover {
    background-color: lighten(white, 6%);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAA8CAMAAADygAyiAAABXFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADVFLkFAAAAc3RSTlMAAQIDBAUGBwgJCgsMDQ8QERMUFhgaIiMkKi8xMzg7PEBBQkRFRklMUVJWWF5fY2VoaWprbm9ze31+f4CEhoyNkZucoqeorK6ztrq7vr/AwsPExcrLzdLU1tfY2tvc3d7g4eLk6evy8/T19vf4+fr7/P3+ZLZynQAAAktJREFUeNrtlgVv60wURGfvrlNmZub2w8fMzFBmJsd15v9LL1bcyGVvZEHhiIxHs3cRV54bblBiiTpd48Aa5xSVAqChLIAGoE7x3F6cmp6xYHpq8fZJk6CNBdAGOZGoj55HKzyPfack6qHPjanZmZjMTm3QZw/khKiXHh/CiDpE1BEkQvbO4CE99kJOSeTxQT5pYBQTyS0KEamCwgN65yXSkkU5BgEqJSEaR3GiiU6KHkBU7g51g+PDzUBOCIWG8YnxiZC/+oMXD84VaQVAAf/Ok9x4Ugade1vzg1H+QXE80SMyvb9PviuBDit4sOu56Sxu2t3jT6RiiDQGmNkmub/N+4AUnEjwnrv8NPqC+1yphS60Rgqla+RcPfCaaXZAcMrk1BJHVL1OfgFwjy67Q1FxZGlAUQo6TrExS6b/Rtcy6bVAwkBGRQaVkRgigzt0mZnb5g5fAXJ8kZle+Ngcp9jBKOKBT9J3+QLQgKCdR3iLogtFCnjGjM+AjMvPFZDCRAZP6fvk+swqyR1+EQTyW9Gmfbi4aVKC4SDP75EaU975ltzkY0gBxXaASXp8gxx3Sd9rhNh3fxmGSM4A5VqkTPCSe8HiYj8gS4MKcRylCChD6xb5FUqhYcJuijh4S3+nKXyvYX6RS2Wwn7QOvpE7VWFTRPCd3K6E/TJSgv/I5/mSpPB/cKvtExlgYKw4L1KHt5bLyEMYJ9cnIUoji1EWi39+O1Ii0Zfhrf12JCd3RusNMpEtO4FDROLHmkQOWgkc/ZI/jCZ3PL7hhqvEHx62lAdOTR0IAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-size: contain;
    border-color: lighten($button-color-three, 6%);
    color: darkgray !important;
  }
}

.real-input {
  display: none;
}

.fake-button {
  background-color: $main-background-color;
  border: 1px solid $link-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-normal;
  padding: 0.4rem 0.6rem;
  text-transform: capitalize;
  transition: all ease 300ms;
  width: fit-content;

  &:hover {
    background-color: lighten($main-background-color, 8%);
    color: $white;
  }
}

.text {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 0.6rem;
}
</style>
