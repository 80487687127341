import axios from 'axios'

export default async ({ code, newPassword }) => {
  const { VUE_APP_GATEWAY_URL } = process.env

  const response = await axios({
    method: 'patch',
    url: `${VUE_APP_GATEWAY_URL}/idp/forgotten-password`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ code, newPassword }),
    timeout: 8000
  })

  return response.data
}
