<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Directory
        </h1>

        <e-input
          v-model="filter"
          placeholder="Search"
          class="search"
        />

        <accordion
          :items="items"
          :display-is-valid="true"
          :display-sub-items-size="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @set-active="setActiveCategory"
          @deleted="handleDeleted"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import Contact from '@/models/Contact'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Directory',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      filter: '',
      activeCategory: ''
    }
  },

  computed: {
    contacts () {
      return Contact
        .all()
        .filter(({ fullName }) => (this.filter.toLowerCase()
          ? fullName.toLowerCase().includes(this.filter.toLowerCase())
          : true
        ))
    },

    letterArray () {
      return this.contacts
        .map(({ fullName }) => fullName[0].toUpperCase())
        .filter((item, index, array) => array.indexOf(item) === index)
        .sort((a, b) => a < b ? -1 : 1)
    },

    addressBook () {
      return {
        id: 'AddressBook',
        text: 'Address Book',
        isActive: this.activeCategory === 'AddressBook',
        length: this.contacts.length,
        sub: this.letterArray.map(letter => ({
          id: letter,
          text: letter,
          sub: this.contacts
            .filter(({ fullName }) => fullName[0].toUpperCase() === letter)
            .map(({ id, fullName, isValid }) => ({
              id,
              text: fullName,
              isValid,
              routeName: 'ContactView',
              paramKey2: 'letterId',
              paramKey3: 'contactId'
            }))
            .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
        })),

        additionals: [
          {
            id: 'newContact',
            text: 'Add new',
            routeName: 'NewContact'
          },
          {
            id: 'ManageContacts',
            text: 'Manage Contacts',
            routeName: 'ManageContacts'
          }
        ]
      }
    },

    services () {
      return {
        id: 'Services',
        text: 'Services',
        isActive: this.activeCategory === 'Services',
        length: this.$store.state.services.categories.reduce((total, category) => category.inventory.length + total, 0),
        sub: this.$store.state.services.categories
          .map(category => ({
            id: category.id,
            text: `${category.title} (${category.inventory.length})`,
            routeName: category.id,
            paramKey2: 'subCategoryId'
          }))
          .filter(({ text }) => this.filter
            ? text.toLowerCase().includes(this.filter.toLowerCase())
            : true
          )
          .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
      }
    },

    items () {
      return [
        this.services,
        this.addressBook
      ]
    }
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    },

    handleDeleted (routeObject) {
      this.$router.push(routeObject)
    },

    handleSubmitted (routeObject) {
      this.$router.push(routeObject)
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  cursor: pointer;
}

.search {
  margin-bottom: 0.6em;
}
</style>
