<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Contract Termination Agreement

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="contract-termination-agreeent-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Contract Termination Date"
            rules="required"
            slim
          >
            <e-input
              v-model="contractTerminationDate"
              label="Date Contract Is Terminated"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Contract Details</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Contract Title"
              :rules="{ required: true }"
              slim
            >
              <e-input
                v-model="contractTitle"
                label="Contract Title"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Contract Date"
              rules="required"
              slim
            >
              <e-input
                v-model="contractDate"
                label="Contract Date"
                type="date"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Contracted Party One"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="contractPartyOneId"
                :items="contractPartyOneSelectOptions"
                label="Contracted Party One"
                default-option-text="Add Contracted Party"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Contracted Party Two"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="contractPartyTwoId"
                :items="contractPartyTwoSelectOptions"
                label="Contracted Party Two"
                default-option-text="Add Contracted Party"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset>
            <legend>Witness(es)</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Witness Date"
              rules="required"
              slim
            >
              <e-input
                v-model="witnessDate"
                label="Witness Date"
                type="date"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Witnesses"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="witnesses.length"
                type="hidden"
              >

              <e-select
                :items="witnessesSelectOptions"
                default-option-text="Add Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="contactId => addContact({ type: 'witnesses', contactId })"
              />
            </validation-provider>

            <table v-if="witnesses.length">
              <thead>
                <tr>
                  <th>Witness</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="witness in witnesses"
                  :key="witness.id"
                >
                  <td>
                    {{ witness.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeContact({ type: 'witnesses', contactId: witness.id })"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="contract-termination-agreeent-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'contractTerminationAgreementAstonVeer'

export default {
  name: 'ContractTerminationAgreement',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: '67224cb4-5097-4fa5-a2fb-311fc39bfdda',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      contractTerminationDate: '',
      contractDate: '',
      contractTitle: '',
      contractPartyOneId: '',
      contractPartyTwoId: '',
      witnesses: [],
      witnessDate: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    contractPartyOneSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => this.contractPartyTwoId !== id)
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    contractPartyTwoSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => this.contractPartyOneId !== id)
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    witnessesSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => ![...new Set([
          this.contractPartyOneId,
          this.contractPartyTwoId,
          ...this.witnesses.map(({ id }) => id)
        ])].includes(id) && type === 'person')
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addContact ({ contactId, type }) {
      this[type].push(this.userAndContacts.find(({ id }) => id === contactId))
      this.setIsDirty(true)
    },

    removeContact ({ contactId, type }) {
      this[type] = this[type]
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            contractTerminationDate: this.contractTerminationDate,
            contractDate: this.contractDate,
            contractPartyOne: this.userAndContacts.find(({ id }) => id === this.contractPartyOneId),
            contractPartyTwo: this.userAndContacts.find(({ id }) => id === this.contractPartyTwoId),
            contractTitle: this.contractTitle,
            witnesses: this.witnesses,
            witnessDate: this.witnessDate
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ContractTerminationAgreement',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ContractTerminationAgreement',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
