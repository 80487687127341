<template>
  <div>
    <p>
      This is your secure list of things of worth that belong to you,
      we call it the Asset Register. Use it to store useful information
      about your assets.
    </p>

    <p>
      Entries here work with all the other features in MeaVitae such as
      in calculating your net worth and making gifts in your will.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AssetsDefault',

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 13,
      isInitial: true
    })
  }
}
</script>
