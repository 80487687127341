<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn animate_faster"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div
      v-if="isVisible"
      class="snackbar"
      :class="type"
      data-test="snackbar"
    >
      <span>
        {{ message }}
      </span>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Snackbar',

  data () {
    return {
      isVisible: false
    }
  },

  computed: mapState('snackbar', {
    epoch: ({ epoch }) => epoch,
    type: ({ type }) => type,
    message: ({ message }) => message,
    timeout: ({ timeout }) => timeout
  }),

  watch: {
    epoch () {
      this.isVisible = true
      setTimeout(() => (this.isVisible = false), this.timeout)
    }
  }
}
</script>

<style lang="scss" scoped>
.snackbar {
  align-items: center;
  background-color: $color-info;
  color: $font-color-two;
  display: flex;
  height: $nav-bar-height;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 10;

  &.error {
    background-color: $color-error;
    color: $font-color-two;
  }

  &.info {
    background-color: $color-info;
    color: $font-color-two;
  }

  &.warning {
    background-color: $color-warning;
    color: $font-color-three;
  }

  &.success {
    background-color: $color-success;
    color: $font-color-two;
  }

  span {
    font-size: 1.25rem;
    font-weight: 400;
  }
}

@media screen and (min-width: $media-break-1) {
  .snackbar {
    border-radius: 6px;
    bottom: 0.6rem;
    height: auto;
    max-width: 14rem;
    padding: 1rem;
    right: 0.6rem;
  }
}
</style>
