'use strict'

import axios from 'axios'

export default async ({ endpoint, accessToken }) => axios({
  method: 'GET',
  url: endpoint,
  headers: {
    Authorization: `Bearer ${accessToken}`
  }
})
