<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Advance Decision: Living Will

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(84)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="advance-decision-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Subject of Document"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="subjectId"
              :items="subjectSelectOptions"
              label="Subject of Living Will"
              default-option-text="Add Subject of Living Will"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="subject-select"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-textarea
            v-model="statementOfValuesAndBeliefs"
            label="Statement Of Values And Beliefs"
            :help-id="85"
            data-test="statement-of-values-and-beliefs-textarea"
            @input="setIsDirty(true)"
          />

          <fieldset>
            <legend>Decisions about Terminal Conditions</legend>

            <p>
              If the Subject has an incurable and irreversible Terminal condition that will result in death within a
              compariatively short period of time, the subject instructs that:
            </p>

            <e-input
              :value="decisionsAboutTerminalConditions.doNotWantLifeSupport"
              type="checkbox"
              label="They do not want to be given Life Support or other life-prolonging treatment?"
              data-test="terminal-do-not-want-life-support-checkbox"
              @change="value => decisionsAboutTerminalConditions.doNotWantLifeSupport = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutTerminalConditions.doNotWantTubeFeeding"
              type="checkbox"
              label="They do not want to receive Tube Feeding even if withholding such feeding would hasten death?"
              data-test="terminal-do-not-want-tube-feeding-checkbox"
              @change="value => decisionsAboutTerminalConditions.doNotWantTubeFeeding = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutTerminalConditions.doNotWantCardiopulmonaryResuscitation"
              type="checkbox"
              label="They do not want to receive receive Cardiopulmonary Resuscitation in the event of cardiac arrest?"
              data-test="terminal-do-not-want-cardiopulmonary-resuscitation-checkbox"
              @change="value => decisionsAboutTerminalConditions.doNotWantCardiopulmonaryResuscitation = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutTerminalConditions.doNotWantActiveTreatment"
              type="checkbox"
              label="If the subject develops another separate condition that is a threat to life, they do not want active treatment, unless it is causing undue pain or suffering?"
              data-test="terminal-do-not-want-active-treatment-checkbox"
              @change="value => decisionsAboutTerminalConditions.doNotWantActiveTreatment = value"
              @input="setIsDirty(true)"
            />
          </fieldset>

          <fieldset>
            <legend>Decisions About Persistently Unconscious</legend>

            <p>
              If the subject is diagnosed as Persistently Unconscious and, to a reasonable degree of medical certainty,
              will not regain consciousness, the subject instructs that:
            </p>

            <e-input
              label="They do not want to be given Life Support or other life-prolonging treatment?"
              type="checkbox"
              :value="decisionsAboutPersistentlyUnconscious.doNotWantLifeSupport"
              data-test="unconscious-do-not-want-life-support-checkbox"
              @change="value => decisionsAboutPersistentlyUnconscious.doNotWantLifeSupport = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="They do not want to receive Tube Feeding even if withholding such feeding would hasten death?"
              type="checkbox"
              :value="decisionsAboutPersistentlyUnconscious.doNotWantTubeFeeding"
              data-test="unconscious-do-not-want-tube-feeding-checkbox"
              @change="value => decisionsAboutPersistentlyUnconscious.doNotWantTubeFeeding = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="They do not want to receive receive Cardiopulmonary Resuscitation in the event of cardiac arrest?"
              type="checkbox"
              :value="decisionsAboutPersistentlyUnconscious.doNotWantCardiopulmonaryResuscitation"
              data-test="unconscious-do-not-want-cardiopulmonary-resuscitation-checkbox"
              @change="value => decisionsAboutPersistentlyUnconscious.doNotWantCardiopulmonaryResuscitation = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="If the subject develops another separate condition that is a threat to life, the subject does not want active treatment unless causing undue pain or suffering?"
              type="checkbox"
              :value="decisionsAboutPersistentlyUnconscious.doNotWantActiveTreatment"
              data-test="unconscious-do-not-want-active-treatment-checkbox"
              @change="value => decisionsAboutPersistentlyUnconscious.doNotWantActiveTreatment = value"
              @input="setIsDirty(true)"
            />
          </fieldset>

          <fieldset>
            <legend>Decisions about being Severely and Permanently Mentally Impaired</legend>

            <p>
              If the subject is diagnosed as being severely and permanently mentally impaired, the subject instructs that:
            </p>

            <e-input
              :value="decisionsAboutMentallyImpaired.doNotWantLifeSupport"
              type="checkbox"
              label="They do not want to be given Life Support or other life-prolonging treatment?"
              data-test="mentally-impaired-do-not-want-life-support-checkbox"
              @change="value => decisionsAboutMentallyImpaired.doNotWantLifeSupport = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutMentallyImpaired.doNotWantTubeFeeding"
              type="checkbox"
              label="They do not want to receive Tube Feeding even if withholding such feeding would hasten death?"
              data-test="mentally-impaired-do-not-want-tube-feeding-checkbox"
              @change="value => decisionsAboutMentallyImpaired.doNotWantTubeFeeding = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutMentallyImpaired.doNotWantCardiopulmonaryResuscitation"
              type="checkbox"
              label="They do not want to receive receive Cardiopulmonary Resuscitation in the event of cardiac arrest?"
              data-test="mentally-impaired-do-not-want-cardiopulmonary-resuscitation-checkbox"
              @change="value => decisionsAboutMentallyImpaired.doNotWantCardiopulmonaryResuscitation = value"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="decisionsAboutMentallyImpaired.doNotWantActiveTreatment"
              type="checkbox"
              label="If the subject develops another separate condition that is a threat to life, the subject does not want active treatment unless causing undue pain or suffering?"
              data-test="mentally-impaired-do-not-want-active-treatment-checkbox"
              @change="value => decisionsAboutMentallyImpaired.doNotWantActiveTreatment = value"
              @input="setIsDirty(true)"
            />
          </fieldset>

          <e-input
            :value="behaviourViolentOrDegradingWantDrugs"
            type="checkbox"
            label="Should the subject have any of the above-mentioned conditions and their behaviour is violent or otherwise degrading, the subject does want symptoms to be controlled with appropriate drugs, even if that would worsen physical condition or shorten life?"
            data-test="behaviour-violent-or-degrading-want-drugs-checkbox"
            @change="value => behaviourViolentOrDegradingWantDrugs = value"
            @input="setIsDirty(true)"
          />

          <e-input
            :value="inPainWantDrugs"
            type="checkbox"
            label="Should the subject have any of the above-mentioned conditions and they appear to be in pain, the subject does want symptoms to be controlled with appropriate drugs, even if that would worsen physical condition or shorten life?"
            data-test="in-pain-want-drugs-checkbox"
            @change="value => inPainWantDrugs = value"
            @input="setIsDirty(true)"
          />

          <e-textarea
            v-model="potentialTreatmentsStatement"
            label="The subject would like to make the following statement about potential treatments"
            :help-id="86"
            data-test="potential-treatments-statement-textarea"
            @input="setIsDirty(true)"
          />

          <e-input
            :value="noOrganDonation"
            type="checkbox"
            label="Organs or tissues not to  be used for transplantation upon my death?"
            data-test="no-organ-donation-checkbox"
            :help-id="87"
            @change="value => noOrganDonation = value"
            @input="setIsDirty(true)"
          />

          <p>
            Intentions made clear on the <a href="https://www.organdonation.nhs.uk" target="_blank">NHS Organ Donor Register</a>
          </p>

          <e-textarea
            v-model="additionalInstructions"
            label="Any additional instructions to include"
            :help-id="88"
            data-test="additional-instructions-textarea"
            @input="setIsDirty(true)"
          />

          <e-input
            v-model="signatureDate"
            label="Signature date"
            type="date"
            data-test="signature-date-input"
            @input="setIsDirty(true)"
          />

          <fieldset>
            <legend>Witness</legend>

            <e-select
              v-model="witnessId"
              :items="witnessSelectOptions"
              default-option-text="Add Contact"
              data-test="witness-select"
              @input="setIsDirty(true)"
            />

            <e-input
              v-model="witnessRelationship"
              label="Relationship to subject"
              data-test="witness-relationship-input"
              @input="setIsDirty(true)"
            />
          </fieldset>

          <fieldset data-test="involved-in-care-fieldset">
            <legend>
              Involved In Care

              <span
                class="icon-help-circle"
                @click="setHelp(89)"
              />
            </legend>

            <e-select
              :items="involvedInCareSelectOptions"
              default-option-text="Add Contact"
              data-test="involved-in-care-select"
              @change="addInvolvedInCareContact"
            />

            <table v-if="involvedInCareContacts.length">
              <thead>
                <tr>
                  <th>Contact</th>
                  <th>Relationship</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in involvedInCareContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      :name="`${contact.fullName} Relationship`"
                      :rules="{ required: true }"
                      slim
                    >
                      <e-input
                        v-model="contact.relationship"
                        :error-messages="errors"
                        :success="valid"
                        :is-required="true"
                        @input="setIsDirty(true)"
                      />
                    </validation-provider>
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeInvolvedInCareContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset data-test="lasting-power-of-attorney-fieldset">
            <legend>
              Lasting Power Of Attorney

              <span
                class="icon-help-circle"
                @click.prevent="setHelp(90)"
              />
            </legend>

            <e-select
              :items="lastingPowerOfAttorneySelectOptions"
              default-option-text="Add Contact"
              data-test="lasting-power-of-attorney-select"
              @change="addLastingPowerOfAttorneyContact"
            />

            <table v-if="lastingPowerOfAttorneyContacts.length">
              <thead>
                <tr>
                  <th>Attorney</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in lastingPowerOfAttorneyContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeLastingPowerOfAttorneyContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="advance-decision-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)

export default {
  name: 'AdvanceDecision',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'MVSKU_184028239298_V',
      isDirty: false,
      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      subjectId: '',
      witnessId: '',
      witnessRelationship: '',

      involvedInCareContacts: [],
      lastingPowerOfAttorneyContacts: [],

      decisionsAboutTerminalConditions: {
        doNotWantLifeSupport: false,
        doNotWantTubeFeeding: false,
        doNotWantCardiopulmonaryResuscitation: false,
        doNotWantActiveTreatment: false
      },

      decisionsAboutPersistentlyUnconscious: {
        doNotWantLifeSupport: false,
        doNotWantTubeFeeding: false,
        doNotWantCardiopulmonaryResuscitation: false,
        doNotWantActiveTreatment: false
      },

      decisionsAboutMentallyImpaired: {
        doNotWantLifeSupport: false,
        doNotWantTubeFeeding: false,
        doNotWantCardiopulmonaryResuscitation: false,
        doNotWantActiveTreatment: false
      },

      statementOfValuesAndBeliefs: '',
      behaviourViolentOrDegradingWantDrugs: true,
      inPainWantDrugs: true,
      noOrganDonation: false,
      potentialTreatmentsStatement: '',
      additionalInstructions: '',
      signatureDate: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person'])
    },

    subjectSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.witnessId)
        .filter(({ id }) => !this.involvedInCareContacts
          .map(careContact => careContact.id)
          .includes(id)
        )
        .filter(({ id }) => !this.lastingPowerOfAttorneyContacts
          .map(attorneyContact => attorneyContact.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    witnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.subjectId)
        .filter(({ id }) => !this.involvedInCareContacts
          .map(careContact => careContact.id)
          .includes(id)
        )
        .filter(({ id }) => !this.lastingPowerOfAttorneyContacts
          .map(attorneyContact => attorneyContact.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    involvedInCareSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.subjectId)
        .filter(({ id }) => id !== this.witnessId)
        .filter(({ id }) => !this.involvedInCareContacts
          .map(careContact => careContact.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    lastingPowerOfAttorneySelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.subjectId)
        .filter(({ id }) => id !== this.witnessId)
        .filter(({ id }) => !this.lastingPowerOfAttorneyContacts
          .map(attorneyContact => attorneyContact.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 84,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addInvolvedInCareContact (contactId) {
      this.involvedInCareContacts.push({
        ...this.userAndContacts.find(({ id }) => id === contactId),
        relationship: ''
      })

      this.setIsDirty(true)
    },

    removeInvolvedInCareContact (contactId) {
      this.involvedInCareContacts = this.involvedInCareContacts
        .filter(({ id }) => contactId !== id)
    },

    addLastingPowerOfAttorneyContact (contactId) {
      this.lastingPowerOfAttorneyContacts.push({
        ...this.userAndContacts.find(({ id }) => id === contactId),
        relationship: ''
      })

      this.setIsDirty(true)
    },

    removeLastingPowerOfAttorneyContact (contactId) {
      this.lastingPowerOfAttorneyContacts = this.lastingPowerOfAttorneyContacts
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            subject: this.userAndContacts.find(({ id }) => id === this.subjectId),
            ...this.witnessId && {
              witness: {
                ...this.userAndContacts.find(({ id }) => id === this.witnessId),
                relationship: this.witnessRelationship
              }
            },
            statementOfValuesAndBeliefs: this.statementOfValuesAndBeliefs,
            decisionsAboutTerminalConditions: this.decisionsAboutTerminalConditions,
            decisionsAboutPersistentlyUnconscious: this.decisionsAboutPersistentlyUnconscious,
            decisionsAboutMentallyImpaired: this.decisionsAboutMentallyImpaired,
            behaviourViolentOrDegradingWantDrugs: this.behaviourViolentOrDegradingWantDrugs,
            inPainWantDrugs: this.inPainWantDrugs,
            potentialTreatmentsStatement: this.potentialTreatmentsStatement,
            noOrganDonation: this.noOrganDonation,
            additionalInstructions: this.additionalInstructions,
            signatureDate: this.signatureDate,
            involvedInCareContacts: this.involvedInCareContacts,
            lastingPowerOfAttorneyContacts: this.lastingPowerOfAttorneyContacts
          },
          locale: 'en-GB',
          version: 0,
          type: 'livingWill'
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'AdvanceDecision',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: 'livingWill',
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'AdvanceDecision',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
