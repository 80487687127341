'use strict'

import axios from 'axios'

const { VUE_APP_CONTENT_URL } = process.env

export default async jwt => {
  const response = await axios({
    method: 'get',
    url: `${VUE_APP_CONTENT_URL}/templates/user-invite`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt
    },
    timeout: 8000
  })
  return response.data
}
