<template>
  <div>
    <e-button
      data-test="add-address-button"
      @click="addAddress(addresses)"
    >
      <span class="icon-plus" />
      Add Address

      <span
        v-if="!addresses.length && neededForIsValid"
        class="icon-alert-triangle"
        :style="{ color: addressRequired ? 'red' : 'orange' }"
      />
    </e-button>

    <validation-provider
      v-if="addressRequired"
      v-slot="{ errors, valid }"
      name="Address"
      :rules="{ array_length: true }"
      tag="div"
    >
      <e-input
        :value="addresses.length"
        type="hidden"
        :error-messages="errors"
        :success="valid"
        data-test="addresses-required-input"
      />
    </validation-provider>

    <fieldset v-if="addresses.length">
      <legend>Addresses</legend>

      <table data-test="address-table">
        <thead>
          <tr>
            <th>Address</th>

            <th class="center">
              Preferred
            </th>

            <th class="center">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(address, index) in addresses"
            :key="address.id"
          >
            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${address.id}-address`"
                name="Address"
                :rules="{ required: true }"
                tag="div"
              >
                <google-address-input
                  v-model="mutableAddresses[index].placeResult['formatted_address']"
                  :address-object="address"
                  :enable-geolocation="true"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  data-test="address-input"
                  @address-selected="updateAddress(addresses, $event)"
                />
              </validation-provider>
            </td>

            <td class="preferred">
              <e-input
                :value="addresses[index].isPref"
                type="checkbox"
                data-test="preferred-address-checkbox"
                @change="updatePref(addresses, address.id, $event)"
              />
            </td>

            <td class="actions">
              <e-button
                class="ghost"
                data-test="remove-address-button"
                @click="removeAddress(addresses, address.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import GoogleAddressInput from './GoogleAddressInput'
import { createId } from '@/utils'

export default {
  name: 'AddressManager',

  components: {
    GoogleAddressInput
  },

  props: {
    addresses: {
      type: Array,
      default: () => []
    },

    addressRequired: {
      type: Boolean,
      default: false
    },

    addEmptyAddress: {
      type: Boolean,
      default: false
    },

    neededForIsValid: {
      type: Boolean,
      default: true
    }
  },

  emits: ['is-dirty'],

  computed: {
    mutableAddresses: {
      get () {
        return this.addresses
      }
    }
  },

  created () {
    if (this.addEmptyAddress) this.addAddress(this.addresses)
  },

  methods: {
    updateAddress (addresses, addressObject) {
      addressObject
        .placeResult
        .formatted_address = addressObject.placeResult.formatted_address.replace(', , ', ', ')

      const index = addresses.findIndex(({ id }) => id === addressObject.id)
      addresses.splice(index, 1, addressObject)
      this.$emit('is-dirty')
    },

    updatePref (addresses, addressId, bool) {
      this.isDirty = true

      if (bool) {
        addresses
          .filter(({ id }) => id !== addressId)
          .forEach(address => (address.isPref = false))
      }

      addresses
        .find(({ id }) => id === addressId)
        .isPref = bool

      this.$emit('is-dirty')
    },

    addAddress (addresses) {
      addresses.push({
        id: createId(),
        placeResult: {
          formatted_address: ''
        },
        returnData: {}
      })

      this.$emit('is-dirty')
    },

    removeAddress (addresses, addressId) {
      const index = addresses.findIndex(({ id }) => id === addressId)
      addresses.splice(index, 1)
      this.$emit('is-dirty')
    }
  }
}
</script>

<style scoped lang="scss">
button {
  margin-bottom: 0.8rem;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
