
<template>
  <div class="main-element">
    <buy-tokens-dialogue
      v-if="showPurchaseTokenDialogue"
      :pricemeta="tokenPriceMeta"
      :requiredtokens="requiredTokens"
      @close-dialogue="closePurchaseTokenDialogue"
    />
    <div v-if="showPurchaseForm">
      <div class="header">
        <h1>Purchase Confirmation</h1>

        <e-button
          class="close-button icon-x-circle ghost"
          @click="$emit('closed')"
        />
      </div>

      <div class="body">
        <img
          :alt="item.title"
          :src="item.imageUrl || '../../default_thumbnail.png'"
        >

        <div class="info">
          <h1>
            {{ item.title }}
          </h1>
          <span v-if="hasPrompt">Create a letter based on: <b><i>{{ item.prompt }}</i></b>
          </span>
          <span v-if="hasDisclaimer" style="font-size: -1;"><b>Disclaimer</b>
            {{ item.disclaimer }}
          </span>
          <span>
            Purchase of <i>{{ item.parentCategory.title }}</i>, a {{ item.type }} from
            <i>{{ item.vendor.name }}</i>. When you click the Pay button,
            {{ item.price }} MVT will be transferred from your wallet to
            {{ item.vendor.name }}.
          </span>
          <span v-if="isService">
            You will receive a confirmation of the order
            and the vendor will contact you to arrange the provision of this
            service.
          </span>
          <div class="cost-and-button">
            <div class="mvt-price">
              Cost: {{ item.price }} MVT
            </div>
            <div v-if="hasRequirements" class="requirements-agree">
              {{ item.requirements[0].name || 'Letter of Engagement' }}
              <input
                type="checkbox"
                label="Agreed"
                @click="generateSignedDocument"
              > Agreed
            </div>

            <div class="pay-button-container">
              <e-button class="pay-button" @click="pay">
                Pay
              </e-button>
              <e-button class="cancel-button" @click="$emit('closed')">
                Cancel
              </e-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showInsufficientTokens">
      <div class="header">
        <h1>Insufficient Tokens</h1>

        <e-button class="close-button icon-x-circle ghost" @click="$emit('closed')" />
      </div>

      <div class="notice">
        <div class="info">
          Your account has insufficient Tokens available to pay for this item.
          If you wish to add MeaVitae Tokens to your account, you may use the
          button below to puchase more.
          <div class="cost-and-buttons">
            <div class="mvt-price">
              You need to add {{ requiredTokens }} MVT
            </div>
            <div id="pay-button-container">
              <e-button class="pay-button" @click="buyMoreTokens">
                Purchase Tokens
              </e-button>
              <e-button class="cancel-button" @click="$emit('closed')">
                Cancel
              </e-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuyTokensDialogue from './BuyTokensDialogue.vue'

export default {
  name: 'ConfirmPurchase',

  components: {
    BuyTokensDialogue
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  emits: [
    'return-data'
  ],

  data () {
    return {
      showPurchaseForm: true,
      showInsufficientTokens: false,
      hasRequirements: false,
      requiredTokens: 0,
      tokenPriceMeta: {},
      showPurchaseTokenDialogue: false,
      isService: false,
      hasDisclaimer: false,
      hasPrompt: false
    }
  },

  created () {
    if (this.item.requirements) this.hasRequirements = true
    this.isService = this.item.type === 'service'
    this.hasDisclaimer = this.item?.disclaimer.length > 0
    this.hasPrompt = this.item?.prompt?.length > 0
  },

  methods: {
    async pay (event) {
      event.stopPropagation()
      try {
        const order = {}
        order.item = this.item
        order.payer = {
          userId: this.$store.state.userId,
          email: this.$store.getters.user.emailAddresses[0].value,
          armoredPublicKey: this.$store.state.armoredPublicKey
        }
        order.owner = {
          userId: this.$store.state.userId,
          email: this.$store.getters.user.emailAddresses[0].value,
          armoredPublicKey: this.$store.state.armoredPublicKey
        }
        if (order.item.prompt) {
          this.$store.dispatch('marketplace/newOrder', {
            order,
            pk: this.$store.state.armoredPrivateKey
          })
          this.$emit('closed')
          this.$emit('show-ai-working-dialogue')
          this.showPurchaseForm = false
          return
        }
        const data = await this.$store.dispatch('marketplace/newOrder', { order, pk: this.$store.state.armoredPrivateKey })
        this.$emit('return-data', data.payload)
        this.$emit('closed')
        this.showPurchaseForm = false
        return data
      } catch (err) {
        console.error(err)
        if (err && err.response.data.require) {
          this.requiredTokens = err.response.data.require
          this.tokenPriceMeta = err.response.data.tokenPriceMeta
          this.showInsufficientTokens = true
          this.showPurchaseForm = false
        }
      }
    },

    buyMoreTokens () {
      this.showPurchaseTokenDialogue = true
      this.showPurchaseForm = false
    },

    generateSignedDocument (evt) {
      // if (evt.target.checked) console.log('Checked the requirements box, now generate a signed attachment from requirements.')
    },

    closePurchaseTokenDialogue () {
      this.showPurchaseTokenDialogue = false
    }
  }
}
</script>

<style lang="scss" scoped>
.main-element {
  display: grid;
  row-gap: 1rem;

  h1 {
    padding-bottom: 1rem;
  }

  .close-button {
    align-self: start;
    padding: 0;
  }

  .notice {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr;

    .info {
      display: grid;

      .cost-and-button {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .body {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr;

    .info {
      display: grid;

      .cost-and-button {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 2vh;
      }
    }
  }

  .pay-button-container {
    justify-self: end;
    margin-top: 1vh;

    .pay-button {
      background-color: $color-success;
      border: 0;
      border-radius: 3px;
      color: $filled-button-text;
      font-size: 1.4rem;
      font-weight: bold;
      height: 2.6rem;
      margin-right: 1.6rem;
      padding: 0 1.6rem;
      text-transform: capitalize;
    }

    .cancel-button {
      background-color: $color-error;
      border: 0;
      color: $font-color-two;
      font-size: 1.4rem;
      font-weight: bold;
      height: 2.6rem;
      padding: 0 1.6rem;
      text-transform: capitalize;
    }
  }

  .mvt-price {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
</style>
