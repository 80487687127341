'use strict'

import decryptString from './decryptString'

export default async ({ string, armoredPrivateKey }) => {
  try {
    if (btoa(atob(string)) === string) {
      return await decryptString({
        encryptedString: string,
        armoredPrivateKey
      })
    } else return string
  } catch (error) {
    return string
  }
}
