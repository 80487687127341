<template>
  <div>
    <label class="question-text">
      {{ label }}

      <span
        v-if="helpId"
        class="icon-help-circle"
        @click="setHelp"
      />

      <span
        v-if="isRequired && !value"
        class="icon-alert-triangle"
      />
    </label>

    <p
      v-if="description"
      v-html="purify(description)"
    />
    <fieldset>
      <input
        v-model="valueReadOnly"
        type="hidden"
      >

      <e-input
        v-for="option in items"
        :key="option.id || option.value"
        type="radio"
        :value="value"
        :radio-value="option.value"
        :label="option.text"
        @change="$emit('change', $event)"
      />

      <span
        v-if="errorMessages.length"
        class="error-text"
        data-test="error-message"
      >
        {{ errorMessages[0] }}
      </span>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'ERadioGroup',

  props: {
    value: {
      type: [String, Number],
      default: null
    },

    label: {
      type: String,
      default: ''
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    description: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    },

    helpId: {
      type: [String, Number],
      default: null
    },

    helpFunction: {
      type: Function,
      default: ({ store, id }) => store.dispatch('findAndSetHelpObject', { id })
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update'],

  computed: {
    valueReadOnly () {
      return this.value
    }
  },

  methods: {
    setHelp () {
      this.helpFunction({ id: this.helpId, store: this.$store })
    }
  }
}
</script>

<style scoped lang="scss">
.icon-alert-triangle {
  color: red;
}

fieldset {
  padding: 0.6rem;

  label {
    margin-bottom: 0.2rem;
  }
}
</style>
