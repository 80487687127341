<template>
  <div>
    <section>
      <div class="top-inputs">
        <h1>
          Articles found: {{ articles.length }}
        </h1>
      </div>

      <div class="articles">
        <article-element
          v-for="article in articles"
          :key="article.id"
          :article="article"
          @article-clicked="goToArticle"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ArticleElement from '@/components/ArticleElement'

export default {
  name: 'InsightCategoryArticles',

  components: {
    ArticleElement
  },

  props: {
    subCategoryId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      articles: []
    }
  },

  async created () {
    this.articles = this.$store.state.insights.categories
      .find(({ id }) => id === this.subCategoryId)
      .articles
  },

  methods: {
    goToArticle (article) {
      this.$router.push({
        name: `${this.subCategoryId}-${article.id}`,
        params: {
          subCategoryId: this.subCategoryId,
          articleId: article.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .articles {
    display: grid;
    row-gap: 1em;
  }
</style>
