<template>
  <div>
    <section>
      <div class="top-inputs">
        <h1>
          {{ services[0].parentCategory.title }}: {{ services[0].subCategory.title }}: {{ services.length }}
        </h1>
      </div>

      <div class="services">
        <service-element
          v-for="service in services"
          :key="service.id"
          :service="service"
          @service-clicked="openAppropriateDialog"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ServiceElement from '@/components/ServiceElement'

export default {
  name: 'ServicesCategoryServices',

  components: {
    ServiceElement
  },

  props: {
    subCategoryId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      services: [],
      selectedService: {},
      dialogInfoType: '',
      showDialog: false
    }
  },

  async created () {
    this.services = this.$store.state.services.categories
      .find(({ id }) => id === this.subCategoryId)
      .inventory
  },

  methods: {
    openAppropriateDialog ({ service, type }) {
      this.dialogInfoType = {
        service: 'ServiceInfoElement',
        vendor: 'VendorInfoElement'
      }[type]

      this.selectedService = service
      this.showDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  display: grid;
  row-gap: 1em;
}
</style>
