'use strict'
import axios from 'axios'
const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt }) => axios({
  method: 'GET',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_ORDERS_URL}/orders`,
  timeout: 8000
})
