<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        General Release Of Liability

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="non-disclosure-agreement-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="General Relaease Date"
            rules="required"
            slim
          >
            <e-input
              v-model="generalRelaeaseDate"
              label="General Relaease Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="considerationSum"
            label="Consideration Sum"
            @input="setIsDirty(true)"
          />

          <fieldset>
            <legend>Releasor</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasor"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releasorId"
                :items="releasorSelectOptions"
                label="Releasor"
                default-option-text="Add Releasor"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasor Notary"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releasorNotaryId"
                :items="releasorNotarySelectOptions"
                label="Releasor Notary"
                default-option-text="Add Releasor Notary"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasor Witness"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releasorWitnessId"
                :items="releasorWitnessSelectOptions"
                label="Releasor Witness"
                default-option-text="Add Releasor Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset>
            <legend>Releasee</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasee"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releaseeId"
                :items="releaseeSelectOptions"
                label="Releasee"
                default-option-text="Add Releasee"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasee Notary"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releaseeNotaryId"
                :items="releaseeNotarySelectOptions"
                label="Releasee Notary"
                default-option-text="Add Releasee Notary"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Releasee Witness"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="releaseeWitnessId"
                :items="releaseeWitnessSelectOptions"
                label="Releasee Witness"
                default-option-text="Add Releasee Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Seal Date"
            rules="required"
            slim
          >
            <e-input
              v-model="sealDate"
              label="Seal Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="non-disclosure-agreement-form"
              button-text="Preview"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'nonDisclosureAgreementAstonVeer'

export default {
  name: 'GeneralReleaseOfLiability',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: '5d26a1fb-88fc-48ac-92fe-65d9567692ad',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      generalRelaeaseDate: '',
      releasorId: '',
      releaseeId: '',
      releasorNotaryId: '',
      releaseeNotaryId: '',
      releasorWitnessId: '',
      releaseeWitnessId: '',
      considerationSum: '',
      sealDate: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    releasorSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => ![
          this.releaseeId,
          this.releasorNotaryId,
          this.releaseeNotaryId,
          this.releasorWitnessId,
          this.releaseeWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    releaseeSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.releasorId,
          this.releasorNotaryId,
          this.releaseeNotaryId,
          this.releasorWitnessId,
          this.releaseeWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    releasorWitnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.releasorId,
          this.releaseeId,
          this.releasorNotaryId,
          this.releaseeNotaryId,
          this.releaseeWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    releaseeWitnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.releasorId,
          this.releaseeId,
          this.releasorNotaryId,
          this.releaseeNotaryId,
          this.releasorWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    releasorNotarySelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.releasorId,
          this.releaseeId,
          this.releaseeNotaryId,
          this.releasorWitnessId,
          this.releaseeWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    releaseeNotarySelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.releasorId,
          this.releaseeId,
          this.releasorNotaryId,
          this.releasorWitnessId,
          this.releaseeWitnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            generalRelaeaseDate: this.generalRelaeaseDate,
            ...this.releasorId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releasorId)
            },
            ...this.releaseeId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releaseeId)
            },
            ...this.releasorNotaryId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releasorNotaryId)
            },
            ...this.releaseeNotaryId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releaseeNotaryId)
            },
            ...this.releasorWitnessId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releasorWitnessId)
            },
            ...this.releaseeWitnessId && {
              employer: this.userAndContacts.find(({ id }) => id === this.releaseeWitnessId)
            },
            considerationSum: this.considerationSum,
            sealDate: this.sealDate
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'GeneralReleaseOfLiability',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'GeneralReleaseOfLiability',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-wide {
  flex: 100;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
