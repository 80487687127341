<template>
  <div class="category" :class="{ 'active': category.isActive }">
    <div class="category-title" @click="handleCategoryClicked">
      <span>
        <span>
          {{ category.text }}
        </span>

        <span v-if="displaySubItemsSize && category.sub.length">
          ({{ category.length || category.sub.length }})
        </span>
      </span>

      <span
        class="icon-chevron"
        :class="[category.isActive ? 'icon-chevron-down' : 'icon-chevron-up']"
      />
    </div>

    <transition
      name="item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <ul
        v-if="category.isActive"
        class="sub-category-list"
      >
        <li
          v-for="additional in category.additionals"
          :key="additional.id"
          class="additional"
        >
          <router-link
            v-slot="{ route, isActive }"
            :to="createIt3(additional)"
            custom
          >
            <span
              :class="{
                'router-link-active': isActive,
                'route-access-denied': !isRouteAccessAllowed(route.name)
              }"
              @click="handleSubCategoryClicked({ route })"
            >
              <span :class="additional.icon || 'icon-plus'" />
              {{ additional.text }}
            </span>
          </router-link>
        </li>

        <li
          v-for="subCategory in category.sub"
          :key="subCategory.id"
          class="sub-category"
        >
          <router-link
            v-if="subCategory.routeName"
            v-slot="{ route, isActive }"
            :to="createIt({ ...subCategory, categoryId: category.id })"
            custom
          >
            <span
              :class="{
                'sub-category-text': true,
                'router-link-active': isActive,
                completed: subCategory.completed,
                'route-access-denied': !isRouteAccessAllowed(route.name)
              }"
              @click="handleSubCategoryClicked({ route })"
            >
              <span
                v-if="displayIsValid && subCategory.isValid !== undefined"
                :class="subCategory.isValid ? 'icon-check-circle' : 'icon-circle'"
              />

              {{ subCategory.text }}

              <span v-if="subCategory.sub && subCategory.sub.length">
                ({{ subCategory.sub.length }})
              </span>
            </span>
          </router-link>

          <span v-else class="sub-category-text">
            {{ subCategory.text }}

            <span v-if="subCategory.sub && subCategory.sub.length">
              ({{ subCategory.sub.length }})
            </span>
          </span>

          <ul v-if="subCategory.sub" class="sub-sub-category-list">
            <li
              v-for="subSubItem in subCategory.sub"
              :key="subSubItem.id"
            >
              <router-link
                v-slot="{ route, isActive }"
                :to="createIt2({ ...subSubItem, subCategoryId: subCategory.id })"
                custom
              >
                <span
                  :class="{
                    'sub-category-text': true,
                    'router-link-active': isActive,
                    'route-access-denied': !isRouteAccessAllowed(route.name)
                  }"
                  @click="handleSubCategoryClicked({ route })"
                >
                  <span
                    v-if="displayIsValid && subSubItem.isValid !== undefined"
                    :class="subSubItem.isValid ? 'icon-check-circle' : 'icon-circle'"
                  />

                  {{ subSubItem.text }}
                </span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'

export default {
  name: 'AccordionItem',

  props: {
    category: {
      type: Object,
      default: () => ({})
    },

    displaySubItemsSize: {
      type: Boolean,
      default: true
    },

    displayIsValid: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'category-clicked',
    'sub-category-clicked',
    'toggle-menu'
  ],

  computed: {
    isSmall () {
      return this.$store.state.isSmall
    }
  },

  methods: {
    createIt3 (additionalItem) {
      return {
        name: additionalItem.routeName
      }
    },

    createIt (subCategory) {
      return {
        name: subCategory.routeName,
        params: {
          [subCategory.paramKey1 || 'id']: subCategory.categoryId,
          [subCategory.paramKey2 || 'id']: subCategory.id
        }
      }
    },

    createIt2 (subSubItem) {
      return {
        name: subSubItem.routeName,
        params: {
          [subSubItem.paramKey1 || 'id']: subSubItem.categoryId,
          [subSubItem.paramKey2]: subSubItem.subCategoryId,
          [subSubItem.paramKey3]: subSubItem.id
        }
      }
    },

    async checkJwtIsValid () {
      const jwt = await jwtDecode(sessionStorage.getItem('jwt'))
      const nowDateInSeconds = Math.floor(Date.now() / 1000)

      if (nowDateInSeconds > jwt.exp) throw new Error()
    },

    startTransition (el) {
      el.style.height = `${el.scrollHeight}px`
    },

    endTransition (el) {
      el.style.height = ''
    },

    isRouteAccessAllowed (routeName) {
      const route = this.$store.getters.routeAccessLookupObject?.[routeName]

      if (!routeName || !route) return true
      return route.routeAccessAllowed
    },

    handleCategoryClicked () {
      this.$emit('category-clicked', this.category)
    },

    async handleSubCategoryClicked ({ route }) {
      event.stopPropagation()

      try {
        const notADoubleClick = route.path !== this.$route.path

        if (notADoubleClick) {
          await this.checkJwtIsValid()
          if (this.isSmall) this.$emit('toggle-menu')
          this.$router.push(route)
        }

        this.$emit('sub-category-clicked', this.category.id)
      } catch (error) {
        this.$store.commit('setShowRefreshJwtDialog', true, { root: true })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.router-link-active,
.router-link-exact-active {
  color: $active-color;
  font-weight: 500;
}

.item-enter-active,
.item-leave-active {
  transition: height 0.3s ease;
  will-change: height;
}

.item-enter,
.item-leave-to {
  height: 0 !important;
}

.completed {
  text-decoration: line-through;
}

.category {
  border: $border-width solid $border-color-one;
  border-radius: $corner;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .category-title {
    align-items: center;
    background-color: $accordion-category-background-color;
    border-bottom: $border-width solid $border-color-one;
    color: $font-color-one;
    display: flex;
    justify-content: space-between;
    padding: 0.6rem;

    .icon-chevron {
      font-size: 1.4rem;
    }
  }

  &.active .category-title {
    background-color: $accordion-category-active-background-color;
    color: $font-color-one;
  }

  .sub-category-list {
    border-radius: $corner;
    list-style: none;
    overflow: hidden;
    padding: 0;

    .additional {
      border-bottom: $border-width solid $border-color-one;
      padding: 0.2rem 0;

      &:hover {
        color: $link-hover-color;
      }
    }

    .sub-category {
      border-bottom: $border-width solid $border-color-one;
      display: flex;
      flex-direction: column;

      .active {
        font-weight: normal;
      }

      .icon-check-circle {
        color: green;
      }

      .icon-circle,
      .icon-check-circle {
        padding-right: 0.2rem;
      }

      .sub-category-text {
        padding: 0.1rem 0.2rem;
        width: 100%;

        &:hover {
          color: $link-hover-color;
          font-weight: 400;
        }

        &.route-access-denied {
          cursor: not-allowed !important;
          opacity: 0.2 !important;
        }
      }

      .sub-sub-category-list {
        list-style-type: none;
        padding: 0 0.2rem;

        li {
          align-items: center;
          display: flex;
          height: 1.6rem;
        }
      }
    }
  }
}

@media screen and (min-width: $media-break-1) {
  .category {
    margin-bottom: 0.6rem;

    .category-title {
      border-radius: $corner;
    }

    /* &.active .category-title { */
    /*   color: $font-color-one; */
    /* } */

    .sub-category-list {
      padding-left: 0.6rem;

      .additional {
        border: 0;

        .route-access-denied {
          cursor: not-allowed !important;
          opacity: 0.2 !important;
        }
      }

      .sub-category {
        border: 0;
        padding: 0.3rem 0;

        .sub-category-text {
          border: 0;
          padding: 0 0.2rem;
        }

        .sub-sub-category-list {
          li {
            border: 0;
          }
        }
      }
    }
  }
}
</style>
