'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class DocumentRecord extends Model {
  static get entity () { return 'documentRecords' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      label: this.string(),
      type: this.string(),
      locale: this.string(),
      version: this.string(),
      content: this.string(),
      timestamp: this.number(),
      reference: this.string()
    }
  }
}
