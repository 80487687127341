<template>
  <message-form
    v-if="showView"
    message-type="reply"
    :predefined-subject="subject"
    :predefined-body="body"
    :predefined-to="to"
    :predefined-from="from"
    :set-cursor-in-body="setCursorInBody"
    @route-change="routeName => $emit('route-change', routeName)"
  />
</template>

<script>
import MessageForm from '@/components/forms/MessageForm'
import { format } from 'date-fns'

export default {
  name: 'Reply',

  components: {
    MessageForm
  },

  props: {
    messageId: {
      type: String,
      default: '',
      required: true
    },

    mailboxType: {
      type: String,
      default: '',
      required: true
    },

    setCursorInBody: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      subject: '',
      body: '',
      to: [],
      from: {},
      sentDate: '',
      showView: false
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 68,
      isInitial: true
    })

    try {
      const { subject, body, to, from, date } = await this.$store.dispatch('messages/getMessage', {
        messageId: this.messageId,
        mailboxType: this.mailboxType
      })

      this.to = to
      this.from = from
      this.sentDate = date
      this.subject = subject.includes('RE: ') ? `${subject}` : `RE: ${subject}`

      this.body = `

     ------------------
      On ${format(new Date(date), 'PPPPp')}, ${from.firstName} ${from.lastName} <${from.email}> wrote:

      ${body}`.replace(/  +/g, '')

      this.showView = true
    } catch (error) {
      this.$store.commit('snackbar/update',
        {
          type: 'error',
          message: 'Failed to get message'
        }
      )

      this.$store.dispatch('logError', {
        error,
        fileName: 'Messages > Reply',
        functionName: 'created'
      })
    }
  }
}
</script>
