'use strict'

import { key, message, sign } from 'openpgp'

export default async ({ unencryptedString, armoredPrivateKey }) => {
  const privateKey = await key.readArmored(armoredPrivateKey)

  const signedString = await sign({
    message: message.fromText(unencryptedString),
    signingKeys: privateKey
  })

  return signedString.data
}
