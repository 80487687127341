'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async jwt => {
  try {
    axios({
      method: 'get',
      url: `${VUE_APP_GATEWAY_URL}/idp/logout`,
      headers: { Authorization: jwt },
      timeout: 8000
    })
  } catch {}
}
