var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" UK Housing Act - Section 21 "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"uk-housing-act-section-twenty-one-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true,"data-test":"document-label-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Property Tenant To Leave")]),_c('validation-provider',{attrs:{"name":"Leave Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date Tenant Must Leave Property","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.leaveDate),callback:function ($$v) {_vm.leaveDate=$$v},expression:"leaveDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Client","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Property Address","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.propertyAddress),callback:function ($$v) {_vm.propertyAddress=$$v},expression:"propertyAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Tenant","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.tenantSelectOptions,"label":"Tenant of Property","default-option-text":"Add Tenant of Property","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.tenantId),callback:function ($$v) {_vm.tenantId=$$v},expression:"tenantId"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Landord(s)")]),_c('validation-provider',{attrs:{"name":"Landlord","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.landlords.length),expression:"landlords.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.landlords.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.landlords, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.landlordSelectOptions,"default-option-text":"Add Landlord","error-messages":errors,"success":valid,"is-required":true},on:{"change":_vm.addLandlordContact}})]}}],null,true)}),(_vm.landlords.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Landlord")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.landlords),function(landlord){return _c('tr',{key:landlord.id},[_c('td',[_vm._v(" "+_vm._s(landlord.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeLandlordContact(landlord.id)}}})],1)])}),0)]):_vm._e(),_c('validation-provider',{attrs:{"name":"Landlord(s) Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.landlordLabelSelectOptions,"label":"Above Landlord(s) Label","default-option-text":"Add Label of Landlords","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.landlordLabel),callback:function ($$v) {_vm.landlordLabel=$$v},expression:"landlordLabel"}})]}}],null,true)})],1),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"uk-housing-act-section-twenty-one-form","button-text":"Preview","data-test":"bottom-preview-button"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }