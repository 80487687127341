'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Todo extends Model {
  static get entity () { return 'todos' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      title: this.string(''),
      body: this.string(''),
      isExternal: this.boolean(),
      linkType: this.string(),
      path: this.string(''),
      type: this.string(''),
      // updatedDate: this.string(),
      deadlineDate: this.string(),
      publishedAt: this.string(),
      expiryDate: this.string(),
      emailTodo: this.boolean(false),
      isDone: this.boolean(false)
    }
  }
}
