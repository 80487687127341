<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        UK Housing Act - Section 8

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="uk-housing-act-section-eight-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Section 8 Date"
            rules="required"
            slim
          >
            <e-input
              v-model="sectionEightDate"
              label="Section 8 Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Property Tenant To Leave</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Property Address"
              :rules="{ required: true }"
              slim
            >
              <e-textarea
                v-model="propertyAddress"
                label="Property Address"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Tenant"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="tenantId"
                :items="tenantSelectOptions"
                label="Tenant of Property"
                default-option-text="Add Tenant of Property"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset>
            <legend>Why Tenant Must Leave Property</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Seeking Possession On Grounds"
              :rules="{ required: true }"
              slim
            >
              <e-textarea
                v-model="seekPossessionOnGround"
                label="Seeking Possession On Grounds"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Explaination Of Why Each Ground"
              :rules="{ required: true }"
              slim
            >
              <e-textarea
                v-model="explainationOfWhyEachGround"
                label="Explaination Of Why Each Ground"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="uk-housing-act-section-eight-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'ukHousingActSectionEightAstonVeer'

export default {
  name: 'UkHousingActSectionEight',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'c98468f2-813a-4703-85ba-b1d6124ca317',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      sectionEightDate: '',
      propertyAddress: '',
      tenantId: '',
      seekPossessionOnGround: '',
      explainationOfWhyEachGround: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person'])
    },

    tenantSelectOptions () {
      return this.userAndContacts
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            sectionEightDate: this.sectionEightDate,
            propertyAddress: this.propertyAddress,
            tenantId: this.tenantId,
            ...this.tenantId && {
              tenant: this.userAndContacts.find(({ id }) => id === this.tenantId)
            },
            seekPossessionOnGround: this.seekPossessionOnGround,
            explainationOfWhyEachGround: this.explainationOfWhyEachGround
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UkHousingActSectionEight',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UkHousingActSectionEight',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
