<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn animate__faster"
  >
    <div>
      <h2>Security Key Authenication</h2>

      <ul>
        <li>Choose one of your registered MeaVitae security keys to authenicate with</li>

        <li>Sync the chosen security key to your device by inserting or nfc, then click 'AUTHENTICATE' to begin</li>
      </ul>

      <img
        src="../assets/security_key.png"
        alt="Authenticator Security Key"
      >

      <e-button
        data-test="authenticate-button"
        @click="authenticateSecurityKey"
      >
        Authenticate
      </e-button>
    </div>
  </transition>
</template>

<script>
import convertArrayBufferToString from '@/utils/convertArrayBufferToString'
import getPublicKeyCredential from '@/auth/getPublicKeyCredential'

export default {
  name: 'SecurityKeyAuthentication',

  props: {
    rpId: {
      type: String,
      default: ''
    },

    challenge: {
      type: String,
      default: ''
    },

    securityKeyObjects: {
      type: Array,
      default: () => ([])
    }
  },

  emits: [
    'envelope-key',
    'security-key-authenticated',
    'error-message'
  ],

  computed: {
    credentialIds () {
      return this.securityKeyObjects
        .map(({ credentialId }) => credentialId)
    }
  },

  methods: {
    async authenticateSecurityKey () {
      try {
        if (!this.credentialIds.length) throw new Error('No credentials provided to authenticate with')
        if (!this.challenge) throw new Error('No challenge provided to authenticate with')
        if (!this.rpId) throw new Error('No rpId provided to authenticate with')

        const publicKeyCredential = await getPublicKeyCredential({
          credentialIds: this.credentialIds,
          rpId: this.rpId,
          challenge: this.challenge
        })
        if (!publicKeyCredential) throw new Error('Failed to retrieve publicKeyCredential')
        if (!publicKeyCredential.response?.userHandle) throw new Error('Authenticator did not return userHandle')

        const [envelopeKey] = convertArrayBufferToString(publicKeyCredential.response.userHandle).split(':')
        if ((typeof envelopeKey !== 'string') || !envelopeKey) throw new Error('envelopeKey was not retrieved correctly')

        this.$emit('envelope-key', envelopeKey)
        this.$emit('security-key-authenticated', true)
      } catch (error) {
        this.$emit('error-message', 'Your security failed to authenticate')

        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeyAuthentication',
          functionName: 'authenticateSecurityKey'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
button {
  background: rgb(134, 134, 195);
  border-radius: 4px;
  border-width: 0;
  color: $font-color-two;
  letter-spacing: 0.05em;
  margin-top: 20px;
  padding: 12px 20px;
  text-transform: uppercase;
  width: 100%;
}

h2 {
  padding: 20px 0;
}

img {
  display: block;
  margin: 10px auto;
}

ul {
  list-style: disc;

  li {
    margin: 10px 30px;
  }
}

.select-next-inputs {
  display: grid;
  grid-template-columns: 80% 20%;

  button {
    margin-left: 10px;
  }
}
</style>
