'use strict'

import { createId } from './'

export default ({ name, type }) => {
  const fileActionObject = {
    id: createId(),
    name,
    type,
    progressUpdator: event => {
      fileActionObject.progress = Math.round((event?.loaded * 100) / event?.total) || 0
    },
    progress: 0,
    abortController: new AbortController()
  }

  return fileActionObject
}
