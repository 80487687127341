'use strict'

import Insights from '@/views/Insights/Insights'
import InsightsDefault from '@/views/Insights/InsightsDefault'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  path: '/insights',
  name: 'Insights',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Insights
  },
  children: [
    {
      path: '',
      name: 'InsightsDefault',
      components: {
        appClosedLayout: LoadingSpinner,
        default: InsightsDefault
      },
      meta: { title: 'Insights' }
    }
  ]
}
