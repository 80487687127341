<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Witnesses

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(76)"
        />
      </h1>
    </div>

    <p>
      In order to execute a will and make it effective you must sign and have this document witnessed in a
      very specific way. The law requires:
    </p>

    <ul class="law-requirements">
      <li>
        You must sign and date first in the presence of your witnesses who must see you perform these
        actions.
      </li>

      <li>
        Your witnesses must sign after you to attest that they believe you are making this declaration
        while mentally capable and free from interference and add their contact details and occupation.
      </li>
    </ul>

    <e-select
      v-if="!maxWitnessesReached"
      :items="eligibleContactsAsSelectOptions"
      default-option-text="Add Witness"
      data-test="eligible-witnesses-select"
      @change="addWitness"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="witnesses.length">
        <legend>Witnesses</legend>

        <ul data-test="witness-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in witnesses" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-witness-button"
                @click="removeWitness(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>
  </div>
</template>

<script>
import Beneficiary from '@/models/Beneficiary'
import Contact from '@/models/Contact'
import Group from '@/models/Group'
import WitnessContact from '@/models/WitnessContact'
import Will from '@/models/Will'
import { getAge } from '@/utils'

export default {
  name: 'Witnesses',

  computed: {
    will () {
      return Will
        .query()
        .with('partner')
        .with('children')
        .with('witnesses')
        .first()
    },

    witnesses () {
      return this.will.witnesses
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    eligibleContactsAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => !this.witnesses
          .map(({ id }) => id)
          .includes(id))
        .map(({ id, selectText }) => ({
          value: id,
          text: selectText,
          isDisabled: this.isDisabled(id)
        }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    maxWitnessesReached () {
      const max = Group
        .all()
        .find(({ name }) => name === 'witnesses')
        .maxContacts

      return this.witnesses.length >= max
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 76,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    isDisabled (contactId) {
      return [
        ...this.will.partner ? [this.will.partner] : [],
        ...Contact.all()
          .filter(({ type }) => type === 'person')
          .filter(({ dateOfBirth }) => getAge(dateOfBirth) < 18),

        ...this.will.witnesses,
        ...Beneficiary
          .all()
          .map(({ contactId }) => ({ id: contactId }))
      ]
        .map(({ id }) => id)
        .includes(contactId)
    },

    async addWitness (contactId) {
      WitnessContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['witnessContacts'],
        message: 'Saved'
      })
    },

    async removeWitness (contactId) {
      WitnessContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['witnessContacts'],
        message: 'Saved'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.law-requirements {
  list-style: circle;
  padding-left: 1.4rem;
}
</style>
