<template>
  <div>
    <p>
      Welcome to Insights. We know that you are probably time poor but
      need to be information rich in order to better plan for the future.
      Therefore we have created Insights as a place where we can offer
      you curated content that can be an asset in helping in your planning.
    </p>
  </div>
</template>

<script>
export default {
  name: 'InsightsDefault',

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 117,
      isInitial: true
    })
  }
}
</script>
