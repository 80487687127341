'use strict'

export default async function myself (apiCallFunction, paramObject, tries = 1) {
  try {
    if (tries <= 3) return await apiCallFunction(paramObject)
  } catch (error) {
    if (error.response?.status === 401) throw error
    if (error.message === 'canceled') throw error
    if (tries === 3) throw error

    return myself(apiCallFunction, paramObject, ++tries)
  }
}
