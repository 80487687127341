<template>
  <div class="spaced">
    <e-dialog
      v-if="selectedAssetId"
      data-test="beneficiaries-manager-dialog"
    >
      <beneficiaries-manager
        :title="selectedAssetName"
        :beneficiary-ids="beneficiaryIds"
        :show-close-button="true"
        @add-beneficiary="addBeneficiary"
        @remove-beneficiary="removeBeneficiary"
        @closed="selectedAssetId = null"
      >
        <p>
          You can add either one contact, or a group of contacts as the beneficiary of this asset.
          You may also add a substitute beneficiary to each, who will replace them if they are
          deceased or fail at the time of probate.
        </p>

        <p>
          If you do not assign a specific beneficiary, then the asset will be left in your will as part
          of your residual estate.
        </p>
      </beneficiaries-manager>
    </e-dialog>

    <div class="top-inputs">
      <h1>
        Properties

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(71)"
        />
      </h1>
    </div>

    <p>
      This section lets you give properties you have created to named individuals or group
      of individuals.  It details whether properties have been assigned to beneficiaries or
      not and you can alter the beneficiaries for each property by clicking on the
      Actions icon next to each.
    </p>

    <e-table
      :headers="headers"
      :rows="rows"
      data-test="property-table"
    >
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            class="ghost"
            @click="selectedAssetId = item.id"
          >
            <span class="icon-edit" />
          </e-button>
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import Asset from '@/models/Asset'
import AssetBeneficiary from '@/models/AssetBeneficiary'
import Beneficiary from '@/models/Beneficiary'
import BeneficiariesManager from '@/components/BeneficiariesManager'
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'

export default {
  name: 'Properties',

  components: {
    BeneficiariesManager,
    EDialog,
    ETable
  },

  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'left'
        },
        {
          text: 'Beneficiaries',
          value: 'beneficiaries',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],

      selectedAssetId: null
    }
  },

  computed: {
    assets () {
      return Asset
        .query()
        .with('beneficiaries')
        .with('beneficiaries.contact')
        .with('beneficiaries.group')
        .get()
        .filter(({ type }) => type === 'property')
    },

    rows () {
      return this.assets
        .map(({ id, name, beneficiaries }) => ({
          id,
          name,
          beneficiaries: beneficiaries
            ?.sort((a, b) => b.percentageShare - a.percentageShare)
            .slice(0, 3)
            .map(({ name, percentageShare }) => `${name} (${percentageShare}%)`)
            .join(', ')
            .concat(beneficiaries.length > 3
              ? `, ...(+${beneficiaries.length - 3} more)`
              : ''
            ),
          rowClickedFunction: () => (this.selectedAssetId = id)
        }))
    },

    beneficiaryIds () {
      return this.assets
        .find(({ id }) => id === this.selectedAssetId)
        ?.beneficiaries
        .map(({ id }) => id)
    },

    selectedAssetName () {
      return this.assets
        .find(({ id }) => id === this.selectedAssetId)
        ?.name
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 71,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async addBeneficiary (beneficiaryObject) {
      const { beneficiaries } = await Beneficiary.insert({ data: beneficiaryObject })

      AssetBeneficiary.insert({
        data: {
          assetId: this.selectedAssetId,
          beneficiaryId: beneficiaries[0].id
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'assetBeneficiaries'],
        message: 'Beneficiary successfully added'
      })
    },

    async removeBeneficiary (beneficiaryId) {
      AssetBeneficiary.delete([this.selectedAssetId, beneficiaryId])
      Beneficiary.delete(beneficiaryId)

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries', 'assetBeneficiaries'],
        message: 'Beneficiary successfully removed'
      })
    }
  }
}
</script>
