<template>
  <div
    v-on-clickaway="hideFeedbackBar"
    class="feedback-bar"
  >
    <div class="header">
      <h1>Feedback Form</h1>

      <e-button
        class="close-button icon-x-circle ghost"
        @click="hideFeedbackBar"
      />
    </div>

    <validation-observer v-slot="{ handleSubmit }">
      <form
        class="spaced"
        @submit.prevent="handleSubmit(submit)"
      >
        <validation-provider
          v-slot="{ errors, valid }"
          name="feedback"
          :rules="{ required: true }"
          slim
        >
          <e-textarea
            v-model="message"
            :is-required="true"
            :error-messages="errors"
            :success="valid"
            rows="14"
          />
        </validation-provider>

        <e-input
          :value="addContactDetails"
          label="Add my contact details"
          type="checkbox"
          @change="value => addContactDetails = value"
        />

        <e-input
          :value="addSystemInfo"
          label="Add my system information"
          type="checkbox"
          @change="value => addSystemInfo = value"
        />

        <div class="submit-container">
          <e-button type="submit">
            Submit
          </e-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'FeedbackBar',

  mixins: [clickaway],

  data () {
    return {
      message: '',
      addContactDetails: true,
      addSystemInfo: true
    }
  },

  watch: {
    addContactDetails (value) {
      if (!value) {
        this.$store.commit('snackbar/update', {
          type: 'warning',
          message: 'Please note that if you send feedback anonymously, MeaVitae cannot respond to you'
        })
      }
    }
  },

  methods: {
    hideFeedbackBar () {
      this.$store.commit('setShowFeedbackBar', false)
    },

    submit () {
      this.$store.dispatch('sendFeedback', {
        message: this.message,
        sysInfo: this.addSystemInfo,
        anonymous: !this.addContactDetails,
        currentPath: this.$router.history.current.path,
        ...this.addContactDetails && {
          name: this.$store.getters.user.fullName,
          email: this.$store.getters.userEmailAddress,
          phone: this.$store.getters.userPhoneNumber
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback-bar {
  background-color: $main-background-color;
  border-left: 1px $border-style $border-color-one;
  height: 100%;
  padding: 1rem;
  position: fixed;
  right: 0;
  z-index: 4;

  .header {
    margin-bottom: 1rem;
  }

  .close-button {
    align-self: start;
    color: $link-color;
    padding: 0;

    &:hover {
      color: $link-hover-color;
    }
  }

  .submit-container {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (min-width: $media-break-1) {
  .feedback-bar {
    width: 30vw;
  }
}
</style>
