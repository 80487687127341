var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDeleteDialog)?_c('confirmation-dialog',{attrs:{"title":"Delete this contact"},on:{"confirm":_vm.deleteContact,"cancel":function($event){_vm.showDeleteDialog = false}}}):_vm._e(),(_vm.showLinkedAccountsDialog)?_c('e-dialog',{staticClass:"linked-accounts-dialog",attrs:{"data-test":"linked-accounts-dialog"}},[_c('p',[_vm._v(" Before deletion, this contact must be unlinked from the following: ")]),_c('ul',_vm._l((_vm.accountsLinkedWithThisContact),function(account){return _c('li',{key:account.id},[_vm._v(" "+_vm._s(account.name)+" ")])}),0),_c('e-button',{attrs:{"data-test":"cancel-button"},on:{"click":function($event){_vm.showLinkedAccountsDialog = false}}},[_vm._v(" Close ")])],1):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.isExistingContact ? 'Edit' : 'Create')+" Contact "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(3)}}})]),_c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeInLeft animate__faster"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"contact-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":_vm.type,"rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-radio-group',{attrs:{"value":_vm.type,"label":"Contact Type","is-required":true,"items":_vm.contactTypes,"error-messages":errors,"success":valid,"data-test":"contact-type-radio-group"},on:{"change":function (value) {
              _vm.type = value
              _vm.setIsDirty(true)
            }}})]}}],null,true)}),_c('div',{staticClass:"connect-input"},[(_vm.type !== '' && _vm.type === 'person')?_c('validation-provider',{attrs:{"name":"First Name","rules":{ required: _vm.type === 'person' },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"First Name","error-messages":errors,"success":valid,"is-required":true,"data-test":"first-name-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}):_vm._e(),(_vm.type !== '' && _vm.type === 'person')?_c('e-input',{attrs:{"label":"Middle Names","data-test":"middle-names-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.middleNames),callback:function ($$v) {_vm.middleNames=$$v},expression:"middleNames"}}):_vm._e(),(_vm.type !== '' && _vm.type === 'person')?_c('validation-provider',{attrs:{"name":"Last Name","rules":{ required: _vm.type === 'person' },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Last Name","error-messages":errors,"is-required":true,"success":valid,"data-test":"last-name-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}):_vm._e()],1),(_vm.type !== '' && _vm.type === 'person')?[_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":{ is_not_over_150_years_ago: true, is_past_date: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date of Birth","type":"date","error-messages":errors,"success":valid,"data-test":"date-of-birth-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Place Of Birth","data-test":"place-of-birth-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.placeOfBirth),callback:function ($$v) {_vm.placeOfBirth=$$v},expression:"placeOfBirth"}}),_c('e-select',{attrs:{"items":_vm.genderTypes,"label":"Gender","data-test":"gender-type-select"},on:{"change":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.genderType),callback:function ($$v) {_vm.genderType=$$v},expression:"genderType"}})],1),_c('e-input',{attrs:{"label":"Job Title","data-test":"job-title-input","help-id":9},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.jobTitle),callback:function ($$v) {_vm.jobTitle=$$v},expression:"jobTitle"}})]:_vm._e(),_c('validation-provider',{attrs:{"name":"Organisation Name","rules":{ required: ['organisation'].includes(_vm.type) },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Organisation Name","error-messages":errors,"success":valid,"is-required":_vm.type !== '' && _vm.type !== 'person',"help-id":{ person: 5, organisation: 6, charity: 7 }[_vm.type],"data-test":"organisation-name-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.organisationName),callback:function ($$v) {_vm.organisationName=$$v},expression:"organisationName"}})]}}],null,true)}),(_vm.type === 'organisation')?[_c('e-input',{attrs:{"label":"Organisation Registered Number"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.organisationRegisteredNumber),callback:function ($$v) {_vm.organisationRegisteredNumber=$$v},expression:"organisationRegisteredNumber"}}),_c('e-input',{attrs:{"label":"Territory Of Incorporation"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.organisationTerritoryOfIncorporation),callback:function ($$v) {_vm.organisationTerritoryOfIncorporation=$$v},expression:"organisationTerritoryOfIncorporation"}}),_c('e-input',{attrs:{"value":_vm.isCharity,"label":"Organistion Is A Charity?","type":"checkbox","data-test":"is-charity-checkbox"},on:{"input":function($event){return _vm.setIsDirty(true)},"change":function (value) { return _vm.isCharity = !!value; }}})]:_vm._e(),(_vm.contactIsACharity)?[_c('e-input',{attrs:{"label":"Acronym","help-id":10,"data-test":"acronym-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.acronym),callback:function ($$v) {_vm.acronym=$$v},expression:"acronym"}}),_c('validation-provider',{attrs:{"name":"Charity Number","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Charity Number","error-messages":errors,"success":valid,"is-required":true,"help-id":11,"data-test":"charity-number-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.charityNumber),callback:function ($$v) {_vm.charityNumber=$$v},expression:"charityNumber"}})]}}],null,true)}),_c('e-input',{attrs:{"value":_vm.isUkRegistered,"label":"Is UK Registered?","type":"checkbox","data-test":"is-uk-registered-checkbox"},on:{"input":function($event){return _vm.setIsDirty(true)},"change":function (value) { return _vm.isUkRegistered = value; }}})]:_vm._e(),_c('e-input',{attrs:{"label":"Website","data-test":"website-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('email-address-manager',{attrs:{"email-addresses":_vm.emailAddresses,"type":_vm.type},on:{"is-dirty":function($event){return _vm.setIsDirty(true)}}}),_c('phone-number-manager',{attrs:{"phone-numbers":_vm.phoneNumbers},on:{"is-dirty":function($event){return _vm.setIsDirty(true)}}}),_c('address-manager',{attrs:{"addresses":_vm.addresses},on:{"is-dirty":function($event){return _vm.setIsDirty(true)}}}),_c('e-textarea',{attrs:{"label":"Notes","data-test":"notes-textarea"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"contact-form"}}),(_vm.isExistingContact)?_c('e-button',{attrs:{"data-test":"delete-button"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" Delete ")]):_vm._e()],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }