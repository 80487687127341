'use strict'

import axios from 'axios'

const { VUE_APP_EMAIL_SCHEDULER_URL } = process.env

export default async id => {
  try {
    return await axios({
      method: 'delete',
      headers: {
        Authorization: sessionStorage.getItem('jwt')
      },
      url: `${VUE_APP_EMAIL_SCHEDULER_URL}/todo/${id}`,
      timeout: 8000
    })
  } catch (error) {}
}
