'use strict'

const oneMinuteInMilliseconds = 60000

export default async ({ rpId, userHandle, challenge, email, userId, excludeCredentialIds }) =>
  navigator.credentials.create({
    publicKey: {
      // attestation: 'direct', // direct = respond with data about the authenticator
      challenge: Uint8Array.from(atob(challenge), c => c.charCodeAt(0)),
      rp: {
        name: 'MeaVitae',
        id: rpId
      },
      user: {
        id: Uint8Array.from(userHandle, c => c.charCodeAt(0)),
        displayName: email,
        name: userId
      },
      pubKeyCredParams: [
        { type: 'public-key', alg: -7 },
        { type: 'public-key', alg: -257 }
      ],
      authenticatorSelection: {
        residentKey: 'required', // required = authenticator must store on it a discoverable credential (a.k.a. resident key inc userHandle)
        userVerification: 'required', // required = user must prove their presence touch/pin
        authenticatorAttachment: 'cross-platform' // cross-platform = not built in such as fingerprint reader. Can be moved between devices, e.g. a Yubikey
      },
      timeout: (oneMinuteInMilliseconds * 2),
      excludeCredentials: excludeCredentialIds
        .map(credentialId => ({
          type: 'public-key',
          id: Uint8Array.from(atob(credentialId), c => c.charCodeAt(0))
        })),
      extensions: {
        loc: true
      }
    }
  })
