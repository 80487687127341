<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      :title="`Delete ${name}`"
      @cancel="showDeleteDialog = false"
      @confirm="deleteLiability"
    />

    <e-dialog v-if="showFileUploadDialog">
      <file-upload-form
        @closed="showFileUploadDialog = false"
        @submitted="addFile"
      />
    </e-dialog>

    <h1>
      {{ isExistingLiability ? 'Edit' : 'Create' }} {{ getTypeAsText(type) }}

      <span
        class="icon-help-circle"
        @click.prevent="resetDefaultHelp"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="liability-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Name"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="name"
              label="Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="42"
              class="title-input"
              data-test="name-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-if="showSubTypeSelect"
            v-slot="{ errors, valid }"
            name="Type"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="subType"
              :items="subTypes"
              label="Type"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="sub-type-select"
              @change="setIsDirty(true)"
            />
          </validation-provider>

          <e-select
            v-model="supplierId"
            :items="suppliersAsSelectOptions"
            label="Supplier"
            :help-id="43"
            data-test="supplier-select"
            @change="setIsDirty(true)"
          />

          <e-input
            v-model="referenceId"
            label="Reference Id"
            :help-id="46"
            data-test="reference-id-input"
            @input="setIsDirty(true)"
          />

          <e-input
            v-if="showContractEndDateInput"
            v-model="contractEndDate"
            type="date"
            label="Contract End Date"
            data-test="contract-end-date-input"
            @input="setIsDirty(true)"
          />

          <e-textarea
            v-model="description"
            label="Description"
            data-test="description-textarea"
            @input="setIsDirty(true)"
          />

          <value-fieldset
            :visibility-object="visibleValuationElements"
            :valuation-date="valuationDate"
            :recurring-frequency="recurringFrequency"
            :currency="currency"
            :value="value"
            :ownership-percentage="ownershipPercentage"
            :payout="payout"
            :value-help-id="type === 'credit' ? 45 : 44"
            :ownership-percentage-help-id="47"
            :recurring-frequency-help-id="50"
            @is-dirty="setIsDirty(true)"
            @value-updated="updatePropertyValue"
          />

          <co-owners-fieldset
            v-if="showCoOwnersFieldset"
            :current-co-owner-ids="currentCoOwnerIds"
            :legend-text="coOwnersFieldsetLegendText"
            :help-id="coOwnersHelpId"
            @co-owner-added="addCoOwner"
            @co-owner-removed="removeCoOwner"
          />

          <fieldset
            v-if="showAssetsFieldset"
            data-test="assets-fieldset"
          >
            <legend>{{ assetsFieldsetLegendText }}</legend>

            <e-select
              :items="eligibleAssetsAsSelectOptions"
              label="Eligible Assets"
              :help-id="49"
              data-test="asset-select"
              @change="addAsset"
            />

            <h4 v-if="currentAssets.length">
              Associated Assets
            </h4>

            <ul v-if="currentAssets.length">
              <li
                v-for="asset in currentAssets"
                :key="asset.id"
                data-test="current-asset"
              >
                {{ asset.name }} ({{ toCapitalCase(asset.type) }})

                <e-button
                  class="ghost"
                  data-test="remove-asset-button"
                  @click="unlinkAsset(asset.id)"
                >
                  <span class="icon-trash-2" />
                </e-button>
              </li>
            </ul>
          </fieldset>

          <e-textarea
            v-model="notes"
            label="Notes"
            data-test="notes-textarea"
            @input="setIsDirty(true)"
          />

          <div>
            <e-button
              data-test="add-file-button"
              @click="showFileUploadDialog = true"
            >
              <span class="icon-plus" />
              Add File
            </e-button>
          </div>

          <files-table
            v-if="associatedFiles.length"
            :files="associatedFiles"
            @delete="removeFile"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="liability-form"
            />

            <e-button
              v-if="isExistingLiability"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import Asset from '@/models/Asset'
import Contact from '@/models/Contact'
import CoOwnersFieldset from './formElements/CoOwnersFieldset'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import EDialog from '@/components/dialogs/EDialog'
import File from '@/models/File'
import FilesTable from '@/components/FilesTable'
import FileUploadForm from '@/components/forms/FileUploadForm'
import Liability from '@/models/Liability'
import LiabilityAsset from '@/models/LiabilityAsset'
import LiabilityCoOwnerContact from '@/models/LiabilityCoOwnerContact'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import ValueFieldset from './formElements/ValueFieldset'
import liabilityTypes from '@/json_files/liabilityTypes.json'
import { capitalCase, camelCase, pascalCase } from 'change-case'

export default {
  name: 'LiabilityForm',

  components: {
    CoOwnersFieldset,
    ConfirmationDialog,
    EDialog,
    FilesTable,
    FileUploadForm,
    SaveBar,
    SubmitButton,
    ValueFieldset
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      liabilityId: '',
      name: '',
      type: '',
      subType: '',
      supplierId: '',
      referenceId: '',
      contractEndDate: '',
      notes: '',
      description: '',
      createdDate: '',

      valuationDate: '',
      recurringFrequency: '',
      currency: 'GBP',
      value: 0,
      ownershipPercentage: 100,
      payout: 0,

      currentAssetIds: [],
      currentCoOwnerIds: [],

      isDirty: false,
      isExistingLiability: true,

      showDeleteDialog: false,
      showFileUploadDialog: false,

      associatedFiles: []
    }
  },

  computed: {
    visibleValuationElements () {
      return {
        currency: true,
        valuationDate: true,
        recurringFrequency: !['credit', 'otherLiability'].includes(this.type),
        value: true,
        payout: this.subType === 'life',
        ownershipPercentage: this.type !== 'subscription' && this.subType !== 'creditCard'
      }
    },

    assetsFieldsetLegendText () {
      if (this.type === 'credit' && this.subType === 'loan') {
        return 'Collateral'
      }

      if (this.type === 'otherLiability') return 'Collateral'

      return 'Assets'
    },

    coOwnersFieldsetLegendText () {
      return this.subType === 'creditCard'
        ? 'Additional Card Holders'
        : 'Co-Owners'
    },

    subTypes () {
      if (!this.type) return []

      return liabilityTypes
        .find(({ type }) => type === this.type)
        .subTypes
    },

    currentAssets () {
      return Asset
        .query()
        .whereIdIn(this.currentAssetIds)
        .get()
    },

    eligibleAssetsAsSelectOptions () {
      return Asset
        .query()
        .get()
        .filter(({ id }) => !this.currentAssetIds.includes(id))
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    suppliersAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'organisation')
        .map(({ id, organisationName }) => ({ value: id, text: organisationName }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    coOwnersHelpId () {
      return this.subType === 'creditCard' ? 51 : 48
    },

    showContractEndDateInput () {
      return ['subscription', 'serviceAgreement', 'insurance'].includes(this.type)
    },

    showSubTypeSelect () {
      return !['otherLiability', 'subscription'].includes(this.type)
    },

    showCoOwnersFieldset () {
      return this.type !== 'subscription'
    },

    showAssetsFieldset () {
      if (this.type === 'subscription') return false
      return this.subType !== 'creditCard'
    }
  },

  watch: {
    showAssetsFieldset () {
      if (!this.showAssetsFieldset) {
        this.currentAssetIds = []
      }
    },

    subType () {
      if (this.subType === 'creditCard') {
        this.ownershipPercentage = 0
      }
    }
  },

  created () {
    const helpLookup = {
      NewCredit: 37,
      Credit: 37,
      NewServiceAgreement: 38,
      ServiceAgreement: 38,
      NewSubscription: 39,
      Subscription: 39,
      NewInsurance: 40,
      Insurance: 40,
      Other: 41,
      NewOtherLiability: 41
    }

    this.$store.dispatch('findAndSetHelpObject', {
      id: helpLookup[this.$router.history.current.name],
      isInitial: true
    })

    if (this.id) {
      const liability = Liability
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.liabilityId = liability.id
      this.name = liability.name
      this.type = liability.type
      this.subType = liability.subType
      this.supplierId = liability.supplierId
      this.referenceId = liability.referenceId
      this.contractEndDate = liability.contractEndDate
      this.notes = liability.notes
      this.description = liability.description
      this.createdDate = liability.createdDate

      this.valuationDate = liability.valuationDate
      this.recurringFrequency = liability.recurringFrequency
      this.currency = liability.currency
      this.value = liability.value
      this.ownershipPercentage = liability.ownershipPercentage
      this.payout = liability.payout

      this.currentAssetIds = LiabilityAsset
        .query()
        .where('liabilityId', value => value === this.liabilityId)
        .get()
        .map(({ assetId }) => assetId)

      this.currentCoOwnerIds = LiabilityCoOwnerContact
        .query()
        .where('liabilityId', value => value === this.liabilityId)
        .get()
        .map(({ contactId }) => contactId)

      this.setAssociatedFilesFromStore()
    } else {
      this.liabilityId = new Liability().id
      this.type = camelCase(this.$route.path.split('/')[2])
      this.isExistingLiability = false
    }
  },

  methods: {
    setAssociatedFilesFromStore () {
      const liability = Liability
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.associatedFiles = liability
        ? liability
          .files
          .map(({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }) => ({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }))
        : []
    },

    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    resetDefaultHelp () {
      const helpLookup = {
        credit: 37,
        serviceAgreement: 38,
        subscription: 39,
        insurance: 40,
        otherLiability: 41
      }

      this.$store.dispatch('findAndSetHelpObject', {
        id: helpLookup[this.type]
      })
    },

    getTypeAsText (typeToGet) {
      return liabilityTypes
        .find(({ type }) => type === typeToGet)
        .text
    },

    updatePropertyValue ({ property, value }) {
      this[property] = value
    },

    toCapitalCase: string => capitalCase(string),

    addCoOwner (contactId) {
      this.currentCoOwnerIds.push(contactId)
      this.setIsDirty(true)
    },

    removeCoOwner (coOwnerId) {
      this.currentCoOwnerIds = this.currentCoOwnerIds
        .filter(id => id !== coOwnerId)

      this.setIsDirty(true)
    },

    addAsset (assetId) {
      this.currentAssetIds.push(assetId)
      this.setIsDirty(true)
    },

    unlinkAsset (assetId) {
      this.currentAssetIds = this.currentAssetIds
        .filter(id => id !== assetId)

      this.setIsDirty(true)
    },

    async deleteLiability () {
      this.showDeleteDialog = false

      LiabilityAsset.delete(({ liabilityId }) => liabilityId === this.liabilityId)
      LiabilityCoOwnerContact.delete(({ liabilityId }) => liabilityId === this.liabilityId)
      Liability.delete(this.liabilityId)
      File.delete(({ ownerId }) => ownerId === this.liabilityId)

      this.$emit('deleted', { name: `New${pascalCase(this.type)}` })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: [
          'liabilityAssets',
          'liabilityCoOwnerContacts',
          'liabilities',
          'files'
        ],
        message: 'Liability successfully deleted'
      })
    },

    addFile ({ file, description, reference, referenceDate }) {
      this.showFileUploadDialog = false

      file.id = new File().id

      this.associatedFiles.push({
        id: file.id,
        fileName: file.name,
        reference: reference,
        uploadDate: new Date().toISOString(),
        owner: {
          name: this.name,
          type: pascalCase(this.type),
          id: this.liabilityId
        }
      })

      this.$store.commit('files/addFileObjToFilesToUpload', {
        file,
        reference,
        referenceDate,
        description,
        ownerId: this.liabilityId,
        ownerType: 'liabilities'
      })

      this.setIsDirty(true)
    },

    removeFile (fileId) {
      this.$store.commit('files/addFileIdToFilesToDelete', fileId)
      this.associatedFiles = this.associatedFiles.filter(({ id }) => id !== fileId)
      this.setIsDirty(true)
    },

    handleLiabilityLogic () {
      Liability.insertOrUpdate({
        data: {
          id: this.liabilityId,
          name: this.name,
          type: this.type,
          subType: this.subType,
          supplierId: this.supplierId,
          referenceId: this.referenceId,
          contractEndDate: this.contractEndDate,
          notes: this.notes,
          description: this.description,
          createdDate: this.createdDate || new Date().toISOString(),

          valuationDate: this.valuationDate,
          recurringFrequency: this.recurringFrequency,
          currency: this.currency,
          value: this.value,
          ownershipPercentage: this.ownershipPercentage,
          payout: this.subType === 'life' ? this.payout : 0
        }
      })
    },

    handleAssociatedAssetsLogic () {
      LiabilityAsset.delete(({ liabilityId }) => liabilityId === this.liabilityId)

      LiabilityAsset.insert({
        data: this.currentAssetIds.map(assetId => ({
          liabilityId: this.liabilityId,
          assetId
        }))
      })
    },

    handleCoOwnerLogic () {
      LiabilityCoOwnerContact.delete(({ liabilityId }) => liabilityId === this.liabilityId)

      LiabilityCoOwnerContact.insert({
        data: this.currentCoOwnerIds.map(coOwnerId => ({
          liabilityId: this.liabilityId,
          contactId: coOwnerId
        }))
      })
    },

    async submit () {
      this.handleLiabilityLogic()
      this.handleAssociatedAssetsLogic()
      this.handleCoOwnerLogic()

      await this.$store.dispatch('files/handleAssociatedFilesLogic')

      this.setAssociatedFilesFromStore()

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: [
          'liabilities',
          'liabilityAssets',
          'liabilityCoOwnerContacts',
          'files'
        ],
        message: 'Liability successfully saved'
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: pascalCase(this.type),
        params: { id: this.liabilityId }
      })
    }
  }
}
</script>
