'use strict'
import axios from 'axios'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt }) => {
  const response = await axios({
    method: 'GET',
    headers: {
      Authorization: jwt
    },
    url: `${VUE_APP_ORDERS_URL}/purchases`,
    timeout: 8000
  })
  return response.data
}
