<template>
  <div>
    <h1>
      Generated Documents
    </h1>

    <e-input
      v-model="filter"
      placeholder="Filter"
      class="filter-input"
    />

    <e-table
      v-model="selectedForDeletion"
      :headers="headers"
      :rows="rows"
      :filter="filter"
    >
      <template #actions="{ item }">
        <template v-if="isMobileDevice">
          <span class="unavailable-on-mobile">
            Unavailable on mobile
          </span>
        </template>

        <template v-else>
          <div v-if="item.available" class="actions">
            <e-button
              v-if="false"
              download
              class="icon-markdown ghost"
              @click="event => generateMarkupFile({ item, event })"
            />

            <e-button
              class="icon-file-pdf ghost"
              @click="event => generatePdf({ item, event })"
            />

            <e-button
              class="icon-file-text ghost"
              @click="event => generateDocx({ item, event })"
            />
          </div>

          <div v-else>
            <span class="creating-docs">
              creating documents...
            </span>
          </div>
        </template>
      </template>
    </e-table>

    <table-delete-dialog
      v-if="selectedForDeletion.length"
      item-type="Will Record"
      @delete-confirmed="deleteDocumentRecords"
    />
  </div>
</template>

<script>
import DocumentRecord from '@/models/DocumentRecord'
import DOMPurify from 'dompurify'
import ETable from '@/components/ETable'
import HTMLtoDOCX from 'html-to-docx'
import TableDeleteDialog from '@/components/dialogs/TableDeleteDialog'
import decryptString from '@/utils/decryptString'
import deleteDocumentRecord from '@/apis/vault/deleteDocumentRecord'
import documentTypes from '@/json_files/documentTypes.json'
import getDocumentDefinition from '@/utils/getDocumentDefinition'
import getDocumentRecord from '@/apis/vault/getDocumentRecord'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfMake from 'pdfmake/build/pdfmake'
import { differenceInSeconds, format } from 'date-fns'
import { marked } from 'marked'
import { snakeCase } from 'change-case'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const documentTemplateTypeLookup = Object
  .fromEntries(documentTypes.map(({ id, title }) => [id, title]))

export default {
  name: 'DocumentsTable',

  components: {
    ETable,
    TableDeleteDialog
  },

  data () {
    return {
      selectedForDeletion: [],
      filter: '',

      headers: [
        {
          text: 'Label',
          value: 'label',
          align: 'left',
          searchable: true
        },
        {
          text: 'Type',
          value: 'type',
          align: 'left'
        },
        {
          text: 'Created Date',
          value: 'createdDate',
          align: 'left'
        },
        {
          text: 'Download',
          value: 'actions',
          sortable: false
        }
      ],

      currentTimestamp: Date.now(),
      intervalID: 0
    }
  },

  computed: {
    isMobileDevice () {
      return this.$store.getters.isMobileDevice
    },

    documentRecords () {
      return DocumentRecord.all()
    },

    rows () {
      return this.documentRecords
        .map(({ id, label, type, timestamp, reference }) => ({
          id,
          label,
          type: documentTemplateTypeLookup[type],
          createdDate: format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss'),
          reference,
          available: differenceInSeconds(this.currentTimestamp, timestamp) > 30,
          rowClickedFunction: () => {}
        }))
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 62,
      isInitial: true
    })

    this.intervalID = setInterval(() => (this.currentTimestamp = Date.now()), 3000)
  },

  beforeDestroy () {
    clearInterval(this.intervalID)
  },

  methods: {
    removeHtml (content = '') {
      const span = document.createElement('span')
      span.innerHTML = content
      return span.textContent || span.innerText || ''
    },

    async deleteDocumentRecords () {
      try {
        const referenceArray = this.documentRecords
          .filter(({ id }) => this.selectedForDeletion.includes(id))
          .map(({ reference }) => reference)

        this.selectedForDeletion.forEach(id => DocumentRecord.delete(id))
        this.selectedForDeletion = []

        await deleteDocumentRecord({
          userId: this.$store.state.userId,
          jwt: sessionStorage.getItem('jwt'),
          referenceArray
        })

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Selected documents have been successfully deleted.'
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'failed to sync to our servers'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DocumentsTable',
          functionName: 'deleteDocumentRecords'
        })
      }
    },

    async generateDocx ({ item: { reference } }, event) {
      if (event) event.stopPropagation()

      try {
        const response = await getDocumentRecord({
          userId: this.$store.state.userId,
          jwt: sessionStorage.getItem('jwt'),
          reference
        })

        const template = JSON.parse(
          decodeURIComponent(escape(
            atob(
              await decryptString({
                encryptedString: response.data.data,
                armoredPrivateKey: this.$store.state.armoredPrivateKey
              })
            )
          ))
        )
        const titleRegex = /(?:#{1,2} <center>)(?<title>.*)(?:<\/center>)/m
        const { title } = titleRegex.exec(template).groups
        const globalRegex = /(?:#{1,2} <center>)(.*)(?:<\/center>)/gm
        const subst = '<h2 style="text-align: center">$1</h2>'
        const wordTemplate = template.replace(globalRegex, subst)
        const html = DOMPurify
          .sanitize(marked(wordTemplate, { gfm: true }))
        const documentOptions = {
          header: true,
          footer: true,
          pageNumber: true,
          footerType: 'first',
          skipFirstHeaderFooter: true,
          creator: 'MeaVitae Document Renderer',
          title,
          keywords: ['MeaVitae', title]
        }
        const footerHTMLString = `<p style="margin-right: 30%;"> MeaVitae - ${title} - </p>`
        const headerHTMLString = `<p>${title}</p>`
        const wordDocBlob = await HTMLtoDOCX(html, headerHTMLString, documentOptions, footerHTMLString)

        const link = document.createElement('a')
        link.download = `${format(Date.now(), 'yyyy-MM-dd-HH:mm:ss')}_${title}.docx`
        link.href = URL.createObjectURL(wordDocBlob)
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Word could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DocumentsTable',
          functionName: 'generateDocx'
        })
      }
    },

    async generatePdf ({ item: { reference } }, event) {
      if (event) event.stopPropagation()

      try {
        const response = await getDocumentRecord({
          userId: this.$store.state.userId,
          jwt: sessionStorage.getItem('jwt'),
          reference
        })

        const template = JSON.parse(
          decodeURIComponent(escape(
            atob(
              await decryptString({
                encryptedString: response.data.data,
                armoredPrivateKey: this.$store.state.armoredPrivateKey
              })
            )
          ))
        )

        const html = DOMPurify
          .sanitize(marked(template, { gfm: true }))

        pdfMake
          .createPdf(getDocumentDefinition({ html, isPreview: false }))
          .open()
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'PDF could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DocumentsTable',
          functionName: 'generatePdf'
        })
      }
    },

    async generateMarkupFile ({ item: { reference, label, createdDate }, event }) {
      if (event) event.stopPropagation()

      try {
        const response = await getDocumentRecord({
          userId: this.$store.state.userId,
          jwt: sessionStorage.getItem('jwt'),
          reference
        })

        const template = this.removeHtml(
          JSON.parse(
            decodeURIComponent(escape(
              atob(
                await decryptString({
                  encryptedString: response.data.data,
                  armoredPrivateKey: this.$store.state.armoredPrivateKey
                })
              )
            ))
          )
        )

        const url = URL.createObjectURL(new Blob([template], { type: 'text/plain' }))
        const a = document.createElement('a')
        a.href = url
        a.download = `${snakeCase(`${label}-${createdDate}`)}.md`
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Markup file could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DocumentsTable',
          functionName: 'generateMarkupFile'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.creating-docs {
  animation: blinkingText 2s infinite;
}

@keyframes blinkingText {
  50% {
    opacity: 0;
  }
}

.filter-input {
  margin-bottom: 0.6rem;
  max-width: 50%;
}

.unavailable-on-mobile {
  color: $color-warning;
}
</style>
