import Vue from 'vue'
import attemptApiCall from '../utils/attemptApiCall'
import decryptString from '../utils/decryptString'
import deleteMessage from '../apis/comms/deleteMessage'
import getInboxInfo from '../apis/comms/getInboxInfo'
import getMessage from '../apis/comms/getMessage'
import getMessages from '../apis/comms/getMessages'
import patchMessageAsRead from '../apis/comms/patchMessageAsRead'
import patchRestoreMessage from '../apis/comms/patchRestoreMessage'
import postMessage from '../apis/comms/postMessage'
import putDraftMessage from '../apis/comms/putDraftMessage'

export default {
  namespaced: true,

  state: {
    unreadMessageIds: [],
    matchedIdpContacts: [],
    sessionDeletedMessageIds: [],
    sessionReadMessageIds: [],
    sessionRestoredMessageIds: []
  },

  getters: {
    numNewMessages: state => {
      return state.unreadMessageIds
        .filter(unreadMessageId => !state.sessionReadMessageIds.includes(unreadMessageId))
        .filter(unreadMessageId => !state.sessionDeletedMessageIds.some(({ id }) => id === unreadMessageId))
        .length
    }
  },

  actions: {
    getMessage: async ({ commit, rootState: { armoredPrivateKey } }, { messageId, mailboxType }) => {
      try {
        const { data: { subject, body, to, from, date, attachments = [], isRead, unknowns } } = await attemptApiCall(
          getMessage,
          {
            messageId,
            mailboxType,
            jwt: sessionStorage.getItem('jwt')
          }
        )

        const decryptedBody = body
          ? await decryptString({ encryptedString: body, armoredPrivateKey })
          : ''

        const decryptedAttachments = await Promise.all(attachments
          .map(async ({ encryptedAttachmentObject }) => {
            const decryptedAttachment = await decryptString({
              encryptedString: encryptedAttachmentObject,
              armoredPrivateKey
            })

            return JSON.parse(decryptedAttachment)
          })
        )

        return {
          subject,
          body: decryptedBody,
          to,
          from,
          date,
          attachments: decryptedAttachments,
          isRead,
          unknowns
        }
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getMessages: async ({ commit }, { type, limit, cursor, filter }) => {
      try {
        const { data: { messages, listComplete, cursor: newCursor } } = await attemptApiCall(
          getMessages,
          {
            type,
            jwt: sessionStorage.getItem('jwt'),
            ...limit && { limit },
            ...cursor && { cursor },
            ...filter && { filter }
          }
        )

        return { messages, listComplete, cursor: newCursor }
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    deleteMessage: async ({ commit }, { messageId, mailboxType }) => {
      try {
        await attemptApiCall(
          deleteMessage,
          {
            jwt: sessionStorage.getItem('jwt'),
            type: mailboxType,
            messageId
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    restoreMessage: async ({ commit }, { messageId, mailboxType }) => {
      try {
        await attemptApiCall(
          patchRestoreMessage,
          {
            jwt: sessionStorage.getItem('jwt'),
            type: mailboxType,
            messageId
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    sendMessage: async ({ commit }, messageObject) => {
      try {
        const response = await attemptApiCall(
          postMessage,
          {
            jwt: sessionStorage.getItem('jwt'),
            messageObject
          }
        )

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    saveDraft: async ({ commit }, messageObject) => {
      try {
        const { data: { mesgId: messageId } } = await attemptApiCall(
          putDraftMessage,
          {
            jwt: sessionStorage.getItem('jwt'),
            messageObject
          }
        )

        return messageId
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setUnreadMessageIds: async ({ commit }) => {
      try {
        const { data: { unreadMessageIds } } = await attemptApiCall(
          getInboxInfo,
          {
            jwt: sessionStorage.getItem('jwt')
          }
        )

        commit('setUnreadMessageIds', unreadMessageIds || [])
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setMessageAsRead: async ({ commit }, messageId) => {
      try {
        const { data: { unread } } = await attemptApiCall(
          patchMessageAsRead,
          {
            messageId,
            jwt: sessionStorage.getItem('jwt')
          }
        )

        return unread
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setUnreadMessageIds: (state, value) => Vue.set(state, 'unreadMessageIds', value),
    addSessionReadMessageId: (state, id) => state.sessionReadMessageIds.push(id),

    addSessionDeletedMessageId: (state, { messageId, mailboxType, originBox }) => state
      .sessionDeletedMessageIds
      .push({
        messageId,
        mailboxType,
        ...originBox && { originBox }
      }),

    removeSessionDeletedMessageId: (state, { messageId, mailboxType }) => {
      Vue.set(
        state,
        'sessionDeletedMessageIds',
        state.sessionDeletedMessageIds
          .filter(sessionObject => {
            if (sessionObject.messageId !== messageId) return true

            return sessionObject.mailboxType !== mailboxType
          })
      )
    },

    addSessionRestoredMessageId: (state, { messageId, mailboxType }) => state
      .sessionRestoredMessageIds
      .push({ messageId, mailboxType }),

    removeSessionRestoredMessageId: (state, { messageId, mailboxType }) => {
      Vue.set(
        state,
        'sessionRestoredMessageIds',
        state.sessionRestoredMessageIds
          .filter(sessionObject => {
            if (sessionObject.messageId !== messageId) return true

            return sessionObject.mailboxType !== mailboxType
          })
      )
    }
  }
}
