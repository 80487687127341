'use strict'

import convertBase64BlockToArrayBuffer from '../utils/convertBase64BlockToArrayBuffer'

const oneMinuteInMilliseconds = 60000

export default async ({ credentialIds, rpId, challenge }) => navigator.credentials.get({
  publicKey: {
    rpId,
    challenge: convertBase64BlockToArrayBuffer(challenge),
    timeout: oneMinuteInMilliseconds,
    userVerification: 'required', // required = client will only allow authenticator that does touch/PIN
    allowCredentials: credentialIds
      .map(credentialId => ({
        type: 'public-key',
        id: Uint8Array.from(atob(credentialId), c => c.charCodeAt(0))
      }))
  }
})
