'use strict'

import axios from 'axios'

const { VUE_APP_EMAIL_SCHEDULER_URL } = process.env

export default async ({ id, message, todoTimestamp }) => {
  try {
    axios({
      method: 'put',
      headers: {
        Authorization: sessionStorage.getItem('jwt')
      },
      url: `${VUE_APP_EMAIL_SCHEDULER_URL}/todo/${id}`,
      data: { message, todoTimestamp },
      timeout: 8000
    })
  } catch (error) {}
}
