<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Knowledge Base
        </h1>

        <e-input
          v-model="search"
          :delay="true"
          placeholder="Search"
          class="search"
          @input="performSearch"
        />

        <accordion
          v-if="items.length > 0"
          :items="items"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @check-article-out-of-sync-with-accordion="checkArticleOutOfSyncWithAccordion"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'KnowledgeBase',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      search: '',
      active: ''
    }
  },

  computed: {
    items () {
      return this.$store.state.knowledgeBase.categories.map(category => ({
        id: category.id,
        text: category.title,
        isActive: this.active === category.id,
        sub: category.subCategory
          .filter(sub => sub.count > 0)
          .map(sub => ({
            id: sub.id,
            text: sub.title,
            routeName: `${category.id}-${sub.id}`,
            paramKey1: 'categoryId',
            paramKey2: 'subCategoryId'
          }))
      }))
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 139,
      isInitial: true
    })

    if (this.$route.params.searchString) {
      this.prePopulateSearchInput()
    }
  },

  methods: {
    checkArticleOutOfSyncWithAccordion (categoryId) {
      this.active = categoryId
    },

    prePopulateSearchInput () {
      this.search = this.$route.params.searchString
    },

    performSearch (string) {
      this.$router.push({
        name: 'KnowledgeBaseSearchResult',
        ...string && {
          params: {
            searchString: string
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 0.6em;
}
</style>
