<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      :title="`Delete ${name}`"
      @cancel="showDeleteDialog = false"
      @confirm="deleteAsset"
    />

    <e-dialog v-if="showFileUploadDialog">
      <file-upload-form
        @closed="showFileUploadDialog = false"
        @submitted="addFile"
      />
    </e-dialog>

    <h1>
      {{ isExistingAsset ? 'Edit' : 'Create' }} {{ getTypeAsText(type) }}

      <span
        class="icon-help-circle"
        @click.prevent="resetDefaultHelp"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="asset-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-if="type !== 'share'"
            v-slot="{ errors, valid }"
            name="Name"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="name"
              label="Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="19"
              class="title-input"
              data-test="name-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <share-lookup
            v-else
            :name="name"
            @value-updated="updatePropertyValue($event); setIsDirty(true)"
            @company-selected="setShareData"
          />

          <validation-provider
            v-if="showSubTypeInput"
            v-slot="{ errors, valid }"
            name="Type"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="subType"
              :items="subTypes"
              label="Type"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="sub-type-select"
              @change="setIsDirty(true)"
            />
          </validation-provider>

          <share-fieldset
            v-if="type === 'share'"
            :quantity="quantity"
            :symbol="symbol"
            :exchange="exchange"
            :price="sharePrice"
            :currency="currency"
            @is-dirty="setIsDirty(true)"
            @value-updated="updatePropertyValue"
          />

          <validation-provider
            v-if="type === 'property'"
            v-slot="{ errors, valid }"
            name="Land Registry Ownership Type"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="landRegistryOwnershipType"
              :items="landRegistryOwnershipTypes"
              label="Land Registry Ownership Type"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="34"
              data-test="land-registry-ownership-type-select"
              @change="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-if="type === 'property'"
            v-model="landRegistryNumber"
            label="Land Registry Number"
            data-test="land-registry-number-input"
            @input="setIsDirty(true)"
          />

          <e-textarea
            v-model="description"
            label="Description"
            data-test="description-textarea"
            @input="setIsDirty(true)"
          />

          <template v-if="type === 'vehicle'">
            <div class="connect-input">
              <e-input
                v-model="vehicleRegistration"
                label="Vehicle Registration"
                data-test="vehicle-registration-input"
                @input="setIsDirty(true)"
              />

              <e-input
                v-model="vehicleV5Number"
                label="Vehicle V5 Number"
                data-test="vehicle-v5-number-input"
                @input="setIsDirty(true)"
              />
            </div>

            <div class="connect-input">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Vehicle MOT Due Date"
                :rules="{
                  ...vehicleMOTEmailTodo && {
                    required: vehicleMOTEmailTodo,
                    is_one_month_in_future_datetime: !vehicleMOTTodoId
                  }
                }"
              >
                <e-input
                  v-model="vehicleMOTDate"
                  :error-messages="errors"
                  :success="valid"
                  label="Vehicle MOT Due Date"
                  type="date"
                  data-test="vehicle-mot-due-date-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <e-input
                :value="vehicleMOTEmailTodo"
                type="checkbox"
                label="Add a todo reminder and email me one month before the MOT Due Date"
                @change="value => vehicleMOTEmailTodo = value"
              />
            </div>
          </template>

          <validation-provider
            v-if="type !== 'share'"
            v-slot="{ errors, valid }"
            name="Address"
            :rules="{ required: type === 'property' }"
            slim
          >
            <google-address-input
              v-model="address.placeResult['formatted_address']"
              label="Address"
              :address-object="address"
              :is-required="type === 'property'"
              :enable-geolocation="true"
              :error-messages="errors"
              :success="valid"
              :help-id="addressHelpId"
              data-test="google-address-input"
              @address-selected="updateAddress"
            />
          </validation-provider>

          <e-input
            v-if="type === 'property'"
            :value="isMainResidence"
            label="Is main residence?"
            type="checkbox"
            data-test="is-main-residence-checkbox"
            @input="setIsDirty(true)"
            @change="value => isMainResidence = value"
          />

          <fieldset v-if="type === 'property'">
            <legend>Legacy Decisions</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Future Ownership Type"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="futureOwnershipType"
                :items="futureOwnershipTypes"
                label="Future Ownership Type"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                :help-id="101"
                data-test="future-ownership-type-select"
                @change="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Outstanding Loans"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="outstandingLoansPaidBy"
                :items="outstandingLoansPaidByOptions"
                label="Outstanding Loans"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                :help-id="102"
                data-test="outstanding-loans-paid-by-select"
                @change="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <value-fieldset
            :visibility-object="visibleValuationElements"
            :valuation-date="valuationDate"
            :currency="currency"
            :value="value"
            :ownership-percentage="ownershipPercentage"
            :value-help-id="valueHelpId"
            :ownership-percentage-help-id="27"
            @is-dirty="setIsDirty(true)"
            @value-updated="updatePropertyValue"
          />

          <co-owners-fieldset
            v-if="showCoOwnersFieldset"
            :current-co-owner-ids="currentCoOwnerIds"
            :help-id="28"
            @co-owner-added="addCoOwner"
            @co-owner-removed="removeCoOwner"
          />

          <fieldset
            v-if="showLiabilitiesFieldset"
            data-test="liabilities-fieldset"
          >
            <legend>Liabilities</legend>

            <e-select
              :items="eligibleLiabilitiesAsSelectOptions"
              label="Eligible Liabilities"
              :help-id="29"
              data-test="liability-select"
              @change="addLiability"
            />

            <fieldset
              v-if="currentLiabilities.length"
              data-test="current-liabilities-fieldset"
            >
              <legend>Associated Liabilities</legend>

              <ul>
                <li
                  v-for="liability in currentLiabilities"
                  :key="liability.id"
                  data-test="current-liability"
                >
                  {{ liability.name }} ({{ toCapitalCase(liability.type) }})

                  <e-button
                    class="ghost"
                    data-test="remove-liability-button"
                    @click="unlinkLiability(liability.id)"
                  >
                    <span class="icon-trash-2" />
                  </e-button>
                </li>
              </ul>
            </fieldset>
          </fieldset>

          <e-textarea
            v-model="notes"
            label="Notes"
            data-test="notes-textarea"
            :help-id="notesHelpId"
            @input="setIsDirty(true)"
          />

          <div>
            <e-button
              data-test="add-file-button"
              @click="showFileUploadDialog = true"
            >
              <span class="icon-plus" />
              Add File
            </e-button>
          </div>

          <files-table
            v-if="associatedFiles.length"
            :files="associatedFiles"
            @delete="removeFile"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="asset-form"
            />

            <e-button
              v-if="isExistingAsset"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import Asset from '@/models/Asset'
import AssetBeneficiary from '@/models/AssetBeneficiary'
import AssetCoOwnerContact from '@/models/AssetCoOwnerContact'
import Beneficiary from '@/models/Beneficiary'
import CoOwnersFieldset from './formElements/CoOwnersFieldset'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import EDialog from '@/components/dialogs/EDialog'
import File from '@/models/File'
import FilesTable from '@/components/FilesTable'
import FileUploadForm from '@/components/forms/FileUploadForm'
import GoogleAddressInput from './formElements/GoogleAddressInput'
import Liability from '@/models/Liability'
import LiabilityAsset from '@/models/LiabilityAsset'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import ShareLookup from '@/components/ShareLookup'
import ShareFieldset from './formElements/ShareFieldset'
import Todo from '@/models/Todo'
import ValueFieldset from './formElements/ValueFieldset'
import Will from '@/models/Will'
import assetTypes from '@/json_files/assetTypes.json'
import attemptApiCall from '@/utils/attemptApiCall'
import deleteScheduledTodoEmail from '@/apis/emailScheduler/deleteScheduledTodoEmail'
import { capitalCase, camelCase, pascalCase } from 'change-case'
import { createId } from '@/utils'
import { subMonths, format } from 'date-fns'

export default {
  name: 'AssetForm',

  components: {
    CoOwnersFieldset,
    ConfirmationDialog,
    EDialog,
    FilesTable,
    FileUploadForm,
    GoogleAddressInput,
    SaveBar,
    ShareFieldset,
    ShareLookup,
    SubmitButton,
    ValueFieldset
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    const {
      landRegistryOwnershipTypes,
      futureOwnershipTypes,
      outstandingLoansPaidByOptions
    } = assetTypes.find(({ type }) => type === 'property')

    return {
      assetId: '',
      type: '',
      subType: '',
      name: '',
      latitude: null,
      longitude: null,
      address: {
        id: createId(),
        placeResult: {
          formatted_address: ''
        },

        returnData: {}
      },

      isMainResidence: false,
      futureOwnershipType: '',
      outstandingLoansPaidBy: '',
      notes: '',
      description: '',
      vehicleRegistration: '',
      vehicleV5Number: '',
      vehicleMOTDate: '',
      vehicleMOTTodoId: '',
      vehicleMOTEmailTodo: '',
      landRegistryOwnershipType: '',
      landRegistryNumber: '',
      createdDate: '',

      symbol: '',
      exchange: '',
      sharePrice: 0,
      quantity: 0,

      valuationDate: '',
      currency: 'GBP',
      value: 0,
      ownershipPercentage: 100,

      currentLiabilityIds: [],
      currentCoOwnerIds: [],

      isDirty: false,
      isExistingAsset: true,

      showDeleteDialog: false,
      showFileUploadDialog: false,

      associatedFiles: [],

      landRegistryOwnershipTypes,
      futureOwnershipTypes,
      outstandingLoansPaidByOptions
    }
  },

  computed: {
    will () {
      return Will.query().first()
    },

    subTypes () {
      if (!this.type) return []

      return assetTypes
        .find(({ type }) => type === this.type)
        .subTypes
    },

    currentLiabilities () {
      return Liability
        .query()
        .whereIdIn(this.currentLiabilityIds)
        .get()
    },

    eligibleLiabilitiesAsSelectOptions () {
      return Liability
        .query()
        .get()
        .filter(({ id }) => !this.currentLiabilityIds.includes(id))
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    visibleValuationElements () {
      return {
        valuationDate: true,
        value: true,
        ownershipPercentage: true,
        currency: true
      }
    },

    addressHelpId () {
      return {
        businessInterest: 21,
        share: 22,
        personalPossession: 23,
        vehicle: 24,
        property: 0
      }[this.type]
    },

    notesHelpId () {
      if (this.type === 'vehicle') return 33
      if (this.type === 'property') return 35
      return 0
    },

    valueHelpId () {
      return this.type === 'personalPossession' ? 26 : 25
    },

    showSubTypeInput () {
      return !['share', 'vehicle'].includes(this.type)
    },

    showCoOwnersFieldset () {
      return this.type !== 'share'
    },

    showLiabilitiesFieldset () {
      return this.type !== 'share'
    },

    vehicleMOTEmailDateObject () {
      return new Date(subMonths(new Date(this.vehicleMOTDate || 0), 1))
    },

    vehicleMOTEmailTimestamp () {
      return this.vehicleMOTEmailDateObject.getTime()
    }
  },

  watch: {
    quantity () {
      if (this.type === 'share') {
        this.value = ((this.sharePrice / 100) * this.quantity * 100).toFixed(2)
        this.valuationDate = format(new Date(), 'yyyy-MM-dd')
      }
    }
  },

  created () {
    const helpLookup = {
      NewBusinessInterest: 14,
      BusinessInterest: 14,
      NewShare: 15,
      Share: 15,
      NewPersonalPossession: 16,
      PersonalPossession: 16,
      NewVehicle: 17,
      Vehicle: 17,
      NewProperty: 18,
      Property: 18
    }

    this.$store.dispatch('findAndSetHelpObject', {
      id: helpLookup[this.$router.history.current.name],
      isInitial: true
    })

    if (this.id) {
      const asset = Asset
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.assetId = asset.id
      this.type = asset.type
      this.subType = asset.subType
      this.name = asset.name
      this.latitude = asset.latitude
      this.longitude = asset.longitude
      this.address = asset.address
      this.isMainResidence = asset.isMainResidence
      this.futureOwnershipType = asset.futureOwnershipType
      this.outstandingLoansPaidBy = asset.outstandingLoansPaidBy
      this.notes = asset.notes
      this.description = asset.description
      this.vehicleRegistration = asset.vehicleRegistration
      this.vehicleV5Number = asset.vehicleV5Number
      this.vehicleMOTDate = asset.vehicleMOTDate
      this.vehicleMOTTodoId = asset.vehicleMOTTodoId
      this.vehicleMOTEmailTodo = asset.vehicleMOTEmailTodo
      this.landRegistryOwnershipType = asset.landRegistryOwnershipType
      this.landRegistryNumber = asset.landRegistryNumber
      this.createdDate = asset.createdDate

      this.symbol = asset.symbol
      this.exchange = asset.exchange
      this.sharePrice = asset.sharePrice
      this.currency = asset.currency
      this.quantity = asset.quantity

      this.valuationDate = asset.valuationDate
      this.value = asset.value
      this.ownershipPercentage = asset.ownershipPercentage

      this.currentLiabilityIds = LiabilityAsset
        .query()
        .where('assetId', value => value === this.id)
        .get()
        .map(({ liabilityId }) => liabilityId)

      this.currentCoOwnerIds = AssetCoOwnerContact
        .query()
        .where('assetId', value => value === this.id)
        .get()
        .map(({ contactId }) => contactId)

      this.setAssociatedFilesFromStore()
    } else {
      this.assetId = new Asset().id
      this.type = camelCase(this.$route.path.split('/')[2])
      this.isExistingAsset = false
    }
  },

  methods: {
    setAssociatedFilesFromStore () {
      const asset = Asset
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.associatedFiles = asset
        ? asset
          .files
          .map(({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }) => ({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }))
        : []
    },

    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    resetDefaultHelp () {
      const helpLookup = {
        businessInterest: 14,
        share: 15,
        personalPossession: 16,
        vehicle: 17,
        property: 18
      }

      this.$store.dispatch('findAndSetHelpObject', {
        id: helpLookup[this.type]
      })
    },

    getTypeAsText (typeToGet) {
      return assetTypes
        .find(({ type }) => type === typeToGet)
        .text
    },

    updateAddress (addressObject) {
      this.setIsDirty(true)
      this.address = addressObject
    },

    setShareData ({ name, symbol, exchange, price, currency }) {
      this.name = name
      this.symbol = symbol
      this.exchange = exchange
      this.sharePrice = currency === 'GBp' ? price / 100 : price
      this.currency = currency.toUpperCase()

      this.value = currency === 'GBp'
        ? Math.round((price / 100) * this.quantity * 100) / 100
        : ((price / 100) * this.quantity * 100).toFixed(2)

      this.valuationDate = format(new Date(), 'yyyy-MM-dd')
    },

    updatePropertyValue ({ property, value }) {
      this[property] = value
    },

    toCapitalCase: string => capitalCase(string),

    addCoOwner (contactId) {
      this.currentCoOwnerIds.push(contactId)
      this.setIsDirty(true)
    },

    removeCoOwner (coOwnerId) {
      this.currentCoOwnerIds = this.currentCoOwnerIds
        .filter(id => id !== coOwnerId)

      this.setIsDirty(true)
    },

    addLiability (liabilityId) {
      this.currentLiabilityIds.push(liabilityId)
      this.setIsDirty(true)
    },

    unlinkLiability (liabilityId) {
      this.currentLiabilityIds = this.currentLiabilityIds
        .filter(id => id !== liabilityId)

      this.setIsDirty(true)
    },

    async deleteAsset () {
      this.showDeleteDialog = false

      const relatedBeneficiaryIds = AssetBeneficiary
        .query()
        .where('assetId', this.assetId)
        .get()
        .map(({ beneficiaryId }) => beneficiaryId)

      LiabilityAsset.delete(({ assetId }) => assetId === this.assetId)
      Beneficiary.delete(({ id }) => relatedBeneficiaryIds.includes(id))
      AssetBeneficiary.delete(({ assetId }) => assetId === this.assetId)
      AssetCoOwnerContact.delete(({ assetId }) => assetId === this.assetId)
      Asset.delete(this.assetId)
      File.delete(({ ownerId }) => ownerId === this.assetId)

      if (this.type === 'vehicle') await this.deleteVehicleMOTTodo()

      this.$emit('deleted', { name: `New${pascalCase(this.type)}` })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: [
          'liabilityAssets',
          'assetBeneficiaries',
          'assetCoOwnerContacts',
          'assets',
          'files'
        ],
        message: 'Asset successfully deleted'
      })
    },

    addFile ({ file, description, reference, referenceDate }) {
      this.showFileUploadDialog = false

      file.id = new File().id

      this.associatedFiles.push({
        id: file.id,
        fileName: file.name,
        reference,
        uploadDate: new Date().toISOString(),
        owner: {
          name: this.name,
          type: pascalCase(this.type),
          id: this.assetId
        }
      })

      this.$store.commit('files/addFileObjToFilesToUpload', {
        file,
        reference,
        referenceDate,
        description,
        ownerId: this.assetId,
        ownerType: 'assets'
      })

      this.setIsDirty(true)
    },

    removeFile (fileId) {
      this.$store.commit('files/addFileIdToFilesToDelete', fileId)
      this.associatedFiles = this.associatedFiles.filter(({ id }) => id !== fileId)
      this.setIsDirty(true)
    },

    handleAssetLogic () {
      Asset.insertOrUpdate({
        data: {
          id: this.assetId,
          willId: this.will.id,
          type: this.type,
          subType: this.subType,
          name: this.name,
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.address,
          isMainResidence: this.isMainResidence,
          futureOwnershipType: this.futureOwnershipType,
          outstandingLoansPaidBy: this.outstandingLoansPaidBy,
          notes: this.notes,
          description: this.description,
          vehicleRegistration: this.vehicleRegistration,
          vehicleV5Number: this.vehicleV5Number,
          vehicleMOTDate: this.vehicleMOTDate,
          vehicleMOTTodoId: this.vehicleMOTTodoId,
          vehicleMOTEmailTodo: this.vehicleMOTEmailTodo,
          landRegistryOwnershipType: this.landRegistryOwnershipType,
          landRegistryNumber: this.landRegistryNumber,
          createdDate: this.createdDate || new Date().toISOString(),

          symbol: this.symbol,
          exchange: this.exchange,
          quantity: this.quantity,
          sharePrice: this.sharePrice,

          valuationDate: this.valuationDate,
          currency: this.currency,
          value: this.value,
          ownershipPercentage: this.ownershipPercentage
        }
      })
    },

    handleAssociatedLiabilitesLogic () {
      LiabilityAsset.delete(({ assetId }) => assetId === this.assetId)

      LiabilityAsset.insert({
        data: this.currentLiabilityIds.map(liabilityId => ({
          liabilityId,
          assetId: this.assetId
        }))
      })
    },

    handleCoOwnerLogic () {
      AssetCoOwnerContact.delete(({ assetId }) => assetId === this.assetId)

      AssetCoOwnerContact.insert({
        data: this.currentCoOwnerIds.map(coOwnerId => ({
          assetId: this.assetId,
          contactId: coOwnerId
        }))
      })
    },

    async deleteVehicleMOTTodo () {
      const todoIdToDelete = this.vehicleMOTTodoId
      this.vehicleMOTTodoId = null

      Todo.delete(todoIdToDelete)
      await this.$store.dispatch('todos/persistTodosToVault')
      if (todoIdToDelete) await attemptApiCall(deleteScheduledTodoEmail, todoIdToDelete)
    },

    async handleVehicleMOTTodoLogic () {
      if (!this.vehicleMOTEmailTodo) return this.deleteVehicleMOTTodo()

      const formattedVehicleMotDueDate = format(new Date(this.vehicleMOTDate), 'dd/MM/yyyy')

      const updatedTodo = (await Todo.insertOrUpdate({
        data: {
          ...this.vehicleMOTTodoId && { id: this.vehicleMOTTodoId },
          title: `Your Vehicle MOT Due Date ${formattedVehicleMotDueDate}`,
          body: `Your Vehicle (${this.name}) is due it's MOT on ${formattedVehicleMotDueDate}${this.vehicleMOTEmailTodo ? ', we will email you a reminder one month before the due date' : ''}`,
          deadlineDate: format(this.vehicleMOTEmailDateObject, 'yyyy-MM-dd'),
          type: 'user',
          isExternal: false,
          linkType: 'app',
          path: `/assets/vehicle/${this.assetId}`,
          emailTodo: this.vehicleMOTEmailTodo
        }
      }))?.todos?.[0]
      this.vehicleMOTTodoId = updatedTodo?.id

      await this.$store.dispatch('todos/persistTodosToVault', { updatedTodo })
    },

    async submit () {
      this.vehicleMOTEmailTodo
        ? await this.handleVehicleMOTTodoLogic()
        : await this.deleteVehicleMOTTodo()

      this.handleAssetLogic()
      this.handleAssociatedLiabilitesLogic()
      this.handleCoOwnerLogic()

      await this.$store.dispatch('files/handleAssociatedFilesLogic')
      this.setAssociatedFilesFromStore()

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: [
          'assets',
          'liabilityAssets',
          'assetCoOwnerContacts',
          'files'
        ],
        message: 'Asset successfully saved'
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: pascalCase(this.type),
        params: { id: this.assetId }
      })
    }
  }
}
</script>
