<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn animate__faster"
  >
    <div class="container">
      <corp-bar class="mv-icon" />

      <p>Welcome to your new MeaVitae account. We are going to help you get started on your MeaVitae journey by walking you through the initial setup process.</p>

      <p>The first step will be to register your new master MeaVitae security key. To get started, simply click on the 'BEGIN' button...</p>

      <e-button
        @click="nextOnboardingStage"
      >
        Begin
      </e-button>
    </div>
  </transition>
</template>

<script>
import CorpBar from '@/components/CorpBar'

export default {
  name: 'UserSetupWelcome',

  components: {
    CorpBar
  },

  inheritAttrs: false,

  emits: [
    'next-stage'
  ],

  methods: {
    nextOnboardingStage () {
      this.$emit('next-stage', true)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  align-items: center;

  button {
    text-transform: uppercase;
    width: 100%;
  }

  .mv-icon {
    display: block;
    height: 15rem;
    margin: 0 auto 2em;
    width: 50%;
  }

  p {
    clear: both;
    display: block;
  }
}
</style>
