<template>
  <div class="beneficiaries-manager">
    <div class="header">
      <h1>{{ title }}</h1>

      <e-button
        v-if="showCloseButton"
        class="close-button icon-x-circle ghost"
        @click="$emit('closed')"
      />
    </div>

    <h3 :class="[remainingShares === 0 ? 'ok-text' : 'warning-text']">
      Distributed Shares:
      <span data-test="distributed-shares-percentage">
        {{ 100 - remainingShares }}%
      </span>
    </h3>

    <div
      class="description-text"
      data-test="description-text"
    >
      <slot />
    </div>

    <form>
      <e-select
        v-if="unassignedGroupsAndContactsAsSelectOptions.length"
        :items="unassignedGroupsAndContactsAsSelectOptions"
        default-option-text="Add Beneficiary"
        class="beneficiary-select"
        data-test="beneficiary-select"
        @change="addBeneficiary"
      />

      <div class="beneficiaries">
        <transition-group
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <div
            v-for="beneficiary in beneficiaries"
            :key="beneficiary.id"
            class="beneficiary-element spaced"
            data-test="assigned-beneficiary-element"
          >
            <div>
              <span
                :class="{ 'warning-text': beneficiary.percentageShare === 0 }"
                class="assigned-beneficiary-text"
                data-test="assigned-beneficiary-text"
              >
                {{ beneficiary.selectText }}
              </span>

              <e-button
                class="ghost"
                data-test="delete-beneficiary-button"
                @click="removeBeneficiary(beneficiary.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </div>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Value"
              :rules="{ min_value: 0, max_value: remainingShares + beneficiary.percentageShare }"
              class="percentage-input-item"
            >
              <e-input
                :key="beneficiary.dynamicIndex"
                :value="beneficiary.percentageShare"
                type="number"
                :error-messages="errors"
                :success="valid"
                required
                :max="remainingShares + beneficiary.percentageShare"
                min="0"
                step="1"
                data-test="assigned-beneficiary-share-value-input"
                @input="updateSharePercentage(beneficiary.id, $event)"
              />

              <span
                class="percentage-sign"
                :class="{ 'warning-text': beneficiary.percentageShare === 0 }"
              >
                %
              </span>
            </validation-provider>

            <transition enter-active-class="animate__animated animate__fadeIn">
              <div
                v-if="beneficiary.hasSubstitute"
                class="substitute-beneficiary-element"
              >
                <span class="icon-corner-down-right" />

                <span data-test="assigned-substitute-beneficiary-text">
                  {{ beneficiary.substitute.selectText }} (Substitute)
                </span>

                <e-button
                  class="ghost"
                  data-test="delete-substitute-beneficiary-button"
                  @click="removeSubstituteBeneficiary(beneficiary.id)"
                >
                  <span class="icon-trash-2" />
                </e-button>
              </div>
            </transition>

            <transition enter-active-class="animate__animated animate__fadeIn">
              <div
                v-if="!beneficiary.hasSubstitute"
                class="substitute-beneficiary-element"
              >
                <e-select
                  :key="`substitute-beneficiary-select-${beneficiary.groupOrContactId}`"
                  :items="getAvailableSubstituteBeneficiaries(beneficiary.groupOrContactId)"
                  default-option-text="Add Substitute Beneficiary"
                  data-test="substitute-beneficiary-select"
                  @change="addSubstituteBeneficiary(beneficiary.id, $event)"
                />
              </div>
            </transition>

            <template v-if="beneficiary.type === 'contact' && beneficiary.contact.type === 'person'">
              <p
                class="beneficiary-dead-instruction-text"
                data-test="beneficiary-dead-instruction-text"
              >
                If the gift to the beneficiary and the substitute beneficiary
                fails, should the share go to the beneficiary’s children or
                their respective descendants?
              </p>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Go to their children?"
                :rules="{ required: true }"
                class="checkbox-input-item"
                tag="fieldset"
              >
                <e-input
                  :value="beneficiary.beneficiaryDeadShareGoesToTheirChildren"
                  :radio-value="true"
                  type="radio"
                  label="Yes"
                  data-test="beneficiary-dead-share-goes-to-their-children-yes-radio"
                  @change="updateBeneficiaryDeadInstruction($event, beneficiary.id)"
                />

                <e-input
                  :value="beneficiary.beneficiaryDeadShareGoesToTheirChildren"
                  :radio-value="false"
                  type="radio"
                  label="No"
                  data-test="beneficiary-dead-share-goes-to-their-children-no-radio"
                  @change="updateBeneficiaryDeadInstruction($event, beneficiary.id)"
                />

                <span v-if="!valid">{{ errors }}</span>
              </validation-provider>
            </template>
          </div>
        </transition-group>
      </div>
    </form>
  </div>
</template>

<script>
import Beneficiary from '@/models/Beneficiary'
import Contact from '@/models/Contact'
import Group from '@/models/Group'

export default {
  name: 'BeneficiariesManager',

  props: {
    title: {
      type: String,
      default: ''
    },

    beneficiaryIds: {
      type: Array,
      default: () => []
    },

    showCloseButton: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'closed',
    'add-beneficiary',
    'remove-beneficiary'
  ],

  computed: {
    beneficiaries () {
      return Beneficiary
        .query()
        .with('contact')
        .with('group')
        .with('contactSubstitute')
        .with('groupSubstitute')
        .whereIdIn(this.beneficiaryIds)
        .get()
    },

    groupsAndContacts () {
      return [...Contact.all(), ...Group.all()]
        .filter(({ name }) => name !== 'witnesses')
        .filter(({ isValid, type }) => isValid || type === 'group')
    },

    unassignedGroupsAndContactsAsSelectOptions () {
      return this.groupsAndContacts
        .filter(({ id }) => !this.beneficiaries
          .map(({ groupOrContactId }) => groupOrContactId)
          .includes(id)
        )
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    remainingShares () {
      return 100 - this.beneficiaries
        .reduce((accumulator, { percentageShare }) => accumulator + percentageShare, 0)
    }
  },

  beforeDestroy () {
    this.$emit('closed')
  },

  methods: {
    getAvailableSubstituteBeneficiaries (groupOrContactId) {
      return this.groupsAndContacts
        .filter(({ id }) => id !== groupOrContactId)
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    async updateApi (message) {
      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['beneficiaries'],
        message
      })
    },

    async addBeneficiary (groupOrContactId) {
      const { type } = this.groupsAndContacts.find(({ id }) => id === groupOrContactId)

      this.$emit('add-beneficiary', {
        [type === 'group' ? 'groupId' : 'contactId']: groupOrContactId,
        percentageShare: this.beneficiaries.length ? 0 : 100
      })
    },

    removeBeneficiary (id) {
      this.$emit('remove-beneficiary', id)
    },

    async updateSharePercentage (beneficiaryId, newPercentage) {
      const updatedNumberOfAssignedShares = this.beneficiaries
        .filter(({ id }) => id !== beneficiaryId)
        .reduce((accumulator, currentBeneficiary) => (
          accumulator + currentBeneficiary.percentageShare
        ), Number(newPercentage))

      if (updatedNumberOfAssignedShares <= 100 && newPercentage >= 0) {
        Beneficiary.update({
          where: beneficiaryId,
          data: {
            percentageShare: Number(newPercentage)
          }
        })

        await this.updateApi('Beneficiary successfully updated')
      } else {
        this.beneficiaries
          .find(({ id }) => id === beneficiaryId)
          .dynamicIndex = Math.random()

        this.$forceUpdate()
      }
    },

    async updateBeneficiaryDeadInstruction (value, beneficiaryId) {
      Beneficiary.update({
        where: beneficiaryId,
        data: {
          beneficiaryDeadShareGoesToTheirChildren: value
        }
      })

      await this.updateApi('Beneficiary successfully updated')
    },

    async addSubstituteBeneficiary (beneficiaryId, substituteId) {
      const { type } = this.groupsAndContacts.find(({ id }) => id === substituteId)

      Beneficiary.update({
        where: beneficiaryId,
        data: {
          contactSubstituteId: type === 'group' ? '' : substituteId,
          groupSubstituteId: type === 'group' ? substituteId : ''
        }
      })

      await this.updateApi('Substitute Beneficiary successfully updated')
    },

    async removeSubstituteBeneficiary (id) {
      Beneficiary.update({
        where: id,
        data: {
          contactSubstituteId: null,
          groupSubstituteId: null
        }
      })

      await this.updateApi('Substitute Beneficiary successfully updated')
    }
  }
}
</script>

<style scoped lang="scss">
.beneficiaries-manager {
  max-height: 100vh;

  .header {
    /* margin-bottom: 1rem; */
  }

  .ok-text,
  .warning-text {
    font-weight: 520;
  }

  .ok-text {
    color: $color-success;
  }

  .warning-text {
    color: $color-warning;
  }

  .close-button {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  .description-text {
    font-size: 1rem;
    margin-top: 0.6rem;
  }

  form {
    display: flex;
    flex-direction: column;
    max-height: 60vh;

    .beneficiary-select {
      padding-bottom: 0;
    }

    .beneficiaries {
      overflow-y: scroll;
      padding: 0;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      .beneficiary-element {
        border: 1px solid $border-color-one;
        border-radius: 4px;
        column-gap: 1%;
        display: grid;
        margin: 1rem 0;
        padding: 1rem;
        row-gap: 5%;

        .assigned-beneficiary-text {
          font-weight: 400;
        }

        .percentage-input-item {
          display: flex;
          justify-self: right;

          .percentage-sign {
            padding: 0.4rem 0 0 0.4rem;
          }
        }

        .checkbox-input-item {
          border: 0;
          display: block;
          justify-self: right;

          & > *:first-child {
            margin-bottom: 0.4rem;
          }
        }

        .substitute-beneficiary-element {
          grid-column: 1 / 3;
        }

        .beneficiary-dead-instruction-text {
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
