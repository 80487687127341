<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Insights
        </h1>

        <accordion
          v-if="items.length > 0"
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @check-article-out-of-sync-with-accordion="checkArticleOutOfSyncWithAccordion"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Insights',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      active: ''
    }
  },

  computed: {
    items () {
      return [
        {
          id: 'categories',
          text: 'Categories',
          isActive: this.active === 'categories',
          sub: this.$store.state.insights.categories.map(category => ({
            id: category.id,
            text: category.title,
            routeName: category.id,
            paramKey2: 'subCategoryId'
          }))
        }

        // {
        //   id: 'bookmarks',
        //   text: 'Bookmarks',
        //   isActive: this.active === 'bookmarks',
        //   sub: []
        // }
      ]
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 117,
      isInitial: true
    })
  },

  methods: {
    checkArticleOutOfSyncWithAccordion (categoryId) {
      this.active = categoryId
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 0.6em;
}
</style>
