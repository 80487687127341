'use strict'

import Assets from '@/views/Assets/Assets'
import AssetsDefault from '@/views/Assets/AssetsDefault'
import FormView from '@/views/FormView'
import LoadingSpinner from '@/components/LoadingSpinner'

const subscriptionFeatureId = 4040

export default {
  path: '/assets',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Assets
  },
  meta: {
    title: 'Assets',
    subscriptionFeatureId
  },
  children: [
    {
      path: '',
      name: 'Assets',
      components: {
        appClosedLayout: LoadingSpinner,
        default: AssetsDefault
      },
      meta: {
        title: 'Assets',
        subscriptionFeatureId
      }
    },

    {
      path: 'business-interest/new',
      name: 'NewBusinessInterest',
      props: {
        default: () => ({
          form: 'AssetForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Business Interest',
        subscriptionFeatureId
      }
    },

    {
      path: 'business-interest/:id?',
      name: 'BusinessInterest',
      props: {
        default: route => ({
          form: 'AssetForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Business Interest',
        subscriptionFeatureId
      }
    },

    {
      path: 'share/new',
      name: 'NewShare',
      props: {
        default: () => ({
          form: 'AssetForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Share',
        subscriptionFeatureId
      }
    },

    {
      path: 'share/:id?',
      name: 'Share',
      props: {
        default: route => ({
          form: 'AssetForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Share',
        subscriptionFeatureId
      }
    },

    {
      path: 'personal-possession/new',
      name: 'NewPersonalPossession',
      props: {
        default: () => ({
          form: 'AssetForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Personal Possession',
        subscriptionFeatureId
      }
    },

    {
      path: 'personal-possession/:id?',
      name: 'PersonalPossession',
      props: {
        default: route => ({
          form: 'AssetForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Personal Possession',
        subscriptionFeatureId
      }
    },

    {
      path: 'vehicle/new',
      name: 'NewVehicle',
      props: {
        default: () => ({
          form: 'AssetForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Vehicle',
        subscriptionFeatureId
      }
    },

    {
      path: 'vehicle/:id?',
      name: 'Vehicle',
      props: {
        default: route => ({
          form: 'AssetForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Vehicle',
        subscriptionFeatureId
      }
    },

    {
      path: 'property/new',
      name: 'NewProperty',
      props: {
        default: () => ({
          form: 'AssetForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Property',
        subscriptionFeatureId
      }
    },

    {
      path: 'property/:id?',
      name: 'Property',
      props: {
        default: route => ({
          form: 'AssetForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Property',
        subscriptionFeatureId
      }
    }
  ]
}
