var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('e-button',{attrs:{"type":"button","data-test":"refer-a-friend-button"},on:{"click":function($event){_vm.showReferAFriendDialog = true}}},[_vm._v(" Invite ")]),(_vm.showReferAFriendDialog)?_c('e-dialog',{attrs:{"data-test":"refer-a-friend-dialog"}},[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Refer A Friend To Meavitae")]),_c('e-button',{staticClass:"icon-x-circle ghost",attrs:{"data-test":"close-refer-a-friend-dialog-button"},on:{"click":function($event){_vm.showReferAFriendDialog = false}}})],1),_c('p',[_vm._v(" Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. Insert text about the benefits of referring a friend to MeaVitae. ")]),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"connect-input"},[_c('div',{staticClass:"recipients-element"},[_c('h2',[_vm._v("Recipients")]),_c('div',{staticClass:"box"},[(!_vm.showAddRecipientList && _vm.eligibleRecipientsAsSelectOptions.length)?_c('e-button',{staticClass:"add-recipient person-button ghost",attrs:{"data-test":"add-recipient-button"},on:{"click":function($event){_vm.showAddRecipientList = true}}},[_c('span',{staticClass:"icon-plus"})]):_vm._e(),_c('ul',{attrs:{"data-test":"current-recipients"}},_vm._l((_vm.recipients),function(recipient){return _c('li',{key:recipient.id},[_c('span',[_c('e-button',{staticClass:"delete-icon person-button ghost",attrs:{"data-test":"remove-recipient-button"},on:{"click":function($event){return _vm.removeRecipient(recipient.id)}}},[_c('span',{attrs:{"data-test":"recipient-name"}},[_vm._v(" "+_vm._s(recipient.fullName)+" ")]),_c('span',{staticClass:"icon-trash-2"})])],1)])}),0)],1),_c('validation-provider',{attrs:{"name":"Eligible Recipient","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.recipients.length),expression:"recipients.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.recipients.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.recipients, "length", $event.target.value)}}}),(_vm.showAddRecipientList)?_c('e-select',{staticClass:"add-contact-select",attrs:{"items":_vm.eligibleRecipientsAsSelectOptions,"default-option-text":"Select a contact to add","error-messages":errors,"success":valid,"is-required":true,"data-test":"contact-select"},on:{"change":_vm.addRecipient}}):_vm._e()]}}],null,true)})],1)]),(_vm.showFormTextFields)?[_c('e-input',{attrs:{"value":_vm.subject,"disabled":true,"label":"Subject","data-test":"subject-input"}}),_c('validation-provider',{attrs:{"name":"Message","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Message To Recipients","rows":"12","error-messages":errors,"success":valid,"is-required":true},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})]:_vm._e(),_c('e-button',{staticClass:"submit-button",attrs:{"disabled":!formIsValid,"type":"submit"}},[_vm._v(" Send Referrals ")])],2)]}}],null,false,4007220798)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }