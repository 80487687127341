<template>
  <router-view
    @deleted="handleDeleted"
    @submitted="handleSubmitted"
  />
</template>

<script>
export default {
  name: 'Credential',

  emits: [
    'deleted',
    'submitted'
  ],

  methods: {
    handleDeleted (routeObject) {
      this.$emit('deleted', routeObject)
    },

    handleSubmitted (routeObject) {
      this.$emit('submitted', routeObject)
    }
  }
}
</script>
