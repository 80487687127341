var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showDeleteDialog)?_c('confirmation-dialog',{attrs:{"title":"Delete Monetary Debt","data-test":"delete-monetary-debt-dialog"},on:{"cancel":function($event){_vm.showDeleteDialog = false},"confirm":_vm.deleteMonetaryDebt}}):_vm._e(),_c('div',[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Monetary Debt")]),_c('e-button',{staticClass:"close-button icon-x-circle ghost",attrs:{"data-test":"close-dialog-button"},on:{"click":function($event){return _vm.$emit('closed')}}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"spaced",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Debtor","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.eligibleContactsAndGroupsAsSelectOptions,"label":"Debtor","default-option-text":"Select Debtor","error-messages":errors,"success":valid,"data-test":"debtor-select"},model:{value:(_vm.selectedContactOrGroupId),callback:function ($$v) {_vm.selectedContactOrGroupId=$$v},expression:"selectedContactOrGroupId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Debt Value","rules":"required|min_value:1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Monetary Debt Value","type":"number","error-messages":errors,"success":valid,"data-test":"debt-value-input","prefix":"£"},on:{"focus":function($event){_vm.amount = _vm.amount || null}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})]}}],null,true)}),_c('fieldset',{attrs:{"data-test":"released-from-debt-element"}},[_c('legend',[_vm._v("Forgivable?")]),_c('e-input',{attrs:{"value":_vm.isForgivable,"radio-value":true,"type":"radio","label":"Yes","data-test":"is-forgivable-radio-yes"},on:{"change":function($event){_vm.isForgivable = $event}}}),_c('e-input',{attrs:{"value":_vm.isForgivable,"radio-value":false,"type":"radio","label":"No","data-test":"is-forgivable-radio-no"},on:{"change":function($event){_vm.isForgivable = $event}}})],1),_c('confirmation-buttons',{attrs:{"confirm-button-text":"Save","confirm-button-type":"submit","cancel-button-text":"Delete"},on:{"cancel":function($event){_vm.showDeleteDialog = true}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }