<template>
  <message-form
    message-type="new"
    :predefined-to="predefinedTo"
    @route-change="routeName => $emit('route-change', routeName)"
  />
</template>

<script>
import MessageForm from '@/components/forms/MessageForm'

export default {
  name: 'NewMessage',

  components: {
    MessageForm
  },

  props: {
    predefinedTo: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
