<template>
  <e-dialog data-test="confirmation-dialog">
    <h1>{{ title }}</h1>

    <slot>
      <p>
        Are you sure you wish to permanently delete this item?
        You won't be able to recover items.
      </p>
    </slot>

    <confirmation-buttons
      @confirm="$emit('confirm')"
      @cancel="$emit('cancel')"
    />
  </e-dialog>
</template>

<script>
import ConfirmationButtons from '@/components/buttons/ConfirmationButtons'
import EDialog from '@/components/dialogs/EDialog'

export default {
  name: 'ConfirmationDialog',

  components: {
    ConfirmationButtons,
    EDialog
  },

  props: {
    title: {
      type: String,
      default: ''
    }
  },

  emits: [
    'confirm',
    'cancel'
  ]
}
</script>
