var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDeleteDialog)?_c('confirmation-dialog',{attrs:{"title":"Delete this letter"},on:{"confirm":_vm.deleteLetter,"cancel":function($event){_vm.showDeleteDialog = false}}}):_vm._e(),(_vm.showPurchaseDialog)?_c('e-dialog',[(_vm.showPurchaseDialog)?_c('ConfirmPurchase',{attrs:{"item":_vm.product},on:{"show-ai-working-dialogue":_vm.aiWorking,"closed":function($event){_vm.showPurchaseDialog = false}}}):_vm._e()],1):_vm._e(),(_vm.showAiWorkingDialog)?_c('e-dialog',[_c('h1',[_vm._v("AI letter is being generated. Please wait.")]),_c('div',{staticClass:"ai-working-dialog__content__text"},[_c('p',[_vm._v(" Our AI is working to generate a Letter, based on your instructions.")]),_c('p',[_vm._v(" This may take a minute or two. Please be patient and when it is ready, the agent will put the letter into the form below.")]),_c('br'),_c('p',[_vm._v(" Please note that our AI Agent is still learning, so the letter may not be perfect.")]),_c('p',[_vm._v(" If you are not happy with the letter, you can edit it before sending it.")])]),_c('div',{staticClass:"ai-working-dialog__content__spinner"},[_c('div',{staticClass:"spinner"})])]):_vm._e(),(_vm.showSendLetterDiaglog)?_c('e-dialog',{attrs:{"title":"Send Letter"}},[_c('h1',[_vm._v("Send the Letter "+_vm._s(_vm.title))]),_vm._t("default",function(){return [_c('p',[_vm._v(" Sending a letter to an external email address ")])]}),_c('div',{staticClass:"confirmation-buttons"},[_c('e-button',{attrs:{"type":"primary","button-type":"primary","button-style":"two"},on:{"click":_vm.sendItNow}},[_vm._v(" Send ")]),_c('e-button',{attrs:{"type":"primary","button-type":"secondary","button-style":"two"},on:{"click":_vm.cancelSend}},[_vm._v(" Cancel ")]),_c('e-input',{attrs:{"value":_vm.scheduleLetter,"type":"checkbox","label":"Send the letter at a later date","data-test":"schedule-letter-checkbox"},on:{"change":function (value) {
          _vm.scheduleLetter = !!value
          _vm.setIsDirty(true)
        }}})],1),(_vm.scheduleLetter)?_c('fieldset',[_c('legend',[_vm._v(" Schedule letter "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(138)}}})]),_c('validation-provider',{attrs:{"name":"Send Date","rules":{ required: true, is_1_min_in_future_datetime: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Send Date","type":"datetime-local","error-messages":errors,"success":valid,"is-required":true,"help-id":137,"data-test":"send-date-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.sendDate),callback:function ($$v) {_vm.sendDate=$$v},expression:"sendDate"}})]}}],null,false,149072180)}),_c('e-input',{attrs:{"value":_vm.encryptPdf,"type":"checkbox","label":"Add a password to ensure that only the recipient is able to read the emailed pdf letter","data-test":"encrypt-pdf-checkbox"},on:{"change":function (value) {
          _vm.encryptPdf = !!value
          _vm.setIsDirty(true)
        }}}),(_vm.encryptPdf)?_c('fieldset',[_c('legend',[_vm._v("Secure letter")]),_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"vid":"userPassword","name":"Password","rules":"required|min:8|max:20","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Password","error-messages":errors,"success":valid,"is-required":true,"type":"password","data-test":"password-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=$$v},expression:"userPassword"}})]}}],null,false,885458195)}),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:userPassword","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Confirm Password","error-messages":errors,"success":valid,"is-required":true,"type":"password","data-test":"confirm-password-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.userPasswordConfirm),callback:function ($$v) {_vm.userPasswordConfirm=$$v},expression:"userPasswordConfirm"}})]}}],null,false,2652455244)})],1),_c('validation-provider',{attrs:{"name":"Password Hint","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Password Hint","error-messages":errors,"success":valid,"is-required":true,"data-test":"password-hint-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.userPasswordHint),callback:function ($$v) {_vm.userPasswordHint=$$v},expression:"userPasswordHint"}})]}}],null,false,3366705748)})],1):_vm._e()],1):_vm._e()],2):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.isExistingLetter? 'Edit': 'New')+" Letter "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(_vm.scheduleLetter ? 128 : 129)}}})]),_c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeInLeft animate__faster"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
        var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"letter-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Reference","error-messages":errors,"success":valid,"is-required":true,"help-id":134,"data-test":"title-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)}),(!_vm.showAiPromptInput)?_c('e-button',{attrs:{"button-type":"primary","button-style":"one"},on:{"click":_vm.useAI}},[_vm._v(" Use AI to generate "+_vm._s(_vm.isExistingLetter? 'a new version of this': 'a')+" letter ")]):_vm._e(),(_vm.showAiPromptInput)?_c('div',{staticClass:"ai-panel"},[(_vm.showAiPromptInput)?_c('e-textarea',{attrs:{"id":"AiInput","label":"AI Instructions","error-messages":_vm.errors,"success":_vm.valid,"is-required":false,"placeholder":"Write a sentence to tell the AI what you like the letter to say","help-id":134,"data-test":"prompt-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.prompt),callback:function ($$v) {_vm.prompt=$$v},expression:"prompt"}}):_vm._e(),_c('div',{staticClass:"button-container"},[(_vm.showAiPromptInput)?_c('e-button',{attrs:{"data-test":"generate-button","button-type":"primary","button-style":"two","disabled":_vm.isAIReady()},on:{"click":_vm.orderAiLetter}},[_vm._v(" Generate Letter ("+_vm._s(_vm.product.price)+" MVT) ")]):_vm._e(),_c('e-button',{on:{"click":function($event){_vm.showAiPromptInput = false}}},[_vm._v(" Cancel ")])],1)],1):_vm._e(),_c('validation-provider',{attrs:{"name":"Letter","rules":"required|required_after_html_removed","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('rich-text-editor',{attrs:{"label":"Letter","error-messages":errors,"success":valid,"is-required":true,"help-id":136},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.letterContent),callback:function ($$v) {_vm.letterContent=$$v},expression:"letterContent"}})]}}],null,true)}),_c('e-button',{attrs:{"data-test":"download-button","disabled":!_vm.hasContent()},on:{"click":_vm.generatePdf}},[_vm._v(" Download PDF ")]),_c('e-button',{attrs:{"data-test":"download-button","disabled":!_vm.hasContent()},on:{"click":_vm.generateDocx}},[_vm._v(" Download Word ")]),_c('validation-provider',{attrs:{"name":"Addressee","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('e-input',{staticClass:"email-field",attrs:{"label":"Addressee (Email)","error-messages":errors,"success":valid,"is-required":true,"type":"email","help-id":135,"data-test":"addressee-input","rules":{ email: true }},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.addressee),callback:function ($$v) {_vm.addressee=$$v},expression:"addressee"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('e-button',{attrs:{"value":_vm.showSendLetterDiaglog,"button-type":"primary","button-style":"two","form-is-valid":formIsValid,"disabled":!_vm.hasContent() && !_vm.hasEmailAddressee()},on:{"click":function (value) { return _vm.showSendLetterDiaglog = !!value; }}},[_vm._v(" Send ")]),_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"letter-form","button-type":"secondary","button-style":"two","button-text":"Save & Close"}}),(_vm.isExistingLetter)?_c('e-button',{attrs:{"data-test":"delete-button"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" Delete ")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }