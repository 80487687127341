<template>
  <e-dialog>
    <div class="header">
      <e-button @click="$emit('create')">
        <span class="icon-check-circle" /> Create Document
      </e-button>

      <e-button
        class="icon-x-circle ghost"
        @click="$emit('close')"
      />
    </div>

    <div
      class="document-display document-preview"
      v-html="document"
    />
  </e-dialog>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'

export default {
  name: 'DocumentPreviewDialog',

  components: {
    EDialog
  },

  props: {
    document: {
      type: String,
      default: ''
    }
  },

  emits: ['create', 'close']
}
</script>

<style scoped lang="scss">
.document-preview {
  background-color: $widget-background-color;
  height: 80vh;
  margin: 40px 0;
  overflow-y: auto;
  width: 100%;
}

/* stylelint-disable-next-line */
::v-deep .document-display {
  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
}
</style>
