<template>
  <div class="forgotten-password-block">
    <div class="header">
      <span />

      <e-button
        class="icon-x-circle ghost"
        @click="goToLogin"
      />
    </div>

    <corp-bar class="mv-icon" />

    <div v-if="idpApiSuccess">
      <p>Check your email for our set password message. You may follow the link or paste the code into our form</p>

      <e-button
        class="button gras"
        @click="goToSetPassword"
      >
        Set New Password
      </e-button>
    </div>

    <div v-else>
      <p>Please enter your email address to request a password reset email</p>

      <validation-observer v-slot="{ handleSubmit, invalid }">
        <form
          class="spaced"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Email Address"
            rules="required|email"
            slim
          >
            <e-input
              v-model="email"
              label="Email"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              type="email"
              class="input"
            />
          </validation-provider>

          <e-button
            :disabled="invalid"
            type="submit"
            class="button gras"
          >
            Request Password Reset
          </e-button>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import CorpBar from '@/components/CorpBar'
import attemptApiCall from '../utils/attemptApiCall'
import generateForgottenPasswordCodeRequest from '../apis/idp/generateForgottenPasswordCodeRequest'

export default {
  name: 'ForgottenPasswordBox',

  components: {
    CorpBar
  },

  data () {
    return {
      idpApiSuccess: false,
      email: ''
    }
  },

  methods: {
    goToLogin () {
      this.$router.push({ name: 'Login' })
    },

    goToSetPassword () {
      this.$router.push({ name: 'SetPassword' })
    },

    async submitForm () {
      try {
        await attemptApiCall(
          generateForgottenPasswordCodeRequest,
          { email: this.email }
        )
      } catch (error) {
      } finally {
        this.idpApiSuccess = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.forgotten-password-block {
  display: flex;
  flex-direction: column;
  max-width: 20rem;

  div {
    p {
      display: block;
      margin: 2em 0;
      text-align: center;
    }
  }

  .input {
    color: $font-color-one;
  }

  input:focus {
    background-color: $link-hover-color;
    border-color: rgb(134, 134, 195);
  }

  .button {
    letter-spacing: 0.05em;
    margin-top: 0.5em;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $color-success;
      border-color: $color-success;
      color: $filled-button-text;
    }
  }

  .mv-icon {
    height: 10rem;
  }
}
</style>
