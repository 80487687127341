<template>
  <div>
    <e-button
      data-test="add-passport-button"
      @click="addPassport(passports)"
    >
      <span class="icon-plus" />

      Add Passport
    </e-button>

    <fieldset v-if="passports.length">
      <legend>Passports</legend>

      <table data-test="passports-table">
        <thead>
          <tr>
            <th>Number</th>

            <th>Country</th>

            <th>Expiry Date</th>

            <th>Notes</th>

            <th class="center">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(passport, index) in passports"
            :key="passport.id"
          >
            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${passport.id}-passport-number`"
                name="Passport Number"
                :rules="{ required: true }"
                tag="div"
              >
                <e-input
                  :value="passports[index].number"
                  :error-messages="errors"
                  :success="valid"
                  data-test="passport-number-input"
                  @input="updatePassport(passports, passport.id, 'number', $event)"
                />
              </validation-provider>
            </td>

            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Country"
                :rules="{ required: true }"
                tag="div"
              >
                <e-select
                  :value="passports[index].countryIsoCode"
                  :items="countrySelectOptions"
                  default-option-text="Select Country"
                  :error-messages="errors"
                  :success="valid"
                  data-test="passport-country-code-select"
                  @input="updatePassport(passports, passport.id, 'countryIsoCode', $event)"
                />
              </validation-provider>
            </td>

            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Expiry Date"
                :rules="{ required: true }"
                tag="div"
              >
                <e-input
                  :value="passports[index].expiryDate"
                  type="date"
                  :error-messages="errors"
                  :success="valid"
                  data-test="passport-expiry-date-input"
                  @input="updatePassport(passports, passport.id, 'expiryDate', $event)"
                />
              </validation-provider>
            </td>

            <td>
              <e-input
                :value="passports[index].notes"
                data-test="passport-notes-input"
                @input="updatePassport(passports, passport.id, 'notes', $event)"
              />
            </td>

            <td class="actions">
              <e-button
                class="ghost"
                data-test="remove-passport-button"
                @click="removePassport(passports, passport.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import countries from '@/json_files/countries.json'
import { createId } from '@/utils'

export default {
  name: 'UserPassportsManager',

  props: {
    passports: {
      type: Array,
      default: () => []
    }
  },

  emits: ['is-dirty'],

  computed: {
    countrySelectOptions () {
      return countries.map(({ name, code }) => ({
        value: code,
        text: name
      }))
    }
  },

  methods: {
    addPassport (passports) {
      passports.push({ id: createId() })
      this.$emit('is-dirty')
    },

    updatePassport (passports, passportId, key, value) {
      passports.find(({ id }) => id === passportId)[key] = value
      this.$emit('is-dirty')
    },

    removePassport (passports, passportId) {
      const index = passports.findIndex(({ id }) => id === passportId)
      passports.splice(index, 1)
      this.$emit('is-dirty')
    }
  }
}
</script>

<style scoped lang="scss">
button {
  margin-bottom: 0.8rem;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
