'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ userId, fileId, jwt }) => {
  return axios({
    method: 'delete',
    url: `${VUE_APP_GATEWAY_URL}/file/delete/${userId}/${fileId}`,
    headers: {
      Authorization: jwt
    },
    timeout: 8000
  })
}
