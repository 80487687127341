'use strict'

import axios from 'axios'

const { VUE_APP_API_LOGGER_PROXY } = process.env

export default async ({ messageObject }) => axios({
  method: 'post',
  url: VUE_APP_API_LOGGER_PROXY,
  data: {
    message: 'error',
    metadata: messageObject
  }
})
