<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          My Will
        </h1>

        <accordion :items="items" />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          data-test="router-view"
          @set-active="setActiveCategory"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import Asset from '@/models/Asset'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'
import Will from '@/models/Will'
import { pascalCase } from 'change-case'

export default {
  name: 'Will',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      activeCategory: ''
    }
  },

  computed: {
    will () {
      return Will
        .query()
        .with('partner')
        .with('children')
        .with('guardians')
        .with('monetaryGifts')
        .with('monetaryDebts')
        .first()
    },

    items () {
      return [
        {
          id: 'Introduction',
          text: 'Introduction',
          isActive: this.activeCategory === 'Introduction',
          sub: [
            {
              id: 'CreatingYourWill',
              text: 'Creating Your Will',
              routeName: 'CreatingYourWill',
              sub: []
            }
          ]
        },

        {
          id: 'MyFamilyMain',
          text: 'My Family',
          isActive: this.activeCategory === 'MyFamily',
          sub: [
            {
              id: 'MyFamily',
              text: 'Summary',
              routeName: 'MyFamily',
              sub: []
            },

            {
              id: 'Partner',
              text: this.will.partner ? 'Partner (1)' : 'Partner',
              routeName: 'Partner',
              sub: []
            },

            {
              id: 'Children',
              text: this.will.children.length ? `Children (${this.will.children.length})` : 'Children',
              routeName: 'Children',
              sub: []
            },

            {
              id: 'Guardians',
              text: this.will.guardians.length ? `Guardians (${this.will.guardians.length})` : 'Guardians',
              routeName: 'Guardians',
              sub: []
            }
          ]
        },

        {
          id: 'DistributionMain',
          text: 'Distribution',
          isActive: this.activeCategory === 'Distribution',
          sub: [
            {
              id: 'Distribution',
              text: 'Introduction',
              routeName: 'Distribution',
              sub: []
            },

            {
              id: 'Possessions',
              text: Asset
                .all()
                .filter(({ type }) => type !== 'property')
                .length
                ? `Possessions (${Asset
                    .all()
                    .filter(({ type }) => type !== 'property')
                    .length})`
                : 'Possessions',

              routeName: 'Possessions',
              sub: []
            },

            {
              id: 'Properties',
              text: Asset
                .all()
                .filter(({ type }) => type === 'property')
                .length
                ? `Properties (${Asset
                    .all()
                    .filter(({ type }) => type === 'property')
                    .length})`
                : 'Properties',

              routeName: 'Properties',
              sub: []
            },

            {
              id: 'Money',
              text: this.will.monetaryGifts.length ? `Money (${this.will.monetaryGifts.length})` : 'Money',
              routeName: 'Money',
              sub: []
            }
          ]
        },

        {
          id: 'ResidualMain',
          text: 'Residual',
          isActive: this.activeCategory === 'Residual',
          sub: [
            {
              id: 'Residual',
              text: 'Summary',
              routeName: 'Residual',
              sub: []
            },

            {
              id: 'Provisions',
              text: 'Provisions',
              routeName: 'Provisions',
              sub: []
            },

            {
              id: 'Contingency',
              text: 'Contingency',
              routeName: 'Contingency',
              sub: []
            }
          ]
        },

        {
          id: 'ExecutorsAndWitnesses',
          text: 'Executors & Witnesses',
          isActive: this.activeCategory === 'ExecutorsAndWitnesses',
          sub: [
            {
              id: 'Executors',
              text: 'Executors',
              routeName: 'Executors',
              sub: []
            },

            {
              id: 'Witnesses',
              text: 'Witnesses',
              routeName: 'Witnesses',
              sub: []
            }
          ]
        },

        {
          id: 'WillSummaryMain',
          text: 'Summary',
          isActive: this.activeCategory === 'WillSummary',
          sub: [
            {
              id: 'WillSummary',
              text: 'Will Preview',
              routeName: 'WillSummary',
              sub: []
            }
          ]
        }
      ]
    }
  },

  created () {
    const path = this.$router
      .currentRoute
      .path
      .split('/')
      .map(path => pascalCase(path))

    this.activeCategory = path[3]
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    },

    handleSubmitted (routeObject) {
      this.$router.push(routeObject)
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  cursor: pointer;
}
</style>
