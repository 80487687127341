<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Messaging
        </h1>

        <accordion
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view :key="$route.fullPath" />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Messages',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      active: ''
    }
  },

  computed: {
    numNewMessages () {
      return this.$store.getters['messages/numNewMessages']
    },

    items () {
      return [
        {
          id: 'Messages',
          text: 'Messages',
          isActive: this.active === 'Messages',
          sub: [
            {
              id: 'Inbox',
              text: this.numNewMessages ? `Inbox (${this.numNewMessages})` : 'Inbox',
              routeName: 'Inbox',
              sub: []
            },

            {
              id: 'Drafts',
              text: 'Drafts',
              routeName: 'Drafts',
              sub: []
            },

            {
              id: 'Sent',
              text: 'Sent',
              routeName: 'Sent',
              sub: []
            },

            {
              id: 'Bin',
              text: 'Bin',
              routeName: 'Bin',
              sub: []
            }
          ],

          additionals: [
            {
              id: 'NewMessage',
              text: 'New Message',
              routeName: 'NewMessage'
            }
          ]
        }
      ]
    }
  }
}
</script>
