'use strict'

import axios from 'axios'

export default async query => {
  const { data } = await axios({
    method: 'GET',
    url: 'https://openexchangerates.org/api/currencies.json'
  })

  return data
}
