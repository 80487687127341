import File from '../models/File'
import Vue from 'vue'
import asyncForEach from '../utils/asyncForEach'
import attemptApiCall from '../utils/attemptApiCall'
import createFileActionObject from '../utils/createFileActionObject'
import deleteFileFromAwsS3 from '../apis/file/deleteFileFromAwsS3'
import deleteFileFromCloudflareR2 from '../apis/file/deleteFileFromCloudflareR2'
import encryptFile from '../utils/encryptFile'
import getFileFromAwsS3 from '../apis/file/getFileFromAwsS3'
import getFileFromCloudflareR2 from '../apis/file/getFileFromCloudflareR2'
import putFileToCloudflareR2 from '../apis/file/putFileToCloudflareR2'
import { currentFileUploadLocation } from '../utils'

export default {
  namespaced: true,

  state: {
    fileActionObjects: [],
    filesToUpload: [],
    filesToDelete: []
  },

  actions: {
    uploadFileToApi: async ({ commit }, { file, userId, fileId, progressExecutor, abortController }) => {
      try {
        return await attemptApiCall(
          putFileToCloudflareR2,
          {
            jwt: sessionStorage.getItem('jwt'),
            file,
            userId,
            fileId,
            progressExecutor,
            abortController
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    downloadFileFromApi: async ({ commit }, { fileUrl, progressExecutor, source }) => {
      try {
        const abortController = new AbortController()

        return await attemptApiCall(
          source === 'R2' ? getFileFromCloudflareR2 : getFileFromAwsS3,
          {
            jwt: sessionStorage.getItem('jwt'),
            fileUrl,
            progressExecutor,
            abortController
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    deleteFileFromApi: async ({ commit, rootState }, { fileId, source }) => {
      try {
        return await attemptApiCall(
          source === 'R2' ? deleteFileFromCloudflareR2 : deleteFileFromAwsS3,
          {
            userId: rootState.userId,
            fileId,
            jwt: sessionStorage.getItem('jwt')
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    handleFileCompletedEvent: ({ state, commit }, actionObjId) => {
      const actionType = state
        .fileActionObjects
        .find(({ id }) => actionObjId === id)
        .type

      if (actionType === 'upload') {
        commit('snackbar/update',
          { type: 'success', message: 'File successfully uploaded' },
          { root: true }
        )
      }

      commit('removeFileActionObject', actionObjId)
    },

    uploadFileAndUpdateStore: async ({ commit, dispatch, rootState }, fileObj) => {
      try {
        const { encryptedFile, encryptKey } = await encryptFile(fileObj.file)

        const fileActionObject = createFileActionObject({
          name: fileObj.file.name,
          type: 'upload'
        })

        commit('addFileActionObject', fileActionObject)

        const { downloadUrl, etag } = await dispatch('uploadFileToApi', {
          file: encryptedFile,
          userId: rootState.userId,
          fileId: fileObj.file.id,
          progressExecutor: event => fileActionObject.progressUpdator(event),
          abortController: fileActionObject.abortController
        })

        File.insertOrUpdate({
          data: {
            id: fileObj.file.id,
            reference: fileObj.reference,
            referenceDate: fileObj.referenceDate,
            description: fileObj.description,
            uploadDate: new Date().toISOString(),
            etag,
            source: currentFileUploadLocation,
            fileUrl: downloadUrl,
            fileName: fileObj.file.name,
            fileSize: fileObj.file.size,
            fileMimeType: fileObj.file.type,
            fileLastModifiedDate: fileObj.file.lastModified,
            encryptDecryptKey: encryptKey,
            ownerId: fileObj.ownerId,
            ownerType: fileObj.ownerType
          }
        })

        commit('removeFileObjFromFilesToUpload', fileObj.file.id)
      } catch (error) {
        if (error.message === 'canceled') return

        commit('snackbar/update',
          { type: 'error', message: error.message },
          { root: true }
        )

        dispatch('logError',
          {
            error,
            fileName: 'files',
            functionName: 'uploadFileAndUpdateStore'
          },
          { root: true }
        )
      }
    },

    deleteFileAndUpdateStore: async ({ commit, dispatch }, fileId) => {
      try {
        const file = File.find(fileId)

        await dispatch('deleteFileFromApi', { fileId, source: file.source })
        File.delete(fileId)
        commit('removeFileIdFromFilesToDelete', fileId)
      } catch (error) {
        commit('snackbar/update',
          { type: 'error', message: error.message },
          { root: true }
        )

        dispatch('logError',
          {
            error,
            fileName: 'files',
            functionName: 'deleteFileAndUpdateStore'
          },
          { root: true }
        )
      }
    },

    handleFileUploads: async ({ state, dispatch }) => {
      await asyncForEach(state.filesToUpload, async fileObj => {
        await dispatch('uploadFileAndUpdateStore', fileObj)
      })
    },

    handleFileDeletions: async ({ state, dispatch }) => {
      await asyncForEach(state.filesToDelete, async id => {
        await dispatch('deleteFileAndUpdateStore', id)
      })
    },

    handleAssociatedFilesLogic: async ({ state, dispatch }) => {
      if (state.filesToUpload.length) await dispatch('handleFileUploads')
      if (state.filesToDelete.length) await dispatch('handleFileDeletions')
    }
  },

  mutations: {
    addFileActionObject: (state, fileObj) => {
      state.fileActionObjects.push(fileObj)
    },

    removeFileActionObject: (state, actionObjId) => {
      Vue.set(state, 'fileActionObjects', state.fileActionObjects.filter(({ id }) => id !== actionObjId))
    },

    addFileObjToFilesToUpload: (state, fileObj) => {
      state.filesToUpload.push(fileObj)
    },

    removeFileObjFromFilesToUpload: (state, fileId) => {
      Vue.set(state, 'filesToUpload', state.filesToUpload.filter(({ file }) => file.id !== fileId))
    },

    addFileIdToFilesToDelete: (state, fileId) => {
      state.filesToDelete.push(fileId)
    },

    removeFileIdFromFilesToDelete: (state, fileId) => {
      Vue.set(state, 'filesToDelete', state.filesToDelete.filter(id => id !== fileId))
    }
  }
}
