<template>
  <div>
    <confirmation-dialog
      v-if="deleteCredentialId"
      @confirm="handleDeleteConfirmation"
      @cancel="deleteCredentialId = ''"
    />

    <e-dialog
      v-if="showAddEditSecurityKeyDialog"
      class="dialog-container"
      data-test="security-key-dialog"
    >
      <div class="header">
        <h1>{{ isExistingSecurityKey ? 'Edit' : 'Add' }} Security Key</h1>

        <e-button
          class="icon-x-circle ghost"
          data-test="close-security-key-dialog-button"
          @click="closeAddEditSecurityKeyDialog"
        />
      </div>

      <security-key-authentication
        v-if="addEditSecurityKeyStage === 'authentication'"
        :rp-id="rpId"
        :challenge="securityKeyCredentialChallenge"
        :security-key-objects="securityKeyObjects"
        data-test="security-key-authentication"
        @envelope-key="envelopeKey = $event"
        @error-message="
          closeAddEditSecurityKeyDialog(),
          showSnackbarError($event)
        "
      />

      <security-key-form
        v-else-if="addEditSecurityKeyStage === 'form'"
        :credential-id="credentialId"
        :security-key-id="securityKeyId"
        :security-key-name="securityKeyName"
        :security-key-notes="securityKeyNotes"
        data-test="security-key-form"
        @security-key-name="securityKeyName = $event"
        @security-key-notes="securityKeyNotes = $event"
        @save-security-key="handleSaveSecurityKey($event)"
        @error-message="
          closeAddEditSecurityKeyDialog(),
          showSnackbarError($event)
        "
      />

      <security-key-registration
        v-else-if="addEditSecurityKeyStage === 'registration'"
        :rp-id="rpId"
        :challenge="securityKeyCredentialChallenge"
        :envelope-key="envelopeKey"
        :user-id="userId"
        :user-email-address="userEmailAddress"
        :security-key-objects="securityKeyObjects"
        data-test="security-key-registration"
        @credential-id="credentialId = $event"
        @security-key-id="securityKeyId = $event"
        @save-security-key="handleSaveSecurityKey($event)"
        @error-message="
          closeAddEditSecurityKeyDialog(),
          showSnackbarError($event)
        "
      />
    </e-dialog>

    <div class="top-inputs">
      <h1>My Security Keys</h1>

      <div
        v-if="showAddSecurityKeyButton"
        class="add-button-wrapper"
      >
        <e-button
          data-test="add-security-key-button"
          @click="showAddSecurityKeyDialog"
        >
          ADD
        </e-button>
      </div>
    </div>

    <e-table
      :headers="headers"
      :rows="rows"
    >
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            class="icon-edit ghost"
            data-test="edit-security-key-button"
            @click.stop="showEditSecurityKeyDialog(item.credentialId)"
          />

          <e-button
            class="icon-trash-2 ghost"
            data-test="delete-security-key-button"
            @click.stop="deleteCredentialId = item.credentialId"
          />
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import SecurityKeyAuthentication from '@/components/SecurityKeyAuthentication'
import SecurityKeyForm from '@/components/SecurityKeyForm'
import SecurityKeyRegistration from '@/components/SecurityKeyRegistration'
import jwtDecode from 'jwt-decode'
import { format } from 'date-fns'

export default {
  name: 'SecurityKeys',

  components: {
    ConfirmationDialog,
    EDialog,
    ETable,
    SecurityKeyAuthentication,
    SecurityKeyForm,
    SecurityKeyRegistration
  },

  data () {
    return {
      deleteCredentialId: '',

      showAddEditSecurityKeyDialog: false,

      rpId: '',
      credentialId: '',
      securityKeyId: '',
      securityKeyName: '',
      securityKeyNotes: '',
      securityKeyCreated: null,

      securityKeyCredentialChallenge: null,

      envelopeKey: '',

      userId: '',
      userEmailAddress: '',

      headers: [
        {
          text: 'Key Id',
          value: 'securityKeyId',
          align: 'left'
        },
        {
          text: 'Key Name',
          value: 'name',
          align: 'left'
        },
        {
          text: 'Activation Date',
          value: 'activationDate',
          align: 'left'
        },
        {
          text: 'Notes',
          value: 'notes',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],

      securityKeyObjects: []
    }
  },

  computed: {
    showAddSecurityKeyButton () {
      return (this.$store.state.operatingSystem !== 'iOS')
    },

    rows () {
      return this.securityKeyObjects
        .map(({ created, ...restOfObject }) => ({
          ...restOfObject,
          id: restOfObject.securityKeyId,
          activationDate: format(new Date(created), 'HH:mm dd/MM/yyyy'),
          rowClickedFunction: () => this.showEditSecurityKeyDialog(restOfObject.credentialId)
        }))
    },

    isExistingSecurityKey () {
      return !!this.securityKeyObjects
        .find(({ credentialId }) => credentialId === this.credentialId)
    },

    addEditSecurityKeyStage () {
      if (this.credentialId) return 'form'

      if (this.envelopeKey && this.securityKeyName) return 'registration'
      if (this.envelopeKey) return 'form'
      return 'authentication'
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 83,
      isInitial: true
    })

    const jwt = sessionStorage.getItem('jwt')
    const decodedJwt = jwtDecode(jwt)
    this.rpId = decodedJwt?.rpId
    this.securityKeyCredentialChallenge = decodedJwt?.challenge
    this.securityKeyObjects = decodedJwt?.securityKeyObjects || []

    this.userId = this.$store.state.userId
    this.userEmailAddress = this.$store.getters.userEmailAddress
  },

  methods: {
    showSnackbarError (errorMessage) {
      this.$store.commit('snackbar/update', {
        type: 'error',
        message: errorMessage
      })
    },

    resetAddEditSecurityKeyForm () {
      this.credentialId = ''
      this.securityKeyId = ''
      this.securityKeyName = ''
      this.securityKeyNotes = ''
      this.securityKeyCreated = null
      this.envelopeKey = ''
    },

    closeAddEditSecurityKeyDialog () {
      this.resetAddEditSecurityKeyForm()
      this.showAddEditSecurityKeyDialog = false
    },

    showAddSecurityKeyDialog () {
      this.resetAddEditSecurityKeyForm()
      this.showAddEditSecurityKeyDialog = true
    },

    showEditSecurityKeyDialog (credentialId) {
      try {
        const securityKeyObject = this.securityKeyObjects
          .find(securityKey => credentialId === securityKey.credentialId)
        if (!securityKeyObject) throw new Error('Could not find security key to edit')

        this.credentialId = securityKeyObject.credentialId
        this.securityKeyId = securityKeyObject.securityKeyId
        this.securityKeyName = securityKeyObject.name
        this.securityKeyNotes = securityKeyObject.notes
        this.securityKeyCreated = securityKeyObject.created || Date.now()
        this.showAddEditSecurityKeyDialog = true
      } catch (error) {
        this.showSnackbarError(error.message)

        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeys',
          functionName: 'showEditSecurityKeyDialog'
        })
      }
    },

    async handleDeleteConfirmation () {
      try {
        if (this.securityKeyObjects.length <= 1) {
          throw new Error('Delete blocked: Your account must always have at least one security key')
        }

        const securityKeyObjects = this.securityKeyObjects
          .filter(({ credentialId }) => credentialId !== this.deleteCredentialId)

        const newUserJwt = await this.$store.dispatch('updateSecurityKeyObjectsToIdp', {
          securityKeyObjects
        })

        sessionStorage.setItem('jwt', newUserJwt)

        this.securityKeyObjects = jwtDecode(newUserJwt).securityKeyObjects

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Security key successfully deleted'
        })
      } catch (error) {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || error.message,
          type: error.response?.data?.type,
          link: error.response?.data?.link
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeys',
          functionName: 'handleDeleteConfirmation'
        })
      } finally {
        this.deleteCredentialId = ''
      }
    },

    async handleSaveSecurityKey ({ credentialId, securityKeyId }) {
      try {
        if (!credentialId || !securityKeyId || !this.securityKeyName) {
          throw new Error('Something has gone wrong when trying to register your security key')
        }

        if (!this.isExistingSecurityKey) this.securityKeyCreated = Date.now()

        this.securityKeyObjects = [
          {
            securityKeyId: securityKeyId,
            credentialId: credentialId,
            name: this.securityKeyName,
            notes: this.securityKeyNotes,
            created: this.securityKeyCreated,
            lastModified: Date.now()
          },
          ...this.securityKeyObjects.filter(({ credentialId }) => credentialId !== this.credentialId)
        ]

        this.closeAddEditSecurityKeyDialog()

        const newUserJwt = await this.$store.dispatch('updateSecurityKeyObjectsToIdp', {
          securityKeyObjects: this.securityKeyObjects
        })

        sessionStorage.setItem('jwt', newUserJwt)

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Security key successfully saved'
        })
      } catch (error) {
        this.closeAddEditSecurityKeyDialog()

        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || error.message,
          type: error.response?.data?.type,
          link: error.response?.data?.link
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'SecurityKeys',
          functionName: 'handleSaveSecurityKey'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;

  button {
    padding: 0.2rem 1rem;
  }
}

h1 {
  margin: 0 0 10px;
}

.top-inputs {
  padding-bottom: 0.6rem;
}

.save-button {
  margin: 10px 0 0;
}

.dialog-container {
  display: flex;
  flex-flow: column;
  max-height: 90vh;
  overflow: hidden;
}
</style>
