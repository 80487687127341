<template>
  <div>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete Monetary Gift"
      data-test="delete-monetary-gift-dialog"
      @cancel="showDeleteDialog = false"
      @confirm="deleteMonetaryGift"
    />

    <div>
      <div class="header">
        <h1>Monetary Gift</h1>

        <e-button
          class="close-button icon-x-circle ghost"
          data-test="close-dialog-button"
          @click="$emit('closed')"
        />
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form class="spaced" @submit.prevent="handleSubmit(submit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Beneficiary"
            rules="required"
            tag="div"
          >
            <e-select
              v-model="selectedContactOrGroupId"
              :items="eligibleContactsAndGroupsAsSelectOptions"
              default-option-text="Select Beneficiary"
              label="Beneficiary"
              :error-messages="errors"
              :success="valid"
              data-test="beneficiary-select"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Gift Value"
            rules="required|min_value:1"
            tag="div"
          >
            <e-input
              v-model="amount"
              label="Monetary Gift Value"
              type="number"
              :error-messages="errors"
              :success="valid"
              data-test="gift-value-input"
              prefix="£"
              @focus="amount = amount || null"
            />
          </validation-provider>

          <template v-if="selectedContactOrGroupId && selectedContactOrGroup.type === 'person'">
            <p>
              If the gift to the beneficiary fails, should the it go to the
              beneficiary’s children or their respective descendants?
            </p>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Go to their children?"
              :rules="{ required: true }"
              class="item"
              tag="div"
            >
              <e-input
                :value="beneficiaryDeadShareGoesToTheirChildren"
                :radio-value="true"
                type="radio"
                label="Yes"
                @change="value => beneficiaryDeadShareGoesToTheirChildren = value"
              />

              <e-input
                :value="beneficiaryDeadShareGoesToTheirChildren"
                :radio-value="false"
                type="radio"
                label="No"
                @change="value => beneficiaryDeadShareGoesToTheirChildren = value"
              />

              <span v-if="!valid">{{ errors }}</span>
            </validation-provider>
          </template>

          <confirmation-buttons
            confirm-button-text="Save"
            confirm-button-type="submit"
            cancel-button-text="Delete"
            @cancel="showDeleteDialog = true"
          />
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Beneficiary from '@/models/Beneficiary'
import ConfirmationButtons from '@/components/buttons/ConfirmationButtons'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import Contact from '@/models/Contact'
import Group from '@/models/Group'
import MonetaryGift from '@/models/MonetaryGift'
import Will from '@/models/Will'

export default {
  name: 'MonetaryGiftForm',

  components: {
    ConfirmationButtons,
    ConfirmationDialog
  },

  props: {
    monetaryGiftId: {
      type: String,
      default: ''
    }
  },

  emits: [
    'submitted',
    'closed'
  ],

  data () {
    return {
      showDeleteDialog: false,
      selectedContactOrGroupId: '',
      amount: 0,
      beneficiaryDeadShareGoesToTheirChildren: true
    }
  },

  computed: {
    monetaryGifts () {
      return MonetaryGift
        .query()
        .with('beneficiary')
        .with('beneficiary.contact')
        .with('beneficiary.group')
        .all()
    },

    eligibleContactsAndGroups () {
      return [...Group.all(), ...Contact.all()]
        .filter(({ id }) => {
          if (id === this.selectedContactOrGroupId) return true

          return !this.monetaryGifts
            .map(({ beneficiary }) => beneficiary.groupOrContactId)
            .includes(id)
        })
    },

    eligibleContactsAndGroupsAsSelectOptions () {
      return this.eligibleContactsAndGroups
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    selectedContactOrGroup () {
      return this.eligibleContactsAndGroups
        .find(({ id }) => id === this.selectedContactOrGroupId)
    }
  },

  created () {
    if (this.monetaryGiftId) {
      const { id, amount, beneficiary } = MonetaryGift
        .query()
        .whereId(this.monetaryGiftId)
        .with('beneficiary')
        .first()

      this.id = id
      this.beneficiaryId = beneficiary.id
      this.selectedContactOrGroupId = beneficiary.groupOrContactId
      this.amount = amount
      this.beneficiaryDeadShareGoesToTheirChildren = beneficiary.beneficiaryDeadShareGoesToTheirChildren
    } else {
      this.id = new MonetaryGift().id
    }
  },

  methods: {
    async deleteMonetaryGift () {
      this.showDeleteDialog = false
      this.$emit('submitted')

      // Beneficiary.delete(this.selectedContactOrGroupId)
      Beneficiary.delete(MonetaryGift.find(this.monetaryGiftId).beneficiaryId)
      MonetaryGift.delete(this.id)

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['monetaryGifts', 'beneficiaries'],
        message: 'Deleted'
      })
    },

    async submit () {
      this.$emit('submitted')

      const beneficiaryType = this.eligibleContactsAndGroups
        .find(({ id }) => id === this.selectedContactOrGroupId)
        ?.type

      const isGroup = beneficiaryType === 'group'

      MonetaryGift.insertOrUpdate({
        data: {
          id: this.id,
          amount: this.amount,
          willId: Will.query().first().id,
          beneficiary: {
            ...this.beneficiaryId && { id: this.beneficiaryId },
            groupId: isGroup ? this.selectedContactOrGroupId : '',
            contactId: isGroup ? '' : this.selectedContactOrGroupId,
            type: beneficiaryType,
            beneficiaryDeadShareGoesToTheirChildren: this.beneficiaryDeadShareGoesToTheirChildren
          }
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['monetaryGifts', 'beneficiaries'],
        message: 'Saved'
      })
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  padding-bottom: 1rem;
}

.close-button {
  align-self: start;
  padding: 0;
}
</style>
