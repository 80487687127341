<template>
  <div>
    <e-dialog v-if="showDialog">
      <component
        :is="dialogInfoType"
        v-if="showDialog"
        :item="selectedProduct"
        @closed="showDialog = false"
      />
    </e-dialog>

    <article class="product">
      <div class="image-blocks">
        <div
          :style="productImage"
          class="image"
        />

        <div
          :style="vendorImage"
          class="image"
        />
      </div>

      <div class="text-blocks">
        <p class="subtype">
          Generator:
        </p>

        <h2>
          {{ product.title }}
        </h2>

        <div class="description">
          {{ product.abstract }}
        </div>

        <e-button class="info-button" @click="showProduct">
          About the Generator
        </e-button>

        <e-button
          v-if="!purchasedProduct"
          class="book-button"
          @click="showPurchaseConfirm"
        >
          MVT {{ product.price }}
        </e-button>

        <template v-else>
          <e-button
            v-if="purchasedProduct.routeName"
            class="book-button"
            @click="gotoInstalledItem(purchasedProduct)"
          >
            Use
          </e-button>
        </template>

        <p class="subtype dotted">
          Vendor:
        </p>

        <h3 class="vendor-title">
          {{ product.vendor.name }}
        </h3>

        <div class="description">
          {{ product.vendor.abstract }}
        </div>

        <e-button class="info-button" @click="showVendor">
          About the vendor
        </e-button>
      </div>
    </article>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ProductInfoElement from './ProductInfoElement'
import VendorInfoElement from './VendorInfoElement'
import ConfirmPurchase from '../ConfirmPurchaseDialogue'

export default {
  name: 'ProductElement',

  components: {
    EDialog,
    ProductInfoElement,
    VendorInfoElement,
    ConfirmPurchase
  },

  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      showDialog: false
    }
  },

  computed: {
    purchasedProduct () {
      return this.$store.getters['marketplace/getPurchasedProduct'](this.product.id)
    },

    productImage () {
      return this.product?.imageUrl && `background-image: url('${this.product?.imageUrl}')`
    },

    vendorImage () {
      return this.product?.vendor?.imageUrl && `background-image: url('${this.product?.vendor?.imageUrl}')`
    }
  },

  methods: {
    openAppropriateDialog ({ product, type }) {
      this.dialogInfoType = {
        product: 'ProductInfoElement',
        vendor: 'VendorInfoElement',
        confirmPurchase: 'ConfirmPurchase'
      }[type]

      this.selectedProduct = product
      this.showDialog = true
    },

    showProduct () {
      this.openAppropriateDialog({
        product: this.product,
        type: 'product'
      })
    },

    showVendor () {
      this.openAppropriateDialog({
        product: this.product,
        type: 'vendor'
      })
    },

    showPurchaseConfirm () {
      this.openAppropriateDialog({
        product: this.product,
        type: 'confirmPurchase'
      })
    },

    gotoInstalledItem (product) {
      if (!product) return
      this.$router.push({ name: product?.routeName })
    }
  }
}
</script>

<style lang="scss" scoped>
$honey-mask: url('/assets/mask.svg');

.product {
  background-color: $widget-background-color;
  border-radius: $corner;
  display: flex;
  gap: 1rem;

  .description {
    line-height: normal;
  }

  button {
    margin: 1rem;
  }
}

.text-blocks {
  width: 68%;
}

.image-blocks {
  align-self: center;
  position: relative;
  width: 28%;

  .image {
    background-image: url('/default_thumbnail.png');
    background-position: center;
    background-size: cover;
    height: 0;
    mask-image: $honey-mask;
    mask-position: center;
    mask-repeat: no-repeat;
    padding-bottom: 74.4%;
    position: relative;
    width: 66%;

    &:nth-child(2) {
      left: 34%;
      margin-top: -14%;
    }
  }
}

.dotted {
  border-top: $accentli dotted 1px;
  padding-top: 1rem;
}

.subtype {
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  margin: 1.2rem 0 0.5rem;
  opacity: 0.7;
  text-transform: uppercase;
}

@media screen and (min-width: $media-break-1) {
  .service {
    background-color: $widget-background-color;
  }
}
</style>
