<template>
  <div>
    <e-dialog
      v-if="isVisible"
      class="dialog"
    >
      <div>
        <e-select
          :items="apiResults"
          default-option-text="Select company"
          @change="setCompany"
        />

        <e-button
          class="icon-x-circle ghost"
          @click="isVisible = false"
        />
      </div>
    </e-dialog>

    <validation-provider
      v-slot="{ errors, valid }"
      name="Name"
      :rules="{ required: true }"
      tag="div"
    >
      <e-input
        v-model="mutableName"
        label="Name"
        :error-messages="errors"
        :success="valid"
        :is-required="true"
        class="title-input"
        :delay="true"
        :help-id="20"
        data-test="share-name-input"
        @input="runSearch"
      />
    </validation-provider>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import getFinancials from '@/apis/yahooFinance/getFinancials'
import getShareAutoComplete from '@/apis/yahooFinance/getShareAutoComplete'

export default {
  name: 'ShareLookup',

  components: {
    EDialog
  },

  props: {
    name: {
      type: String,
      default: ''
    }
  },

  emits: [
    'value-updated',
    'company-selected'
  ],

  data () {
    return {
      isVisible: false,
      apiResults: []
    }
  },

  computed: {
    mutableName: {
      get () {
        return this.name
      },

      set (value) {
        this.$emit('value-updated', { property: 'name', value })
      }
    }
  },

  methods: {
    async runSearch (string) {
      if (string) {
        try {
          const response = await getShareAutoComplete(string)

          this.apiResults = response.data.quotes.map(result => ({
            value: result,
            text: `${result.longname} - ${result.symbol} (EXCH: ${result.exchange})`
          }))

          if (response.data.quotes.length) this.isVisible = true
        } catch (error) {
          this.$store.dispatch('logError', {
            error,
            fileName: 'ShareLookup',
            functionName: 'runSearch'
          })
        }
      }
    },

    async setCompany ({ longname, symbol, exchange }) {
      const financials = await getFinancials(symbol)

      this.isVisible = false

      this.$emit('company-selected', {
        name: longname,
        symbol,
        exchange,
        price: financials.data.price.regularMarketPrice.raw,
        currency: financials.data.price.currency
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  column-gap: 1%;
  display: grid;
  grid-template-columns: 29% 70%;
}

.dialog {
  left: 55%;
  margin: 0;
  padding: 0.4rem;
  top: 3%;
  width: 16% !important;

  div {
    column-gap: 1%;
    display: grid;
    grid-template-columns: 88% 11%;

    & > * {
      place-self: center;
    }

    .icon-x-circle {
      font-size: 1.6rem;
    }
  }
}
</style>
