<template>
  <e-dialog class="unsaved-changes-warning-dialog">
    <h1>Unsaved changes!</h1>

    <p>
      Do you really want to leave this page?
      You will lose any unsaved changes.
    </p>

    <confirmation-buttons
      @confirm="$emit('confirm')"
      @cancel="$emit('cancel')"
    />
  </e-dialog>
</template>

<script>
import ConfirmationButtons from '@/components/buttons/ConfirmationButtons'
import EDialog from '@/components/dialogs/EDialog'

export default {
  name: 'UnsavedChangesWarningDialog',

  components: {
    ConfirmationButtons,
    EDialog
  },

  emits: [
    'confirm',
    'cancel'
  ]
}
</script>

<style scoped lang="scss">
.unsaved-changes-warning-dialog {
  width: 20vw !important;
}
</style>
