import '@/styles/common.scss'
import 'animate.css'

import App from '@/App'
import AccountForm from '@/components/forms/AccountForm'
import AdvanceDecision from '@/components/forms/vendors/aston_veer/AdvanceDecision'
import AssetForm from '@/components/forms/AssetForm'
import BucketListItemForm from '@/components/forms/BucketListItemForm'
import ChildCareServiceAgreement from '@/components/forms/vendors/meavitae/ChildCareServiceAgreement'
import ContactForm from '@/components/forms/ContactForm'
import ContractTerminationAgreement from '@/components/forms/vendors/aston_veer/ContractTerminationAgreement'
import CredentialForm from '@/components/forms/CredentialForm'
import DebtLetterBeforeLegalAction from '@/components/forms/vendors/aston_veer/DebtLetterBeforeLegalAction'
import DeedOfRevocationForPowerOfAttorney from '@/components/forms/vendors/aston_veer/DeedOfRevocationForPowerOfAttorney'
import EButton from '@/components/buttons/EButton'
import EFile from '@/components/forms/formElements/EFile'
import EInput from '@/components/forms/formElements/EInput'
import ERadioGroup from '@/components/forms/formElements/ERadioGroup'
import ESelect from '@/components/forms/formElements/ESelect'
import ETextarea from '@/components/forms/formElements/ETextarea'
import FuneralWishesForm from '@/components/forms/FuneralWishesForm'
import GeneralReleaseOfLiability from '@/components/forms/vendors/aston_veer/GeneralReleaseOfLiability'
import LiabilityForm from '@/components/forms/LiabilityForm'
import LetterForm from '@/components/forms/LetterForm'
import LoanAgreement from '@/components/forms/vendors/aston_veer/LoanAgreement'
import MedicalConsent from '@/components/forms/vendors/meavitae/MedicalConsent'
import MemorialForm from '@/components/forms/MemorialForm'
import NonDisclosureAgreement from '@/components/forms/vendors/aston_veer/NonDisclosureAgreement'
import NoteForm from '@/components/forms/NoteForm'
import PropertyDeclarationOfTrust from '@/components/forms/vendors/aston_veer/PropertyDeclarationOfTrust'
import Questionnaire7iM from '@/components/forms/Questionnaire7iM'
import TravelConsent from '@/components/forms/vendors/aston_veer/TravelConsent'
import UserForm from '@/components/forms/UserForm'
import UnconstrainedFund7iM from '@/components/forms/UnconstrainedFund7iM'
import UkHousingActSectionEight from '@/components/forms/vendors/aston_veer/UkHousingActSectionEight'
import UkHousingActSectionTwentyOne from '@/components/forms/vendors/aston_veer/UkHousingActSectionTwentyOne'
import UkLandRegistryTransfer from '@/components/forms/vendors/aston_veer/UkLandRegistryTransfer'
import Vue from 'vue'
import Vuex from 'vuex'
import createDOMPurify from 'dompurify'
import router from '@/router'
import store from '@/store'
import '@/validation.js'

const DOMPurify = createDOMPurify(window)

Vue.config.productionTip = false

Vue.use(Vuex)

Vue.component('EButton', EButton)
Vue.component('ETextarea', ETextarea)
Vue.component('EInput', EInput)
Vue.component('ESelect', ESelect)
Vue.component('ERadioGroup', ERadioGroup)
Vue.component('EFile', EFile)

Vue.component('AccountForm', AccountForm)
Vue.component('AssetForm', AssetForm)
Vue.component('ContactForm', ContactForm)
Vue.component('CredentialForm', CredentialForm)
Vue.component('UserForm', UserForm)
Vue.component('NoteForm', NoteForm)
Vue.component('FuneralWishesForm', FuneralWishesForm)
Vue.component('LetterForm', LetterForm)
Vue.component('LiabilityForm', LiabilityForm)
Vue.component('BucketListItemForm', BucketListItemForm)
Vue.component('MemorialForm', MemorialForm)

// ===============Vendor Document Forms===============
// -----MEAVITAE-----
Vue.component('ChildCareServiceAgreement', ChildCareServiceAgreement)
Vue.component('MedicalConsent', MedicalConsent)
// -----ASTON VEER-----
Vue.component('AdvanceDecision', AdvanceDecision)
Vue.component('TravelConsent', TravelConsent)
Vue.component('UkHousingActSectionTwentyOne', UkHousingActSectionTwentyOne)
Vue.component('UkHousingActSectionEight', UkHousingActSectionEight)
Vue.component('ContractTerminationAgreement', ContractTerminationAgreement)
Vue.component('UkLandRegistryTransfer', UkLandRegistryTransfer)
Vue.component('PropertyDeclarationOfTrust', PropertyDeclarationOfTrust)
Vue.component('LoanAgreement', LoanAgreement)
Vue.component('NonDisclosureAgreement', NonDisclosureAgreement)
Vue.component('GeneralReleaseOfLiability', GeneralReleaseOfLiability)
Vue.component('DeedOfRevocationForPowerOfAttorney', DeedOfRevocationForPowerOfAttorney)
Vue.component('DebtLetterBeforeLegalAction', DebtLetterBeforeLegalAction)
// -----7iM-----
Vue.component('Questionnaire7iM', Questionnaire7iM)
Vue.component('UnconstrainedFund7iM', UnconstrainedFund7iM)

Vue.use({
  install (Vue, options) {
    Vue.prototype.purify = html => {
      return DOMPurify.sanitize(html)
    }
  }
})

new Vue({
  name: 'Root',
  router,
  store: new Vuex.Store(store),
  render: h => h(App)
}).$mount('#app')
