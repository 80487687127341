<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Children

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(66)"
        />
      </h1>
    </div>

    <e-select
      :items="eligibleContactsAsSelectOptions"
      default-option-text="Add Child"
      data-test="eligible-children-select"
      @change="addChild"
    />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <fieldset v-if="children.length">
        <legend>Children</legend>

        <ul data-test="children-list">
          <transition-group
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <li v-for="{ id, fullName } in children" :key="id">
              <span data-test="name">
                {{ fullName }}
              </span>

              <e-button
                class="ghost"
                data-test="remove-child-button"
                @click="removeChild(id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </li>
          </transition-group>
        </ul>
      </fieldset>
    </transition>

    <p>
      Children will include all descendants, known here and/or proven in the
      future.  However, you may wish to include some individuals based on the
      following categories.
    </p>

    <e-input
      :value="childrenIncludesIllegitimateChildren"
      type="checkbox"
      label="Include illegitimate children"
      data-test="include-illegitimate-children-checkbox"
      @change="value => childrenIncludesIllegitimateChildren = value"
    />

    <e-input
      :value="childrenIncludesStepchildren"
      type="checkbox"
      label="Include step-children, even if they are not legally adopted by you"
      data-test="include-stepchildren-checkbox"
      @change="value => childrenIncludesStepchildren = value"
    />

    <p class="warning">
      If you <strong>do not</strong> include children born outside marriage
      (illegitimate in common law) in the definition of children in the schedule
      accompanying your will, then only your children born within marriage will
      inherit gifts left to 'children'. Similarly, if you do not include step-children
      who you have not formally adopted, you run the same risk.
    </p>

    <p class="warning">
      Note that beneficiaries such as illegitimate and step-children who have been
      financially dependent on you and have been excluded from your will may be able
      to challenge the provision of your will in court.
    </p>
  </div>
</template>

<script>
import ChildContact from '@/models/ChildContact'
import Contact from '@/models/Contact'
import Will from '@/models/Will'

export default {
  name: 'Children',

  computed: {
    will () {
      return Will
        .query()
        .with('children')
        .with('guardians')
        .with('substituteGuardians')
        .first()
    },

    childrenIncludesIllegitimateChildren: {
      get () {
        return this.will.childrenIncludesIllegitimateChildren
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          childrenIncludesIllegitimateChildren: value
        })
      }
    },

    childrenIncludesStepchildren: {
      get () {
        return this.will.childrenIncludesStepchildren
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          childrenIncludesStepchildren: value
        })
      }
    },

    children () {
      return this.will.children
        .map(({ id, fullName }) => ({ id, fullName }))
    },

    eligibleContactsAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => !this.children
          .map(({ id }) => id)
          .includes(id))
        .map(({ id, selectText }) => ({
          value: id,
          text: selectText,
          isDisabled: this.isDisabled(id)
        }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 66,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    isDisabled (contactId) {
      return [
        this.will.partnerId,
        ...this.will.guardians.map(({ id }) => id),
        ...this.will.substituteGuardians.map(({ id }) => id)
      ].includes(contactId)
    },

    async addChild (contactId) {
      ChildContact.insert({
        data: {
          willId: this.will.id,
          contactId
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['childContacts'],
        message: 'Saved'
      })
    },

    async removeChild (contactId) {
      ChildContact.delete([this.will.id, contactId])

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['childContacts'],
        message: 'Saved'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.check {
  display: block;
}

.warning {
  color: $color-error;
}
</style>
