'use strict'

import { generateKey } from 'openpgp'

export default async ({ name, email }) => {
  const { publicKeyArmored: armoredPublicKey, privateKeyArmored: armoredPrivateKey } = await generateKey({
    curve: 'curve25519',
    userIds: [{ name, email }]
  })

  return { armoredPublicKey, armoredPrivateKey }
}
