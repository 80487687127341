<template>
  <router-view @submitted="handleSubmitted" />
</template>

<script>
export default {
  name: 'LettersScheduled',

  emits: [
    'set-active',
    'submitted'
  ],

  created () {
    this.$emit('set-active', 'lettersScheduled')
  },

  methods: {
    handleSubmitted (routeObject) {
      this.$emit('submitted', routeObject)
    }
  }
}
</script>
