'use strict'

import FormView from '@/views/FormView'
import Letters from '@/views/Letters/Letters'
import LettersScheduled from '@/views/Letters/LettersScheduled'
import LettersRecordsTable from '@/views/Letters/LettersRecordsTable'
import LoadingSpinner from '@/components/LoadingSpinner'

const subscriptionFeatureId = null

export default {
  path: '/letters',
  name: 'Letters',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Letters
  },
  meta: {
    title: 'Letters',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'list',
      name: 'LettersScheduled',
      components: {
        appClosedLayout: LoadingSpinner,
        default: LettersScheduled
      },
      meta: {
        title: 'List',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'all',
          name: 'LettersRecordsTable',
          components: {
            appClosedLayout: LoadingSpinner,
            default: LettersRecordsTable
          },
          meta: {
            title: 'Letters',
            subscriptionFeatureId
          }
        },

        {
          path: 'new',
          name: 'NewLetter',
          props: {
            default: () => ({
              form: 'LetterForm',
              setScheduleLetter: true
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'New Letter',
            subscriptionFeatureId
          }
        },

        {
          path: ':id',
          name: 'ScheduledLetter',
          props: {
            default: route => ({
              form: 'LetterForm',
              id: route.params.id,
              setScheduleLetter: true
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'Letter',
            subscriptionFeatureId
          }
        }
      ]
    }
  ]
}
