var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Contract Termination Agreement "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"contract-termination-agreeent-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true,"data-test":"document-label-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Contract Termination Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date Contract Is Terminated","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.contractTerminationDate),callback:function ($$v) {_vm.contractTerminationDate=$$v},expression:"contractTerminationDate"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Contract Details")]),_c('validation-provider',{attrs:{"name":"Contract Title","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Contract Title","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.contractTitle),callback:function ($$v) {_vm.contractTitle=$$v},expression:"contractTitle"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Contract Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Contract Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.contractDate),callback:function ($$v) {_vm.contractDate=$$v},expression:"contractDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Contracted Party One","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.contractPartyOneSelectOptions,"label":"Contracted Party One","default-option-text":"Add Contracted Party","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.contractPartyOneId),callback:function ($$v) {_vm.contractPartyOneId=$$v},expression:"contractPartyOneId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Contracted Party Two","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.contractPartyTwoSelectOptions,"label":"Contracted Party Two","default-option-text":"Add Contracted Party","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.contractPartyTwoId),callback:function ($$v) {_vm.contractPartyTwoId=$$v},expression:"contractPartyTwoId"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Witness(es)")]),_c('validation-provider',{attrs:{"name":"Witness Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Witness Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.witnessDate),callback:function ($$v) {_vm.witnessDate=$$v},expression:"witnessDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Witnesses","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.witnesses.length),expression:"witnesses.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.witnesses.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.witnesses, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.witnessesSelectOptions,"default-option-text":"Add Witness","error-messages":errors,"success":valid,"is-required":true},on:{"change":function (contactId) { return _vm.addContact({ type: 'witnesses', contactId: contactId }); }}})]}}],null,true)}),(_vm.witnesses.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Witness")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.witnesses),function(witness){return _c('tr',{key:witness.id},[_c('td',[_vm._v(" "+_vm._s(witness.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeContact({ type: 'witnesses', contactId: witness.id })}}})],1)])}),0)]):_vm._e()],1),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"contract-termination-agreeent-form","button-text":"Preview","data-test":"bottom-preview-button"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }