'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'
import { capitalCase } from 'change-case'
import { format, parse } from 'date-fns'

const getFullName = ({ type, firstName = '', lastName = '', middleNames = '', organisationName = '' }) => {
  if (type === 'person') {
    if (!firstName && !lastName && !middleNames) return 'NoName'

    return middleNames
      ? `${firstName} ${middleNames} ${lastName}`.trim()
      : `${firstName} ${lastName}`.trim()
  }

  return organisationName || 'NoName'
}

const getContactIsCharity = ({ type, isCharity }) => {
  return !!((type === 'charity') || (type === 'organisation' && isCharity))
}

export default class Contact extends Model {
  constructor (...args) {
    super(...args)

    if (typeof args[0] === 'object') {
      this.fullName = getFullName(args[0])
      this.contactIsCharity = getContactIsCharity(args[0])
    }
  }

  static get entity () { return 'contacts' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      uuid: this.string(),
      publicKey: this.string(),
      linkedInId: this.string(),
      facebookId: this.string(),
      twitterId: this.string(),
      instagramId: this.string(),
      tiktokId: this.string(),
      googleId: this.string(),
      microsoftId: this.string(),
      type: this.string('person'),
      userId: this.string(),
      firstName: this.string(),
      middleNames: this.string(),
      lastName: this.string(),
      dateOfBirth: this.string(),
      placeOfBirth: this.string(),
      addresses: this.attr(() => []),
      emailAddresses: this.attr(() => []),
      phoneNumbers: this.attr(() => []),
      prefixes: this.string(),
      suffixes: this.string(),
      genderType: this.string(),
      genderIdentity: this.string(),
      jobTitle: this.string(),
      organisationName: this.string(),
      organisationRegisteredNumber: this.string(),
      organisationTerritoryOfIncorporation: this.string(),
      isCharity: this.boolean(),
      acronym: this.string(),
      isUkRegistered: this.boolean(),
      charityNumber: this.string(),
      website: this.string(),
      createdDate: this.string(),
      notes: this.string(),
      source: this.string(),
      isValid: this.boolean(false)
    }
  }

  get selectText () {
    return this.type === 'person' && this.dateOfBirth
      ? `${capitalCase(this.type)} - ${this.fullName} (DOB: ${format(parse(this.dateOfBirth, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')})`
      : `${capitalCase(this.type)} - ${this.fullName}`
  }
}
