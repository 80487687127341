var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Deed Of Revocation For A Power Of Attorney "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"non-disclosure-agreement-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Deed Of Revocation Made Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Deed Of Revocation Made Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.deedOfRevocationMadeDate),callback:function ($$v) {_vm.deedOfRevocationMadeDate=$$v},expression:"deedOfRevocationMadeDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Employer","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.revokedBySelectOptions,"label":"revoked By","default-option-text":"Add revoked By","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.revokedById),callback:function ($$v) {_vm.revokedById=$$v},expression:"revokedById"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Power Of Attorney Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Power Of Attorney Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.powerOfAttorneyDate),callback:function ($$v) {_vm.powerOfAttorneyDate=$$v},expression:"powerOfAttorneyDate"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Attorneys")]),_c('validation-provider',{attrs:{"name":"Attorney","rules":{ array_length: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attorneys.length),expression:"attorneys.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.attorneys.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attorneys, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.attorneySelectOptions,"default-option-text":"Add Attorney","error-messages":errors,"success":valid,"is-required":true},on:{"change":_vm.addAttorney}})]}}],null,true)}),(_vm.attorneys.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Attorney")]),_c('th',[_vm._v("Signed As Deed By")]),_c('th',[_vm._v("In Presence Of")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.attorneys),function(attorney){return _c('tr',{key:attorney.id},[_c('td',[_vm._v(" "+_vm._s(attorney.contact.fullName)+" ")]),_c('td',[_c('validation-provider',{attrs:{"name":"Signed As Deed By","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"placeholder":"Signed As Deed By","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(attorney.signedAsDeedBy),callback:function ($$v) {_vm.$set(attorney, "signedAsDeedBy", $$v)},expression:"attorney.signedAsDeedBy"}})]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"In Presence Of","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"placeholder":"In Presence Of","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(attorney.inPresenceOf),callback:function ($$v) {_vm.$set(attorney, "inPresenceOf", $$v)},expression:"attorney.inPresenceOf"}})]}}],null,true)})],1),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeAttorney(attorney.contact.id)}}})],1)])}),0)]):_vm._e()],1),_c('validation-provider',{attrs:{"name":"Deed Of Revocation From Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Deed Of Revocation From Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.deedOfRevocationFromDate),callback:function ($$v) {_vm.deedOfRevocationFromDate=$$v},expression:"deedOfRevocationFromDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Witness","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.witnessSelectOptions,"label":"Witness","default-option-text":"Add Witness","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.witnessId),callback:function ($$v) {_vm.witnessId=$$v},expression:"witnessId"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"non-disclosure-agreement-form","button-text":"Preview"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }