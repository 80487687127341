var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.isEdit)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"card",class:[_vm.status, { highlight: _vm.todo.highlight }, { clickable: _vm.todo.path }],on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateTodo)},"click":_vm.goToTask}},[_c('div',{staticClass:"title"},[_c('div',[_c('validation-provider',{attrs:{"name":"Title","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"error-messages":errors,"success":valid,"placeholder":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"body"},[_c('div',[_c('e-input',{attrs:{"placeholder":"Body"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)]),_c('div',{staticClass:"email"},[((_vm.todo.type !== 'system') && !_vm.todo.isDone)?_c('e-input',{attrs:{"value":_vm.emailTodo,"type":"checkbox","label":"Email me this todo on the date I have set (including the title)"},on:{"change":function (value) { return _vm.emailTodo = value; }}}):_vm._e()],1),((_vm.todo.type !== 'system') && !_vm.todo.isDone)?_c('validation-provider',{staticClass:"deadline-date",attrs:{"name":"Date","rules":Object.assign({}, _vm.emailTodo && { required: _vm.emailTodo, is_future_date: true }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"error-messages":errors,"success":valid,"type":"date"},model:{value:(_vm.deadlineDate),callback:function ($$v) {_vm.deadlineDate=$$v},expression:"deadlineDate"}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"save-button"},[_c('e-button',{attrs:{"type":"submit","disabled":!formIsValid}},[_vm._v(" Save ")])],1)],1)]}}],null,false,2431162743)}):_c('div',{staticClass:"card",class:[_vm.status, { highlight: _vm.todo.highlight }, { clickable: _vm.todo.path }],on:{"click":_vm.goToTask}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.todo.title))])]),_c('div',{staticClass:"body"},[_c('p',[_vm._v(_vm._s(_vm.todo.body))])]),_c('div',{staticClass:"email"},[((_vm.todo.type !== 'system') && !_vm.todo.isDone)?_c('p',[_vm._v(" "+_vm._s(_vm.todo.emailTodo ? 'A scheduled email reminder will be sent to me on the deadline date set' : '')+" ")]):_vm._e()]),_c('div',{staticClass:"actions"},[(!_vm.todo.isDone)?_c('span',{staticClass:"action-icon icon-check-circle",on:{"click":function($event){$event.stopPropagation();return _vm.updateCardStatus.apply(null, arguments)}}}):_vm._e(),((_vm.todo.type !== 'system') && !_vm.todo.isDone)?_c('span',{staticClass:"action-icon icon-edit",on:{"click":function($event){_vm.isEdit = true}}}):_vm._e(),(_vm.todo.type !== 'system')?_c('span',{staticClass:"action-icon icon-trash-2",on:{"click":function($event){$event.stopPropagation();return _vm.deleteTodo.apply(null, arguments)}}}):_vm._e()]),(_vm.todo.type !== 'system')?_c('div',{staticClass:"deadline-date"},[(!_vm.todo.isDone)?_c('div',[_c('div',[(_vm.todo.deadlineDate)?_c('span',[_vm._v(" Deadline Date: "),_c('span',{class:{ warning: _vm.isPastDeadline }},[_vm._v(" "+_vm._s(_vm.getFormattedDate(_vm.todo.deadlineDate))+" ")])]):_vm._e()])]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }