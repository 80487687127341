<template>
  <div>
    <e-button
      data-test="add-email-address-button"
      @click="addEmailAddress(emailAddresses)"
    >
      <span class="icon-plus" />
      Add Email

      <span
        v-if="(type !== '' && type === 'person') && !emailAddresses.length && neededForIsValid"
        class="icon-alert-triangle"
        :style="{ color: emailRequired ? 'red' : 'orange' }"
      />
    </e-button>

    <validation-provider
      v-if="emailRequired"
      v-slot="{ errors, valid }"
      name="Email Address"
      :rules="{ array_length: true }"
      tag="div"
    >
      <e-input
        :value="emailAddresses.length"
        type="hidden"
        :error-messages="errors"
        :success="valid"
        data-test="email-addresses-required-input"
      />
    </validation-provider>

    <fieldset v-if="emailAddresses.length">
      <legend>Email Addresses</legend>

      <table data-test="email-address-table">
        <thead>
          <tr>
            <th>Type</th>

            <th>Email</th>

            <th class="center">
              Preferred
            </th>

            <th class="center">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(email, index) in emailAddresses"
            :key="email.id"
          >
            <td>
              <e-select
                :key="email.id.concat(Date.now())"
                :value="emailAddresses[index].type"
                :items="defaultEmailTypes"
                default-option-text="Select Type"
                data-test="email-address-type-select"
                @change="updateType(emailAddresses, email.id, $event)"
              />
            </td>

            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${email.id}-email`"
                name="Email"
                :rules="{ required: true, email: true }"
                tag="div"
              >
                <e-input
                  :value="emailAddresses[index].value"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  class="email-field"
                  type="email"
                  data-test="email-address-input"
                  @input="updateValue(emailAddresses, email.id, $event)"
                />
              </validation-provider>
            </td>

            <td class="preferred">
              <e-input
                :value="emailAddresses[index].isPref"
                type="checkbox"
                data-test="preferred-email-address-checkbox"
                @change="updatePref(emailAddresses, email.id, $event)"
              />
            </td>

            <td class="actions">
              <e-button
                class="ghost"
                data-test="remove-email-address-button"
                @click="removeEmailAddress(emailAddresses, email.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { createId } from '@/utils'

export default {
  name: 'EmailAddressManager',

  props: {
    emailAddresses: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'person'
    },

    emailRequired: {
      type: Boolean,
      default: false
    },

    neededForIsValid: {
      type: Boolean,
      default: true
    }
  },

  emits: ['is-dirty'],

  data () {
    return {
      defaultEmailTypes: [
        { text: 'Work', value: 'work' },
        { text: 'Home', value: 'home' },
        { text: 'Other', value: 'other' }
      ]
    }
  },

  methods: {
    addEmailAddress (emailAddresses) {
      emailAddresses.push({ id: createId() })
      this.$emit('is-dirty')
    },

    updateEmailAddress ({ emailAddresses, emailId, key, value }) {
      emailAddresses.find(({ id }) => id === emailId)[key] = value
      this.$emit('is-dirty')
    },

    removeEmailAddress (emailAddresses, emailId) {
      const index = emailAddresses.findIndex(({ id }) => id === emailId)
      emailAddresses.splice(index, 1)
      this.$emit('is-dirty')
    },

    updateType (emailAddresses, emailId, value) {
      this.updateEmailAddress({
        emailAddresses,
        emailId,
        key: 'type',
        value
      })
    },

    updateValue (emailAddresses, emailId, value) {
      this.updateEmailAddress({
        emailAddresses,
        emailId,
        key: 'value',
        value
      })
    },

    updatePref (emailAddresses, emailId, bool) {
      if (bool) {
        emailAddresses
          .filter(({ id }) => id !== emailId)
          .forEach(emailAddress => (emailAddress.isPref = false))
      }

      this.updateEmailAddress({
        emailAddresses,
        emailId,
        key: 'isPref',
        value: bool
      })
    }
  }
}
</script>

<style scoped lang="scss">
button {
  margin-bottom: 0.8rem;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
