<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :marketplace-product-sku="marketplaceProductSku"
    />

    <span v-else>
      <h1>
        Medical Consent For Children

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(98)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="medical-consent-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset data-test="parents-fieldset">
            <legend>Parents</legend>

            <validation-provider
              v-if="!maxParentsReached"
              v-slot="{ errors, valid }"
              name="Parent"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="parentContacts.length"
                type="hidden"
              >

              <e-select
                :items="parentSelectOptions"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="parent-select"
                @change="addParentContact"
              />
            </validation-provider>

            <table v-if="parentContacts.length">
              <thead>
                <tr>
                  <th>Parent</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in parentContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeParentContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset data-test="children-fieldset">
            <legend>Children</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Child"
              :rules="{ array_length: true }"
              slim
            >
              <input
                v-model="childContacts.length"
                type="hidden"
              >

              <e-select
                :items="childSelectOptions"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="children-select"
                @change="addChildContact"
              />
            </validation-provider>

            <table v-if="childContacts.length">
              <thead>
                <tr>
                  <th>Child</th>
                  <th>Medical Conditions</th>
                  <th>Medication</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in childContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td>
                    <e-input
                      v-model="contact.medicalConditions"
                      data-test="child-medical-conditions-input"
                      @input="setIsDirty(true)"
                    />
                  </td>

                  <td>
                    <e-input
                      v-model="contact.medication"
                      data-test="child-medication-input"
                      @input="setIsDirty(true)"
                    />
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeChildContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset data-test="deputised-fieldset">
            <legend>
              Deputised Contact

              <span
                class="icon-help-circle"
                @click.prevent="setHelp(103)"
              />
            </legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Deputised"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="deputisedContactId"
                :items="deputisedSelectOptions"
                label="Authorised Person or Organisation"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="deputised-select"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <fieldset>
              <legend>Consent Options</legend>

              <e-input
                :value="canAccessInsuranceOrMedicalRecords"
                label="Can access insurance or medical records required for treatment"
                type="checkbox"
                data-test="can-access-insurance-medical-records-checkbox"
                @change="value => canAccessInsuranceOrMedicalRecords = value"
                @input="setIsDirty(true)"
              />

              <e-input
                :value="canAuthoriseEmergencyMedicalTreatment"
                label="Can authorise emergency medical treatment"
                type="checkbox"
                data-test="consent-to-emergency-medical-treatment-checkbox"
                @change="value => canAuthoriseEmergencyMedicalTreatment = value"
                @input="setIsDirty(true)"
              />

              <e-input
                :value="canAuthoriseRoutineMedicalTreatment"
                label="Can authorise routine medical treatment"
                type="checkbox"
                data-test="consent-to-routine-medical-treatment-checkbox"
                @change="value => canAuthoriseRoutineMedicalTreatment = value"
                @input="setIsDirty(true)"
              />

              <validation-provider
                v-if="!canAuthoriseAMedicalTreatment"
                v-slot="{ errors }"
                name="Medical Treatement Option"
                :rules="{ is: [true] }"
                :immediate="true"
                tag="div"
              >
                <input
                  v-model="canAuthoriseAMedicalTreatment"
                  type="hidden"
                >

                <span class="error-text">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </fieldset>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Start date"
            rules="required"
            slim
          >
            <e-input
              v-model="consentStartDate"
              label="Consent Start Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="consent-start-date-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="medical-consent-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { marked } from 'marked'
import { format } from 'date-fns'

const DOMPurify = createDOMPurify(window)

export default {
  name: 'MedicalConsent',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'd96fcc85-d76a-4631-9fe2-39b77498e54e',
      isDirty: false,
      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      parentContacts: [],
      childContacts: [],
      deputisedContactId: '',
      canAuthoriseEmergencyMedicalTreatment: true,
      canAuthoriseRoutineMedicalTreatment: true,
      canAccessInsuranceOrMedicalRecords: true,
      consentStartDate: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    deputisedSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    childSelectOptions () {
      return this.userAndContacts
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => id !== this.deputisedContactId)
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    parentSelectOptions () {
      return this.userAndContacts
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => id !== this.deputisedContactId)
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    canAuthoriseAMedicalTreatment () {
      return [
        this.canAuthoriseEmergencyMedicalTreatment,
        this.canAuthoriseRoutineMedicalTreatment
      ].some(bool => bool)
    },

    maxParentsReached () {
      return this.parentContacts.length >= 2
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 98,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addParentContact (contactId) {
      this.setIsDirty(true)

      this.parentContacts
        .push(this.userAndContacts.find(({ id }) => id === contactId))
    },

    removeParentContact (contactId) {
      this.parentContacts = this.parentContacts
        .filter(({ id }) => contactId !== id)
    },

    addChildContact (contactId) {
      this.setIsDirty(true)

      this.childContacts.push({
        ...this.userAndContacts.find(({ id }) => id === contactId),
        medicalConditions: '',
        medication: ''
      })
    },

    removeChildContact (contactId) {
      this.childContacts = this.childContacts
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            parents: this.parentContacts,
            children: this.childContacts,
            deputisedContact: this.userAndContacts.find(({ id }) => id === this.deputisedContactId),
            canAuthoriseEmergencyMedicalTreatment: this.canAuthoriseEmergencyMedicalTreatment,
            canAuthoriseRoutineMedicalTreatment: this.canAuthoriseRoutineMedicalTreatment,
            canAccessInsuranceOrMedicalRecords: this.canAccessInsuranceOrMedicalRecords,
            consentStartDate: this.consentStartDate
          },
          locale: 'en-GB',
          version: 0,
          type: 'medicalConsentChildren'
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'MedicalConsent',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: 'medicalConsentChildren',
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
          DocumentRecord.insert({ data: documentRecord })
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'MedicalConsent',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
