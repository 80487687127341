<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this contact"
      @confirm="deleteContact"
      @cancel="showDeleteDialog = false"
    />

    <e-dialog
      v-if="showLinkedAccountsDialog"
      class="linked-accounts-dialog"
      data-test="linked-accounts-dialog"
    >
      <p>
        Before deletion, this contact must be unlinked from the following:
      </p>

      <ul>
        <li
          v-for="account in accountsLinkedWithThisContact"
          :key="account.id"
        >
          {{ account.name }}
        </li>
      </ul>

      <e-button
        data-test="cancel-button"
        @click="showLinkedAccountsDialog = false"
      >
        Close
      </e-button>
    </e-dialog>

    <h1>
      {{ isExistingContact ? 'Edit' : 'Create' }} Contact

      <span
        class="icon-help-circle"
        @click.prevent="setHelp(3)"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="contact-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            :name="type"
            :rules="{ required: true }"
            slim
          >
            <e-radio-group
              :value="type"
              label="Contact Type"
              :is-required="true"
              :items="contactTypes"
              :error-messages="errors"
              :success="valid"
              data-test="contact-type-radio-group"
              @change="value => {
                type = value
                setIsDirty(true)
              }"
            />
          </validation-provider>

          <div class="connect-input">
            <validation-provider
              v-if="type !== '' && type === 'person'"
              v-slot="{ errors, valid }"
              name="First Name"
              :rules="{ required: type === 'person' }"
              slim
            >
              <e-input
                v-model="firstName"
                label="First Name"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="first-name-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <e-input
              v-if="type !== '' && type === 'person'"
              v-model="middleNames"
              label="Middle Names"
              data-test="middle-names-input"
              @input="setIsDirty(true)"
            />

            <validation-provider
              v-if="type !== '' && type === 'person'"
              v-slot="{ errors, valid }"
              name="Last Name"
              :rules="{ required: type === 'person' }"
              slim
            >
              <e-input
                v-model="lastName"
                label="Last Name"
                :error-messages="errors"
                :is-required="true"
                :success="valid"
                data-test="last-name-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </div>

          <template v-if="type !== '' && type === 'person'">
            <div class="connect-input">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Date of Birth"
                :rules="{ is_not_over_150_years_ago: true, is_past_date: true }"
                slim
              >
                <e-input
                  v-model="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  :error-messages="errors"
                  :success="valid"
                  data-test="date-of-birth-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <e-input
                v-model="placeOfBirth"
                label="Place Of Birth"
                data-test="place-of-birth-input"
                @input="setIsDirty(true)"
              />

              <e-select
                v-model="genderType"
                :items="genderTypes"
                label="Gender"
                data-test="gender-type-select"
                @change="setIsDirty(true)"
              />
            </div>

            <e-input
              v-model="jobTitle"
              label="Job Title"
              data-test="job-title-input"
              :help-id="9"
              @input="setIsDirty(true)"
            />
          </template>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Organisation Name"
            :rules="{ required: ['organisation'].includes(type) }"
            slim
          >
            <e-input
              v-model="organisationName"
              label="Organisation Name"
              :error-messages="errors"
              :success="valid"
              :is-required="type !== '' && type !== 'person'"
              :help-id="{ person: 5, organisation: 6, charity: 7 }[type]"
              data-test="organisation-name-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <template v-if="type === 'organisation'">
            <e-input
              v-model="organisationRegisteredNumber"
              label="Organisation Registered Number"
              @input="setIsDirty(true)"
            />

            <e-input
              v-model="organisationTerritoryOfIncorporation"
              label="Territory Of Incorporation"
              @input="setIsDirty(true)"
            />

            <e-input
              :value="isCharity"
              label="Organistion Is A Charity?"
              type="checkbox"
              data-test="is-charity-checkbox"
              @input="setIsDirty(true)"
              @change="value => isCharity = !!value"
            />
          </template>

          <template v-if="contactIsACharity">
            <e-input
              v-model="acronym"
              label="Acronym"
              :help-id="10"
              data-test="acronym-input"
              @input="setIsDirty(true)"
            />

            <validation-provider
              v-slot="{ errors, valid }"
              name="Charity Number"
              :rules="{ required: true }"
              slim
            >
              <e-input
                v-model="charityNumber"
                label="Charity Number"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                :help-id="11"
                data-test="charity-number-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <e-input
              :value="isUkRegistered"
              label="Is UK Registered?"
              type="checkbox"
              data-test="is-uk-registered-checkbox"
              @input="setIsDirty(true)"
              @change="value => isUkRegistered = value"
            />
          </template>

          <e-input
            v-model="website"
            label="Website"
            data-test="website-input"
            @input="setIsDirty(true)"
          />

          <email-address-manager
            :email-addresses="emailAddresses"
            :type="type"
            @is-dirty="setIsDirty(true)"
          />

          <phone-number-manager
            :phone-numbers="phoneNumbers"
            @is-dirty="setIsDirty(true)"
          />

          <address-manager
            :addresses="addresses"
            @is-dirty="setIsDirty(true)"
          />

          <e-textarea
            v-model="notes"
            label="Notes"
            data-test="notes-textarea"
            @input="setIsDirty(true)"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="contact-form"
            />

            <e-button
              v-if="isExistingContact"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import Account from '@/models/Account'
import AddressManager from './formElements/AddressManager'
import Contact from '@/models/Contact'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import EDialog from '@/components/dialogs/EDialog'
import EmailAddressManager from './formElements/EmailAddressManager'
import PhoneNumberManager from './formElements/PhoneNumberManager'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import Todo from '@/models/Todo'
import Will from '@/models/Will'
import contactIsValid from '@/utils/contactIsValid'
import contactTypes from '@/json_files/contactTypes.json'
import genderTypes from '@/json_files/genderTypes.json'

export default {
  name: 'ContactForm',

  components: {
    AddressManager,
    ConfirmationDialog,
    EDialog,
    EmailAddressManager,
    PhoneNumberManager,
    SaveBar,
    SubmitButton
  },

  props: {
    letterId: {
      type: String,
      default: ''
    },

    contactId: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      id: '',
      type: '',
      addresses: [],
      emailAddresses: [],
      phoneNumbers: [],
      firstName: '',
      middleNames: '',
      lastName: '',
      prefixes: '',
      suffixes: '',
      genderType: '',
      genderIdentity: '',
      jobTitle: '',
      dateOfBirth: '',
      placeOfBirth: '',
      organisationName: '',
      organisationRegisteredNumber: '',
      organisationTerritoryOfIncorporation: '',
      website: '',
      isCharity: false,
      acronym: '',
      isUkRegistered: null,
      charityNumber: '',
      notes: '',

      showDeleteDialog: false,
      showLinkedAccountsDialog: false,

      isDirty: false,
      isExistingContact: true,

      isCurrentSpouse: false,
      isCurrentCivilPartner: false,

      genderTypes,
      contactTypes
    }
  },

  computed: {
    contactIsACharity () {
      return !!(this.type === 'organisation' && this.isCharity)
    },

    will () {
      return Will.query().first()
    },

    accountsLinkedWithThisContact () {
      return Account
        .query()
        .where('supplierId', this.id)
        .get()
    }
  },

  watch: {
    contactIsACharity (newValue) {
      if (!newValue) {
        this.isCharity = false
        this.isUkRegistered = null
        this.charityNumber = ''
        this.acronym = ''
      }
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 3,
      isInitial: true
    })

    if (this.contactId) {
      const contact = Contact.find(this.contactId)

      this.id = this.contactId
      this.type = contact.type
      this.addresses = contact.addresses
      this.emailAddresses = contact.emailAddresses
      this.phoneNumbers = contact.phoneNumbers
      this.firstName = contact.firstName
      this.middleNames = contact.middleNames
      this.lastName = contact.lastName
      this.prefixes = contact.prefixes
      this.suffixes = contact.suffixes
      this.genderType = contact.genderType
      this.genderIdentity = contact.genderIdentity
      this.jobTitle = contact.jobTitle
      this.dateOfBirth = contact.dateOfBirth
      this.placeOfBirth = contact.placeOfBirth
      this.organisationName = contact.organisationName
      this.organisationRegisteredNumber = contact.organisationRegisteredNumber
      this.organisationTerritoryOfIncorporation = contact.organisationTerritoryOfIncorporation
      this.website = contact.website
      this.isCharity = contact.isCharity
      this.acronym = contact.acronym
      this.isUkRegistered = contact.isUkRegistered
      this.charityNumber = contact.charityNumber
      this.notes = contact.notes

      if (this.will.spouseId === contact.id) this.isCurrentSpouse = true
      if (this.will.civilPartnerId === contact.id) this.isCurrentCivilPartner = true
    } else {
      this.id = new Contact().id
      this.isExistingContact = false
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async deleteContact () {
      this.showDeleteDialog = false

      if (this.accountsLinkedWithThisContact.length) {
        this.showLinkedAccountsDialog = true
        return
      }

      await this.$store.dispatch('deleteContacts', [this.id])

      this.$emit('deleted', { name: 'NewContact' })
    },

    async submit () {
      const isValid = await contactIsValid({
        type: this.type,
        addresses: this.addresses,

        ...this.type === 'person' && {
          firstName: this.firstName,
          lastName: this.lastName,
          emailAddresses: this.emailAddresses
        },

        ...this.type !== 'person' && {
          organisationName: this.organisationName
        }
      })

      Contact.insertOrUpdate({
        data: {
          id: this.id,
          type: this.type,
          addresses: this.addresses,
          emailAddresses: this.emailAddresses,
          phoneNumbers: this.phoneNumbers,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          prefixes: this.prefixes,
          suffixes: this.suffixes,
          genderType: this.genderType,
          genderIdentity: this.genderIdentity,
          jobTitle: this.jobTitle,
          dateOfBirth: this.dateOfBirth,
          placeOfBirth: this.placeOfBirth,
          organisationName: this.organisationName,
          organisationRegisteredNumber: this.organisationRegisteredNumber,
          organisationTerritoryOfIncorporation: this.organisationTerritoryOfIncorporation,
          website: this.website,
          isCharity: this.isCharity,
          acronym: this.acronym,
          isUkRegistered: this.isUkRegistered,
          charityNumber: this.charityNumber,
          createdDate: this.createdDate || new Date().toISOString(),
          notes: this.notes,
          isValid
        }
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: 'ContactView',
        params: {
          letterId: this.letterId,
          contactId: this.id
        }
      })

      Todo.update({
        where: ({ title }) => title === 'Import your contacts',
        data: {
          isDone: true
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['contacts', 'todos'],
        message: 'Contact successfully saved'
      })

      await this.$store.dispatch('getAndSetContactDetailsFromBackend')
    }
  }
}
</script>

<style scoped lang="scss">
.linked-accounts-dialog {
  p {
    color: $font-color-one;
    font-size: 1.2rem;
    font-weight: 400;
  }
}
</style>
