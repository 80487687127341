<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete Message"
      @cancel="showDeleteDialog = false"
      @confirm="deleteMessage"
    >
      <p v-if="mailboxType !== 'bin'">
        Are you sure you wish to delete this message? Message will be moved to Bin.
      </p>
    </confirmation-dialog>

    <validation-observer
      v-slot="{ handleSubmit }"
      slim
    >
      <div
        v-if="showView"
        @submit.prevent="handleSubmit(submit)"
      >
        <div class="headline">
          {{ headline }}
        </div>

        <div class="message-wrapper">
          <div class="names-dates">
            <div data-test="sender-name" class="sender-name">{{ sender.name }}</div>
            <div class="to-arrows"> >> </div>
            <div class="to-names">
              <span
                v-for="(recipient, index) in recipients"
                :key="recipient.userId"
                data-test="recipient-name"
              >
                <info-pop
                  v-if="recipient.readDate"
                  :text="`Read: ${recipient.readDate}`"
                  class="no-multiline-space"
                >
                  {{ recipient.name }}
                </info-pop>

                <span v-else>{{ recipient.name }}</span>

                <span v-if="(index + 1) < recipients.length">, </span>
              </span>
            </div>
            <span data-test="sent-date" class="sent-date">{{ sentDate }}</span>
          </div>

          <div>
            <span data-test="subject" class="subject">
              {{ subject }}
            </span>

          </div>

          <div class="to-controls">
            <span class="buttons">
              <e-button
                class="icon-mail-reply1"
                data-test="reply-button"
                @click="replyToSender"
              />

              <e-button
                v-if="recipients.length > 1"
                class="icon-mail-reply-all1"
                data-test="reply-all-button"
                @click="replyToAll"
              />

              <e-button
                class="icon-mail-forward"
                data-test="forward-button"
                @click="forwardMessage"
              />

              <e-button
                class="icon-trash-2"
                data-test="delete-button"
                @click="showDeleteDialog = true"
              />
            </span>
          </div>

          <div v-if="unknowns">
            <label>Unknown Recipients: </label>
            <span>{{ unknowns }}</span>
          </div>

          <div
            v-if="body"
            class="box"
            data-test="message-body"
          >
            {{ body }}
          </div>

          <div v-if="attachments.length" data-test="attachments-element">
            <h2>
              Attachments
            </h2>

            <div class="box">
              <ul data-test="attachments-list">
                <li
                  v-for="attachment in attachments"
                  :key="attachment.id"
                >
                  {{ attachment.name }}

                  <e-button
                    download
                    class="icon-download ghost"
                    data-test="download-file-button"
                    @click="downloadFile(attachment)"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import InfoPop from '@/components/InfoPop'
import createFileActionObject from '@/utils/createFileActionObject'
import decryptFile from '@/utils/decryptFile'
import { format } from 'date-fns'
import { pascalCase } from 'change-case'

export default {
  name: 'ViewMessage',

  components: {
    ConfirmationDialog,
    InfoPop
  },

  props: {
    messageId: {
      type: String,
      default: '',
      required: true
    },

    mailboxType: {
      type: String,
      default: '',
      required: true
    },

    originBox: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showView: false,
      timeoutId: '',
      isRead: null,
      sender: '',
      subject: '',
      body: '',
      recipients: [],
      attachments: [],
      showDeleteDialog: false
    }
  },

  computed: {
    headline () {
      const headlinePrefix = 'SECURE MESSAGE'

      if (this.mailboxType === 'inbox') return `${headlinePrefix} RECEIVED`
      if (this.mailboxType === 'sent') return `${headlinePrefix} SENT`
      return headlinePrefix
    }
  },

  beforeDestroy () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 112,
      isInitial: true
    })

    try {
      const {
        subject,
        body,
        to,
        from,
        date,
        attachments,
        isRead,
        unknowns
      } = await this.$store.dispatch('messages/getMessage', {
        messageId: this.originBox ? `${this.messageId}~${this.originBox}` : this.messageId,
        mailboxType: this.mailboxType
      })

      this.sentDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
      this.subject = subject
      this.body = body
      this.attachments = attachments

      this.isRead = isRead
      this.unknowns = unknowns

      this.sender = {
        userId: from.userId,
        name: `${from.firstName} ${from.lastName}`,
        email: from.email
      }

      this.recipients = to.map(({ userId, firstName, lastName, email, isRead, readDate }) => ({
        userId,
        name: `${firstName} ${lastName}`,
        email,
        isRead,
        ...isRead && { readDate: format(new Date(readDate), 'yyyy-MM-dd HH:mm') }
      }))

      if (this.mailboxType === 'inbox' && !isRead) {
        this.timeoutId = setTimeout(async messageId => {
          try {
            await this.$store.dispatch('messages/setMessageAsRead', this.messageId)
            this.$store.commit('messages/addSessionReadMessageId', this.messageId)
            this.isRead = true
          } catch (error) {
            this.$store.commit('snackbar/update',
              {
                type: 'error',
                message: 'Failed to get update message as read'
              }
            )

            this.$store.dispatch('logError', {
              error,
              fileName: 'Messages > ViewMessage',
              functionName: 'created'
            })
          }
        }, 3000)
      }

      this.showView = true
    } catch (error) {
      this.$store.commit('snackbar/update',
        {
          type: 'error',
          message: 'Failed to get message'
        }
      )

      this.$store.dispatch('logError', {
        error,
        fileName: 'Messages > ViewMessage',
        functionName: 'created'
      })
    }
  },

  methods: {
    replyToSender () {
      this.$router.push({
        name: 'Reply',
        params: {
          messageId: this.originBox ? `${this.messageId}~${this.originBox}` : this.messageId,
          mailboxType: this.mailboxType,
          setCursorInBody: true
        }
      })
    },

    replyToAll () {
      this.$router.push({
        name: 'ReplyAll',
        params: {
          messageId: this.originBox ? `${this.messageId}~${this.originBox}` : this.messageId,
          mailboxType: this.mailboxType,
          setCursorInBody: true
        }
      })
    },

    forwardMessage () {
      this.$router.push({
        name: 'Forward',
        params: {
          messageId: this.originBox ? `${this.messageId}~${this.originBox}` : this.messageId,
          mailboxType: this.mailboxType,
          setCursorInBody: true
        }
      })
    },

    async deleteMessage () {
      try {
        this.showDeleteDialog = false

        await this.$store.dispatch('messages/deleteMessage', {
          messageId: this.originBox ? `${this.messageId}~${this.originBox}` : this.messageId,
          mailboxType: this.mailboxType
        })

        this.$store.commit('messages/addSessionDeletedMessageId', {
          messageId: this.messageId,
          mailboxType: this.mailboxType
        })

        if (this.mailboxType === 'inbox' && !this.isRead) {
          this.$store.commit('messages/addSessionReadMessageId', this.messageId)
        }

        this.$router.push({ name: pascalCase(this.mailboxType) })
      } catch {
        this.$store.commit('snackbar/update',
          {
            type: 'error',
            message: 'Failed to delete message'
          }
        )
      }
    },

    async downloadFile (file) {
      try {
        const fileActionObject = createFileActionObject({
          name: file.name,
          type: 'download'
        })

        this.$store.commit('files/addFileActionObject', fileActionObject)

        const { data: encryptedFile } = await this.$store.dispatch('files/downloadFileFromApi', {
          fileUrl: file.fileUrl,
          progressExecutor: event => fileActionObject.progressUpdator(event),
          cancelExecutor: cancelEvent => (fileActionObject.cancel = cancelEvent),
          source: file.source
        })

        const decryptedFile = await decryptFile({
          encryptedFile,
          decryptKey: file.decryptKey,
          filename: file.name,
          mimeType: file.mimeType
        })

        const url = URL.createObjectURL(decryptedFile, { type: file.fileMimeType })

        const a = document.createElement('a')
        a.href = url
        a.download = file.name
        a.click()

        URL.revokeObjectURL(url)
      } catch (error) {
        this.$store.commit('snackbar/update', { type: 'error', message: error.message })
      }
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 0.6rem;
}

.headline {
  background-color: $nav-bar-background-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 400;
  height: 2.2rem;
  line-height: 2.2rem;
  margin-top: 2.7rem;
  text-align: center;
  width: 100%;
}

.sent-date {
  float: right;
  padding-right: 0.4rem;
}

.names-dates {
  margin-top: 2vh;
}

.to-arrows {
  float: left;
}

.sender-name {
  background-color: #00000073;
  border-radius: $corner;
  float: left;
  margin-left: 0.4rem;
  margin-right: 1rem;
  padding: 0.2rem 2rem;
}

.to-names {
  background-color: rgba(0, 0, 0, 0.4509803922);
  border-radius: 4px;
  float: left;
  margin-left: 1rem;
  max-width: 30rem;
  padding: 0.2rem 2rem;
}

.to-controls {
  margin-bottom: 1.8vh;
}

.subject {
  float: left;
  font-size: 2rem;
  margin-top: 1vh;
  margin-top: 1vh;
  padding-left: 0.3rem;
  width: 75%;
}

.buttons {
  float: right;
  padding-right: 0.4rem;
  padding-top: 1vh;
}

.box {
  background-color: #00000073;
  border: $input-border-width solid $nav-bar-background-color;
  border-radius: $corner;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  float: left;
  margin: 0.2rem 0;
  min-height: 40vh;
  padding: 0.6rem;
  white-space: pre-line;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.no-multiline-space {
  display: table-cell;
}
</style>
