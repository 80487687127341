<template>
  <div class="error-box">
    <h1>{{ errorType }}</h1>

    <div class="message-text">
      {{ appErrorObject.message }}
    </div>

    <e-button @click="removeAppError">
      OK
    </e-button>

    <i v-if="appErrorObject.link">
      {{ appErrorObject.link }}
    </i>
  </div>
</template>

<script>
export default {
  name: 'ErrorBox',

  computed: {
    appErrorObject () {
      return this.$store.state.appError
    },

    errorType () {
      return this.appErrorObject.type
        ? this.appErrorObject.type
        : 'ERROR'
    }
  },

  methods: {
    removeAppError () {
      this.$store.commit('setAppErrorState', {
        status: false,
        message: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.error-box {
  background-color: $main-background-color;
  border: 4px solid $color-error;
  border-radius: 8px;
  color: $color-error;
  display: grid;
  justify-items: center;
  left: 50%;
  padding: 2rem;
  position: fixed;
  row-gap: 1rem;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;

  .message-text {
    white-space: pre-wrap;
  }

  button {
    width: 100%;
  }
}
</style>
