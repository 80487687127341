'use strict'

import { Model } from '@vuex-orm/core'

export default class AssetBeneficiary extends Model {
  static get entity () { return 'assetBeneficiaries' }
  static get primaryKey () { return ['assetId', 'beneficiaryId'] }

  static fields () {
    return {
      assetId: this.attr(null),
      beneficiaryId: this.attr(null)
    }
  }
}
