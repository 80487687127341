import Dashboard from '@/views/Dashboard'
import ForgottenPassword from '@/components/ForgottenPassword'
import FormCreator from '@/components/FormCreator'
import LoadingSpinner from '@/components/LoadingSpinner'
import Login from '@/components/Login'
import Router from 'vue-router'
import SetPassword from '@/components/SetPassword'
import UnsubscribedUser from '@/components/UnsubscribedUser'
import Vue from 'vue'

import accounts from './accounts'
import assets from './assets'
import directory from './directory'
import files from './files'
import help from './help'
import insights from './insights'
import knowledgeBase from './knowledgeBase'
import letters from './letters'
import liabilities from './liabilities'
import messaging from './messaging'
import secrets from './secrets'
import userAccount from './userAccount'
import will from './will'
import wishes from './wishes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    accounts,
    assets,
    directory,
    files,
    help,
    insights,
    knowledgeBase,
    letters,
    liabilities,
    messaging,
    secrets,
    userAccount,
    will,
    wishes,

    {
      path: '/',
      redirect: { name: 'Dashboard' }
    },

    {
      path: '/dashboard',
      name: 'Dashboard',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Dashboard
      },
      meta: { title: 'Dashboard' }
    },

    {
      path: '/formCreator',
      name: 'Form Creator',
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormCreator
      },
      meta: { title: 'Form Creator' }
    },

    {
      path: '/login',
      name: 'Login',
      meta: { title: 'Login' },
      components: {
        appClosedLayout: Login,
        default: Dashboard
      }
    },

    {
      path: '/admin/login',
      name: 'Login',
      meta: { title: 'Admin Login' },
      props: {
        appClosedLayout: () => ({
          useAdminLogin: true
        })
      },
      components: {
        appClosedLayout: Login,
        default: Dashboard
      }
    },

    {
      path: '/unsubscribed',
      name: 'UnsubscribedUser',
      meta: { title: 'Unsubscribed User' },
      components: {
        appClosedLayout: UnsubscribedUser,
        default: Dashboard
      }
    },

    {
      path: '/forgotten-password',
      name: 'ForgottenPassword',
      meta: { title: 'Forgotten Password' },
      components: {
        appClosedLayout: ForgottenPassword,
        default: Dashboard
      }
    },

    {
      path: '/set-password/:code?',
      name: 'SetPassword',
      meta: { title: 'Set Password' },
      components: {
        appClosedLayout: SetPassword,
        default: Dashboard
      },
      props: {
        appClosedLayout: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  try {
    document.title = to.meta.title

    const store = router.app.$store

    if (to.path === '/support') return store.commit('setShowFeedbackBar', true)

    if (store && store.state.showAppOpen) {
      try {
        store.dispatch('messages/setUnreadMessageIds')
      } catch {}

      if (!store.getters.user.isValid && !['Profile', 'Logout'].includes(to.name)) {
        return next({ name: 'Profile' })
      }
    }

    return next()
  } catch (error) {
    // console.log('in error', error)
  }
})

export default router
