var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedFileId)?_c('confirmation-dialog',{on:{"confirm":_vm.handleDeleteConfirmation,"cancel":function($event){_vm.selectedFileId = ''}}}):_vm._e(),_c('e-table',{attrs:{"headers":_vm.headers,"rows":_vm.fileList,"data-test":"files-table"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [(item.fileUrl)?_c('div',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-download ghost",attrs:{"download":"","data-test":"download-button"},on:{"click":function($event){return _vm.downloadFile({
            fileName: item.fileName,
            fileMimeType: item.fileMimeType,
            fileUrl: item.fileUrl,
            source: item.source,
            encryptDecryptKey: item.encryptDecryptKey
          })}}}),(_vm.allowDelete)?_c('e-button',{staticClass:"icon-trash-2 ghost",attrs:{"data-test":"delete-file-button"},on:{"click":function($event){_vm.selectedFileId = item.id}}}):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }