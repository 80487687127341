'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ jwt, armoredPublicKey, encryptedArmoredPrivateKey, securityKeyObjects }) => {
  const response = await axios({
    method: 'put',
    url: `${VUE_APP_GATEWAY_URL}/idp/create-credential`,
    headers: { Authorization: jwt },
    data: JSON.stringify({
      ...armoredPublicKey && { armoredPublicKey },
      ...encryptedArmoredPrivateKey && { encryptedArmoredPrivateKey },
      securityKeyObjects
    }),
    timeout: 8000
  })

  return response.data.jwt
}
