'use strict'

import Todo from '../models/Todo'
import User from '../models/User'
import Vue from 'vue'
import attemptApiCall from '../utils/attemptApiCall'
import encryptString from '../utils/encryptString'
import deleteScheduledTodoEmail from '../apis/emailScheduler/deleteScheduledTodoEmail'
import getSystemTodos from '../apis/cms/getSystemTodos'
import putScheduledTodoEmail from '../apis/emailScheduler/putScheduledTodoEmail'
import putVaultEntity from '../apis/vault/putVaultEntity'

export default {
  namespaced: true,

  getters: {
    systemTodoIsDoneArray: () => User.query().first().systemTodoIsDoneArray
  },

  actions: {
    persistTodosToVault: async ({ commit, dispatch, rootState, rootGetters }, { message, updatedTodo } = {}) => {
      try {
        const filteredTodos = Todo
          .query()
          .where('type', value => value !== 'system')
          .get()
          .reduce((accumulator, currentTodo) => ({
            ...accumulator,
            [currentTodo.id]: currentTodo
          }), {})

        const todoEntity = {
          $connection: 'entities',
          $name: 'todos',
          data: filteredTodos
        }

        const encryptedObject = await encryptString({
          unencryptedString: JSON.stringify(todoEntity),
          publicKeyObjects: rootGetters.publicKeyObjects,
          armoredPrivateKey: rootState.armoredPrivateKey
        })

        await attemptApiCall(
          putVaultEntity,
          {
            userId: rootState.userId,
            entityType: 'todos',
            object: encryptedObject,
            jwt: sessionStorage.getItem('jwt')
          }
        )

        commit('removeObjectTypesFromApiUnsynced', ['todos'], { root: true })

        if (updatedTodo?.id && (updatedTodo?.type !== 'system')) {
          updatedTodo?.emailTodo
            ? await attemptApiCall(putScheduledTodoEmail, { id: updatedTodo.id, message: updatedTodo.title, todoTimestamp: (new Date(updatedTodo.deadlineDate || 0)).getTime() })
            : await attemptApiCall(deleteScheduledTodoEmail, updatedTodo.id)
        }

        if (message) {
          commit('snackbar/update', { type: 'success', message }, { root: true })
        }
      } catch (error) {
        commit('addObjectTypesToApiUnsynced', 'todos', { root: true })

        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        commit('snackbar/update',
          {
            type: 'warning',
            message: 'Your item was saved locally, but failed to sync to our servers'
          },
          { root: true }
        )

        dispatch('logError',
          {
            error,
            fileName: 'store',
            functionName: 'persistDocumentRecordToVault'
          },
          { root: true }
        )
      }
    },

    getSystemTodos: async ({ commit }) => {
      try {
        const response = await getSystemTodos({
          jwt: sessionStorage.getItem('jwt')
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setTodos: async ({ getters, dispatch }) => {
      const systemTodos = await dispatch('getSystemTodos')

      Todo.insert({
        data: systemTodos.map(todo => ({
          ...todo,
          type: 'system',
          isDone: getters.systemTodoIsDoneArray.includes(todo.id)
        }))
      })
    },

    updateTodoIsDone: async ({ dispatch }, { id, isDone, type }) => {
      Todo.update({
        data: { id, isDone }
      })

      if (type === 'system') {
        const user = User.query().first()
        const isCurrentlyInArray = user.systemTodoIsDoneArray.includes(id)

        const updatedArray = isCurrentlyInArray
          ? user.systemTodoIsDoneArray.filter(todoId => todoId !== id)
          : user.systemTodoIsDoneArray.concat(id)

        User.update({
          where: User.query().first().id,
          data: {
            systemTodoIsDoneArray: updatedArray
          }
        })

        await dispatch('persistRecordToVault',
          { entityTypes: ['user'] },
          { root: true }
        )
      }

      await dispatch('persistTodosToVault')
    }
  },

  mutations: {
    setTodos: (state, value) => Vue.set(state, 'todos', value)
  }
}
