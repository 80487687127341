'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Credential extends Model {
  static get entity () { return 'credentials' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      credentialName: this.string(),
      username: this.string(),
      password: this.string(),
      serviceWebsite: this.string(),
      description: this.string(),
      createdDate: this.string()
    }
  }
}
