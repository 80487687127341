'use strict'

import Contact from './Contact'
import Group from './Group'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Beneficiary extends Model {
  static get entity () { return 'beneficiaries' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      percentageShare: this.number(),
      beneficiaryDeadShareGoesToTheirChildren: this.boolean(true),

      contactId: this.attr(null),
      contact: this.belongsTo(Contact, 'contactId'),

      groupId: this.attr(null),
      group: this.belongsTo(Group, 'groupId'),

      contactSubstituteId: this.attr(null),
      contactSubstitute: this.belongsTo(Contact, 'contactSubstituteId'),

      groupSubstituteId: this.attr(null),
      groupSubstitute: this.belongsTo(Group, 'groupSubstituteId')
    }
  }

  get hasSubstitute () {
    return !!this.groupSubstituteId || !!this.contactSubstituteId
  }

  get substitute () {
    return this.hasSubstitute
      ? this.groupSubstitute || this.contactSubstitute
      : null
  }

  get type () {
    return this.contactId ? 'contact' : 'group'
  }

  get name () {
    return this.type === 'group'
      ? this.group.text
      : this.contact.fullName
  }

  get selectText () {
    return this.type === 'group'
      ? this.group.selectText
      : this.contact.selectText
  }

  get groupOrContactId () {
    return this.type === 'group'
      ? this.groupId
      : this.contactId
  }
}
