'use strict'

import Asset from './Asset'
import Contact from './Contact'
import File from './File'
import LiabilityAsset from './LiabilityAsset'
import LiabilityCoOwnerContact from './LiabilityCoOwnerContact'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'
import { capitalCase } from 'change-case'

export default class Liability extends Model {
  static get entity () { return 'liabilities' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      name: this.string(),
      type: this.string(),
      subType: this.string(),
      referenceId: this.string(),
      contractEndDate: this.string(),
      notes: this.string(),
      description: this.string(),
      createdDate: this.string(),

      valuationDate: this.string(),
      recurringFrequency: this.string(),
      currency: this.string('GBP'),
      value: this.number(),
      ownershipPercentage: this.number(),
      payout: this.number(),

      supplierId: this.attr(null),
      supplier: this.belongsTo(Contact, 'supplierId'),

      coOwners: this.belongsToMany(Contact, LiabilityCoOwnerContact, 'liabilityId', 'contactId'),
      assets: this.belongsToMany(Asset, LiabilityAsset, 'liabilityId', 'assetId'),
      files: this.morphMany(File, 'ownerId', 'ownerType')
    }
  }

  get selectText () {
    return `${capitalCase(this.type)} - ${capitalCase(this.subType)} - ${this.name}`
  }
}
