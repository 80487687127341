'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class File extends Model {
  static get entity () { return 'files' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      reference: this.string(),
      referenceDate: this.string(),
      description: this.string(),
      uploadDate: this.string(),
      etag: this.string(),
      source: this.string(),

      fileUrl: this.string(),
      fileName: this.string(),
      fileSize: this.string(),
      fileMimeType: this.string(),
      fileLastModifiedDate: this.string(),

      encryptDecryptKey: this.string(),

      ownerId: this.attr(null),
      ownerType: this.attr(null),
      owner: this.morphTo('ownerId', 'ownerType')
    }
  }
}
