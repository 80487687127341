'use strict'

import axios from 'axios'

const { VUE_APP_ORDERS_URL } = process.env

export default async invoiceId => axios({
  method: 'GET',
  headers: {
    Authorization: sessionStorage.getItem('jwt')
  },
  url: `${VUE_APP_ORDERS_URL}/invoice/${invoiceId}`,
  timeout: 8000
})
