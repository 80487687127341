<template>
  <div
    class="avatar"
    :style="{ backgroundImage: `url('${avatar}')` }"
    @click="handleClickEvent($event)"
  />
</template>

<script>
export default {
  name: 'AvatarButton',

  props: {
    avatar: {
      type: String,
      default: null
    }
  },

  emits: ['click'],

  methods: {
    handleClickEvent (mouseEvent) {
      this.$emit('click', mouseEvent)
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  background-color: $input-background-color;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}
</style>
