<template>
  <label class="label" :data-test="dataTest">
    <div v-if="label" class="question-text">
      {{ label }}

      <span
        v-if="helpId"
        class="icon icon-help-circle"
        @click.prevent="setHelp"
      />

      <span
        v-if="neededForIsValid && !success"
        class="icon icon-alert-triangle"
        :style="{ color: 'orange' }"
      />
    </div>

    <p
      v-if="description"
      v-html="purify(description)"
    />

    <div class="background-div">
      <select
        v-model="mutableValue"
        class="select"
        :class="{ invalid: isRequired && !success }"
        v-bind="$attrs"
      >
        <option value="">{{ defaultOptionText }}</option>
        <option
          v-for="(item, index) in items"
          :key="index"
          :value="item.value"
          :disabled="item.isDisabled"
        >
          {{ item.text }}
        </option>
      </select>

      <span class="down-chevron" />
    </div>

    <span
      v-if="errorMessages.length"
      class="error-text"
      data-test="error-message"
    >
      {{ errorMessages[0] }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'ESelect',

  inheritAttrs: false,

  props: {
    dataTest: {
      type: String,
      default: ''
    },

    success: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    defaultOptionText: {
      type: String,
      default: 'Select an option'
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    neededForIsValid: {
      type: Boolean,
      default: false
    },

    helpId: {
      type: [String, Number],
      default: null
    },

    helpFunction: {
      type: Function,
      default: ({ store, id }) => store.dispatch('findAndSetHelpObject', { id })
    }
  },

  emits: [
    'change',
    'input'
  ],

  computed: {
    mutableValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    }
  },

  methods: {
    setHelp () {
      this.helpFunction({ id: this.helpId, store: this.$store })
    }
  }
}
</script>

<style scoped lang="scss">
label {
  display: block;
}

p {
  margin: 0 0 1em;
}

.icon {
  margin: 0 0.1rem;
}

.label-text {
  display: block;
}

.background-div {
  background-color: $input-background-color;
  border-radius: $corner;
  // margin: 0.5rem 0;
}

.select {
  appearance: none;
  background-color: transparent;
  border: $input-border-width $input-border-style $input-border-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $input-font-color;
  height: 2.2rem;
  padding: 0.2rem 0.4rem;
  position: relative;
  width: 100%;
  z-index: 1;

  &.invalid {
    border-color: $color-error;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    &.invalid {
      border-color: $color-error;
    }
  }

  & + .down-chevron {
    border: 1px solid $input-border-color;
    border-left: 0;
    border-radius: 2px;
    border-top: 0;
    display: inline-block;
    height: 10px;
    left: -1em;
    margin: -10px;
    position: relative;
    top: -14px;
    transform: rotate(45deg);
    width: 10px;
  }

  option {
    background-color: $input-background-color;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    & + .down-chevron {
      border-bottom-color: $input-border-color-focus;
      border-right-color: $input-border-color-focus;
    }
  }

  &:hover {
    border-color: $input-border-color-hover;
    box-shadow: none;
    outline: none;

    & + .down-chevron {
      border-bottom-color: $input-border-color-focus;
      border-right-color: $input-border-color-focus;
    }
  }

  &:disabled {
    background-color: $input-background-color-disabled;

    &:hover {
      border-color: $input-border-color;
    }
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}
</style>
