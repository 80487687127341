'use strict'

// lowercase the headers
// remove hyphen, underscore, whitespace
// remove duplicates from array

import contactPropertyMatchers from '../json_files/contactPropertyMatchers.json'
import levenshtein from 'js-levenshtein'
import parse from 'csv-parse/lib/sync'
import { createId } from './'

const joinAddressElements = ({ street, city, postcode }) => `${street} ${city} ${postcode}`

const createAddressObject = address => ({
  id: createId(),
  isPref: false,
  placeResult: {
    formatted_address: address
  },
  returnData: {}
})

const createPhoneNumberObject = phoneNumber => ({
  id: createId(),
  isPref: false,
  type: '',
  value: phoneNumber
})

const createEmailAddressObject = email => ({
  id: createId(),
  isPref: false,
  value: email
})

const getBestMatchSearchStringIndexObject = ({ searchStrings, currentHeader, index }) => searchStrings
  .reduce((textAccumulator, currentText) => {
    const score = levenshtein(currentText, currentHeader)

    return textAccumulator.score < score ? textAccumulator : { score, index }
  }, { score: 10, index: null })

const getBestMatchHeaderIndexObject = ({ headers, searchStrings }) => headers
  .map(header => header.toLowerCase())
  .map(header => header.replace(/[^a-zA-Z0-9]/g, ''))
  .reduce((headerAccumulator, currentHeader, index) => {
    const bestMatchSearchStringIndexObject = getBestMatchSearchStringIndexObject({ searchStrings, currentHeader, index })

    return headerAccumulator.score < bestMatchSearchStringIndexObject.score
      ? headerAccumulator
      : {
          score: bestMatchSearchStringIndexObject.score,
          index: bestMatchSearchStringIndexObject.index
        }
  }, { score: 10, index: null })

const createCsvColumnIndexLookupObject = headers => contactPropertyMatchers
  .reduce((propertyAccumulator, { propertyName, searchStrings, tolerance }) => {
    const headerIndexObject = getBestMatchHeaderIndexObject({ headers, searchStrings })

    return {
      ...propertyAccumulator,
      [propertyName]: headerIndexObject.score <= tolerance
        ? headerIndexObject.index
        : null
    }
  }, {})

const createContactObject = ({ record, csvColumnIndexLookupObject }) => {
  const contactObject = {
    firstName: record[csvColumnIndexLookupObject.firstName],
    middleNames: record[csvColumnIndexLookupObject.middleNames],
    lastName: record[csvColumnIndexLookupObject.lastName],
    prefixes: record[csvColumnIndexLookupObject.prefixes],
    suffixes: record[csvColumnIndexLookupObject.suffixes],
    genderType: record[csvColumnIndexLookupObject.genderType],
    jobTitle: record[csvColumnIndexLookupObject.jobTitle],
    organisationName: record[csvColumnIndexLookupObject.organisationName],
    addresses: ['address1', 'address2']
      .filter(address => record[csvColumnIndexLookupObject[address]])
      .reduce((accumulator, currentAddress) => accumulator.includes(record[csvColumnIndexLookupObject[currentAddress]])
        ? accumulator
        : [...accumulator, record[csvColumnIndexLookupObject[currentAddress]]]
      , [])
      .map(address => createAddressObject(address)),
    phoneNumbers: ['phone1', 'phone2', 'phone3', 'phone4', 'phone5', 'phone6']
      .filter(phoneNumber => record[csvColumnIndexLookupObject[phoneNumber]])
      .reduce((accumulator, currentPhoneNumber) => accumulator.includes(record[csvColumnIndexLookupObject[currentPhoneNumber]])
        ? accumulator
        : [...accumulator, record[csvColumnIndexLookupObject[currentPhoneNumber]]]
      , [])
      .map(phoneNumber => createPhoneNumberObject(phoneNumber)),
    emailAddresses: ['email1', 'email2', 'email3']
      .filter(email => record[csvColumnIndexLookupObject[email]])
      .reduce((accumulator, currentEmail) => accumulator.includes(record[csvColumnIndexLookupObject[currentEmail]])
        ? accumulator
        : [...accumulator, record[csvColumnIndexLookupObject[currentEmail]]]
      , [])
      .map(email => createEmailAddressObject(email))
  }

  if (record[csvColumnIndexLookupObject.addressStreet]) {
    const address = joinAddressElements({
      street: record[csvColumnIndexLookupObject.addressStreet],
      city: record[csvColumnIndexLookupObject.addressCity],
      postcode: record[csvColumnIndexLookupObject.addressPostCode]
    })

    contactObject.addresses.push(createAddressObject(address))
  }

  return contactObject
}

const getRecordsAndHeader = csv => {
  const records = parse(csv, {
    columns: false,
    skip_empty_lines: true,
    trim: true
  })

  const headers = records[0]
  records.shift()

  return { records, headers }
}

export default csv => {
  const { records, headers } = getRecordsAndHeader(csv)
  const csvColumnIndexLookupObject = createCsvColumnIndexLookupObject(headers)

  return records.map(record => createContactObject({ record, csvColumnIndexLookupObject }))
}
