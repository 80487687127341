'use strict'
import Help from '@/views/Help/Help'
import HelpDefault from '@/views/Help/HelpDefault'
import HelpCategoryArticles from '@/views/Help/HelpCategoryArticles'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  path: '/help',
  name: 'Help',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Help
  },
  children: [
    {
      path: 'search/:searchString?',
      name: 'HelpSearchResult',
      components: {
        appClosedLayout: LoadingSpinner,
        default: HelpCategoryArticles
      },
      props: {
        default: route => ({
          isSearch: true,
          searchString: route.params.searchString
        })
      },
      meta: { title: 'Help Search Result' }
    },
    {
      path: '',
      name: 'HelpDefault',
      components: {
        appClosedLayout: LoadingSpinner,
        default: HelpDefault
      },
      meta: { title: 'Help' }
    }
  ]
}
