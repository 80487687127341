<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn animate__faster"
  >
    <div class="container">
      <h2>Complete Your Details</h2>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="user-profile-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <div class="connect-input">
            <validation-provider
              v-slot="{ errors, valid }"
              name="First Name"
              rules="required"
              tag="div"
            >
              <e-input
                v-model="firstName"
                label="First Name"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="first-name-input"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Last Name"
              rules="required"
              tag="div"
            >
              <e-input
                v-model="lastName"
                label="Last Name"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="last-name-input"
              />
            </validation-provider>
          </div>

          <div class="connect-input">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Date of Birth"
              :rules="{ required: true, is_not_over_150_years_ago: true, is_past_date: true }"
              tag="div"
            >
              <e-input
                v-model="dateOfBirth"
                label="Date of Birth"
                type="date"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="date-of-birth-input"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Gender"
              rules="required"
              slim
            >
              <e-select
                v-model="genderType"
                :items="genderTypes"
                label="Gender"
                :is-required="true"
                :error-messages="errors"
                :success="valid"
                data-test="gender-type-select"
              />
            </validation-provider>
          </div>

          <address-manager
            :addresses="addresses"
            :address-required="true"
          />

          <submit-button
            :form-is-valid="formIsValid"
            form="user-profile-form"
            button-text="Submit my details"
            button-style="one"
            button-type="single"
            class="submit-button"
          />
        </form>
      </validation-observer>
    </div>
  </transition>
</template>

<script>
import AddressManager from '@/components/forms/formElements/AddressManager'
import SubmitButton from '@/components/buttons/SubmitButton'
import getAvatar from '@/apis/idp/getAvatar'
import genderTypes from '@/json_files/genderTypes.json'

export default {
  name: 'UserSetupProfile',

  components: {
    AddressManager,
    SubmitButton
  },

  inheritAttrs: false,

  props: {
    avatarUrl: {
      type: String,
      default: ''
    }
  },

  emits: [
    'save-user-profile'
  ],

  data () {
    return {
      firstName: '',
      lastName: '',
      genderType: '',
      dateOfBirth: '',
      addresses: [],
      avatar: '',
      genderTypes
    }
  },

  async created () {
    const user = this.$store.getters.user
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.addresses = user.addresses

    this.avatar = await getAvatar(this.avatarUrl)
  },

  methods: {
    submit () {
      this.$emit('save-user-profile', {
        firstName: this.firstName,
        lastName: this.lastName,
        genderType: this.genderType,
        addresses: this.addresses,
        dateOfBirth: this.dateOfBirth,
        avatar: this.avatar
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  h2 {
    margin-bottom: 2rem;
  }

  .submit-button {
    text-transform: uppercase;
    width: 100%;
  }
}
</style>
