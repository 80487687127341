<template>
  <div>
    <h1>Uploaded Files</h1>

    <files-table
      :files="files"
      :show-owner="true"
      @delete="deleteFile"
    />
  </div>
</template>

<script>
import File from '@/models/File'
import FilesTable from '@/components/FilesTable'

export default {
  name: 'UploadedFiles',

  components: {
    FilesTable
  },

  computed: {
    files () {
      return File
        .query()
        .with('owner')
        .get()
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 81,
      isInitial: true
    })
  },

  methods: {
    async deleteFile (id) {
      try {
        const file = File.find(id)
        File.delete(id)

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['files']
        })

        await this.$store.dispatch('files/deleteFileFromApi', { fileId: id, source: file.source })

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'File successfully deleted'
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UploadedFiles',
          functionName: 'deleteFile'
        })
      }
    }
  }
}
</script>
