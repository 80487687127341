<template>
  <div class="accordion">
    <div class="show-menu" @click="toggleMenu">
      <span>
        MENU
        <span
          class="icon-chevron"
          :class="[showCategories ? 'icon-chevron-down' : 'icon-chevron-up']"
        />
      </span>
    </div>

    <transition
      enter-class="animate__animated animate__slideInDown"
      leave-class="animate__animated animate__slideOutUp"
    >
      <span v-if="showCategories">
        <accordion-item
          v-for="category in mutableCategoryObjects"
          :key="category.id"
          :category="category"
          :display-is-valid="displayIsValid"
          :display-sub-items-size="displaySubItemsSize"
          @category-clicked="toggleCategoryIsActive"
          @sub-category-clicked="closeOtherCategories"
          @toggle-menu="toggleMenu"
        />
      </span>
    </transition>
  </div>
</template>

<script>
import AccordionItem from './AccordionItem'

export default {
  name: 'Accordion',

  components: {
    AccordionItem
  },

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },

    displaySubItemsSize: {
      type: Boolean,
      default: false
    },

    displayIsValid: {
      type: Boolean,
      default: false
    },

    openFirstSection: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mutableCategoryObjects: [],
      showCategories: false
    }
  },

  computed: {
    isSmall () {
      return this.$store.state.isSmall
    }
  },

  watch: {
    items: {
      handler (newCategoryObjects, oldCategoryObjects) {
        this.mutableCategoryObjects = newCategoryObjects

        const newActive = newCategoryObjects.find(({ isActive }) => isActive)

        if (!newActive) {
          const oldActive = oldCategoryObjects.find(({ isActive }) => isActive)
          if (oldActive) {
            this.mutableCategoryObjects
              .find(({ id }) => id === oldActive.id)
              .isActive = true
          }
        }
      },

      deep: true
    },

    isSmall () {
      this.showCategories = !this.isSmall
    }
  },

  created () {
    if (!this.isSmall) this.showCategories = true

    this.mutableCategoryObjects = this.items.map(category => category)

    if (this.openFirstSection) {
      this.mutableCategoryObjects[0].isActive = true
    }

    if (this.$route.params.categoryId) {
      this.toggleCategoryIsActive({ id: this.$route.params.categoryId })
    }
  },

  methods: {
    toggleMenu () {
      this.showCategories = !this.showCategories
    },

    toggleCategoryIsActive (category) {
      this.mutableCategoryObjects
        .find(({ id }) => id === category.id)
        .isActive = !category.isActive
    },

    closeOtherCategories (id) {
      this.mutableCategoryObjects
        .filter(category => category.id !== id)
        .forEach(category => (category.isActive = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  .show-menu {
    align-items: center;
    background-color: $input-background-color;
    border-bottom: $border-width solid $border-color-one;
    color: $input-font-color;
    display: flex;
    font-size: 1.2rem;
    height: 3rem;
    justify-content: center;
  }
}

@media screen and (min-width: $media-break-1) {
  .accordion {
    .show-menu {
      display: none;
    }
  }
}
</style>
