<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="documentLocked"
      document-type="travelConsentChildren"
    />

    <dynamic-form
      v-else
      :json-file="jsonFile"
      @submit="createPreview"
    />
  </span>
</template>

<script>
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DynamicForm from '@/components/DynamicForm'
import jsonFile from '@/json_files/7iMQuestionnaire.json'
import DocumentRecord from '@/models/DocumentRecord'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

export default {
  name: 'Questionnaire7iM',

  components: {
    DocumentPreviewDialog,
    DynamicForm
  },

  data () {
    return {
      jsonFile,

      locale: '',
      version: '',
      template: '',

      documentLocked: false,
      documentPreview: '',

      showDocumentPreviewDialog: false
    }
  },

  methods: {
    async createPreview (propertiesObject) {
      try {
        const user = this.$store.getters.user

        const context = {
          title: this.jsonFile.title,
          createdDate: new Date(),
          user: {
            firstName: user.firstName,
            middleNames: user.middleNames,
            lastName: user.lastName
          },

          ...propertiesObject
        }

        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context,
          locale: 'en-GB',
          version: 0,
          type: this.jsonFile.id
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = this.purify(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'Questionnaire7iM',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: `7iM Questionnaire ${new Date()}`,
          locale: this.locale,
          version: this.version,
          type: 'questionnaire7iM',
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
          DocumentRecord.insert({ data: documentRecord })
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        // this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'Questionnaire7iM',
          functionName: 'create'
        })
      }
    }
  }
}
</script>
