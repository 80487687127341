<template>
  <div>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete Monetary Debt"
      data-test="delete-monetary-debt-dialog"
      @cancel="showDeleteDialog = false"
      @confirm="deleteMonetaryDebt"
    />

    <div>
      <div class="header">
        <h1>Monetary Debt</h1>

        <e-button
          data-test="close-dialog-button"
          class="close-button icon-x-circle ghost"
          @click="$emit('closed')"
        />
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form class="spaced" @submit.prevent="handleSubmit(submit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Debtor"
            rules="required"
            tag="div"
          >
            <e-select
              v-model="selectedContactOrGroupId"
              :items="eligibleContactsAndGroupsAsSelectOptions"
              label="Debtor"
              default-option-text="Select Debtor"
              :error-messages="errors"
              :success="valid"
              data-test="debtor-select"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Debt Value"
            rules="required|min_value:1"
            tag="div"
          >
            <e-input
              v-model="amount"
              label="Monetary Debt Value"
              type="number"
              :error-messages="errors"
              :success="valid"
              data-test="debt-value-input"
              prefix="£"
              @focus="amount = amount || null"
            />
          </validation-provider>

          <fieldset data-test="released-from-debt-element">
            <legend>Forgivable?</legend>

            <e-input
              :value="isForgivable"
              :radio-value="true"
              type="radio"
              label="Yes"
              data-test="is-forgivable-radio-yes"
              @change="isForgivable = $event"
            />

            <e-input
              :value="isForgivable"
              :radio-value="false"
              type="radio"
              label="No"
              data-test="is-forgivable-radio-no"
              @change="isForgivable = $event"
            />
          </fieldset>

          <confirmation-buttons
            confirm-button-text="Save"
            confirm-button-type="submit"
            cancel-button-text="Delete"
            @cancel="showDeleteDialog = true"
          />
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Beneficiary from '@/models/Beneficiary'
import ConfirmationButtons from '@/components/buttons/ConfirmationButtons'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import Contact from '@/models/Contact'
import Group from '@/models/Group'
import MonetaryDebt from '@/models/MonetaryDebt'
import Will from '@/models/Will'

export default {
  name: 'MonetaryDebtForm',

  components: {
    ConfirmationButtons,
    ConfirmationDialog
  },

  props: {
    monetaryDebtId: {
      type: String,
      default: ''
    }
  },

  emits: [
    'submitted',
    'closed'
  ],

  data () {
    return {
      showDeleteDialog: false,
      amount: 0,
      selectedContactOrGroupId: '',
      isForgivable: false
    }
  },

  computed: {
    monetaryDebts () {
      return MonetaryDebt
        .query()
        .with('debtor')
        .with('debtor.contact')
        .with('debtor.group')
        .all()
    },

    eligibleContactsAndGroups () {
      return [...Group.all(), ...Contact.all()]
        .filter(({ id }) => {
          if (id === this.selectedContactOrGroupId) return true

          return !this.monetaryDebts
            .map(({ debtor }) => debtor.groupOrContactId)
            .includes(id)
        })
    },

    eligibleContactsAndGroupsAsSelectOptions () {
      return this.eligibleContactsAndGroups
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  created () {
    if (this.monetaryDebtId) {
      const { id, amount, isForgivable, debtor } = MonetaryDebt
        .query()
        .whereId(this.monetaryDebtId)
        .with('debtor')
        .first()

      this.id = id
      this.debtorId = debtor.id
      this.amount = amount
      this.isForgivable = isForgivable
      this.selectedContactOrGroupId = debtor.groupOrContactId
    } else {
      this.id = new MonetaryDebt().id
    }
  },

  methods: {
    async deleteMonetaryDebt () {
      this.showDeleteDialog = false
      this.$emit('submitted')

      // Beneficiary.delete(this.selectedContactOrGroupId)
      Beneficiary.delete(MonetaryDebt.find(this.monetaryDebtId).debtorId)
      MonetaryDebt.delete(this.id)

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['monetaryDebts', 'beneficiaries'],
        message: 'Deleted'
      })
    },

    async submit () {
      this.$emit('submitted')

      const beneficiaryType = this.eligibleContactsAndGroups
        .find(({ id }) => id === this.selectedContactOrGroupId)
        ?.type

      const isGroup = beneficiaryType === 'group'

      MonetaryDebt.insertOrUpdate({
        data: {
          id: this.id,
          amount: this.amount,
          isForgivable: this.isForgivable,
          willId: Will.query().first().id,
          debtor: {
            ...this.debtorId && { id: this.debtorId },
            groupId: isGroup ? this.selectedContactOrGroupId : null,
            contactId: isGroup ? null : this.selectedContactOrGroupId,
            type: beneficiaryType
          }
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['monetaryDebts', 'beneficiaries'],
        message: 'Saved'
      })
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  padding-bottom: 1rem;
}

.close-button {
  align-self: start;
  padding: 0;
}
</style>
