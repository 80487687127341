<template>
  <span>
    <validation-observer
      v-if="isEdit"
      v-slot="{ handleSubmit, valid: formIsValid }"
      slim
    >
      <form
        class="card"
        :class="[status, { highlight: todo.highlight }, { clickable: todo.path }]"
        @submit.prevent="handleSubmit(updateTodo)"
        @click="goToTask"
      >
        <div class="title">
          <div>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Title"
              :rules="{ required: true }"
            >
              <e-input
                v-model="title"
                :error-messages="errors"
                :success="valid"
                placeholder="Title"
              />
            </validation-provider>
          </div>
        </div>

        <div class="body">
          <div>
            <e-input
              v-model="body"
              placeholder="Body"
            />
          </div>
        </div>

        <div
          class="email"
        >
          <e-input
            v-if="(todo.type !== 'system') && !todo.isDone"
            :value="emailTodo"
            type="checkbox"
            label="Email me this todo on the date I have set (including the title)"
            @change="value => emailTodo = value"
          />
        </div>

        <validation-provider
          v-if="(todo.type !== 'system') && !todo.isDone"
          v-slot="{ errors, valid }"
          name="Date"
          :rules="{ ...emailTodo && { required: emailTodo, is_future_date: true } }"
          class="deadline-date"
          tag="div"
        >
          <e-input
            v-model="deadlineDate"
            :error-messages="errors"
            :success="valid"
            type="date"
          />
        </validation-provider>

        <div class="save-button">
          <e-button
            type="submit"
            :disabled="!formIsValid"
          >
            Save
          </e-button>
        </div>
      </form>
    </validation-observer>

    <div
      v-else
      class="card"
      :class="[status, { highlight: todo.highlight }, { clickable: todo.path }]"
      @click="goToTask"
    >
      <div class="title">
        <h1>{{ todo.title }}</h1>
      </div>

      <div class="body">
        <p>{{ todo.body }}</p>
      </div>

      <div class="email">
        <p v-if="(todo.type !== 'system') && !todo.isDone">
          {{ todo.emailTodo ? 'A scheduled email reminder will be sent to me on the deadline date set' : '' }}
        </p>
      </div>

      <div class="actions">
        <span
          v-if="!todo.isDone"
          class="action-icon icon-check-circle"
          @click.stop="updateCardStatus"
        />

        <span
          v-if="(todo.type !== 'system') && !todo.isDone"
          class="action-icon icon-edit"
          @click="isEdit = true"
        />

        <span
          v-if="todo.type !== 'system'"
          class="action-icon icon-trash-2"
          @click.stop="deleteTodo"
        />
      </div>

      <div
        v-if="todo.type !== 'system'"
        class="deadline-date"
      >
        <div v-if="!todo.isDone">
          <div>
            <span v-if="todo.deadlineDate">
              Deadline Date:
              <span :class="{ warning: isPastDeadline }">
                {{ getFormattedDate(todo.deadlineDate) }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { format, isAfter, parseISO } from 'date-fns'

export default {
  name: 'TaskCard',

  props: {
    todo: {
      type: Object,
      default: () => {}
    },

    status: {
      type: String,
      default: 'ongoing'
    },

    startInEditMode: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'card-status-changed',
    'update',
    'delete'
  ],

  data () {
    return {
      title: '',
      body: '',
      deadlineDate: '',
      emailTodo: false,
      isEdit: false
    }
  },

  computed: {
    isPastDeadline () {
      return isAfter(new Date(), parseISO(this.todo.deadlineDate))
    }
  },

  created () {
    this.title = this.todo.title
    this.body = this.todo.body
    this.deadlineDate = this.todo.deadlineDate
    this.emailTodo = this.todo.emailTodo
    this.isEdit = this.startInEditMode
  },

  methods: {
    goToTask () {
      if (this.todo.isExternal) {
        window.open(this.todo.path, '_blank')
        return
      }

      if (this.todo.path) this.$router.push({ path: this.todo.path })
    },

    updateCardStatus () {
      this.$emit('card-status-changed', {
        id: this.todo.id,
        isDone: !this.todo.isDone,
        type: this.todo.type
      })
    },

    updateTodo () {
      this.isEdit = false

      this.$emit('update', {
        id: this.todo.id,
        title: this.title,
        body: this.body,
        deadlineDate: this.deadlineDate,
        emailTodo: this.emailTodo,
        type: this.todo.type
      })
    },

    deleteTodo () {
      this.$emit('delete', this.todo.id)
    },

    getFormattedDate (date) {
      return date
        ? format(new Date(date), 'dd/MM/yyyy')
        : ''
    }
  }
}
</script>

<style scoped lang="scss">
  .card {
    border: 1px solid $border-color-one;
    border-radius: $corner;
    column-gap: 1%;
    display: grid;
    grid-template-columns: 80% 19%;
    grid-template-rows: auto;
    justify-content: space-between;
    min-height: 8rem;
    padding: 1rem;
    row-gap: 1rem;

    &.clickable {
      cursor: pointer;

      &:hover {
        border-color: $link-color;
      }
    }

    .action-icon {
      cursor: pointer;
    }

    .actions,
    .deadline-date {
      justify-self: end;
    }

    /* stylelint-disable-next-line */
    ::v-deep input,
    .body,
    .title,
    .actions,
    .deadline-date {
      color: $font-color-one;
    }

    .action-icon {
      &:hover {
        color: $link-color;
      }
    }

    .actions {
      span {
        font-size: 1.2rem;
        padding: 0.4rem;
      }
    }

    &.ongoing {
      background-color: $widget-background-color;

      &.highlight {
        border: 0.2rem solid $color-error;
      }
    }

    &.completed {
      border: 1px dashed lightGray;

      & .icon-check-circle {
        color: $link-color;

        &:hover {
          color: $link-color;
        }
      }
    }

    .body,
    .title,
    .deadline-date {
      font-size: 0.9em;
      font-weight: normal;
    }

    .email {
      grid-column-end: 1;
      grid-column-start: 1;
      grid-row-start: 3;

      p {
        font-style: italic;
      }
    }

    .title {
      grid-column-end: 1;
      grid-column-start: 1;
      grid-row-start: 1;

      h1 {
        color: $title-color-one;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    .body {
      grid-column-end: 2;
      grid-column-start: 1;
      grid-row-start: 2;
      margin: 0;
    }

    .deadline-date {
      align-self: end;
      grid-column-start: 2;
      grid-row-start: 4;
      text-decoration: none;

      .warning {
        color: $color-error;
        font-weight: 500;
      }
    }

    .save-button {
      align-self: end;
      grid-column-start: 1;
      grid-row-start: 4;
      text-decoration: none;
    }
  }
</style>
