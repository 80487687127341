<template>
  <letter-records-table :is-scheduled="true" />
</template>

<script>
import LetterRecordsTable from '@/components/LetterRecordsTable.vue'

export default {
  name: 'LettersScheduledRecordsTable',

  components: {
    LetterRecordsTable
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 127,
      isInitial: true
    })
  }
}
</script>
