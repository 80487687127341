<template>
  <div>
    <e-dialog v-if="showDialog">
      <component
        :is="dialogInfoType"
        v-if="showDialog"
        :item="selectedService"
        @closed="showDialog = false"
      />
    </e-dialog>

    <article class="service">
      <div class="image-blocks">
        <div
          :style="serviceImg"
          class="image"
        />

        <div
          :style="vendorImg"
          class="image"
        />
      </div>

      <div class="text-blocks">
        <p class="subtype">
          Service:
        </p>
        <h2>
          {{ service.title }}
        </h2>

        <div class="description">
          {{ service.abstract }}
        </div>
        <e-button class="info-button" @click="showService">
          About the service
        </e-button>

        <e-button class="price-button" @click="showPurchaseConfirm">
          MVT {{ service.price }}
        </e-button>
        <p class="subtype dotted">
          Vendor:
        </p>
        <h3 class="vendor-title">
          {{ service.vendor.name }}
        </h3>

        <div class="description">
          {{ service.vendor.abstract }}
        </div>

        <e-button class="info-button" @click="showVendor">
          About the vendor
        </e-button>
      </div>
    </article>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ServiceInfoElement from './ServiceInfoElement'
import VendorInfoElement from './VendorInfoElement'
import ConfirmPurchase from '../ConfirmPurchaseDialogue'

export default {
  name: 'ServiceElement',

  components: {
    EDialog,
    ServiceInfoElement,
    VendorInfoElement,
    ConfirmPurchase
  },

  props: {
    service: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      showDialog: false,

      serviceImg: {
        'background-image': 'url(' + this.service.imageUrl + ')'
      },

      vendorImg: {
        'background-image': 'url(' + this.service.vendor.imageUrl + ')'
      }
    }
  },

  methods: {
    openAppropriateDialog ({ service, type }) {
      this.dialogInfoType = {
        service: 'ServiceInfoElement',
        vendor: 'VendorInfoElement',
        confirmPurchase: 'ConfirmPurchase'
      }[type]
      this.selectedService = service
      this.showDialog = true
    },

    showService () {
      this.openAppropriateDialog({
        service: this.service,
        type: 'service'
      })
    },

    showVendor () {
      this.openAppropriateDialog({
        service: this.service,
        type: 'vendor'
      })
    },

    showPurchaseConfirm () {
      this.openAppropriateDialog({
        service: this.service,
        type: 'confirmPurchase'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$honey-mask: url('/assets/mask.svg');

.service {
  background-color: $widget-background-color;
  border-radius: $corner;
  display: flex;
  gap: 1rem;

  .description {
    line-height: normal;
  }

  button {
    margin: 1rem;
  }
}

.text-blocks {
  width: 68%;
}

.image-blocks {
  align-self: center;
  position: relative;
  width: 28%;

  .image {
    background-image: url('/default_thumbnail.png');
    background-position: center;
    background-size: cover;
    height: 0;
    mask-image: $honey-mask;
    mask-position: center;
    mask-repeat: no-repeat;
    padding-bottom: 74.4%;
    position: relative;
    width: 66%;

    &:nth-child(2) {
      left: 34%;
      margin-top: -14%;
    }
  }
}

.dotted {
  border-top: $accentli dotted 1px;
  padding-top: 1rem;
}

.subtype {
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  margin: 1.2rem 0 0.5rem;
  opacity: 0.7;
  text-transform: uppercase;
}

@media screen and (min-width: $media-break-1) {
  .service {
    background-color: $widget-background-color;
  }
}
</style>
