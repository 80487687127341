<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn animate__faster"
  >
    <div class="container">
      <h2>Add Contacts</h2>

      <p>MeaVitae relies on connecting you to your contacts. Add one or more contacts to get you started either through importing them (from a file, Google or Microsoft) or manually entering their details by clicking on the 'ADD A CONTACT' button.</p>

      <div class="import-button-container">
        <import-contacts-from-file />
        <import-google-contacts />
        <import-microsoft-contacts />
      </div>

      <div
        v-if="showContactForm"
        class="contact-form-container"
      >
        <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
          <form
            id="contact-form"
            class="spaced"
            @submit.prevent="handleSubmit(submit)"
          >
            <div class="connect-input">
              <validation-provider
                v-slot="{ errors, valid }"
                name="First Name"
                rules="required"
                slim
              >
                <e-input
                  v-model="firstName"
                  label="First Name"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  data-test="first-name-input"
                />
              </validation-provider>

              <e-input
                v-model="middleNames"
                label="Middle Names"
                data-test="middle-names-input"
              />

              <validation-provider
                v-slot="{ errors, valid }"
                name="Last Name"
                rules="required"
                slim
              >
                <e-input
                  v-model="lastName"
                  label="Last Name"
                  :error-messages="errors"
                  :is-required="true"
                  :success="valid"
                  data-test="last-name-input"
                />
              </validation-provider>
            </div>

            <div class="connect-input">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Date of Birth"
                :rules="{ is_not_over_150_years_ago: true, is_past_date: true }"
                slim
              >
                <e-input
                  v-model="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  :error-messages="errors"
                  :success="valid"
                  data-test="date-of-birth-input"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Gender"
                :rules="{ required: true }"
                slim
              >
                <e-select
                  v-model="genderType"
                  :items="genderTypes"
                  label="Gender"
                  :success="valid"
                  :is-required="true"
                  :error-messages="errors"
                  data-test="gender-type-select"
                />
              </validation-provider>
            </div>

            <e-select
              v-model="relationshipType"
              :items="relationshipTypes"
              default-option-text="Select Relationship To Me"
              label="Contact's relationship to me"
              data-test="relationship-type-select"
            />

            <email-address-manager
              :email-addresses="emailAddresses"
              :type="type"
              :needed-for-is-valid="false"
            />

            <phone-number-manager
              :phone-numbers="phoneNumbers"
            />

            <address-manager
              :addresses="addresses"
              :needed-for-is-valid="false"
            />

            <submit-button
              :form-is-valid="formIsValid"
              form="contact-form"
              button-text="Save contact"
              button-style="one"
              button-type="single"
              class="submit-button"
            />
          </form>
        </validation-observer>
      </div>

      <e-button
        v-else
        class="wide-button"
        @click="addContact"
      >
        Add {{ contactAdded ? 'another' : 'a' }} contact
      </e-button>

      <e-table
        v-if="contactAdded"
        :headers="contactTableHeaders"
        :rows="contactList"
        data-test="contact-table"
      >
        <template #valid="{ item }">
          <span
            :class="item.isValid ? 'icon-check-circle' : 'icon-circle'"
          />
        </template>
      </e-table>

      <e-button
        v-if="contactAdded"
        class="wide-button"
        @click="nextOnboardingStage"
      >
        Next
      </e-button>
    </div>
  </transition>
</template>

<script>
import AddressManager from '@/components/forms/formElements/AddressManager'
import ChildContact from '@/models/ChildContact'
import Contact from '@/models/Contact'
import EmailAddressManager from '@/components/forms/formElements/EmailAddressManager'
import ETable from '@/components/ETable'
import ImportContactsFromFile from '@/components/contactImporters/ImportContactsFromFile'
import ImportGoogleContacts from '@/components/contactImporters/ImportGoogleContacts'
import ImportMicrosoftContacts from '@/components/contactImporters/ImportMicrosoftContacts'
import PhoneNumberManager from '@/components/forms/formElements/PhoneNumberManager'
import SubmitButton from '@/components/buttons/SubmitButton'
import Will from '@/models/Will'
import contactIsValid from '@/utils/contactIsValid'
import genderTypes from '@/json_files/genderTypes.json'

export default {
  name: 'UserSetupAddContacts',

  components: {
    AddressManager,
    EmailAddressManager,
    ETable,
    ImportContactsFromFile,
    ImportGoogleContacts,
    ImportMicrosoftContacts,
    PhoneNumberManager,
    SubmitButton
  },

  inheritAttrs: false,

  emits: [
    'save-contacts'
  ],

  data () {
    return {
      showContactForm: false,
      id: '',
      type: 'person',
      addresses: [],
      emailAddresses: [],
      phoneNumbers: [],
      firstName: '',
      middleNames: '',
      lastName: '',
      genderType: '',
      dateOfBirth: '',
      genderTypes,
      relationshipType: '',

      contactTableHeaders: [
        {
          text: 'Name',
          value: 'fullName',
          align: 'left',
          searchable: true
        },
        {
          text: 'Email Addresses',
          value: 'emailAddresses',
          align: 'left'
        },
        {
          text: 'Valid?',
          value: 'valid',
          align: 'center',
          sortable: false
        }
      ]
    }
  },

  computed: {
    will () {
      return Will
        .query()
        .with('children')
        .first()
    },

    isPartnerOrSpouseSet () {
      return !!this.will.partnerId
    },

    relationshipTypes () {
      return [
        { text: 'No familial relationship', value: '' },
        ...!this.isPartnerOrSpouseSet
          ? [
              { text: 'Spouse', value: 'spouse' },
              { text: 'Civil Partner', value: 'civilPartner' }
            ]
          : [],
        { text: 'My Child', value: 'child' }
      ]
    },

    contactList () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .map(({ id, fullName, emailAddresses, isValid }) => ({
          id,
          fullName,
          emailAddresses: emailAddresses
            .map(emailAddress => emailAddress.value)
            .join(', '),
          isValid
        }))
        .sort((a, b) => a.fullName.toUpperCase() < b.fullName.toUpperCase() ? -1 : 1)
    },

    contactAdded () {
      return !!this.contactList.length
    }
  },

  methods: {
    resetContactFormData () {
      this.id = new Contact().id
      this.type = 'person'
      this.addresses = []
      this.emailAddresses = []
      this.phoneNumbers = []
      this.firstName = ''
      this.middleNames = ''
      this.lastName = ''
      this.genderType = ''
      this.dateOfBirth = ''
      this.relationshipType = ''

      this.showContactForm = false
    },

    addContact () {
      this.resetContactFormData()

      this.id = new Contact().id
      this.showContactForm = true
    },

    async createContactRelationship (relationshipType) {
      if (relationshipType === 'child') {
        ChildContact.insert({
          data: {
            willId: this.will.id,
            contactId: this.id
          }
        })

        return
      }

      if (['spouse', 'civilPartner'].includes(relationshipType) && !this.isPartnerOrSpouseSet) {
        Will.update({
          where: Will.query().first().id,
          data: {
            partnerId: this.id,
            partnerType: relationshipType
          }
        })
      }
    },

    async submit () {
      const isValid = await contactIsValid({
        type: this.type,
        addresses: this.addresses,
        firstName: this.firstName,
        lastName: this.lastName,
        dateOfBirth: this.dateOfBirth,
        genderType: this.genderType,
        emailAddresses: this.emailAddresses
      })

      Contact.insertOrUpdate({
        data: {
          id: this.id,
          type: this.type,
          addresses: this.addresses,
          emailAddresses: this.emailAddresses,
          phoneNumbers: this.phoneNumbers,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          genderType: this.genderType,
          dateOfBirth: this.dateOfBirth,
          createdDate: this.createdDate || new Date().toISOString(),
          isValid
        }
      })

      await this.createContactRelationship(this.relationshipType)

      this.resetContactFormData()
    },

    nextOnboardingStage () {
      this.$emit('save-contacts', true)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .contact-form-container {
    margin: 1.5em 0;
  }

  h2 {
    margin-bottom: 1em;
  }

  .import-button-container {
    display: flex;
    flex-wrap: wrap;
  }

  .wide-button {
    margin: 1em 0;
    text-transform: uppercase;
    width: 100%;
  }
}
</style>
