<template>
  <div>
    <e-button
      data-test="add-phone-number-button"
      @click="addPhoneNumber(phoneNumbers)"
    >
      <span class="icon-plus" />
      Add Phone Number
    </e-button>

    <fieldset v-if="phoneNumbers.length">
      <legend>Phone Numbers</legend>

      <table data-test="phone-number-table">
        <thead>
          <tr>
            <th>Type</th>

            <th>Phone Number</th>

            <th class="center">
              Preferred
            </th>

            <th class="center">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(phoneNumber, index) in phoneNumbers"
            :key="phoneNumber.id"
          >
            <td>
              <e-select
                :key="phoneNumber.id.concat(Date.now())"
                :value="phoneNumbers[index].type"
                :items="defaultPhoneTypes"
                default-option-text="Select Type"
                data-test="phone-number-type-select"
                @change="updateType(phoneNumbers, phoneNumber.id, $event)"
              />
            </td>

            <td>
              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${phoneNumber.id}-phone-number`"
                name="Phone Number"
                :rules="{ required: true }"
                tag="div"
              >
                <e-input
                  :value="phoneNumbers[index].value"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  data-test="phone-number-input"
                  @input="updateValue(phoneNumbers, phoneNumber.id, $event)"
                />
              </validation-provider>
            </td>

            <td class="preferred">
              <e-input
                :value="phoneNumbers[index].isPref"
                type="checkbox"
                data-test="preferred-phone-number-checkbox"
                @change="updatePref(phoneNumbers, phoneNumber.id, $event)"
              />
            </td>

            <td class="actions">
              <e-button
                class="ghost"
                data-test="remove-phone-number-button"
                @click="removePhoneNumber(phoneNumbers, phoneNumber.id)"
              >
                <span class="icon-trash-2" />
              </e-button>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import phoneNumberTypes from '@/json_files/phoneNumberTypes.json'
import { createId } from '@/utils'

export default {
  name: 'PhoneNumberManager',

  props: {
    phoneNumbers: {
      type: Array,
      default: () => []
    }
  },

  emits: ['is-dirty'],

  data () {
    return {
      defaultPhoneTypes: phoneNumberTypes
    }
  },

  methods: {
    addPhoneNumber (phoneNumbers) {
      phoneNumbers.push({ id: createId() })
      this.$emit('is-dirty')
    },

    updatePhoneNumber ({ phoneNumbers, phoneNumberId, key, value }) {
      phoneNumbers.find(({ id }) => id === phoneNumberId)[key] = value
      this.$emit('is-dirty')
    },

    removePhoneNumber (phoneNumbers, phoneNumberId) {
      const index = phoneNumbers.findIndex(({ id }) => id === phoneNumberId)
      phoneNumbers.splice(index, 1)
      this.$emit('is-dirty')
    },

    updateType (phoneNumbers, phoneNumberId, value) {
      this.updatePhoneNumber({
        phoneNumbers,
        phoneNumberId,
        key: 'type',
        value
      })
    },

    updateValue (phoneNumbers, phoneNumberId, value) {
      this.updatePhoneNumber({
        phoneNumbers,
        phoneNumberId,
        key: 'value',
        value
      })
    },

    updatePref (phoneNumbers, phoneNumberId, bool) {
      if (bool) {
        phoneNumbers
          .filter(({ id }) => id !== phoneNumberId)
          .forEach(phoneNumber => (phoneNumber.isPref = false))
      }

      this.updatePhoneNumber({
        phoneNumbers,
        phoneNumberId,
        key: 'isPref',
        value: bool
      })
    }
  }
}
</script>

<style scoped lang="scss">
button {
  margin-bottom: 0.8rem;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
