<template>
  <span>
    <h1 v-if="!userUpdatedFromJwt">
      User Details Not Loaded
    </h1>

    <span v-else>
      <h1>
        My Profile

        <span
          class="icon-help-circle"
          @click.prevent="resetDefaultHelp"
        />
      </h1>

      <transition
        appear
        enter-active-class="animate__animated animate__fadeInLeft animate__faster"
      >
        <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
          <form
            id="my-details-form"
            class="spaced"
            @submit.prevent="handleSubmit(submit)"
          >
            <div class="avatar-container">
              <avatar-button
                :avatar="avatar"
                @click="openFileSelector"
              />
            </div>

            <e-input
              label="Email Address"
              data-test="email-address-input"
              :value="emailAddress"
              :help-id="104"
              disabled
            />

            <div class="connect-input">
              <e-input
                :value="firstName"
                label="First Name"
                disabled
              />

              <e-input
                :value="middleNames"
                label="Middle Names"
                disabled
              />

              <e-input
                :value="lastName"
                label="Last Name"
                disabled
              />
            </div>

            <div class="connect-input">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Date of Birth"
                :rules="{ required: true, is_not_over_150_years_ago: true, is_past_date: true }"
                tag="div"
              >
                <e-input
                  v-model="dateOfBirth"
                  label="Date of Birth"
                  type="date"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  data-test="date-of-birth-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <e-input
                v-model="placeOfBirth"
                label="Place Of Birth"
                data-test="place-of-birth-input"
                @input="setIsDirty(true)"
              />

              <validation-provider
                v-slot="{ errors, valid }"
                name="Gender"
                rules="required"
                slim
              >
                <e-select
                  v-model="genderType"
                  :items="genderTypes"
                  label="Gender"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="true"
                  data-test="gender-type-select"
                  @change="setIsDirty(true)"
                />
              </validation-provider>
            </div>

            <phone-number-manager
              :phone-numbers="phoneNumbers"
              @is-dirty="setIsDirty(true)"
            />

            <address-manager
              :addresses="addresses"
              :address-required="true"
              @is-dirty="setIsDirty(true)"
            />

            <e-input
              v-model="nationalInsuranceNumber"
              label="National Insurance (NI) Number"
              data-test="national-insurance-number-input"
              @input="setIsDirty(true)"
            />

            <user-licenses-manager
              :licenses="licenses"
              @is-dirty="setIsDirty(true)"
            />

            <e-input
              v-model="nationalHealthServiceNumber"
              label="National Health Service (NHS) Number"
              data-test="national-health-service-number-input"
              @input="setIsDirty(true)"
            />

            <user-passports-manager
              :passports="passports"
              @is-dirty="setIsDirty(true)"
            />

            <e-input
              v-model="governmentGatewayUserId"
              label="Government Gateway User Id"
              data-test="government-gateway-user-id-input"
              @input="setIsDirty(true)"
            />

            <e-input
              v-model="selfAssessmentUniqueTaxpayerReference"
              label="Self Assessment Unique Taxpayer Reference (UTR)"
              data-test="self-assessment-unique-taxpayer-reference-input"
              @input="setIsDirty(true)"
            />

            <e-input
              v-model="employerPayAsYouEarnReference"
              label="Employer PAYE Reference"
              data-test="employer-pay-as-you-earn-reference-input"
              @input="setIsDirty(true)"
            />

            <fieldset>
              <legend>Privacy settings</legend>

              <e-input
                :value="isPrivate"
                type="checkbox"
                label="I do not allow other MeaVitae users to message me"
                data-test="is-private-checkbox"
                @change="value => isPrivate = value"
              />
            </fieldset>

            <fieldset>
              <legend>Billing Details</legend>

              <div class="connect-input">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="First Name"
                  rules="required"
                  tag="div"
                >
                  <e-input
                    v-model="firstName"
                    label="First Name"
                    :error-messages="errors"
                    :success="valid"
                    :is-required="true"
                    data-test="first-name-input"
                    @input="setIsDirty(true)"
                  />
                </validation-provider>

                <e-input
                  v-model="middleNames"
                  label="Middle Names"
                  data-test="middle-names-input"
                  @input="setIsDirty(true)"
                />

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Last Name"
                  rules="required"
                  tag="div"
                >
                  <e-input
                    v-model="lastName"
                    label="Last Name"
                    :error-messages="errors"
                    :success="valid"
                    :is-required="true"
                    data-test="last-name-input"
                    @input="setIsDirty(true)"
                  />
                </validation-provider>
              </div>

              <div class="connect-input">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Address 1"
                  rules="required"
                  tag="div"
                >
                  <e-input
                    v-model="billingAddress1"
                    label="Address 1"
                    :error-messages="errors"
                    :success="valid"
                    :is-required="true"
                    data-test="address1-input"
                    @input="setIsDirty(true)"
                  />
                </validation-provider>

                <e-input
                  v-model="billingAddress2"
                  label="Address 2"
                  data-test="address2-input"
                  @input="setIsDirty(true)"
                />
              </div>

              <div class="connect-input">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Postcode"
                  rules="required"
                  tag="div"
                >
                  <e-input
                    v-model="billingPostcode"
                    label="Postcode"
                    :error-messages="errors"
                    :success="valid"
                    :is-required="true"
                    data-test="postcode-input"
                    @input="setIsDirty(true)"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Country"
                  rules="required"
                  tag="div"
                >
                  <e-input
                    v-model="billingCountry"
                    label="Country"
                    :error-messages="errors"
                    :success="valid"
                    :is-required="true"
                    data-test="country-input"
                    @input="setIsDirty(true)"
                  />
                </validation-provider>
              </div>
            </fieldset>

            <save-bar :form-is-valid="formIsValid">
              <submit-button
                :form-is-valid="formIsValid"
                form="my-details-form"
              />

              <delete-user-account-button @submitted="setIsDirty(false)" />
            </save-bar>
          </form>
        </validation-observer>
      </transition>
    </span>
  </span>
</template>

<script>
import AvatarButton from '@/components/buttons/AvatarButton'
import AddressManager from '../formElements/AddressManager'
import DeleteUserAccountButton from '@/components/buttons/DeleteUserAccountButton'
import PhoneNumberManager from '../formElements/PhoneNumberManager'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import Todo from '@/models/Todo'
import User from '@/models/User'
import UserLicensesManager from './UserLicensesManager'
import UserPassportsManager from './UserPassportsManager'
import genderTypes from '@/json_files/genderTypes.json'
import { mapState } from 'vuex'

export default {
  name: 'UserForm',

  components: {
    AddressManager,
    AvatarButton,
    DeleteUserAccountButton,
    PhoneNumberManager,
    SaveBar,
    SubmitButton,
    UserLicensesManager,
    UserPassportsManager
  },

  data () {
    return {
      userId: '',
      firstName: '',
      middleNames: '',
      lastName: '',
      avatar: '',
      genderType: '',
      dateOfBirth: '',
      placeOfBirth: '',
      nationalInsuranceNumber: '',
      licenses: [],
      passports: [],
      nationalHealthServiceNumber: '',
      governmentGatewayUserId: '',
      selfAssessmentUniqueTaxpayerReference: '',
      employerPayAsYouEarnReference: '',
      emailAddresses: [],
      phoneNumbers: [],
      addresses: [],
      genderTypes,
      isPrivate: false,
      billingAddress1: '',
      billingAddress2: '',
      billingPostcode: '',
      billingCountry: '',

      isDirty: false
    }
  },

  computed: {
    ...mapState([
      'userUpdatedFromJwt'
    ]),

    emailAddress () {
      return this.emailAddresses?.[0]?.value
    }
  },

  created () {
    const user = this.$store.getters.user

    this.userId = user.id
    this.avatar = user.avatar
    this.firstName = user.firstName
    this.middleNames = user.middleNames
    this.lastName = user.lastName
    this.genderType = user.genderType
    this.emailAddresses = user.emailAddresses
    this.phoneNumbers = user.phoneNumbers
    this.addresses = user.addresses
    this.dateOfBirth = user.dateOfBirth
    this.placeOfBirth = user.placeOfBirth
    this.nationalInsuranceNumber = user.nationalInsuranceNumber
    this.licenses = user.licenses
    this.passports = user.passports
    this.nationalHealthServiceNumber = user.nationalHealthServiceNumber
    this.governmentGatewayUserId = user.governmentGatewayUserId
    this.selfAssessmentUniqueTaxpayerReference = user.selfAssessmentUniqueTaxpayerReference
    this.employerPayAsYouEarnReference = user.employerPayAsYouEarnReference
    this.isPrivate = user.isPrivate
    this.billingAddress1 = user.billingAddress1
    this.billingAddress2 = user.billingAddress2
    this.billingPostcode = user.billingPostcode
    this.billingCountry = user.billingCountry

    this.$store.dispatch('findAndSetHelpObject', {
      id: user.isValid ? 78 : 142,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    openFileSelector () {
      const fileSelector = document.createElement('input')
      fileSelector.setAttribute('type', 'file')
      fileSelector.setAttribute('accept', '.png,.img,.jpg,.jpeg')
      fileSelector.addEventListener('input', event => this.setAvatar(event))
      fileSelector.click()
    },

    setAvatar (event) {
      const file = event.target.files[0]

      if ((file.size / 1024) > 500) {
        alert('Avatar image too big, choose a smaller/lighter image.')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        this.avatar = reader.result
        this.setIsDirty(true)
      }
    },

    resetDefaultHelp () {
      this.$store.dispatch('findAndSetHelpObject', { id: 78 })
    },

    async submit () {
      try {
        this.setIsDirty(false)

        User.update({
          data: {
            id: this.userId,
            avatar: this.avatar,
            firstName: this.firstName,
            middleNames: this.middleNames,
            lastName: this.lastName,
            genderType: this.genderType,
            emailAddresses: this.emailAddresses,
            phoneNumbers: this.phoneNumbers,
            addresses: this.addresses,
            dateOfBirth: this.dateOfBirth,
            placeOfBirth: this.placeOfBirth,
            nationalInsuranceNumber: this.nationalInsuranceNumber,
            licenses: this.licenses,
            passports: this.passports,
            nationalHealthServiceNumber: this.nationalHealthServiceNumber,
            governmentGatewayUserId: this.governmentGatewayUserId,
            selfAssessmentUniqueTaxpayerReference: this.selfAssessmentUniqueTaxpayerReference,
            employerPayAsYouEarnReference: this.employerPayAsYouEarnReference,
            isPrivate: this.isPrivate,
            billingAddress1: this.billingAddress1,
            billingAddress2: this.billingAddress2,
            billingPostcode: this.billingPostcode,
            billingCountry: this.billingCountry,
            isValid: true
          }
        })

        Todo.update({
          where: ({ title }) => title === 'Complete your details',
          data: {
            isDone: true
          }
        })

        const jwt = await this.$store.dispatch('updateUserDetailsToIdp', {
          avatar: this.avatar,
          firstName: this.firstName,
          middleNames: this.middleNames,
          lastName: this.lastName,
          isPrivate: this.isPrivate,
          billingAddress1: this.billingAddress1,
          billingAddress2: this.billingAddress2,
          billingPostcode: this.billingPostcode,
          billingCountry: this.billingCountry
        })

        sessionStorage.setItem('jwt', jwt)

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['user', 'todos'],
          message: 'User successfully saved'
        })
      } catch (error) {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || 'User profile failed to update to our idp server, please try again',
          type: error.response?.data?.type,
          link: error.response?.data?.link
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UserForm',
          functionName: 'submit'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-address {
  div {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  span {
    font-size: 1rem;
    font-weight: 400;
  }
}

.avatar-container {
  height: 14rem;
  margin: 0 auto;
  min-height: 14rem;
  min-width: 14rem;
  width: 14rem;
}
</style>
