var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Debt Letter Before Legal Action "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"contract-termination-agreeent-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true,"data-test":"document-label-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Letter Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Letter Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.letterDate),callback:function ($$v) {_vm.letterDate=$$v},expression:"letterDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Invoice Number","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Invoice Number","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.invoiceNumber),callback:function ($$v) {_vm.invoiceNumber=$$v},expression:"invoiceNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Letter Addressed To","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Letter Addressed To","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.letterAddressedTo),callback:function ($$v) {_vm.letterAddressedTo=$$v},expression:"letterAddressedTo"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Remaining Outstanding Amount","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Remaining Outstanding Amount","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.remainingOutstandingAmount),callback:function ($$v) {_vm.remainingOutstandingAmount=$$v},expression:"remainingOutstandingAmount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Credit Terms Full Payment Days","rules":{ required: true, min_value: 1 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Credit Terms Full Payment Days","type":"number","step":"1","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.creditTermsFullPaymentDays),callback:function ($$v) {_vm.creditTermsFullPaymentDays=$$v},expression:"creditTermsFullPaymentDays"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Number Of Days Account Overdue","rules":{ required: true, min_value: 1 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Number Of Days Account Overdue","type":"number","step":"1","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.accountOverdueDays),callback:function ($$v) {_vm.accountOverdueDays=$$v},expression:"accountOverdueDays"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Number Of Days To Pay","rules":{ required: true, min_value: 1 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Number Of Days To Pay","type":"number","step":"1","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.numberOfDaysToPay),callback:function ($$v) {_vm.numberOfDaysToPay=$$v},expression:"numberOfDaysToPay"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"contract-termination-agreeent-form","button-text":"Preview","data-test":"bottom-preview-button"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }