<template>
  <div class="editor-header">
    <template v-for="(item, index) in items">
      <span
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
        class="editor-menuitem-divider"
      />
      <menu-item
        v-else
        :key="index"
        v-bind="item"
      />
    </template>
  </div>
</template>

<script>
import MenuItem from './MenuItem'

export default {
  name: 'MenuBar',

  components: {
    MenuItem
  },

  props: {
    editor: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      items: [
        {
          title: 'H1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },

        {
          title: 'H2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },

        {
          title: 'H3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 })
        },

        {
          type: 'divider'
        },

        {
          icon: 'icon-bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },

        {
          icon: 'icon-italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },

        {
          icon: 'icon-underline',
          title: 'Underline',
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive('underline')
        },

        {
          icon: 'icon-strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike')
        },

        {
          type: 'divider'
        },

        {
          icon: 'icon-align-left',
          title: 'Align Left',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
          isActive: () => this.editor.isActive({ textAlign: 'left' })
        },

        {
          icon: 'icon-align-center',
          title: 'Align Center',
          action: () => this.editor.chain().focus().setTextAlign('center').run(),
          isActive: () => this.editor.isActive({ textAlign: 'center' })
        },

        {
          icon: 'icon-align-right',
          title: 'Align Right',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
          isActive: () => this.editor.isActive({ textAlign: 'right' })
        },

        {
          icon: 'icon-align-justify',
          title: 'Align Justify',
          action: () => this.editor.chain().focus().setTextAlign('justify').run(),
          isActive: () => this.editor.isActive({ textAlign: 'justify' })
        },

        {
          type: 'divider'
        },

        {
          icon: 'icon-list-ul',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList')
        },

        {
          icon: 'icon-list-ol',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList')
        },

        {
          type: 'divider'
        },

        {
          icon: 'icon-link',
          title: 'Link',
          action: () => this.setLink(),
          isActive: () => this.editor.isActive('link')
        },

        {
          type: 'divider'
        },

        {
          icon: 'icon-undo2',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run()
        },

        {
          icon: 'icon-redo2',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run()
        }
      ]
    }
  },

  methods: {
    setLink () {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      if (url === null) return

      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-menuitem-divider {
  background-color: rgba(black, 0.1);
  display: inline-block;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 2px;
}

.editor-header {
  border-bottom: 1px solid $input-font-color;
  padding: 4px;
}
</style>
