<template>
  <e-input
    :id="id"
    v-model="autocompleteText"
    type="text"
    :label="label"
    :is-required="isRequired"
    :error-messages="errorMessages"
    :success="success"
    :placeholder="placeholder"
    :help-id="helpId"
    @focus="biasAutocompleteLocation"
    @input="manualEntry"
  />
</template>

<script>
const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
}

export default {
  name: 'GoogleAddressInput',

  props: {
    addressObject: {
      type: Object,
      default: () => ({})
    },

    label: {
      type: String,
      default: ''
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    success: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'Type your address here or pick from the suggestions below'
    },

    types: {
      type: String,
      default: 'address'
    },

    country: {
      type: [String, Array],
      default: null
    },

    enableGeolocation: {
      type: Boolean,
      default: false
    },

    geolocationOptions: {
      type: Object,
      default: null
    },

    helpId: {
      type: Number,
      default: null
    }
  },

  emits: [
    'address-selected',
    'no-results-found',
    'error'
  ],

  data () {
    return {
      id: '',
      autocomplete: null,
      autocompleteText: ''
    }
  },

  created () {
    this.id = this.addressObject.id

    this.autocompleteText = this.addressObject
      ?.placeResult
      ?.formatted_address
  },

  mounted () {
    const options = {
      ...this.types && { types: [this.types] },
      ...this.country && { componentRestrictions: { country: this.country } },
      fields: [
        'geometry',
        'formatted_address',
        'address_components',
        'url'
      ]
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    )

    this.autocomplete.addListener('place_changed', this.onAddressSelected)
  },

  methods: {
    manualEntry (value) {
      this.$emit('address-selected', {
        id: this.id,
        returnData: {},
        placeResult: {
          formatted_address: value
        }
      })
    },

    formatResult (place) {
      return place
        .address_components
        .reduce((accumulator, addressComponent) => {
          const addressType = addressComponent.types[0]

          return {
            ...accumulator,
            ...ADDRESS_COMPONENTS[addressType] && {
              [addressType]: addressComponent[ADDRESS_COMPONENTS[addressType]]
            }
          }
        }, {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        })
    },

    onAddressSelected () {
      const place = this.autocomplete.getPlace()

      if (!place.geometry) {
        this.$emit('no-results-found', place, this.id)
        return
      }

      if (place.address_components !== undefined) {
        this.$emit('address-selected', {
          id: this.id,
          returnData: this.formatResult(place),
          placeResult: place
        })

        this.autocompleteText = place.formatted_address
      }
    },

    updateGeolocation (callback = null) {
      if (navigator.geolocation) {
        const options = {}

        if (this.geolocationOptions) {
          Object.assign(options, this.geolocationOptions)
        }

        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          if (callback) callback(geolocation, position)
        }, err => {
          this.$emit('error', 'Cannot get Coordinates from navigator', err)
        }, options)
      }
    },

    biasAutocompleteLocation () {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })

          this.autocomplete.setBounds(circle.getBounds())
        })
      }
    }
  }
}
</script>
