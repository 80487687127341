<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Help
        </h1>

        <e-input
          v-model="search"
          :delay="true"
          placeholder="Search"
          class="search"
          @input="performSearch"
        />

        <accordion
          v-if="items.length > 0"
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @check-article-out-of-sync-with-accordion="checkArticleOutOfSyncWithAccordion"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'HelpArticles',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      active: '',
      search: ''
    }
  },

  computed: {
    items () {
      return [
        {
          id: 'categories',
          text: 'Categories',
          isActive: this.active === 'categories',
          sub: this.$store.state.help.categories.map(category => ({
            id: category.id,
            text: category.title,
            routeName: `help-${category.id}`,
            paramKey2: 'categoryId'
          }))
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 143,
      isInitial: true
    })

    if (this.$route.params.searchString) {
      this.prePopulateSearchInput()
    }
  },

  methods: {
    checkArticleOutOfSyncWithAccordion (categoryId) {
      this.active = categoryId
    },

    prePopulateSearchInput () {
      this.search = this.$route.params.searchString
    },

    performSearch (string) {
      if (string && string.length > 3) {
        this.$router.push({
          name: 'HelpSearchResult',
          ...string && {
            params: {
              searchString: string
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 0.6em;
}
</style>
