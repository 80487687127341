<template>
  <div
    v-if="showHelpComponent"
    class="help-container"
  >
    <component :is="help" />

    <p class="feedback-form-text">
      If you would like to report an issue or ask for help,
      please use our feedback form <span class="link" @click="showFeedbackBar">here</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelpSideBar',

  computed: {
    help () {
      return {
        template: this.$store.state.currentHelpObject.html
      }
    },

    showHelpComponent () {
      return !!this.$store.state.currentHelpObject?.html
    }
  },

  methods: {
    showFeedbackBar () {
      this.$store.commit('setShowFeedbackBar', true)
    }
  }
}
</script>

<style scoped lang="scss">
.help-container {
  /* stylelint-disable-next-line */
  ::v-deep a {
    color: $link-color;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
    }
  }

  /* stylelint-disable-next-line */
  ::v-deep h1 {
    font-size: 1.458rem;
    margin-bottom: 1rem;
  }

  /* stylelint-disable-next-line */
  ::v-deep p {
    margin: 0 0 1em;
  }

  /* stylelint-disable-next-line */
  ::v-deep ul {
    list-style-type: circle;
    padding-left: 1.2rem;

    li {
      margin: 0 0 0.4rem;
    }
  }
}
</style>
