<template>
  <div>
    <e-button
      type="button"
      data-test="delete-button"
      @click="isVisible = true"
    >
      Delete
    </e-button>

    <confirmation-dialog
      v-if="isVisible"
      :title="`Delete ${itemType}`"
      @confirm="confirmDelete"
      @cancel="isVisible = false"
    >
      <p>
        Are you sure you wish to permanently delete {{ itemType }}, this action will not be reversable?
      </p>
    </confirmation-dialog>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'

export default {
  name: 'TableDeleteDialog',

  components: {
    ConfirmationDialog
  },

  props: {
    itemType: {
      type: String,
      default: 'item type'
    }
  },

  emits: ['delete-confirmed'],

  data () {
    return {
      isVisible: false
    }
  },

  methods: {
    confirmDelete () {
      this.isVisible = false
      this.$emit('delete-confirmed')
    }
  }
}
</script>
