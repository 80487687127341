<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        UK Land Registry Transfer Of Registered Title

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="uk-land-registry-transfer-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Property To Transfer</legend>

            <div class="connect-input">
              <e-input
                v-model="propertyTitleNumberToAdd"
                label="Property Title Number"
                class="input-wide"
              />

              <e-button
                :disabled="!propertyTitleNumberToAdd"
                @click="addPropertyTitleNumber"
              >
                <span class="icon-plus" />
                Add Title
              </e-button>
            </div>

            <table v-if="propertyTitleNumbers.length">
              <thead>
                <tr>
                  <th>Property Title Number</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="propertyTitleNumber in propertyTitleNumbers"
                  :key="propertyTitleNumber"
                >
                  <td>
                    {{ propertyTitleNumber }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removePropertyTitleNumber(propertyTitleNumber)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Property Address"
              :rules="{ required: true }"
              slim
            >
              <e-textarea
                v-model="propertyAddress"
                label="Property Address"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset>
            <legend>Transfer</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Property Transfer Type"
              :rules="{ required: true }"
            >
              <e-select
                v-model="propertyTitleTransferType"
                :items="propertyTitleTransferTypeSelectOptions"
                label="Property Transfer Type"
                default-option-text="Add Property Transfer Type"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Transferor"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="transferors.length"
                type="hidden"
              >

              <e-select
                :items="contactSelectOptions"
                label="Transferor (Property Title Transfer From)"
                default-option-text="Add Transferor"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="contactId => addContact({ type: 'transferors', contactId })"
              />
            </validation-provider>

            <table v-if="transferors.length">
              <thead>
                <tr>
                  <th>Transferor</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="tranferor in transferors"
                  :key="tranferor.id"
                >
                  <td>
                    {{ tranferor.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeContact({ type: 'transferors', contactId: tranferor.id })"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Transferee"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="transferees.length"
                type="hidden"
              >

              <e-select
                :items="contactSelectOptions"
                label="Transeree (Property Title Transfer To)"
                default-option-text="Add Transferee"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="contactId => addContact({ type: 'transferees', contactId })"
              />
            </validation-provider>

            <table v-if="transferees.length">
              <thead>
                <tr>
                  <th>Transferee</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="transferer in transferees"
                  :key="transferer.id"
                >
                  <td>
                    {{ transferer.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeContact({ type: 'transferees', contactId: transferer.id })"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <e-select
              v-model="transfereesDeclarationOfTrust"
              :items="transfereesDeclarationOfTrustSelectOptions"
              label="Transferees Declaration Of Trust"
              default-option-text="Add Transferees Declaration Of Trust"
              :disabled="!!(transferees.length < 2)"
              @input="setIsDirty(true)"
            />

            <validation-provider
              v-slot="{ errors, valid }"
              name="Transfer Sum"
              :rules="{ required: true }"
              slim
            >
              <e-input
                v-model="transferSum"
                label="Transfer Sum"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <e-textarea
            v-model="additionalProvisions"
            label="Additional Provisions"
            @input="setIsDirty(true)"
          />

          <fieldset>
            <legend>Witness(es)</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Witnesses"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="witnesses.length"
                type="hidden"
              >

              <e-select
                :items="witnessesSelectOptions"
                default-option-text="Add Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="contactId => addContact({ type: 'witnesses', contactId })"
              />
            </validation-provider>

            <table v-if="witnesses.length">
              <thead>
                <tr>
                  <th>Witness</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="witness in witnesses"
                  :key="witness.id"
                >
                  <td>
                    {{ witness.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeContact({ type: 'witnesses', contactId: witness.id })"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="uk-land-registry-transfer-form"
              button-text="Preview"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'ukLandRegistryTransferAstonVeer'

export default {
  name: 'UkLandRegistryTransfer',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'a94c6a64-5be3-4337-b3b3-dbdd7b48319d',
      isDirty: false,
      propertyTitleTransferTypeSelectOptions: [
        {
          value: 'fullTitleGuarantee',
          text: 'Transferor transfers with full title guarantee'
        },
        {
          value: 'limitedTitleGuarantee',
          text: 'Transferor transfers with limited title guarantee'
        }
      ],

      transfereesDeclarationOfTrustSelectOptions: [
        {
          value: 'holdPropertyOnTrustForThemselvesAsJointTenants',
          text: 'Transferees hold the property on trust for themselves as joint tenants'
        },
        {
          value: 'holdPropertyOnTrustForThemselvesAsTenantsInCommon',
          text: 'Transferees hold the property on trust for themselves as tenants in common in equal shares'
        }
      ],

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      propertyTitleNumbers: [],
      propertyTitleNumberToAdd: '',
      propertyAddress: '',
      propertyTitleTransferType: '',
      transferors: [],
      transferees: [],
      transferSum: '',
      transfereesDeclarationOfTrust: '',
      additionalProvisions: '',
      witnesses: [],

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    addedContactIds () {
      return [...new Set([
        ...this.transferors.map(({ id }) => id),
        ...this.transferees.map(({ id }) => id),
        ...this.witnesses.map(({ id }) => id)
      ])]
    },

    contactSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => ![...new Set([
          ...this.transferors.map(({ id }) => id),
          ...this.transferees.map(({ id }) => id),
          ...this.witnesses.map(({ id }) => id)
        ])].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    witnessesSelectOptions () {
      return this.contactSelectOptions
        .filter(({ type }) => type === 'person')
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addPropertyTitleNumber () {
      this.propertyTitleNumbers = [...new Set([
        ...this.propertyTitleNumbers,
        this.propertyTitleNumberToAdd
      ])]
      this.propertyTitleNumberToAdd = ''
    },

    removePropertyTitleNumber (propertyTitleNumberToRemove) {
      this.propertyTitleNumbers = this.propertyTitleNumbers
        .filter(propertyTitleNumber => propertyTitleNumber !== propertyTitleNumberToRemove)
    },

    addContact ({ contactId, type }) {
      this[type].push(this.userAndContacts.find(({ id }) => id === contactId))
      this.setIsDirty(true)
    },

    removeContact ({ contactId, type }) {
      this[type] = this[type]
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            propertyTitleNumbers: this.propertyTitleNumbers,
            propertyAddress: this.propertyAddress,
            propertyTitleTransferType: this.propertyTitleTransferType,
            transferors: this.transferors,
            transferees: this.transferees,
            transferSum: this.transferSum,
            transfereesDeclarationOfTrust: this.transfereesDeclarationOfTrust,
            additionalProvisions: this.additionalProvisions,
            witnesses: this.witnesses
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UkLandRegistryTransfer',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'UkLandRegistryTransfer',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-wide {
  flex: 100;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
