<template>
  <router-view />
</template>

<script>
export default {
  name: 'Generators',

  emits: [
    'set-active'
  ],

  created () {
    this.$emit('set-active', 'Generators')

    this.$store.dispatch('findAndSetHelpObject', {
      id: 125,
      isInitial: true
    })
  }
}
</script>
