<template>
  <div>
    <p>
      This is your secure record of things you owe and things that draw
      money from your accounts, we call it the Liabilities Register.
    </p>

    <p>
      We do our best to represent an accurate net worth calculation for
      you on the Dashboard so the more information you record here, the
      more value you it will bring to your life and legacy.
    </p>

    <p>
      Entries here work with all the other features in MeaVitae such as
      associating debts secured against assets.
    </p>
  </div>
</template>

<script>
export default {
  name: 'LiabilitiesDefault',

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 36,
      isInitial: true
    })
  }
}
</script>
