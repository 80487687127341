<template>
  <e-button class="google primary" @click="importContacts">
    Sign in with Google
  </e-button>
</template>

<script>
import Contact from '@/models/Contact'
import { mergeContacts } from './contactManager'
import { createId } from '@/utils'
import { format, parse } from 'date-fns'

export default {
  name: 'ImportGoogleContacts',

  methods: {
    importContacts () {
      try {
        gapi.load('client', async () => {
          await this.initClient()
          const scope = 'https://www.googleapis.com/auth/contacts.readonly'
          const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
          const tokenClient = await google.accounts.oauth2.initTokenClient({ client_id: clientId, scope, callback: '' })
          await tokenClient.requestAccessToken({ prompt: 'select_account' })
          tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) throw (resp)
            gapi.client.setToken(resp)
            const contacts = await this.getConnectionsFromGoogle()
            const parsedContacts = contacts.map(contact => createContactObject(contact))
            if (parsedContacts.length) await this.saveContactsToStore(parsedContacts)
            // const token = gapi.client.getToken()
            // google.accounts.oauth2.revoke(token.access_token)
            gapi.client.setToken('')
          }
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to import contacts'
        })
      }
    },

    async initClient () {
      const apiKey = process.env.VUE_APP_GOOGLE_API_KEY
      const discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest']
      return gapi.client.init({ apiKey, discoveryDocs })
    },

    async getConnectionsFromGoogle () {
      let contactsComplete = false
      const contacts = []
      let pageToken = ''
      while (!contactsComplete) {
        const response = await gapi.client.people.people.connections.list({
          resourceName: 'people/me',
          personFields: 'names,addresses,birthdays,emailAddresses,phoneNumbers,organizations,occupations,genders',
          pageToken
        })
        pageToken = response.result.nextPageToken
        contacts.push(...response.result?.connections)
        if (!pageToken) contactsComplete = true
      }
      return contacts
    },

    async saveContactsToStore (updatedContacts) {
      try {
        // moving this to an update function to handle de-dup imports
        const mergedContacts = mergeContacts(updatedContacts)
        Contact.insertOrUpdate({ data: mergedContacts })

        await this.$store.dispatch('persistRecordToVault', { entityTypes: ['contacts'] })
        await this.$store.dispatch('getAndSetContactDetailsFromBackend')

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: `Importer has processed ${updatedContacts.length} ${updatedContacts.length === 1 ? 'contact' : 'contacts'}.`
        })
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'ImportGoogleContacts',
          functionName: 'saveContactsToStore'
        })
      }
    }
  }
}

const createContactObject = contact => {
  const dateOfBirth = contact.birthdays?.[0].text

  return {
    firstName: contact.names?.[0].givenName,
    middleNames: contact.names?.[0].middleName,
    lastName: contact.names?.[0].familyName,
    prefixes: contact.names?.[0].honorificPrefix,
    suffixes: contact.names?.[0].honorificSuffix,
    jobTitle: contact.organizations?.[0].title,
    organisationName: contact.organizations?.[0].name,
    ...dateOfBirth && {
      dateOfBirth: format(parse(dateOfBirth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
    },

    source: 'google',

    addresses: contact.addresses?.map(address => ({
      id: createId(),
      isPref: address.metadata.primary,
      placeResult: {
        formatted_address: address.formattedValue
      },
      returnData: {}
    })) || [],

    phoneNumbers: contact.phoneNumbers?.map(phoneNumber => ({
      id: createId(),
      isPref: phoneNumber.metadata.primary,
      type: phoneNumber.type,
      value: phoneNumber.value
    })) || [],

    emailAddresses: contact.emailAddresses?.map(emailAddress => ({
      id: createId(),
      isPref: emailAddress.metadata.primary,
      value: emailAddress.value
    })) || []
  }
}
</script>

<style lang="scss" scoped>
.google {
  background-color: white !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABLCAMAAADtTJGhAAACHFBMVEX////+/v79/v79/v3+/f3+/Pv9/Pv7/P76/P77/Pv++vr6/Pr5/Pr5+/7++fn++u/3+/j+9vX1+vbz+fTz9/798/Px9v7w+PL98fDu9P7t9/D97u397evq8f3m9Orn7/386Obg8uX967Te8ePc6P3b6PzZ5vzW7dz95Jz62db945r62NXx5anR69jQ69fT4vz61NHM6dTO3/zN3vz93YP50M3L3fzI59D5zsrI3Pv5zMn5zMjE5c34yMT81GH4w7644MP80li138D80FD3vrn2z1Ot3Lr3t7KwzPr7yDX2s66n2bSm2bSl2LKmxvmd1az7wRqZ06mZ06j7vgz7vQv0pJ36vQz7vAX0opv6uwWT0KP0oJmR0KKXvPj0npfXwDPvugn6tAfzm5SRuPiKzZzzmZKGy5mEypfyk4vXuBOByZT2oiL3pQ3yj4fxioF8qvd0w4nxhHuxtCFwwYb0jxbwfXNxo/adsilmvX5hu3nyghteunZnnPbvcmdduXZauHNZuHLubmPubWJXt3FgmPXubGHua2Dual5Xq5ruaV7uaF1blfVwrjtZlPXtZlvtZVrtZFhNsmhLsmftY1RJsWXvaCZSj/REr2HsXFBNjPRBrV5PqkhJifTsVkpCnZo7q1lEhvRChfQ7qFBBhfBAiOM+jczsUy47larrT0I2qFI1qFQ0qFM3n3g1pGE0p1TqSz3qSTzqRzPqRDTqQzVB7J7JAAABv0lEQVR42uzSA4IjURSF4ROMbdu2bdv2tG3btu2O8xYYFeKk1K5vAf/DvVBAMgrIZDKZbKrtfBxd3WU0tObFPdgKURR3s4iL4hsQ7koN8VB+EcIsDyU+fFdDgI0lxKck8LellvhkvAzeFudL10IUYZV9uXboyLWPRYJb5wgj/yRoJ/OFteY3ENpvNVjz/glpYXeiiWr9g3i3PkQOE5soiLfizYcPv5oIGV0H8Q5+sPmUbn4HCVz/4BCxCozzHs6As0dU7A5YfZ4WgquXVOxsgNg2cPWWih0OENsvZey0lM/cK+UANvFdjR+rwYhhZFKtXiXPpU0ZewEv36hYBThb8f7Dh5+VGk37GnhYUEXF/oC7ex/COjQ2/+HhYR/lErg7Gt+vcfgKN/s6qVbnMnC3qEBD+6yC04nmPso38HFKw0g7BtqukO4BqtW2Hrz817CyX189cPz2q4RxjUY76Ig9Bz9L0jS+6IZsLevKOBhIBFLNWE1bt3xhiwgDyUC8ejVW0CHPQAbgScFmVrE4A3nAvAHdqOne5HfEmR0qkI2a5CvAQBGQc8uqnzJ//sTqAh9lhuEERsEoGAWjYBRQdQQZAFcxmt56IupnAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-size: contain;
  color: grey;
  font-family: 'Roboto-Medium';
  padding-left: 46px;

  &:hover {
    background-color: lighten(white, 6%);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABLCAMAAADtTJGhAAACHFBMVEX////+/v79/v79/v3+/f3+/Pv9/Pv7/P76/P77/Pv++vr6/Pr5/Pr5+/7++fn++u/3+/j+9vX1+vbz+fTz9/798/Px9v7w+PL98fDu9P7t9/D97u397evq8f3m9Orn7/386Obg8uX967Te8ePc6P3b6PzZ5vzW7dz95Jz62db945r62NXx5anR69jQ69fT4vz61NHM6dTO3/zN3vz93YP50M3L3fzI59D5zsrI3Pv5zMn5zMjE5c34yMT81GH4w7644MP80li138D80FD3vrn2z1Ot3Lr3t7KwzPr7yDX2s66n2bSm2bSl2LKmxvmd1az7wRqZ06mZ06j7vgz7vQv0pJ36vQz7vAX0opv6uwWT0KP0oJmR0KKXvPj0npfXwDPvugn6tAfzm5SRuPiKzZzzmZKGy5mEypfyk4vXuBOByZT2oiL3pQ3yj4fxioF8qvd0w4nxhHuxtCFwwYb0jxbwfXNxo/adsilmvX5hu3nyghteunZnnPbvcmdduXZauHNZuHLubmPubWJXt3FgmPXubGHua2Dual5Xq5ruaV7uaF1blfVwrjtZlPXtZlvtZVrtZFhNsmhLsmftY1RJsWXvaCZSj/REr2HsXFBNjPRBrV5PqkhJifTsVkpCnZo7q1lEhvRChfQ7qFBBhfBAiOM+jczsUy47larrT0I2qFI1qFQ0qFM3n3g1pGE0p1TqSz3qSTzqRzPqRDTqQzVB7J7JAAABv0lEQVR42uzSA4IjURSF4ROMbdu2bdv2tG3btu2O8xYYFeKk1K5vAf/DvVBAMgrIZDKZbKrtfBxd3WU0tObFPdgKURR3s4iL4hsQ7koN8VB+EcIsDyU+fFdDgI0lxKck8LellvhkvAzeFudL10IUYZV9uXboyLWPRYJb5wgj/yRoJ/OFteY3ENpvNVjz/glpYXeiiWr9g3i3PkQOE5soiLfizYcPv5oIGV0H8Q5+sPmUbn4HCVz/4BCxCozzHs6As0dU7A5YfZ4WgquXVOxsgNg2cPWWih0OENsvZey0lM/cK+UANvFdjR+rwYhhZFKtXiXPpU0ZewEv36hYBThb8f7Dh5+VGk37GnhYUEXF/oC7ex/COjQ2/+HhYR/lErg7Gt+vcfgKN/s6qVbnMnC3qEBD+6yC04nmPso38HFKw0g7BtqukO4BqtW2Hrz817CyX189cPz2q4RxjUY76Ig9Bz9L0jS+6IZsLevKOBhIBFLNWE1bt3xhiwgDyUC8ejVW0CHPQAbgScFmVrE4A3nAvAHdqOne5HfEmR0qkI2a5CvAQBGQc8uqnzJ//sTqAh9lhuEERsEoGAWjYBRQdQQZAFcxmt56IupnAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-size: contain;
    border-color: lighten($button-color-three, 6%);
    color: darkgray !important;
  }
}
</style>
