var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fieldset',{staticClass:"preview-fieldset"},[_c('legend',[_vm._v("Preview")]),_c('dynamic-input',{attrs:{"question":_vm.question,"value":_vm.testValue,"validation-rules":_vm.question.validationRules,"help-html":_vm.helpHtml},on:{"update":function (ref) {
	var value = ref.value;

	return _vm.testValue= value;
}}})],1),_c('fieldset',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"spaced",attrs:{"id":"this-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Property Name","rules":{ required: true, alpha: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Property Name","error-messages":errors,"success":valid,"is-required":true},model:{value:(_vm.question.propertyName),callback:function ($$v) {_vm.$set(_vm.question, "propertyName", $$v)},expression:"question.propertyName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Title","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Title","error-messages":errors,"success":valid,"is-required":true},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}})]}}],null,true)}),_c('e-textarea',{attrs:{"label":"Description","data-test":"description-textarea"},model:{value:(_vm.question.description),callback:function ($$v) {_vm.$set(_vm.question, "description", $$v)},expression:"question.description"}}),_c('e-select',{attrs:{"label":"Help","value":_vm.question.helpId || '',"items":_vm.helpAsSelectOptions},on:{"change":_vm.setHelp}}),_c('fieldset',[_c('legend',[_vm._v("Validation Rules")]),_c('e-select',{attrs:{"items":_vm.remainingValidationOptions,"default-option-text":"Add Rule"},on:{"change":_vm.addValidationRule}}),(_vm.question.validationRules)?_c('ul',_vm._l((Object.keys(_vm.question.validationRules)),function(rule){return _c('li',{key:rule},[_vm._v(" "+_vm._s(_vm.validationOptions.find(function (ref) {
	var text = ref.text;
	var value = ref.value;

	return value === rule;
}).text)+" "),_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeValidationRule(rule)}}})],1)}),0):_vm._e()],1),_c('e-select',{attrs:{"value":_vm.question.defaultValue || '',"items":_vm.question.options,"label":"Default Value"},on:{"change":_vm.setDefaultValue}}),_c('div',{staticClass:"bottom-actions"},[_c('e-button',{attrs:{"type":"submit"}},[_vm._v(" Commit ")]),_c('e-button',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }