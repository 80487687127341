<template>
  <div class="dialog">
    HOLD KEY ON EARPIECE UNTIL AFTER PIN ENTRY
  </div>
</template>

<script>
export default {
  name: 'NfcInfoDialog'
}
</script>

<style scoped lang="scss">
.dialog {
  background-color: $main-background-color;
  border-radius: 0 0 $corner $corner;
  color: $white;
  font-size: $font-small;
  font-weight: bold;
  left: 50%;
  margin: 0;
  padding: 1rem;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  transform: translateX(-50%);
  z-index: 9;
}

@media screen and (min-width: $media-break-1) {
  .dialog {
    display: none;
  }
}
</style>
