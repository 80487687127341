<template>
  <div class="confirmation-buttons">
    <e-button
      :type="confirmButtonType"
      button-type="primary"
      button-style="four"
      data-test="confirm-button"
      @click="$emit('confirm')"
    >
      {{ confirmButtonText }}
    </e-button>

    <e-button
      :type="cancelButtonType"
      button-type="secondary"
      button-style="three"
      data-test="cancel-button"
      @click="$emit('cancel')"
    >
      {{ cancelButtonText }}
    </e-button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationButtons',

  props: {
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },

    confirmButtonType: {
      type: String,
      default: 'button'
    },

    cancelButtonType: {
      type: String,
      default: 'button'
    }
  },

  emits: [
    'confirm',
    'cancel'
  ]
}
</script>

<style scoped lang="scss">
.confirmation-buttons {
  text-align: right;

  & > :nth-child(1) {
    margin-right: 0.5rem;
  }
}
</style>
