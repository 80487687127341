<template>
  <div>
    <confirmation-dialog
      v-if="selectedFileId"
      @confirm="handleDeleteConfirmation"
      @cancel="selectedFileId = ''"
    />

    <e-table
      :headers="headers"
      :rows="fileList"
      data-test="files-table"
    >
      <template #actions="{ item }">
        <div
          v-if="item.fileUrl"
          class="actions"
        >
          <e-button
            download
            class="icon-download ghost"
            data-test="download-button"
            @click="downloadFile({
              fileName: item.fileName,
              fileMimeType: item.fileMimeType,
              fileUrl: item.fileUrl,
              source: item.source,
              encryptDecryptKey: item.encryptDecryptKey
            })"
          />

          <e-button
            v-if="allowDelete"
            class="icon-trash-2 ghost"
            data-test="delete-file-button"
            @click="selectedFileId = item.id"
          />
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import ETable from '@/components/ETable'
import createFileActionObject from '@/utils/createFileActionObject'
import decryptFile from '@/utils/decryptFile'
import { format, parseISO } from 'date-fns'
import { paramCase } from 'change-case'

export default {
  name: 'FilesTable',

  components: {
    ConfirmationDialog,
    ETable
  },

  props: {
    files: {
      type: Array,
      default: () => []
    },

    allowDelete: {
      type: Boolean,
      default: true
    },

    showOwner: {
      type: Boolean,
      default: false
    }
  },

  emits: ['delete'],

  data () {
    return {
      selectedFileId: '',
      headers: [
        {
          text: 'File Name',
          value: 'fileName',
          align: 'left'
        },
        {
          text: 'Reference',
          value: 'reference',
          align: 'left'
        },
        {
          text: 'Uploaded Date',
          value: 'uploadDate',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ]
    }
  },

  computed: {
    fileList () {
      return this.files.map(({ id, fileName, fileMimeType, fileUrl, encryptDecryptKey, reference, owner, ownerType, uploadDate, source }) => ({
        id,
        fileName,
        fileMimeType,
        fileUrl,
        encryptDecryptKey,
        source,
        reference,
        ...owner && {
          owner: {
            text: owner?.name,
            routeName: `/${ownerType}/${paramCase(owner?.type).toLowerCase()}/${owner?.id}`,
            id: owner?.id
          }
        },
        uploadDate: format(parseISO(uploadDate), 'dd/MM/yyyy'),
        rowClickedFunction: () => {}
      }))
    }
  },

  created () {
    if (this.showOwner) {
      this.headers.splice(2, 0, {
        text: 'Attached to',
        value: 'owner',
        align: 'center'
      })
    }
  },

  methods: {
    handleDeleteConfirmation () {
      this.$emit('delete', this.selectedFileId)
      this.selectedFileId = ''
    },

    async downloadFile (fileRecord) {
      try {
        const fileActionObject = createFileActionObject({
          name: fileRecord.fileName,
          type: 'download'
        })

        this.$store.commit('files/addFileActionObject', fileActionObject)

        const { data: encryptedFile } = await this.$store.dispatch('files/downloadFileFromApi', {
          fileUrl: fileRecord.fileUrl,
          progressExecutor: event => fileActionObject.progressUpdator(event),
          source: fileRecord.source
        })

        const decryptedFile = await decryptFile({
          encryptedFile,
          decryptKey: fileRecord.encryptDecryptKey,
          filename: fileRecord.fileName,
          mimeType: fileRecord.fileMimeType
        })

        const url = URL.createObjectURL(decryptedFile, { type: fileRecord.fileMimeType })

        const a = document.createElement('a')
        a.href = url
        a.download = fileRecord.fileName
        a.click()

        URL.revokeObjectURL(url)
      } catch (error) {
        if (error.message === 'canceled') return

        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'FilesTable',
          functionName: 'downloadFile'
        })
      }
    }
  }
}
</script>
