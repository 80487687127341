var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" General Release Of Liability "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"non-disclosure-agreement-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"General Relaease Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"General Relaease Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.generalRelaeaseDate),callback:function ($$v) {_vm.generalRelaeaseDate=$$v},expression:"generalRelaeaseDate"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Consideration Sum"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.considerationSum),callback:function ($$v) {_vm.considerationSum=$$v},expression:"considerationSum"}}),_c('fieldset',[_c('legend',[_vm._v("Releasor")]),_c('validation-provider',{attrs:{"name":"Releasor","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releasorSelectOptions,"label":"Releasor","default-option-text":"Add Releasor","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releasorId),callback:function ($$v) {_vm.releasorId=$$v},expression:"releasorId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Releasor Notary","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releasorNotarySelectOptions,"label":"Releasor Notary","default-option-text":"Add Releasor Notary","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releasorNotaryId),callback:function ($$v) {_vm.releasorNotaryId=$$v},expression:"releasorNotaryId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Releasor Witness","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releasorWitnessSelectOptions,"label":"Releasor Witness","default-option-text":"Add Releasor Witness","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releasorWitnessId),callback:function ($$v) {_vm.releasorWitnessId=$$v},expression:"releasorWitnessId"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Releasee")]),_c('validation-provider',{attrs:{"name":"Releasee","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releaseeSelectOptions,"label":"Releasee","default-option-text":"Add Releasee","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releaseeId),callback:function ($$v) {_vm.releaseeId=$$v},expression:"releaseeId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Releasee Notary","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releaseeNotarySelectOptions,"label":"Releasee Notary","default-option-text":"Add Releasee Notary","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releaseeNotaryId),callback:function ($$v) {_vm.releaseeNotaryId=$$v},expression:"releaseeNotaryId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Releasee Witness","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.releaseeWitnessSelectOptions,"label":"Releasee Witness","default-option-text":"Add Releasee Witness","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.releaseeWitnessId),callback:function ($$v) {_vm.releaseeWitnessId=$$v},expression:"releaseeWitnessId"}})]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Seal Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Seal Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.sealDate),callback:function ($$v) {_vm.sealDate=$$v},expression:"sealDate"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"non-disclosure-agreement-form","button-text":"Preview"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }