<template>
  <router-view />
</template>

<script>
export default {
  name: 'Requests',

  created () {
    this.$emit('set-active', 'requests')
  }
}
</script>
