<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Property Declaration Of Trust

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="property-declaration-of-trust-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Declaration Of Trust Date"
            rules="required"
            slim
          >
            <e-input
              v-model="declarationOfTrustDate"
              label="Declaration Of Trust Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Property</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Property Address"
              :rules="{ required: true }"
              slim
            >
              <e-textarea
                v-model="propertyAddress"
                label="Property Address"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <div class="connect-input">
              <e-input
                v-model="propertyTitleNumberToAdd"
                label="Property Title Number"
                class="input-wide"
              />

              <e-button
                :disabled="!propertyTitleNumberToAdd"
                @click="addPropertyTitleNumber"
              >
                <span class="icon-plus" />
                Add Title
              </e-button>
            </div>

            <table v-if="propertyTitleNumbers.length">
              <thead>
                <tr>
                  <th>Property Title Number</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="propertyTitleNumber in propertyTitleNumbers"
                  :key="propertyTitleNumber"
                >
                  <td>
                    {{ propertyTitleNumber }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removePropertyTitleNumber(propertyTitleNumber)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <e-textarea
              v-model="restrictionToBeEnteredOnTitleNumber"
              label="Restriction To Be Entered On Title Number"
              @input="setIsDirty(true)"
            />

            <e-input
              v-model="propertyOutgoingsCostText"
              placeholder="Property Outgoings Cost"
            />

            <e-input
              v-model="saleWrittenConsentFullName"
              placeholder="Sale Written Consent By Full Name"
            />
          </fieldset>

          <fieldset>
            <legend>Trustee and Beneficiary</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Trustee"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="trusteeId"
                :items="trusteeSelectOptions"
                label="Trustee"
                default-option-text="Add Trustee"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Beneficiary"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="beneficiaryId"
                :items="beneficiarySelectOptions"
                label="Beneficiary of Property"
                default-option-text="Add Beneficiary of Property"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <e-input
              v-model="beneficiaryContributionSum"
              placeholder="Beneficiary Contribution Sum"
            />

            <e-textarea
              v-model="rentalIncomeSplitInFollowingManner"
              placeholder="Rental Income Split In Following Manner"
            />

            <e-textarea
              v-model="terms"
              placeholder="Terms"
            />
          </fieldset>

          <fieldset>
            <legend>Witnesses</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Witness"
              :rules="{ array_length: true }"
              slim
            >
              <input
                v-model="witnesses.length"
                type="hidden"
              >

              <e-select
                :items="witnessesSelectOptions"
                default-option-text="Add Witness"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="addWitness"
              />
            </validation-provider>

            <table v-if="witnesses.length">
              <thead>
                <tr>
                  <th>Witness</th>
                  <th>Signed As Deed By</th>
                  <th>In Presence Of</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="witness in witnesses"
                  :key="witness.id"
                >
                  <td>
                    {{ witness.contact.fullName }}
                  </td>

                  <td>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Signed As Deed By"
                      :rules="{ required: true }"
                      slim
                    >
                      <e-input
                        v-model="witness.signedAsDeedBy"
                        placeholder="Signed As Deed By"
                        :error-messages="errors"
                        :success="valid"
                        :is-required="true"
                        @input="setIsDirty(true)"
                      />
                    </validation-provider>
                  </td>

                  <td>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="In Presence Of"
                      :rules="{ required: true }"
                      slim
                    >
                      <e-input
                        v-model="witness.inPresenceOf"
                        placeholder="In Presence Of"
                        :error-messages="errors"
                        :success="valid"
                        :is-required="true"
                        @input="setIsDirty(true)"
                      />
                    </validation-provider>
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeWitness(witness.contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="property-declaration-of-trust-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'propertyDeclarationOfTrustAstonVeer'

export default {
  name: 'PropertyDeclarationOfTrust',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: '7ec56fa5-3752-422e-95df-dde5382617af',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      declarationOfTrustDate: '',
      propertyAddress: '',
      propertyTitleNumbers: [],
      propertyTitleNumberToAdd: '',
      trusteeId: '',
      beneficiaryId: '',
      beneficiaryContributionSum: '',
      propertyOutgoingsCostText: '',
      rentalIncomeSplitInFollowingManner: '',
      saleWrittenConsentFullName: '',
      restrictionToBeEnteredOnTitleNumber: '',
      terms: '',
      witnesses: [],

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    trusteeSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.beneficiaryId,
          ...this.witnesses.map(({ contact }) => contact.id)
        ].includes(id))
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    beneficiarySelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => ![
          this.trusteeId,
          ...this.witnesses.map(({ contact }) => contact.id)
        ].includes(id))
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    witnessesSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          this.trusteeId,
          this.beneficiaryId,
          ...this.witnesses.map(({ contact }) => contact.id)
        ].includes(id))
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addPropertyTitleNumber () {
      this.propertyTitleNumbers = [...new Set([
        ...this.propertyTitleNumbers,
        this.propertyTitleNumberToAdd
      ])]
      this.propertyTitleNumberToAdd = ''
    },

    removePropertyTitleNumber (propertyTitleNumberToRemove) {
      this.propertyTitleNumbers = this.propertyTitleNumbers
        .filter(propertyTitleNumber => propertyTitleNumber !== propertyTitleNumberToRemove)
    },

    addWitness (contactId) {
      this.witnesses.push({
        contact: this.userAndContacts.find(({ id }) => id === contactId),
        signedAsDeedBy: '',
        inPresenceOf: ''
      })
      this.setIsDirty(true)
    },

    removeWitness (contactId) {
      this.witnesses = this.witnesses
        .filter(witness => contactId !== witness.contact.id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            declarationOfTrustDate: this.declarationOfTrustDate,
            propertyAddress: this.propertyAddress,
            propertyTitleNumbers: this.propertyTitleNumbers,
            trusteeId: this.trusteeId,
            ...this.trusteeId && {
              trustee: this.userAndContacts.find(({ id }) => id === this.trusteeId)
            },
            beneficiaryId: this.beneficiaryId,
            ...this.beneficiaryId && {
              beneficiary: this.userAndContacts.find(({ id }) => id === this.beneficiaryId)
            },
            beneficiaryContributionSum: this.beneficiaryContributionSum,
            propertyOutgoingsCostText: this.propertyOutgoingsCostText,
            rentalIncomeSplitInFollowingManner: this.rentalIncomeSplitInFollowingManner,
            saleWrittenConsentFullName: this.saleWrittenConsentFullName,
            restrictionToBeEnteredOnTitleNumber: this.restrictionToBeEnteredOnTitleNumber,
            terms: this.terms,
            witnesses: this.witnesses
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'PropertyDeclarationOfTrust',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'PropertyDeclarationOfTrust',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
