import HelpCategoryArticles from '../views/Help/HelpCategoryArticles'
import HelpArticle from '../views/Help/HelpArticle'
import Vue from 'vue'
// import getArticle from '../apis/cms/help/getArticle'
import getArticles from '../apis/cms/help/getArticles'
import getCategories from '../apis/cms/help/getCategories'
import getSearchResults from '../apis/cms/help/getSearchResults'
import updateLiked from '../apis/cms/updateLiked'

const createDynamicRoutes = ({ categories, router }) => {
  categories.forEach(category => {
    router.addRoute('Help', {
      path: ':categoryId',
      name: `help-${category.id}`,
      props: true,
      component: HelpCategoryArticles,
      meta: { title: category.title }
    })
    category.articles.forEach(article => {
      router.addRoute('Help', {
        path: ':categoryId/:articleId',
        name: `help-${article.subCategory.id}-${article.id}`,
        props: true,
        component: HelpArticle,
        meta: { title: article.title }
      })
    })
  })
}

export default {
  namespaced: true,

  state: {
    categories: []
  },

  getters: {
    getArticle: state => articleId => state.categories
      .flatMap(({ articles }) => articles)
      .find(({ id }) => id === articleId),

    getSearchResults: state => searchString => state.categories.flatMap(({ articles }) => articles).filter(({ title }) => title.toLowerCase().includes(searchString.toLowerCase()))
  },

  actions: {
    getCategories: async ({ commit }) => {
      try {
        const response = await getCategories({
          jwt: sessionStorage.getItem('jwt')
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setCategories: async ({ commit, dispatch }, router) => {
      const categories = await dispatch('getCategories')
      commit('setCategories', categories)
      createDynamicRoutes({ categories, router })
    },

    getSearchResults: async ({ commit }, searchString) => {
      try {
        const response = await getSearchResults({
          jwt: sessionStorage.getItem('jwt'),
          searchString
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getArticles: async ({ commit }, { categoryId }) => {
      try {
        const response = await getArticles({
          jwt: sessionStorage.getItem('jwt'),
          categoryId
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    // getArticle: async ({ commit }, { articleId }) => {
    //   try {
    //     const response = await getArticle({
    //       jwt: sessionStorage.getItem('jwt'),
    //       articleId
    //     })

    //     return response.data
    //   } catch (error) {
    //     if (error.response?.status === 401) {
    //       commit('setShowRefreshJwtDialog', true, { root: true })
    //     }

    //     throw error
    //   }
    // },

    updateLiked: async ({ commit }, { articleId, isLiked }) => {
      try {
        const response = await updateLiked({
          jwt: sessionStorage.getItem('jwt'),
          articleId,
          isLiked
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setCategories: (state, value) => Vue.set(state, 'categories', value),

    updateArticleIsLiked: (state, { categoryId, articleId, isLiked }) => {
      Vue.set(state, 'categories', state.categories.map(category => ({
        ...category,
        ...(category.id === categoryId && {
          articles: category.articles.map(article => article.id === articleId ? { ...article, isLiked } : article)
        })
      }))
      )
    }
  }
}
