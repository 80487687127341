<template>
  <router-view
    @deleted="handleDeleted"
    @submitted="handleSubmitted"
  />
</template>

<script>
import Credential from '@/models/Credential'

export default {
  name: 'Credentials',

  emits: [
    'set-active',
    'deleted',
    'submitted'
  ],

  created () {
    this.$emit('set-active', 'secrets')

    const currentRouteName = this.$router.history.current.name
    if (currentRouteName === 'Credentials') {
      const credentialId = Credential.query().first()?.id

      const routeObject = credentialId
        ? { name: 'CredentialView', params: { id: credentialId } }
        : { name: 'NewCredential' }
      this.$router.push(routeObject)
    }
  },

  methods: {
    handleDeleted (routeObject) {
      this.$emit('deleted', routeObject)
    },

    handleSubmitted (routeObject) {
      this.$emit('submitted', routeObject)
    }
  }
}
</script>
