'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async jwt => {
  const { data } = await axios({
    method: 'get',
    url: `${VUE_APP_GATEWAY_URL}/idp/gdpr`,
    headers: { Authorization: jwt },
    timeout: 8000
  })

  return data
}
