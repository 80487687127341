<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Wishes
        </h1>

        <accordion
          v-if="items.length > 0"
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @set-active="setActiveCategory"
          @deleted="handleDeleted"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import BucketListItem from '@/models/BucketListItem'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Wishes',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      activeCategory: ''
    }
  },

  computed: {
    items () {
      return [
        {
          id: 'requests',
          text: 'Requests',
          isActive: this.activeCategory === 'requests',
          sub: [
            {
              id: 'funeralWishes',
              text: 'Funeral Wishes',
              routeName: 'FuneralWishes'
            },

            {
              id: 'memorial',
              text: 'Memorial',
              routeName: 'Memorial'
            }
          ]
          // .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
        },

        {
          id: 'bucketListItems',
          text: 'Bucket List',
          isActive: this.activeCategory === 'bucketListItems',
          sub: BucketListItem
            .query()
            .get()
            .map(({ id, title, isDone }) => ({
              id,
              text: title,
              completed: isDone,
              routeName: 'BucketListItem',
              paramKey2: 'id'
            }))
            .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1),

          additionals: [
            {
              text: 'Add new',
              routeName: 'NewBucketListItem'
            }
          ]
        }
      ]
    }
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    },

    handleDeleted (routeObject) {
      this.$router.push(routeObject)
    },

    handleSubmitted (routeObject) {
      const notADoubleClick = routeObject.name !== this.$route.name

      if (notADoubleClick) {
        this.$router.push(routeObject)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 0.6em;
}
</style>
