<template>
  <div>
    <div class="top-inputs">
      <h1>My Purchases</h1>
    </div>

    <e-dialog
      v-if="showConfirmRefundRequest"
      class="dialog-container"
      data-test="client-refund-request"
    >
      <div class="header">
        <h1>Confirm Refund Request</h1>
        <e-button class="close-button icon-x-circle ghost" @click="closeConfirmRequest" />
      </div>
      <div class="body">
        <div class="info">
          <h2>
            {{ refundItem.title }}
          </h2>
          A {{ refundItem.type }} from
          {{ refundItem.vendorName }}. When you click the Confirm button,
          a request will be sent to the vendor for review. <br>They may contact you in relation to this request.
          <div class="cost-and-button">
            <div class="refund-button-container">
              <e-button class="confirm-button" @click="doRefundRequest">
                Confirm Request
              </e-button>
              <e-button class="cancel-button" @click="closeConfirmRequest">
                Cancel
              </e-button>
            </div>
          </div>
        </div>
      </div>
    </e-dialog>

    <e-table :headers="headers" :rows="statement">
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            v-if="item.userReference"
            class="icon-link ghost"
            @click="gotoRoute(item)"
          >
            USE
          </e-button>
          <e-button
            v-if="item.routeName"
            class="icon-link ghost"
            @click="gotoRoute(item)"
          >
            USE
          </e-button>
          <e-button
            v-if="refunableItem(item)"
            :id="item.id"
            class="ghost"
            @click="requestRefund(item)"
          >
            REFUND
          </e-button>
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import ETable from '@/components/ETable'
import EDialog from '@/components/dialogs/EDialog'
import { format } from 'date-fns'

export default {
  name: 'Purchases',

  components: {
    ETable,
    EDialog
  },

  data () {
    return {
      showConfirmRefundRequest: false,
      intervalID: 0,
      refundItem: {},
      headers: [
        {
          text: 'Date',
          value: 'created',
          align: 'left'
        },
        {
          text: 'Item',
          value: 'title',
          align: 'left'
        },
        {
          text: 'Vendor',
          value: 'vendorName',
          align: 'left'
        },
        {
          text: 'Type',
          value: 'type',
          align: 'left'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  computed: {
    statement () {
      return this.$store.state.marketplace.purchases
        .map(purchase => ({
          id: purchase.id,
          ts: purchase.ts,
          type: purchase.type === 'product' ? 'Add-on' : purchase.type.substring(0, 1).toUpperCase() + purchase.type.substring(1),
          created: format(purchase.ts, 'dd/MM/yyyy - HH:mm'),
          title: purchase.itemTitle,
          vendorName: purchase.vendorName,
          vendorEmail: purchase.vendorEmail,
          status: purchase.status,
          sku: purchase.sku,
          routeName: purchase.routeName,
          userReference: purchase.userReference,
          rowClickedFunction: () => { }
        })).sort((a, b) => b.ts - a.ts)
    }
  },

  beforeDestroy () { clearInterval(this.intervalID) },

  created () {
    this.userId = this.$store.state.userId
    this.userEmail = this.$store.getters.userEmailAddress
    this.updatePurchases()
    this.intervalID = setInterval(() => this.updatePurchases(), 15000)
    this.$store.dispatch('findAndSetHelpObject', {
      id: 82,
      isInitial: true
    })
  },

  methods: {

    async updatePurchases () {
      await this.$store.dispatch('marketplace/setPurchases')
    },

    // use item and if there is a userReference, create a router object with the letterId from the userReference
    /*
    this.$router.push({
        name: this.isScheduled ? 'ScheduledLetter' : 'UnscheduledLetter',
        params: { id: item.id }
      })
   */

    gotoRoute (item) {
      if (item.userReference) {
        this.$router.push({
          name: 'ScheduledLetter',
          params: { id: item.userReference }
        })
      } else {
        this.$router.push({ name: item.routeName })
      }
    },

    requestRefund (item) {
      this.refundItem = item
      this.showConfirmRefundRequest = true
    },

    closeConfirmRequest () {
      this.showConfirmRefundRequest = false
    },

    async doRefundRequest () {
      const refButton = document.getElementById(this.refundItem.id)
      refButton.style.visibility = 'hidden'
      await this.$store.dispatch('marketplace/refundPurchase', this.refundItem)
      this.showConfirmRefundRequest = false
    },

    refunableItem (item) {
      return item.type && item.type.toLowerCase() === 'service' && item.status && ['active', 'delivered'].includes(item.status.toLowerCase())
    }
    // async getItemDetail (receiptId) {
    //   this.itemDetail = await getDetail({ jwt: sessionStorage.getItem('jwt'), receiptId })
    // }
  }
}
</script>

<style>
.refund-button-container {
  float: right;
  margin-top: 2rem;
}

.processing::after {
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0;
}
</style>
