'use strict'

export default {
  namespaced: true,

  state: {
    epoch: Date.now(),
    message: '',
    type: 'info',
    timeout: 4000
  },

  mutations: {
    update: (state, { type = 'info', message, timeout = 4000 }) => {
      state.epoch = Date.now()
      state.type = type
      state.message = message
      state.timeout = timeout
    }
  }
}
