<template>
  <fieldset>
    <legend>Help Item</legend>

    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Description"
          :rules="{ required: true }"
          slim
        >
          <e-input
            v-model="description"
            label="Description"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
            :help-function="() => $store.commit('setHelpObject', html)"
          />
        </validation-provider>

        <rich-text-editor
          v-model="html"
          label="Text"
        />

        <div class="bottom-actions">
          <e-button type="submit">
            Commit
          </e-button>

          <e-button @click="$emit('close', helpObject.id)">
            Close
          </e-button>
        </div>
      </form>
    </validation-observer>
  </fieldset>
</template>

<script>
import RichTextEditor from '../RichTextEditor'

export default {
  name: 'HelpItemElement',

  components: {
    RichTextEditor
  },

  props: {
    helpObject: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  emits: ['close', 'submit'],

  data () {
    return {
      html: this.helpObject.html,
      description: this.helpObject.description
    }
  },

  methods: {
    submit () {
      this.$emit('submit', {
        id: this.helpObject.id,
        description: this.description,
        html: this.html
      })
    }
  }
}
</script>
