<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Loan Agreement

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="loan-agreement-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Lender"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="lenderId"
              :items="lenderSelectOptions"
              label="Lender"
              default-option-text="Add Lender"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Borrower"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="borrowerId"
              :items="borrowerSelectOptions"
              label="Borrower"
              default-option-text="Add Borrower"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Agreement Date"
            rules="required"
            slim
          >
            <e-input
              v-model="loanAgreementDate"
              label="Loan Agreement Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Amount"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="loanAmount"
              label="Loan Amount"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="legalFees"
            label="Legal Fees"
            @input="setIsDirty(true)"
          />

          <validation-provider
            v-slot="{ errors, valid }"
            name="Interest Rate"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="interestRate"
              label="Interest Rate"
              type="number"
              step=".01"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="$emit('is-dirty')"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Interest Beginning On Date"
            rules="required"
            slim
          >
            <e-input
              v-model="interestBeginningOnDate"
              label="Interest Beginning On Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Repayment Date"
            rules="required"
            slim
          >
            <e-input
              v-model="loanRepaymentDate"
              label="Loan Repayment Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="latePaymentInterestRate"
            label="Late Payment Interest Rate"
            type="number"
            step=".01"
            @input="$emit('is-dirty')"
          />

          <e-input
            v-model="latePaymentPropertySoldBy"
            label="Late Payment Property Sold By"
            @input="setIsDirty(true)"
          />

          <e-input
            v-model="latePaymentPropertyReducedByAfterTwoMonths"
            label="Late Payment Property Reduced By After Two Months"
            @input="setIsDirty(true)"
          />

          <e-input
            v-model="latePaymentPropertyLowestSalePrice"
            label="Late Payment Property Lowest Sale Price"
            @input="setIsDirty(true)"
          />

          <e-textarea
            v-model="loanSecuredByFollowingSecurity"
            label="Loan Secured By Following Security"
            @input="setIsDirty(true)"
          />

          <validation-provider
            v-slot="{ errors, valid }"
            name="Witness Date"
            rules="required"
            slim
          >
            <e-input
              v-model="witnessDate"
              label="Witness Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Agreement Signed Sealed And Delivered By"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="loanAgreementSignedSealedAndDeliveredBy"
              label="Loan Agreement Signed Sealed And Delivered By"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Agreement Explained To Borrower By"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="loanAgreementExplainedToBorrowerBy"
              label="Loan Agreement Explained To Borrower By"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Loan Agreement Explaination Date"
            rules="required"
            slim
          >
            <e-input
              v-model="loanAgreementExplainationDate"
              label="Loan Agreement Explaination Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Solicitors Name"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="solicitorsName"
              label="Solicitors Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Solicitors Address"
            :rules="{ required: true }"
            slim
          >
            <e-textarea
              v-model="solicitorsAddress"
              label="Solicitors Address"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="loan-agreement-form"
              button-text="Preview"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'loanAgreementAstonVeer'

export default {
  name: 'LoanAgreement',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'efe620ee-9245-4084-afa9-d2505c7d7163',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      loanAgreementDate: '',
      lenderId: '',
      borrowerId: '',
      loanAmount: '',
      legalFees: '',
      interestRate: 0,
      interestBeginningOnDate: '',
      loanRepaymentDate: '',
      latePaymentInterestRate: 0,
      latePaymentPropertySoldBy: '',
      latePaymentPropertyReducedByAfterTwoMonths: '',
      latePaymentPropertyLowestSalePrice: '',
      loanSecuredByFollowingSecurity: '',
      witnessDate: '',
      loanAgreementSignedSealedAndDeliveredBy: '',
      loanAgreementExplainedToBorrowerBy: '',
      loanAgreementExplainationDate: '',
      solicitorsName: '',
      solicitorsAddress: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    lenderSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => this.borrowerId !== id)
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    borrowerSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => this.lenderId !== id)
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addPropertyTitleNumber () {
      this.propertyTitleNumbers = [...new Set([
        ...this.propertyTitleNumbers,
        this.propertyTitleNumberToAdd
      ])]
      this.propertyTitleNumberToAdd = ''
    },

    removePropertyTitleNumber (propertyTitleNumberToRemove) {
      this.propertyTitleNumbers = this.propertyTitleNumbers
        .filter(propertyTitleNumber => propertyTitleNumber !== propertyTitleNumberToRemove)
    },

    addContact ({ contactId, type }) {
      this[type].push(this.userAndContacts.find(({ id }) => id === contactId))
      this.setIsDirty(true)
    },

    removeContact ({ contactId, type }) {
      this[type] = this[type]
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            loanAgreementDate: this.loanAgreementDate,
            ...this.lenderId && {
              lender: this.userAndContacts.find(({ id }) => id === this.lenderId)
            },
            ...this.borrowerId && {
              borrower: this.userAndContacts.find(({ id }) => id === this.borrowerId)
            },
            loanAmount: this.loanAmount,
            legalFees: this.legalFees,
            interestRate: this.interestRate,
            interestBeginningOnDate: this.interestBeginningOnDate,
            loanRepaymentDate: this.loanRepaymentDate,
            latePaymentInterestRate: this.latePaymentInterestRate,
            latePaymentPropertySoldBy: this.latePaymentPropertySoldBy,
            latePaymentPropertyReducedByAfterTwoMonths: this.latePaymentPropertyReducedByAfterTwoMonths,
            latePaymentPropertyLowestSalePrice: this.latePaymentPropertyLowestSalePrice,
            loanSecuredByFollowingSecurity: this.loanSecuredByFollowingSecurity,
            witnessDate: this.witnessDate,
            loanAgreementSignedSealedAndDeliveredBy: this.loanAgreementSignedSealedAndDeliveredBy,
            loanAgreementExplainedToBorrowerBy: this.loanAgreementExplainedToBorrowerBy,
            loanAgreementExplainationDate: this.loanAgreementExplainationDate,
            solicitorsName: this.solicitorsName,
            solicitorsAddress: this.solicitorsAddress
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LoanAgreement',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LoanAgreement',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-wide {
  flex: 100;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
