<template>
  <div class="app-closed-container">
    <div class="app-closed-inner-block">
      <router-view name="appClosedLayout" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppClosedLayout'
}
</script>

<style scoped lang="scss">
.app-closed-container {
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;

  .app-closed-inner-block {
    background-color: $main-background-color;
    border-radius: 8px;
    box-shadow: 0 3px 8px 3px rgba(0, 0, 0, 0.14);
    min-width: 20rem;
    padding: 2rem;
    transition: ease-in-out left 2s;
  }
}
</style>
