'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ letterObject, jwt }) => {
  const url = letterObject.id
    ? `${VUE_APP_COMMS_URL}/letter/${letterObject.id}`
    : `${VUE_APP_COMMS_URL}/letter`

  return axios({
    method: 'put',
    headers: {
      Authorization: jwt
    },
    url,
    data: letterObject,
    timeout: 8000
  })
}
