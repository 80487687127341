'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ letterObject, jwt }) => axios({
  method: 'post',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_COMMS_URL}/letter/${letterObject.id}`,
  data: letterObject,
  timeout: 8000
})
