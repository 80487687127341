'use strict'

import axios from 'axios'

const { VUE_APP_CONTENT_URL } = process.env

export default async ({ jwt, searchString }) => axios({
  method: 'get',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_CONTENT_URL}/help/search`,
  params: {
    query: searchString
  },
  timeout: 8000
})
