var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" UK Land Registry Transfer Of Registered Title "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"uk-land-registry-transfer-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Property To Transfer")]),_c('div',{staticClass:"connect-input"},[_c('e-input',{staticClass:"input-wide",attrs:{"label":"Property Title Number"},model:{value:(_vm.propertyTitleNumberToAdd),callback:function ($$v) {_vm.propertyTitleNumberToAdd=$$v},expression:"propertyTitleNumberToAdd"}}),_c('e-button',{attrs:{"disabled":!_vm.propertyTitleNumberToAdd},on:{"click":_vm.addPropertyTitleNumber}},[_c('span',{staticClass:"icon-plus"}),_vm._v(" Add Title ")])],1),(_vm.propertyTitleNumbers.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Property Title Number")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.propertyTitleNumbers),function(propertyTitleNumber){return _c('tr',{key:propertyTitleNumber},[_c('td',[_vm._v(" "+_vm._s(propertyTitleNumber)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removePropertyTitleNumber(propertyTitleNumber)}}})],1)])}),0)]):_vm._e(),_c('validation-provider',{attrs:{"name":"Property Address","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Property Address","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.propertyAddress),callback:function ($$v) {_vm.propertyAddress=$$v},expression:"propertyAddress"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Transfer")]),_c('validation-provider',{attrs:{"name":"Property Transfer Type","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.propertyTitleTransferTypeSelectOptions,"label":"Property Transfer Type","default-option-text":"Add Property Transfer Type","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.propertyTitleTransferType),callback:function ($$v) {_vm.propertyTitleTransferType=$$v},expression:"propertyTitleTransferType"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Transferor","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transferors.length),expression:"transferors.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.transferors.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.transferors, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.contactSelectOptions,"label":"Transferor (Property Title Transfer From)","default-option-text":"Add Transferor","error-messages":errors,"success":valid,"is-required":true},on:{"change":function (contactId) { return _vm.addContact({ type: 'transferors', contactId: contactId }); }}})]}}],null,true)}),(_vm.transferors.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Transferor")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.transferors),function(tranferor){return _c('tr',{key:tranferor.id},[_c('td',[_vm._v(" "+_vm._s(tranferor.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeContact({ type: 'transferors', contactId: tranferor.id })}}})],1)])}),0)]):_vm._e(),_c('validation-provider',{attrs:{"name":"Transferee","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.transferees.length),expression:"transferees.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.transferees.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.transferees, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.contactSelectOptions,"label":"Transeree (Property Title Transfer To)","default-option-text":"Add Transferee","error-messages":errors,"success":valid,"is-required":true},on:{"change":function (contactId) { return _vm.addContact({ type: 'transferees', contactId: contactId }); }}})]}}],null,true)}),(_vm.transferees.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Transferee")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.transferees),function(transferer){return _c('tr',{key:transferer.id},[_c('td',[_vm._v(" "+_vm._s(transferer.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeContact({ type: 'transferees', contactId: transferer.id })}}})],1)])}),0)]):_vm._e(),_c('e-select',{attrs:{"items":_vm.transfereesDeclarationOfTrustSelectOptions,"label":"Transferees Declaration Of Trust","default-option-text":"Add Transferees Declaration Of Trust","disabled":!!(_vm.transferees.length < 2)},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.transfereesDeclarationOfTrust),callback:function ($$v) {_vm.transfereesDeclarationOfTrust=$$v},expression:"transfereesDeclarationOfTrust"}}),_c('validation-provider',{attrs:{"name":"Transfer Sum","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Transfer Sum","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.transferSum),callback:function ($$v) {_vm.transferSum=$$v},expression:"transferSum"}})]}}],null,true)})],1),_c('e-textarea',{attrs:{"label":"Additional Provisions"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.additionalProvisions),callback:function ($$v) {_vm.additionalProvisions=$$v},expression:"additionalProvisions"}}),_c('fieldset',[_c('legend',[_vm._v("Witness(es)")]),_c('validation-provider',{attrs:{"name":"Witnesses","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.witnesses.length),expression:"witnesses.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.witnesses.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.witnesses, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.witnessesSelectOptions,"default-option-text":"Add Witness","error-messages":errors,"success":valid,"is-required":true},on:{"change":function (contactId) { return _vm.addContact({ type: 'witnesses', contactId: contactId }); }}})]}}],null,true)}),(_vm.witnesses.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Witness")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.witnesses),function(witness){return _c('tr',{key:witness.id},[_c('td',[_vm._v(" "+_vm._s(witness.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",on:{"click":function($event){return _vm.removeContact({ type: 'witnesses', contactId: witness.id })}}})],1)])}),0)]):_vm._e()],1),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"uk-land-registry-transfer-form","button-text":"Preview"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }