<template>
  <label :class="type" :data-test="dataTest">
    <div
      v-if="label"
      :class="labelFirst ? 'question-text' : 'label-text'"
      :style="{ order: labelFirst ? 0 : 1 }"
    >
      {{ label }}

      <span
        v-if="helpId"
        class="icon icon-help-circle"
        @click.prevent="setHelp"
      />

      <span
        v-if="neededForIsValid && isValid(value)"
        class="icon icon-alert-triangle"
        :style="{ color: 'orange' }"
      />
    </div>

    <p
      v-if="description"
      v-html="purify(description)"
    />

    <input
      v-model="mutableValue"
      :value="radioValue !== null ? radioValue : mutableValue"
      :type="type"
      :class="{ invalid: isRequired && !success }"
      :placeholder="placeholder"
      :maxlength="maxlength"
      v-bind="$attrs"
      @focus="$emit('focus')"
      @keyup="$emit('keyup', $event)"
      @change="emitChange"
      @input="delay ? emitDebouncedInput() : emitInput()"
    >

    <span class="flip">
      <span class="tick" />
    </span>

    <span
      v-if="errorMessages.length"
      class="error-text"
      data-test="error-message"
    >
      {{ errorMessages[0] }}
    </span>
  </label>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'EInput',

  inheritAttrs: false,

  props: {
    dataTest: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number, Boolean],
      default: null
    },

    label: {
      type: String,
      default: ''
    },

    maxlength: {
      type: Number,
      default: 100
    },

    placeholder: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    radioValue: {
      type: [String, Number, Boolean],
      default: null
    },

    success: {
      type: Boolean,
      default: false
    },

    delay: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    neededForIsValid: {
      type: Boolean,
      default: false
    },

    isLabelFirst: {
      type: Boolean,
      default: null
    },

    helpId: {
      type: [String, Number],
      default: null
    },

    helpFunction: {
      type: Function,
      default: ({ store, id }) => store.dispatch('findAndSetHelpObject', { id })
    }
  },

  emits: [
    'focus',
    'keyup',
    'input',
    'change'
  ],

  data () {
    return {
      valueToEmit: this.value
    }
  },

  computed: {
    mutableValue: {
      get () {
        return this.value
      },

      set (value) {
        this.valueToEmit = value
      }
    },

    labelFirst () {
      return this.isLabelFirst || !['checkbox', 'radio'].includes(this.type)
    }
  },

  methods: {
    isValid (value) {
      return this.type === 'number'
        ? value < 0.01
        : !value
    },

    emitDebouncedInput: debounce(
      function () {
        this.$emit('input', this.valueToEmit)
      },
      700
    ),

    emitInput () {
      this.$emit('input', this.valueToEmit)
    },

    emitChange (event) {
      this.$emit('change', this.type === 'file'
        ? event.target?.files
        : this.valueToEmit
      )
    },

    setHelp () {
      this.helpFunction({ id: this.helpId, store: this.$store })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0 0 1em;
}

label {
  display: block;
}

input {
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $input-font-color;
  height: 2.2rem;
  padding: 0.2rem 0.4rem;
  width: 100%;

  &.invalid {
    border-color: $color-error;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    &.invalid {
      border-color: $color-error;
    }
  }

  &:hover {
    border-color: $input-border-color-hover;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: $input-background-color-disabled;

    &:hover {
      border-color: $input-border-color;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type='text'] {
  &::placeholder {
    color: $input-placeholder-color;
  }
}

input[type=radio],
input[type=checkbox] {
  display: none;
}

.icon {
  margin: 0 0.1rem;
}

.icon-alert-triangle {
  margin-left: 2px;
}

.label-text {
  display: inline-block;
}

.question-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox,
.radio {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;

  .error-text {
    grid-column-end: 3;
    grid-column-start: 1;
  }
}

input[type=radio] + .flip {
  align-content: center;
  border: 1px solid $input-border-color;
  border-radius: 30px;
  box-sizing: border-box;
  display: grid;
  height: 1.6rem;
  transition: ease-in all 250ms;
  width: 1.6rem;

  & + .error-text {
    order: 2;
  }

  .tick {
    border-radius: 30px;
    box-shadow: none;
    display: inline-block;
    height: 0;
    place-self: center;
    transition: ease-in all 250ms;
    width: 0;
  }
}

input[type=checkbox] + .flip {
  border: 1px solid $input-border-color;
  border-radius: 3px;
  display: inline-block;
  height: 20px;
  position: relative;
  text-align: left;
  transition: ease-in all 250ms;
  width: 20px;

  & + .error-text {
    order: 2;
  }

  .tick {
    border-bottom: 3px solid rgba($input-font-color, 0);
    border-radius: 2px;
    border-right: 3px solid rgba($input-font-color, 0);
    display: inline-block;
    height: 0; // transition
    margin-bottom: 4px;
    margin-left: 5.2px;
    transform: rotate(45deg);
    transition: ease-in all 250ms;
    transition-delay: 200ms;
    width: 0; // transition
  }
}

input[type=checkbox]:checked + .flip {
  background-color: $checkbox-checked-background-color;

  &:hover {
    border-color: #b3b3f4;
    border-width: 1px;
  }

  .tick {
    border-color: $white;
    height: 14px;
    width: 7px;
  }
}

input[type=radio]:checked + .flip {
  background-color: $checkbox-checked-background-color;

  .tick {
    background-color: $white;
    height: 0.7rem;
    width: 0.7rem;
  }
}

label:hover {
  input[type=radio],
  input[type=checkbox] {
    & + .flip {
      border-color: $input-border-color-hover;
    }
  }
}
</style>
