<template>
  <div>
    <e-dialog
      v-if="showMonetaryDebtFormDialog"
      data-test="monetary-debt-dialog"
    >
      <monetary-debt-form
        v-if="showMonetaryDebtFormDialog"
        :monetary-debt-id="selectedMonetaryDebtId"
        @closed="resetMonetaryDebtAndCloseDialog"
        @submitted="resetMonetaryDebtAndCloseDialog"
      />
    </e-dialog>

    <fieldset>
      <legend>Debts Owed To Me</legend>

      <e-button
        data-test="create-new-monetary-debt-button"
        @click="showMonetaryDebtFormDialog = true"
      >
        New Debt
      </e-button>

      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-if="monetaryDebts.length">
          <e-table
            :headers="headers"
            :rows="monetaryDebts"
          >
            <template #actions="{ item }">
              <div class="actions">
                <e-button
                  class="ghost"
                  data-test="edit-item-button"
                  @click="event => setMonetaryDebtAndOpenDialog({ item, event })"
                >
                  <span class="icon-edit" />
                </e-button>
              </div>
            </template>
          </e-table>
        </div>
      </transition>
    </fieldset>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import MonetaryDebt from '@/models/MonetaryDebt'
import MonetaryDebtForm from './MonetaryDebtForm'
import { formatMoney } from 'accounting'

export default {
  name: 'MonetaryDebtManager',

  components: {
    EDialog,
    ETable,
    MonetaryDebtForm
  },

  data () {
    return {
      headers: [
        {
          text: 'Debtor',
          value: 'debtor',
          align: 'left',
          searchable: true
        },
        {
          text: 'Forgivable',
          value: 'isForgivable',
          align: 'center'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'right'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],

      showMonetaryDebtFormDialog: false,
      selectedMonetaryDebtId: ''
    }
  },

  computed: {
    monetaryDebts () {
      return MonetaryDebt
        .query()
        .with('debtor')
        .with('debtor.contact')
        .with('debtor.group')
        .all()
        .map(debt => ({
          id: debt.id,
          debtor: debt.debtor.name,
          isForgivable: this.toYesNo(debt.isForgivable),
          amount: this.toCurrency(debt.amount),
          rowClickedFunction: () => this.setMonetaryDebtAndOpenDialog({ item: { id: debt.id } })
        }))
    }
  },

  methods: {
    toYesNo: bool => bool ? 'Yes' : 'No',
    toCurrency: value => formatMoney(value, '£', 2) || '',

    setMonetaryDebtAndOpenDialog ({ item, event }) {
      if (event) event.stopPropagation()

      this.showMonetaryDebtFormDialog = true
      this.selectedMonetaryDebtId = item.id
    },

    resetMonetaryDebtAndCloseDialog () {
      this.showMonetaryDebtFormDialog = false
      this.selectedMonetaryDebtId = ''
    }
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin: 2rem 0 1.2rem;
}
</style>
