<template>
  <article v-if="article.title" class="article">
    <h1>
      {{ article.title }}
    </h1>

    <section class="published-and-liked">
      <p>
        Published {{ article.formattedPublishedAt }}
        - on
        <a :href="article.articleUrl.href" target="_blank">
          {{ article.articleUrl.host }}
        </a>
        - by
        {{ article.publisher }}
      </p>

      <section class="like">
        <div class="like-element" @click="changeIsLiked">
          <span class="text">like</span>
          <span class="icon" :class="isLiked ? 'icon-heart1' : 'icon-heart-o'" />
        </div>
      </section>
    </section>

    <p>
      {{ article.wordCount }} words
    </p>

    <div class="image-container">
      <img
        v-if="article.showImageInArticle"
        :alt="article.title"
        :src="article.imageUrl || '../../default_thumbnail.png'"
      >
    </div>

    <div v-html="article.body" />
  </article>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  name: 'InsightCategoryArticle',

  props: {
    subCategoryId: {
      type: String,
      default: ''
    },

    articleId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      article: {},
      isLiked: false
    }
  },

  async created () {
    const article = this.$store.getters['insights/getArticle'](this.articleId)

    this.article = {
      ...article,
      articleUrl: new URL(article.link),
      formattedPublishedAt: format(parseISO(article.publishedAt), 'MMMM d, Y')
    }

    this.isLiked = article.isLiked

    this.$emit('check-article-out-of-sync-with-accordion', this.subCategoryId)
  },

  methods: {
    async changeIsLiked () {
      try {
        await this.$store.dispatch('insights/updateLiked', {
          categoryId: this.subCategoryId,
          articleId: this.article.id,
          isLiked: !this.isLiked
        })

        this.isLiked = !this.isLiked
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'InsightCategoryArticle',
          functionName: 'changeIsLiked'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.article {
  border: $border-width solid $border-color-one;
  display: grid;
  grid-row-gap: 1rem;

  /* stylelint-disable-next-line */
  ::v-deep a {
    color: $link-color;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
    }
  }

  .published-and-liked {
    display: grid;
    grid-template-columns: 90% 1fr;

    p {
      margin: 0;
    }
  }

  .like {
    display: grid;
    justify-items: end;

    .like-element {
      cursor: pointer;
      display: grid;
      grid-column-gap: 0.2rem;
      grid-template-columns: 1fr 1fr;
      text-transform: uppercase;

      .icon {
        color: $heart-color;
        font-size: 1.4rem;

        &:hover {
          font-weight: bold;
        }
      }

      .text,
      .icon {
        align-self: end;
      }
    }
  }

  .image-container {
    display: grid;
    justify-items: center;
  }
}
</style>
