var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"top-inputs"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.jsonFile.defaultHelpId)?_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(_vm.jsonFile.defaultHelpId)}}}):_vm._e()])]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"dynamic-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitted)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.header)}}),_vm._l((_vm.jsonFile.questions),function(question){return _c('div',{key:question.id},[(question.type === 'fieldset')?_c('dynamic-fieldset',{attrs:{"fieldset":question,"dynamic-properties":_vm.dynamicProperties,"value":_vm.dynamicProperties[question.propertyName],"get-input-type":_vm.getInputType,"help":_vm.jsonFile.help},on:{"update":function (ref) {
	var value = ref.value;
	var propertyName = ref.propertyName;

	return _vm.dynamicProperties[propertyName] = value;
}}}):_c(_vm.getInputType(question.type),{tag:"component",attrs:{"question":question,"value":_vm.dynamicProperties[question.propertyName],"validation-rules":question.validationRules,"help-object":_vm.getHelpObject(question.helpId)},on:{"update":function (ref) {
	var value = ref.value;
	var propertyName = ref.propertyName;

	return _vm.dynamicProperties[propertyName] = value;
}}})],1)}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.footer)}})],2),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"dynamic-form","button-text":"Preview"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }