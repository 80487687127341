<template>
  <div>
    <h1>My Data</h1>

    <div class="my-data-json">
      <pre class="json-example">
        {
          "user": {
            "userId": "a238bc48-81a9-481a-abdfr-09e95bc7e3e0",
            "email": "joe.bloggs@meavitae.com",
            "firstName": "Joe",
            "middleNames": "Alex",
            "lastName": "Bloggs",
            "address": "15 Sycamore Avenue, Townsville, United Kingdom",
            "permissions": [],
            "createdDate": 1610472830019,
            "lastLoginDate": 1618492400368,
            "metadata": {
              "nextNudgeReminderTimestamp": 1653125523871,
              "accountLocked": {
                "startsAt": 0,
                "expiresAt": 0,
                "reason": ""
              }
            }
          },
          "messages": [
            {
              "to": [
                {
                  "userId": "sdf-81a9-481a-abcc-09edfdffsdbc7e3e0",
                  "firstName": "Paul",
                  "middleNames": "John",
                  "lastName": "Morpeth",
                  "email": "paul.morpeth@meavitae.com"
                }
              ],
              "unknowns": 0,
              "from": {
                "userId": "a4dsfc48-81a9-481a-abcc-09sdfc7e3e0",
                "firstName": "Joe",
                "middleNames": "Alex",
                "lastName": "Bloggs",
                "email": "joe.bloggs@meavitae.com"
              },
              "subject": "Should see this message",
              "date": 1641977639879,
              "body": "This is a very important message, I need you to respond to me.",
              "attachments": []
            }
          ]
        }
      </pre>

      <e-button
        v-if="dataCreationComplete"
        button-style="two"
        button-type="primary"
        @click="downloadGdprData"
      >
        Download My Data
      </e-button>

      <e-button
        v-else
        :disabled="dataCreationInProgress"
        @click="makeGdprData"
      >
        Create My Data To Download
      </e-button>
    </div>

    <template v-if="dataCreationComplete">
      <h2>My Files</h2>

      <files-table
        :files="files"
        :allow-delete="false"
        :show-owner="false"
        class="files-table"
      />
    </template>
  </div>
</template>

<script>
import File from '@/models/File'
import FilesTable from '@/components/FilesTable'

export default {
  name: 'MyFiles',

  components: {
    FilesTable
  },

  computed: {
    files () {
      return [
        ...File
          .query()
          .with('owner')
          .get(),
        ...this.$store.getters['gdpr/messageFiles']
      ]
    },

    dataCreationInProgress () {
      return this.$store.getters['gdpr/inProgress']
    },

    dataCreationComplete () {
      return this.$store.state.gdpr.complete
    },

    dataAsJson () {
      return JSON.stringify(this.$store.getters['gdpr/data'] || {}, null, 2)
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 141,
      isInitial: true
    })
  },

  methods: {
    async makeGdprData () {
      this.$store.dispatch('gdpr/makeData')
    },

    downloadGdprData () {
      try {
        const url = URL.createObjectURL(
          new Blob([this.dataAsJson]),
          { type: 'application/json' }
        )

        const a = document.createElement('a')
        a.href = url
        a.download = 'MyData.json'
        a.click()

        URL.revokeObjectURL(url)
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'MyData',
          functionName: 'downloadGdprData'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-data-json {
  border: $input-border-width solid $input-border-color;
  height: 20rem;
  margin: 1rem 0;
  overflow: hidden;
  position: relative;

  .download-data-button {
    background-color: $color-success;
  }

  .json-example {
    filter: blur(1px);
    max-width: 1em;
  }

  button {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.files-table {
  margin: 1rem 0;
}
</style>
