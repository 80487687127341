<template>
  <router-view
    @deleted="handleDeleted"
    @submitted="handleSubmitted"
  />
</template>

<script>
export default {
  name: 'BucketListItems',

  created () {
    this.$emit('set-active', 'bucketListItems')
  },

  methods: {
    handleDeleted (routeObject) {
      this.$emit('deleted', routeObject)
    },

    handleSubmitted (routeObject) {
      this.$emit('submitted', routeObject)
    }
  }
}
</script>
