var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeIn animate__faster"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Add Contacts")]),_c('p',[_vm._v("MeaVitae relies on connecting you to your contacts. Add one or more contacts to get you started either through importing them (from a file, Google or Microsoft) or manually entering their details by clicking on the 'ADD A CONTACT' button.")]),_c('div',{staticClass:"import-button-container"},[_c('import-contacts-from-file'),_c('import-google-contacts'),_c('import-microsoft-contacts')],1),(_vm.showContactForm)?_c('div',{staticClass:"contact-form-container"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"contact-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"First Name","error-messages":errors,"success":valid,"is-required":true,"data-test":"first-name-input"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Middle Names","data-test":"middle-names-input"},model:{value:(_vm.middleNames),callback:function ($$v) {_vm.middleNames=$$v},expression:"middleNames"}}),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Last Name","error-messages":errors,"is-required":true,"success":valid,"data-test":"last-name-input"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"connect-input"},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":{ is_not_over_150_years_ago: true, is_past_date: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date of Birth","type":"date","error-messages":errors,"success":valid,"data-test":"date-of-birth-input"},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Gender","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.genderTypes,"label":"Gender","success":valid,"is-required":true,"error-messages":errors,"data-test":"gender-type-select"},model:{value:(_vm.genderType),callback:function ($$v) {_vm.genderType=$$v},expression:"genderType"}})]}}],null,true)})],1),_c('e-select',{attrs:{"items":_vm.relationshipTypes,"default-option-text":"Select Relationship To Me","label":"Contact's relationship to me","data-test":"relationship-type-select"},model:{value:(_vm.relationshipType),callback:function ($$v) {_vm.relationshipType=$$v},expression:"relationshipType"}}),_c('email-address-manager',{attrs:{"email-addresses":_vm.emailAddresses,"type":_vm.type,"needed-for-is-valid":false}}),_c('phone-number-manager',{attrs:{"phone-numbers":_vm.phoneNumbers}}),_c('address-manager',{attrs:{"addresses":_vm.addresses,"needed-for-is-valid":false}}),_c('submit-button',{staticClass:"submit-button",attrs:{"form-is-valid":formIsValid,"form":"contact-form","button-text":"Save contact","button-style":"one","button-type":"single"}})],1)]}}],null,false,3253377514)})],1):_c('e-button',{staticClass:"wide-button",on:{"click":_vm.addContact}},[_vm._v(" Add "+_vm._s(_vm.contactAdded ? 'another' : 'a')+" contact ")]),(_vm.contactAdded)?_c('e-table',{attrs:{"headers":_vm.contactTableHeaders,"rows":_vm.contactList,"data-test":"contact-table"},scopedSlots:_vm._u([{key:"valid",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isValid ? 'icon-check-circle' : 'icon-circle'})]}}],null,false,4131731050)}):_vm._e(),(_vm.contactAdded)?_c('e-button',{staticClass:"wide-button",on:{"click":_vm.nextOnboardingStage}},[_vm._v(" Next ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }