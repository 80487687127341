'use strict'

import Messages from '@/views/Messaging/Messages'
import Bin from '@/views/Messaging/Bin'
import Draft from '@/views/Messaging/Draft'
import Drafts from '@/views/Messaging/Drafts'
import Forward from '@/views/Messaging/Forward'
import Inbox from '@/views/Messaging/Inbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import NewMessage from '@/views/Messaging/NewMessage'
import Reply from '@/views/Messaging/Reply'
import ReplyAll from '@/views/Messaging/ReplyAll'
import Sent from '@/views/Messaging/Sent'
import ViewMessage from '@/views/Messaging/ViewMessage'

const subscriptionFeatureId = 2500

export default {
  path: '/messages',
  name: 'Messages',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Messages
  },
  meta: {
    title: 'Messages',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'inbox',
      name: 'Inbox',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Inbox
      },
      meta: {
        title: 'Inbox',
        subscriptionFeatureId
      }
    },

    {
      path: 'inbox/:messageId',
      name: 'ViewInboxMessage',
      components: {
        appClosedLayout: LoadingSpinner,
        default: ViewMessage
      },
      props: {
        default: route => ({
          mailboxType: 'inbox',
          messageId: route.params.messageId
        })
      },
      meta: {
        title: 'View Message',
        subscriptionFeatureId
      }
    },

    {
      path: 'new-message',
      name: 'NewMessage',
      components: {
        appClosedLayout: LoadingSpinner,
        default: NewMessage
      },
      props: {
        default: route => ({
          predefinedTo: route.params.predefinedTo
        })
      },
      meta: {
        title: 'New Message',
        subscriptionFeatureId
      }
    },

    {
      path: ':mailboxType/reply/:messageId',
      name: 'Reply',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Reply
      },
      props: {
        default: route => ({
          mailboxType: route.params.mailboxType,
          messageId: route.params.messageId,
          setCursorInBody: route.params.setCursorInBody
        })
      },
      meta: {
        title: 'Reply',
        subscriptionFeatureId
      }
    },

    {
      path: ':mailboxType/reply-all/:messageId',
      name: 'ReplyAll',
      components: {
        appClosedLayout: LoadingSpinner,
        default: ReplyAll
      },
      props: {
        default: route => ({
          mailboxType: route.params.mailboxType,
          messageId: route.params.messageId,
          setCursorInBody: route.params.setCursorInBody
        })
      },
      meta: {
        title: 'Reply All',
        subscriptionFeatureId
      }
    },

    {
      path: ':mailboxType/forward/:messageId',
      name: 'Forward',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Forward
      },
      props: {
        default: route => ({
          mailboxType: route.params.mailboxType,
          messageId: route.params.messageId,
          setCursorInBody: route.params.setCursorInBody
        })
      },
      meta: {
        title: 'Forward Message',
        subscriptionFeatureId
      }
    },

    {
      path: 'drafts',
      name: 'Drafts',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Drafts
      },
      meta: {
        title: 'Drafts',
        subscriptionFeatureId
      }
    },

    {
      path: 'drafts/:messageId',
      name: 'EditDraftMessage',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Draft
      },
      props: {
        default: route => ({
          mailboxType: route.params.mailboxType,
          messageId: route.params.messageId
        })
      },
      meta: {
        title: 'Draft Message',
        subscriptionFeatureId
      }
    },

    {
      path: 'sent',
      name: 'Sent',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Sent
      },
      meta: {
        title: 'Sent',
        subscriptionFeatureId
      }
    },

    {
      path: 'sent/:messageId',
      name: 'ViewSentMessage',
      components: {
        appClosedLayout: LoadingSpinner,
        default: ViewMessage
      },
      props: {
        default: route => ({
          mailboxType: 'sent',
          messageId: route.params.messageId
        })
      },
      meta: {
        title: 'View Message',
        subscriptionFeatureId
      }
    },

    {
      path: 'bin',
      name: 'Bin',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Bin
      },
      meta: {
        title: 'Bin',
        subscriptionFeatureId
      }
    },

    {
      path: 'bin/:messageId',
      name: 'ViewBinMessage',
      components: {
        appClosedLayout: LoadingSpinner,
        default: ViewMessage
      },
      props: {
        default: route => ({
          mailboxType: 'bin',
          messageId: route.params.messageId,
          originBox: route.query.originBox
        })
      },
      meta: {
        title: 'View Message',
        subscriptionFeatureId
      }
    }
  ]
}
