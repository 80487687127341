'use strict'

import Vue from 'vue'
import attemptApiCall from '../utils/attemptApiCall'
import asyncForEach from '../utils/asyncForEach'
import getGdprFromIdp from '../apis/idp/getGdprFromIdp'
import getGdprListFromComms from '../apis/comms/getGdprListFromComms'

export default {
  namespaced: true,

  state: {
    showProgressElement: false,
    idp: null,
    comms: null,
    complete: false
  },

  getters: {
    inProgress: state => (state.showProgressElement && !state.complete),
    data: (state, _, rootState) => ({
      ...state.idp && { idp: state.idp },
      ...rootState.entities && { vault: rootState.entities },
      ...state.comms && { comms: state.comms }
    }),

    messageFiles: (_, getters) => {
      return getters
        .data
        .comms?.flatMap(({ attachments = [], subject, date }) => attachments
          .map(({ id, name, mimeType, fileUrl, decryptKey }) => ({
            id,
            fileName: name,
            fileMimeType: mimeType,
            fileUrl,
            reference: `Attached to message: ${subject}`,
            encryptDecryptKey: decryptKey,
            uploadDate: new Date(date).toISOString()
          }))
        ) || []
    }
  },

  actions: {
    makeData: async ({ commit, dispatch }) => {
      try {
        commit('setData', { key: 'showProgressElement', value: true })

        await dispatch('getUserJsonFromIdp')
        await dispatch('getUserJsonFromComms')

        commit('setData', { key: 'complete', value: true })
      } catch (error) {
        commit(
          'snackbar/update',
          {
            type: 'error',
            message: 'Failed to make data download'
          },
          { root: true }
        )

        dispatch(
          'logError',
          {
            error,
            fileName: 'gdpr',
            functionName: 'makeData'
          },
          { root: true }
        )
      }
    },

    getUserJsonFromIdp: async ({ commit }) => {
      try {
        const idpUserData = await attemptApiCall(
          getGdprFromIdp,
          sessionStorage.getItem('jwt')
        )

        if (Object.keys(idpUserData).length) commit('setData', { key: 'idp', value: idpUserData })
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getUserJsonFromComms: async ({ commit, dispatch }) => {
      try {
        const { messages, letters, scheduledLetters } = await attemptApiCall(
          getGdprListFromComms,
          sessionStorage.getItem('jwt')
        )

        const commsData = []

        await asyncForEach(messages, async commsId => {
          const [mailboxType, messageId] = commsId.split('/')
          commsData.push({
            commsId,
            ...(await dispatch(
              'messages/getMessage',
              {
                mailboxType,
                messageId
              },
              { root: true }
            ))
          })
        })

        const allLetterIds = [...new Set([...letters, ...scheduledLetters])]
        await asyncForEach(allLetterIds, async letterId => {
          commsData.push({
            letterId,
            ...(await dispatch(
              'letters/getLetterById',
              letterId,
              { root: true }
            ))
          })
        })

        if (commsData.length) commit('setData', { key: 'comms', value: commsData })
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setData: (state, { key, value }) => {
      Vue.set(state, key, value)
    },

    resetState: state => {
      Vue.set(state, 'showProgressElement', false)
      Vue.set(state, 'idp', null)
      Vue.set(state, 'comms', null)
      Vue.set(state, 'complete', false)
    }
  }
}
