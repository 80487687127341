'use strict'

import blocksToHtml from '@sanity/block-content-to-html'

export default blocks => blocksToHtml({
  blocks,
  serializers: {
    types: {
      image: props => {
        const image = document.createElement('img')
        image.setAttribute('src', props.node.url)
        image.setAttribute('width', 'props.node.metadata.dimensions.width')
        image.setAttribute('height', 'props.node.metadata.dimensions.height')

        return image
      }
    },

    marks: {
      link: props => {
        const link = document.createElement('a')
        link.innerText = props.children[0]
        link.setAttribute('href', props.mark.href)
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'noopener')
        link.setAttribute('class', 'link')

        return link
      },

      internalLink: props => {
        const routerLink = document.createElement('router-link')
        routerLink.innerText = props.children[0]
        routerLink.setAttribute('to', props.mark.href)
        routerLink.setAttribute('class', 'link')

        return routerLink
      }
    }
  }
})
