var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category",class:{ 'active': _vm.category.isActive }},[_c('div',{staticClass:"category-title",on:{"click":_vm.handleCategoryClicked}},[_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.category.text)+" ")]),(_vm.displaySubItemsSize && _vm.category.sub.length)?_c('span',[_vm._v(" ("+_vm._s(_vm.category.length || _vm.category.sub.length)+") ")]):_vm._e()]),_c('span',{staticClass:"icon-chevron",class:[_vm.category.isActive ? 'icon-chevron-down' : 'icon-chevron-up']})]),_c('transition',{attrs:{"name":"item"},on:{"enter":_vm.startTransition,"after-enter":_vm.endTransition,"before-leave":_vm.startTransition,"after-leave":_vm.endTransition}},[(_vm.category.isActive)?_c('ul',{staticClass:"sub-category-list"},[_vm._l((_vm.category.additionals),function(additional){return _c('li',{key:additional.id,staticClass:"additional"},[_c('router-link',{attrs:{"to":_vm.createIt3(additional),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var isActive = ref.isActive;
return [_c('span',{class:{
              'router-link-active': isActive,
              'route-access-denied': !_vm.isRouteAccessAllowed(route.name)
            },on:{"click":function($event){return _vm.handleSubCategoryClicked({ route: route })}}},[_c('span',{class:additional.icon || 'icon-plus'}),_vm._v(" "+_vm._s(additional.text)+" ")])]}}],null,true)})],1)}),_vm._l((_vm.category.sub),function(subCategory){return _c('li',{key:subCategory.id,staticClass:"sub-category"},[(subCategory.routeName)?_c('router-link',{attrs:{"to":_vm.createIt(Object.assign({}, subCategory, {categoryId: _vm.category.id})),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var route = ref.route;
            var isActive = ref.isActive;
return [_c('span',{class:{
              'sub-category-text': true,
              'router-link-active': isActive,
              completed: subCategory.completed,
              'route-access-denied': !_vm.isRouteAccessAllowed(route.name)
            },on:{"click":function($event){return _vm.handleSubCategoryClicked({ route: route })}}},[(_vm.displayIsValid && subCategory.isValid !== undefined)?_c('span',{class:subCategory.isValid ? 'icon-check-circle' : 'icon-circle'}):_vm._e(),_vm._v(" "+_vm._s(subCategory.text)+" "),(subCategory.sub && subCategory.sub.length)?_c('span',[_vm._v(" ("+_vm._s(subCategory.sub.length)+") ")]):_vm._e()])]}}],null,true)}):_c('span',{staticClass:"sub-category-text"},[_vm._v(" "+_vm._s(subCategory.text)+" "),(subCategory.sub && subCategory.sub.length)?_c('span',[_vm._v(" ("+_vm._s(subCategory.sub.length)+") ")]):_vm._e()]),(subCategory.sub)?_c('ul',{staticClass:"sub-sub-category-list"},_vm._l((subCategory.sub),function(subSubItem){return _c('li',{key:subSubItem.id},[_c('router-link',{attrs:{"to":_vm.createIt2(Object.assign({}, subSubItem, {subCategoryId: subCategory.id})),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var route = ref.route;
            var isActive = ref.isActive;
return [_c('span',{class:{
                  'sub-category-text': true,
                  'router-link-active': isActive,
                  'route-access-denied': !_vm.isRouteAccessAllowed(route.name)
                },on:{"click":function($event){return _vm.handleSubCategoryClicked({ route: route })}}},[(_vm.displayIsValid && subSubItem.isValid !== undefined)?_c('span',{class:subSubItem.isValid ? 'icon-check-circle' : 'icon-circle'}):_vm._e(),_vm._v(" "+_vm._s(subSubItem.text)+" ")])]}}],null,true)})],1)}),0):_vm._e()],1)})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }