'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ messageObject, jwt }) => axios({
  method: 'post',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_COMMS_URL}/send`,
  data: messageObject,
  timeout: 8000
})
