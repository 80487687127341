'use strict'

import FormView from '@/views/FormView'
import Liabilities from '@/views/Liabilities/Liabilities'
import LiabilitiesDefault from '@/views/Liabilities/LiabilitiesDefault'
import LoadingSpinner from '@/components/LoadingSpinner'

const subscriptionFeatureId = 8000

export default {
  path: '/liabilities',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Liabilities
  },
  meta: {
    title: 'Liabilities',
    subscriptionFeatureId
  },
  children: [
    {
      path: '',
      name: 'Liabilities',
      components: {
        appClosedLayout: LoadingSpinner,
        default: LiabilitiesDefault
      },
      meta: {
        title: 'Liabilities',
        subscriptionFeatureId
      }
    },

    {
      path: 'credit/new',
      name: 'NewCredit',
      props: {
        default: () => ({
          form: 'LiabilityForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Credit',
        subscriptionFeatureId
      }
    },

    {
      path: 'credit/:id?',
      name: 'Credit',
      props: {
        default: route => ({
          form: 'LiabilityForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Credit',
        subscriptionFeatureId
      }
    },

    {
      path: 'service-agreement/new',
      name: 'NewServiceAgreement',
      props: {
        default: () => ({
          form: 'LiabilityForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Service Agreement',
        subscriptionFeatureId
      }
    },

    {
      path: 'service-agreement/:id?',
      name: 'ServiceAgreement',
      props: {
        default: route => ({
          form: 'LiabilityForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Service Agreement',
        subscriptionFeatureId
      }
    },

    {
      path: 'subscription/new',
      name: 'NewSubscription',
      props: {
        default: () => ({
          form: 'LiabilityForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Subscription',
        subscriptionFeatureId
      }
    },

    {
      path: 'subscription/:id?',
      name: 'Subscription',
      props: {
        default: route => ({
          form: 'LiabilityForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Subscription',
        subscriptionFeatureId
      }
    },

    {
      path: 'insurance/new',
      name: 'NewInsurance',
      props: {
        default: () => ({
          form: 'LiabilityForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Insurance',
        subscriptionFeatureId
      }
    },

    {
      path: 'insurance/:id?',
      name: 'Insurance',
      props: {
        default: route => ({
          form: 'LiabilityForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Insurance',
        subscriptionFeatureId
      }
    },

    {
      path: 'otherLiability/new',
      name: 'NewOtherLiability',
      props: {
        default: () => ({
          form: 'LiabilityForm'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'New Other',
        subscriptionFeatureId
      }
    },

    {
      path: 'otherLiability/:id?',
      name: 'OtherLiability',
      props: {
        default: route => ({
          form: 'LiabilityForm',
          id: route.params.id
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: {
        title: 'Other',
        subscriptionFeatureId
      }
    }
  ]
}
