<template>
  <e-button
    :key="key"
    type="submit"
    :disabled="!formIsValid"
    :form="form"
    :button-style="buttonStyle"
    :button-type="buttonType"
    :button-text="buttonText"
    data-test="save-button"
  >
    {{ buttonText }}
  </e-button>
</template>

<script>
export default {
  name: 'SubmitButton',

  props: {
    formIsValid: {
      type: Boolean,
      default: false
    },

    form: {
      type: String,
      default: ''
    },

    buttonStyle: {
      type: String,
      default: 'two'
    },

    buttonType: {
      type: String,
      default: 'primary'
    },

    buttonText: {
      type: String,
      default: 'Submit'
    }
  },

  data () {
    return {
      key: 0
    }
  },

  watch: {
    formIsValid () {
      if (this.formIsValid) this.key++
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-check-circle {
  padding-right: 8px;
}
</style>
