<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Summary

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(77)"
        />
      </h1>

      <span class="buttons">
        <e-button
          v-if="showReloadButton === true"
          class="top-submit"
          @click="reloadPage"
        >
          Reload
        </e-button>

        <e-button
          v-if="isValid"
          type="submit"
          form="will-form"
        >
          Create Will
        </e-button>
      </span>
    </div>

    <validation-observer v-if="isValid" v-slot="{ handleSubmit }">
      <form
        id="will-form"
        @submit.prevent="handleSubmit(save)"
      >
        <validation-provider
          v-slot="{ errors, valid }"
          name="Label"
          :rules="{ required: true }"
          tag="div"
        >
          <e-input
            v-model="label"
            label="Label this will"
            :error-messages="errors"
            :success="valid"
          />
        </validation-provider>

        <div
          v-if="isMobileDevice"
          class="unavailable-on-mobile"
        >
          Will preview is unavailable on a mobile device, please use your desktop computer
        </div>

        <iframe
          v-else
          id="iframeContainer"
          type="application/pdf"
          class="will-preview document-display"
        />
      </form>
    </validation-observer>

    <div v-else>
      <p>Will data is not valid:</p>

      <ul>
        <li v-for="(issue, index) in issues" :key="index">
          <component :is="{ template: `<span>${issue}</span>` }" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Will from '@/models/Will'
import DocumentRecord from '@/models/DocumentRecord'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import getDocumentDefinition from '@/utils/getDocumentDefinition'
import md5 from 'md5'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfMake from 'pdfmake/build/pdfmake'
import willIsValid from '@/utils/willIsValid'
import { format } from 'date-fns'
import { marked } from 'marked'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const DOMPurify = createDOMPurify(window)

const getWillObject = () => {
  const willObject = Will
    .query()
    .with([
      'user',
      'partner',
      'children',
      'monetaryGifts',
      'monetaryGifts.beneficiary',
      'monetaryGifts.beneficiary.contact',
      'monetaryGifts.beneficiary.group',
      'monetaryGifts.beneficiary.contactSubstitute',
      'monetaryGifts.beneficiary.groupSubstitute',
      'monetaryDebts',
      'monetaryDebts.debtor',
      'monetaryDebts.debtor.contact',
      'monetaryDebts.debtor.group',
      'monetaryDebts.debtor.contactSubstitute',
      'monetaryDebts.debtor.groupSubstitute',
      'residuaryEstateBeneficiaries',
      'residuaryEstateBeneficiaries.contact',
      'residuaryEstateBeneficiaries.group',
      'residuaryEstateBeneficiaries.contactSubstitute',
      'residuaryEstateBeneficiaries.groupSubstitute',
      'residualPersonalPossessionsBeneficiaries',
      'residualPersonalPossessionsBeneficiaries.contact',
      'residualPersonalPossessionsBeneficiaries.group',
      'residualPersonalPossessionsBeneficiaries.contactSubstitute',
      'residualPersonalPossessionsBeneficiaries.groupSubstitute',
      'witnesses',
      'executors',
      'substituteExecutors',
      'guardians',
      'substituteGuardians',
      'assets',
      'assets.coOwners',
      'assets.supplier',
      'assets.beneficiaries',
      'assets.beneficiaries.contact',
      'assets.beneficiaries.group',
      'assets.beneficiaries.contactSubstitute',
      'assets.beneficiaries.groupSubstitute',
      'assets.liabilities',
      'assets.liabilities.coOwners',
      'assets.liabilities.supplier'
    ])
    .first()

  const assetsWithBeneficiaries = willObject
    .assets
    .filter(({ beneficiaries }) => beneficiaries.length)

  willObject.propertyAssets = assetsWithBeneficiaries
    .filter(({ type }) => type === 'property')

  willObject.nonPropertyAssets = assetsWithBeneficiaries
    .filter(({ type }) => type !== 'property')

  delete willObject.assets

  return willObject
}

export default {
  name: 'WillSummary',

  data () {
    return {
      label: '',
      locale: 'en-GB',
      renderEngineResponse: {},
      isValid: false,
      issues: [],
      allowReloadButton: false
    }
  },

  computed: {
    isMobileDevice () {
      return this.$store.getters.isMobileDevice
    },

    showReloadButton () {
      return this.allowReloadButton && !this.isMobileDevice
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 77,
      isInitial: true
    })

    const willObject = getWillObject()
    console.log(willObject)
    const { isValid, issues } = await willIsValid(willObject)

    this.isValid = isValid
    this.issues = issues

    if (isValid) {
      try {
        this.renderEngineResponse = await this.$store.dispatch('renderEngine', {
          context: willObject,
          locale: this.locale,
          version: 0,
          type: 'will'
        })

        const willPreview = DOMPurify
          .sanitize(marked(this.renderEngineResponse.template, { gfm: true }))

        this.createPdf(willPreview)
      } catch (error) {
        this.isValid = false

        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.allowReloadButton = true

        this.$store.dispatch('logError', {
          error,
          fileName: 'WillSummary',
          functionName: 'created'
        })
      }
    }
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async reloadPage () {
      try {
        this.renderEngineResponse = await this.$store.dispatch('renderEngine', {
          context: getWillObject(),
          locale: this.locale,
          version: 0,
          type: 'will'
        })

        const willPreview = DOMPurify
          .sanitize(marked(this.renderEngineResponse.template, { gfm: true }))

        this.createPdf(willPreview)
        this.allowReloadButton = false
      } catch (error) {
        this.allowReloadButton = true

        this.$store.dispatch('logError', {
          error,
          fileName: 'WillSummary',
          functionName: 'reloadPage'
        })
      }
    },

    createPdf (html) {
      const docDefinition = getDocumentDefinition({ html, isPreview: true })
      const pdfDocGenerator = pdfMake.createPdf(docDefinition)

      pdfDocGenerator.getDataUrl(dataUrl => {
        const byteArrays = [...atob(dataUrl.slice(28))].map((byteCharacter, index, array) => {
          const slice = array.slice(index * 512, (index * 512) + 512)
          const byteNumbers = [...slice].map(char => char.charCodeAt())
          return new Uint8Array(byteNumbers)
        })

        const dataUrlAsBase64 = new Blob(byteArrays, { type: 'application/pdf' })
        const updatedDataUrl = URL.createObjectURL(dataUrlAsBase64)

        document
          .getElementById('iframeContainer')
          .src = `${updatedDataUrl}#toolbar=0&navpanes=0&scrollbar=0`
      })
    },

    async save () {
      const { locale, version, template, documentType: type } = this.renderEngineResponse

      const { base64Block } = convertObjectToBase64Block(template)

      const documentRecord = {
        label: this.label,
        locale,
        version,
        type,
        content: base64Block,
        reference: md5(base64Block),
        timestamp: new Date().getTime()
      }

      const duplicate = DocumentRecord
        .query()
        .where('reference', md5(documentRecord.content))
        .first()

      if (!duplicate) {
        DocumentRecord.insert({ data: documentRecord })
        await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
      } else {
        const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

        this.$store.commit('snackbar/update', {
          type: 'warning',
          message: `An identical will already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
        })
      }

      this.$router.push({ name: 'DocumentsTable' })
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: circle;
  padding-left: 2rem;
}

.unavailable-on-mobile {
  color: $color-warning;
  margin: 2em 0;
  text-align: center;
}

.will-preview {
  background-color: $input-background-color;
  border-radius: $corner;
  height: 80vh;
  margin: 10px 0;
  overflow-y: auto;
  width: 100%;

  /* stylelint-disable-next-line */
  ::v-deep h1 {
    font-size: 1.6rem;
  }

  /* stylelint-disable-next-line */
  ::v-deep h2 {
    font-size: 1.4rem;
  }

  /* stylelint-disable-next-line */
  ::v-deep h3 {
    font-size: 1.2rem;
  }

  /* stylelint-disable-next-line */
  ::v-deep p {
    margin-bottom: 1rem;
  }
}

/* stylelint-disable-next-line */
::v-deep .document-display {
  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
}
</style>
