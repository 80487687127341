<template>
  <div>
    <h1 class="page-title">
      My Letters
    </h1>

    <e-input
      v-model="filter"
      placeholder="Filter"
      class="filter-input"
    />

    <e-table
      v-model="selectedForDeletion"
      :headers="headers"
      :rows="rows"
      :filter="filter"
    >
      <template #actions="{ item }">
        <div class="actions">
          <e-button
            class="icon-edit ghost"
            data-test="edit-letter-button"
            @click="event => editLetter({ item, event })"
          />

          <e-button
            v-if="!isSmall"
            class="icon-file-pdf ghost"
            @click="event => generatePdf({ item, event })"
          />

          <e-button
            class="icon-file-text ghost"
            @click="event => generateDocx({ item, event })"
          />
        </div>
      </template>
    </e-table>

    <table-delete-dialog
      v-if="selectedForDeletion.length"
      item-type="Letter Record"
      @delete-confirmed="deleteLetterRecords"
    />
  </div>
</template>

<script>
import ETable from '@/components/ETable'
import TableDeleteDialog from '@/components/dialogs/TableDeleteDialog'
import asyncForEach from '@/utils/asyncForEach'
import decryptString from '@/utils/decryptString'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import DOMPurify from 'dompurify'
import pdfMake from 'pdfmake/build/pdfmake'
import HTMLtoDOCX from 'html-to-docx'
import { format } from 'date-fns'
import { htmlToPdfDocGenerator } from '@/utils/htmlToPdf'
import attemptToDecryptString from '../utils/attemptToDecryptString'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'LetterRecordsTable',

  components: {
    ETable,
    TableDeleteDialog
  },

  props: {
    isScheduled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      selectedForDeletion: [],
      filter: ''
    }
  },

  computed: {
    isSmall () {
      return this.$store.state.isSmall
    },

    letterRecords () {
      return this.$store.getters['letters/getLetters']
    },

    headers () {
      return [
        {
          text: 'Reference',
          value: 'title',
          align: 'left',
          searchable: true
        },

        {
          text: 'To',
          value: 'addressee',
          align: 'left',
          searchable: true
        },

        {
          text: 'Send Date',
          value: 'sendDate',
          align: 'left'
        },

        {
          text: 'Sent',
          value: 'sent',
          align: 'left'
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },

    rows () {
      return this.letterRecords
        .map(({ id, addressee, title, updateDate, sendDate, sent }) => ({
          id,
          addressee,
          title,
          lastUpdated: (updateDate && format(new Date(updateDate), 'yyyy-MM-dd HH:mm:ss')) || '',
          ...this.sendDate && { sendDate: format(new Date(sendDate), 'yyyy-MM-dd HH:mm:ss') },
          ...this.sent && { sent: sent ? 'Yes' : 'No' },
          rowClickedFunction: () => this.editLetter({ item: { id } })
        }))
    }
  },

  methods: {
    editLetter ({ item, event }) {
      if (event) event.stopPropagation()

      this.$router.push({
        name: this.isScheduled ? 'ScheduledLetter' : 'UnscheduledLetter',
        params: { id: item.id }
      })
    },

    async generateDocx ({ item, event }) {
      if (event) event.stopPropagation()

      try {
        const response = await this.$store.dispatch(
          'letters/getLetterById',
          item.id
        )
        console.log(response)
        const raw = await decryptString({
          encryptedString: response.raw,
          armoredPrivateKey: this.$store.state.armoredPrivateKey
        })
        // const titleRegex = /(?:#{1,2} <center>)(?<title>.*)(?:<\/center>)/m
        // const { title } = titleRegex.exec(raw).groups
        const title = await attemptToDecryptString({ string: response.title, armoredPrivateKey: this.$store.state.armoredPrivateKey })
        const html = DOMPurify
          .sanitize(raw)
        const documentOptions = {
          header: true,
          footer: true,
          pageNumber: true,
          footerType: 'first',
          skipFirstHeaderFooter: true,
          creator: 'MeaVitae Letter Renderer',
          title,
          keywords: ['MeaVitae', title]
        }
        const footerHTMLString = `<p style="margin-right: 30%;">${response.senderName} on ${format(Date.now(), 'yyyy-MM-dd')} - </p>`
        const headerHTMLString = `<h1>${response.senderName}</h1>`
        const wordDocBlob = await HTMLtoDOCX(html, headerHTMLString, documentOptions, footerHTMLString)

        const link = document.createElement('a')
        link.download = `${format(Date.now(), 'yyyy-MM-dd')}_${title.substring(0, 20)}.docx`
        link.href = URL.createObjectURL(wordDocBlob)
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (error) {
        console.error(error)
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Word could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LettersTable',
          functionName: 'generateDocx'
        })
      }
    },

    async generatePdf ({ item, event }) {
      event.stopPropagation()

      try {
        const response = await this.$store.dispatch(
          'letters/getLetterById',
          item.id
        )

        const html = await decryptString({
          encryptedString: response.raw,
          armoredPrivateKey: this.$store.state.armoredPrivateKey
        })

        htmlToPdfDocGenerator({ html, isPreview: true }).open()
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'PDF could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterRecordsTable',
          functionName: 'generatePdf'
        })
      }
    },

    async deleteLetterRecords () {
      try {
        await asyncForEach(this.selectedForDeletion, async letterId => {
          await this.$store.dispatch('letters/deleteLetter', letterId)
        })
        this.selectedForDeletion = []

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Selected letters have been successfully deleted.'
        })
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterRecordsTable',
          functionName: 'deleteLetterRecords'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.filter-input {
  margin-bottom: 0.6rem;
  max-width: 50%;
}
</style>
