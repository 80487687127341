<template>
  <div class="account-buttons">
    <e-button
      v-if="showAccountButton"
      @click.stop="navigateToProfile"
    >
      ACCOUNT
    </e-button>

    <e-button @click.stop="logoutFromApp">
      LOGOUT
    </e-button>
  </div>
</template>

<script>
export default {
  name: 'AccountAndLogoutButtons',

  props: {
    showAccountButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    navigateToProfile () {
      this.$router.push({ name: 'Profile' })
    },

    async logoutFromApp () {
      await this.$store.dispatch('logoutUser')
    }
  }
}
</script>

<style scoped lang="scss">
.account-buttons {
  margin: 0 0 1rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  & > :last-child {
    margin-top: 0.35rem;
  }
}
</style>
