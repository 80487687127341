var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showNfcDialog)?_c('nfc-info-dialog'):_vm._e(),(_vm.showUserSetupOnboarding)?_c('user-setup-onboarding',{attrs:{"jwt":_vm.jwt,"data-test":"user-setup-onboarding"},on:{"close-user-setup-onboarding":_vm.closeUserSetupOnboarding,"user-setup-onboarding-complete":function($event){return _vm.setUserToStoreGetAppApiDataAndOpenApp($event)}}}):_c('div',{staticClass:"login-block",attrs:{"data-test":"login-box"}},[_c('corp-bar',{staticClass:"mv-icon"}),(!_vm.isBrowserOrOperatingSystemSupported)?_c('unsupported-browser-or-operating-system'):(_vm.loadingMessage)?_c('div',{staticClass:"loading-spinner-block",attrs:{"data-test":"loading-spinner"}},[_c('p',[_vm._v(_vm._s(_vm.loadingMessage)+"...")])]):_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"spaced",attrs:{"data-test":"login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[(_vm.useAdminLogin)?_c('h1',[_vm._v(" Admin Login ")]):_vm._e(),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"input",attrs:{"label":"Email","error-messages":errors,"success":valid,"is-required":true,"type":"email","data-test":"email-input"},on:{"keyup":_vm.showCapsLockWarningIfOn,"input":function($event){return _vm.setShowNfcDialogIfIOS(true)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"input",attrs:{"error-messages":errors,"success":valid,"is-required":true,"label":"Password","autocomplete":"on","type":"password","data-test":"password-input"},on:{"keyup":_vm.showCapsLockWarningIfOn,"input":function($event){return _vm.setShowNfcDialogIfIOS(true)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),(!invalid)?_c('e-button',{staticClass:"button gras",attrs:{"type":"submit","data-test":"submit-button"}},[_vm._v(" Login ")]):_vm._e(),_c('router-link',{staticClass:"forgotten-password",attrs:{"to":{ name: 'ForgottenPassword' }}},[_vm._v(" Forgotten your password? ")])],1)]}}])}),(!_vm.loadingMessage)?_c('div',{staticClass:"app-version"},[_vm._v(" v."+_vm._s(_vm.version)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }