var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"set-password-block"},[_c('div',{staticClass:"header"},[_c('span'),_c('e-button',{staticClass:"icon-x-circle ghost",on:{"click":_vm.goToLogin}})],1),_c('corp-bar',{staticClass:"mv-icon"}),(_vm.idpApiSuccess)?_c('div',[_c('p',[_vm._v("We are processing your password change request. If successful, you will be able to login with your new details in 30 seconds time")]),_c('e-button',{staticClass:"button gras",on:{"click":_vm.goToLogin}},[_vm._v(" Login ")])],1):_c('div',[_c('p',[_vm._v("Please enter your new password")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"spaced",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[(!_vm.code)?_c('validation-provider',{attrs:{"name":"Code","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"input",attrs:{"label":"Code","error-messages":errors,"success":valid,"is-required":true},model:{value:(_vm.enteredCode),callback:function ($$v) {_vm.enteredCode=$$v},expression:"enteredCode"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"New Password","rules":"required|confirmed:confirm","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"input",attrs:{"error-messages":errors,"success":valid,"is-required":true,"label":"New Password","type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"confirm","name":"New Password Confirm","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"input",attrs:{"error-messages":errors,"success":valid,"is-required":true,"label":"New Password Confirm","type":"password"},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})]}}],null,true)}),_c('e-button',{staticClass:"button gras",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Request Password Change ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }