'use strict'

import axios from 'axios'
import createEndpoint from './utils/createEndpoint'

const { VUE_APP_API_URL } = process.env

export default async ({ jwt, userId, referenceArray }) => axios({
  method: 'delete',
  headers: { Authorization: jwt },
  url: `${VUE_APP_API_URL}/${createEndpoint({ userId, entityType: 'documentRecords' })}`,
  data: referenceArray,
  timeout: 8000
})
