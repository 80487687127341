<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Letters
        </h1>

        <accordion
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @set-active="setActiveCategory"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Letters',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      activeCategory: ''
    }
  },

  computed: {
    items () {
      return [
        {
          id: 'lettersAll',
          text: 'All',
          isActive: this.activeCategory === 'lettersAll',
          sub: [
            {
              id: 'lettersList',
              text: 'Show List',
              routeName: 'LettersRecordsTable'
            },
            ...this.$store.getters['letters/getLetters']
              .map(({ id, title }) => ({
                id,
                text: title,
                routeName: 'ScheduledLetter',
                paramKey2: 'id'
              }))
              .sort((a, b) => a.title?.toUpperCase() < b.title?.toUpperCase() ? -1 : 1)
          ],

          additionals: [
            {
              text: 'Add New',
              routeName: 'NewLetter',
              icon: 'icon-plus'
            }
          ]
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 127,
      isInitial: true
    })
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    },

    handleSubmitted (routeObject) {
      const notADoubleClick = routeObject.name !== this.$route.name

      if (notADoubleClick) {
        this.$router.push(routeObject)
      }
    }
  }
}
</script>
