<template>
  <fieldset data-test="co-owners-fieldset">
    <legend>{{ legendText }}</legend>

    <e-select
      :items="eligibleCoOwnersAsSelectOptions"
      label="Eligible Co-owners"
      :help-id="helpId"
      data-test="co-owner-select"
      @change="id => $emit('co-owner-added', id)"
    />

    <h4 v-if="currentCoOwners.length">
      Current Co-owners
    </h4>

    <ul v-if="currentCoOwners.length">
      <li
        v-for="coOwner in currentCoOwners"
        :key="coOwner.id"
        data-test="current-co-owner"
      >
        {{ coOwner.fullName }} ({{ toCapitalCase(coOwner.type) }})

        <e-button
          class="ghost"
          data-test="remove-co-owner-button"
          @click="$emit('co-owner-removed', coOwner.id)"
        >
          <span class="icon-trash-2" />
        </e-button>
      </li>
    </ul>
  </fieldset>
</template>

<script>
import Contact from '@/models/Contact'
import { capitalCase } from 'change-case'

export default {
  name: 'CoOwnersFieldset',

  props: {
    currentCoOwnerIds: {
      type: Array,
      default: () => []
    },

    legendText: {
      type: String,
      default: 'Co-Owners'
    },

    helpId: {
      type: Number,
      default: null
    }
  },

  emits: [
    'co-owner-added',
    'co-owner-removed'
  ],

  computed: {
    currentCoOwners () {
      return Contact
        .query()
        .whereIdIn(this.currentCoOwnerIds)
        .get()
    },

    eligibleCoOwnersAsSelectOptions () {
      return Contact
        .query()
        .get()
        .filter(({ id }) => !this.currentCoOwnerIds.includes(id))
        .map(({ id, selectText }) => ({ value: id, text: selectText }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  methods: {
    toCapitalCase: string => capitalCase(string)
  }
}
</script>
