'use strict'

import { Model } from '@vuex-orm/core'

export default class AssetCoOwnerContact extends Model {
  static get entity () { return 'assetCoOwnerContacts' }
  static get primaryKey () { return ['assetId', 'contactId'] }

  static fields () {
    return {
      assetId: this.attr(null),
      contactId: this.attr(null)
    }
  }
}
