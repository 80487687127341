'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ messageId, jwt }) => axios({
  method: 'patch',
  headers: {
    Authorization: jwt,
    'Content-Type': 'application/json'
  },
  url: `${VUE_APP_COMMS_URL}/inbox/${messageId}`,
  timeout: 8000
})
