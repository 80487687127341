<template>
  <span class="main">
    <span v-if="showInfo" class="info-pop">
      {{ text }}
    </span>

    <span
      v-on-clickaway="hideInfo"
      class="main-text"
      @click="toggleInfo"
    >
      <slot />
    </span>
  </span>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'InfoPop',

  mixins: [clickaway],

  props: {
    text: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showInfo: false
    }
  },

  methods: {
    toggleInfo () {
      this.showInfo = !this.showInfo
    },

    hideInfo () {
      this.showInfo = false
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  position: relative;

  .info-pop {
    background-color: darkGrey;
    border-radius: $corner;
    bottom: 1rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
    color: $font-color-two;
    font-weight: 400;
    left: 0.6rem;
    padding: 0.1rem 0.3rem;
    position: absolute;
    white-space: nowrap;
  }

  .main-text {
    cursor: help;
    text-decoration: underline dotted;
  }
}
</style>
