<template>
  <div>
    <div
      v-if="showSubMenu"
      v-on-clickaway="showChildren"
      class="sub-menu"
      data-test="sub-menu"
      :style="isSmall ? {} : { top: ycord }"
    >
      <ul>
        <li
          v-for="child in children"
          :key="child.title"
          :class="{ active: isActive(child.routeName), 'access-denied': !child.routeAccessAllowed }"
          @click="goToChildRoute(child.routeName)"
        >
          <span class="nav-icon" :class="child.icon">
            <span v-if="child.badgeText" class="badge">
              {{ child.badgeText }}
            </span>
          </span>

          <span class="title">{{ child.title }}</span>
        </li>
      </ul>
    </div>

    <nav class="nav">
      <div class="avatar-menu-item" @click="navigateToDashboard">
        <div class="avatar-container">
          <avatar-button :avatar="user.avatar" />
          <div class="avatar-text">
            Home
          </div>
        </div>
      </div>
      <ul v-if="user.isValid">
        <li
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <div
            v-if="item.isParent"
            :class="{ 'nav-item': true, 'access-denied': !item.routeAccessAllowed }"
            @click="clickDelay({ delayedFunction: showChildren, params: [$event, item.children] })"
          >
            <div class="nav-icon" :class="item.icon">
              <div v-if="item.badgeText" class="badge">
                {{ item.badgeText }}
              </div>
            </div>

            <div class="icon-text">
              {{ item.title }}
            </div>
          </div>

          <router-link
            v-else
            :class="{ 'nav-item': true, 'access-denied': !item.routeAccessAllowed }"
            :to="{ name: item.routeName }"
          >
            <div class="nav-icon" :class="item.icon">
              <div v-if="item.badgeText" class="badge">
                {{ item.badgeText }}
              </div>
            </div>

            <div class="icon-text">
              {{ item.title }}
            </div>
          </router-link>
        </li>
      </ul>

      <div class="app-version">
        v.{{ version }}
      </div>
    </nav>
  </div>
</template>

<script>
import AvatarButton from '@/components/buttons/AvatarButton'
import Will from '@/models/Will'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'NavigationBar',

  components: {
    AvatarButton
  },

  mixins: [clickaway],

  data () {
    return {
      showSubMenu: false,
      ycord: 0,
      children: []
    }
  },

  computed: {
    isSmall () {
      return this.$store.state.isSmall
    },

    user () {
      return this.$store.getters.user || {}
    },

    numNewMessages () {
      return this.$store.getters['messages/numNewMessages']
    },

    willRouteName () {
      return Will.query().first()?.disposingOfAllWorldwideAssets
        ? 'WillSummary'
        : 'CreatingYourWill'
    },

    menuItems () {
      const menus = [
        {
          icon: 'icon-users',
          title: 'Directory',
          routeName: 'ManageContacts',
          authenticatedOnly: true
        },

        {
          icon: 'icon-envelope-o',
          title: 'Messages',
          routeName: 'Inbox',
          authenticatedOnly: true,
          badgeText: this.numNewMessages
        },

        {
          icon: 'icon-dollar',
          title: 'Wealth',
          authenticatedOnly: true,
          isParent: true,
          children: [
            {
              icon: 'icon-briefcase',
              title: 'Assets',
              routeName: 'Assets',
              authenticatedOnly: true
            },

            {
              icon: 'icon-pie-chart',
              title: 'Liabilities',
              routeName: 'Liabilities',
              authenticatedOnly: true
            },

            {
              icon: 'icon-grid',
              title: 'Accounts',
              routeName: 'Accounts',
              authenticatedOnly: true
            }
          ]
        },

        {
          icon: 'icon-check-square-o',
          title: 'Plans',
          authenticatedOnly: true,
          isParent: true,
          children: [
            {
              icon: 'icon-briefcase',
              title: 'My Will',
              routeName: this.willRouteName,
              authenticatedOnly: true
            },

            {
              icon: 'icon-pie-chart',
              title: 'Letters',
              routeName: 'LettersRecordsTable',
              authenticatedOnly: true
            },

            {
              icon: 'icon-grid',
              title: 'Wishes',
              routeName: 'FuneralWishes',
              authenticatedOnly: true
            }
          ]
        },

        {
          icon: 'icon-folder',
          title: 'Files',
          routeName: 'DocumentsTable',
          authenticatedOnly: true
        },

        {
          icon: 'icon-lock',
          title: 'Secrets',
          routeName: 'Credentials',
          authenticatedOnly: true
        },

        {
          icon: 'icon-study',
          title: 'Knowledge',
          authenticatedOnly: true,
          isParent: true,
          children: [
            {
              icon: 'icon-study',
              title: 'Knowledge Base',
              routeName: 'KnowledgeBaseSearchResult',
              authenticatedOnly: true
            },
            {
              icon: 'icon-lightbulb-o',
              title: 'Insights',
              routeName: 'InsightsDefault',
              authenticatedOnly: true
            }
          ]
        },

        {
          icon: 'icon-lightbulb-o',
          title: 'Help',
          routeName: 'Help',
          authenticatedOnly: true
        }
      ]

      return menus
        .map(menuItem => ({
          ...menuItem,
          routeAccessAllowed: this.isRouteAccessAllowed(menuItem.routeName),
          children: menuItem.children?.length
            ? menuItem.children.map(childMenuItem => ({ ...childMenuItem, routeAccessAllowed: this.isRouteAccessAllowed(childMenuItem.routeName) }))
            : []
        }))
    },

    version () {
      return this.$store.state.appVersion
    }
  },

  methods: {
    isRouteAccessAllowed (routeName) {
      const route = this.$store.getters.routeAccessLookupObject?.[routeName]

      if (!routeName || !route) return true
      return route.routeAccessAllowed
    },

    navigateToDashboard () {
      const navigateTo = 'Dashboard'
      const currentRouteName = this.$route.name

      if (currentRouteName !== navigateTo) this.$router.push({ name: navigateTo })
    },

    isActive (routeName) {
      return this.$route.name === routeName
    },

    goToChildRoute (routeName) {
      this.showSubMenu = false
      const notADoubleClick = routeName !== this.$route.name

      if (notADoubleClick) {
        this.$router.push({ name: routeName })
      }
    },

    clickDelay ({ delayedFunction, params }) {
      setTimeout(() => { delayedFunction(...params) }, 1)
    },

    showChildren (mouseEvent, children) {
      if (!children) {
        this.showSubMenu = false
        return
      }

      this.children = children
      this.ycord = `${mouseEvent.srcElement.parentElement.getBoundingClientRect().y}px`
      this.showSubMenu = true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  align-items: center;
  background-color: $nav-bar-background-color;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  padding: $default-padding;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .app-version {
    display: none;
  }

  .avatar-menu-item {
    box-sizing: border-box;

    .avatar-container {
      height: 2.4rem;
      width: 2.4rem;
    }

    .avatar-text {
      display: none;
    }
  }

  ul {
    align-content: stretch;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    flex-wrap: nowrap;
    justify-content: space-around;
    list-style: none;
    margin-top: 0;
    padding: 0;

    li {
      cursor: pointer;
      margin: 0;
      overflow: visible;
      text-align: center;
      width: 100%;

      &.access-denied {
        cursor: not-allowed !important;
        opacity: 0.2 !important;
      }

      .router-link-active {
        opacity: 1;
      }

      .nav-item {
        color: $font-color-one;
        opacity: 0.7;
        text-decoration: none;
        transition: ease 300ms color;

        &:hover {
          background-color: darken($nav-bar-background-color, 4%);
          opacity: 1;
        }

        &.router-link-active {
          opacity: 1;
        }

        .nav-icon {
          font-size: min(6vw, 2rem);
          position: relative;

          .badge {
            background-color: green;
            border-radius: 50%;
            bottom: min(4vw, 0.8rem);
            color: $font-color-two;
            font-size: min(4vw, 0.7rem);
            left: min(4vw, 1.4rem);
            min-width: 40%;
            padding: min(4vw, 0.3rem);
            position: absolute;
          }
        }

        .icon-text {
          display: none;
        }
      }
    }
  }

  .active {
    cursor: auto;
    opacity: 1;
  }
}

.sub-menu {
  background-color: darken($nav-bar-background-color, 4%);
  bottom: $nav-bar-height;
  position: absolute;
  width: 100%;
  z-index: 2;

  ul {
    padding: 0;

    li {
      border-bottom: $border-width solid $border-color-one;
      color: $font-color-one;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 300;
      opacity: 0.7;
      padding: 1rem 0;
      text-align: center;

      &:hover {
        opacity: 1;
      }

      &.active {
        font-weight: 500;
      }

      &.access-denied {
        cursor: not-allowed !important;
        opacity: 0.2 !important;
      }

      .nav-icon {
        padding-right: 0.2rem;
      }
    }
  }
}

@media screen and (min-width: $media-break-1) {
  .nav {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;

    .app-version {
      color: $font-color-one;
      display: initial;
      font-size: 0.8rem;
      text-align: center;
    }

    .avatar-menu-item {
      height: 3.4rem;
      width: 3.4rem;

      .avatar-container {
        box-sizing: border-box;
        height: 3.4rem;
        width: 3.4rem;
      }

      .avatar-text {
        color: $font-color-one;
        display: block;
        font-size: min(3vw, 0.8rem);
        margin-top: 0.1vh;
        opacity: 0.7;
        text-align: center;
        text-decoration: none;

        &:hover {
          background-color: darken($nav-bar-background-color, 4%);
          color: $font-color-two;
          opacity: 1;
        }
      }
    }

    ul {
      display: block;
      flex-direction: column;
      justify-content: center;
      margin-top: 3vh;

      li {
        .nav-item {
          display: block;
          padding: 0.8rem 0;

          &.access-denied {
            cursor: not-allowed !important;
            opacity: 0.2 !important;
          }

          .nav-icon {
            font-size: 1.8rem;

            .badge {
              bottom: 1rem;
              box-sizing: content-box;
              left: 2rem;
              min-width: 20%;
            }
          }

          .icon-text {
            display: inline-block;
            font-size: 0.8rem;
            text-align: center;
          }
        }
      }
    }
  }

  .sub-menu {
    border-radius: 0 $corner $corner 0;
    bottom: unset;
    left: $nav-bar-width;
    min-width: 10rem;
    padding: 1rem;
    padding-left: 1.5rem;
    top: -10px;
    width: auto;

    ul {
      list-style-type: none;
      padding: 0 1rem 0 0;

      li {
        border: 0;
        padding: 0.2rem 0;
        text-align: left;

        &.access-denied {
          cursor: not-allowed !important;
          opacity: 0.2 !important;
        }

        .nav-icon {
          font-size: 1.4rem;
        }

        .title {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
