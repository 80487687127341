<template>
  <div>
    <p>
      Welcome to the help section, here you can find detailed help for a variety of functions within the MeaVitae app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelpDefault'
}
</script>
