'use strict'

import axios from 'axios'

const {
  VUE_APP_ENVIRONMENT,
  VUE_APP_FILESIGN_URL
} = process.env

export default async ({ userId, fileId, jwt }) => {
  return axios({
    method: 'delete',
    url: `${VUE_APP_FILESIGN_URL}/delete/${userId}/${fileId}`,
    headers: {
      Authorization: jwt,
      'X-Environment': VUE_APP_ENVIRONMENT
    },
    timeout: 8000
  })
}
