<template>
  <div class="save-bar">
    <p v-if="formIsValid">
      Everything looks good. Ready to save!
    </p>

    <p v-else>
      Not ready to save yet, please check for <span class="highlight">warnings</span> in the form
    </p>

    <div class="button-container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveBar',

  props: {
    formIsValid: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.save-bar {
  align-content: center;
  align-items: center;
  background-color: $widget-background-color;
  bottom: 0;
  color: black;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  margin: 0 -1rem -1rem;
  margin-top: auto;
  padding: 1rem;
  position: sticky;
  z-index: 1;

  p {
    color: $white;
    margin: 0;

    .highlight {
      color: $color-error;
    }
  }

  .button-container {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;
    justify-items: end;
  }
}
</style>
