<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="main-title">
          Secrets
        </h1>

        <accordion
          v-if="items.length > 0"
          :items="items"
          :open-first-section="true"
        />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view
          :key="$route.fullPath"
          @set-active="setActiveCategory"
          @deleted="handleDeleted"
          @submitted="handleSubmitted"
        />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import HelpSideBar from '@/components/HelpSideBar'
import Credential from '@/models/Credential'
import Note from '@/models/Note'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'Secrets',

  components: {
    Accordion,
    HelpSideBar,
    ThreeColumnLayout
  },

  data () {
    return {
      activeCategory: ''
    }
  },

  computed: {
    items () {
      return [
        {
          id: 'credentials',
          text: 'Credentials',
          isActive: this.activeCategory === 'credentials',
          sub: Credential
            .query()
            .get()
            .map(({ id, credentialName }) => ({
              id,
              text: credentialName,
              routeName: 'CredentialView',
              paramKey2: 'id'
            }))
            .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1),

          additionals: [
            {
              text: 'Add new',
              routeName: 'NewCredential'
            }
          ]
        },

        {
          id: 'notes',
          text: 'Notes',
          isActive: this.activeCategory === 'notes',
          sub: Note
            .query()
            .get()
            .map(({ id, title }) => ({
              id,
              text: title,
              routeName: 'Note',
              paramKey2: 'id'
            }))
            .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1),

          additionals: [
            {
              text: 'Add new',
              routeName: 'NewNote'
            }
          ]
        }
      ]
    }
  },

  methods: {
    setActiveCategory (categoryId) {
      this.activeCategory = categoryId
    },

    handleDeleted (routeObject) {
      this.$router.push(routeObject)
    },

    handleSubmitted (routeObject) {
      const notADoubleClick = routeObject.name !== this.$route.name

      if (notADoubleClick) {
        this.$router.push(routeObject)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 0.6em;
}
</style>
