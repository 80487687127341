<template>
  <div>
    <p>
      This is your secure list of monetary account and investment
      containers such as ISAs, we call it the Accounts Register.
    </p>

    <p>
      We do our best to represent an accurate net worth calculation
      for you on the Dashboard so the more information you record here,
      the more value you it will bring to your life and legacy.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AccountsDefault',

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 52,
      isInitial: true
    })
  }
}
</script>
