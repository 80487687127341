'use strict'

import Contact from '../../models/Contact'
import { merge } from 'lodash'

export function mergeContacts (contacts) {
  const existContacts = sortArrayByProperty(Contact.all().map(c => setTypeAndBuildSearchKey(c)), 'searchKey')
  const newContacts = sortArrayByProperty(contacts.map(n => setTypeAndBuildSearchKey(n)), 'searchKey')
  return deduplicateContacts(contactMerger(newContacts, existContacts))
}

const contactMerger = (inbound, current) => {
  for (let i = 0; i < inbound.length; i++) {
    for (let c = 0; c < current.length; c++) {
      if (inbound[i].searchKey === current[c].searchKey) {
        current[c] = merge(current[c], inbound[i])
        inbound.splice(i, 1)
      }
    }
  }
  return current.concat(inbound)
}

export function deduplicateContacts (contacts) {
  const master = contacts.reduce((masterContacts, contact) => {
    if (!masterContacts.find(m => m.searchKey === contact.searchKey)) masterContacts.push(contact)
    return masterContacts
  }, [])
  for (let c = 0; c < contacts.length; c++) {
    for (let i = 0; i < master.length; i++) {
      if (master[i].searchKey === contacts[c].searchKey) master[i] = merge(master[i], contacts[c])
    }
  }
  return master
}

const setTypeAndBuildSearchKey = contact => {
  if (!!contact.firstName || !!contact.middleNames || !!contact.lastName) {
    contact.type = 'person'
    contact.searchKey = `${contact?.firstName}${contact?.middleNames}${contact?.lastName}`.toLowerCase().replace(/undefined/g, '').replace(/null/g, '').replace(/\s/g, '')
  } else if (contact.organisationName) {
    contact.type = 'organisation'
    contact.searchKey = contact.organisationName.toLowerCase().replace(/undefined/g, '').replace(/null/g, '').replace(/\s/g, '')
  } else {
    contact.type = 'unknown'
    contact.searchKey = ''
  }
  if (contact.type === 'unknown') {
    if (!!contact.emailAddresses && contact.emailAddresses.length) {
      contact.searchKey = contact.emailAddresses[0].value
      contact.fullName = contact.emailAddresses[0].value.split('@')[0]
      contact.type = 'person'
    }
    if (!!contact.phoneNumbers && contact.phoneNumbers.length) {
      contact.searchKey = contact.phoneNumbers[0].value
      contact.fullName = contact.phoneNumbers[0].value.split('@')[0]
      contact.type = 'person'
    }
  }
  contact.id = contact.$id
  return contact
}

const sortArrayByProperty = (arr, propertyName) => arr.sort((a, b) => {
  if (a[propertyName] < b[propertyName]) return -1
  else if (a[propertyName] > b[propertyName]) return 1
  else return 0
})
