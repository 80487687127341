import Vue from 'vue'
import attemptApiCall from '../utils/attemptApiCall'
import attemptToDecryptString from '../utils/attemptToDecryptString'
import deleteLetter from '../apis/comms/deleteLetter'
import getNewLetterId from '../apis/comms/getNewLetterId'
import getLetter from '../apis/comms/getLetter'
import getLetters from '../apis/comms/getLetters'
import postLetter from '../apis/comms/postLetter'
import putLetter from '../apis/comms/putLetter'

export default {
  namespaced: true,

  state: {
    letters: []
  },

  getters: {
    getLetters: state => state.letters
  },

  actions: {
    generateLetter: async ({ commit }, prompt) => {
      try {
        this.showPurchaseForm = true
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getNewLetterId: async ({ commit }) => {
      try {
        const { data } = await attemptApiCall(
          getNewLetterId,
          {
            jwt: sessionStorage.getItem('jwt')
          }
        )
        return data.id
      } catch (error) {
        if (error.response?.status) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getLetterById: async ({ commit, rootState: { armoredPrivateKey } }, letterId) => {
      try {
        const { data } = await attemptApiCall(
          getLetter,
          {
            jwt: sessionStorage.getItem('jwt'),
            letterId
          }
        )
        data.title = data.title ? await attemptToDecryptString({ string: data.title, armoredPrivateKey }) : ''
        data.content = data.raw ? await attemptToDecryptString({ string: data.raw, armoredPrivateKey }) : null
        data.prompt = data.prompt ? await attemptToDecryptString({ string: data.prompt, armoredPrivateKey }) : ''

        return {
          ...data
        }
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getLetters: async ({ commit, rootState: { armoredPrivateKey } }) => {
      try {
        const { data } = await attemptApiCall(
          getLetters,
          sessionStorage.getItem('jwt')
        )
        const letters = await Promise.all([
          ...data.letters,
          ...data.scheduledLetters
        ].map(async letter => ({
          ...letter,
          title: letter.title && (await attemptToDecryptString({ string: letter.title, armoredPrivateKey }))
        })))

        commit('setLetters', letters.sort((a, b) => a?.title.charCodeAt(0) - b?.title.charCodeAt(0)))
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    postLetter: async ({ commit }, letterObject) => {
      try {
        await attemptApiCall(
          postLetter,
          {
            jwt: sessionStorage.getItem('jwt'),
            letterObject
          }
        )
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    putLetter: async ({ commit, rootState: { armoredPrivateKey } }, letterObject) => {
      try {
        await attemptApiCall(
          putLetter,
          {
            jwt: sessionStorage.getItem('jwt'),
            letterObject
          }
        )

        commit('deleteLetter', letterObject.id)
        commit('addLetter', {
          ...letterObject,
          title: letterObject.title && (await attemptToDecryptString({ string: letterObject.title, armoredPrivateKey })),
          id: letterObject.id,
          updateDate: Date.now()
        })
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    deleteLetter: async ({ commit }, letterId) => {
      try {
        await attemptApiCall(
          deleteLetter,
          {
            jwt: sessionStorage.getItem('jwt'),
            letterId
          }
        )

        commit('deleteLetter', letterId)
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setLetters: (state, value) => Vue.set(state, 'letters', value),

    addLetter: (state, value) => Vue.set(
      state,
      'letters',
      [value, ...state.letters]
    ),

    deleteLetter: (state, letterId) => Vue.set(
      state,
      'letters',
      state.letters.filter(({ id }) => id !== letterId)
    )
  }
}
