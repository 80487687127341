<template>
  <div>
    <nfc-info-dialog v-if="showNfcDialog" />
    <user-setup-onboarding
      v-if="showUserSetupOnboarding"
      :jwt="jwt"
      data-test="user-setup-onboarding"
      @close-user-setup-onboarding="closeUserSetupOnboarding"
      @user-setup-onboarding-complete="setUserToStoreGetAppApiDataAndOpenApp($event)"
    />

    <div
      v-else
      class="login-block"
      data-test="login-box"
    >
      <corp-bar class="mv-icon" />

      <unsupported-browser-or-operating-system v-if="!isBrowserOrOperatingSystemSupported" />

      <div
        v-else-if="loadingMessage"
        data-test="loading-spinner"
        class="loading-spinner-block"
      >
        <p>{{ loadingMessage }}...</p>
      </div>

      <validation-observer v-else v-slot="{ handleSubmit, invalid }">
        <form
          data-test="login-form"
          class="spaced"
          @submit.prevent="handleSubmit(login)"
        >
          <h1 v-if="useAdminLogin">
            Admin Login
          </h1>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Email"
            rules="required|email"
            slim
          >
            <e-input
              v-model="email"
              label="Email"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              type="email"
              class="input"
              data-test="email-input"
              @keyup="showCapsLockWarningIfOn"
              @input="setShowNfcDialogIfIOS(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Password"
            rules="required"
            slim
          >
            <e-input
              v-model="password"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              label="Password"
              autocomplete="on"
              type="password"
              class="input"
              data-test="password-input"
              @keyup="showCapsLockWarningIfOn"
              @input="setShowNfcDialogIfIOS(true)"
            />
          </validation-provider>

          <e-button
            v-if="!invalid"
            type="submit"
            class="button gras"
            data-test="submit-button"
          >
            Login
          </e-button>

          <router-link :to="{ name: 'ForgottenPassword' }" class="forgotten-password">
            Forgotten your password?
          </router-link>
        </form>
      </validation-observer>

      <div v-if="!loadingMessage" class="app-version">
        v.{{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import CorpBar from '@/components/CorpBar'
import NfcInfoDialog from '@/components/dialogs/NfcInfoDialog'
import UserSetupOnboarding from '@/components/UserSetupOnboarding'
import UnsupportedBrowserOrOperatingSystem from '@/components/UnsupportedBrowserOrOperatingSystem'
import attemptApiCall from '@/utils/attemptApiCall'
import getUserFromIdpLogin from '@/apis/idp/getUserFromIdpLogin'
import jwtDecode from 'jwt-decode'

export default {
  name: 'Login',

  components: {
    CorpBar,
    NfcInfoDialog,
    UserSetupOnboarding,
    UnsupportedBrowserOrOperatingSystem
  },

  props: {
    useAdminLogin: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isBrowserOrOperatingSystemSupported: true,
      email: '',
      password: '',
      loadingMessage: '',
      showNfcDialog: false,
      showUserSetupOnboarding: false,
      jwt: '',
      capsLockWarningHasAlreadyDisplayed: false
    }
  },

  computed: {
    operatingSystem () {
      return this.$store.state.operatingSystem
    },

    version () {
      return this.$store.state.appVersion
    }
  },

  created () {
    this.setIsBrowserOrOperatingSystemSupported()
  },

  methods: {
    setIsBrowserOrOperatingSystemSupported () {
      if (this.$store.state.operatingSystem === 'Android OS') this.isBrowserOrOperatingSystemSupported = false
      if (this.$store.state.browserName === 'firefox') this.isBrowserOrOperatingSystemSupported = false
      if (!window.PublicKeyCredential) this.isBrowserOrOperatingSystemSupported = false
    },

    setLoadingMessage (message) {
      this.loadingMessage = message
    },

    setShowNfcDialogIfIOS (value) {
      if (this.operatingSystem === 'iOS') this.showNfcDialog = value
    },

    showCapsLockWarningIfOn (event) {
      if (this.capsLockWarningHasAlreadyDisplayed) return
      if (!event?.getModifierState('CapsLock')) return

      this.$store.commit('snackbar/update', {
        type: 'warning',
        message: 'Caps Lock is on'
      })
      this.capsLockWarningHasAlreadyDisplayed = true
    },

    processError (error) {
      if (error.response?.data) {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || error.message,
          type: error.response?.data?.type,
          link: error.response?.data?.link
        })
      } else {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })
      }

      this.$store.dispatch('logError', {
        error,
        fileName: 'LoginBox'
      })

      this.setLoadingMessage('')
    },

    closeUserSetupOnboarding () {
      this.jwt = ''
      this.showUserSetupOnboarding = false
    },

    goToUnsubscribed () {
      this.$router.push({ name: 'UnsubscribedUser' })
    },

    async setUserToStoreGetAppApiDataAndOpenApp ({
      userId,
      avatarUrl,
      firstName,
      middleNames,
      lastName,
      email,
      permissions,
      subscribed,
      subscribedUntilDate,
      subscribedFeatures,
      subscriptionProductId,
      isPrivate,
      address1,
      address2,
      postcode,
      country,
      locale
    }) {
      try {
        this.closeUserSetupOnboarding()
        this.setLoadingMessage('Decrypting Vault Data')

        await this.$store.dispatch(
          'updateUser',
          {
            userId,
            avatarUrl,
            firstName,
            middleNames,
            lastName,
            email,
            permissions,
            subscribed,
            subscribedUntilDate,
            subscribedFeatures,
            subscriptionProductId,
            isPrivate,
            address1,
            address2,
            postcode,
            country,
            locale
          }
        )
        await this.$store.dispatch('setEssentialApiCallData')
        await this.$store.dispatch('setNonEssentialApiCallData', this.$router)

        this.setLoadingMessage('')
        this.setShowNfcDialogIfIOS(false)

        if (!this.$store.getters.user.isValid && !['Profile'].includes(this.$route.name)) {
          this.$router.push({ name: 'Profile' })
        }

        subscribed
          ? this.$store.dispatch('openApp', 'Dashboard')
          : this.goToUnsubscribed()
      } catch (error) {
        this.processError(error)
      }
    },

    async openAppToLoggedInUser ({
      userId,
      avatarUrl,
      firstName,
      middleNames,
      lastName,
      email,
      permissions,
      subscribed,
      subscribedUntilDate,
      subscribedFeatures,
      subscriptionProductId,
      isPrivate,
      address1,
      address2,
      postcode,
      country,
      locale,
      securityKeyObjects,
      armoredPublicKey,
      encryptedArmoredPrivateKey,
      rpId,
      challenge,
      jwt
    }) {
      this.setLoadingMessage('Communicating with MeaVitae Key')

      await this.$store.dispatch('repopulateState', {
        userId,
        armoredPublicKey,
        encryptedArmoredPrivateKey,
        subscribed,
        subscribedUntilDate,
        subscribedFeatures,
        subscriptionProductId,
        locale,
        securityKeyObjects,
        rpId,
        challenge,
        jwt
      })

      sessionStorage.setItem('jwt', jwt)
      this.setShowNfcDialogIfIOS(false)

      await this.setUserToStoreGetAppApiDataAndOpenApp({
        userId,
        avatarUrl,
        firstName,
        middleNames,
        lastName,
        email,
        permissions,
        subscribed,
        subscribedUntilDate,
        subscribedFeatures,
        subscriptionProductId,
        isPrivate,
        address1,
        address2,
        postcode,
        country,
        locale
      })
    },

    async initiateNewUserSetup (jwt) {
      if (this.operatingSystem === 'iOS') {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: 'Account setup is currently not supported on iPhone or iPad, please do this on a computer',
          type: 'Operating System Error',
          link: 'https://www.meavitae.com/mobile-support'
        })

        throw new Error('Account setup failed')
      }

      this.jwt = jwt
      this.showUserSetupOnboarding = true
    },

    async login () {
      try {
        const jwt = await attemptApiCall(
          getUserFromIdpLogin,
          { email: this.email, password: this.password, useAdminLogin: this.useAdminLogin }
        )

        const decodedJwt = jwtDecode(jwt)

        const isUserSetupComplete = !!decodedJwt.securityKeyObjects?.length

        isUserSetupComplete
          ? await this.openAppToLoggedInUser({ ...decodedJwt, jwt })
          : await this.initiateNewUserSetup(jwt)
      } catch (error) {
        this.processError(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-block {
  display: flex;
  flex-direction: column;
  max-width: 20rem;

  h1 {
    margin: 1em 0;
    text-align: center;
  }

  .input {
    color: $font-color-one;
  }

  input:focus {
    background-color: $link-hover-color;
    border-color: rgb(134, 134, 195);
  }

  .loading-spinner-block {
    p {
      color: $font-color-one;
      display: block;
      margin: 2rem 0 0;
      text-align: center;
    }
  }

  .button {
    letter-spacing: 0.05em;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $color-success;
      border-color: $color-success;
      color: $filled-button-text;
    }
  }

  .app-version {
    color: $font-color-one;
    display: initial;
    font-size: 0.8rem;
    margin-top: 2vh;
    text-align: center;
  }

  .mv-icon {
    height: 10rem;
  }

  .forgotten-password {
    color: $link-color;
    display: block;
    text-align: center;
    text-decoration: none;
  }
}
</style>
