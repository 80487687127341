<template>
  <div>
    <fieldset class="preview-fieldset">
      <legend>Preview</legend>

      <dynamic-textarea
        :question="question"
        :value="testValue"
        :validation-rules="question.validationRules"
        :help-html="helpHtml"
        @update="({ value }) => testValue= value"
      />
    </fieldset>

    <fieldset>
      <validation-observer v-slot="{ handleSubmit }">
        <form
          id="this-form"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Property Name"
            :rules="{ required: true, alpha: true }"
            slim
          >
            <e-input
              v-model="question.propertyName"
              label="Property Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Title"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="question.title"
              label="Title"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
            />
          </validation-provider>

          <e-textarea
            v-model="question.description"
            label="Description"
            data-test="description-textarea"
          />

          <e-select
            label="Help"
            :value="question.helpId || ''"
            :items="helpAsSelectOptions"
            @change="setHelp"
          />

          <fieldset>
            <legend>Validation Rules</legend>

            <e-select
              :items="remainingValidationOptions"
              default-option-text="Add Rule"
              @change="addValidationRule"
            />

            <ul v-if="question.validationRules">
              <li
                v-for="rule in Object.keys(question.validationRules)"
                :key="rule"
              >
                {{ validationOptions.find(({ text, value }) => value === rule).text }}

                <e-button
                  class="icon-trash-2 ghost"
                  @click="removeValidationRule(rule)"
                />
              </li>
            </ul>
          </fieldset>

          <div class="bottom-actions">
            <e-button type="submit">
              Commit
            </e-button>

            <e-button @click="$emit('close')">
              Close
            </e-button>
          </div>
        </form>
      </validation-observer>
    </fieldset>
  </div>
</template>

<script>
import DynamicTextarea from '../DynamicForm/DynamicTextarea'
import { createId } from '../../utils'

export default {
  name: 'TextareaBuilder',

  components: {
    DynamicTextarea
  },

  props: {
    help: {
      type: Array,
      default: () => []
    },

    existingValuesObject: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      helpHtml: '',

      question: {
        id: createId(),
        title: '',
        description: '',
        type: 'textarea',
        propertyName: '',
        helpId: null,
        validationRules: {}
      },

      testValue: '',

      validationOptions: [
        { value: 'required', text: 'Required' }
      ]
    }
  },

  computed: {
    helpAsSelectOptions () {
      return this.help
        .map(({ id, description }) => ({
          text: description,
          value: id
        }))
    },

    remainingValidationOptions () {
      const rules = [
        { value: 'required', text: 'Required' }
      ]

      if (!this.question.validationRules) return rules

      return rules
        .filter(({ value }) => !Object
          .keys(this.question.validationRules)
          .includes(value)
        )
    }
  },

  created () {
    if (this.existingValuesObject.id) {
      this.question = this.existingValuesObject
    }

    if (this.existingValuesObject.helpId) {
      this.setHelp(this.existingValuesObject.helpId)
    }
  },

  methods: {
    setHelp (helpId) {
      this.question.helpId = helpId
      this.helpHtml = this.help
        .find(({ id }) => id === helpId)
        ?.html || ''
    },

    addValidationRule (rule) {
      this.question.validationRules = {
        ...this.question.validationRules,
        [rule]: true
      }
    },

    removeValidationRule (rule) {
      const { [rule]: remove, ...rest } = this.question.validationRules
      this.question.validationRules = rest
    },

    submit () {
      this.$emit('submit', this.question)
    }
  }
}
</script>

<style scoped lang="scss">
.preview-fieldset {
  border-color: $border-color-one;
  margin-bottom: 1rem;
}
</style>
