'use strict'

import axios from 'axios'

export default async () => {
  const { data } = await axios({
    method: 'GET',
    // url: 'https://openexchangerates.org/api/latest.json?app_id=0b286005355e4c71b2002962425ac8ca;base=GBP'
    url: 'https://openexchangerates.org/api/latest.json?app_id=0b286005355e4c71b2002962425ac8ca'
  })

  return {
    base: data.base,
    rates: data.rates
  }
}
