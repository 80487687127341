'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ userId, jwt }) => {
  return axios({
    method: 'delete',
    url: `${VUE_APP_GATEWAY_URL}/file/2ef1fbbbd4c2d1f599ff65358aa35ffd/${userId}`,
    headers: {
      Authorization: jwt
    },
    timeout: 8000
  })
}
