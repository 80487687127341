var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Non-Disclosure Agreement "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"non-disclosure-agreement-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Agreement Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Agreement Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.agreementDate),callback:function ($$v) {_vm.agreementDate=$$v},expression:"agreementDate"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Employer")]),_c('validation-provider',{attrs:{"name":"Employer","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.employerSelectOptions,"label":"Employer","default-option-text":"Add Employer","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.employerId),callback:function ($$v) {_vm.employerId=$$v},expression:"employerId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Employer Witness","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.employerWitnessSelectOptions,"label":"Employer Witness","default-option-text":"Add Employer Witness","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.employerWitnessId),callback:function ($$v) {_vm.employerWitnessId=$$v},expression:"employerWitnessId"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Employee")]),_c('validation-provider',{attrs:{"name":"Employee","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.employeeSelectOptions,"label":"Employee","default-option-text":"Add Employee","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.employeeId),callback:function ($$v) {_vm.employeeId=$$v},expression:"employeeId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Employee Witness","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.employeeWitnessSelectOptions,"label":"Employee Witness","default-option-text":"Add Employee Witness","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.employeeWitnessId),callback:function ($$v) {_vm.employeeWitnessId=$$v},expression:"employeeWitnessId"}})]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Witnessed Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Witnessed Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.witnessedDate),callback:function ($$v) {_vm.witnessedDate=$$v},expression:"witnessedDate"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"non-disclosure-agreement-form","button-text":"Preview"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }