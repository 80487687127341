<template>
  <div>
    <e-dialog
      v-if="showClientMessageDialogue"
      class="dialog-container"
      data-test="client-message-dialogue"
    >
      <div class="header">
        <h1>Client Message</h1>

        <e-button
          class="icon-x-circle ghost"
          data-test="close-security-key-dialog-button"
          @click="toggleClientMessageDialogue(false)"
        />
      </div>

      <e-input
        v-model="client_name"
        label="To"
        :disabled="true"
      />

      <e-input
        v-model="subject"
        v:bind="subject"
        label="Subject"
      />

      <e-textarea
        v-model="body"
        v:bind="body"
        label="Message"
      />

      <e-button
        class="sendButton"
        @click="generateMessagePayloadAndSend"
      >
        SEND MESSAGE
      </e-button>
    </e-dialog>

    <div class="top-inputs">
      <h1>My Orders</h1>
    </div>

    <e-table :headers="headers" :rows="orders">
      <template #status="{ item }">
        <span
          :class="{ ...item.status === 'processing' && { loading: true }}"
        > {{ item.status }}</span>
      </template>

      <template #actions="{ item }">
        <div class="actions">
          <!-- <e-button class="icon-file ghost" @click="gotoRoute('Wallet')#item.receiptId">
            RECEIPT
          </e-button> -->
          <e-button
            v-if="item.customerEmail"
            class="ghost"
            @click="messageUser(item)"
          >
            MESSAGE
          </e-button>

          <span> | </span>

          <e-button
            class="ghost"
            v:bind="item.id"
            :disabled="refunableItem(item)"
            @click="doRefund(item)"
          >
            REFUND
          </e-button>
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import { format } from 'date-fns'
import lookupUser from '@/apis/comms/postContactLookup'

export default {
  name: 'Orders',

  components: {
    EDialog,
    ETable
  },

  data () {
    return {
      // itemDetail: '',
      intervalID: 0,
      showClientMessageDialogue: false,
      client_name: '',
      clientData: {},
      subject: '',
      body: '',
      headers: [
        {
          text: 'Date',
          value: 'created',
          align: 'left'
        },
        {
          text: 'Item',
          value: 'itemName',
          align: 'left'
        },
        {
          text: 'Customer',
          value: 'customerName',
          align: 'left'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  computed: {
    orders () {
      return this.$store.state.marketplace.orders
        .map(order => ({
          id: order.id,
          ts: order.lastModified,
          customerName: order.customerName,
          status: order.status,
          created: format(order.lastModified, 'dd/MM/yyyy - HH:mm'),
          itemName: order.itemName,
          itemId: order.itemId,
          customerEmail: order.customerEmail,
          rowClickedFunction: () => { }
        })).sort((a, b) => b.ts - a.ts)
    }
  },

  beforeDestroy () { clearInterval(this.intervalID) },

  created () {
    this.userId = this.$store.state.userId
    this.userEmail = this.$store.getters.userEmailAddress

    this.updateOrders()
    this.intervalID = setInterval(() => this.updateOrders(), 15000)
    this.$store.dispatch('findAndSetHelpObject', {
      id: 82,
      isInitial: true
    })
  },

  methods: {

    async updateOrders () {
      await this.$store.dispatch('marketplace/setOrders')
    },

    async messageUser (item) {
      // lookup the client email address to get publicKey
      this.clientData = (await lookupUser({
        contactIdObjects: [{ emailAddress: item.customerEmail }],
        jwt: sessionStorage.getItem('jwt')
      })).data[0]
      this.client_name = this.clientData.firstName + ' ' + this.clientData.lastName
      this.subject = 'Regarding ' + item.itemName
      this.body = `Dear ${this.clientData.firstName}, \n\nI am writing to you regarding your Order number ${item.id}, which you placed on ${new Date(item.ts)}. The current status of the order is ${item.status} ...`
      this.showClientMessageDialogue = true
    },

    async generateMessagePayloadAndSend () {
      try {
        const payload = {
          client: this.clientData,
          vendor: { armoredPublicKey: this.$store.state.armoredPublicKey },
          pk: this.$store.state.armoredPrivateKey,
          messageBody: this.body,
          subject: this.subject
        }
        // send details to the message generator
        await this.$store.dispatch('marketplace/sendMessageToClient', payload)
        this.toggleClientMessageDialogue(false)
      } catch (err) {
        console.error(err)
      }
    },

    async doRefund (item) {
      try {
        await this.$store.dispatch('marketplace/vendorRefundPurchase', item)
        await this.updateOrders()
      } catch (err) {
        console.error(err)
      }
    },

    refunableItem (item) {
      return item.status && ['pending', 'paid', 'delivered', 'refunded'].includes(item.status.toLowerCase())
    },

    toggleClientMessageDialogue (bool) {
      this.showClientMessageDialogue = bool
    }

    // gotoRoute (routeName) {
    //   this.$router.push({ name: routeName })
    // }
    // async getItemDetail (receiptId) {
    //   this.itemDetail = await getDetail({ jwt: sessionStorage.getItem('jwt'), receiptId })
    // }
  }
}
</script>

<style>
.refund-button {
  background-color: whitesmoke;
  border: 1px solid red;
  color: red;
  font-size: small;
  font-weight: 400;
  padding: 0.2rem;
}

.loading::after {
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
