<template>
  <router-view @submitted="handleSubmitted" />
</template>

<script>
export default {
  name: 'WillIntroduction',

  emits: [
    'submitted',
    'set-active'
  ],

  methods: {
    handleSubmitted (routeObject) {
      this.$emit('submitted', routeObject)
      this.$emit('set-active', routeObject.name)
    }
  }
}
</script>
