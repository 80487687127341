var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{attrs:{"data-test":"value-fieldset"}},[_c('legend',[_vm._v("Value")]),(_vm.visibilityObject.valuationDate)?_c('e-input',{attrs:{"label":"Valuation Date","type":"date","data-test":"valuation-date-input"},on:{"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.mutableValuationDate),callback:function ($$v) {_vm.mutableValuationDate=$$v},expression:"mutableValuationDate"}}):_vm._e(),(_vm.visibilityObject.recurringFrequency)?_c('e-select',{attrs:{"items":_vm.recurringFrequencyOptions,"label":"Recurring Frequency","help-id":_vm.recurringFrequencyHelpId,"data-test":"recurring-frequency-select"},on:{"change":function($event){_vm.isDirty = true},"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.mutableRecurringFrequency),callback:function ($$v) {_vm.mutableRecurringFrequency=$$v},expression:"mutableRecurringFrequency"}}):_vm._e(),(_vm.visibilityObject.value)?_c('div',{staticClass:"connect-input"},[(_vm.visibilityObject.currency)?_c('validation-provider',{attrs:{"name":"Currency","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{staticClass:"currency-select",attrs:{"items":_vm.currencies,"label":"Currency","error-messages":errors,"success":valid,"is-required":true,"data-test":"currency-select"},on:{"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.mutableCurrency),callback:function ($$v) {_vm.mutableCurrency=$$v},expression:"mutableCurrency"}})]}}],null,false,823050166)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Value","rules":{ required: true, min_value: 0.01 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Value","type":"number","step":".01","error-messages":errors,"success":valid,"is-required":true,"help-id":_vm.valueHelpId,"data-test":"value-input"},on:{"input":function($event){return _vm.$emit('is-dirty')},"focus":function($event){_vm.mutableValue = _vm.mutableValue || null}},model:{value:(_vm.mutableValue),callback:function ($$v) {_vm.mutableValue=$$v},expression:"mutableValue"}})]}}],null,false,1570077422)}),(_vm.visibilityObject.ownershipPercentage)?_c('validation-provider',{attrs:{"name":"Ownership Percentage","rules":{ required: true, min_value: 1, max_value: 100 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Ownership %","type":"number","error-messages":errors,"success":valid,"is-required":true,"help-id":_vm.ownershipPercentageHelpId,"data-test":"ownership-percentage-input"},on:{"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.mutableOwnershipPercentage),callback:function ($$v) {_vm.mutableOwnershipPercentage=$$v},expression:"mutableOwnershipPercentage"}})]}}],null,false,380305220)}):_vm._e(),(_vm.visibilityObject.payout)?_c('validation-provider',{attrs:{"name":"Final Payout","rules":{ required: true, min_value: 0.01 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Final Payout","type":"number","step":".01","error-messages":errors,"success":valid,"is-required":true,"data-test":"payout-input"},on:{"input":function($event){return _vm.$emit('is-dirty')},"focus":function($event){_vm.mutablePayout = _vm.mutablePayout || null}},model:{value:(_vm.mutablePayout),callback:function ($$v) {_vm.mutablePayout=$$v},expression:"mutablePayout"}})]}}],null,false,1038430719)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }