<template>
  <transition name="fade">
    <corp-bar />
  </transition>
</template>

<script>
import CorpBar from '@/components/CorpBar'

export default {
  name: 'LoadingSpinner',

  components: {
    CorpBar
  }
}
</script>
