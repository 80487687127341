<template>
  <label>
    <div
      class="fake-button"
    >
      {{ buttonText }}

      <span
        v-if="!success"
        class="icon-alert-triangle"
      />
    </div>

    <span
      v-if="helpButton"
      class="icon-help-circle"
      @click.prevent="emitHelp"
    />

    <span v-if="showFileName" class="text">
      {{ fileName }}
    </span>

    <input
      type="file"
      class="real-input"
      v-bind="$attrs"
      :success="success"
      @change="emitFileEvent"
    >

    <span
      class="error-text"
      data-test="error-message"
    >
      {{ errorMessages[0] }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'EFile',

  inheritAttrs: false,

  props: {
    errorMessages: {
      type: Array,
      default: () => []
    },

    success: {
      type: Boolean,
      default: true
    },

    helpButton: {
      type: Boolean,
      default: false
    },

    buttonText: {
      type: String,
      default: 'Select File'
    },

    showFileName: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'change',
    'input',
    'help'
  ],

  data () {
    return {
      fileName: ''
    }
  },

  methods: {
    emitFileEvent (fileEvent) {
      this.fileName = fileEvent.target.files[0].name

      this.$emit('change', fileEvent)
      this.$emit('input', fileEvent)
    },

    emitHelp () {
      return this.$emit('help', true)
    }
  }
}
</script>

<style scoped lang="scss">
.icon-alert-triangle {
  color: red;
  margin-left: 2px;
}

.error-text {
  color: $color-error;
  font-size: $font-small;
}

.real-input {
  display: none;
}

.fake-button {
  background-color: transparent;
  border: 1px solid $input-border-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $input-font-color;
  cursor: pointer;
  display: inline-block;
  font-size: $font-small;
  padding: 5px 12px;
  text-transform: uppercase;
  transition: all ease 300ms;
  width: fit-content;

  &.invalid {
    border-color: $color-error;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    &.invalid {
      border-color: $color-error;
    }
  }

  &:hover {
    border-color: $font-color-one;
    color: $font-color-two;
  }
}

.text {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.4rem;
}
</style>
