'use strict'

import AssetBeneficiary from './AssetBeneficiary'
import AssetCoOwnerContact from './AssetCoOwnerContact'
import Beneficiary from './Beneficiary'
import Contact from './Contact'
import File from './File'
import Liability from './Liability'
import LiabilityAsset from './LiabilityAsset'
import Todo from './Todo'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'
import { capitalCase } from 'change-case'

export default class Asset extends Model {
  static get entity () { return 'assets' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      willId: this.attr(null),
      type: this.string(),
      subType: this.string(),
      name: this.string(),
      address: this.attr({
        id: createId(),
        placeResult: {
          formatted_address: ''
        },
        returnData: {}
      }),
      isMainResidence: this.boolean(false),
      futureOwnershipType: this.string(),
      outstandingLoansPaidBy: this.string(),
      notes: this.string(),
      description: this.string(),

      vehicleRegistration: this.string(),
      vehicleV5Number: this.string(),
      vehicleMOTDate: this.string(),
      vehicleMOTTodoId: this.attr(null),
      vehicleMOTTodo: this.hasOne(Todo, 'id', 'vehicleMOTTodoId'),
      vehicleMOTEmailTodo: this.boolean(false),

      landRegistryOwnershipType: this.string(),
      landRegistryNumber: this.string(),

      createdDate: this.string(),

      symbol: this.string(),
      exchange: this.string(),
      quantity: this.number(),
      sharePrice: this.number(),

      valuationDate: this.string(),
      currency: this.string('GBP'),
      value: this.number(),
      ownershipPercentage: this.number(),

      coOwners: this.belongsToMany(Contact, AssetCoOwnerContact, 'assetId', 'contactId'),
      liabilities: this.belongsToMany(Liability, LiabilityAsset, 'assetId', 'liabilityId'),
      beneficiaries: this.belongsToMany(Beneficiary, AssetBeneficiary, 'assetId', 'beneficiaryId'),
      files: this.morphMany(File, 'ownerId', 'ownerType')
    }
  }

  get selectText () {
    return `${capitalCase(this.type)} - ${capitalCase(this.subType)} - ${this.name}`
  }
}
