<template>
  <div class="dialog">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'EDialog'
}
</script>

<style scoped lang="scss">
.dialog {
  background-color: $widget-background-color;
  border-radius: $corner;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  color: $font-color-two;
  display: grid;
  left: 50%;
  margin: 3vh 0 0 auto;
  min-width: 80%;
  padding: 1rem 0;
  position: fixed;
  row-gap: 1rem;
  top: 0;
  transform: translateX(-50%);
  z-index: 5;

  & > * {
    padding: 0 1rem;
  }

  h1 {
    border-bottom: 1px solid $main-background-color;
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }

  p {
    margin: 0;
  }
}

@media screen and (min-width: $media-break-1) {
  .dialog {
    min-width: 40%;
  }
}
</style>
