<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :name="question.title"
    :rules="validationRules"
    tag="div"
  >
    <e-radio-group
      :value="value"
      :label="question.title"
      :is-required="validationRules.required"
      :description="question.description"
      :items="question.options"
      :error-messages="errors"
      :success="valid"
      :help-id="helpObject.id"
      :help-function="() => $store.commit('setHelpObject', helpObject)"
      @change="$emit('update', { propertyName: question.propertyName, value: $event })"
    />
  </validation-provider>
</template>

<script>
export default {
  name: 'DynamicRadioGroup',

  props: {
    question: {
      type: Object,
      default: () => ({})
    },

    value: {
      type: String,
      default: ''
    },

    validationRules: {
      type: Object,
      default: () => ({})
    },

    helpObject: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['update']
}
</script>
