'use strict'

import { Model } from '@vuex-orm/core'

export default class GuardianContact extends Model {
  static get entity () { return 'guardianContacts' }
  static get primaryKey () { return ['willId', 'contactId'] }

  static fields () {
    return {
      willId: this.attr(null),
      contactId: this.attr(null)
    }
  }
}
