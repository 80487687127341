var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" UK Housing Act - Section 8 "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"uk-housing-act-section-eight-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true,"data-test":"document-label-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Section 8 Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Section 8 Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.sectionEightDate),callback:function ($$v) {_vm.sectionEightDate=$$v},expression:"sectionEightDate"}})]}}],null,true)}),_c('fieldset',[_c('legend',[_vm._v("Property Tenant To Leave")]),_c('validation-provider',{attrs:{"name":"Property Address","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Property Address","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.propertyAddress),callback:function ($$v) {_vm.propertyAddress=$$v},expression:"propertyAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Tenant","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.tenantSelectOptions,"label":"Tenant of Property","default-option-text":"Add Tenant of Property","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.tenantId),callback:function ($$v) {_vm.tenantId=$$v},expression:"tenantId"}})]}}],null,true)})],1),_c('fieldset',[_c('legend',[_vm._v("Why Tenant Must Leave Property")]),_c('validation-provider',{attrs:{"name":"Seeking Possession On Grounds","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Seeking Possession On Grounds","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.seekPossessionOnGround),callback:function ($$v) {_vm.seekPossessionOnGround=$$v},expression:"seekPossessionOnGround"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Explaination Of Why Each Ground","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Explaination Of Why Each Ground","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.explainationOfWhyEachGround),callback:function ($$v) {_vm.explainationOfWhyEachGround=$$v},expression:"explainationOfWhyEachGround"}})]}}],null,true)})],1),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"uk-housing-act-section-eight-form","button-text":"Preview","data-test":"bottom-preview-button"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }