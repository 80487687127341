var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("File Upload")]),_c('e-button',{staticClass:"close-button icon-x-circle ghost",on:{"click":function($event){return _vm.$emit('closed')}}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Reference","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Reference","error-messages":errors,"success":valid,"is-required":true},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Reference Date","type":"date"},model:{value:(_vm.referenceDate),callback:function ($$v) {_vm.referenceDate=$$v},expression:"referenceDate"}}),(false)?_c('e-textarea',{attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e(),_c('validation-provider',{attrs:{"name":"Upload File","rules":{ required: true, ext: _vm.acceptedFileExtensions, mimes: _vm.acceptedMimeTypes },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('e-file',{attrs:{"accept":_vm.acceptedMimeTypes.toString(),"error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setFile($event, validate)}}})]}}],null,true)}),_c('div',{staticClass:"submit-container"},[_c('e-button',{attrs:{"type":"submit","data-test":"submit-button"}},[_vm._v(" Add ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }