<template>
  <div class="app-open-container">
    <navigation-bar class="nav-bar" />

    <unsynced-bar
      v-if="isApiUnsynced"
      class="unsynced-bar"
    />

    <router-view class="main" />

    <transition name="slide">
      <feedback-bar v-if="showFeedbackBar" />
    </transition>

    <transition name="slide">
      <sliding-help v-if="showSlidingHelp" />
    </transition>
  </div>
</template>

<script>
import FeedbackBar from '@/components/FeedbackBar'
import NavigationBar from '@/components/NavigationBar'
import SlidingHelp from '@/components/SlidingHelp'
import UnsyncedBar from '@/components/UnsyncedBar'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppOpenLayout',

  components: {
    FeedbackBar,
    NavigationBar,
    SlidingHelp,
    UnsyncedBar
  },

  computed: {
    ...mapState([
      'showFeedbackBar',
      'showSlidingHelp'
    ]),

    ...mapGetters([
      'isApiUnsynced'
    ]),

    route () {
      return this.$route
    }
  },

  watch: {
    route: {
      handler: function (newRoute) {
        const routeSubscriptionFeatureId = newRoute?.meta?.subscriptionFeatureId
        const userSubscriptionFeatureIds = this.$store.state.subscribedFeatures || []

        if (routeSubscriptionFeatureId && !userSubscriptionFeatureIds.includes(routeSubscriptionFeatureId)) {
          this.$router.push({
            name: 'Membership',
            params: { upgradeSubscriptionFeatureId: routeSubscriptionFeatureId }
          })
        }
      },

      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.app-open-container {
  display: grid;
  grid-auto-columns: min-content;
  grid-gap: 0;
  grid-template-areas:
    'main'
    'unsynced-bar'
    'nav-bar';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto $nav-bar-height;
  height: 100vh;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    /* fix for safari */
    height: stretch;
  }

  .nav-bar {
    grid-area: nav-bar;
    width: 100vw;
  }

  .main {
    grid-area: main;
    overflow: hidden;
  }
}

@media screen and (min-width: $media-break-1) {
  .app-open-container {
    grid-template-areas:
      'nav-bar main'
      'nav-bar unsynced-bar';
    grid-template-columns: $nav-bar-width 1fr;
    grid-template-rows: 1fr auto;

    .nav-bar {
      width: 100%;
      /* max-width: 6rem; */
      /* min-width: 4.375rem; */
      /* width: 5%; */
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 500ms;
}

.slide-enter,
.slide-leave-to {
  right: -50vw;
}
</style>
