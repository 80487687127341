'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class BucketListItem extends Model {
  static get entity () { return 'bucketListItems' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      title: this.string(),
      details: this.string(),
      isDone: this.boolean(false),
      doneText: this.string(),
      createdDate: this.string(),
      targetDate: this.string(),
      completedDate: this.string()
    }
  }
}
