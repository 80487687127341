'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ jwt, avatar, firstName, middleNames, lastName, isPrivate, billingAddress1, billingAddress2, billingPostcode, billingCountry }) => {
  const response = await axios({
    method: 'put',
    url: `${VUE_APP_GATEWAY_URL}/idp/update`,
    headers: { Authorization: jwt },
    data: {
      avatar,
      firstName,
      middleNames,
      lastName,
      isPrivate,
      address1: billingAddress1,
      address2: billingAddress2,
      postcode: billingPostcode,
      country: billingCountry
    },
    timeout: 8000
  })

  return response.data.jwt
}
