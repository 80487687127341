var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Travel Consent For Children "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(99)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"travel-consent-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true,"data-test":"document-label-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Travel Start Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Trip Start Date","type":"date","error-messages":errors,"success":valid,"is-required":true,"data-test":"trip-start-date-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.tripStartDate),callback:function ($$v) {_vm.tripStartDate=$$v},expression:"tripStartDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Travel End Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Trip End Date","type":"date","error-messages":errors,"success":valid,"is-required":true,"data-test":"trip-end-date-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.tripEndDate),callback:function ($$v) {_vm.tripEndDate=$$v},expression:"tripEndDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Destination Country","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.destinationCountrySelectOptions,"label":"Destination Country","default-option-text":"Select Country","error-messages":errors,"success":valid,"is-required":true,"data-test":"destination-country-code-select"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.destinationCountryCode),callback:function ($$v) {_vm.destinationCountryCode=$$v},expression:"destinationCountryCode"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Accomodation Address","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Accomodation Address","error-messages":errors,"success":valid,"is-required":true,"data-test":"accomodation-address-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.accomodationAddress),callback:function ($$v) {_vm.accomodationAddress=$$v},expression:"accomodationAddress"}})]}}],null,true)}),_c('fieldset',{attrs:{"data-test":"parents-fieldset"}},[_c('legend',[_vm._v("Parents")]),(!_vm.maxParentsReached)?_c('validation-provider',{attrs:{"name":"Parent","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.parentContacts.length),expression:"parentContacts.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.parentContacts.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.parentContacts, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.parentSelectOptions,"default-option-text":"Add Contact","error-messages":errors,"success":valid,"is-required":true,"data-test":"parent-select"},on:{"change":_vm.addParentContact}})]}}],null,true)}):_vm._e(),(_vm.parentContacts.length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Parent")]),_c('th',{staticClass:"center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.parentContacts),function(contact){return _c('tr',{key:contact.id},[_c('td',[_vm._v(" "+_vm._s(contact.fullName)+" ")]),_c('td',{staticClass:"actions"},[_c('e-button',{staticClass:"icon-trash-2 ghost",attrs:{"data-test":"remove-contact-button"},on:{"click":function($event){return _vm.removeParentContact(contact.id)}}})],1)])}),0)]):_vm._e()],1),_c('fieldset',{attrs:{"data-test":"children-fieldset"}},[_c('legend',[_vm._v("Children")]),_c('validation-provider',{attrs:{"name":"Child","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.childContacts.length),expression:"childContacts.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.childContacts.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.childContacts, "length", $event.target.value)}}}),_c('e-select',{attrs:{"items":_vm.childSelectOptions,"default-option-text":"Add Contact","error-messages":errors,"success":valid,"is-required":true,"data-test":"children-select"},on:{"change":_vm.addChildContact}})]}}],null,true)}),_vm._l((_vm.childContacts),function(contact){return _c('fieldset',{key:contact.id,attrs:{"data-test":"child-fieldset"}},[_c('legend',[_vm._v(" "+_vm._s(contact.fullName)+" "),_c('e-button',{staticClass:"icon-trash-2 ghost",attrs:{"data-test":"remove-contact-button"},on:{"click":function($event){return _vm.removeChildContact(contact.id)}}})],1),_c('validation-provider',{attrs:{"vid":((contact.id) + "-passport-nationality"),"name":"Passport Nationality","rules":{ required: _vm.isInternational },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Passport Nationality","error-messages":errors,"success":valid,"is-required":_vm.isInternational,"data-test":"passport-nationality-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(contact.passportNationality),callback:function ($$v) {_vm.$set(contact, "passportNationality", $$v)},expression:"contact.passportNationality"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":((contact.id) + "-passport-number"),"name":"Passport Number","rules":{ required: _vm.isInternational },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Passport Number","error-messages":errors,"success":valid,"is-required":_vm.isInternational,"data-test":"passport-number-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(contact.passportNumber),callback:function ($$v) {_vm.$set(contact, "passportNumber", $$v)},expression:"contact.passportNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":((contact.id) + "-passport-place-of-issue"),"name":"Passport Place of Issue","rules":{ required: _vm.isInternational },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Place of Issue","error-messages":errors,"success":valid,"is-required":_vm.isInternational,"data-test":"passport-place-of-issue-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(contact.passportPlaceOfIssue),callback:function ($$v) {_vm.$set(contact, "passportPlaceOfIssue", $$v)},expression:"contact.passportPlaceOfIssue"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":((contact.id) + "-passport-date-of-issue"),"name":"Passport Date of Issue","rules":{ required: _vm.isInternational },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Date of Issue","type":"date","error-messages":errors,"success":valid,"is-required":_vm.isInternational,"data-test":"passport-date-of-issue-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(contact.passportDateOfIssue),callback:function ($$v) {_vm.$set(contact, "passportDateOfIssue", $$v)},expression:"contact.passportDateOfIssue"}})]}}],null,true)})],1)})],2),_c('e-select',{attrs:{"items":_vm.deputisedSelectOptions,"label":"Deputised Contact","default-option-text":"Add Contact","help-id":100,"data-test":"deputised-select"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.deputisedContactId),callback:function ($$v) {_vm.deputisedContactId=$$v},expression:"deputisedContactId"}}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"travel-consent-form","button-text":"Preview","data-test":"bottom-preview-button"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }