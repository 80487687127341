'use strict'

import Beneficiary from './Beneficiary'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class MonetaryGift extends Model {
  static get entity () { return 'monetaryGifts' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      willId: this.attr(null),
      beneficiaryId: this.attr(null),
      beneficiary: this.belongsTo(Beneficiary, 'beneficiaryId'),
      currency: this.string(),
      amount: this.number()
    }
  }
}
