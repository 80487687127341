import KnowledgeBaseSubCategory from '../views/KnowledgeBase/KnowledgeBaseSubCategory'
import Vue from 'vue'
import getArticle from '../apis/cms/getArticle'
import getArticles from '../apis/cms/getArticles'
import getCategories from '../apis/cms/getCategories'
import getSearchResults from '../apis/cms/getSearchResults'
import updateLiked from '../apis/cms/updateLiked'

const createDynamicRoutes = ({ categories, router }) => {
  categories.forEach(category => {
    category.subCategory
      .forEach(subCategory => {
        router.addRoute('KnowledgeBase', {
          path: ':categoryId/:subCategoryId',
          name: `${category.id}-${subCategory.id}`,
          props: true,
          component: KnowledgeBaseSubCategory,
          meta: { title: 'Knowledge Base' }
        })
      })
  })
}

export default {
  namespaced: true,

  state: {
    categories: []
  },

  actions: {
    getCategories: async ({ commit }) => {
      try {
        const response = await getCategories({
          jwt: sessionStorage.getItem('jwt')
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setCategories: async ({ commit, dispatch }, router) => {
      const categories = await dispatch('getCategories')
      commit('setCategories', categories)
      createDynamicRoutes({ categories, router })
    },

    getSearchResults: async ({ commit }, searchString) => {
      try {
        const response = await getSearchResults({
          jwt: sessionStorage.getItem('jwt'),
          searchString
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getArticles: async ({ commit }, { categoryId, subCategoryId }) => {
      try {
        const response = await getArticles({
          jwt: sessionStorage.getItem('jwt'),
          categoryId,
          subCategoryId
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    getArticle: async ({ commit }, { articleId }) => {
      try {
        const response = await getArticle({
          jwt: sessionStorage.getItem('jwt'),
          articleId
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    updateLiked: async ({ commit }, { articleId, isLiked }) => {
      try {
        const response = await updateLiked({
          jwt: sessionStorage.getItem('jwt'),
          articleId,
          isLiked
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setCategories: (state, value) => Vue.set(state, 'categories', value)
  }
}
