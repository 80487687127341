'use strict'

import axios from 'axios'

const { VUE_APP_CONTENT_URL } = process.env

export default async ({ jwt, articleId, isLiked }) => axios({
  method: 'post',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_CONTENT_URL}/like`,
  data: { id: articleId, isLiked },
  timeout: 8000
})
