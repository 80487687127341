<template>
  <div>
    <div class="title-container">
      <h1>
        Manage Address Book Contacts
      </h1>

      <e-button
        v-if="!showImportButtonBar"
        @click="showImportButtonBar = true"
      >
        IMPORT
      </e-button>
    </div>

    <p>
      Here you can easily add or delete contacts in your secure Address Book. You can aggregate
      all the contacts you have recorded in the various address books you may have
      created elsewhere by importing them from services such as Apple, Google or Microsoft.
    </p>
    <p>
      The buttons below will ask to connect only your local browser to the service and
      copy your contacts to your secure address book.
    </p>
    <p>
      The entries you record here work with all the other features within
      MeaVitae and bring your contacts and their details to important
      documents simply by selecting them from a list. When the circle beside
      a contact gets a tick mark in it, you know it is ready for use
      across all the features of MeaVitae.
    </p>

    <div v-if="showImportButtonBar" class="buttons">
      <import-contacts-from-file />
      <import-apple-contacts />
      <import-google-contacts />
      <import-microsoft-contacts />
    </div>

    <template v-if="rows.length">
      <div class="buttons-search-container">
        <div v-if="selectedContacts.length" class="buttons">
          <table-delete-dialog
            item-type="Contacts"
            @delete-confirmed="deleteContacts"
          />

          <refer-a-friend-dialog
            :pre-selected-recipients="selectedContacts"
          />
        </div>

        <e-input
          v-model="filter"
          placeholder="Search"
          class="filter-input"
        />
      </div>

      <e-table
        v-model="selectedContacts"
        :headers="headers"
        :rows="rows"
        :filter="filter"
        class="contacts-table"
      >
        <template #user="{ item }">
          <corp-bar v-if="item.isMeaVitaeUser" class="mv-icon" />
        </template>

        <template #invited="{ item }">
          {{ item.inviteStatus }}
        </template>

        <template #actions="{ item }">
          <div class="actions">
            <e-button
              v-if="item.isMeaVitaeUser"
              class="icon-envelope-o ghost"
              @click.stop="messageContact(item)"
            />

            <e-button
              class="icon-edit ghost"
              data-test="edit-contact-button"
              @click="event => editContact({ event, item })"
            />
          </div>
        </template>
      </e-table>

      <div v-if="selectedContacts.length" class="buttons">
        <table-delete-dialog
          item-type="Contacts"
          @delete-confirmed="deleteContacts"
        />

        <refer-a-friend-dialog
          :pre-selected-recipients="selectedContacts"
        />
      </div>
    </template>
  </div>
</template>

<script>
import CorpBar from '@/components/CorpBar'
import ETable from '@/components/ETable'
import ImportAppleContacts from '@/components/contactImporters/ImportAppleContacts'
import ImportContactsFromFile from '@/components/contactImporters/ImportContactsFromFile'
import ImportGoogleContacts from '@/components/contactImporters/ImportGoogleContacts'
import ImportMicrosoftContacts from '@/components/contactImporters/ImportMicrosoftContacts'
import ReferAFriendDialog from '@/components/dialogs/ReferAFriendDialog'
import TableDeleteDialog from '@/components/dialogs/TableDeleteDialog'

export default {
  name: 'ContactManager',

  components: {
    CorpBar,
    ETable,
    ImportAppleContacts,
    ImportContactsFromFile,
    ImportGoogleContacts,
    ImportMicrosoftContacts,
    ReferAFriendDialog,
    TableDeleteDialog
  },

  data () {
    return {
      selectedContacts: [],
      filter: '',
      showImportButtonBar: false,

      headers: [
        {
          text: '',
          value: 'user',
          align: 'left',
          searchable: false,
          sortable: false
        },

        {
          text: 'Name',
          value: 'fullName',
          align: 'left',
          searchable: true
        },

        {
          text: 'Invited?',
          value: 'invited',
          align: 'center',
          sortable: false
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  computed: {
    contactsWithInviteStatus () {
      return this.$store.state.contactsWithInviteStatus || []
    },

    rows () {
      return this.contactsWithInviteStatus
        .filter(({ fullName }) => (this.filter.toLowerCase()
          ? fullName.toLowerCase().includes(this.filter.toLowerCase())
          : true
        ))
        .map(({ id, firstName, lastName, fullName, isValid, userId, inviteStatus = '' }) => ({
          id,
          firstName,
          lastName,
          fullName,
          isMeaVitaeUser: !!userId,
          isValid,
          userId,
          inviteStatus: inviteStatus.toUpperCase(),
          rowClickedFunction: () => this.$router.push({
            name: 'ContactView',
            params: {
              contactId: id,
              letterId: (fullName || '').charAt(0).toUpperCase()
            }
          })
        }))
        .sort((a, b) => (a?.fullName || '').toUpperCase() < (b?.fullName || '').toUpperCase() ? -1 : 1)
    }
  },

  async created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 2,
      isInitial: true
    })

    await this.$store.dispatch('getAndSetContactsInviteStatus')
  },

  methods: {
    editContact ({ event, item: { id: contactId, fullName } }) {
      event.stopPropagation()

      this.$router.push({
        name: 'EditContact',
        params: {
          contactId,
          letterId: fullName.charAt(0).toUpperCase()
        }
      })
    },

    async deleteContacts () {
      try {
        await this.$store.dispatch('deleteContacts', this.selectedContacts)
        this.selectedContacts = []

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Selected contacts have been successfully deleted.'
        })
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'ContactManager',
          functionName: 'deleteContacts'
        })
      }
    },

    messageContact ({ firstName, lastName, userId }) {
      this.$router.push({
        name: 'NewMessage',
        params: {
          predefinedTo: [{ firstName, lastName, userId }]
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {
  display: inline-grid;
  flex-basis: auto;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-items: start;
}

.buttons-search-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  margin: 1em 0;

  .filter-input {
    flex-grow: 1;
  }
}

.contacts-table {
  margin: 1em 0;
}

.mv-icon {
  height: 1.8em;
}

.title-container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1em;

  h1 {
    flex-grow: 1;
  }
}

@media screen and (min-width: $media-break-1) {
  .buttons {
    grid-auto-flow: column;
  }

  .buttons-search-container {
    flex-flow: row wrap;
  }
}
</style>
