<template>
  <div class="spaced" data-test="share-fieldset">
    <validation-provider
      v-slot="{ errors, valid }"
      name="Symbol"
      rules="required"
      tag="div"
    >
      <e-input
        v-model="mutableSymbol"
        label="Symbol"
        :error-messages="errors"
        :success="valid"
        :is-required="true"
        :help-id="31"
        data-test="symbol-input"
        @input="$emit('is-dirty')"
      />
    </validation-provider>

    <e-input
      v-model="mutableExch"
      label="Exchange"
      :help-id="32"
      @input="$emit('is-dirty')"
    />

    <validation-provider
      v-slot="{ errors, valid }"
      name="Quantity"
      rules="required|min_value:1"
      tag="div"
    >
      <e-input
        v-model="mutableQuantity"
        label="Quantity"
        type="number"
        :error-messages="errors"
        :success="valid"
        :is-required="true"
        :help-id="30"
        @input="$emit('is-dirty')"
      />
    </validation-provider>

    <e-input
      v-model="mutablePrice"
      label="Price"
      @input="$emit('is-dirty')"
    />

    <e-input
      v-model="mutableCurrency"
      label="Currency"
      @input="$emit('is-dirty')"
    />
  </div>
</template>

<script>
export default {
  name: 'ShareFieldset',

  props: {
    symbol: {
      type: String,
      default: ''
    },

    exchange: {
      type: String,
      default: ''
    },

    quantity: {
      type: [Number, String],
      default: 0
    },

    price: {
      type: Number,
      default: 0
    },

    currency: {
      type: String,
      default: ''
    }
  },

  computed: {
    mutableSymbol: {
      get () {
        return this.symbol
      },

      set (value) {
        this.$emit('value-updated', { property: 'symbol', value })
      }
    },

    mutableExch: {
      get () {
        return this.exchange
      },

      set (value) {
        this.$emit('value-updated', { property: 'exchange', value })
      }
    },

    mutableQuantity: {
      get () {
        return this.quantity
      },

      set (value) {
        this.$emit('value-updated', { property: 'quantity', value })
      }
    },

    mutablePrice: {
      get () {
        return this.price
      },

      set (value) {
        this.$emit('value-updated', { property: 'price', value })
      }
    },

    mutableCurrency: {
      get () {
        return this.currency
      },

      set (value) {
        this.$emit('value-updated', { property: 'currency', value })
      }
    }
  }
}
</script>
