<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <div class="form-options">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Ttile"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="jsonFile.title"
              placeholder="Form Title"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
            />
          </validation-provider>

          <div class="connect-input">
            <e-button
              :class="{ active: displayElement === 'header' }"
              @click="toggleHeader"
            >
              Header
            </e-button>

            <e-button
              :class="{ active: displayElement === 'footer' }"
              @click="toggleFooter"
            >
              Footer
            </e-button>

            <e-button
              :class="{ active: displayElement === 'questions' }"
              @click="toggleQuestions"
            >
              Questions
            </e-button>

            <e-button
              :class="{ active: displayElement === 'help' }"
              @click="toggleHelp"
            >
              Help
            </e-button>

            <e-button @click="load7im">
              Load File
            </e-button>
          </div>
        </div>

        <component
          :is="getQuestionBuilder(draftQuestionType)"
          v-if="displayElement === 'questionBuilder'"
          :help="jsonFile.help"
          :existing-values-object="existingValuesObject"
          @submit="commitQuestionToForm"
          @close="closeDraftQuestion"
        />

        <help-item-element
          v-if="displayElement === 'helpItem'"
          :help-object="selectedHelpObject"
          @submit="addHelpObject"
          @close="closeHelpItem"
        />

        <fieldset v-if="displayElement === 'header'">
          <legend>Header</legend>

          <e-textarea v-model="jsonFile.header" />
        </fieldset>

        <fieldset v-if="displayElement === 'footer'">
          <legend>Footer</legend>

          <e-textarea v-model="jsonFile.footer" />
        </fieldset>

        <fieldset v-if="displayElement === 'questions'">
          <legend>Questions</legend>

          <e-select
            :items="questionTypes"
            default-option-text="Add Question"
            @change="addDraftQuestion"
          />

          <ol>
            <li v-for="question in jsonFile.questions" :key="question.id">
              {{ question.title }}

              <e-button
                class="icon-edit ghost"
                @click="editQuestion(question)"
              />

              <e-button
                class="icon-trash-2 ghost"
                @click="deleteQuestion(question.id)"
              />
            </li>
          </ol>
        </fieldset>

        <fieldset v-if="displayElement === 'help'">
          <legend>Help</legend>

          <e-button @click="createHelpObject">
            Create
          </e-button>

          <ul>
            <li v-for="helpObject in jsonFile.help" :key="helpObject.id">
              <span>{{ helpObject.description }}</span>

              <e-button
                class="icon-edit ghost"
                @click="editHelp(helpObject)"
              />

              <e-button
                class="icon-trash-2 ghost"
                @click="deleteHelpObject(helpObject.id)"
              />
            </li>
          </ul>
        </fieldset>
      </div>
    </template>

    <template #two>
      <div class="padded">
        <dynamic-form
          :json-file="jsonFile"
          @submit="dynamicFormSubmitted"
        />
      </div>
    </template>

    <template #three>
      <div class="padded">
        <help-side-bar />

        <fieldset>
          <legend>Property Object</legend>

          <ul v-for="(value, key) in propertiesObject" :key="key">
            <li>{{ key }} : {{ value }}</li>
          </ul>
        </fieldset>
      </div>
    </template>
  </three-column-layout>
</template>

<script>
import DynamicForm from '../DynamicForm'
import FieldsetBuilder from './FieldsetBuilder'
import HelpSideBar from '../HelpSideBar'
import HelpItemElement from './HelpItemElement'
import NumberInputBuilder from './NumberInputBuilder'
import RadioGroupBuilder from './RadioGroupBuilder'
import SelectBuilder from './SelectBuilder'
import TextareaBuilder from './TextareaBuilder'
import TextInputBuilder from './TextInputBuilder'
import ThreeColumnLayout from '../ThreeColumnLayout'
import { createId } from '../../utils'

import questionnaire from '../../json_files/7iMQuestionnaire.json'

export default {
  name: 'FormCreator',

  components: {
    DynamicForm,
    FieldsetBuilder,
    HelpSideBar,
    HelpItemElement,
    NumberInputBuilder,
    RadioGroupBuilder,
    SelectBuilder,
    TextareaBuilder,
    TextInputBuilder,
    ThreeColumnLayout
  },

  data () {
    return {
      jsonFile: {
        id: '',
        title: '',
        header: '',
        footer: '',
        questions: [],
        help: []
      },

      selectedHelpObject: {
        html: ''
      },

      draftQuestionType: '',
      displayElement: 'default',
      existingValuesObject: {},
      propertiesObject: {},

      questionTypes: [
        { value: 'textInput', text: 'Text Input' },
        { value: 'numberInput', text: 'Number Input' },
        { value: 'select', text: 'Select' },
        { value: 'radioGroup', text: 'Radio Group' },
        { value: 'textarea', text: 'Textarea' },
        { value: 'fieldset', text: 'Fieldset' }
      ]
    }
  },

  created () {
    this.$store.commit('setHelpObject', {
      html: '<p>Some help text for the Form Creator</p>'
    })
  },

  methods: {
    load7im () {
      this.jsonFile = questionnaire
    },

    getQuestionBuilder (type) {
      const lookup = {
        textInput: 'TextInputBuilder',
        numberInput: 'NumberInputBuilder',
        select: 'SelectBuilder',
        radioGroup: 'RadioGroupBuilder',
        textarea: 'TextareaBuilder',
        fieldset: 'FieldsetBuilder'
      }

      return lookup[type]
    },

    addDraftQuestion (type) {
      this.draftQuestionType = type
      this.displayElement = 'questionBuilder'
      this.existingValuesObject = {}
    },

    closeDraftQuestion () {
      this.draftQuestionType = ''
      this.displayElement = 'questions'
      this.existingValuesObject = {}
    },

    commitQuestionToForm (questionObject) {
      const index = this.jsonFile
        .questions
        .findIndex(({ id }) => id === questionObject.id)

      index > -1
        ? this.jsonFile.questions[index] = questionObject
        : this.jsonFile.questions.push(questionObject)

      this.draftQuestionType = ''
      this.displayElement = 'questions'
      this.existingValuesObject = {}
    },

    editQuestion (question) {
      this.draftQuestionType = this.jsonFile
        .questions
        .find(({ id }) => id === question.id)
        .type

      this.existingValuesObject = question
      this.displayElement = 'questionBuilder'
    },

    deleteQuestion (questionId) {
      this.jsonFile.questions = this.jsonFile
        .questions
        .filter(({ id }) => id !== questionId)
    },

    createHelpObject () {
      this.selectedHelpObject = {
        id: createId(),
        description: '',
        html: ''
      }

      this.displayElement = 'helpItem'
    },

    editHelp (helpObject) {
      this.selectedHelpObject = helpObject
      this.displayElement = 'helpItem'
    },

    deleteHelpObject (helpId) {
      this.jsonFile.help = this.jsonFile
        .help
        .filter(({ id }) => id !== helpId)
    },

    addHelpObject (helpObject) {
      const index = this.jsonFile
        .help
        .findIndex(({ id }) => id === helpObject.id)

      index > -1
        ? this.jsonFile.help[index] = helpObject
        : this.jsonFile.help.push(helpObject)

      this.displayElement = 'help'

      this.selectedHelpObject = {
        html: ''
      }
    },

    closeHelpItem () {
      this.displayElement = 'help'

      this.selectedHelpObject = {
        html: ''
      }
    },

    toggleHeader () {
      this.displayElement = this.displayElement === 'header'
        ? 'default'
        : 'header'
    },

    toggleFooter () {
      this.displayElement = this.displayElement === 'footer'
        ? 'default'
        : 'footer'
    },

    toggleQuestions () {
      this.displayElement = this.displayElement === 'questions'
        ? 'default'
        : 'questions'
    },

    toggleHelp () {
      this.displayElement = this.displayElement === 'help'
        ? 'default'
        : 'help'
    },

    dynamicFormSubmitted (propertiesObject) {
      this.propertiesObject = propertiesObject
    }
  }
}
</script>

<style scoped lang="scss">
.form-options {
  display: grid;
  justify-items: stretch;
  margin-bottom: 1rem;
  row-gap: 0.4rem;

  .connect-input {
    justify-content: space-evenly;
  }
}
</style>
