'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ jwt }) => axios({
  method: 'delete',
  url: `${VUE_APP_GATEWAY_URL}/idp/delete-account`,
  headers: { Authorization: jwt },
  timeout: 8000
})
