'use strict'

import attemptApiCall from '../utils/attemptApiCall'
import createCredential from '../apis/idp/createCredential'

export default async ({ securityKeyId, armoredPublicKey, encryptedArmoredPrivateKey, credentialId, jwt }) => {
  const securityKeyObject = {
    name: 'Master',
    securityKeyId,
    credentialId,
    default: true,
    notes: 'Your master security key created when first setting up your MeaVitae vault',
    created: Date.now()
  }

  return attemptApiCall(
    createCredential,
    {
      jwt,
      armoredPublicKey,
      encryptedArmoredPrivateKey,
      securityKeyObjects: [securityKeyObject]
    }
  )
}
