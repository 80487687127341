'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ contactIdObjects, jwt }) => axios({
  method: 'post',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_COMMS_URL}/resolve`,
  data: contactIdObjects,
  timeout: 8000
})
