<template>
  <div>
    <e-dialog
      v-if="showCashOutDialogue"
      class="dialog-container"
      data-test="vendor-cash-out"
    >
      <div class="header">
        <h1>Cash Out Tokens</h1>

        <e-button
          v-model="cashTokensNumber"
          class="icon-x-circle ghost"
          data-test="close-security-key-dialog-button"
          @click="closeTokenCashOutDialog"
        />
      </div>

      <span>Enter the number of Tokens you wish to cash out</span>

      <e-input
        v-model="sellTokensNumber"
        data-test="sellTokensNumber"
        class="sell-tokens-input"
      />

      <e-button
        class="button"
        data-test="generateInvoice"
        @click="generateInvoice"
      >
        Generate and Send Invoice
      </e-button>
    </e-dialog>
    <buy-tokens-dialogue
      v-if="showPurchaseTokenDialogue"
      :pricemeta="tokenPriceMeta"
      @close-dialogue="closePurchaseTokenDialogue"
    />
    <div class="wallet-header">
      <h1>
        {{ userIsVendor ? 'My MVT Statement' : 'My MVT Wallet' }}
      </h1>

      <h2> Balance: {{ tokens || 0 }} MVT</h2>

      <div class="add-button-wrapper">
        <e-button
          v-if="userIsVendor"
          data-test="cashout-tokens-key-button"
          @click="openCashOutDialogue"
        >
          CASH OUT TOKENS
        </e-button>

        <e-button data-test="add-tokens-key-button" @click="showPurchaseDialogue">
          BUY TOKENS
        </e-button>
      </div>
    </div>

    <e-table :headers="headers" :rows="statement">
      <template #actions="{ item }">
        <div class="actions" :name="(item.id)">
          <e-button
            v-if="item.hasReceipt"
            class="icon-file-pdf ghost"
            @click="downloadReceipt(item.id, item.source)"
          />
        </div>
      </template>
    </e-table>
  </div>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import ETable from '@/components/ETable'
import generateVendorInvoice from '../../apis/wallet/generateVendorInvoice'
import getReceiptById from '../../apis/wallet/getReceiptById'
import getStatementForUserId from '../../apis/wallet/getStatementForUserId'
import getVendorInvoice from '../../apis/wallet/getVendorInvoice'
import BuyTokensDialogue from '@/components/BuyTokensDialogue.vue'
import { format } from 'date-fns'
import { htmlToPdf } from '../../utils/htmlToPdf'

export default {
  name: 'Wallet',

  components: {
    ETable,
    EDialog,
    BuyTokensDialogue
  },

  data () {
    return {
      showCashOutDialogue: false,
      showPurchaseTokenDialogue: false,
      cashTokensNumber: 0,
      tokenPriceMeta: {},
      buyTokensNumber: 0,
      buyTokenGrossPrice: 0,
      buyTokenVAT: 0,
      buyTokenTotalPrice: 0,
      sellTokensNumber: 0,
      tokens: 0,
      receipts: [],
      intervalID: 0,
      headers: [
        {
          text: 'Date',
          value: 'created',
          align: 'left'
        },
        {
          text: 'Item',
          value: 'title',
          align: 'left'
        },
        {
          text: 'Value',
          value: 'value',
          align: 'right'
        },
        {
          text: 'Receipt',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  computed: {
    userIsVendor () {
      return this.$store.getters.userIsVendor
    },

    statement () {
      return this.receipts
        .map(receipt => ({
          id: receipt.id,
          ts: receipt.created,
          created: format(receipt.created, 'dd/MM/yyyy - HH:mm'),
          title: receipt.title,
          source: receipt.source,
          hasReceipt: ['revolut', 'stripe', 'meavitae'].includes(receipt.source),
          value: receipt.value || 0
          // rowClickedFunction: () => { console.log(receipt.id) } // TODO: make a row expander.
        }
        )).sort((a, b) => b.ts - a.ts)
    }
  },

  beforeDestroy () { clearInterval(this.intervalID) },

  created () {
    this.userId = this.$store.state.userId
    this.userEmail = this.$store.getters.userEmailAddress

    this.updateStatement()
    this.intervalID = setInterval(() => this.updateStatement(), 15000)

    this.$store.dispatch('findAndSetHelpObject', {
      id: 82,
      isInitial: true
    })
  },

  methods: {
    showPurchaseDialogue () {
      this.zeroOutPurchaseDialogue()
      this.showPurchaseTokenDialogue = true
    },

    zeroOutPurchaseDialogue () {
      this.buyTokensNumber = 0
      this.buyTokenGrossPrice = 0
      this.buyTokenVAT = 0
      this.buyTokenTotalPrice = 0
    },

    async openCashOutDialogue () {
      this.showCashOutDialogue = true
    },

    async downloadReceipt (receiptId, source) {
      if (source === 'meavitae') return await this.renderInvoice(receiptId)
      const { data } = await getReceiptById(receiptId)
      await htmlToPdf({ html: data })
    },

    async updateStatement () {
      const { data } = await getStatementForUserId()
      this.tokens = data.tokens
      this.receipts = data.receipts
      this.tokenPriceMeta = data.tokenPriceMeta
    },

    closeTokenCashOutDialog () {
      this.zeroOutPurchaseDialogue()
      this.showCashOutDialogue = false
    },

    closePurchaseTokenDialogue () {
      this.showPurchaseTokenDialogue = false
    },

    async generateInvoice () {
      const { data } = await generateVendorInvoice({ tokensNumber: this.sellTokensNumber, tokenPriceMeta: this.tokenPriceMeta })
      this.closeTokenCashOutDialog()
      if (data) {
        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'CashOut Invoice Generate and Submitted. Check your email for more details.'
        })
      } else {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'CashOut Invoice Generation failed. Please try again later, or email support.'
        })
      }
    },

    async renderInvoice (invoiceId) {
      const { data } = await getVendorInvoice(invoiceId)
      const url = URL.createObjectURL(new Blob([data], { type: 'text/html' }))
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.wallet-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1em;
}

.sell-tokens-input {
  margin-left: 17rem;
  text-align: right;
  width: 20%;
}

.button {
  margin-left: 20%;
  width: 60%;
}

.quantity {
  float: left;
  width: 10%;
}

@media screen and (min-width: $media-break-1) {
  .wallet-header {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}
</style>
