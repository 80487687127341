'use strict'

export default async (string, algorithm = 'SHA-256') => {
  const enc = new TextEncoder()
  const data = enc.encode(string)

  return crypto.subtle.digest(algorithm, data).then(hash => {
    const hexCodes = []
    const view = new DataView(hash)
    for (let i = 0; i < view.byteLength; i += 4) {
      const value = view.getUint32(i)
      const stringValue = value.toString(16)
      const padding = '00000000'
      const paddedValue = (padding + stringValue).slice(-padding.length)
      hexCodes.push(paddedValue)
    }

    return hexCodes.join('')
  })
}
