var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showDeleteDialog)?_c('confirmation-dialog',{attrs:{"title":"Delete Monetary Gift","data-test":"delete-monetary-gift-dialog"},on:{"cancel":function($event){_vm.showDeleteDialog = false},"confirm":_vm.deleteMonetaryGift}}):_vm._e(),_c('div',[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Monetary Gift")]),_c('e-button',{staticClass:"close-button icon-x-circle ghost",attrs:{"data-test":"close-dialog-button"},on:{"click":function($event){return _vm.$emit('closed')}}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"spaced",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Beneficiary","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.eligibleContactsAndGroupsAsSelectOptions,"default-option-text":"Select Beneficiary","label":"Beneficiary","error-messages":errors,"success":valid,"data-test":"beneficiary-select"},model:{value:(_vm.selectedContactOrGroupId),callback:function ($$v) {_vm.selectedContactOrGroupId=$$v},expression:"selectedContactOrGroupId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Gift Value","rules":"required|min_value:1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Monetary Gift Value","type":"number","error-messages":errors,"success":valid,"data-test":"gift-value-input","prefix":"£"},on:{"focus":function($event){_vm.amount = _vm.amount || null}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})]}}],null,true)}),(_vm.selectedContactOrGroupId && _vm.selectedContactOrGroup.type === 'person')?[_c('p',[_vm._v(" If the gift to the beneficiary fails, should the it go to the beneficiary’s children or their respective descendants? ")]),_c('validation-provider',{staticClass:"item",attrs:{"name":"Go to their children?","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"value":_vm.beneficiaryDeadShareGoesToTheirChildren,"radio-value":true,"type":"radio","label":"Yes"},on:{"change":function (value) { return _vm.beneficiaryDeadShareGoesToTheirChildren = value; }}}),_c('e-input',{attrs:{"value":_vm.beneficiaryDeadShareGoesToTheirChildren,"radio-value":false,"type":"radio","label":"No"},on:{"change":function (value) { return _vm.beneficiaryDeadShareGoesToTheirChildren = value; }}}),(!valid)?_c('span',[_vm._v(_vm._s(errors))]):_vm._e()]}}],null,true)})]:_vm._e(),_c('confirmation-buttons',{attrs:{"confirm-button-text":"Save","confirm-button-type":"submit","cancel-button-text":"Delete"},on:{"cancel":function($event){_vm.showDeleteDialog = true}}})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }