<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Money

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(72)"
        />
      </h1>
    </div>

    <p>
      This section lets you give sums of money to named individuals, groups of individuals or charities.
      You can also use the section to release people from debt they might owe you on your death. It is
      worth noting that you can reduce your Inheritance Tax percentage from 40% to 36% if you leave
      at least 10% of your estate to charity.
    </p>

    <fieldset>
      <legend>Payable Inheritance Tax Instruction</legend>

      <p>
        Any inheritance tax payable on the following monetary gifts will be payable:
      </p>

      <e-input
        :value="payableInheritanceTaxInstruction"
        radio-value="paidByTheEstate"
        type="radio"
        label="From the Estate"
        data-test="paid-by-the-estate-radio"
        @change="value => payableInheritanceTaxInstruction = value"
      />

      <e-input
        :value="payableInheritanceTaxInstruction"
        radio-value="paidByTheBeneficiaries"
        type="radio"
        label="By the beneficiaries"
        data-test="paid-by-the-beneficiaries-radio"
        @change="value => payableInheritanceTaxInstruction = value"
      />
    </fieldset>

    <monetary-gift-manager data-test="monetary-gift-manager" />

    <monetary-debt-manager data-test="monetary-debt-manager" />
  </div>
</template>

<script>
import MonetaryDebtManager from '@/components/MonetaryDebtManager/MonetaryDebtManager'
import MonetaryGiftManager from '@/components/MonetaryGiftManager/MonetaryGiftManager'
import Will from '@/models/Will'

export default {
  name: 'Money',

  components: {
    MonetaryDebtManager,
    MonetaryGiftManager
  },

  computed: {
    will () {
      return Will
        .query()
        .first()
    },

    payableInheritanceTaxInstruction: {
      get () {
        return this.will.payableInheritanceTaxInstruction
      },

      async set (value) {
        Will.update({
          where: this.will.id,
          data: {
            payableInheritanceTaxInstruction: value
          }
        })

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['will'],
          message: 'Saved'
        })
      }
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 72,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    }
  }
}
</script>
