<template>
  <article v-if="title" class="article">
    <h1>
      {{ title }}
    </h1>

    <div class="published-and-liked">
      <p>
        Published on {{ publishedAt }} by {{ author.name }}
      </p>

      <section class="like">
        <div class="like-element" @click="changeIsLiked">
          <span class="text">like</span>
          <span class="icon" :class="isLiked ? 'icon-heart1' : 'icon-heart-o'" />
        </div>
      </section>
    </div>

    <component :is="html" class="article-body" />

    <section class="related-articles">
      <div
        v-for="relatedArticle in relatedArticles"
        :key="relatedArticle.id"
        class="related-article"
        @click="goToRelatedArticle(relatedArticle)"
      >
        {{ relatedArticle.text }}
      </div>
    </section>
  </article>
</template>

<script>
import portableTextToHtml from '@/utils/portableTextToHtml'
import { format, parseISO } from 'date-fns'

export default {
  name: 'KnowledgeBaseArticle',

  props: {
    categoryId: {
      type: String,
      default: ''
    },

    articleId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      author: {},
      title: '',
      body: '',
      tags: [],
      publishedAt: '',
      isLiked: false,
      relatedArticles: []
    }
  },

  computed: {
    html () {
      return {
        template: portableTextToHtml(this.body)
      }
    }
  },

  async created () {
    const article = await this.$store.dispatch('knowledgeBase/getArticle', {
      articleId: this.articleId
    })

    this.$emit('check-article-out-of-sync-with-accordion', this.categoryId)

    this.author = article.author
    this.title = article.title
    this.body = article.body
    this.tags = article.tags.join(', ')
    this.publishedAt = format(parseISO(article.publishedAt), 'MMMM d, Y')
    this.isLiked = article.isLiked

    this.relatedArticles = article
      .related
      .map(related => ({
        articleId: related._id,
        categoryId: related.category.id,
        subCategoryId: related.subCategory.id,
        text: related.title
      }))
  },

  methods: {
    goToRelatedArticle ({ categoryId, subCategoryId, articleId }) {
      this.$router.push({
        name: 'KnowledgeBaseArticle',
        params: {
          categoryId,
          subCategoryId,
          articleId
        }
      })
    },

    async changeIsLiked () {
      try {
        await this.$store.dispatch('knowledgeBase/updateLiked', {
          articleId: this.articleId,
          isLiked: !this.isLiked
        })

        this.isLiked = !this.isLiked
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'KnowledgeBaseArticle',
          functionName: 'changeIsLiked'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.article {
  border: $border-width solid $border-color-one;
  display: grid;
  grid-row-gap: 1rem;

  h1 {
    padding-bottom: 1rem;
  }

  .published-and-liked {
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
      margin: 0;
    }
  }

  .article-body {
    padding-bottom: 1rem;
  }

  .like {
    display: grid;
    justify-items: end;

    .like-element {
      cursor: pointer;
      display: grid;
      grid-column-gap: 0.2rem;
      grid-template-columns: 1fr 1fr;
      text-transform: uppercase;

      .icon {
        color: $heart-color;
        font-size: 1.4rem;

        &:hover {
          font-weight: bold;
        }
      }

      .text,
      .icon {
        align-self: end;
      }
    }
  }

  .related-articles {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: stretch;
    padding-top: 1rem;
    row-gap: 1rem;

    .related-article {
      align-items: center;
      border: 1px solid $link-color;
      border-radius: 4px;
      cursor: pointer;
      display: grid;
      padding: 1rem;
      text-align: center;

      &:hover {
        background-color: $main-brand-color;
        border-color: $main-brand-color;
        color: $font-color-one;
      }
    }
  }
}
</style>
