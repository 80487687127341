<template>
  <transition name="fade">
    <div class="file-progress-element">
      <label>
        <h1>
          {{ fileActionObject.name }}
        </h1>

        <div class="progress-element">
          <progress
            :value="fileActionObject.progress"
            max="100"
            class="progress-bar"
          >
            {{ fileActionObject.progress }}%
          </progress>

          <e-button
            class="icon-x-circle ghost"
            @click="cancel"
          />
        </div>
      </label>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProgressBox',

  props: {
    fileActionObject: {
      type: Object,
      default: () => ({})
    }
  },

  watch: {
    'fileActionObject.progress' () {
      if (this.fileActionObject.progress === 100) {
        this.$store.dispatch('files/handleFileCompletedEvent', this.fileActionObject.id)
      }
    }
  },

  methods: {
    cancel () {
      this.fileActionObject.abortController.abort()
      this.$store.commit('files/removeFileActionObject', this.fileActionObject.id)
    }
  }
}
</script>

<style scoped lang="scss">
.file-progress-element {
  background-color: black;
  border-radius: 6px;
  border-width: 0;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
  color: $font-color-two;
  font-size: 1rem;
  font-weight: 400;
  min-width: 10rem;
  padding: 1rem;

  label {
    display: grid;
    row-gap: 0.8rem;
  }

  h1 {
    font-size: 1rem;
  }

  .progress-element {
    display: grid;
    grid-template-columns: 90% 10%;

    .progress-bar {
      justify-self: start;
      width: 100%;
    }

    .icon-x-circle {
      justify-self: end;
      padding: 0;
    }
  }
}
</style>
