<template>
  <div>
    <section>
      <div class="top-inputs">
        <h1>
          Generators in category: {{ products.length }}
        </h1>
      </div>

      <div class="products">
        <product-element
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ProductElement from '@/components/ProductElement'

export default {
  name: 'ProductsByCategory',

  components: {
    ProductElement
  },

  props: {
    subCategoryId: {
      type: String,
      default: ''
    }
  },

  computed: {
    products () {
      return this.$store.state.marketplace.products
        .find(({ id }) => id === this.subCategoryId)
        .inventory.filter(({ isListed }) => isListed) || []
    }
  }
}
</script>

<style lang="scss" scoped>
  .products {
    display: grid;
    row-gap: 1em;
  }
</style>
