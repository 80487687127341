'use strict'

import BucketListItems from '@/views/Wishes/BucketListItems'
import FormView from '@/views/FormView'
import LoadingSpinner from '@/components/LoadingSpinner'
import Requests from '@/views/Wishes/Requests'
import Wishes from '@/views/Wishes/Wishes'

export default {
  path: '/wishes',
  name: 'Wishes',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Wishes
  },
  meta: { title: 'Wishes' },
  children: [
    {
      path: 'requests',
      name: 'Requests',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Requests
      },
      children: [
        {
          path: 'funeral-wishes',
          name: 'FuneralWishes',
          props: {
            default: () => ({
              form: 'FuneralWishesForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: { title: 'Funeral Wishes' }
        },

        {
          path: 'memorial',
          name: 'Memorial',
          props: {
            default: () => ({
              form: 'MemorialForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: { title: 'Memorial' }
        }
      ]
    },

    {
      path: 'bucket-list',
      name: 'BucketList',
      components: {
        appClosedLayout: LoadingSpinner,
        default: BucketListItems
      },
      children: [
        {
          path: 'new',
          name: 'NewBucketListItem',
          props: {
            default: () => ({
              form: 'BucketListItemForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: { title: 'New Bucket List Item' }
        },

        {
          path: ':id',
          name: 'BucketListItem',
          props: {
            default: route => ({
              form: 'BucketListItemForm',
              id: route.params.id
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: { title: 'Bucket List Item' }
        }
      ]
    }
  ]
}
