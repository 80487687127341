'use strict'

import convertArrayBufferToBase64Block from './convertArrayBufferToBase64Block'
import { key, encrypt, message, enums } from 'openpgp'

export default async ({ unencryptedString, publicKeyObjects }) => {
  const publicKeys = await Promise.all(publicKeyObjects.map(async ({ armoredPublicKey }) =>
    (await key.readArmored(armoredPublicKey)).keys[0]
  ))

  const data = await encrypt({
    message: message.fromText(unencryptedString), // input as a String/Unit8(fromBinary)
    publicKeys, // lists of public keys for encryption
    compression: enums.compression.zlib,
    armor: false
  })

  const encryptedMessage = convertArrayBufferToBase64Block(data.message.packets.write())

  return encryptedMessage
}
