<template>
  <div>
    <div
      v-if="title"
      class="title"
    >
      {{ title }}
    </div>

    <table>
      <thead>
        <tr>
          <th v-if="value">
            <e-input
              type="checkbox"
              :value="allChecked"
              @change="changeAll"
            />
          </th>

          <template v-if="!isSmallDisplay">
            <th
              v-for="(header, index) in headers"
              :key="index"
              :style="{ 'text-align': header.align }"
            >
              {{ header.text }}
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, rowIndex) in filteredRows"
          :id="row.id"
          :key="rowIndex"
          :class="{ highlight: row.highlight }"
          @click="row.rowClickedFunction()"
        >
          <td
            v-if="value"
            @click.stop
          >
            <e-input
              type="checkbox"
              :value="isChecked(row.id)"
              @change="changeChecked(row.id)"
            />
          </td>

          <td
            v-for="(header, headerIndex) in headers"
            :key="headerIndex"
            :style="{ 'text-align': header.align }"
          >
            <span
              class="td-data"
              :class="{ small: isSmallDisplay }"
            >
              <span
                v-if="isSmallDisplay"
                class="td-label"
              >
                {{ headers[headerIndex].text }}
              </span>

              <slot
                :name="header.value"
                :item="row"
              >
                <span
                  v-if="typeof row[header.value] !== 'object'"
                  data-test="td-value"
                >
                  {{ row[header.value] }}
                </span>
                <e-button
                  v-else
                  data-test="td-value"
                  class="icon-link ghost"
                  @click="gotoRoute(row[header.value].routeName)"
                >
                  {{ row[header.value].text }}
                </e-button>
              </slot>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ETable',

  props: {
    value: {
      type: Array,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    headers: {
      type: Array,
      default: () => []
    },

    rows: {
      type: Array,
      default: () => []
    },

    filter: {
      type: String,
      default: ''
    }
  },

  emits: ['input', 'end-of-table-reached'],

  data () {
    return {
      checkedRows: [],
      scrollToHashHasBeenRun: false
    }
  },

  computed: {
    isSmallDisplay () {
      return this.$store.state.isSmall
    },

    filteredRows () {
      if (!this.filter) return this.rows

      const searchable = this.headers
        .filter(({ searchable }) => searchable)
        .map(({ value }) => value)

      return this.rows
        .filter(row => Object
          .keys(row)
          .reduce((accumulator, current) => searchable.includes(current)
            ? `${accumulator}|~|${row[current].toLowerCase()}`
            : accumulator
          , (''))
          .includes(this.filter.toLowerCase())
        )
    },

    allChecked () {
      return this.filteredRows.length === this.checkedRows.length
    }
  },

  watch: {
    filteredRows: {
      async handler (newValue) {
        if (this.scrollToHashHasBeenRun) return
        if (!newValue?.length) return

        const hash = this.$router?.history?.current?.hash?.replace('#', '')
        if (!hash) return

        await this.$nextTick(() => {
          const element = document.getElementById(hash)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
            this.scrollToHashHasBeenRun = true
          }
        })
      },

      flush: 'post'
    }
  },

  mounted () {
    document
      .getElementById('column-two')
      ?.addEventListener('scroll', this.bottomOfViewReached)
  },

  beforeDestroy () {
    document
      .getElementById('column-two')
      ?.removeEventListener('scroll', this.bottomOfViewReached)
  },

  methods: {
    bottomOfViewReached ({ target: { scrollHeight, scrollTop, clientHeight } }) {
      if (!(scrollHeight - scrollTop - clientHeight)) {
        this.$emit('end-of-table-reached')
      }
    },

    changeChecked (rowId) {
      this.checkedRows.includes(rowId)
        ? this.checkedRows.splice(this.checkedRows.indexOf(rowId), 1)
        : this.checkedRows.push(rowId)

      this.$emit('input', this.checkedRows)
    },

    changeAll () {
      this.allChecked
        ? this.checkedRows = []
        : this.checkedRows = this.filteredRows.map(({ id }) => id)

      this.$emit('input', this.checkedRows)
    },

    isChecked (id) {
      return this.checkedRows.includes(id)
    },

    gotoRoute (routePath) {
      this.$router.push({ path: routePath })
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  color: $font-color-one;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 0.4rem 0;
}

/* stylelint-disable-next-line */
::v-deep .flip {
  border: 1px solid $white !important;
}

table {
  border-collapse: collapse;
  border-top: 0;
  margin-bottom: 0;
  width: 100%;

  thead {
    background-color: $table-head-background-color;
    color: $table-head-font-color;

    tr {
      padding: 0;

      th {
        padding: 0.6rem;
      }
    }
  }

  tbody {
    background-color: $table-row-background-color;
    color: table-body-font-color;
    display: flex;
    flex-direction: column;

    tr {
      border-bottom: 1px solid $border-color-one;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      padding: 0.6rem;

      &:hover {
        cursor: pointer;
      }

      &:nth-child(odd) {
        background-color: $table-row-background-alt-color;

        &:hover {
          background-color: lighten($table-row-background-alt-color, 20%);
        }
      }

      &:nth-child(even) {
        background-color: $table-row-background-color;

        &:hover {
          background-color: lighten($table-row-background-color, 20%);
        }
      }

    }
  }

  td {
    padding: 0.2rem 0;

    .actions {
      white-space: nowrap;

      button {
        margin-right: 0.2rem;
        padding: 0;
      }
    }

    .td-data {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: 1fr;

      &.small {
        text-align: left !important;
      }

      .td-label {
        font-weight: normal;
      }
    }
  }
}

@media screen and (min-width: $media-break-1) {
  .title {
    border: 1px $border-style $border-color-one;
    border-bottom: 0;
    padding: 0.4rem 0 0.4rem 0.4rem;
  }

  table {
    border: 1px solid $border-color-one;
    border-collapse: collapse;
    margin-bottom: 0.8rem;
    width: 100%;

    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }

    tr {
      display: table-row !important;

      &.highlight {
        font-weight: bold;
      }
    }

    td {
      padding: 0.6rem;

      .actions {
        text-align: center !important;
      }

      .td-data {
        grid-template-columns: 1fr;
      }

      button {
        font-size: 1rem;
      }
    }

    th {
      font-weight: 500;
      padding: 0.6rem;
    }
  }
}
</style>
