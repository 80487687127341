'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ type, locale, version = 0, jwt }) => axios({
  method: 'get',
  url: `${VUE_APP_GATEWAY_URL}/tms/${type}/${locale}/${version}`,
  headers: { Authorization: jwt },
  timeout: 8000
})
