'use strict'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt, orderId }) => {
  try {
    const response = await fetch(`${VUE_APP_ORDERS_URL}/order/${orderId}`, {
      method: 'get',
      headers: {
        Authorization: jwt
      },
      timeout: 8000
    })
    return await response.json()
  } catch (err) {
    throw new Error(err)
  }
}
