'use strict'

import { Model } from '@vuex-orm/core'

export default class LiabilityCoOwnerContact extends Model {
  static get entity () { return 'liabilityCoOwnerContacts' }
  static get primaryKey () { return ['liabilityId', 'contactId'] }

  static fields () {
    return {
      liabilityId: this.attr(null),
      contactId: this.attr(null)
    }
  }
}
