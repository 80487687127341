'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ letterId, jwt }) => axios({
  method: 'delete',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_COMMS_URL}/letter/${letterId}`,
  timeout: 8000
})
