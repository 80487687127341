<template>
  <fieldset data-test="value-fieldset">
    <legend>Value</legend>

    <e-input
      v-if="visibilityObject.valuationDate"
      v-model="mutableValuationDate"
      label="Valuation Date"
      type="date"
      data-test="valuation-date-input"
      @input="$emit('is-dirty')"
    />

    <e-select
      v-if="visibilityObject.recurringFrequency"
      v-model="mutableRecurringFrequency"
      :items="recurringFrequencyOptions"
      label="Recurring Frequency"
      :help-id="recurringFrequencyHelpId"
      data-test="recurring-frequency-select"
      @change="isDirty = true"
      @input="$emit('is-dirty')"
    />

    <div
      v-if="visibilityObject.value"
      class="connect-input"
    >
      <validation-provider
        v-if="visibilityObject.currency"
        v-slot="{ errors, valid }"
        name="Currency"
        :rules="{ required: true }"
        slim
      >
        <e-select
          v-model="mutableCurrency"
          :items="currencies"
          label="Currency"
          :error-messages="errors"
          :success="valid"
          :is-required="true"
          class="currency-select"
          data-test="currency-select"
          @input="$emit('is-dirty')"
        />
      </validation-provider>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Value"
        :rules="{ required: true, min_value: 0.01 }"
        slim
      >
        <e-input
          v-model="mutableValue"
          label="Value"
          type="number"
          step=".01"
          :error-messages="errors"
          :success="valid"
          :is-required="true"
          :help-id="valueHelpId"
          data-test="value-input"
          @input="$emit('is-dirty')"
          @focus="mutableValue = mutableValue || null"
        />
      </validation-provider>

      <validation-provider
        v-if="visibilityObject.ownershipPercentage"
        v-slot="{ errors, valid }"
        name="Ownership Percentage"
        :rules="{ required: true, min_value: 1, max_value: 100 }"
        slim
      >
        <e-input
          v-model="mutableOwnershipPercentage"
          label="Ownership %"
          type="number"
          :error-messages="errors"
          :success="valid"
          :is-required="true"
          :help-id="ownershipPercentageHelpId"
          data-test="ownership-percentage-input"
          @input="$emit('is-dirty')"
        />
      </validation-provider>

      <validation-provider
        v-if="visibilityObject.payout"
        v-slot="{ errors, valid }"
        name="Final Payout"
        :rules="{ required: true, min_value: 0.01 }"
        slim
      >
        <e-input
          v-model="mutablePayout"
          label="Final Payout"
          type="number"
          step=".01"
          :error-messages="errors"
          :success="valid"
          :is-required="true"
          data-test="payout-input"
          @input="$emit('is-dirty')"
          @focus="mutablePayout = mutablePayout || null"
        />
      </validation-provider>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'ValueFieldset',

  props: {
    visibilityObject: {
      type: Object,
      default: () => ({
        valuationDate: true,
        currency: false,
        recurringFrequency: true,
        value: true,
        payout: false,
        ownershipPercentage: true
      })
    },

    valuationDate: {
      type: String,
      default: ''
    },

    currency: {
      type: String,
      default: 'GBP'
    },

    recurringFrequency: {
      type: String,
      default: ''
    },

    value: {
      type: [Number, String],
      default: 0
    },

    ownershipPercentage: {
      type: [Number, String],
      default: 100
    },

    payout: {
      type: [Number, String],
      default: 0
    },

    valueHelpId: {
      type: Number,
      default: null
    },

    recurringFrequencyHelpId: {
      type: Number,
      default: null
    },

    ownershipPercentageHelpId: {
      type: Number,
      default: null
    }
  },

  emits: [
    'is-dirty',
    'value-updated'
  ],

  data () {
    return {
      recurringFrequencyOptions: [
        { value: 'none', text: 'None' },
        { value: 'daily', text: 'Daily' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'biweekly', text: 'Biweekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'quarterly', text: 'Quarterly' },
        { value: 'biannually', text: 'Biannually' },
        { value: 'annually', text: 'Annually' }
      ]
    }
  },

  computed: {
    mutableValuationDate: {
      get () {
        return this.valuationDate
      },

      set (value) {
        this.$emit('value-updated', { property: 'valuationDate', value })
      }
    },

    mutableCurrency: {
      get () {
        return this.currency
      },

      set (value) {
        this.$emit('value-updated', { property: 'currency', value })
      }
    },

    mutableRecurringFrequency: {
      get () {
        return this.recurringFrequency
      },

      set (value) {
        this.$emit('value-updated', { property: 'recurringFrequency', value })
      }
    },

    mutableValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('value-updated', { property: 'value', value })
      }
    },

    mutableOwnershipPercentage: {
      get () {
        return this.ownershipPercentage
      },

      set (value) {
        this.$emit('value-updated', { property: 'ownershipPercentage', value })
      }
    },

    mutablePayout: {
      get () {
        return this.payout
      },

      set (value) {
        this.$emit('value-updated', { property: 'payout', value })
      }
    },

    currencies () {
      return Object
        .entries(this.$store.state.currencies)
        .map(kvPair => ({
          value: kvPair[0],
          text: kvPair[1]
        }))
        .sort((a, b) => {
          const x = a.text.toLowerCase()
          const y = b.text.toLowerCase()
          return x < y ? -1 : x > y ? 1 : 0
        })
    }
  }
}
</script>

<style scoped lang="scss">
.currency-select {
  width: 30%;
}
</style>
