<template>
  <div>
    <div class="header">
      <h1>Your MeaVitae Account Subscription Has Lapsed</h1>

      <e-button
        class="icon-x-circle ghost"
        @click="logoutUser"
      />
    </div>

    <p>
      Your membership subscription has expired. To update your account payment details to renew your subscription, click here: <a href="https://meavitae.com/plans?renew">Renew Membership</a>
    </p>

    <my-profile-view />

    <div class="button-container">
      <e-button @click.stop="logoutUser">
        LOGOUT
      </e-button>

      <delete-user-account-button />
    </div>

    <my-data />
  </div>
</template>

<script>
import DeleteUserAccountButton from '@/components/buttons/DeleteUserAccountButton'
import MyData from '@/views/UserAccount/MyData'
import MyProfileView from '@/views/UserAccount/MyProfileView'

export default {
  name: 'UnsubscribedUser',

  components: {
    DeleteUserAccountButton,
    MyData,
    MyProfileView
  },

  methods: {
    logoutUser () {
      this.$store.dispatch('logoutUser')
    }
  }
}
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
}

p {
  color: $font-color-one;
  display: block;
}

.user-profile {
  h1 {
    margin: 0.5 rem 0;
    padding-bottom: 0.5rem;
  }
}
</style>
