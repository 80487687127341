'use strict'

import axios from 'axios'

const { VUE_APP_GATEWAY_URL } = process.env

export default async ({ email, password, useAdminLogin }) => {
  try {
    const { data: { jwt } } = await axios({
      method: 'post',
      url: `${VUE_APP_GATEWAY_URL}/idp${useAdminLogin ? '/admin/login' : '/login'}`,
      data: { email, password },
      timeout: 8000
    })

    return jwt
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Our server failed to log you in, please try again')
  }
}
