<template>
  <div>
    <validation-observer v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Key Name"
          :rules="{ required: true }"
          tag="div"
        >
          <e-input
            v-model="formSecurityKeyName"
            label="Key Name"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
          />
        </validation-provider>

        <e-textarea
          v-model="formSecurityKeyNotes"
          label="Notes About Key"
        />

        <e-button
          v-if="!invalid"
          type="submit"
        >
          {{ credentialId ? 'Save' : 'Next' }}
        </e-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: 'SecurityKeyForm',

  props: {
    credentialId: {
      type: String,
      default: ''
    },

    securityKeyId: {
      type: String,
      default: ''
    },

    securityKeyName: {
      type: String,
      default: ''
    },

    securityKeyNotes: {
      type: String,
      default: ''
    }
  },

  emits: [
    'security-key-name',
    'security-key-notes',
    'save-security-key'
  ],

  data () {
    return {
      formSecurityKeyName: '',
      formSecurityKeyNotes: ''
    }
  },

  computed: {
    isExistingSecurityKey () {
      return this.securityKeyId && this.credentialId
    }
  },

  created () {
    if (this.isExistingSecurityKey) {
      this.formSecurityKeyName = this.securityKeyName
      this.formSecurityKeyNotes = this.securityKeyNotes
    }
  },

  methods: {
    submit () {
      this.$emit('security-key-name', this.formSecurityKeyName)
      this.$emit('security-key-notes', this.formSecurityKeyNotes)

      if (this.isExistingSecurityKey) {
        this.$emit('save-security-key', {
          securityKeyId: this.securityKeyId,
          credentialId: this.credentialId
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
button {
  background: rgb(134, 134, 195);
  border-radius: 4px;
  border-width: 0;
  color: $font-color-two;
  letter-spacing: 0.05em;
  margin-top: 20px;
  padding: 12px 20px;
  text-transform: uppercase;
  width: 100%;
}
</style>
