'use strict'

import axios from 'axios'
import createEndpoint from './utils/createEndpoint'
import convertObjectToBase64Block from '../../utils/convertObjectToBase64Block'

const { VUE_APP_API_URL } = process.env

export default async ({ userId, object, jwt, entityType }) => {
  const { base64Block } = convertObjectToBase64Block(object)

  return axios({
    method: 'put',
    headers: { Authorization: jwt },
    url: `${VUE_APP_API_URL}/${createEndpoint({ userId, entityType })}`,
    data: base64Block,
    timeout: 8000
  })
}
