'use strict'

import axios from 'axios'

const { VUE_APP_CONTENT_URL } = process.env

export default async ({ jwt, categoryId }) => axios({
  method: 'get',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_CONTENT_URL}/kb/help/${categoryId}`,
  timeout: 8000
})
