<template>
  <e-dialog
    class="dialog-container"
    data-test="buy-tokens"
  >
    <div class="header">
      <h1>Buy Tokens</h1>

      <e-button class="icon-x-circle ghost" @click="close" />
    </div>
    <div class="purchase-table">
      <div class="purchase-row">
        <div class="quantity">
          <e-input
            v-model="buyTokensNumber"
            data-test="buyTokensNumber"
            @input="computeBuyTokenPriceAndTotal"
          />
        </div>

        <div class="description">
          MeaVitae Tokens ({{ pricemeta.currencySymbol }}{{ pricemeta.price/100 }} each)
        </div>

        <div v-bind="buyTokenGrossPrice" class="price">
          {{ pricemeta.currencySymbol }} {{ buyTokenGrossPrice }}
        </div>
      </div>

      <div class="purchase-row">
        <div class="vatandtotal">
          VAT
        </div>

        <div class="price">
          {{ pricemeta.currencySymbol }} {{ buyTokenVAT }}
        </div>
      </div>

      <div class="total-row">
        <div class="quantity">
            &nbsp;
        </div>

        <div class="vatandtotal">
          Total
        </div>

        <div class="price">
          {{ pricemeta.currency }} {{ pricemeta.currencySymbol }} {{ buyTokenTotalPrice }}
        </div>
      </div>
    </div>

    <e-button
      :disabled="buyButtonIsDisabled"
      data-test="doTokenPurchase"
      class="buybutton"
      @click="doTokenPurchase"
    >
      BUY
    </e-button>
  </e-dialog>
</template>

<script>
import RevolutCheckout from '@revolut/checkout'
import createOrder from '@/apis/wallet/createOrder'
import EDialog from '@/components/dialogs/EDialog'

export default {
  name: 'BuyTokensDialogue',

  components: {
    EDialog
  },

  props: {

    pricemeta: {
      type: Object,
      default: () => ({ })
    },

    requiredtokens: {
      type: Number,
      default: 0
    }
  },

  emits: ['close-dialogue'],

  data () {
    return {
      buyTokensNumber: 0,
      buyTokenGrossPrice: 0,
      buyTokenVAT: 0,
      buyTokenTotalPrice: 0,
      buyButtonIsDisabled: true
    }
  },

  created () {
    if (this.requiredtokens) this.buyTokensNumber = this.requiredtokens
    this.computeBuyTokenPriceAndTotal()
  },

  methods: {
    async doTokenPurchase () {
      const order = {
        amount: parseInt(this.buyTokensNumber),
        currency: 'GBP',
        charge: this.buyTokenTotalPrice,
        vat: this.buyTokenVAT,
        item: { title: 'MeaVitae Tokens', description: 'Tokens for use in the MeaVitae Eco-system.' }
      }

      const orderMetadata = await createOrder({ jwt: sessionStorage.getItem('jwt'), order })
      if (orderMetadata) this.showPurchaseTokenDialogue = false

      const that = this
      RevolutCheckout(orderMetadata.public_id, process.env.VUE_APP_ENVIRONMENT === 'production' ? 'prod' : 'sandbox').then((instance) => {
        instance.payWithPopup({
          onSuccess () {
            that.$store.commit('snackbar/update', {
              type: 'success',
              message: 'Token Purchase Approved. Wallet update pending..'
            })
            that.$emit('close-dialogue')
            instance.destroy()
          },
          onError (message) {
            that.$store.commit('snackbar/update', {
              type: 'error',
              message: message
            })
            instance.destroy()
          }
        })
      })
    },

    close () { this.$emit('close-dialogue') },

    computeBuyTokenPriceAndTotal () {
      this.buyTokenGrossPrice = this.buyTokensNumber * (this.pricemeta.price / 100)
      this.buyTokenVAT = this.buyTokenGrossPrice * this.pricemeta.vat
      this.buyTokenTotalPrice = this.buyTokenGrossPrice + this.buyTokenVAT
      if (this.buyTokenTotalPrice > 0) this.buyButtonIsDisabled = false
      else this.buyButtonIsDisabled = true
    }
  }

}
</script>

<style>
.purchase-table {
  min-width: 40rem;
}

.purchase-row {
  line-height: 3.4rem;
}

.description {
  float: left;
  line-height: 3.4rem;
  padding-left: 1rem;
  width: 26rem;
}

.price {
  float: right;
  line-height: 3.2rem;
  padding-right: 2rem;
}

.quantity {
  float: left;
  width: 10%;
}

.vatandtotal {
  float: left;
  line-height: 3.4rem;
  padding-left: 1rem;
  text-align: right;
  width: 30rem;
}

.buybutton {
  margin-left: 20%;
  width: 60%;
}
</style>
