'use strict'

import Beneficiary from './Beneficiary'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class MonetaryDebt extends Model {
  static get entity () { return 'monetaryDebts' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      willId: this.attr(null),
      debtorId: this.attr(null),
      debtor: this.belongsTo(Beneficiary, 'debtorId'),
      currency: this.string(),
      amount: this.number(),
      isForgivable: this.boolean(false)
    }
  }
}
