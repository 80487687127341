'use strict'

import Will from '@/views/Will/Will'
import Children from '@/views/Will/Children'
import Contingency from '@/views/Will/Contingency'
import CreatingYourWill from '@/views/Will/CreatingYourWill'
import Distribution from '@/views/Will/Distribution'
import Executors from '@/views/Will/Executors'
import Guardians from '@/views/Will/Guardians'
import LoadingSpinner from '@/components/LoadingSpinner'
import Money from '@/views/Will/Money'
import MyFamily from '@/views/Will/MyFamily'
import Partner from '@/views/Will/Partner'
import Possessions from '@/views/Will/Possessions'
import Properties from '@/views/Will/Properties'
import Provisions from '@/views/Will/Provisions'
import Residual from '@/views/Will/Residual'
import WillIntroduction from '@/views/Will/WillIntroduction'
import WillSummary from '@/views/Will/WillSummary'
import Witnesses from '@/views/Will/Witnesses'

const subscriptionFeatureId = 6000

export default {
  path: '/will',
  name: 'Will',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Will
  },
  meta: {
    title: 'My Will',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'introduction',
      name: 'Introduction',
      meta: {
        title: 'Introduction',
        subscriptionFeatureId
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: WillIntroduction
      },
      children: [
        {
          path: 'creating-your-will',
          name: 'CreatingYourWill',
          components: {
            appClosedLayout: LoadingSpinner,
            default: CreatingYourWill
          },
          meta: {
            title: 'Creating Your Will',
            subscriptionFeatureId
          }
        }
      ]
    },

    {
      path: 'my-family',
      name: 'MyFamilyMain',
      components: {
        appClosedLayout: LoadingSpinner,
        default: { render (c) { return c('router-view') } }
      },
      meta: {
        title: 'My Family',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'my-family',
          name: 'MyFamily',
          components: {
            appClosedLayout: LoadingSpinner,
            default: MyFamily
          },
          meta: {
            title: 'My Family',
            subscriptionFeatureId
          }
        },

        {
          path: 'partner',
          name: 'Partner',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Partner
          },
          meta: {
            title: 'Partner',
            subscriptionFeatureId
          }
        },

        {
          path: 'children',
          name: 'Children',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Children
          },
          meta: {
            title: 'Children',
            subscriptionFeatureId
          }
        },

        {
          path: 'guardians',
          name: 'Guardians',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Guardians
          },
          meta: {
            title: 'Guardians',
            subscriptionFeatureId
          }
        }
      ]
    },

    {
      path: 'distribution',
      name: 'DistributionMain',
      components: {
        appClosedLayout: LoadingSpinner,
        default: { render (c) { return c('router-view') } }
      },
      meta: {
        title: 'Distribution',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'distribution',
          name: 'Distribution',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Distribution
          },
          meta: {
            title: 'Distribution',
            subscriptionFeatureId
          }
        },

        {
          path: 'possessions',
          name: 'Possessions',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Possessions
          },
          meta: {
            title: 'Possessions',
            subscriptionFeatureId
          }
        },

        {
          path: 'properties',
          name: 'Properties',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Properties
          },
          meta: {
            title: 'Properties',
            subscriptionFeatureId
          }
        },

        {
          path: 'money',
          name: 'Money',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Money
          },
          meta: {
            title: 'Money',
            subscriptionFeatureId
          }
        }
      ]
    },

    {
      path: 'residual',
      name: 'ResidualMain',
      components: {
        appClosedLayout: LoadingSpinner,
        default: { render (c) { return c('router-view') } }
      },
      meta: {
        title: 'Residual',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'residual',
          name: 'Residual',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Residual
          },
          meta: {
            title: 'Residual',
            subscriptionFeatureId
          }
        },

        {
          path: 'provisions',
          name: 'Provisions',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Provisions
          },
          meta: {
            title: 'Provisions',
            subscriptionFeatureId
          }
        },

        {
          path: 'contingency',
          name: 'Contingency',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Contingency
          },
          meta: {
            title: 'Contingency',
            subscriptionFeatureId
          }
        }
      ]
    },

    {
      path: 'executors-and-witnesses',
      name: 'ExecutorsAndWitnesses',
      components: {
        appClosedLayout: LoadingSpinner,
        default: { render (c) { return c('router-view') } }
      },
      meta: {
        title: 'Executors & Witnesses',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'executors',
          name: 'Executors',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Executors
          },
          meta: {
            title: 'Executors',
            subscriptionFeatureId
          }
        },

        {
          path: 'witnesses',
          name: 'Witnesses',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Witnesses
          },
          meta: {
            title: 'Witnesses',
            subscriptionFeatureId
          }
        }
      ]
    },

    {
      path: 'will-summary',
      name: 'WillSummary',
      components: {
        appClosedLayout: LoadingSpinner,
        default: WillSummary
      },
      meta: {
        title: 'Summary',
        subscriptionFeatureId
      }
    }
  ]
}
