<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Deed Of Revocation For A Power Of Attorney

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="non-disclosure-agreement-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Deed Of Revocation Made Date"
            rules="required"
            slim
          >
            <e-input
              v-model="deedOfRevocationMadeDate"
              label="Deed Of Revocation Made Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Employer"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="revokedById"
              :items="revokedBySelectOptions"
              label="revoked By"
              default-option-text="Add revoked By"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Power Of Attorney Date"
            rules="required"
            slim
          >
            <e-input
              v-model="powerOfAttorneyDate"
              label="Power Of Attorney Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Attorneys</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Attorney"
              :rules="{ array_length: true }"
              slim
            >
              <input
                v-model="attorneys.length"
                type="hidden"
              >

              <e-select
                :items="attorneySelectOptions"
                default-option-text="Add Attorney"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                @change="addAttorney"
              />
            </validation-provider>

            <table v-if="attorneys.length">
              <thead>
                <tr>
                  <th>Attorney</th>
                  <th>Signed As Deed By</th>
                  <th>In Presence Of</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="attorney in attorneys"
                  :key="attorney.id"
                >
                  <td>
                    {{ attorney.contact.fullName }}
                  </td>

                  <td>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Signed As Deed By"
                      :rules="{ required: true }"
                      slim
                    >
                      <e-input
                        v-model="attorney.signedAsDeedBy"
                        placeholder="Signed As Deed By"
                        :error-messages="errors"
                        :success="valid"
                        :is-required="true"
                        @input="setIsDirty(true)"
                      />
                    </validation-provider>
                  </td>

                  <td>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="In Presence Of"
                      :rules="{ required: true }"
                      slim
                    >
                      <e-input
                        v-model="attorney.inPresenceOf"
                        placeholder="In Presence Of"
                        :error-messages="errors"
                        :success="valid"
                        :is-required="true"
                        @input="setIsDirty(true)"
                      />
                    </validation-provider>
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      @click="removeAttorney(attorney.contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Deed Of Revocation From Date"
            rules="required"
            slim
          >
            <e-input
              v-model="deedOfRevocationFromDate"
              label="Deed Of Revocation From Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Witness"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="witnessId"
              :items="witnessSelectOptions"
              label="Witness"
              default-option-text="Add Witness"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="non-disclosure-agreement-form"
              button-text="Preview"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)
const templateType = 'deedOfRevocationForPowerOfAttorneyAstonVeer'

export default {
  name: 'DeedOfRevocationForPowerOfAttorney',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'ba8673da-4319-4340-bb8e-d4139b1095e4',
      isDirty: false,

      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      deedOfRevocationMadeDate: '',
      revokedById: '',
      powerOfAttorneyDate: '',
      attorneys: [],
      deedOfRevocationFromDate: '',
      witnessId: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    attorneyIds () {
      return this.attorneys.map(({ contact }) => contact.id)
    },

    revokedBySelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => ![
          ...this.attorneyIds,
          this.witnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    attorneySelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          ...this.attorneyIds,
          this.revokedById,
          this.witnessId
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    },

    witnessSelectOptions () {
      return this.userAndContacts
        .filter(({ id, type }) => type === 'person' && ![
          ...this.attorneyIds,
          this.revokedById
        ].includes(id))
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid,
          type
        }))
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addAttorney (contactId) {
      this.attorneys.push({
        contact: this.userAndContacts.find(({ id }) => id === contactId),
        signedAsDeedBy: '',
        inPresenceOf: ''
      })
      this.setIsDirty(true)
    },

    removeAttorney (contactId) {
      this.attorneys = this.attorneys
        .filter(Attorney => contactId !== Attorney.contact.id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            deedOfRevocationMadeDate: this.deedOfRevocationMadeDate,
            ...this.revokedById && {
              revokedBy: this.userAndContacts.find(({ id }) => id === this.revokedById)
            },
            powerOfAttorneyDate: this.powerOfAttorneyDate,
            attorneys: this.attorneys,
            deedOfRevocationFromDate: this.deedOfRevocationFromDate,
            ...this.witnessId && {
              witness: this.userAndContacts.find(({ id }) => id === this.witnessId)
            }
          },
          locale: 'en-GB',
          version: 0,
          type: templateType
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DeedOfRevocationForPowerOfAttorney',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: templateType,
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DeedOfRevocationForPowerOfAttorney',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-wide {
  flex: 100;
}

table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
