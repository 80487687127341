var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [(_vm.showView)?_c('form',{staticClass:"spaced",attrs:{"id":"message-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.headline)+" ")]),_c('div',{staticClass:"connect-input"},[_c('div',{staticClass:"recipients-element"},[_c('h2',[_vm._v(" Recipients "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(110)}}})]),_c('div',{staticClass:"box"},[_c('e-button',{staticClass:"add-recipient person-button ghost",attrs:{"data-test":"add-recipient-button"},on:{"click":_vm.doShowAddRecipientList}},[_c('span',{staticClass:"icon-plus"})]),_c('ul',{attrs:{"data-test":"current-recipients"}},_vm._l((_vm.recipients),function(recipient){return _c('li',{key:recipient.id},[_c('span',[_c('e-button',{staticClass:"delete-icon person-button ghost",attrs:{"data-test":"remove-recipient-button"},on:{"click":function($event){return _vm.removeRecipient(recipient.userId)}}},[_c('span',{attrs:{"data-test":"recipient-name"}},[_vm._v(" "+_vm._s(recipient.name)+" ")]),_c('span',{staticClass:"icon-trash-2"})])],1)])}),0)],1),_c('validation-provider',{attrs:{"name":"Eligible Contact","rules":{ array_length: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.recipients.length),expression:"recipients.length"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.recipients.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.recipients, "length", $event.target.value)}}}),(_vm.showAddRecipientList)?_c('e-select',{staticClass:"add-contact-select",attrs:{"items":_vm.eligibleRecipientsAsSelectOptions,"default-option-text":"Select a contact to add","error-messages":errors,"success":valid,"is-required":true,"disabled":_vm.recipients.length === 25,"data-test":"contact-select"},on:{"change":_vm.addRecipient}}):_vm._e()]}}],null,true)})],1)]),_c('validation-provider',{attrs:{"name":"Subject","rules":{ required: true, max: 100 },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"error-messages":errors,"label":"Subject","success":valid,"is-required":true,"help-id":111,"data-test":"subject-input"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}],null,true)}),_c('e-textarea',{attrs:{"label":"Body","rows":"12"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_c('add-file-button',{attrs:{"data-test":"file-input"},on:{"change":function (event) { _vm.addAttachment(event.target.files[0]) }}}),(_vm.attachments.length)?_c('div',[_c('h2',[_vm._v(" Attachments ")]),_c('div',{staticClass:"box"},[_c('ul',{attrs:{"data-test":"attachments"}},_vm._l((_vm.attachments),function(attachment){return _c('li',{key:attachment.id},[_vm._v(" "+_vm._s(attachment.name)+" "),_c('e-button',{staticClass:"delete-icon ghost",attrs:{"data-test":"remove-attachment-button"},on:{"click":function($event){return _vm.removeAttachment(attachment)}}},[_c('span',{staticClass:"icon-trash-2"})])],1)}),0)])]):_vm._e(),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[(_vm.showSendButton)?_c('e-button',{attrs:{"data-test":"save-as-draft-button"},on:{"click":_vm.saveAsDraft}},[_vm._v(" Save as draft ")]):_vm._e(),(_vm.showSendButton)?_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"message-form","button-text":"Send","data-test":"form-submit-button"}}):_vm._e()],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }