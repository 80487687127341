<template>
  <label :data-test="dataTest">
    <span class="question-text">
      {{ label }}

      <span
        v-if="helpId"
        class="icon-help-circle"
        @click.prevent="setHelp"
      />

      <span
        v-if="false && (isRequired || neededForIsValid)"
        class="icon-alert-triangle"
        :style="{ color: isRequired ? 'red' : 'orange' }"
      />
    </span>

    <p
      v-if="description"
      v-html="purify(description)"
    />

    <textarea
      id="textarea"
      v-model="mutableValue"
      class="textarea"
      :class="{ invalid: (isRequired || neededForIsValid) && !success }"
      v-bind="$attrs"
      :rows="rows"
      @input="emitInput"
      @change="emitChange"
    />

    <span
      v-if="errorMessages.length"
      class="error-text"
      data-test="error-message"
    >
      {{ errorMessages[0] }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'ETextarea',

  inheritAttrs: false,

  props: {
    dataTest: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    rows: {
      type: String,
      default: '10'
    },

    label: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    success: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    neededForIsValid: {
      type: Boolean,
      default: false
    },

    helpId: {
      type: [String, Number],
      default: null
    },

    helpFunction: {
      type: Function,
      default: ({ store, id }) => store.dispatch('findAndSetHelpObject', { id })
    }
  },

  emits: [
    'input',
    'change'
  ],

  data () {
    return {
      mutableValue: this.value
    }
  },

  methods: {
    emitInput () {
      this.$emit('input', this.mutableValue)
    },

    emitChange () {
      this.$emit('change', this.mutableValue)
    },

    setHelp () {
      this.helpFunction({ id: this.helpId, store: this.$store })
    }
  }
}
</script>

<style scoped lang="scss">
label {
  display: block;
}

textarea {
  appearance: none;
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $input-font-color;
  min-width: 240px;
  padding: 4px 8px;
  width: 100%;

  &.invalid {
    border-color: $color-error;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    &.invalid {
      border-color: $color-error;
    }
  }

  &:hover {
    border-color: $input-border-color-hover;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: $input-background-color-disabled;

    &:hover {
      border-color: $input-border-color;
    }
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}

p {
  font-size: 1rem;
  margin: 0 0 1em;
}
</style>
