'use strict'

import CryptoJS from 'crypto-js'
import md5 from 'md5'
import { createId } from './'

const errorCallback = reject => error => reject(error)

const createEncryptedFile = ({ resolve, file }) => event => {
  const encryptKey = md5(createId())

  const fileWordArray = CryptoJS
    .lib
    .WordArray
    .create(event.currentTarget.result)

  const encryptedFileAsString = CryptoJS
    .AES
    .encrypt(fileWordArray, encryptKey)
    .toString()

  const fileChecksum = CryptoJS
    .MD5(encryptedFileAsString)
    .toString()

  const encryptedFile = new File(
    [encryptedFileAsString],
    file.id,
    { type: 'text/plain' }
  )

  resolve({ encryptKey, encryptedFile, fileChecksum })
}

export default file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = createEncryptedFile({ resolve, file })
  reader.onerror = errorCallback(reject)
  reader.readAsArrayBuffer(file)
})
