'use strict'

import axios from 'axios'

export default async url => {
  const response = await axios({
    method: 'get',
    url,
    headers: { 'Content-Type': 'text/plain' },
    timeout: 8000
  })

  return response.data
}
