<template>
  <fieldset>
    <legend class="title">
      {{ fieldset.legend }}

      <span
        v-if="fieldset.helpId"
        class="icon-help-circle"
        @click="getHelpObject(fieldset.helpId).html"
      />
    </legend>

    <component
      :is="getInputType(question.type)"
      v-for="question in fieldset.questions"
      :key="question.title"
      :question="question"
      :value="dynamicProperties[question.propertyName]"
      :validation-rules="question.validationRules"
      :help-id="question.helpId"
      :help-function="() => $store.commit('setHelpObject', getHelpObject(question.helpId))"
      @update="value => $emit('update', value)"
    />
  </fieldset>
</template>

<script>
import DynamicInput from './DynamicInput'
import DynamicRadioGroup from './DynamicRadioGroup'
import DynamicTextarea from './DynamicTextarea'
import DynamicSelect from './DynamicSelect'

export default {
  name: 'DynamicFieldset',

  components: {
    DynamicInput,
    DynamicRadioGroup,
    DynamicTextarea,
    DynamicSelect
  },

  props: {
    fieldset: {
      type: Object,
      default: () => ({})
    },

    dynamicProperties: {
      type: Object,
      default: () => ({})
    },

    getInputType: {
      type: Function,
      default: () => {}
    },

    help: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update'],

  methods: {
    getHelpObject (helpId) {
      return this.help.find(({ id }) => id === helpId)
    }
  }
}
</script>

<style scoped lang="scss">
fieldset label {
  margin-bottom: 0.2rem;
}
</style>
