'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async jwt => {
  const response = await axios({
    method: 'get',
    headers: {
      Authorization: jwt
    },
    url: `${VUE_APP_COMMS_URL}/all`,
    timeout: 8000
  })

  return response.data
}
