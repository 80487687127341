<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :product-id="productId"
    />

    <span v-else>
      <h1>
        Travel Consent For Children

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(99)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="travel-consent-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Travel Start Date"
            rules="required"
            slim
          >
            <e-input
              v-model="tripStartDate"
              label="Trip Start Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="trip-start-date-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Travel End Date"
            rules="required"
            slim
          >
            <e-input
              v-model="tripEndDate"
              label="Trip End Date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="trip-end-date-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Destination Country"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="destinationCountryCode"
              :items="destinationCountrySelectOptions"
              label="Destination Country"
              default-option-text="Select Country"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="destination-country-code-select"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Accomodation Address"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="accomodationAddress"
              label="Accomodation Address"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="accomodation-address-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset data-test="parents-fieldset">
            <legend>Parents</legend>

            <validation-provider
              v-if="!maxParentsReached"
              v-slot="{ errors, valid }"
              name="Parent"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="parentContacts.length"
                type="hidden"
              >

              <e-select
                :items="parentSelectOptions"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="parent-select"
                @change="addParentContact"
              />
            </validation-provider>

            <table v-if="parentContacts.length">
              <thead>
                <tr>
                  <th>Parent</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in parentContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeParentContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset data-test="children-fieldset">
            <legend>Children</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Child"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="childContacts.length"
                type="hidden"
              >

              <e-select
                :items="childSelectOptions"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="children-select"
                @change="addChildContact"
              />
            </validation-provider>

            <fieldset
              v-for="contact in childContacts"
              :key="contact.id"
              data-test="child-fieldset"
            >
              <legend>
                {{ contact.fullName }}

                <e-button
                  class="icon-trash-2 ghost"
                  data-test="remove-contact-button"
                  @click="removeChildContact(contact.id)"
                />
              </legend>

              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${contact.id}-passport-nationality`"
                name="Passport Nationality"
                :rules="{ required: isInternational }"
                slim
              >
                <e-input
                  v-model="contact.passportNationality"
                  label="Passport Nationality"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="isInternational"
                  data-test="passport-nationality-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${contact.id}-passport-number`"
                name="Passport Number"
                :rules="{ required: isInternational }"
                slim
              >
                <e-input
                  v-model="contact.passportNumber"
                  label="Passport Number"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="isInternational"
                  data-test="passport-number-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${contact.id}-passport-place-of-issue`"
                name="Passport Place of Issue"
                :rules="{ required: isInternational }"
                slim
              >
                <e-input
                  v-model="contact.passportPlaceOfIssue"
                  label="Place of Issue"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="isInternational"
                  data-test="passport-place-of-issue-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                :vid="`${contact.id}-passport-date-of-issue`"
                name="Passport Date of Issue"
                :rules="{ required: isInternational }"
                slim
              >
                <e-input
                  v-model="contact.passportDateOfIssue"
                  label="Date of Issue"
                  type="date"
                  :error-messages="errors"
                  :success="valid"
                  :is-required="isInternational"
                  data-test="passport-date-of-issue-input"
                  @input="setIsDirty(true)"
                />
              </validation-provider>
            </fieldset>
          </fieldset>

          <e-select
            v-model="deputisedContactId"
            :items="deputisedSelectOptions"
            label="Deputised Contact"
            default-option-text="Add Contact"
            :help-id="100"
            data-test="deputised-select"
            @input="setIsDirty(true)"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="travel-consent-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import countries from '@/json_files/countries.json'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)

export default {
  name: 'TravelConsent',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: 'eab802e2-e37d-4c55-90b5-5ee4a81ca63e',
      isDirty: false,
      documentLabel: '',
      documentPreview: '',
      homeCountryCode: 'GB',

      locale: '',
      version: '',
      template: '',

      parentContacts: [],
      childContacts: [],
      deputisedContactId: '',
      tripStartDate: '',
      tripEndDate: '',
      destinationCountryCode: '',
      accomodationAddress: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person', 'organisation'])
    },

    destinationCountrySelectOptions () {
      return countries.map(({ name, code }) => ({
        value: code,
        text: name
      }))
    },

    deputisedSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid, type }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    childSelectOptions () {
      return this.userAndContacts
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => id !== this.deputisedContactId)
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    parentSelectOptions () {
      return this.userAndContacts
        .filter(({ type }) => type === 'person')
        .filter(({ id }) => id !== this.deputisedContactId)
        .filter(({ id }) => !this.parentContacts
          .map(parent => parent.id)
          .includes(id)
        )
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    isInternational () {
      return this.destinationCountryCode
        ? this.destinationCountryCode !== this.homeCountryCode
        : false
    },

    maxParentsReached () {
      return this.parentContacts.length >= 2
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 99,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addParentContact (contactId) {
      this.setIsDirty(true)

      this.parentContacts
        .push(this.userAndContacts.find(({ id }) => id === contactId))
    },

    removeParentContact (contactId) {
      this.parentContacts = this.parentContacts
        .filter(({ id }) => contactId !== id)
    },

    addChildContact (contactId) {
      this.setIsDirty(true)

      this.childContacts.push({
        ...this.userAndContacts.find(({ id }) => id === contactId),
        passportNationality: '',
        passportNumber: '',
        passportPlaceOfIssue: '',
        passportDateOfIssue: ''
      })
    },

    removeChildContact (contactId) {
      this.childContacts = this.childContacts
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            parents: this.parentContacts,
            children: this.childContacts,
            deputisedContact: this.userAndContacts.find(({ id }) => id === this.deputisedContactId),
            isInternational: this.isInternational,
            tripStartDate: this.tripStartDate,
            tripEndDate: this.tripEndDate,
            destinationCountry: countries.find(({ name, code }) => code === this.destinationCountryCode).name,
            accomodationAddress: this.accomodationAddress
          },
          locale: 'en-GB',
          version: 0,
          type: 'travelConsentChildren'
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'TravelConsent',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: 'travelConsentChildren',
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
          DocumentRecord.insert({ data: documentRecord })
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'TravelConsent',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
