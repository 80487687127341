import { render, staticRenderFns } from "./EDialog.vue?vue&type=template&id=81bed37a&scoped=true&"
import script from "./EDialog.vue?vue&type=script&lang=js&"
export * from "./EDialog.vue?vue&type=script&lang=js&"
import style0 from "./EDialog.vue?vue&type=style&index=0&id=81bed37a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81bed37a",
  null
  
)

export default component.exports