'use strict'

import axios from 'axios'

const { VUE_APP_COMMS_URL } = process.env

export default async ({ jwt }) => axios({
  method: 'get',
  headers: {
    Authorization: jwt
  },
  url: `${VUE_APP_COMMS_URL}/letter`,
  timeout: 8000
})
