'use strict'

import Account from '../models/Account'
import Asset from '../models/Asset'
import AssetBeneficiary from '../models/AssetBeneficiary'
import AssetCoOwnerContact from '../models/AssetCoOwnerContact'
import Beneficiary from '../models/Beneficiary'
import BucketListItem from '../models/BucketListItem'
import ChildContact from '../models/ChildContact'
import Contact from '../models/Contact'
import DocumentRecord from '../models/DocumentRecord'
import ExecutorContact from '../models/ExecutorContact'
import File from '../models/File'
import Group from '../models/Group'
import GuardianContact from '../models/GuardianContact'
import Liability from '../models/Liability'
import LiabilityAsset from '../models/LiabilityAsset'
import LiabilityCoOwnerContact from '../models/LiabilityCoOwnerContact'
import MonetaryDebt from '../models/MonetaryDebt'
import MonetaryGift from '../models/MonetaryGift'
import Note from '../models/Note'
import ResidualPersonalPossessionsBeneficiary from '../models/ResidualPersonalPossessionsBeneficiary'
import ResiduaryEstateBeneficiary from '../models/ResiduaryEstateBeneficiary'
import Credential from '../models/Credential'
import SubstituteExecutorContact from '../models/SubstituteExecutorContact'
import SubstituteGuardianContact from '../models/SubstituteGuardianContact'
import Todo from '../models/Todo'
import User from '../models/User'
import VuexORM from '@vuex-orm/core'
import Will from '../models/Will'
import WitnessContact from '../models/WitnessContact'

const database = new VuexORM.Database()
database.register(User)
database.register(Will)
database.register(Beneficiary)
database.register(Account)
database.register(Asset)
database.register(AssetCoOwnerContact)
database.register(AssetBeneficiary)
database.register(ChildContact)
database.register(Liability)
database.register(LiabilityCoOwnerContact)
database.register(LiabilityAsset)
database.register(MonetaryDebt)
database.register(MonetaryGift)
database.register(Contact)
database.register(ExecutorContact)
database.register(SubstituteExecutorContact)
database.register(WitnessContact)
database.register(GuardianContact)
database.register(SubstituteGuardianContact)
database.register(ResidualPersonalPossessionsBeneficiary)
database.register(ResiduaryEstateBeneficiary)
database.register(DocumentRecord)
database.register(Group)
database.register(Todo)
database.register(File)
database.register(Credential)
database.register(Note)
database.register(BucketListItem)

export default database
