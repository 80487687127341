<template>
  <button
    :type="type"
    v-bind="$attrs"
    :class="[buttonStyle, buttonType]"
    @click="emitClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'EButton',

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'button'
    },

    buttonStyle: {
      type: String,
      default: 'one'
    },

    buttonType: {
      type: String,
      default: 'single'
    }
  },

  emits: ['click'],

  methods: {
    emitClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  border: 1px solid $link-color;
  border-radius: $corner;
  cursor: pointer;
  font-size: $font-normal;
  padding: 0.4rem 1rem;
  text-transform: capitalize;

  &.primary {
    &.one {
      background-color: $button-color-one;
      border-color: $button-color-one;
      color: $main-background-color;

      &:hover {
        background-color: lighten($button-color-one, 10%);
        border-color: lighten($button-color-one, 10%);
      }
    }

    &.two {
      background-color: $button-color-two;
      border-color: $button-color-two;
      color: $white;

      &:hover {
        background-color: lighten($button-color-two, 10%);
        border-color: lighten($button-color-two, 10%);
        color: $main-background-color;
      }
    }

    &.three {
      background-color: $button-color-three;
      border-color: $button-color-three;
      color: $black;

      &:hover {
        background-color: lighten($button-color-three, 6%);
        border-color: lighten($button-color-three, 6%);
      }
    }
  }

  &.secondary {
    background-color: $main-background-color;
    border-color: $input-border-color;

    &:hover {
      background-color: lighten($main-background-color, 8%);
    }

    &.one {
      color: $button-color-one;

      &:hover {
        border-color: $button-color-one;
      }
    }

    &.two {
      color: $button-color-two;

      &:hover {
        border-color: $button-color-two;
      }
    }

    &.three {
      color: $button-color-three;

      &:hover {
        border-color: $button-color-three;
      }
    }
  }

  &.single {
    background-color: $main-background-color;
    color: $white;

    &:hover {
      background-color: lighten($main-background-color, 8%);
      color: $white;
    }

    &.one {
      border-color: $button-color-one;
    }

    &.two {
      border-color: $button-color-two;
    }

    &.three {
      border-color: $button-color-three;
    }

  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: transparent;
      color: $white !important;
    }
  }

  &.ghost {
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: transparent;
      color: $link-color;
    }
  }

  &.dialogue {
    margin: auto;
    width: 40%;
  }

  &.person-button {
    border: 1px white solid;
    border-radius: 4px;
    margin-right: 0.1rem;
    padding: 0.1rem 0.6rem;
  }

  &.add-recipient {
    float: right;
    margin-right: 0.5rem;
    margin-top: 0.2rem;

    &.more {
      position: relative;
      top: -38px;
    }
  }
}
</style>
