<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Residual

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(73)"
        />
      </h1>
    </div>

    <p>
      Based on your decisions, this is how your remaining estate will be distributed
    </p>

    <fieldset v-if="mainRemainingEstateBeneficiary">
      <legend>Provisions</legend>

      <p>
        Remaining estate passes to:

        <ul>
          <li>{{ mainRemainingEstateBeneficiary }}</li>
        </ul>
      </p>

      <p v-if="backupRemainingEstateBeneficiaries">
        and failing that to:

        <ul>
          <li
            v-for="beneficiary in backupRemainingEstateBeneficiaries"
            :key="beneficiary"
          >
            {{ beneficiary }}
          </li>
        </ul>
      </p>
    </fieldset>

    <fieldset v-if="rows.length">
      <legend>Contingency</legend>

      <p>
        If all other provisions on this will fail, then remaining estate passes to:
      </p>

      <e-table
        :headers="headers"
        :rows="rows"
      />
    </fieldset>
  </div>
</template>

<script>
import ETable from '@/components/ETable'
import Will from '@/models/Will'

export default {
  name: 'Residual',

  components: {
    ETable
  },

  data () {
    return {
      headers: [
        {
          text: 'Beneficiary',
          value: 'name',
          align: 'left'
        },
        {
          text: 'Percentage',
          value: 'percentageShare',
          align: 'center'
        }
      ]
    }
  },

  computed: {
    will () {
      return Will
        .query()
        .with('residuaryEstateBeneficiaries')
        .with('residuaryEstateBeneficiaries.contact')
        .with('residuaryEstateBeneficiaries.group')
        .first()
    },

    mainRemainingEstateBeneficiary () {
      const {
        remainingEstateToPartner,
        remainingEstateToChildren,
        remainingEstateToGrandchildren
      } = this.will

      if (remainingEstateToPartner) return 'Partner'
      if (remainingEstateToChildren && remainingEstateToGrandchildren) return 'Children & Grandchildren'
      if (remainingEstateToChildren) return 'Children'
      if (remainingEstateToGrandchildren) return 'Grandchildren'
      return false
    },

    backupRemainingEstateBeneficiaries () {
      if (this.mainRemainingEstateBeneficiary === 'Partner') {
        return [
          ...this.will.remainingEstateToChildren ? ['Children'] : [],
          ...this.will.remainingEstateToGrandchildren ? ['Grandchildren'] : []
        ]
      }

      if (this.mainRemainingEstateBeneficiary === 'Children') {
        return this.will.remainingEstateToGrandchildren ? ['Grandchildren'] : []
      }

      return []
    },

    rows () {
      return this.will
        .residuaryEstateBeneficiaries
        .map(({ name, percentageShare }) => ({
          name,
          percentageShare: `${percentageShare}%`,
          rowClickedFunction: () => {}
        }))
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 73,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    }
  }
}
</script>
