<template>
  <e-dialog class="refresh-jwt-dialog">
    <h1>Your session has expired</h1>

    <p v-if="!timerCancelled">
      You will be automatically logged out in {{ thirtySecondTimer }} seconds
    </p>

    <p>Any unsaved changes will be lost</p>

    <validation-observer v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(refreshJwt)">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Password"
          rules="required"
          slim
        >
          <e-input
            v-model="password"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
            label="Submit your password to keep this session alive"
            autocomplete="on"
            type="password"
            data-test="password-input"
            @input="timerCancelled = true"
          />
        </validation-provider>

        <e-button
          v-if="!invalid"
          type="submit"
        >
          <span>Refresh</span>
        </e-button>
      </form>
    </validation-observer>
  </e-dialog>
</template>

<script>
import EDialog from '@/components/dialogs/EDialog'
import attemptApiCall from '@/utils/attemptApiCall'
import getUserFromIdpLogin from '@/apis/idp/getUserFromIdpLogin'

export default {
  name: 'RefreshJwtBox',

  components: {
    EDialog
  },

  data () {
    return {
      password: '',
      thirtySecondTimer: 30,
      intervalID: 0,
      timerCancelled: false
    }
  },

  watch: {
    thirtySecondTimer (value) {
      if (value < 0 && !this.timerCancelled) {
        sessionStorage.removeItem('jwt')
        this.$store.dispatch('logoutUser')
        this.$store.commit('setShowRefreshJwtDialog', false)
      }
    }
  },

  created () {
    this.intervalID = setInterval(() => {
      if (this.thirtySecondTimer >= 0) this.thirtySecondTimer--
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.intervalID)
  },

  methods: {
    async refreshJwt () {
      try {
        const jwt = await attemptApiCall(getUserFromIdpLogin, {
          email: this.$store.getters.userEmailAddress,
          password: this.password,
          useAdminLogin: this.$store.getters.userIsAdmin
        })
        if (!jwt) throw new Error('No JWT returned from IDP on Refresh login')

        sessionStorage.setItem('jwt', jwt)
        this.$store.commit('setShowRefreshJwtDialog', false)
        this.$store.dispatch('retry')
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'RefreshJwtBox',
          functionName: 'refreshJwt'
        })

        sessionStorage.removeItem('jwt')
        this.$store.dispatch('logoutUser')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.refresh-jwt-dialog {
  p {
    display: block;
    margin: 0.5em 0;
    text-align: center;
  }

  button {
    background-color: $color-success;
    color: $font-color-two;
    letter-spacing: 0.05em;
    margin-top: 20px;
    padding: 12px 20px;
    text-transform: uppercase;
    width: 100%;
  }
}
</style>
