'use strict'

import { randomBytes } from 'crypto'

export default function createSecurityKeyId (existingSecurityKeyIds) {
  const newSecurityKeyId = randomBytes(2).toString('hex')

  return existingSecurityKeyIds.includes(newSecurityKeyId)
    ? createSecurityKeyId(existingSecurityKeyIds)
    : newSecurityKeyId
}
