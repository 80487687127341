<template>
  <article class="article" @click="articleClicked">
    <div class="column-one">
      <div class="image-container">
        <img :alt="article.title" :src="article.imageUrl || '../../default_thumbnail.png'">
      </div>

      <div class="type">
        {{ article.type }}
      </div>
    </div>

    <div class="column-two">
      <h1 class="title">
        {{ article.title }}
      </h1>

      <p>
        {{ article.abstract }}
      </p>

      <div class="category">
        <span>
          {{ article.category.title }}
        </span>

        <span v-if="article.likes > 0">
          - {{ article.likes }} {{ article.likes === 1 ? 'Like' : 'Likes' }}
        </span>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ArticleElement',

  props: {
    article: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  methods: {
    articleClicked () {
      this.$emit('article-clicked', this.article)
    }
  }
}
</script>

<style lang="scss" scoped>
.article {
  background-color: $widget-background-color;
  border: 1px solid $border-color-one;
  border-radius: $corner;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  &:hover {
    border-color: $link-color;
  }

  .column-one {
    display: none;
  }

  .column-two {
    display: grid;
    padding: 0.8rem;

    .title {
      color: $title-color-one;
      font-size: 1.2rem;
      padding-bottom: 0.6rem;
    }

    .category {
      font-style: italic;
      font-weight: 400;
    }
  }
}

@media screen and (min-width: $media-break-1) {
  .article {
    grid-template-columns: 13% auto;

    .column-one {
      display: grid;
      grid-template-rows: 3fr 1fr;

      .image-container img {
        height: 7rem;
        object-fit: cover;

        .image-container {
          margin: 2%;
        }
      }

      .type {
        align-self: center;
        justify-self: center;
      }
    }
  }
}
</style>
