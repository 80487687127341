<template>
  <three-column-layout>
    <template #one>
      <div class="padded">
        <h1 class="title">
          My Account
        </h1>

        <account-and-logout-buttons
          v-if="!userIsValid"
          :show-account-button="false"
        />

        <accordion :items="items" />
      </div>
    </template>

    <template #two>
      <div class="padded full-height">
        <router-view :key="$route.fullPath" />
      </div>
    </template>

    <template #three>
      <help-side-bar class="padded" />
    </template>
  </three-column-layout>
</template>

<script>
import Accordion from '@/components/Accordion'
import AccountAndLogoutButtons from '@/components/buttons/AccountAndLogoutButtons'
import HelpSideBar from '@/components/HelpSideBar'
import ThreeColumnLayout from '@/components/ThreeColumnLayout'

export default {
  name: 'UserAccount',

  components: {
    Accordion,
    AccountAndLogoutButtons,
    HelpSideBar,
    ThreeColumnLayout
  },

  computed: {
    userIsValid () {
      return this.$store.getters.userIsValid
    },

    userIsVendor () {
      return this.$store.getters.userIsVendor
    },

    items () {
      const userMenuItems = this.userIsValid
        ? [
            {
              id: 'Membership',
              text: 'My Membership',
              routeName: 'Membership',
              sub: []
            },

            {
              id: 'Purchases',
              text: 'Purchases',
              routeName: 'Purchases',
              sub: []
            },

            {
              id: 'SecurityKeys',
              text: 'Security Keys',
              routeName: 'SecurityKeys',
              sub: []
            },

            {
              id: 'MyData',
              text: 'My Data',
              routeName: 'MyData',
              sub: []
            },

            {
              id: 'Wallet',
              text: this.userIsVendor ? 'Vendor Wallet' : 'Wallet',
              routeName: 'Wallet',
              sub: []
            }
          ]
        : []

      const vendorMenuItems = this.userIsVendor
        ? [
            {
              id: 'Orders',
              text: 'Vendor Orders',
              routeName: 'Orders',
              sub: []
            }]
        : []

      return [
        {
          id: 'Account',
          text: 'Account',
          isActive: true,
          sub: [
            {
              id: 'Profile',
              text: 'Profile',
              routeName: 'Profile',
              sub: []
            },
            ...userMenuItems,
            ...vendorMenuItems
          ]
        }
      ]
    }
  }
}
</script>
