<template>
  <div>
    <product-element
      v-if="product"
      :product="product"
    />

    <div v-else>
      Document Locked
    </div>
  </div>
</template>

<script>
import ProductElement from '@/components/ProductElement'

export default {
  name: 'DocumentLocked',

  components: {
    ProductElement
  },

  props: {
    productId: {
      type: String,
      default: ''
    }
  },

  computed: {
    product () {
      return this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    }
  }
}
</script>
