<template>
  <div
    class="feature-panel"
    :style="{ 'background-image': featureBackgroundImage }"
    @click="navigateToLink"
  >
    <div>{{ panelText }}</div>
  </div>
</template>

<script>
export default {
  name: 'DashboardFeaturePanel',

  props: {
    linkPath: {
      type: String,
      default: ''
    },

    linkIsExternal: {
      type: Boolean,
      default: false
    },

    imageUrl: {
      type: String,
      default: ''
    },

    panelText: {
      type: String,
      default: ''
    }
  },

  computed: {
    featureBackgroundImage () {
      return this.imageUrl && `url('${this.imageUrl}')`
    }
  },

  methods: {
    navigateToLink () {
      this.linkIsExternal
        ? window.open(this.linkPath, '_blank')
        : this.$router.push({ path: this.linkPath })
    }
  }
}
</script>

<style scoped lang="scss">
.feature-panel {
  background-color: $widget-background-color;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $corner;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 250px;

  div {
    background-color: $image-strap-color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: bold;
    margin-bottom: 0;
    opacity: 0.8;
    padding: 1em;
  }
}
</style>
