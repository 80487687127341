<template>
  <span>
    <h1>Funeral Wishes</h1>

    <p>
      Many people find discussing their funeral wishes with loved ones difficult.
    </p>

    <p>
      However, it can be far more emotionally distressing for them if, after you die,
      they do not know what sort of ceremony you want. By recording information here
      (which is also copied to your will if you create one) your final farewell can
      be exactly as you want it to be.
    </p>

    <form
      id="funeral-wishes-form"
      @submit.prevent="submit"
    >
      <e-textarea
        v-model="description"
        class="description-textarea"
        @input="setIsDirty(true)"
      />
    </form>

    <save-bar :form-is-valid="true">
      <submit-button
        :form-is-valid="true"
        form="funeral-wishes-form"
      />
    </save-bar>
  </span>
</template>

<script>
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import Todo from '@/models/Todo'
import User from '@/models/User'

export default {
  name: 'FuneralWishes',

  components: {
    SaveBar,
    SubmitButton
  },

  emits: ['submitted'],

  data () {
    return {
      description: '',
      isDirty: false
    }
  },

  computed: {
    user () {
      return this.$store.getters.user || {}
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 79,
      isInitial: true
    })

    this.description = this.user.requests.find(({ id }) => id === 'funeralWishes').text
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    async submit () {
      this.setIsDirty(false)

      const currentRequests = [...this.user.requests]
      const index = currentRequests.findIndex(({ id }) => id === 'funeralWishes')

      currentRequests.splice(index, 1, {
        id: 'funeralWishes',
        name: 'Funeral Wishes',
        text: this.description
      })

      User.update({
        data: {
          id: this.user.id,
          requests: currentRequests
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['user'],
        message: 'Funeral wishes successfully saved'
      })

      Todo.update({
        where: ({ title }) => title === 'Funeral Wishes',
        data: {
          isDone: true
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['todos']
      })
    }
  }
}
</script>

<style scoped lang="scss">
/* stylelint-disable-next-line */
::v-deep textarea {
  height: 30vh;
}

h1 {
  padding-bottom: 1rem;
}
</style>
