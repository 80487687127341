'use strict'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt, purchase }) => {
  const response = await fetch(`${VUE_APP_ORDERS_URL}/refund`, {
    method: 'POST',
    headers: {
      Authorization: jwt,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(purchase),
    timeout: 8000
  })
  if (!response.ok) throw (response.statusText)
  return response
}
