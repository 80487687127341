<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this note"
      @confirm="deleteNote"
      @cancel="showDeleteDialog = false"
    />

    <h1>
      {{ isExistingNote ? 'Edit' : 'Create' }} Note

      <span
        class="icon-help-circle"
        @click.prevent="setHelp(124)"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="note-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Title"
            rules="required"
            slim
          >
            <e-input
              v-model="title"
              label="Title"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="130"
              data-test="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-textarea
            v-model="description"
            label="Description"
            data-test="description-textarea"
            @input="setIsDirty(true)"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="note-form"
            />

            <e-button
              v-if="isExistingNote"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete Item
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import Note from '@/models/Note'

export default {
  name: 'NoteForm',

  components: {
    ConfirmationDialog,
    SaveBar,
    SubmitButton
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      noteId: '',
      title: '',
      description: '',
      createdDate: '',

      showDeleteDialog: false,

      isDirty: false,
      isExistingNote: true
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 124,
      isInitial: true
    })

    if (this.id) {
      const note = Note.find(this.id)

      this.noteId = this.id
      this.title = note.title
      this.description = note.description
      this.createdDate = note.createdDate
    } else {
      this.noteId = new Note().id
      this.isExistingNote = false
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async deleteNote () {
      this.showDeleteDialog = false

      Note.delete(this.noteId)

      this.$emit('deleted', { name: 'NewNote' })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['notes'],
        message: 'Note successfully deleted'
      })
    },

    async submit () {
      Note.insertOrUpdate({
        data: {
          id: this.noteId,
          title: this.title,
          description: this.description,
          createdDate: this.createdDate || new Date().toISOString()
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['notes'],
        message: 'Note successfully saved'
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: 'Note',
        params: { id: this.noteId }
      })
    }
  }
}
</script>
