<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Your Account"
      @cancel="showDeleteDialog = false"
      @confirm="deleteUserAccount"
    >
      <p>
        Are you sure you wish to permanently delete your account?
      </p>

      <p>
        Warning: Clicking on this button will permanently delete
        your Meavitae Account, <strong>it cannot be undone</strong>.
      </p>
    </confirmation-dialog>

    <e-button
      data-test="delete-user-account-button"
      @click="showDeleteDialog = true"
    >
      DELETE ACCOUNT
    </e-button>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import getPublicKeyCredential from '@/auth/getPublicKeyCredential'
import jwtDecode from 'jwt-decode'

export default {
  name: 'DeleteUserAccountButton',

  components: {
    ConfirmationDialog
  },

  emits: [
    'submitted'
  ],

  data () {
    return {
      showDeleteDialog: false
    }
  },

  methods: {
    async deleteUserAccount () {
      const jwt = sessionStorage.getItem('jwt')

      try {
        this.showDeleteDialog = false

        const { securityKeyObjects, rpId, challenge } = await jwtDecode(jwt)

        const publicKeyCredential = await getPublicKeyCredential({
          credentialIds: securityKeyObjects.map(({ credentialId }) => credentialId),
          challenge: challenge,
          rpId
        })

        if (!publicKeyCredential) throw new Error('Failed to retrieve publicKeyCredential')

        this.$emit('submitted')

        await this.$store.dispatch('deleteUserAccount')
      } catch (error) {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || 'Failed to delete account',
          type: error.response?.data?.type || 'Delete Account Error',
          link: error.response?.data?.link
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'DeleteUserAccountButton',
          functionName: 'deleteUserAccount'
        })
      } finally {
        await this.$store.dispatch('logoutUser', { jwt })
      }
    }
  }
}
</script>
