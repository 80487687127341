'use strict'

import { Model } from '@vuex-orm/core'

export default class ResidualPersonalPossessionsBeneficiary extends Model {
  static get entity () { return 'residualPersonalPossessionsBeneficiaries' }
  static get primaryKey () { return ['willId', 'beneficiaryId'] }

  static fields () {
    return {
      willId: this.attr(null),
      beneficiaryId: this.attr(null)
    }
  }
}
