import InsightCategoryArticle from '@/views/Insights/InsightCategoryArticle'
import InsightCategoryArticles from '@/views/Insights/InsightCategoryArticles'
import Vue from 'vue'
import getInsights from '@/apis/insights/getInsights'
import updateLiked from '@/apis/insights/updateLiked'

const createDynamicRoutes = ({ categories, router }) => {
  categories.forEach(category => {
    router.addRoute('Insights', {
      path: 'categories/:subCategoryId',
      name: category.id,
      props: true,
      component: InsightCategoryArticles,
      meta: { title: 'Insights' }
    })

    category.articles.forEach(article => {
      router.addRoute('Insights', {
        path: 'categories/:subCategoryId/:articleId',
        name: `${category.id}-${article.id}`,
        props: true,
        component: InsightCategoryArticle,
        meta: { title: 'Insights' }
      })
    })
  })
}

export default {
  namespaced: true,

  state: {
    categories: []
  },

  getters: {
    getArticle: state => articleId => state.categories
      .flatMap(({ articles }) => articles)
      .find(({ id }) => id === articleId)
  },

  actions: {
    getInsights: async ({ commit }) => {
      try {
        const response = await getInsights({
          jwt: sessionStorage.getItem('jwt')
        })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setCategories: async ({ commit, dispatch }, router) => {
      const categories = await dispatch('getInsights')
      commit('setCategories', categories)
      createDynamicRoutes({ categories, router })
    },

    updateLiked: async ({ commit }, { categoryId, articleId, isLiked }) => {
      try {
        const response = await updateLiked({
          jwt: sessionStorage.getItem('jwt'),
          articleId,
          isLiked
        })

        commit('updateArticleIsLiked', { categoryId, articleId, isLiked })

        return response.data
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    }
  },

  mutations: {
    setCategories: (state, value) => Vue.set(state, 'categories', value),

    updateArticleIsLiked: (state, { categoryId, articleId, isLiked }) => {
      Vue.set(state, 'categories', state.categories.map(category => ({
        ...category,
        ...(category.id === categoryId && {
          articles: category.articles.map(article => article.id === articleId ? { ...article, isLiked } : article)
        })
      }))
      )
    }
  }
}
