<template>
  <label
    :data-test="dataTest"
    @click.stop.prevent="editor.commands.focus()"
  >
    <span class="question-text">
      {{ label }}

      <span
        v-if="helpId"
        class="icon-help-circle"
        @click.prevent="setHelp"
      />

      <span
        v-if="neededForIsValid && !success"
        class="icon icon-alert-triangle"
        :style="{ color: 'orange' }"
      />
    </span>

    <p
      v-if="description"
      v-html="purify(description)"
    />

    <div
      class="editor-container"
      :class="{ invalid: isRequired && !success }"
    >
      <menu-bar
        v-if="editor"
        :editor="editor"
      />

      <editor-content
        :editor="editor"
        class="editor-content"
      />
    </div>

    <span
      v-if="errorMessages.length"
      class="error-text"
      data-test="error-message"
    >
      {{ errorMessages[0] }}
    </span>
  </label>
</template>

<script>
import Link from '@tiptap/extension-link'
import MenuBar from './MenuBar'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { Editor, EditorContent } from '@tiptap/vue-2'

export default {
  name: 'RichTextEditor',

  components: {
    EditorContent,
    MenuBar
  },

  props: {
    dataTest: {
      type: String,
      default: ''
    },

    value: {
      type: [Array, String],
      default: null
    },

    type: {
      type: String,
      default: 'html'
    },

    label: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    neededForIsValid: {
      type: Boolean,
      default: false
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: true
    },

    helpId: {
      type: [String, Number],
      default: null
    }
  },

  emits: [
    'input',
    'change'
  ],

  data () {
    return {
      editor: null,

      editorOnUpdateFunctionLookup: {
        html: () => {
          this.emitInput(this.editor.getHTML())
          this.emitChange(this.editor.getHTML())
        },

        text: () => {
          this.emitInput(this.editor.getText())
          this.emitChange(this.editor.getText())
        },

        json: () => {
          this.emitInput(this.editor.getJSON())
          this.emitChange(this.editor.getJSON())
        }
      }
    }
  },

  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) return

      this.editor.commands.setContent(value, false)
    }
  },

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image']
        })
      ],

      onUpdate: this.editorOnUpdateFunctionLookup[this.type]
    })
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },

    emitChange (value) {
      this.$emit('change', value)
    },

    setHelp () {
      this.$store.dispatch('findAndSetHelpObject', { id: this.helpId })
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-container {
  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  border-radius: $corner;
  box-sizing: border-box;
  color: $input-font-color;
  margin-bottom: 15px;
  width: 100%;

  &.invalid {
    border-color: $color-error;
  }

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: none;
    outline: none;

    &.invalid {
      border-color: $color-error;
    }
  }
}

.editor-content {
  min-height: 200px;
  padding: 10px;
}
</style>
