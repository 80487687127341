<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this credential"
      @confirm="deleteCredential"
      @cancel="showDeleteDialog = false"
    />

    <h1>
      {{ isExistingCredential ? 'Edit' : 'Create' }} Credential

      <span
        class="icon-help-circle"
        @click.prevent="setHelp(122)"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="credential-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Credential Name"
            rules="required"
            slim
          >
            <e-input
              v-model="credentialName"
              label="Credential Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="140"
              data-test="credential-name-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Username"
            rules="required"
            slim
          >
            <e-input
              v-model="username"
              label="Username"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="username-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <div class="password-element">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Password"
              rules="required|confirmed:confirm"
            >
              <e-input
                v-model="password"
                label="Password"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="off"
                data-test="password-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <e-button
              class="show-password-button"
              :class="showPassword ? 'icon-eye-slash' : 'icon-eye'"
              @click="toggleShowPassword"
            />
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            vid="confirm"
            name="Confirm Password"
            rules="required"
            slim
          >
            <e-input
              v-model="passwordConfirm"
              label="Confirm Password"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="off"
              data-test="password-confirm-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Service Website"
            rules="required"
            slim
          >
            <e-input
              v-model="serviceWebsite"
              label="Service Website"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="123"
              type="text"
              data-test="service-website-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-textarea
            v-model="description"
            label="Description"
            data-test="description-textarea"
            @input="setIsDirty(true)"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="credential-form"
            />

            <e-button
              v-if="isExistingCredential"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import Credential from '@/models/Credential'

export default {
  name: 'CredentialForm',

  components: {
    ConfirmationDialog,
    SaveBar,
    SubmitButton
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      credentialId: '',
      credentialName: '',
      username: '',
      password: '',
      passwordConfirm: '',
      serviceWebsite: '',
      description: '',
      createdDate: '',

      showDeleteDialog: false,
      showPassword: false,

      isDirty: false,
      isExistingCredential: true
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 122,
      isInitial: true
    })

    if (this.id) {
      const credential = Credential.find(this.id)

      this.credentialId = this.id
      this.credentialName = credential.credentialName
      this.username = credential.username
      this.password = credential.password
      this.serviceWebsite = credential.serviceWebsite
      this.description = credential.description
      this.createdDate = credential.createdDate
    } else {
      this.credentialId = new Credential().id
      this.isExistingCredential = false
    }
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    async deleteCredential () {
      this.showDeleteDialog = false

      Credential.delete(this.credentialId)

      this.$emit('deleted', { name: 'NewCredential' })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['credentials'],
        message: 'Credential successfully deleted'
      })
    },

    async submit () {
      Credential.insertOrUpdate({
        data: {
          id: this.credentialId,
          credentialName: this.credentialName,
          username: this.username,
          password: this.password,
          serviceWebsite: this.serviceWebsite,
          description: this.description,
          createdDate: this.createdDate || new Date().toISOString()
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['credentials'],
        message: 'Credential successfully saved'
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: 'CredentialView',
        params: { id: this.credentialId }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.password-element {
  display: grid;
  grid-template-columns: 1fr 40px;

  .show-password-button {
    border-color: $input-border-color !important;
    border-width: 1px 1px 1px 0;
    color: $input-border-color;
    font-size: 1.2rem;
    height: 2.23rem;
    margin-top: 24px;
    padding: 0;

    &:hover {
      background-color: $input-background-color;
      color: $input-font-color;
    }
  }
}
</style>
