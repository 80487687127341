<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      :title="`Delete ${name}`"
      @cancel="showDeleteDialog = false"
      @confirm="deleteAccount"
    />

    <e-dialog v-if="showFileUploadDialog">
      <file-upload-form
        @closed="showFileUploadDialog = false"
        @submitted="addFile"
      />
    </e-dialog>

    <h1>
      {{ isExistingAccount ? 'Edit' : 'Create' }} {{ getTypeAsText(type) }}

      <span
        class="icon-help-circle"
        @click.prevent="resetDefaultHelp"
      />
    </h1>

    <transition
      appear
      enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    >
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="account-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Account Name"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="name"
              label="Account Name"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="56"
              class="title-input"
              data-test="name-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-if="type !== 'otherAccount'"
            v-slot="{ errors, valid }"
            name="Type"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="subType"
              :items="subTypes"
              label="Type"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="sub-type-select"
              @change="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Supplier"
            :rules="{ required: true }"
            slim
          >
            <e-select
              v-model="supplierId"
              label="Supplier"
              :items="suppliersAsSelectOptions"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="57"
              data-test="supplier-select"
              @change="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="referenceId"
            label="Reference Id"
            :help-id="58"
            data-test="reference-id-input"
            @input="setIsDirty(true)"
          />

          <e-textarea
            v-model="description"
            label="Description"
            data-test="description-textarea"
            @input="setIsDirty(true)"
          />

          <value-fieldset
            :visibility-object="visibleValuationElements"
            :valuation-date="valuationDate"
            :currency="currency"
            :value="value"
            :value-help-id="valueHelpId"
            @is-dirty="setIsDirty(true)"
            @value-updated="updatePropertyValue"
          />

          <e-textarea
            v-model="notes"
            label="Notes"
            data-test="notes-textarea"
            @input="setIsDirty(true)"
          />

          <div>
            <e-button
              data-test="add-file-button"
              @click="showFileUploadDialog = true"
            >
              <span class="icon-plus" />
              Add File
            </e-button>
          </div>

          <files-table
            v-if="associatedFiles.length"
            :files="associatedFiles"
            @delete="removeFile"
          />

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="account-form"
            />

            <e-button
              v-if="isExistingAccount"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import Account from '@/models/Account'
import Contact from '@/models/Contact'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import EDialog from '@/components/dialogs/EDialog'
import File from '@/models/File'
import FilesTable from '@/components/FilesTable'
import FileUploadForm from '@/components/forms/FileUploadForm'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import ValueFieldset from './formElements/ValueFieldset'
import accountTypes from '@/json_files/accountTypes.json'
import { camelCase, pascalCase } from 'change-case'

export default {
  name: 'AccountForm',

  components: {
    ConfirmationDialog,
    EDialog,
    FilesTable,
    FileUploadForm,
    SaveBar,
    SubmitButton,
    ValueFieldset
  },

  props: {
    id: {
      type: String,
      default: ''
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      accountId: '',
      name: '',
      type: '',
      subType: '',
      notes: '',
      description: '',
      supplierId: '',
      referenceId: '',
      createdDate: '',

      valuationDate: '',
      currency: 'GBP',
      value: 0,

      isDirty: false,
      isExistingAccount: true,

      showDeleteDialog: false,
      showFileUploadDialog: false,

      associatedFiles: []
    }
  },

  computed: {
    subTypes () {
      if (!this.type) return []

      return accountTypes
        .find(({ type }) => type === this.type)
        .subTypes
    },

    suppliersAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'organisation')
        .map(({ id, organisationName }) => ({ value: id, text: organisationName }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    },

    visibleValuationElements () {
      return {
        currency: true,
        valuationDate: true,
        recurringFrequency: false,
        value: true,
        ownershipPercentage: false
      }
    },

    valueHelpId () {
      return {
        investment: 59,
        monetaryAccount: 60,
        pension: 61
      }[this.type]
    }
  },

  created () {
    const helpLookup = {
      NewInvestment: 53,
      Investment: 53,
      NewMonetaryAccount: 54,
      MonetaryAccount: 54,
      NewPension: 55,
      Pension: 55,
      NewOtherAccount: 115
    }

    this.$store.dispatch('findAndSetHelpObject', {
      id: helpLookup[this.$router.history.current.name],
      isInitial: true
    })

    if (this.id) {
      const account = Account
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.accountId = account.id
      this.name = account.name
      this.type = account.type
      this.subType = account.subType
      this.notes = account.notes
      this.description = account.description
      this.supplierId = account.supplierId
      this.referenceId = account.referenceId
      this.createdDate = account.createdDate

      this.valuationDate = account.valuationDate
      this.currency = account.currency
      this.value = account.value

      this.setAssociatedFilesFromStore()
    } else {
      this.accountId = new Account().id
      this.type = camelCase(this.$route.path.split('/')[2])
      this.isExistingAccount = false
    }
  },

  methods: {
    setAssociatedFilesFromStore () {
      const account = Account
        .query()
        .with('files')
        .with('files.owner')
        .find(this.id)

      this.associatedFiles = account
        ? account
          .files
          .map(({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }) => ({
            id,
            fileName,
            reference,
            uploadDate,
            fileUrl,
            fileMimeType,
            encryptDecryptKey,
            etag,
            source,
            owner
          }))
        : []
    },

    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    resetDefaultHelp () {
      const helpLookup = {
        investment: 53,
        monetaryAccount: 54,
        pension: 55,
        otherAccount: 115
      }

      this.$store.dispatch('findAndSetHelpObject', {
        id: helpLookup[this.type]
      })
    },

    getTypeAsText (typeToGet) {
      return accountTypes
        .find(({ type }) => type === typeToGet)
        .text
    },

    updatePropertyValue ({ property, value }) {
      this[property] = value
    },

    async deleteAccount () {
      try {
        this.showDeleteDialog = false

        Account.delete(this.accountId)
        File.delete(({ ownerId }) => ownerId === this.accountId)

        this.$emit('deleted', { name: `New${pascalCase(this.type)}` })

        await this.$store.dispatch('persistRecordToVault', {
          entityTypes: ['accounts', 'files'],
          message: 'Account successfully deleted'
        })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to delete account'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'AccountForm',
          functionName: 'deleteAccount'
        })
      }
    },

    addFile ({ file, description, reference, referenceDate }) {
      this.showFileUploadDialog = false

      file.id = new File().id

      this.associatedFiles.push({
        id: file.id,
        fileName: file.name,
        reference,
        uploadDate: new Date().toISOString(),
        owner: {
          name: this.name,
          type: pascalCase(this.type),
          id: this.accountId
        }
      })

      this.$store.commit('files/addFileObjToFilesToUpload', {
        file,
        reference,
        referenceDate,
        description,
        ownerId: this.accountId,
        ownerType: 'accounts'
      })

      this.setIsDirty(true)
    },

    removeFile (fileId) {
      this.$store.commit('files/addFileIdToFilesToDelete', fileId)
      this.associatedFiles = this.associatedFiles.filter(({ id }) => id !== fileId)
      this.setIsDirty(true)
    },

    handleAccountLogic () {
      Account.insertOrUpdate({
        data: {
          id: this.accountId,
          name: this.name,
          type: this.type,
          subType: this.subType,
          notes: this.notes,
          description: this.description,
          supplierId: this.supplierId,
          referenceId: this.referenceId,
          createdDate: this.createdDate || new Date().toISOString(),

          valuationDate: this.visibleValuationElements.valuationDate ? this.valuationDate : '',
          currency: this.visibleValuationElements.currency ? this.currency : '',
          value: this.visibleValuationElements.value ? this.value : null
        }
      })
    },

    async submit () {
      this.handleAccountLogic()

      await this.$store.dispatch('files/handleAssociatedFilesLogic')
      this.setAssociatedFilesFromStore()

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['accounts', 'files'],
        message: 'Account successfully saved'
      })

      this.setIsDirty(false)

      this.$emit('submitted', {
        name: pascalCase(this.type),
        params: { id: this.accountId }
      })
    }
  }
}
</script>
