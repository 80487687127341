<template>
  <div :class="{ 'plan-container': true, ...isUserMembership && { 'highlight-plan': true } }">
    <h1>{{ title }}</h1>

    <corp-bar class="mv-icon" />

    <component :is="html" class="plan-body" />

    <div class="plan-price">
      <p>{{ priceText }}</p>
    </div>

    <div
      v-if="isUserMembership"
      class="disabled-membership-link"
    >
      My Membership
    </div>

    <a
      v-else
      :href="planPurchaseUrl"
    >
      Purchase This Membership
    </a>
  </div>
</template>

<script>
import CorpBar from './CorpBar'
import portableTextToHtml from '@/utils/portableTextToHtml'

const { VUE_APP_MEAVITAE_URL } = process.env

export default {
  name: 'MembershipPlan',

  components: {
    CorpBar
  },

  props: {
    subscriptionPlan: {
      type: Object,
      default: () => ({})
    },

    isUserMembership: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title () {
      return this.subscriptionPlan.title
    },

    price () {
      return Number.parseFloat(this.isUserMembership ? (this.subscriptionPlan.renewalPrice || 0) : (this.subscriptionPlan.price || 0)).toFixed(2)
    },

    priceExcludingTax () {
      return Number.parseFloat(this.price - (this.price * this.subscriptionPlan.jurisdiction.vat)).toFixed(2)
    },

    priceText () {
      return `${this.subscriptionPlan.jurisdiction.currencySymbol}${this.price} ${this.subscriptionPlan.jurisdiction.currency} (Exc VAT ${this.subscriptionPlan.jurisdiction.currencySymbol}${this.priceExcludingTax})`
    },

    html () {
      return {
        template: portableTextToHtml(this.subscriptionPlan.body)
      }
    },

    planPurchaseUrl () {
      return `${VUE_APP_MEAVITAE_URL}/request?plan=${this.subscriptionPlan.id}`
    }
  }
}
</script>

<style scoped lang="scss">
.highlight-plan {
  border-color: $link-color !important;
}

.plan-container {
  align-content: stretch;
  background-color: $widget-background-color;
  border: 1px solid $border-color-one;
  border-radius: $corner;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  h1 {
    color: $title-color-one;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .mv-icon {
    display: block;
    height: 12rem;
    margin: 0 auto 2em;
    width: 50%;
  }

  .plan-body {
    display: block;
    flex-grow: 1;
    font-size: 0.9em;
    font-weight: normal;
  }

  .plan-price {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1em;
    text-align: right;
  }

  a {
    background-color: $main-background-color;
    border: 1px solid $link-color;
    border-radius: $corner;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: $font-normal;
    margin-top: 1em;
    padding: 0.4rem 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }

  .disabled-membership-link {
    background-color: $main-background-color;
    border: 1px solid $link-color;
    border-radius: $corner;
    color: $white;
    cursor: not-allowed;
    display: block;
    font-size: $font-normal;
    margin-top: 1em;
    opacity: 0.5;
    padding: 0.4rem 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background-color: transparent;
      color: $white !important;
    }
  }
}
</style>
