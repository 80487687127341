'use strict'

import DOMPurify from 'dompurify'
import getDocumentDefinition from './getDocumentDefinition'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfMake from 'pdfmake/build/pdfmake'
import htmlToPdfMake from 'html-to-pdfmake'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const htmlToPdf = ({ html }) => {
  return pdfMake
    .createPdf({
      pageSize: 'A4',
      pageOrientation: 'portrait',
      permissions: {
        modifying: false,
        copying: false
      },
      content: [htmlToPdfMake(html)],
      styles: { 'html-center': { alginment: 'center' } }
    }
    ).open()
}

const htmlToPdfDocGenerator = ({ html, userPassword, isPreview = false }) => {
  return pdfMake
    .createPdf(
      getDocumentDefinition({
        html: DOMPurify.sanitize(html),
        userPassword,
        isPreview
      })
    )
}

const htmlToPdfAsBase64 = ({ html, userPassword }) => new Promise((resolve, reject) => {
  try {
    const pdfDocGenerator = htmlToPdfDocGenerator({ html, userPassword })

    pdfDocGenerator
      .getBase64(pdfAsBase64 => {
        if (!pdfAsBase64) throw new Error('PDF failed to convert to Base64')

        resolve(pdfAsBase64)
      })
  } catch (error) {
    reject(error)
  }
})

export {
  htmlToPdf,
  htmlToPdfAsBase64,
  htmlToPdfDocGenerator
}
