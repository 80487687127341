<template>
  <div>
    <snackbar />
    <error-box v-if="appError.status" />
    <refresh-jwt-box v-if="showRefreshJwtDialog" />
    <action-objects />
    <curtains :open="showAppOpen" />
    <app-open-layout v-if="showAppOpen" />
    <app-closed-layout v-else />
  </div>
</template>

<script>
import ActionObjects from '@/components/ActionObjects'
import AppClosedLayout from '@/components/AppClosedLayout'
import AppOpenLayout from '@/components/AppOpenLayout'
import Curtains from '@/components/Curtains'
import ErrorBox from '@/components/ErrorBox'
import RefreshJwtBox from '@/components/RefreshJwtBox'
import Snackbar from '@/components/Snackbar'
import jwtDecode from 'jwt-decode'
import { detect } from 'detect-browser'
import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    ActionObjects,
    AppClosedLayout,
    AppOpenLayout,
    Curtains,
    ErrorBox,
    RefreshJwtBox,
    Snackbar
  },

  data () {
    return {
      intervalID: 0
    }
  },

  computed: {
    ...mapState([
      'showAppOpen',
      'showFeedbackBar',
      'appError',
      'showRefreshJwtDialog'
    ])
  },

  beforeDestroy () {
    if (this.intervalID) clearInterval(this.intervalID)
  },

  async created () {
    const browser = detect()
    this.$store.commit('setBrowserDetails', browser)

    const mediaQuery = window.matchMedia('(min-width: 60rem)')
    this.$store.commit('setIsSmall', !mediaQuery.matches)

    if (this.$store.state.showSlidingHelp && mediaQuery.matches) {
      this.$store.commit('setShowSlidingHelp', false)
    }

    mediaQuery.addEventListener('change', this.toggleIsSmall)

    this.appHeartbeatRunEveryMinute()

    try {
      if (this.$store.getters.userIsLoggedIn) {
        this.$store.dispatch('openApp')
        return
      }

      const jwt = sessionStorage.getItem('jwt')

      if (!jwt) {
        await this.$store.dispatch('logoutUser', { jwt: null })
        return
      }

      const {
        userId,
        avatarUrl,
        firstName,
        middleNames,
        lastName,
        email,
        isPrivate,
        address1,
        address2,
        postcode,
        country,
        locale,
        subscribed,
        subscribedUntilDate,
        subscribedFeatures,
        subscriptionProductId,
        armoredPublicKey,
        encryptedArmoredPrivateKey,
        securityKeyObjects,
        challenge,
        permissions,
        exp
      } = await jwtDecode(jwt)

      const nowDateInSeconds = Math.floor(Date.now() / 1000)

      if (nowDateInSeconds > exp) {
        throw new Error('Failed to repopulate vault - session expired')
      }

      await this.$store.dispatch('repopulateState', {
        userId,
        armoredPublicKey,
        encryptedArmoredPrivateKey,
        subscribed,
        subscribedUntilDate,
        subscribedFeatures,
        subscriptionProductId,
        locale,
        securityKeyObjects,
        challenge,
        jwt
      })

      await this.$store.dispatch(
        'updateUser',
        { userId, avatarUrl, firstName, middleNames, lastName, email, isPrivate, address1, address2, postcode, country, locale, permissions, subscribed, subscribedUntilDate, subscribedFeatures, subscriptionProductId }
      )
      await this.$store.dispatch('setEssentialApiCallData')
      await this.$store.dispatch('setNonEssentialApiCallData', this.$router)

      if (!this.$store.getters.user.isValid && !['Profile'].includes(this.$route.name)) {
        this.$router.push({ name: 'Profile' })
      }

      subscribed
        ? this.$store.dispatch('openApp')
        : this.goToUnsubscribed()
    } catch (error) {
      if (error.response?.data) {
        this.$store.commit('setAppErrorState', {
          status: true,
          message: error.response?.data?.message || error.message,
          type: error.response?.data?.type,
          link: error.response?.data?.link
        })
      } else {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: error.message
        })
      }

      this.$store.dispatch('logError', {
        error,
        fileName: 'App',
        functionName: 'created'
      })

      const jwt = sessionStorage.getItem('jwt')
      await this.$store.dispatch('logoutUser', { jwt })
    }
  },

  methods: {
    async appHeartbeatRunEveryMinute () {
      const oneMinuteInMilliseconds = 60000

      this.intervalID = setInterval(async () => {
        this.$store.dispatch('userLoggedInPollApis')
      }, oneMinuteInMilliseconds)
    },

    toggleIsSmall ({ matches }) {
      this.$store.commit('setIsSmall', !matches)

      if (this.$store.state.showSlidingHelp && matches) {
        this.$store.commit('setShowSlidingHelp', false)
      }
    },

    goToUnsubscribed () {
      this.$router.push({ name: 'UnsubscribedUser' })
    }
  }
}
</script>
