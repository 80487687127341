<template>
  <span>
    <confirmation-dialog
      v-if="showDeleteDialog"
      title="Delete this letter"
      @confirm="deleteLetter"
      @cancel="showDeleteDialog = false"
    />
    <e-dialog
      v-if="showPurchaseDialog"
    >
      <ConfirmPurchase
        v-if="showPurchaseDialog"
        :item="product"
        @show-ai-working-dialogue="aiWorking"
        @closed="showPurchaseDialog = false"
      />
    </e-dialog>

    <e-dialog
      v-if="showAiWorkingDialog"
    >
      <h1>AI letter is being generated. Please wait.</h1>
      <div class="ai-working-dialog__content__text">
        <p> Our AI is working to generate a Letter, based on your instructions.</p>
        <p> This may take a minute or two. Please be patient and when it is ready, the agent will put the letter into the form below.</p><br>
        <p> Please note that our AI Agent is still learning, so the letter may not be perfect.</p>
        <p> If you are not happy with the letter, you can edit it before sending it.</p>
      </div>
      <div class="ai-working-dialog__content__spinner">
        <div class="spinner" />
      </div>
    </e-dialog>

    <e-dialog
      v-if="showSendLetterDiaglog"
      title="Send Letter"
    >
      <h1>Send the Letter {{ title }}</h1>

      <slot>
        <p>
          Sending a letter to an external email address
        </p>
      </slot>

      <div class="confirmation-buttons">
        <e-button
          type="primary"
          button-type="primary"
          button-style="two"
          @click="sendItNow"
        >
          Send
        </e-button>
        <e-button
          type="primary"
          button-type="secondary"
          button-style="two"
          @click="cancelSend"
        >
          Cancel
        </e-button>
        <e-input
          :value="scheduleLetter"
          type="checkbox"
          label="Send the letter at a later date"
          data-test="schedule-letter-checkbox"
          @change="value => {
            scheduleLetter = !!value
            setIsDirty(true)
          }"
        />
      </div>

      <fieldset
        v-if="scheduleLetter"
      >
        <legend>
          Schedule letter

          <span
            class="icon-help-circle"
            @click.prevent="setHelp(138)"
          />
        </legend>

        <validation-provider
          v-slot="{ errors, valid }"
          name="Send Date"
          :rules="{ required: true, is_1_min_in_future_datetime: true }"
          tag="div"
        >
          <e-input
            v-model="sendDate"
            label="Send Date"
            type="datetime-local"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
            :help-id="137"
            data-test="send-date-input"
            @input="setIsDirty(true)"
          />
        </validation-provider>

        <e-input
          :value="encryptPdf"
          type="checkbox"
          label="Add a password to ensure that only the recipient is able to read the emailed pdf letter"
          data-test="encrypt-pdf-checkbox"
          @change="value => {
            encryptPdf = !!value
            setIsDirty(true)
          }"
        />

        <fieldset
          v-if="encryptPdf"
        >
          <legend>Secure letter</legend>

          <div class="connect-input">
            <validation-provider
              v-slot="{ errors, valid }"
              vid="userPassword"
              name="Password"
              rules="required|min:8|max:20"
              tag="div"
            >
              <e-input
                v-model="userPassword"
                label="Password"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                type="password"
                data-test="password-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Confirm Password"
              rules="required|confirmed:userPassword"
              tag="div"
            >
              <e-input
                v-model="userPasswordConfirm"
                label="Confirm Password"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                type="password"
                data-test="confirm-password-input"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Password Hint"
            rules="required"
            slim
          >
            <e-input
              v-model="userPasswordHint"
              label="Password Hint"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="password-hint-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>
        </fieldset>
      </fieldset>

    </e-dialog>

    <h1>
      {{ isExistingLetter? 'Edit': 'New' }} Letter

      <span class="icon-help-circle" @click.prevent="setHelp(scheduleLetter ? 128 : 129)" />
    </h1>

    <transition appear enter-active-class="animate__animated animate__fadeInLeft animate__faster">
      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="letter-form"
          class="spaced"
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Title"
            rules="required"
            slim
          >
            <e-input
              v-model="title"
              label="Reference"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="134"
              data-test="title-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>
          <e-button
            v-if="!showAiPromptInput"
            button-type="primary"
            button-style="one"
            @click="useAI"
          >
            Use AI to generate {{ isExistingLetter? 'a new version of this': 'a' }} letter
          </e-button>
          <div
            v-if="showAiPromptInput"
            class="ai-panel"
          >
            <e-textarea
              v-if="showAiPromptInput"
              id="AiInput"
              v-model="prompt"
              label="AI Instructions"
              :error-messages="errors"
              :success="valid"
              :is-required="false"
              placeholder="Write a sentence to tell the AI what you like the letter to say"
              :help-id="134"
              data-test="prompt-input"
              @input="setIsDirty(true)"
            />
            <div class="button-container">
              <e-button
                v-if="showAiPromptInput"
                data-test="generate-button"
                button-type="primary"
                button-style="two"
                :disabled="isAIReady()"
                @click="orderAiLetter"
              >
                Generate Letter ({{ product.price }} MVT)
              </e-button>
              <e-button @click="showAiPromptInput = false">
                Cancel
              </e-button>
            </div>
          </div>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Letter"
            rules="required|required_after_html_removed"
            tag="div"
          >
            <rich-text-editor
              v-model="letterContent"
              label="Letter"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="136"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-button
            data-test="download-button"
            :disabled="!hasContent()"
            @click="generatePdf"
          >
            Download PDF
          </e-button>

          <e-button
            data-test="download-button"
            :disabled="!hasContent()"
            @click="generateDocx"
          >
            Download Word
          </e-button>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Addressee"
            slim
          >
            <e-input
              v-model="addressee"
              label="Addressee (Email)"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              type="email"
              :help-id="135"
              class="email-field"
              data-test="addressee-input"
              :rules="{ email: true }"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <save-bar
            :form-is-valid="formIsValid"
          >
            <e-button
              :value="showSendLetterDiaglog"
              button-type="primary"
              button-style="two"
              :form-is-valid="formIsValid"
              :disabled="!hasContent() && !hasEmailAddressee()"
              @click="value => showSendLetterDiaglog = !!value"
            >
              Send
            </e-button>

            <submit-button
              :form-is-valid="formIsValid"
              form="letter-form"
              button-type="secondary"
              button-style="two"
              button-text="Save & Close"
            />

            <e-button
              v-if="isExistingLetter"
              data-test="delete-button"
              @click="showDeleteDialog = true"
            >
              Delete
            </e-button>
          </save-bar>
        </form>
      </validation-observer>
    </transition>
  </span>
</template>

<script>
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog'
import ConfirmPurchase from '@/components/ConfirmPurchaseDialogue'
import EDialog from '@/components/dialogs/EDialog'
import RichTextEditor from '@/components/RichTextEditor'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import encryptString from '@/utils/encryptString'
import attemptToDecryptString from '@/utils/attemptToDecryptString'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfMake from 'pdfmake/build/pdfmake'
import DOMPurify from 'dompurify'
import HTMLtoDOCX from 'html-to-docx'
import { format } from 'date-fns'
import { htmlToPdfAsBase64, htmlToPdfDocGenerator } from '@/utils/htmlToPdf'

const emailTest = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'LetterForm',

  components: {
    ConfirmationDialog,
    ConfirmPurchase,
    EDialog,
    RichTextEditor,
    SaveBar,
    SubmitButton
  },

  props: {
    id: {
      type: String,
      default: ''
    },

    setScheduleLetter: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'deleted',
    'submitted'
  ],

  data () {
    return {
      letterId: '',
      title: '',
      prompt: '',
      addressee: '',
      letterContent: '',
      scheduleLetter: false,
      sendLetter: false,
      sendDate: '',
      encryptPdf: false,
      userPassword: '',
      userPasswordConfirm: '',
      userPasswordHint: '',

      showSendLetterDiaglog: false,
      showDeleteDialog: false,
      showPurchaseDialog: false,
      showAiPromptInput: false,
      showAiWorkingDialog: false,

      isDirty: false,
      isExistingLetter: false,
      product: {}
    }
  },

  computed: {
    aiLetterData () {
      return this.$store.state.marketplace.aiLetterResponse
    }
  },

  watch: {
    aiLetterData (newValue, oldValue) {
    // Handle the changes
      if (newValue !== oldValue) {
        this.showAiWorkingDialog = false
        this.onAiLetterReturned(newValue)
      }
    }
  },

  async created () {
    if (this.id) {
      try {
        this.isExistingLetter = true
        const letter = await this.$store.dispatch('letters/getLetterById', this.id)
        this.prompt = letter.prompt
        this.letterId = this.id
        this.title = await attemptToDecryptString({ string: letter.title, armoredPublicKey: this.$store.state.armoredPublicKey })
        this.addressee = letter.addressee
        this.letterContent = letter.content
        this.sendDate = letter.sendDate && (format(letter.sendDate, "yyyy-MM-dd'T'HH:mm"))
        this.scheduleLetter = !!this.sendDate
        this.userPasswordHint = letter.hint
        if (this.userPasswordHint) this.encryptPdf = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Letter could not be retrieved'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterForm',
          funtionName: 'created -> store letters/getLetterById'
        })
      }
    } else {
      this.letterId = await this.$store.dispatch('letters/getNewLetterId')
      this.scheduleLetter = this.setScheduleLetter
    }

    this.product = this.$store.state.marketplace.products.filter(c => c.title === 'Letters')[0].inventory.filter(i => i.sku === 'MVSKU_41L3773R_V1')[0]
    this.product.userReference = this.letterId
    const helpId = this.scheduleLetter ? 128 : 129

    this.$store.dispatch('findAndSetHelpObject', {
      id: helpId,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    hasEmailAddressee () {
      return emailTest.test(this.addressee)
    },

    hasContent () {
      return !!this.letterContent && this.letterContent.length > 7
    },

    useAI () {
      this.showAiPromptInput ? this.showAiPromptInput = false : this.showAiPromptInput = true
    },

    isAIReady () {
      this.product.prompt = this.prompt
      return !this.prompt.match(/^\b\w+\b(?:\s+\b\w+\b){4,}/)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    aiWorking () {
      this.showAiWorkingDialog = true
      this.showAiPromptInput = false
    },

    async orderAiLetter () {
      try {
        this.showPurchaseDialog = true
        this.letterContent = 'Generating AI Letter..'
        // this.showAiWorkingDialog = true
        // this.isExistingLetter = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'AI Agent is not available'
        })
        this.showAiWorkingDialog = false
        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterForm',
          functionName: 'orderAiLetter'
        })
      }
    },

    onAiLetterReturned (letter) {
      try {
        this.showAiWorkingDialog = false
        this.letterContent = letter.text
          .split('\n\n')
          .map(p => '<p>' + p + '</p>')
          .reduce((a, p) => a + p)
      } catch (err) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'AI Letter is not available'
        })

        this.$store.dispatch('logError', {
          error: err,
          fileName: 'LetterForm',
          functionName: 'onAiLetterReturned'
        })
      }
    },

    generatePdf () {
      try {
        htmlToPdfDocGenerator({ html: this.letterContent, isPreview: true })
          .open()
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'PDF could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterForm',
          functionName: 'generatePdf'
        })
      }
    },

    async generateDocx () {
      try {
        const html = DOMPurify.sanitize(this.letterContent)
        const documentOptions = {
          header: true,
          footer: true,
          pageNumber: true,
          footerType: 'first',
          skipFirstHeaderFooter: true,
          creator: 'MeaVitae Letter Renderer',
          title: this.title,
          keywords: ['MeaVitae', this.title]
        }
        const footerHTMLString = `<p style="margin-right: 30%;">From the desk of ${this.$store.getters.user?.fullName} on ${format(Date.now(), 'yyyy-MM-dd')} - </p>`
        const headerHTMLString = `<h1>${this.$store.getters.user?.fullName}</h1>`
        const wordDocBlob = await HTMLtoDOCX(html, headerHTMLString, documentOptions, footerHTMLString)

        const link = document.createElement('a')
        link.download = `${format(Date.now(), 'yyyy-MM-dd')}_${this.title.substring(0, 20)}.docx`
        link.href = URL.createObjectURL(wordDocBlob)
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Word could not be created'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LettersTable',
          functionName: 'generateDocx'
        })
      }
    },

    async deleteLetter () {
      try {
        this.showDeleteDialog = false

        await this.$store.dispatch('letters/deleteLetter', this.letterId)

        this.$store.commit('snackbar/update', {
          type: 'success',
          message: 'Letter successfully deleted'
        })

        this.$emit('deleted', { name: 'LettersRecordsTable' })
      } catch (error) {
        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterForm',
          functionName: 'deleteLEtter'
        })
      }
    },

    cancelSend () {
      this.showSendLetterDiaglog = false
      this.sendLetter = false
    },

    sendItNow () {
      this.sendLetter = true
      return this.submit()
    },

    async submit () {
      try {
        console.log('ID:', this.letterId)
        const pdfAsBase64 = await htmlToPdfAsBase64({
          html: this.letterContent,
          ...this.encryptPdf && { userPassword: this.userPassword }
        })

        const encryptedLetterContent = await encryptString({
          unencryptedString: this.letterContent,
          publicKeyObjects: [{ armoredPublicKey: this.$store.state.armoredPublicKey }],
          armoredPrivateKey: this.$store.state.armoredPrivateKey
        })

        const encryptedPrompt = this.prompt && this.prompt.length > 0
          ? await encryptString({
            unencryptedString: this.prompt,
            publicKeyObjects: [{ armoredPublicKey: this.$store.state.armoredPublicKey }],
            armoredPrivateKey: this.$store.state.armoredPrivateKey
          })
          : null

        const letterObject = {
          ...this.letterId && { id: this.letterId },
          title: await encryptString({
            unencryptedString: this.title,
            publicKeyObjects: [{ armoredPublicKey: this.$store.state.armoredPublicKey }],
            armoredPrivateKey: this.$store.state.armoredPrivateKey
          }),

          addressee: this.addressee,
          raw: encryptedLetterContent,
          prompt: encryptedPrompt,
          ...(this.sendLetter && {
            sendNow: !this.scheduleLetter,
            sendDate: new Date(this.sendDate).getTime(),
            pdf: pdfAsBase64,
            ...this.encryptPdf && { hint: this.userPasswordHint }
          }) || {
            sendDate: null,
            pdf: null,
            hint: null
          }
        }
        console.log('Letter:', letterObject)
        await this.$store.dispatch(`letters/${this.sendLetter ? 'post' : 'put'}Letter`, letterObject)

        this.setIsDirty(false)

        if (!this.sendLetter) this.$emit('submitted', { name: 'LettersRecordsTable' })
        else this.showSendLetterDiaglog = false
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: `Failed to ${this.sendLetter ? 'send' : 'save'} letter`
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'LetterForm',
          functionName: 'submit'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ai-panel {
  border: 2px solid $sun;
  border-radius: 4px;
  padding: 8px;

  .button-container {
    display: inline-grid;
    float: right;
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;
    justify-items: end;
    padding-bottom: 4px;
    padding-top: 10px;
  }
}

.confirmation-buttons {
  align-content: center;
  align-items: center;
  background-color: $widget-background-color;
  bottom: 0;
  color: $filled-button-text;
  display: flex;
  height: 5rem;
  justify-content: space-around;
  margin-top: auto;
  position: sticky;
  z-index: 1;

  p {
    color: $white;
    margin: 0;

    .highlight {
      color: $color-error;
    }
  }
}
</style>
