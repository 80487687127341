import axios from 'axios'

export default async ({ email, referringSubdomain }) => {
  const { VUE_APP_GATEWAY_URL } = process.env

  return axios({
    method: 'post',
    url: `${VUE_APP_GATEWAY_URL}/idp/forgotten-password`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ email }),
    timeout: 8000
  })
}
