var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDeleteDialog)?_c('confirmation-dialog',{attrs:{"title":"Delete this credential"},on:{"confirm":_vm.deleteCredential,"cancel":function($event){_vm.showDeleteDialog = false}}}):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.isExistingCredential ? 'Edit' : 'Create')+" Credential "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(122)}}})]),_c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeInLeft animate__faster"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"credential-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"Credential Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Credential Name","error-messages":errors,"success":valid,"is-required":true,"help-id":140,"data-test":"credential-name-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.credentialName),callback:function ($$v) {_vm.credentialName=$$v},expression:"credentialName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Username","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Username","error-messages":errors,"success":valid,"is-required":true,"data-test":"username-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('div',{staticClass:"password-element"},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Password","error-messages":errors,"success":valid,"is-required":true,"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"off","data-test":"password-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('e-button',{staticClass:"show-password-button",class:_vm.showPassword ? 'icon-eye-slash' : 'icon-eye',on:{"click":_vm.toggleShowPassword}})],1),_c('validation-provider',{attrs:{"vid":"confirm","name":"Confirm Password","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Confirm Password","error-messages":errors,"success":valid,"is-required":true,"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"off","data-test":"password-confirm-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Service Website","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Service Website","error-messages":errors,"success":valid,"is-required":true,"help-id":123,"type":"text","data-test":"service-website-input"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.serviceWebsite),callback:function ($$v) {_vm.serviceWebsite=$$v},expression:"serviceWebsite"}})]}}],null,true)}),_c('e-textarea',{attrs:{"label":"Description","data-test":"description-textarea"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"credential-form"}}),(_vm.isExistingCredential)?_c('e-button',{attrs:{"data-test":"delete-button"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" Delete ")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }