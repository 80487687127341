'use strict'

import axios from 'axios'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ tokensNumber, tokenPriceMeta }) => axios({
  method: 'POST',
  headers: {
    Authorization: sessionStorage.getItem('jwt'),
    'Content-Type': 'application/json'
  },
  data: { description: 'Vendor CashOut Tokens', tokensNumber, tokenPriceMeta },
  url: `${VUE_APP_ORDERS_URL}/invoice`,
  timeout: 8000
})
