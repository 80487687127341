import Vue from 'vue'

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { subYears, isBefore, isPast, isFuture, isAfter } from 'date-fns'
import { messages } from 'vee-validate/dist/locale/en.json'

import {
  required,
  // is,
  max,
  min,
  min_value as minValue,
  max_value as maxValue,
  email,
  ext,
  mimes,
  alpha,
  confirmed
} from 'vee-validate/dist/rules'

// extend('is', is)

extend('required', { ...required, message: messages.required })
extend('min_value', { ...minValue, message: messages.minValue })
extend('max_value', { ...maxValue, message: messages.maxValue })
extend('email', { ...email, message: messages.email })
extend('ext', { ...ext, message: messages.ext })
extend('mimes', { ...mimes, message: messages.mimes })
extend('alpha', { ...alpha, message: messages.alpha })
extend('confirmed', { ...confirmed, message: messages.confirmed })
extend('min', { ...min, message: messages.min })
extend('max', { ...max, message: messages.max })

// custom
extend('is_past_date', {
  validate: date => isPast(new Date(date)),
  message: 'Date must be in the past'
})

extend('is_future_date', {
  validate: date => isFuture(new Date(date)),
  message: 'Date must be in the future'
})

extend('is_1_min_in_future_datetime', {
  validate: date => isAfter(
    new Date(date),
    new Date(new Date().getTime() + (1 * 60000))
  ),
  message: '{_field_} must be at least one minute in the future'
})

extend('is_one_month_in_future_datetime', {
  validate: date => isAfter(
    new Date(date),
    new Date(new Date().getTime() + (30 * 86400000))
  ),
  message: '{_field_} must be at least one month in the future'
})

extend('is_not_over_150_years_ago', {
  validate: date => isBefore(subYears(new Date(), 150), new Date(date)),
  message: 'Date can not be more than 150 years ago'
})

extend('array_length', {
  validate: length => length > 0,
  message: 'At least one {_field_} is required'
})

extend('required_after_html_removed', {
  validate: string => !!string.replace(/(<([^>]+)>)/gi, ''),
  message: '{_field_} is required'
})

// extend('min', {
//   ...min,
//   message: '{_field_} must be at least {length} characters'
// })
//
// extend('max', {
//   ...max,
//   message: '{_field_} can not be greater than {length} characters'
// })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
