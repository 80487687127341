'use strict'

import Asset from './Asset'
import Beneficiary from './Beneficiary'
import ChildContact from './ChildContact'
import Contact from './Contact'
import ExecutorContact from './ExecutorContact'
import GuardianContact from './GuardianContact'
import MonetaryDebt from './MonetaryDebt'
import MonetaryGift from './MonetaryGift'
import ResidualPersonalPossessionsBeneficiary from './ResidualPersonalPossessionsBeneficiary'
import ResiduaryEstateBeneficiary from './ResiduaryEstateBeneficiary'
import SubstituteExecutorContact from './SubstituteExecutorContact'
import SubstituteGuardianContact from './SubstituteGuardianContact'
import User from './User'
import WitnessContact from './WitnessContact'
import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class Will extends Model {
  static get entity () { return 'will' }

  static fields () {
    return {
      id: this.uid(() => createId()),
      user: this.hasOne(User, 'willId'),
      disposingOfAllWorldwideAssets: this.boolean(),
      partnerId: this.attr(),
      partner: this.belongsTo(Contact, 'partnerId'),
      partnerType: this.string(),
      partnerIsFormalised: this.boolean(true),
      children: this.belongsToMany(Contact, ChildContact, 'willId', 'contactId'),
      childrenIncludesIllegitimateChildren: this.boolean(false),
      childrenIncludesStepchildren: this.boolean(false),
      monetaryDebts: this.hasMany(MonetaryDebt, 'willId'),
      monetaryGifts: this.hasMany(MonetaryGift, 'willId'),
      payableInheritanceTaxInstruction: this.string('paidByTheEstate'),
      residualPersonalPossessionsInstruction: this.string('coupledWithRequestToDistribute'),
      residualPersonalPossessionsBeneficiaries: this.belongsToMany(Beneficiary, ResidualPersonalPossessionsBeneficiary, 'willId', 'beneficiaryId'),
      residuaryEstatePayableInheritanceTaxInstruction: this.string('paidByTheEstate'),
      residuaryEstateUnder25BeneficiaryShareInTrustFund: this.boolean(),
      residuaryEstateBeneficiaries: this.belongsToMany(Beneficiary, ResiduaryEstateBeneficiary, 'willId', 'beneficiaryId'),
      remainingEstateToPartner: this.boolean(false),
      remainingEstateToChildren: this.boolean(false),
      remainingEstateToGrandchildren: this.boolean(false),
      witnesses: this.belongsToMany(Contact, WitnessContact, 'willId', 'contactId'),
      executors: this.belongsToMany(Contact, ExecutorContact, 'willId', 'contactId'),
      substituteExecutors: this.belongsToMany(Contact, SubstituteExecutorContact, 'willId', 'contactId'),
      guardians: this.belongsToMany(Contact, GuardianContact, 'willId', 'contactId'),
      substituteGuardians: this.belongsToMany(Contact, SubstituteGuardianContact, 'willId', 'contactId'),
      assets: this.hasMany(Asset, 'willId')
    }
  }
}
