<template>
  <button
    class="menu-item"
    :class="{ 'is-active': isActive ? isActive(): null }"
    :title="title"
    @click="action"
  >
    <span
      v-if="icon"
      :class="icon"
    />

    <template v-else>
      {{ title }}
    </template>
  </button>
</template>

<script>
export default {
  name: 'MenuItem',

  props: {
    icon: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    action: {
      type: Function,
      required: true
    },

    isActive: {
      type: Function,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  background-color: transparent;
  border: 0;
  border-radius: 0.4rem;
  color: $white;
  height: 1.75rem;
  margin-right: 0.25rem;
  min-width: 1.75rem;
  padding: 0.25rem;

  &.is-active,
  &:hover {
    background-color: #0d0d0d;
    color: $font-color-two;
  }
}
</style>
