'use strict'

import axios from 'axios'
import createEndpoint from './utils/createEndpoint'

const { VUE_APP_API_URL } = process.env

export default async ({ userId, jwt, reference }) => axios({
  method: 'get',
  headers: { Authorization: jwt },
  url: `${VUE_APP_API_URL}/${createEndpoint({ userId, entityType: 'documentRecords' })}:${reference}`,
  timeout: 8000
})
