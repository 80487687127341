'use strict'

import endpointLookupObject from './endpointLookupObject.json'

export default ({ userId, entityType }) => {
  if (endpointLookupObject[entityType] === undefined) {
    throw new Error('entityType is not valid')
  }

  return userId.concat('/', endpointLookupObject[entityType])
}
