<template>
  <message-form
    v-if="showView"
    message-type="draft"
    :predefined-message-id="messageId"
    :predefined-subject="subject"
    :predefined-body="body"
    :predefined-attachments="attachments"
    :predefined-to="to"
    :predefined-from="from"
    @route-change="routeName => $emit('route-change', routeName)"
  />
</template>

<script>
import MessageForm from '@/components/forms/MessageForm'

export default {
  name: 'Draft',

  components: {
    MessageForm
  },

  props: {
    messageId: {
      type: String,
      default: '',
      required: true
    },

    mailboxType: {
      type: String,
      default: '',
      required: true
    }
  },

  data () {
    return {
      subject: '',
      body: '',
      attachments: [],
      to: [],
      from: {},
      sentDate: '',
      showView: false
    }
  },

  async created () {
    try {
      const { subject, body, to, from, date, attachments } = await this.$store.dispatch('messages/getMessage', {
        messageId: this.messageId,
        mailboxType: this.mailboxType
      })

      this.to = to
      this.from = from
      this.sentDate = date
      this.subject = subject
      this.body = body.replace(/  +/g, '')
      this.attachments = attachments

      this.showView = true
    } catch (error) {
      this.$store.commit('snackbar/update',
        {
          type: 'error',
          message: 'Failed to get message'
        }
      )

      this.$store.dispatch('logError', {
        error,
        fileName: 'Messages > Draft',
        functionName: 'created'
      })
    }
  }
}
</script>
