<template>
  <div>
    <section>
      <div class="top-inputs">
        <h1>
          Articles found: {{ articles.length }}
        </h1>
      </div>

      <div class="articles">
        <article-element
          v-for="article in articles"
          :key="article.id"
          :article="article"
          @article-clicked="goToArticle"
        />
      </div>
    </section>

    <section v-if="isSearch && articles.length === 0">
      <p>
        Welcome to the MeaVitae Help archive. Here you will find
        a wide selection of help articles with information and
        guides to help explain how MeaVitae works.
      </p>
    </section>
  </div>
</template>

<script>
import ArticleElement from '@/components/ArticleElement'

export default {
  name: 'HelpCategoryArticles',

  components: {
    ArticleElement
  },

  props: {
    isSearch: {
      type: Boolean,
      default: false
    },

    searchString: {
      type: String,
      default: ''
    },

    categoryId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      articles: []
    }
  },

  async created () {
    if (this.searchString) {
      try {
        this.articles = this.$store.getters['help/getSearchResults'](this.searchString)
      } catch {
        this.articles = []
      }
    } else {
      this.articles = this.$store.state.help.categories
        .find(({ id }) => id === this.categoryId)
        .articles
    }
  },

  methods: {
    goToArticle (article) {
      this.$router.push({
        name: `help-${article.subCategory.id}-${article.id}`,
        params: {
          categoryId: this.categoryId,
          articleId: article.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .articles {
    display: grid;
    row-gap: 1em;
  }
</style>
