'use strict'
import axios from 'axios'

const { VUE_APP_ORDERS_URL } = process.env

export default async ({ jwt, order }) => axios({
  method: 'POST',
  headers: {
    Authorization: jwt
  },
  data: order,
  url: `${VUE_APP_ORDERS_URL}/order`,
  timeout: 200000
})
