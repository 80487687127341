<template>
  <span>
    <document-preview-dialog
      v-if="showDocumentPreviewDialog"
      :document="documentPreview"
      @close="showDocumentPreviewDialog = false"
      @create="create"
    />

    <document-locked
      v-if="!documentUnlocked"
      :marketplace-product-sku="marketplaceProductSku"
    />

    <span v-else>
      <h1>
        Child Care Service Agreement

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(91)"
        />
      </h1>

      <validation-observer v-slot="{ handleSubmit, valid: formIsValid }">
        <form
          id="child-care-service-agreement-form"
          class="spaced"
          @submit.prevent="handleSubmit(createPreview)"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            name="Document Label"
            :rules="{ required: true }"
            slim
          >
            <e-input
              v-model="documentLabel"
              label="Document Label"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              class="title-input"
              data-test="document-label-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>Contracted Parties</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Client"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="childCareClientId"
                :items="childCareClientSelectOptions"
                label="Child Care Client"
                default-option-text="Add Child Care Client"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="child-care-client-select"
                @input="setIsDirty(true)"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Provider"
              :rules="{ required: true }"
              slim
            >
              <e-select
                v-model="childCareProviderId"
                :items="childCareProviderSelectOptions"
                label="Child Care Provider"
                default-option-text="Add Child Care Provider"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="child-care-provider-select"
                @input="setIsDirty(true)"
              />
            </validation-provider>
          </fieldset>

          <fieldset data-test="children-fieldset">
            <legend>Children</legend>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Child"
              :rules="{ array_length: true }"
              tag="div"
            >
              <input
                v-model="childContacts.length"
                type="hidden"
              >

              <e-select
                :items="childSelectOptions"
                default-option-text="Add Contact"
                :error-messages="errors"
                :success="valid"
                :is-required="true"
                data-test="children-select"
                @change="addChildContact"
              />
            </validation-provider>

            <table v-if="childContacts.length">
              <thead>
                <tr>
                  <th>Child</th>
                  <th class="center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="contact in childContacts"
                  :key="contact.id"
                >
                  <td>
                    {{ contact.fullName }}
                  </td>

                  <td class="actions">
                    <e-button
                      class="icon-trash-2 ghost"
                      data-test="remove-contact-button"
                      @click="removeChildContact(contact.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Work Hours"
            :rules="{ required: true, min_value: 1 }"
            slim
          >
            <e-input
              v-model="workHours"
              label="Work Hours"
              type="number"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="92"
              data-test="work-hours-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Start date"
            rules="required"
            slim
          >
            <e-input
              v-model="startDate"
              label="Start date"
              type="date"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="start-date-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="endDate"
            label="End date"
            type="date"
            :help-id="93"
            data-test="end-date-input"
            @input="setIsDirty(true)"
          />

          <validation-provider
            v-slot="{ errors, valid }"
            name="Pay Rate"
            :rules="{ required: true, min_value: 0.1 }"
            slim
          >
            <e-input
              v-model="payRate"
              label="Pay Rate"
              type="number"
              step=".01"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              :help-id="94"
              data-test="pay-rate-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            v-model="prorataRate"
            label="Prorata Rate"
            type="number"
            step=".01"
            :help-id="95"
            data-test="prorata-rate-input"
            @input="setIsDirty(true)"
          />

          <e-input
            v-model="latePaymentFee"
            label="Late Payment Fee"
            type="number"
            step=".01"
            :help-id="96"
            data-test="late-payment-fee-input"
            @input="setIsDirty(true)"
          />

          <fieldset>
            <legend>Job Responsibilities</legend>

            <p>
              Provider agrees to perform the following duties as a condition of their employment:
            </p>

            <e-input
              label="Prioritise the physical and mental well-being and safety of the child(ren) above all other duties"
              type="checkbox"
              :value="jobResponsibilities.prioritisePhysicalMental"
              data-test="job-responsibilities-prioritise-physical-mental-checkbox"
              @change="value => jobResponsibilities.prioritisePhysicalMental = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Tend to the child(ren)'s basic needs, such as bathing, brushing teeth, dressing, nappy changing, feeding, napping, and putting to bed, as relevant and necessary"
              type="checkbox"
              :value="jobResponsibilities.tendToBasicNeeds"
              data-test="job-responsibilities-tend-to-basic-needs-checkbox"
              @change="value => jobResponsibilities.tendToBasicNeeds = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Pick up and drop off the child(ren) at school"
              type="checkbox"
              :value="jobResponsibilities.schoolRun"
              data-test="job-responsibilities-school-run-checkbox"
              @change="value => jobResponsibilities.schoolRun = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Administer medications to the child(ren) as directed by the Family"
              type="checkbox"
              :value="jobResponsibilities.administerMedication"
              data-test="job-responsibilities-administer-medication-checkbox"
              @change="value => jobResponsibilities.administerMedication = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Prepare meals for the child(ren) during their time tending to the child(ren), and then cleaning/tidying the kitchen and dining area when the meal is completed"
              type="checkbox"
              :value="jobResponsibilities.prepareMealsAndCleanUpAfter"
              data-test="job-responsibilities-prepare-meals-and-clean-up-after-checkbox"
              @change="value => jobResponsibilities.prepareMealsAndCleanUpAfter = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Supervise and participate in activities with the child(ren), including games, playdates with other children, playground outings, crafting projects, cultural activities, trips to museums and libraries, and reading books to the child(ren) daily"
              type="checkbox"
              :value="jobResponsibilities.superviseParticipateInActivities"
              data-test="job-responsibilities-supervise-participate-in-activities-checkbox"
              @change="value => jobResponsibilities.superviseParticipateInActivities = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Research and plan activities for the child(ren) that have substantial child development, social skill building, or educational value"
              type="checkbox"
              :value="jobResponsibilities.researchPlanActivities"
              data-test="job-responsibilities-research-plan-activities-checkbox"
              @change="value => jobResponsibilities.researchPlanActivities = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Keep a daily journal to be provided to the Family with descriptions of the child(ren)'s daily activities, meals, medications, and disciplinary incidents"
              type="checkbox"
              :value="jobResponsibilities.keepDailyJournal"
              data-test="job-responsibilities-keep-daily-journal-checkbox"
              @change="value => jobResponsibilities.keepDailyJournal = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Provide daily language instruction to the child(ren)?"
              type="checkbox"
              :value="jobResponsibilities.provideDailyLanguageInstruction"
              data-test="job-responsibilities-provide-daily-language-instruction-checkbox"
              @change="value => jobResponsibilities.provideDailyLanguageInstruction = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Provide daily music instruction to the child(ren)"
              type="checkbox"
              :value="jobResponsibilities.provideDailyMusicInstruction"
              data-test="job-responsibilities-provide-daily-music-instruction-checkbox"
              @change="value => jobResponsibilities.provideDailyMusicInstruction = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Use a car provided by the Family to take the child(ren) to and from activities and events"
              type="checkbox"
              :value="jobResponsibilities.carToFromActivities"
              data-test="job-responsibilities-car-to-from-activities-checkbox"
              @change="value => jobResponsibilities.carToFromActivities = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Tidy the child(ren)'s bedroom and play areas, which includes keeping these areas organised, making beds, vacuuming, and disinfecting toys as relevant and necessary"
              type="checkbox"
              :value="jobResponsibilities.tidyBedroomAndPlayAreas"
              data-test="job-responsibilities-tidy-bedroom-and-play-areas-checkbox"
              @change="value => jobResponsibilities.tidyBedroomAndPlayAreas = value"
              @input="setIsDirty(true)"
            />

            <e-input
              label="Wash, fold, and put away the child(ren)'s laundry and linens"
              type="checkbox"
              :value="jobResponsibilities.putAwayLaundry"
              data-test="job-responsibilities-put-away-laundry-checkbox"
              @change="value => jobResponsibilities.putAwayLaundry = value"
              @input="setIsDirty(true)"
            />

            <e-textarea
              v-model="jobResponsibilitiesOther"
              label="The following other job responsibilities:"
              data-test="job-responsibilities-other-textarea"
              @input="setIsDirty(true)"
            />

            <validation-provider
              v-if="!someJobResponsibilitiesAreDefined"
              v-slot="{ errors }"
              name="Job Responsibilities"
              :rules="{ is: [true] }"
              :immediate="true"
              tag="div"
            >
              <input
                v-model="someJobResponsibilitiesAreDefined"
                type="hidden"
              >

              <span class="error-text">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </fieldset>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Number Of Holiday Days"
            :rules="{ required: true, min_value: 1 }"
            slim
          >
            <e-input
              v-model="numberOfHolidayDays"
              label="Number Of Holiday Days"
              type="number"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="number-of-holiday-days-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <e-input
            :value="travelForClientHolidayReceivePay"
            label="If the Provider travels on holiday with the Family, the Provider shall receive their regular pay rate plus overtime pay as previously agreed upon?"
            type="checkbox"
            data-test="travel-for-client-holiday-receive-pay-checkbox"
            @change="value => travelForClientHolidayReceivePay = value"
            @input="setIsDirty(true)"
          />

          <validation-provider
            v-slot="{ errors, valid }"
            name="Number Of Weeks Notice"
            :rules="{ required: true, min_value: 1 }"
            slim
          >
            <e-input
              v-model="numberOfWeeksNotice"
              label="Number Of Weeks Notice"
              type="number"
              :error-messages="errors"
              :success="valid"
              :is-required="true"
              data-test="number-of-weeks-notice-input"
              @input="setIsDirty(true)"
            />
          </validation-provider>

          <fieldset>
            <legend>
              Termination Reasons

              <span
                class="icon-help-circle"
                @click="setHelp(97)"
              />
            </legend>

            <e-textarea
              v-model="otherClientTerminationReasons"
              label="Other Client Termination Reasons"
              data-test="other-client-termination-reasons-textarea"
              @input="setIsDirty(true)"
            />

            <e-textarea
              v-model="otherProviderTerminationReasons"
              label="Other Provider Termination Reasons"
              data-test="other-provider-termination-reasons-textarea"
              @input="setIsDirty(true)"
            />

            <e-textarea
              v-model="otherMutualTerminationReasons"
              label="Other Mutual Termination Reasons"
              data-test="other-mutual-termination-reasons-textarea"
              @input="setIsDirty(true)"
            />
          </fieldset>

          <save-bar :form-is-valid="formIsValid">
            <submit-button
              :form-is-valid="formIsValid"
              form="child-care-service-agreement-form"
              button-text="Preview"
              data-test="bottom-preview-button"
            />
          </save-bar>
        </form>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import DocumentLocked from '@/components/DocumentLocked'
import DocumentPreviewDialog from '@/components/dialogs/DocumentPreviewDialog'
import DocumentRecord from '@/models/DocumentRecord'
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import convertObjectToBase64Block from '@/utils/convertObjectToBase64Block'
import createDOMPurify from 'dompurify'
import md5 from 'md5'
import { format } from 'date-fns'
import { marked } from 'marked'

const DOMPurify = createDOMPurify(window)

export default {
  name: 'ChildCareServiceAgreement',

  components: {
    DocumentLocked,
    DocumentPreviewDialog,
    SaveBar,
    SubmitButton
  },

  data () {
    return {
      productId: '33fb76ed-5cf6-4b3f-85cc-bf2d40570975',
      isDirty: false,
      documentLabel: '',
      documentPreview: '',

      locale: '',
      version: '',
      template: '',

      childCareClientId: '',
      childCareProviderId: '',

      childContacts: [],

      workHours: 0,
      startDate: '',
      endDate: '',
      payRate: 0,
      prorataRate: 0,
      latePaymentFee: 0,

      jobResponsibilities: {
        prioritisePhysicalMental: true,
        tendToBasicNeeds: true,
        schoolRun: true,
        administerMedication: true,
        prepareMealsAndCleanUpAfter: true,
        superviseParticipateInActivities: true,
        researchPlanActivities: true,
        keepDailyJournal: true,
        provideDailyLanguageInstruction: true,
        provideDailyMusicInstruction: true,
        carToFromActivities: true,
        tidyBedroomAndPlayAreas: true,
        putAwayLaundry: true
      },

      jobResponsibilitiesOther: '',
      numberOfHolidayDays: 20,
      travelForClientHolidayReceivePay: true,
      numberOfWeeksNotice: 4,
      otherClientTerminationReasons: '',
      otherProviderTerminationReasons: '',
      otherMutualTerminationReasons: '',

      showDocumentPreviewDialog: false
    }
  },

  computed: {
    documentUnlocked () {
      return !!this.$store.getters['marketplace/getPurchasedProduct'](this.productId)
    },

    userAndContacts () {
      return this
        .$store
        .getters
        .userAndContacts(['person'])
    },

    childSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => !this.childContacts
          .map(child => child.id)
          .includes(id)
        )
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    childCareClientSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.childCareProviderId)
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    childCareProviderSelectOptions () {
      return this.userAndContacts
        .filter(({ id }) => id !== this.childCareClientId)
        .map(({ id, selectText, isValid }) => ({
          value: id,
          text: selectText,
          isDisabled: !isValid
        }))
    },

    someJobResponsibilitiesAreDefined () {
      if (this.jobResponsibilitiesOther.trim()) return true

      return !Object
        .values(this.jobResponsibilities)
        .every(value => !value)
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 91,
      isInitial: true
    })
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    addChildContact (contactId) {
      this.childContacts.push(this.userAndContacts.find(({ id }) => id === contactId))
      this.setIsDirty(true)
    },

    removeChildContact (contactId) {
      this.childContacts = this.childContacts
        .filter(({ id }) => contactId !== id)
    },

    async createPreview () {
      try {
        const { locale, version, template } = await this.$store.dispatch('renderEngine', {
          context: {
            ...this.childCareClientId && {
              childCareClient: this.userAndContacts.find(({ id }) => id === this.childCareClientId)
            },
            ...this.childCareProviderId && {
              childCareProvider: this.userAndContacts.find(({ id }) => id === this.childCareProviderId)
            },
            children: this.childContacts,
            workHours: this.workHours,
            startDate: this.startDate,
            endDate: this.endDate,
            payRate: this.payRate,
            prorataRate: this.prorataRate,
            latePaymentFee: this.latePaymentFee,
            jobResponsibilities: this.jobResponsibilities,
            jobResponsibilitiesOther: this.jobResponsibilitiesOther,
            numberOfHolidayDays: this.numberOfHolidayDays,
            travelForClientHolidayReceivePay: this.travelForClientHolidayReceivePay,
            numberOfWeeksNotice: this.numberOfWeeksNotice,
            otherClientTerminationReasons: this.otherClientTerminationReasons,
            otherProviderTerminationReasons: this.otherProviderTerminationReasons,
            otherMutualTerminationReasons: this.otherMutualTerminationReasons
          },
          locale: 'en-GB',
          version: 0,
          type: 'childCare'
        })

        this.locale = locale
        this.version = version
        this.template = template

        this.documentPreview = DOMPurify.sanitize(marked(template, { gfm: true }))
        this.documentPreview = DOMPurify.sanitize(marked.parse(template, { gfm: true }))
        this.showDocumentPreviewDialog = true
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document preview'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ChildCareServiceAgreement',
          functionName: 'createPreview'
        })
      }
    },

    async create () {
      try {
        this.showDocumentPreviewDialog = false

        const { base64Block } = convertObjectToBase64Block(this.template)

        const documentRecord = {
          label: this.documentLabel,
          locale: this.locale,
          version: this.version,
          type: 'childCare',
          content: base64Block,
          reference: md5(base64Block),
          timestamp: new Date().getTime()
        }

        const duplicate = DocumentRecord
          .query()
          .where('reference', md5(documentRecord.content))
          .first()

        if (!duplicate) {
          DocumentRecord.insert({ data: documentRecord })
          await this.$store.dispatch('persistDocumentRecordToVault', documentRecord)
        } else {
          const duplicateCreatedDate = format(new Date(duplicate.timestamp), 'HH:mm dd/MM/yyyy')

          this.$store.commit('snackbar/update', {
            type: 'warning',
            message: `An identical document already exists (Label: "${duplicate.label}" Created: ${duplicateCreatedDate})`
          })
        }

        this.setIsDirty(false)

        this.$router.push({ name: 'DocumentsTable' })
      } catch (error) {
        this.$store.commit('snackbar/update', {
          type: 'error',
          message: 'Failed to create document'
        })

        this.$store.dispatch('logError', {
          error,
          fileName: 'ChildCareServiceAgreement',
          functionName: 'create'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;

  th {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  td {
    vertical-align: top;

    &.preferred {
      padding-top: 12px;
      text-align: center;

      label {
        display: inline-block;
      }
    }

    &.actions {
      padding-top: 5px;
      text-align: center;
    }
  }
}
</style>
