<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Partner

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(65)"
        />
      </h1>
    </div>

    <e-select
      v-model="partnerType"
      :items="partnerTypes"
      default-option-text="Select partner type"
      data-test="partner-type-select"
    />

    <e-select
      v-if="partnerType"
      v-model="partnerId"
      :items="eligibleContactsAsSelectOptions"
      default-option-text="Select Contact"
      data-test="partner-select"
    />

    <e-input
      v-if="partnerType"
      :value="partnerIsFormalised"
      type="checkbox"
      label="Legal marriage/civil Partnership ceremony has occurred"
      data-test="partner-is-formalised-checkbox"
      @change="value => partnerIsFormalised = value"
    />
  </div>
</template>

<script>
import Contact from '@/models/Contact'
import Will from '@/models/Will'

export default {
  name: 'Partner',

  data () {
    return {
      partnerTypes: [
        { text: 'Spouse', value: 'spouse' },
        { text: 'Civil Partner', value: 'civilPartner' }
      ]
    }
  },

  computed: {
    will () {
      return Will
        .query()
        .with('children')
        .with('guardians')
        .with('substituteGuardians')
        .with('witnesses')
        .with('executors')
        .with('substituteExecutors')
        .first()
    },

    partnerIsFormalised: {
      get () {
        return this.will.partnerIsFormalised
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          partnerIsFormalised: value
        })
      }
    },

    partnerType: {
      get () {
        return this.will.partnerType
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          partnerType: value,
          ...(!value) && { partnerId: undefined }
        })
      }
    },

    partnerId: {
      get () {
        return this.will.partnerId
      },

      async set (value) {
        await this.$store.dispatch('updateWillProperty', {
          partnerId: value
        })
      }
    },

    eligibleContactsAsSelectOptions () {
      return Contact
        .all()
        .filter(({ type }) => type === 'person')
        .map(({ id, selectText }) => ({
          value: id,
          text: selectText,
          isDisabled: this.isDisabled(id)
        }))
        .sort((a, b) => a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1)
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 65,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    },

    isDisabled (contactId) {
      return [
        ...this.will.children.map(({ id }) => id),
        ...this.will.guardians.map(({ id }) => id),
        ...this.will.substituteGuardians.map(({ id }) => id),
        ...this.will.witnesses.map(({ id }) => id)
      ].includes(contactId)
    }
  }
}
</script>
