'use strict'

import AddressBook from '@/views/Directory/AddressBook'
import Contact from '@/views/Directory/Contact'
import Directory from '@/views/Directory/Directory'
import ContactManager from '@/views/Directory/ContactManager'
import ContactView from '@/views/Directory/ContactView'
import FormView from '@/views/FormView'
import LoadingSpinner from '@/components/LoadingSpinner'
import Services from '@/views/Directory/Services'

const directorySubscriptionFeatureId = 1050

export default {
  path: '/directory',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Directory
  },
  meta: {
    title: 'Directory',
    subscriptionFeatureId: directorySubscriptionFeatureId
  },
  children: [
    {
      path: 'address-book',
      name: 'AddressBook',
      components: {
        appClosedLayout: LoadingSpinner,
        default: AddressBook
      },
      meta: {
        title: 'Address Book',
        subscriptionFeatureId: directorySubscriptionFeatureId
      },
      children: [
        {
          path: 'manage-contacts',
          name: 'ManageContacts',
          components: {
            appClosedLayout: LoadingSpinner,
            default: ContactManager
          },
          meta: {
            title: 'Manage Contacts',
            subscriptionFeatureId: directorySubscriptionFeatureId
          }
        },

        {
          path: 'new',
          name: 'NewContact',
          props: {
            default: () => ({
              form: 'ContactForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'New Contact',
            subscriptionFeatureId: directorySubscriptionFeatureId
          }
        },

        {
          path: ':letterId/:contactId',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Contact
          },
          meta: {
            title: 'Contact',
            subscriptionFeatureId: directorySubscriptionFeatureId
          },
          children: [
            {
              path: '',
              name: 'ContactView',
              props: {
                default: route => ({
                  contactId: route.params.contactId
                })
              },
              components: {
                appClosedLayout: LoadingSpinner,
                default: ContactView
              },
              meta: {
                title: 'Contact',
                subscriptionFeatureId: directorySubscriptionFeatureId
              }
            },

            {
              path: 'edit',
              name: 'EditContact',
              props: {
                default: route => ({
                  form: 'ContactForm',
                  letterId: route.params.letterId,
                  contactId: route.params.contactId
                })
              },
              components: {
                appClosedLayout: LoadingSpinner,
                default: FormView
              },
              meta: {
                title: 'Edit Contact',
                subscriptionFeatureId: directorySubscriptionFeatureId
              }
            }
          ]
        }
      ]
    },

    {
      path: 'services',
      name: 'Services',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Services
      },
      meta: {
        title: 'Services',
        subscriptionFeatureId: null
      }
    }
  ]
}
