import ServicesCategoryServices from '@/views/Directory/ServicesCategoryServices'
import Vue from 'vue'
import getServices from '@/apis/cms/getMarketplaceServices'

const createDynamicRoutes = ({ categories, router }) => {
  categories.forEach(category => {
    router.addRoute('Services', {
      path: ':subCategoryId',
      name: category.id,
      props: true,
      component: ServicesCategoryServices,
      meta: { title: 'Services' }
    })
  })
}

export default {
  namespaced: true,

  state: {
    categories: []
  },

  getters: {
    getArticle: state => articleId => state.categories
      .flatMap(({ articles }) => articles)
      .find(({ id }) => id === articleId)
  },

  actions: {
    getServices: async ({ commit }) => {
      try {
        return await getServices({
          jwt: sessionStorage.getItem('jwt')
        })
      } catch (error) {
        if (error.response?.status === 401) {
          commit('setShowRefreshJwtDialog', true, { root: true })
        }

        throw error
      }
    },

    setCategories: async ({ commit, dispatch }, router) => {
      const categories = await dispatch('getServices')
      commit('setCategories', categories)
      createDynamicRoutes({ categories, router })
    }
  },

  mutations: {
    setCategories: (state, value) => Vue.set(state, 'categories', value)
  }
}
