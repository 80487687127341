<template>
  <div class="main">
    <div class="column-one">
      <slot name="one" />
    </div>

    <div id="column-two" class="column-two">
      <slot name="two" />
    </div>

    <div class="column-three">
      <slot name="three" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeColumnLayout'
}
</script>

<style lang="scss" scoped>
.main {
  display: grid;
  grid-template-areas:
    'one'
    'two';
  grid-template-rows: auto 1fr;

  .column-one {
    grid-area: one;

    h1 {
      font-size: 1.458rem;
    }
  }

  .column-two {
    h1 {
      font-size: 1.875rem;
    }

    grid-area: two;
    overflow: scroll;
  }

  .column-three {
    display: none;
    grid-area: three;

    h1 {
      font-size: 1.458rem;
    }
  }

  .column-one,
  .column-two,
  .column-three {
    -ms-overflow-style: none;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}

@media screen and (min-width: $media-break-1) {
  $divider-width: 1px;

  .main {
    grid-template-areas: 'one two three';
    grid-template-columns: 1fr 3fr 1fr;

    .column-one {
      border-right: $divider-width solid $border-color-one;
    }

    .column-two {
      height: 100vh;
    }

    .column-three {
      border-left: $divider-width solid $border-color-one;
      display: block;
    }
  }
}
</style>
