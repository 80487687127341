'use strict'

import convertArrayBufferToString from '../utils/convertArrayBufferToString'
import getPublicKeyCredential from './getPublicKeyCredential'

export default async ({ credentialId, rpId, challenge, userHandle }) => {
  const { response } = await getPublicKeyCredential({
    credentialIds: [credentialId],
    rpId,
    challenge
  })

  if (!response.userHandle || convertArrayBufferToString(response.userHandle) !== userHandle) {
    throw new Error('Security key not compatible, userHandle missing')
  }
}
