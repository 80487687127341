'use strict'

import htmlToPdfMake from 'html-to-pdfmake'
import md5 from 'md5'

export default ({ html, isPreview, userPassword }) => ({
  pageSize: 'A4',
  pageOrientation: 'portrait',
  ...userPassword && { userPassword },
  permissions: {
    modifying: false,
    copying: false
  },
  footer: (currentPage, pageCount) => [
    {
      text: 'MeaVitae Ltd. Registered in England and Wales No. 11556365',
      alignment: 'center'
    },
    {
      text: `${currentPage}/${pageCount}`,
      alignment: 'center',
      margin: [0, 4]
    }
  ],
  content: [
    htmlToPdfMake(html),
    `MD5 Checksum: ${md5(html)}`,
    {
      qr: md5(html),
      alignment: 'left',
      fit: '100',
      margin: [0, 8]
    }
  ],
  styles: {
    'html-center': {
      alignment: 'center'
    }
  },
  ...isPreview && {
    watermark: {
      text: 'Preview',
      fontSize: 200,
      color: 'blue',
      opacity: 0.1,
      bold: true,
      italics: false
    }
  },
  pageBreakBefore: currentNode => currentNode
    .style
    ?.find(style => style === 'page-break')
})
