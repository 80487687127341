'use strict'

import { Model } from '@vuex-orm/core'
import { createId } from '../utils'

export default class SubstituteExecutorContact extends Model {
  static get entity () { return 'substituteExecutorContacts' }
  static get primaryKey () { return ['willId', 'contactId'] }

  static fields () {
    return {
      id: this.uid(() => createId()),
      willId: this.attr(null),
      contactId: this.attr(null)
    }
  }
}
