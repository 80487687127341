<template>
  <span>
    <div class="header">
      <h1>File Upload</h1>

      <e-button
        class="close-button icon-x-circle ghost"
        @click="$emit('closed')"
      />
    </div>

    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider
          v-slot="{ errors, valid }"
          name="Reference"
          :rules="{ required: true }"
          slim
        >
          <e-input
            v-model="reference"
            label="Reference"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
          />
        </validation-provider>

        <e-input
          v-model="referenceDate"
          label="Reference Date"
          type="date"
        />

        <e-textarea
          v-if="false"
          v-model="description"
          label="Description"
        />

        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Upload File"
          :rules="{ required: true, ext: acceptedFileExtensions, mimes: acceptedMimeTypes }"
          slim
        >
          <e-file
            :accept="acceptedMimeTypes.toString()"
            :error-messages="errors"
            :success="valid"
            :is-required="true"
            @input="setFile($event, validate)"
          />
        </validation-provider>

        <div class="submit-container">
          <e-button
            type="submit"
            data-test="submit-button"
          >
            Add
          </e-button>
        </div>
      </form>
    </validation-observer>
  </span>
</template>

<script>
import acceptedFileTypes from '@/json_files/acceptedFileTypes.json'

export default {
  name: 'FileUploadForm',

  emits: [
    'closed',
    'submitted'
  ],

  data () {
    return {
      reference: '',
      referenceDate: '',
      description: '',
      fileToUpload: {}
    }
  },

  computed: {
    acceptedMimeTypes () {
      return acceptedFileTypes.map(({ mimeType }) => mimeType)
    },

    acceptedFileExtensions () {
      return acceptedFileTypes.flatMap(({ extensions }) => extensions)
    }
  },

  methods: {
    async setFile (event, validate) {
      const { valid: isValid } = await validate(event)

      if (isValid) {
        this.fileToUpload = event.target.files[0]
      }
    },

    submit () {
      this.$emit('submitted', {
        file: this.fileToUpload,
        description: this.description,
        reference: this.reference,
        referenceDate: this.referenceDate
      })
    }
  }
}
</script>

<style scoped lang="scss">
label {
  margin-bottom: 1rem;
}

h1 {
  padding-bottom: 1rem;
}

.submit-container {
  margin-top: 1rem;
}

.close-button {
  align-self: start;
  padding: 0;
}
</style>
