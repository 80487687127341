<template>
  <div class="net-worth-bars-container">
    <div class="net-worth-bar">
      <div
        :style="{ width: `${liabilitiesPercentage}%` }"
        class="bar"
      >
        {{ formatAsCurrency(netWorth) }}
      </div>
    </div>

    <div class="assets-bar">
      <span
        :style="{ width: `${assetsPercentage}%` }"
        class="bar"
      />

      <span
        :style="{ width: `${accountsPercentage}%` }"
        class="bar accounts"
      />
    </div>

    <div class="liabilities-bar">
      <span
        :style="{ width: `${liabilitiesPercentage}%` }"
        class="bar"
      />
    </div>
  </div>
</template>

<script>
import { formatMoney } from 'accounting'

export default {
  name: 'ProgressBar',

  props: {
    assetsValue: {
      type: Number,
      default: 0
    },

    accountsValue: {
      type: Number,
      default: 0
    },

    liabilitiesValue: {
      type: Number,
      default: 0
    }
  },

  computed: {
    netWorth () {
      return (this.assetsValue + this.accountsValue) - this.liabilitiesValue
    },

    liabilitiesPercentage () {
      return this.liabilitiesValue / (this.assetsValue + this.accountsValue) * 100
    },

    assetsPercentage () {
      return this.assetsValue / (this.assetsValue + this.accountsValue) * 100
    },

    accountsPercentage () {
      return this.accountsValue / (this.assetsValue + this.accountsValue) * 100
    }
  },

  methods: {
    formatAsCurrency (value) {
      return formatMoney(value, '£', 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.net-worth-bars-container {
  display: grid;
  margin: 0.6rem 0;
  padding: 1.2rem 0 1.1rem;
  position: relative;
  row-gap: 0.3rem;

  .net-worth-bar {
    box-sizing: border-box;
    display: flex;
    height: 3.2rem;
    justify-content: flex-end;
    padding-right: 0.2rem;
    position: absolute;
    text-align: left;
    width: 100%;

    .bar {
      border-left: 0.2rem solid $font-color-one;
      box-sizing: border-box;
      padding-left: 0.2rem;
    }
  }

  .assets-bar,
  .liabilities-bar {
    display: flex;
    height: 0.6rem;

    .bar {
      border-radius: 30px;
      height: 100%;
      min-width: 1%;
    }
  }

  .assets-bar {
    /* background-color: lighten(black, 20%); */
    border-radius: 30px;
    justify-items: start;

    .bar {
      background-color: green;

      &.accounts {
        background-color: yellow;
      }
    }
  }

  .liabilities-bar {
    justify-content: flex-end;

    .bar {
      background-color: red;
    }
  }
}
</style>
