<template>
  <div class="main-element">
    <div class="header">
      <h1>{{ vendor.name }}</h1>

      <e-button
        class="close-button icon-x-circle ghost"
        @click="$emit('closed')"
      />
    </div>

    <div class="body">
      <div>
        <component :is="html" class="item-description" />
      </div>

      <div class="side-bit">
        <img :alt="vendor.name" :src="vendor.imageUrl || '../../default_thumbnail.png'">

        <dl class="info">
          <div>
            <dt>Website:</dt>
            <dd>{{ vendor.website }}</dd>
          </div>

          <div>
            <dt>Address:</dt>
            <dd>{{ vendor.address }}</dd>
          </div>

          <div>
            <dt>Email:</dt>
            <dd>{{ vendor.email }}</dd>
          </div>

          <div>
            <dt>Established:</dt>
            <dd>{{ vendor.established }}</dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="registration-info">
      Registered in the {{ vendor.jurisdiction.name }} as {{ vendor.legalName }}
    </div>
  </div>
</template>

<script>
import portableTextToHtml from '@/utils/portableTextToHtml'

export default {
  name: 'VendorInfoElement',

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    vendor () {
      return this.item.vendor
    },

    html () {
      return {
        template: portableTextToHtml(this.vendor.profile)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-element {
  display: grid;

  h1 {
    color: $title-color-one;
    padding-bottom: 1rem;
  }

  .close-button {
    align-self: start;
    padding: 0;
  }

  .body {
    color: $font-color-two;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 2fr 1fr;

    .side-bit {
      display: grid;
      row-gap: 0.6rem;

      dl.info {
        background-color: lightGrey;
        color: black;
        display: grid;
        font-size: 0.8rem;
        padding: 0.4rem;
        row-gap: 0.6rem;

        div {
          column-gap: 0.2rem;
          display: grid;
          grid-template-columns: max-content auto;
        }

        dt {
          font-weight: 400;
          grid-column-start: 1;
        }

        dd {
          grid-column-start: 2;
        }
      }
    }
  }

  .registration-info {
    color: $font-color-two;
    font-weight: 400;
    justify-self: center;
    padding-top: 1rem;
  }
}
</style>
