var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.showDocumentPreviewDialog)?_c('document-preview-dialog',{attrs:{"document":_vm.documentPreview},on:{"close":function($event){_vm.showDocumentPreviewDialog = false},"create":_vm.create}}):_vm._e(),(!_vm.documentUnlocked)?_c('document-locked',{attrs:{"product-id":_vm.productId}}):_c('span',[_c('h1',[_vm._v(" Loan Agreement "),_c('span',{staticClass:"icon-help-circle",on:{"click":function($event){$event.preventDefault();return _vm.setHelp(91)}}})]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var formIsValid = ref.valid;
return [_c('form',{staticClass:"spaced",attrs:{"id":"loan-agreement-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createPreview)}}},[_c('validation-provider',{attrs:{"name":"Document Label","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{staticClass:"title-input",attrs:{"label":"Document Label","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.documentLabel),callback:function ($$v) {_vm.documentLabel=$$v},expression:"documentLabel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Lender","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.lenderSelectOptions,"label":"Lender","default-option-text":"Add Lender","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.lenderId),callback:function ($$v) {_vm.lenderId=$$v},expression:"lenderId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Borrower","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-select',{attrs:{"items":_vm.borrowerSelectOptions,"label":"Borrower","default-option-text":"Add Borrower","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.borrowerId),callback:function ($$v) {_vm.borrowerId=$$v},expression:"borrowerId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Agreement Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Agreement Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanAgreementDate),callback:function ($$v) {_vm.loanAgreementDate=$$v},expression:"loanAgreementDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Amount","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Amount","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanAmount),callback:function ($$v) {_vm.loanAmount=$$v},expression:"loanAmount"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Legal Fees"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.legalFees),callback:function ($$v) {_vm.legalFees=$$v},expression:"legalFees"}}),_c('validation-provider',{attrs:{"name":"Interest Rate","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Interest Rate","type":"number","step":".01","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.interestRate),callback:function ($$v) {_vm.interestRate=$$v},expression:"interestRate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Interest Beginning On Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Interest Beginning On Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.interestBeginningOnDate),callback:function ($$v) {_vm.interestBeginningOnDate=$$v},expression:"interestBeginningOnDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Repayment Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Repayment Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanRepaymentDate),callback:function ($$v) {_vm.loanRepaymentDate=$$v},expression:"loanRepaymentDate"}})]}}],null,true)}),_c('e-input',{attrs:{"label":"Late Payment Interest Rate","type":"number","step":".01"},on:{"input":function($event){return _vm.$emit('is-dirty')}},model:{value:(_vm.latePaymentInterestRate),callback:function ($$v) {_vm.latePaymentInterestRate=$$v},expression:"latePaymentInterestRate"}}),_c('e-input',{attrs:{"label":"Late Payment Property Sold By"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.latePaymentPropertySoldBy),callback:function ($$v) {_vm.latePaymentPropertySoldBy=$$v},expression:"latePaymentPropertySoldBy"}}),_c('e-input',{attrs:{"label":"Late Payment Property Reduced By After Two Months"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.latePaymentPropertyReducedByAfterTwoMonths),callback:function ($$v) {_vm.latePaymentPropertyReducedByAfterTwoMonths=$$v},expression:"latePaymentPropertyReducedByAfterTwoMonths"}}),_c('e-input',{attrs:{"label":"Late Payment Property Lowest Sale Price"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.latePaymentPropertyLowestSalePrice),callback:function ($$v) {_vm.latePaymentPropertyLowestSalePrice=$$v},expression:"latePaymentPropertyLowestSalePrice"}}),_c('e-textarea',{attrs:{"label":"Loan Secured By Following Security"},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanSecuredByFollowingSecurity),callback:function ($$v) {_vm.loanSecuredByFollowingSecurity=$$v},expression:"loanSecuredByFollowingSecurity"}}),_c('validation-provider',{attrs:{"name":"Witness Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Witness Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.witnessDate),callback:function ($$v) {_vm.witnessDate=$$v},expression:"witnessDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Agreement Signed Sealed And Delivered By","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Agreement Signed Sealed And Delivered By","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanAgreementSignedSealedAndDeliveredBy),callback:function ($$v) {_vm.loanAgreementSignedSealedAndDeliveredBy=$$v},expression:"loanAgreementSignedSealedAndDeliveredBy"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Agreement Explained To Borrower By","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Agreement Explained To Borrower By","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanAgreementExplainedToBorrowerBy),callback:function ($$v) {_vm.loanAgreementExplainedToBorrowerBy=$$v},expression:"loanAgreementExplainedToBorrowerBy"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Loan Agreement Explaination Date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Loan Agreement Explaination Date","type":"date","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.loanAgreementExplainationDate),callback:function ($$v) {_vm.loanAgreementExplainationDate=$$v},expression:"loanAgreementExplainationDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Solicitors Name","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-input',{attrs:{"label":"Solicitors Name","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.solicitorsName),callback:function ($$v) {_vm.solicitorsName=$$v},expression:"solicitorsName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Solicitors Address","rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('e-textarea',{attrs:{"label":"Solicitors Address","error-messages":errors,"success":valid,"is-required":true},on:{"input":function($event){return _vm.setIsDirty(true)}},model:{value:(_vm.solicitorsAddress),callback:function ($$v) {_vm.solicitorsAddress=$$v},expression:"solicitorsAddress"}})]}}],null,true)}),_c('save-bar',{attrs:{"form-is-valid":formIsValid}},[_c('submit-button',{attrs:{"form-is-valid":formIsValid,"form":"loan-agreement-form","button-text":"Preview"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }