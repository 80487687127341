<template>
  <span>
    <h1>Memorial</h1>

    <p>
      What permanent marker would you like to leave behind. This
      maybe the design you would like on a gravestone or something
      more elaborate.
    </p>

    <form
      id="memorial-form"
      @submit.prevent="submit"
    >
      <e-textarea
        v-model="description"
        class="description-textarea"
        @input="setIsDirty(true)"
      />
    </form>

    <save-bar :form-is-valid="true">
      <submit-button
        :form-is-valid="true"
        form="memorial-form"
      />
    </save-bar>
  </span>
</template>

<script>
import SaveBar from '@/components/SaveBar'
import SubmitButton from '@/components/buttons/SubmitButton'
import User from '@/models/User'

export default {
  name: 'Memorial',

  components: {
    SaveBar,
    SubmitButton
  },

  emits: ['submitted'],

  data () {
    return {
      description: '',
      isDirty: false
    }
  },

  computed: {
    user () {
      return this.$store.getters.user || {}
    }
  },

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 131,
      isInitial: true
    })

    this.description = this.user.requests.find(({ id }) => id === 'memorial').text
  },

  methods: {
    setIsDirty (bool) {
      this.isDirty = bool
      this.$emit('is-dirty', bool)
    },

    async submit () {
      const currentRequests = [...this.user.requests]
      const index = currentRequests.findIndex(({ id }) => id === 'memorial')

      currentRequests.splice(index, 1, {
        id: 'memorial',
        name: 'Memorial',
        text: this.description
      })

      User.update({
        data: {
          id: this.user.id,
          requests: currentRequests
        }
      })

      await this.$store.dispatch('persistRecordToVault', {
        entityTypes: ['user'],
        message: 'Memorial successfully updated'
      })

      this.setIsDirty(false)
    }
  }
}
</script>

<style scoped lang="scss">
/* stylelint-disable-next-line */
::v-deep textarea {
  height: 30vh;
}

h1 {
  padding-bottom: 1rem;
}
</style>
