'use strict'

import Documents from '@/views/Files/Files'
import DocumentsTable from '@/views/Files/DocumentsTable'
import FormView from '@/views/FormView'
import Generators from '@/views/Files/Generators'
import LoadingSpinner from '@/components/LoadingSpinner'
import UploadedFiles from '@/views/Files/UploadedFiles'

const subscriptionFeatureId = 2100

export default {
  path: '/files',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Documents
  },
  meta: {
    title: 'Documents',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'generators',
      name: 'Generators',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Generators
      }
    },
    {
      path: 'docs',
      name: 'DocumentsTable',
      components: {
        appClosedLayout: LoadingSpinner,
        default: DocumentsTable
      },
      meta: { title: 'Rendered Documents' }
    },

    {
      path: 'uploaded',
      name: 'UploadedFiles',
      components: {
        appClosedLayout: LoadingSpinner,
        default: UploadedFiles
      },
      meta: { title: 'Uploaded Files' }
    },

    {
      path: '/generators/advance-decision',
      name: 'AdvanceDecision',
      props: {
        default: () => ({
          form: 'AdvanceDecision'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Advance Decision: Living Will' }
    },

    {
      path: '/generators/child-care-service-agreement',
      name: 'ChildCareServiceAgreement',
      props: {
        default: () => ({
          form: 'ChildCareServiceAgreement'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Child Care Service Agreement' }
    },

    {
      path: '/generators/medical-consent',
      name: 'MedicalConsent',
      props: {
        default: () => ({
          form: 'MedicalConsent'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Medical Consent' }
    },

    {
      path: '/generators/travel-consent',
      name: 'TravelConsent',
      props: {
        default: () => ({
          form: 'TravelConsent'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Travel Consent' }
    },

    {
      path: '/generators/7iMQuestionnaire',
      name: '7iMQuestionnaire',
      props: {
        default: () => ({
          form: '7iMQuestionnaire'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: '7iM Questionnaire' }
    },

    {
      path: '/generators/7iMUnconstrainedFund',
      name: '7iMUnconstrainedFund',
      props: {
        default: () => ({
          form: 'UnconstrainedFund7iM'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: '7iM Unconstrained Fund' }
    },

    {
      path: '/generators/uk-housing-act-section-twenty-one',
      name: 'UkHousingActSectionTwentyOne',
      props: {
        default: () => ({
          form: 'UkHousingActSectionTwentyOne'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'UK Housing Act: Section 21' }
    },

    {
      path: '/generators/uk-housing-act-section-eight',
      name: 'UkHousingActSectionEight',
      props: {
        default: () => ({
          form: 'UkHousingActSectionEight'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'UK Housing Act: Section 8' }
    },

    {
      path: '/generators/contract-termination-agreement',
      name: 'ContractTerminationAgreement',
      props: {
        default: () => ({
          form: 'ContractTerminationAgreement'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Contract Termination Agreement' }
    },

    {
      path: '/generators/uk-land-registry-transfer',
      name: 'UkLandRegistryTransfer',
      props: {
        default: () => ({
          form: 'UkLandRegistryTransfer'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'UK Land Registry Title Transfer' }
    },

    {
      path: '/generators/property-declaration-of-trust',
      name: 'PropertyDeclarationOfTrust',
      props: {
        default: () => ({
          form: 'PropertyDeclarationOfTrust'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Property Declaration Of Trust' }
    },

    {
      path: '/generators/loan-agreement',
      name: 'LoanAgreement',
      props: {
        default: () => ({
          form: 'LoanAgreement'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Loan Agreement' }
    },

    {
      path: '/generators/non-disclosure-agreement',
      name: 'NonDisclosureAgreement',
      props: {
        default: () => ({
          form: 'NonDisclosureAgreement'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Non-Disclosure Agreement' }
    },

    {
      path: '/generators/general-release-of-liability',
      name: 'GeneralReleaseOfLiability',
      props: {
        default: () => ({
          form: 'GeneralReleaseOfLiability'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'General Release Of Liability' }
    },

    {
      path: '/generators/deed-of-revocation-for-power-of-attorney',
      name: 'DeedOfRevocationForPowerOfAttorney',
      props: {
        default: () => ({
          form: 'DeedOfRevocationForPowerOfAttorney'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Deed Of Revocation For A Power Of Attorney' }
    },

    {
      path: '/generators/debt-letter-before-legal-action',
      name: 'DebtLetterBeforeLegalAction',
      props: {
        default: () => ({
          form: 'DebtLetterBeforeLegalAction'
        })
      },
      components: {
        appClosedLayout: LoadingSpinner,
        default: FormView
      },
      meta: { title: 'Debt Letter Before Legal Action' }
    }
  ]
}
