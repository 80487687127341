<template>
  <transition name="fade">
    <div
      class="progress-element-container"
    >
      <label>
        <h1>
          Retrieve My Data
        </h1>

        <div class="progress-element">
          <progress
            v-if="!isComplete"
            class="progress-bar"
          />

          <e-button
            v-else
            @click="closeGdprProgressElement"
          >
            Download
          </e-button>
        </div>
      </label>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'GdprProgressElement',

  data () {
    return {
      navigateToRoute: 'MyData'
    }
  },

  computed: {
    isComplete () {
      return this.$store.state.gdpr.complete
    }
  },

  watch: {
    isComplete () {
      if (this.isComplete && this.$route.name === this.navigateToRoute) {
        this.$store.commit('gdpr/setData', { key: 'showProgressElement', value: false })
      }
    }
  },

  methods: {
    closeGdprProgressElement  () {
      this.$store.commit('gdpr/setData', { key: 'showProgressElement', value: false })

      if (this.$route.name !== this.navigateToRoute) this.$router.push({ name: this.navigateToRoute })
    }
  }
}
</script>

<style scoped lang="scss">
.progress-element-container {
  background-color: black;
  border-radius: 6px;
  border-width: 0;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
  color: $font-color-two;
  font-size: 1rem;
  font-weight: 400;
  min-width: 10rem;
  padding: 1rem;

  label {
    display: grid;
    row-gap: 0.8rem;
  }

  h1 {
    font-size: 1rem;
    text-align: center;
  }

  .progress-element {
    display: grid;
    grid-template-columns: 100%;

    .progress-bar {
      justify-self: start;
      width: 100%;
    }

    .icon-x-circle {
      justify-self: end;
      padding: 0;
    }
  }
}
</style>
