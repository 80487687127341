'use strict'

import Credential from '@/views/Secrets/Credential'
import Credentials from '@/views/Secrets/Credentials'
import CredentialView from '@/views/Secrets/CredentialView'
import FormView from '@/views/FormView'
import LoadingSpinner from '@/components/LoadingSpinner'
import Notes from '@/views/Secrets/Notes'
import Secrets from '@/views/Secrets/Secrets'

const subscriptionFeatureId = 6535

export default {
  path: '/secrets',
  name: 'Secrets',
  components: {
    appClosedLayout: LoadingSpinner,
    default: Secrets
  },
  meta: {
    title: 'Secrets',
    subscriptionFeatureId
  },
  children: [
    {
      path: 'credentials',
      name: 'Credentials',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Credentials
      },
      meta: {
        title: 'Credentials',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'new',
          name: 'NewCredential',
          props: {
            default: () => ({
              form: 'CredentialForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'New Credential',
            subscriptionFeatureId
          }
        },

        {
          path: ':id',
          components: {
            appClosedLayout: LoadingSpinner,
            default: Credential
          },
          meta: {
            title: 'Credential',
            subscriptionFeatureId
          },
          children: [
            {
              path: '',
              name: 'CredentialView',
              props: {
                default: route => ({
                  id: route.params.id
                })
              },
              components: {
                appClosedLayout: LoadingSpinner,
                default: CredentialView
              },
              meta: {
                title: 'Credential',
                subscriptionFeatureId
              }
            },

            {
              path: 'edit',
              name: 'EditCredential',
              props: {
                default: route => ({
                  form: 'CredentialForm',
                  id: route.params.id
                })
              },
              components: {
                appClosedLayout: LoadingSpinner,
                default: FormView
              },
              meta: {
                title: 'Edit Credential',
                subscriptionFeatureId
              }
            }
          ]
        }
      ]
    },

    {
      path: 'notes',
      name: 'Notes',
      components: {
        appClosedLayout: LoadingSpinner,
        default: Notes
      },
      meta: {
        title: 'Notes',
        subscriptionFeatureId
      },
      children: [
        {
          path: 'new',
          name: 'NewNote',
          props: {
            default: () => ({
              form: 'NoteForm'
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'New Note',
            subscriptionFeatureId
          }
        },

        {
          path: ':id',
          name: 'Note',
          props: {
            default: route => ({
              form: 'NoteForm',
              id: route.params.id
            })
          },
          components: {
            appClosedLayout: LoadingSpinner,
            default: FormView
          },
          meta: {
            title: 'Note',
            subscriptionFeatureId
          }
        }
      ]
    }
  ]
}
