'use strict'

export default class DecryptError extends Error {
  constructor (...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DecryptError)
    }

    this.name = 'DecryptError'
  }
}
