<template>
  <div class="spaced">
    <div class="top-inputs">
      <h1>
        Distribution

        <span
          class="icon-help-circle"
          @click.prevent="setHelp(69)"
        />
      </h1>
    </div>

    <p>
      In this section you decide what should happen to your personal possessions,
      your property and your money after you die. You can choose to bequeath some
      or all of your possessions, real estate and gifts of money to individuals,
      'groups' of individuals such as 'children' and to charities.
    </p>

    <p>
      It is common practice to add a clause in your will to exclude section 33 of
      the Wills Act 1837. By excluding this section we ensure that if the primary
      beneficiary of a gift passes before your death, that gift is distributed to
      a named substitute beneficiaries or, if the option is selected, the children
      of the primary beneficiary. If section 33 is allowed to stand, then the children
      of the primary beneficiary will always inherit if no substitute is named and
      their parent has passed by the time of your death. If you need further information
      on the implications of section 33 you might consider seeking professional
      legal advice through our legal services affiliate.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Distribution',

  created () {
    this.$store.dispatch('findAndSetHelpObject', {
      id: 69,
      isInitial: true
    })
  },

  methods: {
    setHelp (id) {
      this.$store.dispatch('findAndSetHelpObject', { id })
    }
  }
}
</script>
