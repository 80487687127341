'use strict'

import axios from 'axios'

export default async ({ fileUrl, progressExecutor, abortController }) => {
  return axios({
    method: 'get',
    headers: { 'Content-Type': 'application/octet-stream' },
    url: fileUrl,
    responseType: 'blob',
    onDownloadProgress: progressExecutor,
    signal: abortController.signal,
    timeout: 600000 // 10 minutes
  })
}
